<script type="text/javascript">
	import VueSlider from 'vue-slider-component'
	import ImageBlocks from "../components/widgets/blocks/ImageBlocks";
	import MiniCardCarousel from "../components/widgets/carousel/MiniCardCarousel";
	import Swiper from "../components/widgets/carousel/Swiper";
	import ImageSwiper from "../components/widgets/gallery/ImageSwiper";
	import Login from "../public-views/account/onpageLogin";
	import VueEasyLightbox from "vue-easy-lightbox";
	import ProductContent from "../components/widgets/ecommerce/public/ProductContent";
	import Breadcrumbs from "../components/widgets/ecommerce/public/Breadcrumbs"
	import { EventBus } from '../public-app'
	export default{
		components: { Swiper, ImageBlocks, MiniCardCarousel, VueSlider, Login, VueEasyLightbox, ImageSwiper, ProductContent, Breadcrumbs },
		data(){
			return{
				title: '',
				breadcrumbs: [],
				bread: {
					title: '',
					link: '',
					id: 1,
				},
				csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
				categorydata: '',
				categoriesdata: '',
				data: '',
				
				imgswiperOptions: {
          direction: 'vertical',
          slidesPerView: 3,
          spaceBetween: 10,
          pagination: {
            el: '.swiper-pagination',
            clickable: true
          },
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
          }

        },
        swiperOptions: {
          slidesPerView: 4,
          spaceBetween: 5,
          loop: false,
          loopFillGroupWithBlank: false,
          pagination: {
            el: '.swiper-pagination',
            clickable: true,
          },
          // Some Swiper option/callback...
        },
        items2: [
        {
          cover_image: '/images/product-placeholder.png',
          name: 'Modern Aquamarine Diamond And White Gold Chandelier Drop Earrings',
          link: '/category/jewellery',
          price: '2000.00',
        },
        {
          cover_image: '/images/product-placeholder.png',
          name: 'Vintage 18ct Gold Necklace And Bracelet Suite, Circa 1980',
          link: '/category/jewellery',
          price: '2000.00',
        },
        {
          cover_image: '/images/product-placeholder.png',
          name: 'Edwardian Sapphire And Diamond Bracelet, Circa 1910',
          link: '/category/jewellery',
          price: '2000.00',
        },
        {
          cover_image: '/images/product-placeholder.png',
          name: 'David Webb Rock Crystal Diamond Gold Earrings',
          link: '/category/jewellery',
          price: '2000.00',
        },
        {
          cover_image: '/images/product-placeholder.png',
          name: 'Earrings',
          link: '/category/jewellery',
          price: '2000.00',
        },
        {
          cover_image: '/images/product-placeholder.png',
          name: 'Earrings',
          link: '/category/jewellery',
          price: '2000.00',
        },
        {
          cover_image: '/images/product-placeholder.png',
          name: 'Earrings',
          link: '/category/jewellery',
          price: '2000.00',
        },
        {
          cover_image: '/images/product-placeholder.png',
          name: 'Earrings',
          link: '/category/jewellery',
          price: '2000.00',
        },
        {
          cover_image: '/images/product-placeholder.png',
          name: 'Earrings',
          link: '/category/jewellery',
          price: '2000.00',
        },
        {
          cover_image: '/images/product-placeholder.png',
          name: 'Earrings',
          link: '/category/jewellery',
          price: '2000.00',
        },
        {
          cover_image: '/images/product-placeholder.png',
          name: 'Earrings',
          link: '/category/jewellery',
          price: '2000.00',
        },
        {
          cover_image: '/images/product-placeholder.png',
          name: 'Earrings',
          link: '/category/jewellery',
          price: '2000.00',
        },
      ],
				showLightbox: false,
				previewImgBtns: false,
				coverimgs: [
	        { src: "/images/product-placeholder.png", title: "Caption 1" },
	        { src: "/images/product-placeholder.png", title: "Caption 2" },
	        { src: "/images/product-placeholder.png", title: "Caption 1" },
	        { src: "/images/product-placeholder.png", title: "Caption 2" },
	        { src: "/images/product-placeholder.png", title: "Caption 1" },
	        { src: "/images/product-placeholder.png", title: "Caption 2" }
	      ],
				//storageurl: 'https://'+this.$attrs.company.nickname+'.s3.eu-west-2.amazonaws.com',
				productsLoading: true,
				showFilters: false,
		        sliderPrice: 800,
		        priceRange: [0,250000],
		        brands: [],
		        collections: [],
		        categories: [],
		        sortby: 'created_at',
		        order: 'desc',
		        search: '',
		        view: 'grid',
		        public_active: 1,
		        trade_active: '',
		        active: '',
		        expanded: 1,
		        currentPage: 1,
		        query: '',
		        limit: 25,
				limits: [
				        {value: 25, text: "25"},
				        {value: 50, text: "50"},
				        {value: 100, text: "100"},
				      ],
			}
		},
		props: {
			user: {
				type: Object
			}
		},
		mounted() {
			this.createQueryString();
			this.loadSellerInfo();
			this.loadSellerProducts();
			this.loadSellerCollections();
			this.checkUserLoggedIn();
			//this.loadSellerCollections();
			this.breadcrumbs = this.$route.path.split('/');
			this.breadcrumbs[1] = this.breadcrumbs[1]+'s';
			this.breadcrumbs[2] = this.breadcrumbs[2].replaceAll('-',' ');
				if(this.breadcrumbs.length > 0){
				this.title = this.breadcrumbs[this.breadcrumbs.length - 1];
				this.breadcrumbs[0] = "Home";
				}
				//let self = this;
			
		},
		created(){
			let self = this;
			EventBus.$on('category-select', function(id){
				self.filterSelectedCategories(id);
		    });
			EventBus.$on('categories-cleared', function(id){
				self.categories = [];
				self.loadSellerProducts();
		    });
		},
		methods: {
			checkUserLoggedIn(){
				axios.get('/customer-api/user').then(response => {
					console.log(response);
				}).catch( error => {
					console.log(error);
				})
			},
			loadSellerProducts(){
				this.createQueryString();
				this.productsLoading = true;
				//console.log(this.$route.path);
				var routepath = this.$route.path;
				var path = routepath.replace('/profile', '');
				console.log(path);
				axios.get('/customer-api'+path+'/products'+this.query).then(response => {
					this.data = response.data;
				}).catch(error => {
					console.log(error);
				});
				var self = this;
                setTimeout(function(){
	                self.productsLoading = false;                
                }, 500);
			},
			loadSellerInfo(){
				axios.get('/customer-api'+this.$route.path).then(response => {
					this.categorydata = response.data;
				}).catch(error => {
					console.log(error);
				});
			},
			loadSellerCollections(){
				axios.get('/customer-api/seller/collections').then(response => {
					this.categoriesdata = response.data;
				}).catch(error => {
					console.log(error);
				});
			},
			filterBrands(value){
		      this.brands = value;
		      //this.createQueryString();
		      this.loadSellerProducts();
		    },
		    filterCollections(id){
		        
		          var exists = this.collections.findIndex(item => item == id);
		          console.log(exists);
		          if(exists == -1){
		            this.collections.push(id);
		          }else{
								this.collections.splice(exists, 1);
		          }
		          //this.createQueryString();
		      		this.loadSellerProducts();
		      },
			createQueryString(){
				this.query = '?page='+this.currentPage+'&limit='+this.limit+'&sortby='+this.sortby+'&order='+this.order+'&search='+this.search+'&public_active='+this.public_active+'&active='+this.active+'&brands='+this.brands+'&collections='+this.collections+'&categories='+this.categories+'&pricerange='+this.priceRange+'';
			},
			filterSelectedCategories(id){
		        
		          var exists = this.categories.findIndex(item => item == id);
		          console.log(exists);
		          if(exists == -1){
		            this.categories.push(id);
		          }else{
								this.categories.splice(exists, 1);
		          }
		          //this.createQueryString();
		      		this.loadSellerProducts();
		      },
		},
		watch: {
        $route() {
        	this.$nextTick(() => {
        		this.loadSellerProducts();
        		this.loadSellerInfo();
        		
        	 });
            //this.$nextTick();
            let self = this;
			
         }  
    	},
	}

</script>

<template>
<div>
		<section class="seller-top-row" >
		  <div class="container-fullwidth" style="background: #fff">
		    <!--<PageHeader :title="title" :items="items" />-->
		   
		     <!--<b-row class="mb-4" style="min-height: 400px; background-image: url('/images/large-banner-bg.png'); background-position:  right bottom; background-size: 50%; background-repeat: no-repeat">

		      <b-col md="10" class="d-flex flex-column justify-content-center align-items-start mx-auto">
		      	
		      	<div class="d-flex flex-column mt-4 pt-4">
		      	
		      		<b-row class="">
				 	<b-col md="12" class="d-flex flex-row align-items-start mx-auto">
				 	<div  v-for="(bread, index) in breadcrumbs" class="d-flex flex-row">
		    		<span class="text-muted font-size-12 text-capitalize my-auto"><i class="bx bx-home my-auto font-size-10 mr-1" v-if="index == 0"></i>{{bread}}</span>
		    		 <i class="bx bx-chevron-right my-auto" v-if="index !== (breadcrumbs.length -1)"></i>
		    		</div>

				    	</b-col>
				    </b-row>
		        <h1 class="font-size-32 text-primary my-1 text-capitalize">Product Name</h1>
		      	</div>
		      </b-col>
		    </b-row>-->
		    <b-row class="seller-top-row pb-4">
		    	<ProductContent v-if="categorydata !== ''" :product="categorydata" :user="user" :selected_currency="$attrs.selected_currency" :exchange_rates="$attrs.exchange_rates" :currencies="$attrs.currencies" :selected_currency_symbol="$attrs.selected_currency_symbol" ></ProductContent>
		    	 	<!--<ImageSwiper :swiperOptions="imgswiperOptions" :items="coverimgs" ></ImageSwiper>
		    	<b-row class="pb-5">
		    		<b-col md="7" class="ml-auto profile-user-wid">
		    		<div class="d-flex flex-row">
		    			<div class="position-relative" style="width: 90%;" >
				    	<img
                           src="/images/banner-placeholder.png"
                            alt
                            class="img-fluid mx-auto d-block border-radius-1em"
                            @mouseenter="previewImgBtns = !previewImgBtns" @mouseleave="previewImgBtns = !previewImgBtns"
                          />
                          <transition name="fade-top">
	                          <div class="position-absolute top-0 right-0 mt-3 mr-3 rounded" v-if="previewImgBtns == true">
	                          	<a class="btn btn-primary rounded-circle" href="javascript:;" @click="showLightbox = !showLightbox">
	                          		<i class="bx bx-search"></i>
	                          	</a>
	                          </div>
                        	</transition>
                        	<figcaption class="text-muted font-italic my-2">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</figcaption>
               </div>
              <div class="d-block overflow-hidden px-1 position-relative" style="max-height: 626px; width: 10%;">
				   
				    	</div>
            </div>
                 
        <vue-easy-lightbox
                :visible="showLightbox"
                :imgs="captionimgs"
                :index="0"
                @hide="showLightbox = !showLightbox"
              ></vue-easy-lightbox>
                    <b-tabs class="profile-user-wid"  pills vertical end nav-wrapper-class="col-md-2 col-sm-3 col-4" active-nav-item-class="p-0 border border-primary">
                      <b-tab title-link-class="p-0 mb-2 border-radius-1em shadow-sm" >
                        <template v-slot:title>
                          <img
                            src="/images/banner-placeholder.png"
                            alt
                            class="img-fluid mx-auto d-block tab-img rounded"
                          />
                        </template>
                        <div class="product-img border-radius-1em position-relative" @mouseenter="previewImgBtns = !previewImgBtns" @mouseleave="previewImgBtns = !previewImgBtns">
                          <img
                           src="/images/banner-placeholder.png"
                            alt
                            class="img-fluid mx-auto d-block border-radius-1em"
                          />
                          <transition name="fade-top">
	                          <div class="position-absolute top-0 right-0 mt-3 mr-3 rounded" v-if="previewImgBtns == true">
	                          	<a class="btn btn-primary rounded-circle" href="javascript:;" @click="showLightbox = !showLightbox">
	                          		<i class="bx bx-search"></i>
	                          	</a>
	                          </div>
                        	</transition>
                        	<figcaption class="text-muted font-italic my-2">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</figcaption>
                        </div>
                    </b-tab>
                    <b-tab title-link-class="p-0 mb-2 border-radius-1em shadow-sm">
                      <template v-slot:title>
                        <img
                        src="/images/banner-placeholder.png"
                          alt
                          class="img-fluid mx-auto d-block tab-img rounded"
                        />
                        <div class="img-upload-icon"><i class="bx bx bx-image-add"></i></div>
                      </template>
                   
                    </b-tab>
                  </b-tabs>
                  
                  <b-row>
                  	<b-col>
                  		<h5 class="mt-4">Product Summary</h5>
                  		<p class="font-size-16">
                  			Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                  		</p>
                  		<hr class="my-5">
                  		
                       <h5 class="text-dark"> Description</h5>
                      
                   			<p class="font-size-16">
                  			Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                  		</p>
                  		<p class="font-size-16">
                  			Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                  		</p>
                    <hr class="my-5">
                    <h5 class="text-dark pb-3"> Product Features</h5>
                    <b-list-group flush class="">
                    	<b-list-group-item>
                    		<img src="/images/product-placeholder.png" class="avatar-md mr-3 rounded-circle">
                    		<span>Feature Name</span>
                    		<span>Feature Value</span>
                    		<span>Feature Unit</span>
                    	</b-list-group-item>
                    	<b-list-group-item>
                    		<img src="/images/product-placeholder.png" class="avatar-md mr-3 rounded-circle">
                    		<span>Feature Name</span>
                    		<span>Feature Value</span>
                    		<span>Feature Unit</span>
                    	</b-list-group-item>
                    </b-list-group>
                    <hr class="my-5">
                    <h5 class="text-dark pb-3"> Product Reviews</h5>
                    <hr class="my-5">
                    <h5 class="text-dark pb-3"> More From This Seller</h5> 
                    <Swiper :items="items2" :swiperOptions="swiperOptions"></Swiper>
                    <hr class="my-5">
                    <h5 class="text-dark pb-3"> Related Products</h5>
                     <Swiper :items="items2" :swiperOptions="swiperOptions"></Swiper>

                  	</b-col>

                  	
                  </b-row>
                
		        </b-col>
		        <b-col md="3" class="mr-auto">
		        	<div class="avatar-xl profile-user-wid mb-2 mr-auto w-100">
		        		<b-card class="border-radius-1em">
		        			<h3 class="text-center mt-4">£ 7000.00 <small>inc. VAT</small></h3>
		        			<p class="mb-4 text-center">£6333.33 exc. VAT</p>
			            
		        			<a class="btn btn-primary btn-rounded btn-block mb-2" v-b-modal.modal-1 @click="form.action = 'message'">Add to Cart <i class="bx bx-shopping-bag"></i></a>
		        			<a class="btn btn-light btn-rounded btn-block mb-2" @click="form.action = 'favourites'">Add To Wishlist <i class="mdi mdi-heart-outline text-danger"></i></a>
		        			<h5 class="text-center mt-5">Product Rating</h5>
		        			<p  class="text-muted my-3 text-center">
			                    <span class="bx bx-star font-size-18 text-warning"></span>
			                    <span class="bx bx-star font-size-18 text-warning ml-1"></span>
			                    <span class="bx bx-star font-size-18 text-warning ml-1"></span>
			                    <span class="bx bx-star font-size-18 text-warning ml-1"></span>
			                    <span class="bx bx-star font-size-18 ml-1"></span>
			                    <br><small>
			             	 / 5 (1,000 reviews)
			             </small>
			                  </p>
		        			<a class="btn btn-outline-light btn-rounded btn-block mb-2"  v-b-modal.modal-1 @click="form.action = 'review'">Submit Review <i class="bx bx-star text-warning"></i></a>
		        		</b-card>
		        		<b-card class="border-radius-1em mt-3">
		        		</b-card>
		        	</div>
		        </b-col>
		    	</b-row>-->
		  	</b-row>
		    <!-- end row -->
		  </div>
		</section>

		
</div>
</template>