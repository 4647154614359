		<template>	
			<div class="overflow-hidden">				
				<a class="position-relative d-block" @mouseenter="previewImgBtns = true" @mouseleave="previewImgBtns = false" @click="setUpLightbox(), showLightbox = !showLightbox">
				<img v-if="(product.cover_image !== null) && (imgView == null)" :src="storageurl + product.cover_image.src.replaceAll('//', '/')" class="img-fluid w-100 border mb-3" >
				<img v-else-if="imgView !== null" :src="storageurl + imgView.gallery.replaceAll('//', '/')" class="img-fluid w-100 border mb-3"> 
				<img v-else src="/images/product-placeholder.png" class="img-fluid w-100 border mb-3">
				<transition name="fade-right">
				   <div class="position-absolute top-0 right-0 mt-3 mr-3 rounded" v-if="previewImgBtns == true">
	                          	<a class="btn btn-primary rounded-circle bg-peach-soft" href="javascript:;">
	                          		<i class="bx bx-search"></i>
	                          	</a>
	                </div>
	            </transition>
				</a>
				<ImageSwiper :swiperOptions="imgswiperOptions" :items="product.images" @image-selected="imageSelected" :imgClass="'border'" :company="$attrs.company" ></ImageSwiper>
			</div>
		</template>

		<script type="text/javascript">
			export default {
				props: {
					product: {
						type: Object
					}
				},
				data(){
					return{
						storageurl: 'https://'+this.$attrs.company.nickname+'.s3.eu-west-2.amazonaws.com',
						previewImgBtns: false,
						showLightbox: false,
						imgView: null,
						imgViewIndex: 0,
						imgswiperOptions: {
				          slidesPerView: 5,
				          spaceBetween: 10,
				          pagination: {
				            el: '.swiper-pagination',
				            clickable: true
				          },
				          navigation: {
				            nextEl: '.swiper-button-next',
				            prevEl: '.swiper-button-prev'
				          }

				        },
					}
				},
				methods: {
					imageSelected(img){
				    	if(this.imgView == null){
				    		this.product.images.push(this.product.cover_image);
				    	}
				    	this.imgView = img;
			    	},
		    	}
			}

		</script>