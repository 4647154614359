<script type="text/javascript">
	import Layout from "../layouts/public";
	import PageHeader from "../components/widgets/ecommerce/public/PageHeader";
	export default{
		components: { Layout, PageHeader },
		data(){
			return{
				title: 'Contact us',
				breadcrumbs: ["Home", "Contact"],
				contact_methods: [
					{
						name: 'Phone',
						icon: 'bx bx-phone',
						link: '/',
						hours: '',
						response_time: 'Coming Soon',
						status: 'Coming Soon',
					}, 
					{
						name: 'Email Support',
						icon: 'bx bx-mail-send',
						link: '/',
						hours: '',
						response_time: '2 - 4 hours',
						status: '24/7',
					},
					{
						name: 'Online Chat',
						icon: 'bx bx-chat',
						link: '/',
						hours: '',
						response_time: 'Coming Soon',
						status: 'Coming Soon',
					}
				]
			}
		}
	}

</script>

<template>
	<div>
		<PageHeader :data="{name: title, description: 'Have an issue, question or want to submit some feedback? You\'ve come to the right place...'}" />
		<section>
		<b-container>
			<b-row>
				<b-col md="12" class="my-4 py-4 d-flex flex-column">
					<h2 class="text-dark text-center position-relative section-info-header mx-auto">How Do You Want to Communicate?</h2>
					<p class="w-75 mb-0 mx-auto text-center">
		           There's a number of ways you can get in touch. 
		          </p>
				</b-col>
				<b-col md="4" v-for="(method, index) in contact_methods" class="pt-4 mb-4">
					<router-link :to="method.link">
						<b-card class="card shadow-none text-center text-center" :class="{'border-right' : index !== (contact_methods.length - 1)}">
							<i :class="method.icon" class="font-size-36"></i>
							<h4>{{method.name}}</h4>
							<p class="text-muted mb-0 pb-0">Opening Hours: {{method.status}} </p>
							<small class="text-muted font-italic"> Wait Time: {{method.response_time}} </small>
						</b-card>
					</router-link>
				</b-col>
				<b-col md="12" class="mb-5 pb-5 mt-3">
					<b-card class="card shadow-none border border-radius-1em">
						<div class="d-flex">
							<i class="bx bx-question-mark font-size-36 text-primary float-left p-2 bg-light rounded-circle my-auto mr-3"></i> 
							<div class="d-inline-block my-auto"><h4 class="mb-2r">Have some feedback?</h4>
							<p class="mb-0 text-muted">Positive or negative, we want to hear it! Let us know what you like about our product and what you think we could improve. We won't be able to respond to all general feedback, but we will use it in evaluating our product choices in future!</p>
							
							</div>
							<a class="btn btn-primary my-auto w-25">
								Provide Feedback
							</a>
						</div>
					</b-card>
				</b-col>
			</b-row>
		</b-container>

		</section>
	</div>

</template>