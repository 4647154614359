<template>
	<div class="d-flex" >
		<div v-for="network in $attrs.content[filterStylesKey($attrs.content, 'list')].list">
		<a class="btn" :style="{'background-color' : network[filterStylesKey(network, 'background_colour')].background_colour.value, 'color' : network[filterStylesKey(network, 'colour')].colour.value, 'color' : network[filterStylesKey(network, 'colour')].colour.value, 'border-color' : network[filterStylesKey(network, 'border_colour')].border_colour.value}" :href="getFollowLink(network[filterStylesKey(network, 'network')].network.value)+network[filterStylesKey(network, 'handle')].handle.value">
			<i :class="'bx bxl-'+network[filterStylesKey(network, 'network')].network.value"></i> <span v-if="network[filterStylesKey(network, 'title')].title.value !== ''">{{network[filterStylesKey(network, 'title')].title.value}}</span>
		</a>
		
		</div>
	</div>
</template>

<script type="text/javascript">
	export default {
		data(){
			return {
				follow_links: [
						{
							network: 'facebook',
							link: 'http://www.facebook.com/'
						},
						{
							network: 'twitter',
							link: 'http://www.twitter.com/'
						},
						{
							network: 'linkedin',
							link: 'http://www.linkedin.com/company/'
						},
						{
							network: 'pinterest',
							link: 'http://www.pinterest.com/'
						},
						{
							network: 'instagram',
							link: 'http://www.instagram.com/'
						},
						{
							network: 'youtube',
							link: 'http://www.youtube.com/c/'
						},
					]
			}
		},
		methods: {
			getFollowLink(network){
				console.log(network);
				return this.follow_links.filter(item => item.network == network)[0].link;
			},
			filterStylesKey(styles, key){
            for(var i = 0; i < styles.length; i++){
               var keys = Object.keys(styles[i])
               if(keys.includes(key)){
                 return i;
               }
            }
          },
		}
	}

</script>