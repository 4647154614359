<template>
	<b-tab>
		<template v-slot:title>
			<span class="d-inline-block d-sm-none">
				<i class="bx bx-notepad"></i>
			</span>
			<span class="d-none d-sm-inline-block">Styles</span>
		</template>
		<div class=""  :class="{'widget-scrollbox widget-inner-scrollbox' : widgetbox == true}"  :style="[{'max-height' : sidePanelMaxHeight+'px', height: ( !widgetbox ? 'auto' : ''+sidePanelMaxHeight+'px')}]" >
				<b-card class="border shadow-none w-100" body-class="p-0">
					<b-list-group flush class="" v-for="(value, sindex) in $attrs.styles">
						<b-list-group-item v-for="(value, key) in $attrs.styles[sindex]" :id="'settings-'+key" :key="value.id" :class="{'bg-light' : ($attrs.styles[sindex][key].type !== 'hidden') && ($attrs.styles[sindex][key].type == 'label')}" v-if="($attrs.styles[sindex][key].type !== 'hidden')">

							
							<h5 v-if="($attrs.styles[sindex][key].type !== 'hidden') && ($attrs.styles[sindex][key].type == 'label')" class="mb-0">
							<i class="bx bx-chevron-right text-primary"></i> {{$attrs.styles[sindex][key].text}}</h5>
							<label v-else-if="($attrs.styles[sindex][key].type !== 'hidden')" class="text-uppercase">
							<span v-if="$attrs.styles[sindex][key].type == 'cols'">Column</span>
							{{key.replace(/_/g, ' ')}}
							</label>
							<b-form-group class="text-capitalize" >
								
								<div class="d-flex" v-if="$attrs.styles[sindex][key].type == 'number'">
									<b-input-group>
									<b-form-input type="number"  v-model="$attrs.styles[sindex][key].value" :max="2400"></b-form-input>
										<!--<template #append>
											<b-form-radio-group v-model="$attrs.styles[sindex][key].units" buttons :button-variant="'outline-'+activeVariant.variant" >
		    								<b-form-radio class="font-size-12 text-uppercase" value="px">px</b-form-radio>
		    								<b-form-radio class="font-size-12 text-uppercase" value="%">%</b-form-radio>
		    								</b-form-radio-group>

		    								<b-dropdown text="Dropdown" variant="success">
										        <b-dropdown-item @click="$attrs.styles[sindex][key].units = 'px'">px</b-dropdown-item>
										        <b-dropdown-item @click="$attrs.styles[sindex][key].units = '%'">%</b-dropdown-item>
										      </b-dropdown>
    									</template>-->
    								</b-input-group>
    							</div>
    							<div class="d-flex w-100" v-else-if="$attrs.styles[sindex][key].type == 'cols'">
    								<b-form-group class="w-100" >

    								<label class="d-flex w-100">
    									<span class="my-auto">X-Large Screens</span>
    									<i class="mdi mdi-television  ml-auto font-size-18 text-primary"></i>
    								</label>
    								<vue-slide-bar :processStyle="{'backgroundColor' : activeVariant.color+' !important'}" :tooltipStyles="{'backgroundColor': activeVariant.color+' !important', 'borderColor': activeVariant.color+' !important'}" v-model="$attrs.styles[sindex][key].xl" class="mt-1 py-4" style="min-height: unset;" :min="1" :max="12" :data="colrange" :range="collabels" />
    								<label class="d-flex w-100">
    									<span class="my-auto">Large Screens</span>
    									<i class="mdi mdi-monitor  ml-auto font-size-18 text-primary"></i>
    								</label>
    								<vue-slide-bar :processStyle="{'backgroundColor' : activeVariant.color+' !important'}" :tooltipStyles="{'backgroundColor': activeVariant.color+' !important', 'borderColor': activeVariant.color+' !important'}" v-model="$attrs.styles[sindex][key].lg" class="mt-1 py-4" style="min-height: unset;" :min="1" :max="12" :data="colrange" :range="collabels" />
    								<label class="d-flex w-100">
    									<span class="my-auto">Medium Screens</span>
    									<i class="bx bx-laptop  ml-auto font-size-18 text-primary"></i>
    								</label>
    								<vue-slide-bar :processStyle="{'backgroundColor' : activeVariant.color+' !important'}" :tooltipStyles="{'backgroundColor': activeVariant.color+' !important', 'borderColor': activeVariant.color+' !important'}" v-model="$attrs.styles[sindex][key].md" class="mt-1 py-4" style="min-height: unset;" :min="1" :max="12" :data="colrange" :range="collabels" />
    								<label class="d-flex w-100">
    									<span class="my-auto">Small Screens</span>
    									<i class="mdi mdi-tablet-android  ml-auto font-size-18 text-primary"></i>
    								</label>
    								<vue-slide-bar :processStyle="{'backgroundColor' : activeVariant.color+' !important'}" :tooltipStyles="{'backgroundColor': activeVariant.color+' !important', 'borderColor': activeVariant.color+' !important'}" v-model="$attrs.styles[sindex][key].sm" class="mt-1 py-4" style="min-height: unset;" :min="1" :max="12" :data="colrange" :range="collabels" />
    								<label class="d-flex w-100">
    									X-Small Screens <i class="bx bx-mobile  ml-auto font-size-18 text-primary"></i>
    								</label>
    								<vue-slide-bar :processStyle="{'backgroundColor' : activeVariant.color+' !important'}" :tooltipStyles="{'backgroundColor': activeVariant.color+' !important', 'borderColor': activeVariant.color+' !important'}" v-model="$attrs.styles[sindex][key].xs" class="mt-1 py-4" style="min-height: unset;" :min="1" :max="12" :data="colrange" :range="collabels" />
    								
    							</b-form-group>
    						</div>
    							<div class="d-flex flex-column" v-else-if="$attrs.styles[sindex][key].type == 'range'">
       								<vue-slide-bar :processStyle="{'backgroundColor' : activeVariant.color+' !important'}" :tooltipStyles="{'backgroundColor': activeVariant.color+' !important', 'borderColor': activeVariant.color+' !important'}" class="w-100 pt-3 pb-3" v-if="($attrs.styles[sindex][key].units == '') || ($attrs.styles[sindex][key].units == 'px')" v-model="$attrs.styles[sindex][key].value" :max="2400" @input="$attrs.styles[sindex][key].active = true" />
    								<vue-slide-bar :processStyle="{'backgroundColor' : activeVariant.color+' !important'}" :tooltipStyles="{'backgroundColor': activeVariant.color+' !important', 'borderColor': activeVariant.color+' !important'}" class="w-100 pt-3 pb-3" v-else-if="$attrs.styles[sindex][key].units == '%'" v-model="$attrs.styles[sindex][key].value" :max="100" @input="$attrs.styles[sindex][key].active = true" />
									<b-input-group>
									<b-form-input type="number"  v-model="$attrs.styles[sindex][key].value" :max="2400" @input="$attrs.styles[sindex][key].active = true"></b-form-input>
										<template #append v-if="'unit_options' in $attrs.styles[sindex][key]">
											<b-form-radio-group v-model="$attrs.styles[sindex][key].units" buttons :button-variant="'outline-'+activeVariant.variant" v-b-tooltip.hover title="Units" >
		    								
											
		    								<b-form-radio v-for="unit in $attrs.styles[sindex][key].unit_options" class="font-size-12 text-uppercase" :value="unit">{{unit}}</b-form-radio>
		    								</b-form-radio-group>
		    								<b-button :variant="activeVariant.variant" v-b-tooltip.hover title="Reset" @click="$attrs.styles[sindex][key].value = ''"><i class="bx bx-reset"></i></b-button>
		    								<!--<b-dropdown text="Dropdown" variant="success">
										        <b-dropdown-item @click="$attrs.styles[sindex][key].units = 'px'">px</b-dropdown-item>
										        <b-dropdown-item @click="$attrs.styles[sindex][key].units = '%'">%</b-dropdown-item>
										      </b-dropdown>-->
    									</template>

    								</b-input-group>
    							</div>
								
    						<div class="d-flex" v-else-if="$attrs.styles[sindex][key].type == 'numbergroup'">
    							<div class="d-flex" v-if="key.includes('radius') == false">
    								<b-form-group label="" description="LEFT" class="mr-1" >
									<b-form-input @input="$attrs.styles[sindex][key].active = true" type="number"  v-model="$attrs.styles[sindex][key].left" :min="0"></b-form-input>
									</b-form-group>
									<b-form-group description="RIGHT" class="mx-1">
									<b-form-input @input="$attrs.styles[sindex][key].active = true" type="number"  v-model="$attrs.styles[sindex][key].right" :min="0"></b-form-input>
									</b-form-group>
									<b-form-group description="TOP" class="mx-1">
									<b-form-input @input="$attrs.styles[sindex][key].active = true" type="number"  v-model="$attrs.styles[sindex][key].top" :min="0"></b-form-input>
									</b-form-group>
									<b-form-group description="BOTTOM" class="ml-1">
									<b-form-input @input="$attrs.styles[sindex][key].active = true" type="number"  v-model="$attrs.styles[sindex][key].bottom" :min="0"></b-form-input>
									</b-form-group>
								</div>
								<div class="d-flex" v-else-if="key.includes('radius') == true">
    								<b-form-group label="" description="TOP LEFT" class="mr-1" >
									<b-form-input @input="$attrs.styles[sindex][key].active = true" type="number"  v-model="$attrs.styles[sindex][key].top_left" :min="0"></b-form-input>
									</b-form-group>
									<b-form-group description="TOP RIGHT" class="mx-1">
									<b-form-input @input="$attrs.styles[sindex][key].active = true" type="number"  v-model="$attrs.styles[sindex][key].top_right" :min="0"></b-form-input>
									</b-form-group>
									<b-form-group description="BOTTOM LEFT" class="mx-1">
									<b-form-input @input="$attrs.styles[sindex][key].active = true" type="number"  v-model="$attrs.styles[sindex][key].bottom_left" :min="0"></b-form-input>
									</b-form-group>
									<b-form-group description="BOTTOM RIGHT" class="ml-1">
									<b-form-input @input="$attrs.styles[sindex][key].active = true" type="number"  v-model="$attrs.styles[sindex][key].bottom_right" :min="0"></b-form-input>
									</b-form-group>
								</div>

								</div>
								<div class="d-flex" v-else-if="$attrs.styles[sindex][key].type == 'select'">
									<b-form-group class="w-100">
    									<b-form-select v-model="$attrs.styles[sindex][key].value"  class="form-control text-capitalize" :options="$attrs.styles[sindex][key].options" @input="$attrs.styles[sindex][key].active = true">
    									</b-form-select>
    								</b-form-group>

    							</div>
    							<div class="d-flex" v-else-if="$attrs.styles[sindex][key].type == 'color'">
    							<b-form-group id="color"
					                   class="w-100"
					                    >
					                      <div class="bg-light mt-2 mb-3 d-flex flex-column justify-content-center p-4" style="min-height: 150px">
					                     <label>Select Color:</label>
                    					<b-form-input id="color" type="color" name="color" v-model="$attrs.styles[sindex][key].value" @input="recentlyUsedSwatch($attrs.styles[sindex][key].value), $attrs.styles[sindex][key].active = true"></b-form-input>
                    					<label>Default Swatches:</label>
                    					<b-form-checkbox-group id="default-colors" class="d-inline" buttons  button-variant="white" @input="recentlyUsedSwatch($event[$event.length - 1]), $attrs.styles[sindex][key].active = true">
                    						<b-form-checkbox v-for="(color, defaultcolrsindex) in colors.default" :value="color" :style="{'background': color}" style="; width: 25px; height: 25px" class="border border-dark" @change="$attrs.styles[sindex][key].value = colors.default[defaultcolrsindex]">
                    							
                    						</b-form-checkbox>
                    					</b-form-checkbox-group>
                    					<label>Recently Used:</label>
                    					<b-form-checkbox-group id="recent-colors" class="d-inline" buttons  button-variant="white" @input="$attrs.styles[sindex][key].active = true"  >
                    						<template #first>
                    							<b-button variant="white" v-if="colors.recent.length > 11" style="; width: 25px; height: 25px" class="border border-dark" @click="recent_colors_end = 11, $attrs.styles[sindex][key].active = true">
                    							<i class="mdi mdi-minus"></i>
                    							</b-button>
                    						</template>
                    						<b-form-checkbox v-for="(color, recentcolrsindex) in colors.recent.slice(0, recent_colors_end)" :value="color" :style="{'background': color}" style="; width: 25px; height: 25px" class="border border-dark" @input="$attrs.styles[sindex][key].active = true"  @change="$attrs.styles[sindex][key].value = colors.recent[recentcolrsindex]">
                    							
                    						</b-form-checkbox>
                    					
                    							<b-button variant="white" v-if="colors.recent.length > 11" style="; width: 25px; height: 25px" class="border border-dark" @click="recent_colors_end = (colors.recent.length - 1)">
                    							<i class="mdi mdi-plus"></i>
                    							</b-button>
                    						
                    					</b-form-checkbox-group>
                    					<label>Saved Swatches:</label>
                    					<b-form-checkbox-group id="saved-colors" class="d-inline" buttons button-variant="white" >
                    						<b-form-checkbox style="; width: 25px; height: 25px" class="border border-dark">
                    							<i class="mdi mdi-plus"></i>
                    						</b-form-checkbox>
                    						<b-form-checkbox v-for="color in colors.saved" :value="color" :style="{'background': color}" style="; width: 25px; height: 25px" class="border border-dark" @input="$attrs.styles[sindex][key].active = true" >
                    							
                    						</b-form-checkbox>
                    					</b-form-checkbox-group>
                    				</div>
    								</b-form-group>
    							</div>
    							<div class="" v-else-if="$attrs.styles[sindex][key].type == 'radiogroup'">
    								<b-form-radio-group :button-variant="'outline-'+activeVariant.variant" v-if="$attrs.styles[sindex][key].isButton == true" buttons v-model="$attrs.styles[sindex][key].value" :options="$attrs.styles[sindex][key].options" @input="$attrs.styles[sindex][key].active = true" >
    								</b-form-radio-group>
    								<b-form-radio-group :button-variant="'outline-'+activeVariant.variant" v-if="$attrs.styles[sindex][key].isButton == false" v-model="$attrs.styles[sindex][key].value" :options="$attrs.styles[sindex][key].options" @input="$attrs.styles[sindex][key].active = true" >
    								</b-form-radio-group>
    							</div>
    							<div class="" v-else-if="$attrs.styles[sindex][key].type == 'background'">
    								<b-form-radio-group :button-variant="'outline-'+activeVariant.variant" buttons v-model="$attrs.styles[sindex][key].value" :options="$attrs.styles[sindex][key].options" >
    								</b-form-radio-group>

    								<b-form-group v-if="$attrs.styles[sindex][key].value[0] == 'background-image'">
    									  <div class="bg-light mt-2 d-flex position-relative" style="min-height: 150px"  @mouseenter="imghoverElement = 1" @mouseleave="imghoverElement = ''">
    									  <a v-if="$attrs.styles[sindex][key].value[1] == ''" class="btn btn-primary w-75 m-auto" href="javascript:;"  @click="fileResourceKey = key, fileResourceIndex = sindex, showFileManager = !showFileManager">Select File</a>
                         				  <img v-else :src="$attrs.styles[sindex][key].value[1]" class="w-75 m-auto">
                         				  <div v-if="(imghoverElement == 1) && ($attrs.styles[sindex][key].value[1] !== '')" class=" bg-secondary bg-soft mx-auto w-75 position-absolute top-0 right-0 left-0 bottom-0 d-flex flex-column justify-content-center align-items-center">
                         				  	<a class="btn btn-primary w-75 m-auto mb-1" href="javascript:;"  @click="fileResourceKey = key, fileResourceIndex = sindex, showFileManager = !showFileManager">Select File</a>
                         				  	<a class="btn btn-danger w-75 m-auto mt-1" href="javascript:;"  @click="$attrs.styles[sindex][key].value[1] = '', imghoverElement = ''">Remove File</a>

                         				  </div>
                         				</div>
    								</b-form-group>
    								 <div v-if="$attrs.styles[sindex][key].value[0] == 'background-image'" class="bg-light mb-3 d-flex flex-column px-4 pb-4" style="min-height: 150px">
    								<b-form-group class="w-100 mb-2" v-if="$attrs.styles[sindex][key].value[0] == 'background-image'" label="Size">
    									<b-form-select class="form-control text-capitalize" v-model="$attrs.styles[sindex][key].size" :options="$attrs.styles[sindex][key].image_size_options">
    									</b-form-select>
    								</b-form-group>

    								<b-form-group class="w-100 mb-2" v-if="$attrs.styles[sindex][key].value[0] == 'background-image'" label="Position">
    									<b-form-select class="form-control text-capitalize" v-model="$attrs.styles[sindex][key].position" :options="$attrs.styles[sindex][key].image_position_options">
    									</b-form-select>
    								</b-form-group>

    								<b-form-group class="w-100 mb-2" v-if="$attrs.styles[sindex][key].value[0] == 'background-image'" label="Repeat">
    									<b-form-select class="form-control text-capitalize" v-model="$attrs.styles[sindex][key].repeat" :options="$attrs.styles[sindex][key].image_repeat_options">
    									</b-form-select>
    								</b-form-group>
    								<b-form-group class="w-100" v-if="($attrs.styles[sindex][key].value[0] == 'background-image') && ($attrs.styles[sindex][key].size == 'custom')" label="Custom Size">
    								<vue-slide-bar :processStyle="{'backgroundColor' : activeVariant.color+' !important'}" :tooltipStyles="{'backgroundColor': activeVariant.color+' !important', 'borderColor': activeVariant.color+' !important'}" v-model="$attrs.styles[sindex][key].custom_size" class="w-100 mt-1 pt-4" style="min-height: unset;" :min="0" :max="500" />
    								</b-form-group>

    								</div>
    								<b-form-group v-if="$attrs.styles[sindex][key].value[0] == 'background-color'" id="color"
					                   
					                    >
					                      <div class="bg-light mt-2 mb-3 d-flex flex-column justify-content-center p-4" style="min-height: 150px">
					                     <label>Select Color:</label>
                    					<b-form-input id="color" type="color" name="color" v-model="$attrs.styles[sindex][key].value[1]"></b-form-input>
                    					<label>Default Swatches:</label>
                    					<b-form-checkbox-group id="default-colors" class="d-inline" buttons v-model="$attrs.styles[sindex][key].value[1]"  button-variant="white" @input="recentlyUsedSwatch($event)">
                    						<b-form-checkbox v-for="color in colors.default" :value="color" :style="{'background': color}" style="; width: 25px; height: 25px" class="border border-dark" >
                    							
                    						</b-form-checkbox>
                    					</b-form-checkbox-group>
                    					<label>Recently Used:</label>
                    					<b-form-checkbox-group id="recent-colors" class="d-inline" buttons v-model="$attrs.styles[sindex][key].value[1]"  button-variant="white" >
                    						<b-form-checkbox v-for="color in colors.recent.slice(0, recent_colors_end)" :value="color" :style="{'background': color}" style="; width: 25px; height: 25px" class="border border-dark">
                    							
                    						</b-form-checkbox>
                    					
                    							<b-button variant="white" v-if="colors.recent.length > 11" style="; width: 25px; height: 25px" class="border border-dark" @click="recent_colors_end = (colors.recent.length - 1)">
                    							<i class="mdi mdi-plus"></i>
                    							</b-button>
                    						
                    					</b-form-checkbox-group>
                    					<label>Saved Swatches:</label>
                    					<b-form-checkbox-group id="saved-colors" class="d-inline" buttons v-model="$attrs.styles[sindex][key].value[1]"  button-variant="white" >
                    						<b-form-checkbox style="; width: 25px; height: 25px" class="border border-dark">
                    							<i class="mdi mdi-plus"></i>
                    						</b-form-checkbox>
                    						<b-form-checkbox v-for="color in colors.saved" :value="color" :style="{'background': color}" style="; width: 25px; height: 25px" class="border border-dark">
                    							
                    						</b-form-checkbox>
                    					</b-form-checkbox-group>
                    				</div>
    								</b-form-group>
    							</div>

								<b-form-input v-else-if="($attrs.styles[sindex][key].type !== 'hidden') && ($attrs.styles[sindex][key].type !== 'label')" class="w-100 mt-2"  :type="$attrs.styles[sindex][key].type" v-model="$attrs.styles[sindex][key].value"></b-form-input>
								</b-form-group>
						</b-list-group-item>
					</b-list-group>
				</b-card>
				<ModalWindow :editModal="showFileManager" :panelClass="'product-modal d-flex flex-column justify-content-center'" @close-modal="showFileManager = !showFileManager" :formReponse="''">
			        <StorageWindow :user="$attrs.user" :company="$attrs.company" @close-window="showFileManager = false" @file-selected="fileSelected" :selectedFiles="selectedFiles" ></StorageWindow>
			      </ModalWindow>
			</div>
		</b-tab>
</template>

<script type="text/javascript">
	import VueSlideBar from "vue-slide-bar";
	import ModalWindow from "../../../../components/widgets/ModalWindow";
	import StorageWindow from "../../../../components/widgets/file-manager/StorageWindow";
	export default{
		name: 'stylesTab',
		components: { VueSlideBar, ModalWindow, StorageWindow },
		data() {
			return {
				fileResourceIndex: 0,
				imghoverElement: '',
				storageurl: 'https://'+this.$attrs.company.nickname+'.s3.eu-west-2.amazonaws.com',
				variants: [
					{element: 'column', variant: 'info', color: '#50a5f1'},
					{element: 'section', variant: 'success', color: '#34c38f'},
					{element: 'row', variant: 'primary', color: '#5E3BB0'},
				],
				fileResourceKey: '',
				recent_colors_end: 11,
				colors: {
					default: ['#4b2f8d', '#c35555', '#34c38f', '#f1b44c', '#ffffff', '#000000'],
					recent: [],
					saved: [],
				},
				sidePanelMaxHeight: 420,
				showFileManager: false,
				selectedFiles: [],
				colrange: [1,2,3,4,5,6,7,8,9,10,11,12],
				collabels: [
		          {
		            label: "1"
		          },
		          {
		            label: "2"
		          },
		          {
		            label: "3"
		          },
		          {
		            label: "4"
		          },
		          {
		            label: "5"
		          },
		          {
		            label: "6"
		          },
		          {
		            label: "7"
		          },
		          {
		            label: "8"
		          },
		          {
		            label: "9"
		          },
		          {
		            label: "10"
		          },
		          {
		            label: "11"
		          },
		          {
		            label: "12"
		          },
		        ]
			}
		},
		props: {
			element: {
				type: String,
				default: 'row'
			},
			widgetbox: {
				type: Boolean,
				default: true,
			}
		},
		computed:{
			activeVariant(){
				var active = this.variants.filter(item => item.element == this.element);
				return active[0];
			},
		},
		created(){
			window.addEventListener("resize", this.windowResize);
		},
		destroyed() {
            window.removeEventListener("resize", this.windowResize);
        },
		mounted(){
        	this.sidePanelMaxHeight = window.innerHeight - 80;
        },
		methods: {
			windowResize(e) {
            console.log(window.innerHeight);
    
            this.sidePanelMaxHeight = window.innerHeight - 80;
   
           
          	},

			fileSelected(payload){
				if(this.fileResourceKey == 'background'){
					this.$attrs.styles[this.fileResourceIndex][this.fileResourceKey].value[1] = ''+this.storageurl+''+payload.file+'';
				}
				this.showFileManager = !this.showFileManager;
			},
			recentlyUsedSwatch(color){
				var index = this.colors.recent.findIndex(item => item == color);
				if(index == -1){
					this.colors.recent.unshift(color);
					localStorage.setItem('recentColors', JSON.stringify(this.colors.recent));
				}else{
					this.colors.recent.splice(index, 1);
					this.colors.recent.unshift(color);
					localStorage.setItem('recentColors', JSON.stringify(this.colors.recent));
				}
			}
		}
	}

</script>