<script>
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import VueSlideBar from "vue-slide-bar";
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
import CompanyProductSearch from '../../components/widgets/CompanyProductSearch';
import CompanyProductList from '../../components/widgets/CompanyProductList';
import SideSlidePanel from "../../components/widgets/SideSlidePanel";
import FilterListTree from "../../components/widgets/FilterListTree";
import SelectCompanyCategoryTree from "../../components/widgets/SelectCompanyCategoryTree";

export default{
  components:{VueSlideBar, Layout, PageHeader, SideSlidePanel, ckeditor: CKEditor.component, SelectCompanyCategoryTree, FilterListTree},
  data() {
      return {
          quickfilter_options: [
              {text: 'My Categories', value: 'company'},
              {text: 'Public Market', value: 'public'},
              {text: 'Trade Market', value: 'trade'},
          ],
          marketplace_categories: 'company',
          editor: ClassicEditor,
          brandsData: [],
          categoryProducts: [],
          selectedCategory: [],
          brands_error: [],
          products_error: [],
          error_message: false,
          error_reponse: [],
          success_message: false,
          loadingForm: false,
          success_text: '',
          view: 'list',
          search: '',
          limit: '25',
          sortby: 'id',
          order: 'desc',
          selectedids: [],
          addNewPanel: 0,
          isCheckAll: false, 
          getProductsPanel: 0,
          newcategory: {
            name: '',
            description: '',
            image: '',
            cover_image: '',
            order: '',
            slug: '',
            active: 0,
          },
          editcategory: {
            name: '',
            description: '',
            image: '',
            cover_image: '',
            order: '',
            slug: '',
            active: 0,
          },
          editPanel: false,
          expandable: 1,
          addPanelClass: 'edit-sidepanel',
          selected_parent: {id: null},
          limits: [
            {value: 25, text: "25"},
            {value: 50, text: "50"},
            {value: 100, text: "100"},
          ],
          all_categories: [],
          title: "Categories",
          items: [
            {
              text: "Stock",
              href: "javascript:;",
            },
            {
              text: "Brands",
              active: true,
            },
          ],
          } 
  },
  methods:{
    brandQuery(){
      axios.get('/api/company/service/categories?page='+this.currentPage+'&limit='+this.limit+'&sortby='+this.sortby+'&order='+this.order+'&search='+this.search+'&parent_id='+this.selected_parent.id+'&marketplace_categories='+this.marketplace_categories, {headers: {
                  'Authorization': 'Bearer ' + this.$attrs.user.api_token
              }}).then(response => {
        this.brandsData = response.data;
      }).catch(error => {
        this.brands_error = error.data;
      });
    },
    getAllCategories(){
      axios.get('/api/company/service/categories?page='+this.currentPage+'&limit='+this.limit+'&sortby='+this.sortby+'&order='+this.order+'&search='+this.search+'&parent_id='+this.selected_parent.id+'&marketplace_categories='+this.marketplace_categories, {headers: {
                  'Authorization': 'Bearer ' + this.$attrs.user.api_token
              }}).then(response => {
        this.all_categories = response.data;
      }).catch(error => {
        this.brands_error = error.data;
      });
    },
    getProducts(category_id){
      axios.get('/api/company/product/category/'+category_id).then(response => {
        this.categoryProducts = response.data;
      }).catch(error => {
        this.products_error = error.data;
      });
    },
    priceIncVat(price, tax_percentage){
      var tax = 1 + (tax_percentage / 100);
      return price * tax;
    },
    addCategory(){
      this.loadingForm = true;
      var postForm = new FormData(document.getElementById("addcategoryform"));
      axios.post('/api/company/service/category/create', postForm, {headers: {
                  'Authorization': 'Bearer ' + this.$attrs.user.api_token
              }}).then(response => {
        console.log(response);
        this.success_text = 'Congratulations, your new category has been added.';
        this.success_message = true;
        //this.resetProjectData();
        this.addPanel = false;
        let self = this;
          setTimeout(function(){
            self.loadingForm = false;
            self.brandQuery();
            self.getAllCategories();
          }, 1500);
      }).catch( error => {
        
        this.error_reponse = error.response.data.errors;
        this.error_message = true;
        //this.resetProjectData();
        let self = this;
          setTimeout(function(){
            self.loadingForm = false;
          }, 1500);
      });
    },
    updateCategory(){
      this.success_message = false;
      this.error_message = false;
      this.loadingForm = true;
      var postForm = new FormData(document.getElementById("updatecatform"));
      axios.post('/api/company/service/category/'+this.editcategory.id+'/update?api_token='+this.$attrs.user.api_token+'', postForm).then(response => {
        console.log(response);
        this.success_text = 'Congratulations, your category has been updated.';
        this.success_message = true;
        //this.resetProjectData();
        this.addPanel = false;
        let self = this;
          setTimeout(function(){
            self.loadingForm = false;
            self.brandQuery();
            self.getAllCategories();
          }, 1500);
      }).catch( error => {
        
        this.error_reponse = error.response.data.errors;
        this.error_message = true;
        //this.resetProjectData();
        let self = this;
          setTimeout(function(){
            self.loadingForm = false;
          }, 1500);
      });

    },
    editParentCategory(id){
      this.editcategory.parent_id = id;
    },
    addParentCategory(id){
      this.newcategory.parent_id = id;

    },
    filterSelected(id){
        if(this.selectedids.length > 0){
          var exists = this.selectedids.filter(item => item == id);
          if(exists.length > 0){
            return true;
          }else{
            return false
          }
        }else{
          return false;
        }
      },
      checkAll: function(){ 
                 this.isCheckAll = !this.isCheckAll; 
                 this.selectedids = []; 
                 if(this.isCheckAll){ 
                     // Check all 
                     for (var key in this.brandsData.data) {          
                        this.selectedids.push(this.brandsData.data[key].id); 
                     } 
                 } 
              }, 
      updateCheckall: function(){ 
                 if(this.selectedids.length == this.brandsData.data.length){
                    this.isCheckAll = true; 
                 }else{ 
                    this.isCheckAll = false; 
                 } 
      },
      sanitizeTitle: function(title) {
            var nickname = "";
            // Change to lower case
            var titleLower = title.toLowerCase();
            // Letter "e"
            nickname = titleLower.replace(/e|é|è|ẽ|ẻ|ẹ|ê|ế|ề|ễ|ể|ệ/gi, 'e');
            // Letter "a"
            nickname = nickname.replace(/a|á|à|ã|ả|ạ|ă|ắ|ằ|ẵ|ẳ|ặ|â|ấ|ầ|ẫ|ẩ|ậ/gi, 'a');
            // Letter "o"
            nickname = nickname.replace(/o|ó|ò|õ|ỏ|ọ|ô|ố|ồ|ỗ|ổ|ộ|ơ|ớ|ờ|ỡ|ở|ợ/gi, 'o');
            // Letter "u"
            nickname = nickname.replace(/u|ú|ù|ũ|ủ|ụ|ư|ứ|ừ|ữ|ử|ự/gi, 'u');
            // Letter "d"
            nickname = nickname.replace(/đ/gi, 'd');
            // Trim the last whitespace
            nickname = nickname.replace(/\s*$/g, '');
            // Change whitespace to "-"
            nickname = nickname.replace(/\s+/g, '-');
            // Remove quotations
            nickname = nickname.replace(/["']/g, "");
            // Remove symbols
            nickname = nickname.replace(/@|#|!/g, '');
            return nickname;
        }
  },
  created(){
    this.brandQuery();
    this.getAllCategories();
  }
}
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
        <div class="row">
      <div class="col-md-12">
         <div class="row mb-3">
          <div class="col-xl-1 col-sm-6">
            <div class="mt-2">
              <b-form-select class="form-control" v-model="limit" :options="limits" @input="brandQuery()"></b-form-select>
            </div>
          </div>
         
          <div class="col-lg-2 col-sm-6 d-flex">
          <form class="my-auto">
            <small class="mx-auto my-2 text-center d-block">Results {{brandsData.from}} - {{brandsData.to}} of {{brandsData.total}}</small>
          </form>
        </div>
        <div class="col-lg-3">
          <b-form-group class="my-auto ml-auto mr-3">
            <b-form-radio-group id="btn-radios-1" button-variant="outline-light"
        size="md" v-model="marketplace_categories" :options="quickfilter_options" buttons @input="brandQuery()"></b-form-radio-group>
      
          
          </b-form-group>
        </div>
        <div class="col-lg-3 col-sm-6 d-flex">
          
            <div class="mt-sm-0 form-inline w-100" >
              <div class="search-box mr-2 ml-auto">
                <div class="position-relative">
                  <input
                    type="text"
                    class="form-control w-100 border-0"
                    placeholder="Search name, reference or description..."
                    v-model="search"
                    @keyup.enter="brandQuery()"
                  />
                  <i class="bx bx-search-alt search-icon"></i>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-sm-6 d-flex">
            <div class="d-flex ml-auto">
              <ul class="nav nav-pills product-view-nav">
                
                <li class="nav-item">
                  <a class="nav-link" :class="{'active': view == 'grid' }" href="javascript: void(0);" @click="view = 'grid'">
                    <i class="bx bx-grid-alt"></i>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" :class="{'active': view == 'list' }" href="javascript: void(0);" @click="view = 'list'">
                    <i class="bx bx-list-ul"></i>
                  </a>
                </li>
                <li class="nav-item">
                   <b-dropdown text="Bulk Actions" variant="primary" class="rounded">
                  <b-dropdown-item href="javascript: void(0);" :disabled="($attrs.user.companies[0].userrole.name !== 'Owner') && !$attrs.user.companies[0].permissions.edit.includes($attrs.active_menu.id)" @click="setActive(true)">Set Active</b-dropdown-item>
                  <b-dropdown-item href="javascript: void(0);" :disabled="($attrs.user.companies[0].userrole.name !== 'Owner') && !$attrs.user.companies[0].permissions.edit.includes($attrs.active_menu.id)" @click="setActive(false)">Set Inactive</b-dropdown-item>
                  <b-dropdown-item href="javascript: void(0);" :disabled="($attrs.user.companies[0].userrole.name !== 'Owner') && !$attrs.user.companies[0].permissions.edit.includes($attrs.active_menu.id)" @click="bulkDuplicateResource()">Duplicate</b-dropdown-item>
                  <b-dropdown-item href="javascript: void(0);" v-b-modal.move-modal @click="moveMode = 'bulk'" :disabled="($attrs.user.companies[0].userrole.name !== 'Owner') && !$attrs.user.companies[0].permissions.edit.includes($attrs.active_menu.id)">Move Category</b-dropdown-item>
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-item :disabled="($attrs.user.companies[0].userrole.name !== 'Owner') && !$attrs.user.companies[0].permissions.delete.includes($attrs.active_menu.id)" href="javascript: void(0);" v-b-modal.delete-modal @click="deleteMode = 'bulk'">Delete</b-dropdown-item>
                </b-dropdown>
               
                </li>
                <li class="nav-item">
                 <b-button
                    class="btn btn-success btn-rounded mb-2 mr-2 text-white"
                    href="javascript:;"
                    :disabled="(($attrs.user.companies[0].userrole.name !== 'Owner') && !$attrs.user.companies[0].permissions.add.includes($attrs.active_menu.id)) || (marketplace_categories !== 'company')"
                    @click="addNewPanel = !addNewPanel"
                  >
                    <i class="mdi mdi-plus mr-1"></i> New
                  </b-button>
                </li>
              </ul>
              <div class="text-sm-right">
                  
                </div>
          </div>
        </div>
      </div>
      <div class="d-flex flex-column justify-content-center h-100 restricted-col" v-if="($attrs.user.companies[0].userrole.name !== 'Owner') && !$attrs.user.companies[0].permissions.read.includes($attrs.active_menu.id)" >
          <AccessRestricted :page="false"></AccessRestricted>
        </div>
        <div v-else>
        <div class="card" v-if="view == 'list'" >
          <div class="card-body p-0">
            <div class="col-md-12 bg-light">
                 <tr class="d-flex w-100 bg-light" v-if="(selected_parent.name != '') && (selected_parent.name != null)">
                    <a v-if="selected_parent.parent != null" class="btn btn-primary btn-soft btn-rounded mx-4 my-3" @click="selected_parent = selected_parent.parent, brandQuery()"><i class="bx bx-chevron-left float-left font-size-16  px-1 py-2"></i></a>
                    <a v-if="selected_parent.parent == null" class="btn btn-primary btn-soft btn-rounded mx-4 my-3" @click="selected_parent.id = selected_parent.parent_id, selected_parent.name = null, brandQuery()"><i class="bx bx-chevron-left float-left font-size-16  px-1 py-2"></i></a>
                    <h5 class="float-right mx-2 my-auto p-1">Categories in: {{selected_parent.name}}</h5> 
                  </tr>
                    <tr v-else  class="d-flex w-100 bg-light">
                      <a class="btn btn-primary btn-soft btn-rounded mx-4 my-3"><i class="bx bx-home float-left font-size-16  px-1 py-2"></i></a> 
                      <h5 class="float-right mx-2 my-auto p-1">Root</h5> 
                    </tr>

                </div>
            <div class="table-responsive">
              <table class="table table-centered table-hover">

                <thead class="thead-light">
                  <tr>
                    <th scope="col"  style="width: 100px" class="text-center"><div
                        class="form-check form-switch form-switch-sm mb-0 mx-auto p-0 mt-auto d-flex"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input mx-auto my-auto" 
                          type="checkbox"
                          id="SwitchCheckSizesm"
                         
                          v-model="isCheckAll"
                          @click='checkAll()'
                          name="active"
                        />
                    </div></th>
                    <th scope="col"><a :class="{'text-dark': sortby != 'name'}" href="javascript: void(0);" @click="orderDesc = !orderDesc,  sortby = 'name', brandQuery()">Name & Description <i v-if="orderDesc == true" class="bx bx-caret-up"></i><i v-if="orderDesc == false" class="bx bx-caret-down"></i></a></th>
                    
                    <th scope="col">Products</th>
                    <th scope="col">Sub Categories</th>
                    <th scope="col">Status</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
               
                <tbody>
                  
                  <tr v-for="list in brandsData.data" :key="list.id" valign="middle">
                    <td>
                      <div class="text-center" v-if="list.image">
                      <b-form-checkbox class="select-row-btn" v-model="selectedids" :value="list.id" button-variant="primary" @input="updateSelected" button>
                        <i v-if="filterSelected(list.id)" class="bx bx-check"></i>
                        <img v-else :src="`https://gemesys-dev.s3.eu-west-2.amazonaws.com/${list.image}`" alt class="avatar-sm rounded-circle" />
                      </b-form-checkbox>
                    </div>
                    <div class="text-center" v-else >
                        <b-form-checkbox class="select-row-btn" v-model="selectedids" :value="list.id" button-variant="primary" @input="updateSelected" button>

                          <i v-if="filterSelected(list.id)" class="bx bx-check"></i><span class="avatar-title rounded-circle" v-else>{{(list.name).slice(0,1)}}</span></b-form-checkbox>
                    </div> 
                    </td>
          
                    <td>
                        <h5 class="m-0">{{list.name}}</h5>
                     <small class="text-muted mb-0 list-description" v-if="list.short_description != null" v-html="(list.short_description).slice(0, 200)+'...'"></small>
                    </td>
                    <td><a href="javascript:;" @click="selectedCategory = list, getProducts(list.id), getProductsPanel = 1" class="btn btn-outline-light btn-rounded"><i class="bx bx bx-purchase-tag-alt"></i></a></td>
                    <td>
                      <a v-if="list.childs.length !== 0" href="javascript:;" @click="selected_parent = list, brandQuery()" class="btn btn-light btn-rounded"><i class="bx bx-right-arrow-alt text-primary py-1"></i></a>
                    </td>
                    <td>

                      <div
                        class="form-check form-switch form-switch-sm mb-0 mt-auto"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          id="SwitchCheckSizesm"
                          v-model="list.active"
                          :disabled="(($attrs.user.companies[0].userrole.name !== 'Owner') && !$attrs.user.companies[0].permissions.edit.includes($attrs.active_menu.id)) || (marketplace_categories !== 'company')"
                          value="1"
                          unchecked-value="0"
                          name="active"
                        />
                        <label class="form-check-label" for="SwitchCheckSizesm" 
                          >Active</label
                        >
                    </div>
                    </td>
                    <td>
                      <ul class="list-inline font-size-20 contact-links mb-0">
                        <li class="list-inline-item px-2" v-if="(($attrs.user.companies[0].userrole.name == 'Owner') && $attrs.user.companies[0].permissions.edit.includes($attrs.active_menu.id)) && (marketplace_categories == 'company')"><a v-b-tooltip.hover title="Edit" placement="left" @click="editcategory = list, editPanel = true"><i class="bx bx-edit-alt"></i>
                          </a>
                        </li>
                        <li class="list-inline-item px-2" v-if="(($attrs.user.companies[0].userrole.name == 'Owner') && $attrs.user.companies[0].permissions.delete.includes($attrs.active_menu.id)) && (marketplace_categories == 'company')"><a v-b-tooltip.hover title="Delete" placement="left" @click="editcategory = list, editPanel = true"><i class="bx bx-trash-alt"></i>
                          </a>
                        </li>
                        <li class="list-inline-item px-2">
                           <b-dropdown v-b-tooltip.hover title="More Options" placement="left"dropleft class="more-options-dropdown">
                        <template v-slot:button-content>
                           <i class="bx bx-dots-horizontal-rounded"></i>
                        </template>
                        <b-dropdown-item href="javascript: void(0);" :disabled="($attrs.user.companies[0].userrole.name !== 'Owner') && !$attrs.user.companies[0].permissions.edit.includes($attrs.active_menu.id)" @click="selectedids = [], selectedids.push(list.id), bulkDuplicateResource()">Duplicate</b-dropdown-item>
                        <b-dropdown-item href="javascript: void(0);" :disabled="($attrs.user.companies[0].userrole.name !== 'Owner') && !$attrs.user.companies[0].permissions.edit.includes($attrs.active_menu.id)" v-b-modal.move-modal @click="editcategory = JSON.parse(JSON.stringify(list)), moveMode = 'single'">Move Category</b-dropdown-item>
                      </b-dropdown>
                        </li>
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="row">
              <div class="col-lg-12">
                <b-pagination
              v-if="brandsData"
              class="justify-content-center"
              pills
              v-model="currentPage"
              :total-rows="brandsData.total"
              :per-page="brandsData.per_page"
              aria-controls="my-table"
              @change="brandQuery"
            ></b-pagination>
             <small class="mx-auto my-2 text-center d-block">Records {{brandsData.from}} - {{brandsData.to}} of {{brandsData.total}} // Page {{brandsData.current_page}} of {{brandsData.last_page}}</small>
              </div>
            </div>
          </div>
      </div>
    <div class="row" v-if="view == 'grid'" >
      <div class="col-md-12">
        <div class="card">
                  <tr v-if="(selected_parent.name != '') && (selected_parent.name != null)">
                    <a v-if="selected_parent.parent != null" class="btn btn-outline-light btn-rounded m-3" @click="selected_parent = selected_parent.parent, brandQuery()"><i class="bx bxs-caret-left-circle float-left"></i></a>
                    <a v-if="selected_parent.parent == null" class="btn btn-outline-light btn-rounded m-3" @click="selected_parent.id = selected_parent.parent_id, selected_parent.name = null, brandQuery()"><i class="bx bxs-caret-left-circle float-left"></i></a> <h5 class="float-right m-3 p-1">{{selected_parent.name}}</h5> 
                  </tr>
                    <tr v-else>
                      <a class="btn btn-outline-light btn-rounded m-3"><i class="bx bxs-home float-left"></i></a> <h5 class="float-right m-3 p-1">Root</h5> 
                    </tr>
                  </div>
                </div>
      <div class="col-xl-4"  v-for="list in brandsData.data" :key="list.id">
        <div class="card">
          <img v-if="list.logo" class="card-img-top" :src="`/public/storage/${list.logo}`" alt />
          <img v-else class="card-img-top" src="/images/labradorite-gemstone.jpg" alt />
          <div class="card-body pt-0">
            <div class="row">
              <div class="col-sm-12">
                <div class="avatar-sm profile-user-wid float-left">
                   <div class="text-center" v-if="list.image">
                      <b-form-checkbox class="select-row-btn" v-model="selectedids" :value="list.id" button-variant="primary" @input="updateSelected" button>
                        <i v-if="filterSelected(list.id)" class="bx bx-check"></i>
                        <img v-else :src="`https://gemesys-dev.s3.eu-west-2.amazonaws.com/${list.image}`" alt class="avatar-sm rounded-circle" />
                      </b-form-checkbox>
                    </div>
                    <div class="text-center" v-else >
                        <b-form-checkbox class="select-row-btn" v-model="selectedids" :value="list.id" button-variant="primary" @input="updateSelected" button>

                          <i v-if="filterSelected(list.id)" class="bx bx-check"></i><span class="avatar-title rounded-circle" v-else>{{(list.name).slice(0,1)}}</span></b-form-checkbox>
                    </div> 
                </div>
                <div class="avatar-sm profile-user-wid float-right">
                    <div class="text-center" >
                        <a class="btn btn-light text-primary btn-rounded" v-if="list.childs.length !== 0" href="javascript:;"   @click="selected_parent = list, brandQuery()" >
                          <i class="bx bx-right-arrow-alt font-size-20 py-1"></i>
                          </a>
                    </div> 
                </div>
                <h5 class="font-size-15 text-truncate w-100 mt-4">{{list.name}}</h5>
                <p class="card-text">{{list.description}}</p>
                <div class="mt-4">
                    
                  </div>
              </div>
              </div>
          </div>
             <div class="card-footer bg-transparent border-top">
            <div class="contact-links d-flex font-size-20">
              <!--<div class="flex-fill text-center">
                <a v-if="list.childs.length !== 0" href="javascript:;" class="text-primary"  @click="selected_parent = list, brandQuery()">
                      <i class="bx bx-list-ul"></i>
                    </a>
              </div>-->
              <div class="flex-fill text-center">
                    <a v-b-tooltip.hover title="Products" href="javascript:;" @click="selectedCategory = list, getProducts(list.id), getProductsPanel = 1"><i class="bx bx bx-purchase-tag-alt"></i></a>
              </div>
              <div class="flex-fill text-center">
                 <a v-b-tooltip.hover title="Edit" v-if="($attrs.user.companies[0].userrole.name == 'Owner') || $attrs.user.companies[0].permissions.edit.includes($attrs.active_menu.id)" placement="left" @click="editcategory = list, editPanel = true"><i class="bx bx-edit-alt"></i>
                          </a>
              </div>
              <div class="flex-fill text-center" v-if="($attrs.user.companies[0].userrole.name == 'Owner') || $attrs.user.companies[0].permissions.delete.includes($attrs.active_menu.id)">
                          <a v-b-tooltip.hover title="Delete">
                            <i class="bx bx-trash-alt"></i>
                          </a>
              </div>
            </div>
          </div>
        </div>
    </div>
      </div>
    </div>
    </div>
</div>
  <SideSlidePanel :editPanel="getProductsPanel" :panelClass="'edit-sidepanel'" @close-panel="getProductsPanel = 0">
    <div class="row">
        
        <div class="col-md-12">
        <b-card>
          <h4 class="my-3 pb-2">Services in <strong class="text-primary">{{selectedCategory.name}}</strong> <a v-if="($attrs.user.companies[0].userrole.name == 'Owner') && $attrs.user.companies[0].permissions.edit.includes($attrs.active_menu.id)" id="tooltip-add" class="btn btn-outline-light font-size-16 btn-rounded float-right"><i class="mdi mdi-plus"></i></a><b-tooltip target="tooltip-add">Add Service</b-tooltip></h4>
          <b-list-group>
            <b-list-group-item href="#some-link" v-for="product in categoryProducts.data">
              <img :src="'/public/storage/'+product.cover_image" width="85" class="float-left mr-4"> 
            <strong class="text-muted">{{product.name}}</strong><a id="tooltip-remove" class="btn btn-rounded btn-outline-light  float-right font-size-16 my-4 mx-1"><i class="bx bx-trash"></i></a>
            <b-tooltip target="tooltip-remove">Remove from Category</b-tooltip>
            <a id="tooltip-edit" class="btn btn-rounded btn-outline-light  float-right font-size-16 my-4 mx-1"><i class="bx bx-pencil"></i></a>
            <b-tooltip target="tooltip-edit">Edit Product</b-tooltip>
            <small class="text-muted italic no-margin" v-html="product.short_description"></small>
            <strong class="text-muted">£{{product.sales_price}} <small class="mr-3">exc. VAT</small>   £{{priceIncVat(product.sales_price, product.tax_rule.percentage)}} <small class="mr-3">inc. VAT</small> £{{product.trade_price}} <small class="mr-3">Trade Price</small></strong>

            </b-list-group-item>
          </b-list-group>
        </b-card>
        </div>
    </div>
  </SideSlidePanel>

     <SideSlidePanel :editPanel="addNewPanel" :panelClass="addPanelClass" @close-panel="addNewPanel = !addNewPanel" :expandable="1"  @expand="addPanelClass = 'edit-sidepanel-large'" @contract="addPanelClass = 'edit-sidepanel'">
      <div class="row">
        
        <div class="col-md-12">
        <b-card class="shadow-none">
          <h4 class="my-3">Add New Category</h4>
          <b-overlay
                        id="overlay-background"
                        :show="loadingForm"
                        variant="white"
                        opacity="0.85"
                        blur="1px"
                        rounded="md"
                      >
         <b-form id="addcategoryform" method="POST" enctype="multipart/form-data">
                   <input type="hidden" name="_token" :value="csrf">
           <b-row>
                     <b-col md="12">
                    <transition name="slide-error" mode="out-in">
                    <b-alert id="errors" variant="danger" :show="error_message"  dismissible @dismissed="error_message = !error_message, loadingForm = false">
                      <p class="font-size-14">Oops! Please correct the following errors in your submission</p>
                      <ul>
                        <li v-for="(error, key) in error_reponse">
                          {{error[0]}}
                        </li>
                      </ul>
                    </b-alert>
                    
                  </transition>
                  </b-col>
          </b-row>
               <b-form-group label="Name">
            <b-form-input type="text" name="name" v-model="newcategory.name"></b-form-input>
          </b-form-group>
          <input type="hidden" name="slug" :value="sanitizeTitle(newcategory.name)">
           <b-form-group class="mt-2" label="Description">
            <input type="hidden" name="description" :value="newcategory.description">
            <b-textarea rows="6" v-model="newcategory.description" :editor="editor"></b-textarea>
          </b-form-group>
          <b-form-group class="mt-2" label="Image">
            <input class="form-control mb-2" name="image" type="file">
          </b-form-group>
          <b-form-group class="mt-2" label="Cover Image">
            <input class="form-control mb-2" name="cover_image" type="file">
          </b-form-group>
          <p class="mt-3">Parent Category</p>
          <!--<FilterListTree :category_data="brandsData.data"></FilterListTree>-->
          <input type="hidden" name="parent_id" :value="newcategory.parent_id">
          <div class="border border-radius-1em">
            <SelectCompanyCategoryTree :selectedid="newcategory.parent_id" :category_data="all_categories.data" @category-select="addParentCategory"></SelectCompanyCategoryTree>
          </div>
          <b-form-group class="my-3">
                     <div
                        class="form-check form-switch form-switch-sm mb-0 mt-auto"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          id="newbrand-owner"
                          v-model="newcategory.active" value="true"
                          unchecked-value="false"
                        />
                        <label class="form-check-label" for="newbrand-owner" 
                          > Active</label
                        >
                    </div>
          </b-form-group>
          <input type="hidden" name="active" :value="newcategory.active">
          <div class="d-flex flex-row mt-3">
          <b-button variant="success" class="w-md w-100" @click="addCategory()"> <i class=" bx bx-save"></i> Save</b-button>
          </div>
        </b-form>
      </b-overlay>
        </b-card>
        </div>
      </div>
    </SideSlidePanel> 
      <SideSlidePanel :editPanel="editPanel" :panelClass="addPanelClass" @close-panel="editPanel = !editPanel"  :expandable="1"  @expand="addPanelClass = 'edit-sidepanel-large'" @contract="addPanelClass = 'edit-sidepanel'">
      <div class="row">
        
        <div class="col-md-12">
        <b-card class="shadow-none">
          <h4 class="my-3">Edit Category: {{editcategory.name}}</h4>
           <b-overlay
                        id="overlay-background"
                        :show="loadingForm"
                        variant="white"
                        opacity="0.85"
                        blur="1px"
                        rounded="md"
                      >
         <b-form id="updatecatform" method="POST" enctype="multipart/form-data">
                   <input type="hidden" name="_token" :value="csrf">
           <b-row>
                     <b-col md="12">
                    <transition name="slide-error" mode="out-in">
                    <b-alert id="errors" variant="danger" :show="error_message"  dismissible @dismissed="error_message = !error_message, loadingForm = false">
                      <p class="font-size-14">Oops! Please correct the following errors in your submission</p>
                      <ul>
                        <li v-for="(error, key) in error_reponse">
                          {{error[0]}}
                        </li>
                      </ul>
                    </b-alert>
                    
                  </transition>
                  </b-col>
          </b-row>
         <b-form-group label="Name">
            <b-form-input type="text" name="name" v-model="editcategory.name"></b-form-input>
          </b-form-group>
          <input type="hidden" name="slug" :value="sanitizeTitle(editcategory.name)">
           <b-form-group class="mt-2" label="Description">
            <input type="hidden" name="description" :value="editcategory.description">
            <b-textarea rows="6" v-model="editcategory.description" :editor="editor"></b-textarea>
          </b-form-group>
          <b-form-group class="mt-2" label="Image">
            <input class="form-control mb-2" name="image" type="file">
          </b-form-group>
          <b-form-group class="mt-2" label="Cover Image">
            <input class="form-control mb-2" name="cover_image" type="file">
          </b-form-group>
          <p class="mt-3">Parent Category</p>
          <!--<FilterListTree :category_data="brandsData.data"></FilterListTree>-->
          <input type="hidden" name="parent_id" :value="editcategory.parent_id">
          <div class="border border-radius-1em">
            <SelectCompanyCategoryTree :selectedid="editcategory.parent_id" :category_data="all_categories.data" @category-select="editParentCategory"></SelectCompanyCategoryTree>
          </div>
          <b-form-group class="my-3">
                     <div
                        class="form-check form-switch form-switch-sm mb-0 mt-auto"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          id="newbrand-owner"
                          v-model="editcategory.active" value="true"
                          unchecked-value="false"
                          name="active"
                        />
                        <label class="form-check-label" for="newbrand-owner" 
                          > Active</label
                        >
                    </div>
          </b-form-group>
          <input type="hidden" name="active" :value="editcategory.active">
          <div class="d-flex flex-row mt-3">
          <b-button variant="success" class="w-md w-100" @click="updateCategory()"> <i class=" bx bx-save"></i> Save</b-button>
          </div>
        </b-form>
      </b-overlay>
        </b-card>
        </div>
      </div>
    </SideSlidePanel> 
</Layout>
</template>