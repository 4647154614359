<script type="text/javascript">
	import { EventBus } from '../../../../app'
	import ImageSwiper from "../../../../components/widgets/gallery/ImageSwiper";
	export default{
		components: { ImageSwiper },
		props: {
			company: {
				type: Object,
				default: () => {},
			},
			resource: {
		        type: String,
		        default: () => '',
		      },
		      resource_id: {
		        type: Number,
		        default: () => '',
		      },
		      api_token: {
		        type: String,
		        default: () => '',
		      },
		      resource_route: {
		        type: String,
		        default: () => '',
		      },
		      view: {
		        type: String,
		        default: () => 'grid',
		      },
		      product_list: {
		        type: Array,
		        default: () => '',
		      },
		      expanded:{
		      	type: Number,
		      	default: () => 0
		      },
		      truncate_titles:{
		      	type: Boolean,
		      	default: () => true
		      },
		      pos_settings:{
		      	type: Object,
		      	default: () => true
		      }
		  },
		  data(){
				return{
			        quickviewproduct: '',
			        hoverIndex: -1,
			        storageurl: '.s3.eu-west-2.amazonaws.com',
			        showQVGallery: true,
			        viewImage: null,
				}
		  },
		  methods: {
		  	 imageSelected(img){
		    	if(this.viewImage == null){
		    		this.quickviewproduct.images.unshift(this.quickviewproduct.cover_image);
		    	}
		    	this.viewImage = img.src;

		    },
		    qvModalClosed(){
		    	this.viewImage = null;
		    },
		  	removeResource(){

		  	},
		  	addToCartOptions(product){
		  		this.showOptionsWindow = true;
		  		EventBus.$emit('pos-product-options', product);
		  	},
		  	addToCart(product, quantity){
		  		EventBus.$emit('add-to-pos', product, quantity);
		  	},
		  	addTax(price, tax_rule){
        		if(tax_rule !== null){
        			var percentage = (parseFloat(tax_rule.percentage) / 100) + 1;
        			return price * percentage;
         		}else{
         			return price;
         		}
        	},
        	/*addTax(product, price){
        		var tax_rule = product.tax_rule;
        		console.log(tax_rule);
		    	if(this.delivery_address == '' || this.delivery_address == null){
		    		var country_id = this.company.country_id;
		    	}else{
		    		var country_id = this.delivery_address.country_id;
		    	}
		    	if(tax_rule !== null && tax_rule.country_rules.length > 0){
		    		var selected_rule = null;
		    		var selected_ruleIndex = -1;
			    	for(var ti = 0; ti < tax_rule.country_rules.length; ti++){
			    		selected_ruleIndex = tax_rule.country_rules[ti].countries.findIndex(item => item == country_id);
			    		if(selected_ruleIndex !== -1){
			    			selected_rule = tax_rule.country_rules[ti];
			    			ti = tax_rule.country_rules.length;
			    		}
			    	}
			    	console.log(selected_rule);
			    	if(tax_rule.type == 1){
		        		if(selected_rule !== null){
		        			var percentage = (parseFloat(selected_rule.value) / 100) + 1;
		        			return price * percentage;
		         		}else{
		         			return price;
		         		}
	         		}else{
	         			if(selected_rule !== null){
		        			return price + selected_rule.value;
		         		}else{
		         			return price;
		         		}
	         		}
         		}else if(tax_rule !== null && tax_rule.country_rules.length > 0){
         			var percentage = (parseFloat(tax_rule.percentage) / 100) + 1;
		        	return price * percentage;
         		}else {
		        	return price;
         		}
        	},*/
		    productAtThisListing(listings){
    				var index = -1;
    			  	if(listings.length > 0){
    			  		index = listings.findIndex(item => item.company_listing_id == this.pos_settings.company_listing_id);
    			  		console.log(index);
    				}
    				if(index > -1){
			      		return listings[index].quantity;
			      	}else{
			      		return 0;
			      	}
				}
		  },
		  computed: {
		  	companyCurrency(){
        if(this.company.currency !== null){
            return this.company.currency.symbol;
        }else{
            return '£';
        }
      }
		  }
	}
</script>
<template>
	 <div class="row">
          <div class="col-md-12">
          	<!--<ul class="nav nav-pills product-view-nav mb-2 float-right">
          	<li class="nav-item">
                  <a class="nav-link" :class="{'active': view == 'grid' }" href="javascript: void(0);" @click="view = 'grid'">
                    <i class="bx bx-grid-alt"></i>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" :class="{'active': view == 'list' }" href="javascript: void(0);" @click="view = 'list'">
                    <i class="bx bx-list-ul"></i>
                  </a>
                </li>
               </ul>-->
          	<ul class="list-group product-list w-100 no-shadow" v-if="(product_list.length !== 0) && (view == 'list')">
                              <li v-for="product in product_list" class="list-group-item d-flex px-0 py-0 border product-list-item mb-2 shadow-sm">
                              	<div class="d-flex product-list-item-container w-100">
                              	<b-img-lazy v-if="(product.cover_image) && ('gallery' in product.cover_image)"class="rounded-0 avatar-xl" :src="'https://'+company.nickname+storageurl+product.cover_image.gallery.replaceAll('//', '/')" ></b-img-lazy>
                              	<b-img-lazy v-else-if="(product.cover_image) && ('thumb' in product.cover_image)"class="rounded-0 avatar-xl" :src="'https://'+company.nickname+storageurl+product.cover_image.thumb.replaceAll('//', '/')" ></b-img-lazy>
                              	<b-img-lazy v-else-if="(product.cover_image) && ('src' in product.cover_image)"class="rounded-0 avatar-xl" :src="'https://'+company.nickname+storageurl+product.cover_image.src.replaceAll('//', '/')" ></b-img-lazy>
		                        <b-img-lazy v-else :src="'/images/product-placeholder.png'" alt="Image" class="rounded-0 avatar-xl"></b-img-lazy>
                                <div class="mr-auto ml-3 w-50 my-auto flex-grow-5 mr-3">
                                	<p class="mr-auto my-auto card-title text-truncate font-size-12">{{product.name}}</p>
                                	<span class="badge badge-primary bg-primary position-absolute product-list-reference-badge top-0 ml-2 mt-2">{{product.reference}}</span>
						           
										            <div class="d-flex flex-row mt-2">
						                         <span class="badge badge-light my-auto mr-auto bg-light p-2 font-size-12 border-radius-1em" v-if="product.tax_rule !== null">
						                       
						                         	{{companyCurrency}}{{addTax(product.sales_price, product.tax_rule).toFixed(2)}} <small>inc tax</small> 
						                         </span>
						                         <span class="badge badge-light my-auto mr-auto bg-light p-2 font-size-12 border-radius-1em" v-else>
						                         	{{companyCurrency}} {{product.sales_price}} <small>inc tax</small>
						                        
						                         </span>

							              		</div>
                                </div>
                                 <div class="mt-auto my-auto ml-auto d-flex flex-grow-2 w-25" >
                              <p  class="text-muted ml-auto mr-0 my-auto">
							                <span v-if="productAtThisListing(product.listings) !== 0 " class="font-size-11 badge badge-success bg-success">
		              							 In Stock Here
		              						</span>
		              						<span class="font-size-11 badge badge-danger bg-danger" v-else-if="productAtThisListing(product.listings) == 0">Out of Stock Here</span>
		              						<span class="font-size-11 badge badge-success bg-success" v-if="(product.total_qty > 0) && (productAtThisListing(product.listings) == 0)">In Stock at Another Store</span>
		              						<span class="font-size-11 badge badge-success bg-success" v-else-if="(product.total_qty > 0) && (productAtThisListing(product.listings) !== 0)">Out of Stock</span>
							                  </p>
              							</div>
                               <div class="mr-auto ml-2 my-auto flex-grow-1 mr-3 d-flex">
                                	<a id="tooltip-add"  @click="addToCartOptions(product)" class="btn btn-primary btn-rounded ml-auto my-auto mr-2" href="javascript:;"><i class="mdi mdi-shopping-outline py-3 px-2 font-size-20"></i></a>
                            	</div>
              
                              </div>
                              </li>
                              

                              <!--<li class="list-group-item py-4 text-center" ><a class="btn btn-outline-light btn-rounded">Load More</a>
                              </li>-->
                            </ul>
                <ul class="list-group product-list w-100 no-shadow" v-if="(product_list.length == 0) && (view == 'list')">
                	  <li class="list-group-item d-flex">
                	  	<i class="bx bx-warning"></i> No Products <span v-if="resource !== ''">assigned to this {{resource}}</span>
                	  </li>
                </ul>
          </div>
          <div class="col-md-12">
          	<div class="row product-grid px-2 row-eq-height" v-if="(product_list.length !== 0) && (view == 'grid')">
                              <div class=" d-flex flex-column px-2 product-grid-item mb-2" :class="{'col-md-4' : expanded == 0,'col-xs-12 col-sm-6 col-md-6 col-lg-4' : expanded == 1}" v-for="(product, index) in product_list">
                              	

                              	<!--<div class="border shadow-sm product-grid-item-container">
                              	<img v-if="product.cover_image" class="flex-grow-3 w-100 product-grid-img" :src="product.cover_image">
                              	<img v-else class="flex-grow-3 w-100 product-grid-img" src="/images/product-placeholder.png">
                                <div class="my-3 flex-grow-3 px-3">
                                	<strong class="mr-auto my-auto text-primary">{{product.name}}</strong>
                                	<span class="badge badge-primary position-absolute product-grid-reference-badge">{{product.reference}}</span>
                                </div>
                                <div class="my-1 flex-grow-3 px-3">
                                	<span class="text-muted mb-auto badge badge-light bg-light font-size-12">{{companyCurrency}}{{product.sales_price}} <small class="mr-3">exc. VAT</small></span>
                                	<span class="text-muted mb-auto badge badge-light bg-light font-size-12"> {{companyCurrency}}{{addTax(product.sales_price, product.tax_rule)}} <small class="mr-3">inc. VAT</small></span>
                                </div>
                                <div class="my-1 flex-grow-3 px-3">
                                	<span class="text-muted mb-auto badge badge-light bg-light font-size-12"> {{companyCurrency}}{{addTax(product.sales_price, product.tax_rule)}} <small class="mr-3">inc. VAT</small></span>
                                </div>

                                <div class="my-3 flex-grow-3 px-3">
                                	<a class="btn btn-outline-light btn-rounded ml-auto my-auto flex-grow-3" href="javascript:;" @click="quickviewproduct = product"><i class="mdi mdi-eye-outline"></i></a>
                                	<a class="btn btn-outline-light btn-rounded ml-auto my-auto flex-grow-3" href="javascript:;"><i class="bx bx-pencil"></i></a>
                                <a class="btn btn-outline-light btn-rounded ml-auto my-auto flex-grow-3" href="javascript:;" @click="removeResource(product)"><i class="bx bx-trash-alt"></i></a>
              					</div>
                              	</div>-->
                         

                              <b-card no-body class="overflow-hidden news-card shadow-none border-radius-1em border" @click="hoverIndex = index">

		                    <b-row no-gutters>
		                    
		                      <b-col lg="12">
		                      		<span class="badge badge-primary bg-primary position-absolute product-grid-reference-badge">{{product.reference}}</span>
		                         <b-card-img-lazy v-if="(product.cover_image) && ('gallery' in product.cover_image)"class="rounded-0" :src="'https://'+company.nickname+storageurl+product.cover_image.gallery.replaceAll('//', '/')" ></b-card-img-lazy>
                              	<b-card-img-lazy v-else-if="(product.cover_image) && ('thumb' in product.cover_image)"class="rounded-0" :src="'https://'+company.nickname+storageurl+product.cover_image.thumb.replaceAll('//', '/')" ></b-card-img-lazy>
                              	<b-card-img-lazy v-else-if="(product.cover_image) && ('src' in product.cover_image)"class="rounded-0" :src="'https://'+company.nickname+storageurl+product.cover_image.src.replaceAll('//', '/')" ></b-card-img-lazy>
		                        <b-card-img-lazy v-else :src="'/images/product-placeholder.png'" alt="Image" class="rounded-0"></b-card-img-lazy>
		                     <transition name="fade-right">
		                        <div class="mt-auto mb-3 mx-2 d-flex position-absolute bottom-0 right-0 left-0" v-if="hoverIndex == index">
                              <p  v-if="hoverIndex == index" class="text-muted ml-3 mr-auto my-auto">
							                <span v-if="productAtThisListing(product.listings) !== 0 " class="badge badge-success bg-success">
		              							 In Stock Here
		              						</span>
		              						<span class="badge badge-danger bg-danger" v-else-if="productAtThisListing(product.listings) == 0">Out of Stock Here</span>
		              						<span class="badge badge-success bg-success" v-if="(product.total_qty > 0) && (productAtThisListing(product.listings) == 0)">In Stock at Another Store</span>
		              						<span class="badge badge-success bg-success" v-else-if="(product.total_qty > 0) && (productAtThisListing(product.listings) !== 0)">Out of Stock</span>
							                  </p>
                              	<a id="tooltip-add"  @click="addToCartOptions(product)" class="mr-3 ml-auto btn btn-primary font-size-16 btn-rounded my-auto"><i class="mdi mdi-shopping-outline"></i></a>
              					</div>
              				</transition>
		                      </b-col>
		                      <b-col lg="12">
		                        <b-card-body class="border-top">
		                        	<p class="card-title" :class="{'text-truncate font-size-12' : truncate_titles == true}">{{product.name}}</p>
		                          <!--<b-card-text>
		                             <p v-html="product.short_description.substring(0,120)+'...'"></p>
		                           

		                          </b-card-text>-->
		                          
		                          <div class="d-flex flex-row">
		                         <span class="badge badge-light my-auto mr-auto bg-light p-2 font-size-12 border-radius-1em" v-if="product.tax_rule !== null">
		                       
		                         	{{companyCurrency}}{{addTax(product.sales_price, product.tax_rule)}} <small>inc tax</small> 
		                         </span>
		                         <span class="badge badge-light my-auto mr-auto bg-light p-2 font-size-12 border-radius-1em" v-else>
		                         	{{companyCurrency}} {{product.sales_price}} <small>inc tax</small>
		                        
		                         </span>

			              		</div>
		                        </b-card-body>
		                      </b-col>
		                    </b-row>
		                  </b-card>
                              </div>

                              <!--<li class="list-group-item py-4 text-center" ><a class="btn btn-outline-light btn-rounded">Load More</a>
                              </li>-->
                            </div>
                <ul class="list-group product-list w-100 no-shadow"  v-if="(product_list.length == 0) && (view == 'grid')">
                	  <li class="list-group-item d-flex">
                	  	<i class="bx bx-warning"></i> No Products <span v-if="resource !== ''">assigned to this {{resource}}</span>
                	  </li>
                </ul>
          </div>
         
           
     </div>
</template>