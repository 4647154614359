<script type="text/javascript">
	//import { EventBus } from '../../../../public-app'
	export default{
		data(){
			return{
				showControls: -1,
			}
		},
		props: {
			user: {
				type: Object,
				default: () => '',
			},
			addresses: {
				type: Array,
				default: () => [],
			}
		},
		methods: {
			loadAddresses(){
				axios.get('/website-api/user/addresses', {headers: {
			            'Authorization': 'Bearer ' + this.user.api_token
			        } }).then(response => {
					this.addresses = response.data;
				}).catch(error => {
					alert('could not load addresses');
				});
			},
			updateAddress(address){
				this.$emit('update-address', address);
			}

		},
		created(){
			if(this.addresses.length == 0){
				this.loadAddresses();
			}
		}
	}

</script>
<template>
	<b-row>
		<b-col md="12" class="mb-3">
			<h5>All Addresses</h5>
		</b-col>
		<b-col md="4" v-for="(address, index) in addresses">
			<b-card class="border-radius-1em shadow-none border" @mouseenter="showControls = index" @mouseleave="showControls = -1">
				<div class="media">
						<div class="avatar-sm me-3 my-auto">
							
							<a v-b-modal.modal-edit @click="updateAddress(address)"><span class="avatar-title rounded-circle">
								<i v-if="showControls == index" class="bx bx-pencil font-size-18 text-white"></i>
							<i class="bx bx-home font-size-18"  v-else></i>
							</span></a>
		                 </div>
		                 <div class="media-body">       
		                 <h5 class="font-size-14 mb-1">
		                    {{address.alias}}
		                 </h5>
		                 <div>
							<span v-if="address.address_1">{{address.address_1}}</span>
							<span v-if="address.address_2">{{address.address_2}}</span>
							<span v-if="address.address_3">{{address.address_3}}</span>
							<span v-if="address.city">{{address.city}}</span>
							<span v-if="address.country">{{address.country.name}}</span>
							<span v-if="address.county">{{address.county.name}}</span>
							<span v-if="address.postcode">{{address.postcode}}</span>
						</div>
						<div class="w-100 d-flex">
						<i class="bx bxs-truck text-white position-absolute top-0 right-0 mt-2 mr-2 font-size-14 rounded-circle p-1 bg-success" v-if="address.delivery_default == true"></i>
						<i class="bx bx-money  text-white position-absolute top-0 right-0 mt-2 mr-4 font-size-14 rounded-circle p-1 bg-success" v-if="address.invoice_default == true"></i>
						
						<a  v-b-modal.modal-edit @click="updateAddress(address)" class="ml-auto">
							Edit
						</a>
						</div>
					</div>
				</div>
			</b-card>
		</b-col>
		<b-col md="12" class="mb-3">
			<a class="btn btn-primary btn-rounded" v-b-modal.modal-edit @click="$emit('create-address')">Add New Address</a>
		</b-col>
	</b-row>
</template>