<script>
import VueSlideBar from "vue-slide-bar";
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
import DateTime from 'luxon/src/datetime.js';
import Interval from 'luxon/src/interval.js';
//import FilterListTree from "../../views/stock/product/category/_partials/FilterListTree";
import FilterListTree from "../../components/widgets/FilterListTree";
import FilterProductBrands from "../../components/widgets/FilterProductBrands";
import FilterProductCollections from "../../components/widgets/FilterProductCollections";
//import { clothsData } from "./data-products";
import SearchCompanySupplier from "../../components/widgets/SearchCompanySupplier"
import SearchCompanyCustomerAddress from "../../components/widgets/SearchCompanyCustomerAddress"
import SearchCompanyLocation from "../../components/widgets/SearchCompanyLocation"
import SearchCompanyPaymentMethod from "../../components/widgets/SearchCompanyPaymentMethod"
import SearchCompanyPaymentTerm from "../../components/widgets/SearchCompanyPaymentTerm"
import SearchCompanyServiceStatus from "../../components/widgets/SearchCompanyServiceStatus"
import CompanyServiceSearch from "../../components/widgets/CompanyServiceSearch"
import CompanyServiceList from "../../components/widgets/CompanyServiceList"
import CompanyCustomerSearch from "../../components/widgets/CompanyCustomerSearch"
import CompanyCustomerList from "../../components/widgets/CompanyCustomerList"
import SideSlidePanel from "../../components/widgets/SideSlidePanel";
import CompanyCustomerAddressForm from "../../components/widgets/forms/company/CompanyCustomerAddressForm";
import Calendar from "./calendar"

/**
 * Products component
 */
export default {
  components: { VueSlideBar, Layout, PageHeader, FilterListTree, FilterProductBrands, FilterProductCollections, SideSlidePanel, SearchCompanyPaymentTerm, SearchCompanyPaymentMethod, SearchCompanyServiceStatus, SearchCompanyLocation, CompanyServiceSearch, CompanyServiceList, CompanyCustomerSearch, CompanyCustomerList, SearchCompanyCustomerAddress, Calendar, DateTime, Interval, CompanyCustomerAddressForm },
  data() {
    return {
      loadingBookingDate: false,
      productsData: '',
      loadingForm: false,
      error_reponse: [],
      error_message: false,
      success_message: false,
      success_text: '',
      loadingList: false,
      loadingCustomerList: false,
      title: "Bookings",
      items: [
        {
          text: "Service",
          href: "/",
        },
        {
          text: "Bookings",
          active: true,
        },
      ],
      search: '',
      showFilters: false,
      sliderPrice: 800,
      priceRange: [0,999999],
      currentPage: 1,
      limit: 25,
      selectedids: [],
      orderDesc: true,
      addNewPanel: false, 
      order: 'desc',
      sortby: 'id',
      trade_active: '',
      public_active: '',
      newbooking: {
        listing: '',
        listing_id: '',
        id: null,
        reference: "",
        company_id: 0,
        company_service_id: 0,
        services: [],
        company_service_frequency_id: 0,
        company_customer_id: "74",
        customer_invoice_address_id: 0,
        customer_delivery_address_id: 0,
        status_id: "",
        total_exc: "0.00",
        total_inc: "0.00",
        tax_rule_id: "1",
        start_date: "",
        renewal_date: "",
        end_date: "",
        one_off: 0,
        created_at: "2021-05-26 10:15:54",
        updated_at: "2021-05-26 10:15:54",
        payment_term: {
          name: 'None selected',
          recurring: 0,
        },
        payment_method: {
          id: null,
          name: 'None selected',
          integration_id: 0
        },
        status: {
          id: null, 
        },
        customer: [
            {id: null, addresses: []}
        ],
        customer_address: '',
      },
      updatebooking: {
        id: null,
        reference: "",
        company_id: 0,
        company_service_id: 0,
        services: [],
        company_service_frequency_id: 0,
        company_customer_id: "74",
        customer_invoice_address_id: 0,
        customer_delivery_address_id: 0,
        status_id: "",
        total_exc: "0.00",
        total_inc: "0.00",
        tax_rule_id: "1",
        start_date: "",
        renewal_date: "",
        end_date: "",
        one_off: 0,
        created_at: "2021-05-26 10:15:54",
        updated_at: "2021-05-26 10:15:54",
        payment_term: {
          name: 'None selected',
          recurring: 0,
        },
        payment_method: {
          id: null,
          name: 'None selected',
          integration_id: 0
        },
        status: {
          id: null, 
        },
        customer: [
            {id: null, addresses: []}
        ],
        customer_address: '',
      },
      newaddress: {
        id: null,
        company_customer_id: '',
        alias: '',
        address_1: '',
        address_2: '',
        address_3: '',
        county: '',
        country: '',
        city: '',
        postcode: '',
        active: 0,
        invoice_default: 0,
        delivery_default: 0,
        type: '',
      },
      brands: '',
      collections: '',
      selectedids: [],
      isCheckAll: false,
      active: '',
      view: 'list',
      fields: [
        { key: "id", sortable: true },
        { key: "name", sortable: true },
        ],
      filter: null,
      filterOn: [],
      limits: [
        {value: 25, text: "25"},
        {value: 50, text: "50"},
        {value: 100, text: "100"},
      ],  
      discountRates: [],
    };
  },
  methods: {
    setDate(){
      this.newbooking.start_date = LocalDateTime.now();
      this.newbooking.end_date = LocalDateTime.now();
    },
    getDay(date){
      console.log(date);
      console.log(DateTime.fromISO(this.newbooking.start_date));
      //var sqldate = DateTime.fromHTTP(this.newbooking.start_date).toSQLDate();
      return this.$luxon(date, {input: 'iso', output: 'date_huge'});
    },
    getTime(date){
      return this.$luxon(date, "full");
    },
    checkBookingDate(start_date){
     if(this.newbooking.services.length > 0){
        if(this.newbooking.services[0].booking_days.length > 0 && start_date !== ''){
         var testdate = start_date;
         var hugedate = this.$luxon(start_date, {input: 'iso', output: 'date_huge'});
         var datearray = hugedate.split(',');
         var dayindex = this.newbooking.services[0].booking_days.findIndex(item => item == datearray[0]);
         console.log('Test 1 passed');
         if(dayindex > -1){
          console.log('Test 2 passed');
          var time = this.$luxon(start_date, {input: 'iso', output: 'time24'});
            if(time > this.newbooking.services[0].booking_times[dayindex][0] && time < this.newbooking.services[0].booking_times[dayindex][1]){
              console.log('All tests passed');
              return true;
            }else{
              return false;
            }
         }else{
          return false;
         }
        }else{
          return true;
        }
      }else{
        return true;
      }
    },
    addCustomerAddressEvent(){
      this.newaddress.company_customer_id = this.newbooking.customer.id;
      this.$bvModal.show('modal-add-address');
    },
    selectPaymentTerm(payload){

    },
    selectPaymentMethod(payload){

    },
    selectInvoiceAddress(payload){

    },
    valuechange(value) {
      this.clothsData = clothsData.filter(function (product) {
        return product.newprice <= value.currentValue;
      });
    },
    priceIncVat(price, tax_percentage){
          var tax = 1 + (tax_percentage / 100);
          return price * tax;
    },
    searchFilter(e) {
      const searchStr = e.target.value;
      this.productQuery();
    },

    filterBrands(value){
      this.brands = value;
      this.productQuery();
    },

     filterCollections(value){
      this.collections = value;
      this.productQuery();
    },

    discountLessFilter(e, percentage) {
      if (e === "accepted" && this.discountRates.length === 0) {
        this.clothsData = clothsData.filter((product) => {
          return product.discount < percentage;
        });
      } else {
        this.clothsData = clothsData.filter((product) => {
          return product.discount >= Math.max.apply(null, this);
        }, this.discountRates);
      }
    },

    discountMoreFilter(e, percentage) {
      if (e === "accepted") {
        this.discountRates.push(percentage);
      } else {
        this.discountRates.splice(this.discountRates.indexOf(percentage), 1);
      }
      this.clothsData = clothsData.filter((product) => {
        return product.discount >= Math.max.apply(null, this);
      }, this.discountRates);
    },
     productQuery(){
      if(this.productsData != ''){
        if(this.limit > this.productsData.total){
          this.currentPage = 1;
        }
      }
      if(this.orderDesc == true){
        this.order = 'desc';
      }else{
        this.order = 'asc';
      }
      axios.get('/api/company/service/bookings?page='+this.currentPage+'&limit='+this.limit+'&sortby='+this.sortby+'&order='+this.order+'&search='+this.search+'&trade_active='+this.trade_active+'&public_active='+this.public_active+'&active='+this.active+'&brands='+this.brands+'&collections='+this.collections+'&pricerange='+this.priceRange, {headers: {
                  'Authorization': 'Bearer ' + this.$attrs.user.api_token
              }}).then(response => {
        console.log(response);
        this.productsData = response.data;
      });
    },
    addBooking(){
      this.loadingForm = true;
      //var postForm = new FormData(document.getElementById("addpurchaseorderform"));
      axios.post('/api/company/service/booking/create', this.newbooking, {headers: {
                  'Authorization': 'Bearer ' + this.$attrs.user.api_token
              }}).then(response => {
        console.log(response);
        this.success_text = 'Congratulations, your purchase order has been added.';
        this.success_message = true;
        //this.resetProjectData();
        //this.addPanel = false;
        let self = this;
          setTimeout(function(){
            self.loadingForm = false;
            self.addNewPanel = false;
            self.productQuery();
          }, 1500);
      }).catch( error => {
        
        this.error_reponse = error.response.data.errors;
        this.error_message = true;
        //this.resetProjectData();
        let self = this;
          setTimeout(function(){
            self.loadingForm = false;
          }, 1500);
      });
    },
    updateBooking(){
      this.loadingForm = true;
      //var postForm = new FormData(document.getElementById("updatebookingform"));
      axios.post('/api/company/service/booking/'+this.newbooking.id+'/update', this.newbooking, {headers: {
                  'Authorization': 'Bearer ' + this.$attrs.user.api_token
              }}).then(response => {
        console.log(response);
        this.success_text = 'Congratulations, your purchase order has been updated.';
        this.success_message = true;
        //this.resetProjectData();
        //this.addPanel = false;
        let self = this;
          setTimeout(function(){
            self.loadingForm = false;
            //self.addNewPanel = false;
            //self.productQuery();
          }, 1500);
      }).catch( error => {
        
        this.error_reponse = error.response.data.errors;
        this.error_message = true;
        //this.resetProjectData();
        let self = this;
          setTimeout(function(){
            self.loadingForm = false;
          }, 1500);
      });
    },
    selectStatus(status){
        this.newbooking.status = status;
    },
    selectService(service){
      this.loadingList = true;
      this.newbooking.services.push(service);
      let self = this;
          setTimeout(function(){
            self.loadingList = false;
            //self.addNewPanel = false;
            //self.productQuery();
          }, 500);
    },
    selectCustomer(customer){
      this.loadingCustomerList = true;
      this.newbooking.customer[0] = customer;
      let self = this;
          setTimeout(function(){
            self.loadingCustomerList = false;
            //self.addNewPanel = false;
            //self.productQuery();
          }, 500);
    },
    removeCustomer(){
      this.loadingCustomerList = true;
      this.newbooking.customer[0] = {id: null, addresses: []};
      let self = this;
          setTimeout(function(){
            self.loadingCustomerList = false;
            //self.addNewPanel = false;
            //self.productQuery();
          }, 500);
    },
    removeService(service, index){
      this.loadingList = true;
      this.newbooking.services.splice(index, 1);
      let self = this;
          setTimeout(function(){
            self.loadingList = false;
            //self.addNewPanel = false;
            //self.productQuery();
          }, 500);
    },
    resetBookingData(){
      this.newbooking = {
        id: null,
        reference: "",
        company_id: 0,
        company_service_id: 0,
        services: [],
        company_service_frequency_id: 0,
        company_customer_id: "74",
        customer_invoice_address_id: 0,
        customer_delivery_address_id: 0,
        status_id: "",
        total_exc: "0.00",
        total_inc: "0.00",
        tax_rule_id: "1",
        start_date: "2021-05-26 12:00:00",
        renewal_date: "2021-06-26 00:00:00",
        end_date: "2021-05-26 12:00:00",
        one_off: 0,
        created_at: "2021-05-26 10:15:54",
        updated_at: "2021-05-26 10:15:54",
        payment_term: {
          name: 'None selected',
          recurring: 0,
        },
        payment_method: {
          id: null,
          name: 'None selected',
          integration_id: 0
        },
        status: {
          id: null, 
        },
        customer: [
            {id: null, addresses: []}
        ],
        customer_address: '',
      };
    },
    filterSelected(id){
        if(this.selectedids.length > 0){
          var exists = this.selectedids.filter(item => item == id);
          if(exists.length > 0){
            return true;
          }else{
            return false
          }
        }else{
          return false;
        }
    },
    editEvent(event_id){
      var project = this.productsData.data.filter(item => (item.id == event_id));
      this.updateBookingEvent(project[0]);
    },
    updateBookingEvent(project){
      this.newbooking = project;
      // this.updateProject.invited = JSON.parse(project.invited);
      this.newbooking.meeting_date = this.newbooking.meeting_date.slice(0, -3);
      this.newbooking.meeting_date = this.newbooking.meeting_date.replace(' ', 'T');
      this.newbooking.meeting_date_end = this.newbooking.meeting_date_end.slice(0, -3);
      this.newbooking.meeting_date_end = this.newbooking.meeting_date_end.replace(' ', 'T');
      var self = this;
                setTimeout(function(){
                 self.addNewPanel = true;
                }, 50);
    },
    addEvent(eventinfo){
      console.log(eventinfo);
      if(eventinfo.allDay == true){
        this.newbooking.start_date = eventinfo.date.toISOString();
        this.newbooking.start_date = eventinfo.dateStr + 'T00:00:00';
        this.newbooking.end_date = DateTime.fromISO(this.newbooking.start_date).plus({days: 1}).toISODate() + 'T00:00:00';
        //this.newbooking.end_date = this.newbooking.end_date.slice(0, -5);
      }else{
        this.newbooking.start_date = eventinfo.startStr.slice(0, -9);
        this.newbooking.end_date = eventinfo.endStr.slice(0, -9);
      }
      this.newbooking.reference = 'MRVW' + ((this.$attrs.company.nickname).slice(0,2)).toUpperCase() + '' + Math.floor((Math.random() * 1000000) + 1) + '';
            var self = this;
                setTimeout(function(){
                 self.addNewPanel = true;
                }, 50);
    },
    checkAll: function(){ 
                 this.isCheckAll = !this.isCheckAll; 
                 this.selectedids = []; 
                 if(this.isCheckAll){ 
                     // Check all 
                     for (var key in this.productsData.data) {          
                        this.selectedids.push(this.productsData.data[key].id); 
                     } 
                 } 
    }, 
    updateCheckall: function(){ 
                 if(this.selectedids.length == this.productsData.data.length){
                    this.isCheckAll = true; 
                 }else{ 
                    this.isCheckAll = false; 
                 } 
    },
  },
  beforeMount(){
      this.productQuery();
  },
  computed:{
   serviceItemsTotal: function(){
      //this.newpurchaseorder.total_inc = 0.00;
      //this.newpurchaseorder.total_exc = 0.00;
      var priceSum = 0.00;
      var priceSumInc = 0.00;
      for(var i = 0; i < this.newpurchaseorder.items.length; i++){
         //var currentTotal = this.newpurchaseorder.total_exc;
         var priceSum = parseFloat(priceSum) + parseFloat(this.newpurchaseorder.items[i].price_exc) * parseFloat(this.newpurchaseorder.items[i].quantity);
         var priceSumInc = parseFloat(priceSumInc) + parseFloat(this.newpurchaseorder.items[i].price_inc) * parseFloat(this.newpurchaseorder.items[i].quantity);
         this.newpurchaseorder.items[i].line_item_total = (parseFloat(this.newpurchaseorder.items[i].price_inc) * parseFloat(this.newpurchaseorder.items[i].quantity)).toFixed(2);
      }
        this.newpurchaseorder.total_taxes = ((priceSumInc).toFixed(2) - (priceSum).toFixed(2)).toFixed(2);
        this.newpurchaseorder.total_exc = (priceSum).toFixed(2);
        this.newpurchaseorder.total_inc = (priceSumInc).toFixed(2);
        return [(priceSum).toFixed(2), (priceSumInc).toFixed(2)];
      
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div :class="{'col-lg-12': showFilters == true, 'col-lg-12': showFilters == false}">
        <div class="row mb-3">
          <div class="col-xl-1 col-sm-6">
            <div class="mt-2">
              <b-form-select class="form-control" v-model="limit" :options="limits" @input="productQuery()"></b-form-select>
            </div>
          </div>
         
          <div class="col-lg-2 col-sm-6 d-flex">
          <form class="my-auto">
            <small class="mx-auto my-2 text-center d-block">Results {{productsData.from}} - {{productsData.to}} of {{productsData.total}}</small>
          </form>
        </div>
        <div class="col-lg-5 col-sm-6 d-flex">
            <div class="mt-sm-0 form-inline w-100" >
              <div class="search-box mr-2 ml-auto">
                <div class="position-relative">
                  <input
                    type="text"
                    class="form-control w-100 border-0"
                    placeholder="Search name, reference or description..."
                    v-model="search"
                    @keyup.enter="searchFilter($event)"
                  />
                  <i class="bx bx-search-alt search-icon"></i>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-sm-6 d-flex">
            <div class="d-flex ml-auto">
              <ul class="nav nav-pills product-view-nav">
                <li class="nav-item">
                  <a class="nav-link" :class="{'active': showFilters == true }" href="javascript: void(0);" @click="showFilters = !showFilters">
                    <i class="bx bx-filter-alt"></i>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" :class="{'active': view == 'grid' }" href="javascript: void(0);" @click="view = 'grid'">
                    <i class="bx bx-grid-alt"></i>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" :class="{'active': view == 'calendar' }" href="javascript: void(0);" @click="limit = 0, view = 'calendar'">
                    <i class="bx bx-calendar-alt"></i>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" :class="{'active': view == 'list' }" href="javascript: void(0);" @click="view = 'list'">
                    <i class="bx bx-list-ul"></i>
                  </a>
                </li>
                <li class="nav-item">
                 <b-dropdown text="Bulk Actions" variant="primary" class="rounded">
                    <b-dropdown-item v-if="$attrs.user.companies[0].permissions.edit.includes($attrs.active_menu.id)" href="javascript: void(0);" v-b-modal.update-status @click="actionMode = 'bulk', getOrderStatuses()"><i class="bx bx-history font-size-14 text-primary"></i> Update Status</b-dropdown-item>
                  <b-dropdown-item v-if="$attrs.user.companies[0].permissions.edit.includes($attrs.active_menu.id)" href="javascript: void(0);" @click="bulkUpdateOrderState('paid', true)"><i class="bx bx-check-circle font-size-14 text-success"></i> Mark Paid</b-dropdown-item>
                  <b-dropdown-item v-if="$attrs.user.companies[0].permissions.edit.includes($attrs.active_menu.id)" href="javascript: void(0);" @click="bulkUpdateOrderState('paid', true)"><i class="bx bx-check-circle font-size-14 text-danger"></i> Mark Unpaid</b-dropdown-item>
                  
                  
                  <!--<b-dropdown-item href="javascript: void(0);" @click="bulkDuplicateResource()"><i class="bx bx-copy-alt font-size-14"></i> Duplicate</b-dropdown-item>-->
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-item href="javascript: void(0);" v-if="$attrs.user.companies[0].permissions.edit.includes($attrs.active_menu.id)" v-b-modal.send-email @click="send_email.email_to = [], send_email.email_to.push(location.email)" ><i class="bx bx-mail-send font-size-14"></i> Send Email</b-dropdown-item>
                  <b-dropdown-divider></b-dropdown-divider>
                   <b-dropdown-item v-if="$attrs.user.companies[0].permissions.edit.includes($attrs.active_menu.id)" href="javascript: void(0);" @click="setActive(true)"><i class="bx bx-no-entry font-size-14 text-danger"></i> Mark Cancelled</b-dropdown-item>
                  <b-dropdown-item v-if="$attrs.user.companies[0].permissions.delete.includes($attrs.active_menu.id)" href="javascript: void(0);" v-b-modal.delete-modal @click="deleteMode = 'bulk'"><i class="bx bx-trash-alt font-size-14"></i> Delete</b-dropdown-item>
                </b-dropdown>
                </li>
                <li class="nav-item">
                 <b-button
                    class="btn btn-success btn-rounded mb-2 mr-2 text-white"
                    href="javascript:;"
                    :disabled="!$attrs.user.companies[0].permissions.add.includes($attrs.active_menu.id)"
                    @click="addNewPanel = !addNewPanel, setDate()"
                  >
                    <i class="mdi mdi-plus mr-1"></i> New
                  </b-button>
                </li>
              </ul>
              <div class="text-sm-right">
                  
                </div>
          </div>
        </div>
      </div>
        <!--<div class="row">
          <div class="col-lg-12">
            <b-pagination
              v-if="productsData.data.length > 0"
              class="justify-content-center mb-0"
              pills
              v-model="currentPage"
              :total-rows="productsData.total"
              :per-page="productsData.per_page"
              aria-controls="my-table"
              @input="productQuery"
            ></b-pagination>
             <small class="mx-auto my-2 text-center d-block">Records {{productsData.from}} - {{productsData.to}} of {{productsData.total}} // Page {{productsData.current_page}} of {{productsData.last_page}}</small>
          </div>
        </div>-->
        <div class="d-flex flex-column justify-content-center h-100 restricted-col" v-if="($attrs.user.companies[0].userrole.name !== 'Owner') && !$attrs.user.companies[0].permissions.read.includes($attrs.active_menu.id)" >
          <AccessRestricted :page="false"></AccessRestricted>
        </div>
        <div v-else>
        <div class="row" v-if="view == 'calendar'">
      <calendar :meetings="productsData.data" @edit-event="editEvent" @add-event="addEvent" ></calendar>

    </div>
        <div class="row"  v-if="view == 'grid'">
          <div id="my-table" v-for="data in productsData.data" :key="data.id" class="col-xl-4 col-sm-6">
            <div class="card">
              <div class="card-body">
                <div class="product-img position-relative">
                  <div v-if="data.discount" class="avatar-sm product-ribbon">
                    <span class="avatar-title rounded-circle bg-primary">-{{data.discount}}%</span>
                  </div>
                  <a :href="`/shop/product/detail/${data.id}`">
                    <img :src="`/public/storage/${data.cover_image}`" alt class="img-fluid mx-auto d-block" />
                  </a>
                </div>
                <div class="mt-4 text-center">
                  <h5 class="mb-3 text-truncate">
                    <a
                      class="text-dark"
                      :href="`/ecommerce/product-detail/${data.id}`"
                    >{{data.name}}</a>
                  </h5>
                  <p class="text-muted">
                    <i class="bx bx-star text-warning"></i>
                    <i class="bx bx-star text-warning"></i>
                    <i class="bx bx-star text-warning"></i>
                    <i class="bx bx-star text-warning"></i>
                    <i class="bx bx-star text-warning"></i>
                  </p>
                  <h5 class="my-0">
                    <span class="text-muted mr-2">
                      <del>${{data.oldprice}}</del>
                    </span>
                    <b>${{data.price}}</b>
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- end row -->

        
        <div class="card" v-if="view == 'list'" >
          <div class="card-body p-0">
            <div class="table-responsive">
              <table class="table table-centered table-hover">
                <thead class="thead-light">
                  <tr>
                   <th scope="col"  style="width: 100px" class="text-center">
                     <div
                        class="form-check form-switch form-switch-sm mb-0 mx-auto p-0 mt-auto d-flex"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input mx-auto my-auto" 
                          type="checkbox"
                          id="SwitchCheckSizesm"
                         
                          v-model="isCheckAll"
                          @click='checkAll()'
                          name="active"
                        />
                    </div>
                  </th>
                    <th scope="col"><a :class="{'text-dark': sortby != 'reference'}" href="javascript: void(0);" @click="orderDesc = !orderDesc, sortby = 'reference', productQuery()">Reference <i v-if="orderDesc == true" class="bx bx-caret-up"></i><i v-if="orderDesc == false" class="bx bx-caret-down"></i></a></th>
                    <th scope="col" style="max-width: 300px"><a :class="{'text-dark': sortby != 'name'}" href="javascript: void(0);" @click="orderDesc = !orderDesc,  sortby = 'name', productQuery()">Service <i v-if="orderDesc == true" class="bx bx-caret-up"></i><i v-if="orderDesc == false" class="bx bx-caret-down"></i></a></th>
                    
                    <th scope="col">Customer</th>
                    <th scope="col">Frequency</th>
                    <th scope="col">Booking Date</th>
                    <!--<th scope="col">Renewal</th>
                    <th scope="col">Expires</th>-->
                      <th scope="col"><a :class="{'text-dark': sortby != 'sales_price'}" href="javascript: void(0);" @click="orderDesc = !orderDesc,  sortby = 'sales_price', productQuery()">Paid <i v-if="orderDesc == true" class="bx bx-caret-up"></i><i v-if="orderDesc == false" class="bx bx-caret-down"></i></a></th>
                    <th scope="col">Payment Method</th>
                    <th scope="col">Status</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="list in productsData.data" :key="list.id" valign="middle">
                    <td>
                    <div class="text-center" >
                        <b-form-checkbox class="select-row-btn" v-model="selectedids" :value="list.id" button-variant="light" @input="updateSelected" button>

                          <i v-if="filterSelected(list.id)" class="bx bx-check"></i><span class="avatar-title rounded-circle" v-else>{{(list.reference).slice(0,1)}}</span></b-form-checkbox>
                    </div> 
                    </td>
                    <td>
                     <a :href="`/service/booking/${list.service.uid}`" class="mb-0"> <p class="text-primary mb-0">{{list.reference}}</p> </a>
                    </td>
                    <td style="max-width: 300px">
                        <a :href="`/service/details/${list.service.uid}`" class="mb-0"><p class="mb-0">{{list.service.name}}</p></a>
                    </td>
                    <td> <a href="" class="mb-0" v-if="list.customer !== null"><p class="mb-0">{{list.customer.name}} {{list.customer.lastname}} <i class="bx bx-link-external"></i></p></a></td>
                    <td>
                      <div>
                       
                      </div>
                    </td>
                    <td><span  class="text-muted"><i class="bx bx-calendar bg-primary text-white p-1 rounded-circle"></i> {{list.start_date | luxon("full")}}</span></td>
                    <!--<td><span class="text-muted"><i class="bx bx-calendar bg-primary text-white p-1 rounded-circle"></i> {{list.renewal_date | luxon("full")}}</span></td>
                    <td>
                      <span class="text-muted"><i class="bx bx-calendar bg-primary text-white p-1 rounded-circle"></i> {{list.end_date | luxon("full")}}</span></td>-->
                    <td> <a href="javascript: void(0);"
                          class="badge badge-soft-primary font-size-11 m-1"
                        >£{{list.total_exc}} exc. tax</a><br><a href="javascript: void(0);"
                          class="badge badge-soft-primary font-size-11 m-1"
                        >£{{list.total_inc}} inc. tax</a></td>
                      <td>
                        <i :class="`fas fa-credit-card  mr-1`"></i>
                                  Payment Method

                      </td>
                      <td>
                        <span class="badge badge-light text-dark bg-light">-- No Status Set --</span>
                    </td>
                    <td>
                      <ul class="list-inline font-size-20 contact-links mb-0">
                        <li class="list-inline-item px-2">
                          <a v-b-tooltip.hover title="Edit" placement="left">
                            <i class="bx bx-edit-alt"></i>
                          </a>
                        </li>
                        <li class="list-inline-item px-2">
                           
                            <b-dropdown v-b-tooltip.hover title="More Options" 
                            placement="left"
                            dropleft class="more-options-dropdown">
                  <template v-slot:button-content>
                     <i class="bx bx-dots-horizontal-rounded"></i>
                  </template>
                  <b-dropdown-item>Action</b-dropdown-item>
                  <b-dropdown-item>Another Action</b-dropdown-item>
                  <b-dropdown-item>Something else here</b-dropdown-item>
                </b-dropdown>
                          </a>
                        </li>
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="row">
              <div class="col-lg-12" v-if="productsData !== ''">
                <b-pagination
              class="justify-content-center"
              pills
              v-model="currentPage"
              :total-rows="productsData.total"
              :per-page="productsData.per_page"
              aria-controls="my-table"
              @input="productQuery"
            ></b-pagination>
             <small class="mx-auto my-2 text-center d-block">Records {{productsData.from}} - {{productsData.to}} of {{productsData.total}} // Page {{productsData.current_page}} of {{productsData.last_page}}</small>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
       <SideSlidePanel :editPanel="showFilters" :panelClass="'edit-sidepanel-slim'" @close-panel="showFilters = !showFilters">     
         <div class="col-lg-12" v-if="showFilters == true">
        <div class="">
          <div class="">
            <h4 class="card-title mb-4">Filter</h4>
              <div class="row">
                <div class="col-md-12 d-flex">
                      <b-form-checkbox v-b-tooltip.hover title="Toggle Active/Inactive" placement="left" switch size="lg" class="my-auto d-inline" value="1"
                      unchecked-value="0" v-model="active" @input="productQuery"><small class="mr-auto">Active</small></b-form-checkbox>
                      
                    <i v-b-tooltip.hover title="Clear Toggle" placement="left" class="font-size-20 bx bx-reset ml-auto" v-if="active != ''" @click="active = '', productQuery"></i>
                </div>
                 <div class="col-md-12 d-flex">
                      <b-form-checkbox switch size="lg" class="d-inline my-auto" value="1"
                      unchecked-value="0" v-model="public_active" @input="productQuery"><small class="mr-auto">Public</small></b-form-checkbox>
                      <i class="bx bx-reset font-size-20 ml-auto" v-if="public_active != ''" @click="public_active = '', productQuery"></i>
                </div>
                 <div class="col-md-12 d-flex">
                       <b-form-checkbox switch size="lg" class="my-auto d-inline" value="1"
                      unchecked-value="0" v-model="trade_active" indeterminate="null" @input="productQuery"><small class="mr-auto"  >Trade</small></b-form-checkbox>
                      <i class="bx bx-reset font-size-20 ml-auto" v-if="trade_active != ''" @click="trade_active = '', productQuery"></i>
                </div>
              </div>


            <div>
               <div class="my-3 pt-3">
              <h5 class="font-size-14">Price</h5>
              <b-form-group label="Min Price" class="w-50 d-inline float-left mt-2 mb-3 px-2">
              <b-form-input
                type="number"
                v-model="priceRange[0]"
                @input="productQuery"></b-form-input>
              </b-form-group>
              <b-form-group label="Max Price" class="w-50 d-inline float-left mt-2 mb-3 px-2">
                 <b-form-input
                 label="Max Price"
                 type="number"
                 v-model="priceRange[1]"
                 @input="productQuery"></b-form-input>
              </b-form-group>
            </div>
                <h5 class="font-size-14 my-3">Categories</h5>
                <FilterListTree></FilterListTree>
                
                <h5 class="font-size-14 mb-3">Brands</h5>
               <FilterProductBrands v-on:brands-filtered="filterBrands" :api_token="$attrs.user.api_token"></FilterProductBrands>

               <h5 class="font-size-14 mb-3">Collections</h5>
               <FilterProductCollections v-on:collections-filtered="filterCollections" :companies="$attrs.companies" :default_company="$attrs.company.id" :api_token="$attrs.user.api_token"></FilterProductCollections>

            </div>
           

          <!-- <div class="mt-4 pt-3">
              <h5 class="font-size-14 mb-3">Customer Rating</h5>
              <div>
                <b-form-checkbox
                  id="checkbox-1"
                  name="checkbox-1"
                  value="accepted"
                  unchecked-value="not_accepted"
                >
                  4
                  <i class="bx bx-star text-warning"></i> & Above
                </b-form-checkbox>

                <b-form-checkbox
                  id="checkbox-2"
                  class="mt-2"
                  name="checkbox-2"
                  value="accepted"
                  unchecked-value="not_accepted"
                >
                  3
                  <i class="bx bx-star text-warning"></i> & Above
                </b-form-checkbox>

                <b-form-checkbox
                  id="checkbox-3"
                  class="mt-2"
                  name="checkbox-3"
                  value="accepted"
                  unchecked-value="not_accepted"
                >
                  2
                  <i class="bx bx-star text-warning"></i> & Above
                </b-form-checkbox>
                <b-form-checkbox
                  id="checkbox-4"
                  class="mt-2"
                  name="checkbox-4"
                  value="accepted"
                  unchecked-value="not_accepted"
                >
                  1
                  <i class="bx bx-star text-warning"></i>
                </b-form-checkbox>
              </div>
            </div>-->
          </div>
        </div>
      </div>
    </SideSlidePanel>


     <SideSlidePanel :editPanel="addNewPanel" :panelClass="'edit-sidepanel'" @close-panel="addNewPanel = !addNewPanel">
      <div class="row px-4">
        <form id="addpurchaseorderform" class="repeater" enctype="multipart/form-data">
           <b-row>
                     <b-col md="12">
                    <transition name="slide-error" mode="out-in">
                    <b-alert id="errors" variant="danger" :show="error_message"  dismissible @dismissed="error_message = !error_message">
                      <p class="font-size-14">Oops! Please correct the following errors in your submission</p>
                      <ul>
                        <li v-for="(error, key) in error_reponse">
                          {{error[0]}}
                        </li>
                      </ul>
                    </b-alert>
                    
                  </transition>
                  </b-col>
          </b-row>
          <div class="row">
            <h4 class="mt-auto mb-4 mr-auto">Create Service Booking</h4>
          </div>
              <b-overlay
                            id="overlay-background"
                            :show="loadingForm"
                            variant="white"
                            opacity="0.85"
                            blur="1px"
                            rounded="md"
                          >
          <div class="row">
              <div class="col-md-12 mb-4">
                <strong><i class="font-size-16 text-primary bx bx-info-circle"></i> Reference Info</strong>
                <hr>
              <b-row>
              <div class="col-md-12">
                 <b-form-group class="mt-2" label="Reference">
                  <b-form-input type="text" name="reference" v-model="newbooking.reference"></b-form-input>
                </b-form-group>
              </div>

              <div class="col-md-12">
                 <b-form-group class="mt-2" label="Status">
                  <SearchCompanyServiceStatus :id="newbooking.status" :api_token="$attrs.user.api_token" @status-selected="selectStatus"></SearchCompanyServiceStatus>
                </b-form-group>
              </div>
            </b-row>
              </div>
            <div class="col-md-12">
                <strong><i class="font-size-16 text-primary bx bx-user"></i> Customer</strong>
                <hr>
            </div>
            <div class="col-md-12">
               <b-form-group class="mt-2" label="Customer">
                <CompanyCustomerSearch v-if="newbooking.customer.id !== null" :api_token="$attrs.user.api_token" @search-resource-selected="selectCustomer"></CompanyCustomerSearch>
                <b-overlay
                            id="overlay-background"
                            :show="loadingCustomerList"
                            variant="white"
                            opacity="0.85"
                            blur="1px"
                            rounded="md"
                          >
                  <CompanyCustomerList class="mt-2" v-if="loadingCustomerList == false" :api_token="$attrs.user.api_token" :view="'list'" :customer_list="newbooking.customer" @remove-resource="removeCustomer" :show_view="false"></CompanyCustomerList>
                </b-overlay>
               </b-form-group>
            </div>
            <div class="col-md-12 mt-2">
               <b-form-group class="mt-2" label="Customer Invoice Address">
                    <SearchCompanyCustomerAddress :id="newbooking.customer_address" :api_token="$attrs.user.api_token" :options="newbooking.customer[0].addresses" @status-selected="selectStatus" :data_provided="true" @add-address="addCustomerAddressEvent"></SearchCompanyCustomerAddress>
               </b-form-group>
            </div>

            <div class="col-md-12 mt-4">
                
                 <strong><i class="font-size-16 text-primary bx bx-wrench"></i> Service Details</strong>
                 <hr>
                <CompanyServiceSearch v-if="newbooking.services.length == 0" :api_token="$attrs.user.api_token" @search-resource-selected="selectService"></CompanyServiceSearch>
                <b-overlay
                            id="overlay-background"
                            :show="loadingList"
                            variant="white"
                            opacity="0.85"
                            blur="1px"
                            rounded="md"
                          >
                <CompanyServiceList class="mt-2" v-if="loadingList == false" :api_token="$attrs.user.api_token" :view="'list'" :service_list="newbooking.services" @remove-resource="removeService" :show_view="false"></CompanyServiceList>
                </b-overlay>
              </div>
              <div class="col-md-12 mt-3" v-if="newbooking.services.length > 0">
                <b-row>
                  <strong><i class="font-size-16 text-primary bx bx-building-house"></i> Booking Location</strong>
                  
                  <div class="col-md-12">
                    <hr>
                    <b-form-group class="mt-2" label="Location">
                      <div v-if="newbooking.services[0].listings.length > 0">
              <simplebar
                            style="height: 300px"
                        >
                            <li class="list-group-item" v-for="listing in newbooking.services[0].listings">
                                <div class="media">
                                    
                                       <div class="avatar-sm me-3">
                                        <span class="avatar-title rounded-circle" >{{(listing.listing_info.name).slice(0,1)}}</span>
                                      </div>
                                   
                                    <div class="media-body d-flex">
                                        <div class="d-flex flex-column">
                                        <h5 class="font-size-14">
                                            {{listing.listing_info.name}}
                                        </h5>
                                        <p class="text-muted">
                                            <span v-if="listing.listing_info.address_1">{{listing.listing_info.address_1}},</span>
                                            <span v-if="listing.listing_info.address_2">{{listing.listing_info.address_2}},</span>
                                            <span v-if="listing.listing_info.address_3">{{listing.listing_info.address_3}},</span>
                                            <span v-if="listing.listing_info.postcode">{{listing.listing_info.postcode}}</span>
                                        </p>
                                      </div>
                                        <div class="float-end">
                                            <p class="text-muted my-2" v-if="listing.listing_info.listing_type">
                                                <i 
                                                    class="bx bx-buildings me-1"
                                                ></i>
                                                {{listing.listing_info.listing_type.name}}
                                            </p>
                                        </div>
                                        <p class="text-muted mb-0">
                                            <a class="btn btn-primary btn-rounded" @click="newbooking.listing = listing, newbooking.invoice_address_id = listing.listing_info.id">Use Location</a>
                                        </p>
                                    </div>
                                </div>
                            </li>
                           
                        </simplebar>
                    </div>
                    <input type="hidden" name="invoice_address_id" v-model="newbooking.invoice_address_id">
                  </b-form-group>
                  </div>
              </b-row>
            </div>
            <div class="col-md-12 mt-4">
                
                 <strong><i class="font-size-16 text-primary bx bx-receipt"></i> Booking Details</strong>
                 <hr>
                
              </div>
                <div class="col-md-12 mb-2" v-if="newbooking.services.length > 0">
                  <div v-if="newbooking.services[0].allow_bookings == 1">
                  <h6>Allowed Booking Days</h6>
                  <div class="table-responsive p-3 border-radius-1em bg-light mb-3">
                  <table class="w-100 table mb-0">
                    <tr v-for="(day, index) in newbooking.services[0].booking_days">
                      <th>
                        {{day}}
                      </th>
                      <td>
                        {{newbooking.services[0].booking_times[index][0]}}
                      </td>
                      <td>
                        {{newbooking.services[0].booking_times[index][1]}}
                      </td>
                    </tr>
                  </table>


                </div>
              </div>
                  <div v-if="newbooking.services[0].set_duration == 1">
                    <h6>Service Duration</h6>
                    <p>Each service booking lasts <span class="badge badge-light bg-light text-dark font-size-12 lh-16 my-auto border-radius-1em p-2"><i class="bx bx-timer text-primary"></i> {{newbooking.services[0].duration}} {{newbooking.services[0].duration_units}}</span></p>
                  </div>
                </div>
                <div class="col-md-6 mb-4">
            
                <b-form-group
                    class="mb-2"
                    id="example-date-time"
                    label="Start Date & Time"
                    label-for="date-time"
                   
                  >
                    <b-form-input
                      id="date-time"
                       v-model="newbooking.start_date"
                      type="datetime-local"
                    ></b-form-input>
                  </b-form-group>

                  <h5>{{getDay(newbooking.start_date)}}</h5>
                  <div v-if="checkBookingDate(newbooking.start_date) == false">
                
                <p class="bg-danger bg-soft text-danger p-3 border-radius-1em">Your selected date does not match your allowed booking date or time for this service, please adjust the booking dates!</p>
              </div>    
              </div>
              <div class="col-md-6 mb-4">

               <b-form-group
                    class="mb-2"
                    id="example-date-time"
                    label="End Date & Time"
                    label-for="date-time"
                   
                  >
                    <b-form-input
                      id="date-time"
                       v-model="newbooking.end_date"
                      :min="newbooking.start_date"
                      type="datetime-local"
                    ></b-form-input>
                  </b-form-group>

                   <h5  v-if="loadingBookingDate == false">{{getDay(newbooking.end_date)}}</h5>
                   <div v-if="checkBookingDate(newbooking.end_date) == false">
                
                <p class="bg-danger bg-soft text-danger p-3 border-radius-1em">Your selected date does not match your allowed booking date or time for this service, please adjust the booking dates!</p>
              </div> 
                                </div>

                        
            <!--<div class="col-md-12 mt-4">
                <strong><i class="font-size-16 text-primary bx bxs-truck"></i> Payment Details</strong>
                <hr>
              </div>
          
              <div class="col-md-12 mb-2">
                <b-form-group class="mt-2" label="Payment Term">
                <SearchCompanyPaymentTerm :id="newbooking.payment_term" :api_token="$attrs.user.api_token" @payment-term-selected="selectPaymentTerm"></SearchCompanyPaymentTerm>

                <input type="hidden" name="payment_term_id" v-model="newbooking.payment_term_id">
              </b-form-group>
              </div>
              <div class="col-md-12 mb-2" v-if="newbooking.payment_term.recurring !== (false || 0)">
                {{newbooking.payment_term.name}}
                {{newbooking.payment_term.description}}
                Number of payments: {{newbooking.payment_term.recurring_payments}}
                Paying for: {{newbooking.payment_term.term_length}} {{newbooking.payment_term.term_period}}
              </div>
              <div class="col-md-12 mb-2" v-if="newbooking.payment_term.recurring !== (false || 0)">
                <b-form-group class="mt-2" label="Next Renewal Date">
                <b-form-datepicker id="action-duedate" class="mb-2" name="delivery_date" v-model="newbooking.renewal_date"></b-form-datepicker>
              </b-form-group>
              </div>

              <div class="col-md-12 mb-2">
                <b-form-group class="mt-2" label="Payment Method">
                <SearchCompanyPaymentMethod :id="newbooking.payment_method" :api_token="$attrs.user.api_token" @payment-method-selected="selectPaymentMethod"></SearchCompanyPaymentMethod>
                <input type="hidden" name="payment_method_id" v-model="newbooking.payment_method_id">
              </b-form-group>
              </div>
              
              <div class="col-md-12 mb-2">
                <b-form-group class="mt-2" label="Payment Date">
                <b-form-datepicker id="example-datepicker" class="mb-2" name="payment_date" v-model="newbooking.payment_date"></b-form-datepicker>
              </b-form-group>
              </div>
              -->


              <div class="col-md-12" v-if="newbooking.payment_method.integration_id !== 0">
                <strong><i class="font-size-16 text-primary" :class="newbooking.payment_method.icon"></i> Create New Payment</strong>
              </div>
              
                

              <div class="col-md-12 my-4">
                
                 <strong><i class="font-size-16 text-primary bx bx-notepad"></i> Notes</strong>
                 <hr>
                
                 <b-form-group class="mt-2" label="Notes">
                  <b-textarea type="text" name="notes" rows="4"></b-textarea>
                </b-form-group>
              </div>
              <div class="col-md-12 mb-4">
                 <strong><i class="font-size-16 text-primary bx bx-mail-send"></i> Invoice & Email</strong>
                 <hr>
                <div
                        class="form-check form-switch form-switch-sm mt-4 ml-auto"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          :id="`SwitchCheckSize-createpdf`"
                          v-model="newbooking.pdf"
                          value="1"
                          unchecked-value="0"
                          name="active"
                        />
                        <label class="form-check-label" :for="`SwitchCheckSize-createpdf`" 
                          >Create Invoice</label
                        >
                    </div>

                    <div
                        class="form-check form-switch form-switch-sm my-auto ml-auto"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          :id="`SwitchCheckSize-emailsupplier`"
                          v-model="newbooking.email"
                          value="1"
                          unchecked-value="0"
                          name="active"
                        />
                        <label class="form-check-label" :for="`SwitchCheckSize-emailsupplier`" 
                          >Email Booking to Customer</label
                        >
                    </div>
                
              </div>
              <div class="col-md-6 mt-2 mb-1">
                <a class="btn btn-success w-100" v-if="newbooking.id == null" @click="addBooking()">Save</a>
                <a class="btn btn-success w-100" v-if="newbooking.id !== null" @click="updateBooking()">Save</a>
              </div>
              <div class="col-md-6 mt-sm-1 mt-2 mb-4">
                <a class="btn btn-danger w-100" @click="addNewPanel = false">Cancel</a>
              </div>

              </div>
            </b-overlay>
            </form>
        
      </div>


    </SideSlidePanel>
    <b-modal ref="modal-add-address" id="modal-add-address" centered content-class="border-radius-1em" header-class="border-none" hide-footer>
 
    <CompanyCustomerAddressForm :address="newaddress" :action="'create'" :user="$attrs.user"></CompanyCustomerAddressForm>
 

  </b-modal>
    </div>
    <!-- end row -->
  </Layout>
</template>
