<template>
	<div>
	<b-img-lazy  :src="$attrs.content[filterStylesKey($attrs.content, 'url')].url.value" :width="$attrs.content[filterStylesKey($attrs.content, 'width')].width.value+$attrs.content[filterStylesKey($attrs.content, 'width')].width.units" :height="$attrs.content[filterStylesKey($attrs.content, 'height')].height.value+$attrs.content[filterStylesKey($attrs.content, 'height')].height.units" :class="{'img-fluid' : $attrs.content[filterStylesKey($attrs.content, 'fullwidth')].fullwidth.value == true}"></b-img-lazy>
	</div>
</template>

<script type="text/javascript">
	export default {
		data() {
	      return {
	        mainProps: {
	          center: true,
	          fluidGrow: true,
	          blank: true,
	          blankColor: '#bbb',
	        }
	      }
	    },
	    methods: {
		    filterStylesKey(styles, key){
	            for(var i = 0; i < styles.length; i++){
	               var keys = Object.keys(styles[i])
	               if(keys.includes(key)){
	                 return i;
	               }
	            }
	        },
		}
	}

</script>