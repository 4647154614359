export const menuItems = [
    {
        id: 6,
        label: 'menuitems.public.jewellery.text',
        isUiElement: true,
        subItems: [
            {
                id: 7,
                label: 'All Jewellery',
                link: '/category/jewellery',
                parentId: 6
            },
            {
                id: 8,
                label: 'Rings',
                link: '/category/rings',
                parentId: 6
            },
            {
                id: 9,
                label: 'Earrings',
                link: '/category/earrings',
                parentId: 6
            },
            {
                id: 10,
                label: 'Necklaces',
                link: '/category/necklaces',
                parentId: 6
            },
            {
                id: 11,
                label: 'Bracelets',
                link: '/category/bracelets',
                parentId: 6
            },
            {
                id: 12,
                label: 'Pendants',
                link: '/category/pendants',
                parentId: 6
            },
            {
                id: 13,
                label: 'Chains',
                link: '/category/chains',
                parentId: 6
            },
            {
                id: 14,
                label: 'Tiaras',
                link: '/category/tiaras',
                parentId: 6
            },
            {
                id: 15,
                label: 'Bangles',
                link: '/category/bangles',
                parentId: 6
            },
            {
                id: 15,
                label: 'All Categories',
                link: '/categories',
                parentId: 6
            }
        ]
    },
    {
        id: 70,
        
        label: 'menuitems.public.watches.text',
        link: '/category/watches'
    },
    {
        id: 25,
        label: 'menuitems.public.antiques.text',
        link: '/category/antiques'
    },
    
    {
        id: 97,
        label: 'menuitems.public.giftware.text',
        
    },
    {
        id: 97,
        label: 'menuitems.public.sellers.text',
        link: '/sellers'
    },
    {
        id: 97,
        label: 'menuitems.public.news.text',
        
    },
    {
        id: 97,
        label: 'menuitems.public.contact.text',
        link: '/contact'
    }
];

