<script>
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
import AccessRestricted from "../../views/utility/access-restricted";

import vue2Dropzone from 'vue2-dropzone'

export default {
  components: { vueDropzone: vue2Dropzone, Layout, PageHeader, AccessRestricted },
  data() {
    return {
      headers: '',
      title: "File Manager",
      files: [],
      file_uploaded: {file: '', file_extension: '', last_modified: '', size: 'Awaiting...'},
      newdirectory: '',
      protectedfile: 0,
      directories: [],
      usage_loading: true,
      bucket_size: 0,
      directory_error: '',
      move_from: '',
      move_to: '',
      dropzoneOptions: {
        url: 'https://httpbin.org/post',
        thumbnailHeight: 150,
        thumbnailWidth: 150,
        autoProcessQueue: false,
      },
      files_loading: true,
      total_size: {
        gb: 0,
        mb: 0,
      },
      current_directory: 
      {
        file: 'Root',
        folder_name: 'Root',
      },
      file_to_upload: {},
      upload_to_directory: '',
      items: [
        {
          text: "Apps",
          href: "/",
        },
        {
          text: "File Manager",
          active: true,
        },
      ],
      series: [76],
      chartOptions: {
        chart: {
          height: 150,
          type: "radialBar",
          sparkline: {
            enabled: true,
          },
        },
        colors: ["#556ee6"],
        plotOptions: {
          radialBar: {
            startAngle: -90,
            endAngle: 90,
            track: {
              background: "#e7e7e7",
              strokeWidth: "97%",
              margin: 5, // margin is in pixels
            },
            hollow: {
              size: "60%",
            },

            dataLabels: {
              name: {
                show: false,
              },
              value: {
                offsetY: -2,
                fontSize: "16px",
              },
            },
          },
        },
        grid: {
          padding: {
            top: -10,
          },
        },
        stroke: {
          dashArray: 3,
        },
        labels: ["Storage"],
      },
    };
  },
  created(){
      this.headers = {headers: {'Authorization' : 'Bearer '+ this.$attrs.user.api_token}};
      var pathname = window.location.pathname;
      if(pathname == '/company/storage'){
         this.loadFiles('');
         this.getBucketSize();
       }else{
        var foldername = pathname.split('/');
        console.log(foldername);
        this.loadFiles(foldername.slice(3, foldername.length - 1).replaceAll('-').toLowerCase());
       }

     

  }, 
  methods: {
    loadFiles(folder){
        axios.get('/api/company/storage/files?folder_path='+folder+'', this.headers).then(response => {
          console.log(response);
        this.files = response.data.file_details;
        this.directories = response.data.directories;
        this.total_size.mb = parseFloat(response.data.total_size).toFixed(2);
        this.total_size.gb = (parseFloat(response.data.total_size) / 1024).toFixed(2);
        var self = this;
                    setTimeout(function(){
                     self.files_loading = false;
                    }, 300);
        
      }).catch(error => {
        console.log(error);
      });
    },
    getBucketSize(){
      this.usage_loading = true;
      axios.get('/api/company/storage/size', this.headers).then(response => {
          console.log(response);
        this.bucket_size = response.data;
        this.series[0] = parseFloat(parseFloat(response.data / 20000) * 100).toFixed(0);
        var self = this;
                    setTimeout(function(){
                     self.usage_loading = false;
                    }, 300);
        
      }).catch(error => {
        console.log(error);
      });
    },
    addFile(){
      var postForm = new FormData(document.getElementById("add-file-form"));
      postForm.append('current_directory', '/'+ this.$attrs.company.uid + '/' + this.current_directory.file.replace('Root', ''));
      postForm.append('file', this.file_to_upload);
      axios.post('/api/company/storage/file/store', postForm, {
        headers: { 'Authorization' : 'Bearer '+this.$attrs.user.api_token }
      }).then(response =>{
        this.file_uploaded.file = response.data;
        var extension = response.data.split('.');
        this.file_uploaded.file_extension = extension[extension.length - 1];
        this.file_uploaded.last_modified = DateTime.local().toSQL();
        this.files[0] = this.file_uploaded;
        this.$bVModal.hide('add-file');
      }).catch(error => {
        console.log(error);
      });
    },
    fileAdded(file){
            this.file_to_upload = file;
    },
    addDirectory(directory, newdirectory, protectedfile){
      if(!newdirectory.includes('protected') || !newdirectory.includes('public')){
        axios.post('/api/company/storage/directory/create', {current_directory: directory, foldername: newdirectory, protected: protectedfile }, {
          headers: { 'Authorization' : 'Bearer '+this.$attrs.user.api_token }
        }).then(response => {
          console.log(newdirectory);
          if(this.current_directory.file !== 'Root'){
          this.loadFiles(current_directory.file);
          }else{
            this.loadFiles('');
          }
          this.$bVModal.hide('add-folder');
        }).catch(error => {

        });
      }else{
        this.directory_error = "Cannot use reserved words public or protected in directory names";
      }
    },
    openDirectory(){

    },
    openFile(file_path){
         axios.get('/api/company/storage/file/url?filename='+file_path+'', this.headers).then(response => {
      console.log(response);
      var fileURL = window.open(response.data);
        fileLink.click();
      }).catch(error => {
        console.log(error);
      });
    },
    downloadFile(file_path, filename){
      axios({url: '/api/company/storage/file/download?filename='+file_path+'', method: 'GET', responseType: 'blob', headers: {'Authorization' : 'Bearer '+this.$attrs.user.api_token}}).then(response => {
      console.log(new Blob([response.data]));
      var fileURL = window.URL.createObjectURL(new Blob([response.data]));
       var fileLink = document.createElement('a');
       fileLink.href = fileURL;
       fileLink.setAttribute('download', filename);
       document.body.appendChild(fileLink);
     fileLink.click();
    }).catch(error => {
      console.log(error);
    });
    },
    moveFile(current_path, new_path){
      axios.post('/api/company/storage/file/download?filename='+current_path+'&new_filename='+new_path+'', this.headers).then(response => {
        if(response.data == true){
          this.loadFiles(new_path);
        }
      }).catch(error => {
        console.log(error);
      });
    }, 
    deleteFile(file){
      axios.post('/api/company/storage/file/delete?filename='+current_path+'', this.headers).then(response => {
        if(response.data == true){
          this.loadFiles();
        }
      }).catch(error => {
        console.log(error);
      });
    },
    deleteDirectory(directory){
       axios.post('/api/company/storage/directory/delete?filename='+current_path+'', this.headers).then(response => {
        if(response.data == true){
          this.loadFiles();
        }
      }).catch(error => {
        console.log(error);
      });
    }
  }
};
</script>

<template>
  <Layout :nickname="$attrs.nickname" :user="$attrs.user">
    <PageHeader :title="title" :items="items" />
    <AccessRestricted v-if="(($attrs.user.companies[0].userrole.name !== ('Owner' || 'Manager' ))) && !$attrs.user.companies[0].permissions.view.includes($attrs.active_menu.id)" />
    <div class="d-xl-flex" v-else>
      <div class="w-100">
        <div class="d-md-flex">
          <div class="card filemanager-sidebar mr-md-2">
            <div class="card-body">
              <div class="d-flex flex-column h-100">
                <div class="mb-4">
                  <div class="mb-3">
                    <b-dropdown
                      toggle-class="btn-block"
                      variant="light"
                      class="btn-block"
                    >
                      <template #button-content>
                        <i class="mdi mdi-plus mr-1"></i> Create New
                      </template>
                      <b-dropdown-item href="#" v-b-modal.add-folder
                        ><i class="bx bx-folder mr-1"></i>
                        Folder</b-dropdown-item
                      >
                      <b-dropdown-item href="#" v-b-modal.add-file
                        ><i class="bx bx-file mr-1"></i> File</b-dropdown-item
                      >
                    </b-dropdown>
                  </div>
                  <ul class="list-unstyled categories-list">
                      <b-overlay
                        id="overlay-background"
                        :show="files_loading"
                        variant="light"
                        opacity="0.85"
                        blur="1px"
                        rounded="md"
                        no-wrap
                      >
                          </b-overlay>
                    <li>
                      <div class="custom-accordion">
                        <a
                          class="text-body font-weight-medium py-1 d-flex align-items-center"
                          data-toggle="collapse"
                          v-b-toggle.categories-collapse
                          role="button"
                          aria-expanded="false"
                          aria-controls="categories-collapse"
                        >
                          <i
                            class="mdi mdi-folder font-size-16 text-warning mr-2 text-capitalizet"
                          ></i>
                          {{current_directory.folder_name}}
                          <i
                            class="mdi mdi-chevron-up accor-down-icon ml-auto"
                          ></i>
                        </a>
                        <b-collapse
                          visible
                          class="collapse show"
                          id="categories-collapse"
                        >
                          <div class="card border-0 shadow-none pl-2 mb-0">
                            <ul class="list-unstyled mb-0">
                              <li v-if="current_directory.file !== current_directory.folder_name">
                                 <a  href="javascript:;" class="d-flex align-items-center" @click="files_loading = true, current_directory.file = (current_directory.file).replace('/'+current_directory.folder_name+'',''), loadFiles(current_directory.file.replace(''+current_directory.folder_name+'/','')), current_directory.folder_name = current_directory.file.split('/').pop()"
                                  ><span class="mr-auto text-capitalize text-truncate"><i
                                  class="bx bx-left-arrow-alt text-secondary"
                                ></i> Back to {{current_directory.file.split('/').reverse()[1]}}</span></a
                                >
                              </li>
                              <li v-if="(current_directory.file == current_directory.folder_name) && ((current_directory.file && current_directory.folder_name) !== 'Root')">
                                 <a  href="javascript:;" class="d-flex align-items-center" @click="files_loading = true, current_directory.file = 'Root', loadFiles(''), current_directory.folder_name = 'Root'"
                                  ><span class="mr-auto text-capitalize text-truncate"><i
                                  class="bx bx-left-arrow-alt text-secondary"
                                ></i> Back</span></a
                                >
                              </li>
                              <li v-for="directory in directories" v-if="directory.file.includes('public/')">
                                <a href="javascript:;" class="d-flex align-items-center" @click="files_loading = true, loadFiles(directory.file.replace(''+$attrs.company.uid+'/', '')), current_directory.folder_name = directory.file.replace(''+$attrs.company.uid+'/', '').replace(''+current_directory.file+'/',''), current_directory.file = directory.file.replace(''+$attrs.company.uid+'/', '')"
                                  ><span class="mr-auto text-capitalize text-truncate">{{(directory.file.replace(''+$attrs.company.uid+'/', '')).replace(''+current_directory.file+'/','').replace('public/', '')}}</span></a
                                >
                              </li>
                              <li v-if="current_directory.file == 'Root'" class="d-flex align-items-center">
                               <i class="mdi mdi-folder font-size-16 text-warning mr-2 text-capitalizet"></i> Protected Files <i class="bx bx-lock font-size-14 text-primary ml-auto"></i>
                              </li>
                              <li v-for="directory in directories" v-if="directory.file.includes('protected/')">
                                <a href="javascript:;" class="d-flex align-items-center" @click="files_loading = true, loadFiles(directory.file.replace(''+$attrs.company.uid+'/', '')), current_directory.folder_name = directory.file.replace(''+$attrs.company.uid+'/', '').replace(''+current_directory.file+'/',''), current_directory.file = directory.file.replace(''+$attrs.company.uid+'/', '')"
                                  ><span class="mr-auto text-capitalize text-truncate">{{(directory.file.replace(''+$attrs.company.uid+'/', '')).replace(''+current_directory.file+'/','').replace('protected/', '')}}</span></a
                                >
                              </li>
                              
                              <!--<li>
                                <a href="#" class="d-flex align-items-center"
                                  ><span class="mr-auto">Design</span></a
                                >
                              </li>
                              <li>
                                <a href="#" class="d-flex align-items-center"
                                  ><span class="mr-auto">Development</span>
                                  <i class="mdi mdi-pin ml-auto"></i
                                ></a>
                              </li>
                              <li>
                                <a href="#" class="d-flex align-items-center"
                                  ><span class="mr-auto">Project A</span></a
                                >
                              </li>
                              <li>
                                <a href="#" class="d-flex align-items-center"
                                  ><span class="mr-auto">Admin</span>
                                  <i class="mdi mdi-pin ml-auto"></i
                                ></a>
                              </li>-->
                            </ul>
                          </div>
                        </b-collapse>
                      </div>
                    </li>
                    <!--<li>
                      <a
                        href="javascript: void(0);"
                        class="text-body d-flex align-items-center"
                      >
                        <i
                          class="mdi mdi-google-drive font-size-16 text-muted mr-2"
                        ></i>
                        <span class="mr-auto">Google Drive</span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="javascript: void(0);"
                        class="text-body d-flex align-items-center"
                      >
                        <i
                          class="mdi mdi-dropbox font-size-16 mr-2 text-primary"
                        ></i>
                        <span class="mr-auto">Dropbox</span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="javascript: void(0);"
                        class="text-body d-flex align-items-center"
                      >
                        <i class="mdi mdi-share-variant font-size-16 mr-2"></i>
                        <span class="mr-auto">Shared</span>
                        <i class="mdi mdi-circle-medium text-danger ml-2"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="javascript: void(0);"
                        class="text-body d-flex align-items-center"
                      >
                        <i
                          class="mdi mdi-star-outline text-muted font-size-16 mr-2"
                        ></i>
                        <span class="mr-auto">Starred</span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="javascript: void(0);"
                        class="text-body d-flex align-items-center"
                      >
                        <i
                          class="mdi mdi-trash-can text-danger font-size-16 mr-2"
                        ></i>
                        <span class="mr-auto">Trash</span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="javascript: void(0);"
                        class="text-body d-flex align-items-center"
                      >
                        <i class="mdi mdi-cog text-muted font-size-16 mr-2"></i>
                        <span class="mr-auto">Settings</span
                        ><span class="badge badge-success badge-pill ml-2"
                          >01</span
                        >
                      </a>
                    </li>-->
                  </ul>
                </div>
                 <div class="text-center mt-auto">
            <h5 class="font-size-15 mb-4">Storage</h5>
            <apexchart
              class="apex-charts"
              type="radialBar"
              height="150"
              dir="ltr"
              :series="series"
              :options="chartOptions"
            ></apexchart>

            <p class="text-muted mt-4">{{bucket_size}} MB / {{parseFloat(parseFloat(bucket_size) / 1024).toFixed(6)}} GB of 20 GB used.</p>
          </div>
                <!--<div class="mt-auto">
                  <b-alert show dismissible variant="success">
                    <div class="mb-3">
                      <i class="bx bxs-folder-open h1 text-success"></i>
                    </div>

                    <div>
                      <h5 class="text-success">Upgrade Features</h5>
                      <p>Cum sociis natoque penatibus et</p>
                      <div class="text-center">
                        <button
                          type="button"
                          class="btn btn-link text-decoration-none text-success"
                        >
                          Upgrade <i class="mdi mdi-arrow-right"></i>
                        </button>
                      </div>
                    </div>
                  </b-alert>
                </div>-->
              </div>
            </div>
          </div>
          <!-- filemanager-leftsidebar -->

          <div class="w-100">
            <div class="card">
              <div class="card-body">
                <div>
                  <div class="row mb-3">
                    <div class="col-xl-3 col-sm-6 d-flex">
                      <div class="my-auto">
                        <h5 class="my-auto">My Files</h5>
                      </div>
                    </div>
                    <div class="col-xl-9 col-sm-6">
                      <form class="my-auto d-flex float-sm-right form-inline">
                        <div class="search-box my-auto mr-2 w-100">
                          <div class="position-relative">
                            <input
                              type="text"
                              class="form-control bg-light border-light rounded"
                              placeholder="Search..."
                            />
                            <i class="bx bx-search-alt search-icon"></i>
                          </div>
                        </div>

                        <b-dropdown
                          class="mb-0"
                          toggle-class="btn btn-link text-muted"
                          right
                          variant="white"
                        >
                          <template #button-content>
                            <i class="mdi mdi-dots-vertical font-size-20"></i>
                          </template>
                          <b-dropdown-item href="#"
                            >Share Files</b-dropdown-item
                          >
                          <b-dropdown-item href="#"
                            >Share with me</b-dropdown-item
                          >
                          <b-dropdown-item href="#"
                            >Other Actions</b-dropdown-item
                          >
                        </b-dropdown>
                      </form>
                    </div>
                  </div>
                </div>
                <hr class="mb-4" />
                <div>
                  <div class="row">
                    <b-overlay
                        id="overlay-background"
                        :show="files_loading"
                        variant="light"
                        opacity="0.85"
                        blur="1px"
                        rounded="md"
                        no-wrap
                      >
                          </b-overlay>
                     <div class="col-xl-4 col-sm-6" v-if="current_directory.file !== 'Root'">
                      <a v-if="current_directory.file !== current_directory.folder_name"  href="javascript:;" class="w-100" @click="files_loading = true, current_directory.file = (current_directory.file).replace('/'+current_directory.folder_name+'',''), loadFiles(current_directory.file.replace(''+current_directory.folder_name+'/','')), current_directory.folder_name = current_directory.file.split('/').pop()"
                                  >
                      <div class="card shadow-none border">
                        <div class="card-body p-3">
                          <div class="">
                            <div class="float-right ml-2">
                              
                            </div>
                            <div class="avatar-xs mr-3 mb-3">
                              <div class="avatar-title bg-transparent rounded">
                                <i
                                  class="bx bx-left-arrow-alt font-size-24 text-secondary"
                                ></i>
                              </div>
                            </div>
                            <div class="d-flex">
                              <div class="overflow-hidden mr-auto">
                                <h5 class="font-size-14 text-truncate mb-1">
                             
                               
                                 <a v-if="current_directory.file !== current_directory.folder_name"  href="javascript:;" class="w-100" @click="files_loading = true, current_directory.file = (current_directory.file).replace('/'+current_directory.folder_name+'',''), loadFiles(current_directory.file.replace(''+current_directory.folder_name+'/','')), current_directory.folder_name = current_directory.file.split('/').pop()"
                                  ><span class="mr-auto text-capitalize text-truncate">Back</span></a
                                >
                     
                                </h5>
                                <p class="text-muted text-truncate mb-0">
                                  Go Back
                                </p>
                              </div>
                              <div class="align-self-end ml-2">
                                <p class="text-muted mb-0"></p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                     <a v-if="(current_directory.file == current_directory.folder_name) && ((current_directory.file && current_directory.folder_name) !== 'Root')" href="javascript:;" class="w-100" @click="files_loading = true, current_directory.file = 'Root', loadFiles(''), current_directory.folder_name = 'Root'" >
                      <div class="card shadow-none border">
                        <div class="card-body p-3">
                          <div class="">
                            <div class="float-right ml-2">
                              
                            </div>
                            <div class="avatar-xs mr-3 mb-3">
                              <div class="avatar-title bg-transparent rounded">
                                <i
                                  class="bx bx-left-arrow-alt font-size-24 text-secondary"
                                ></i>
                              </div>
                            </div>
                            <div class="d-flex">
                              <div class="overflow-hidden mr-auto">
                                <h5 class="font-size-14 text-truncate mb-1">
                               
                                 <a v-if="(current_directory.file == current_directory.folder_name) && ((current_directory.file && current_directory.folder_name) !== 'Root')" href="javascript:;" class="d-flex align-items-center" @click="files_loading = true, current_directory.file = 'Root', loadFiles(''), current_directory.folder_name = 'Root'"
                                  ><span class="mr-auto text-capitalize text-truncate">Back</span></a
                                >
                     
                                </h5>
                                <p class="text-muted text-truncate mb-0">
                                  
                                </p>
                              </div>
                              <div class="align-self-end ml-2">
                                <p class="text-muted mb-0"></p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                    </div>
                    <div class="col-xl-4 col-sm-6"  v-for="directory in directories.filter(item => item.file.includes('public/'))">
                      <a href="javascript:;" class="" @click="files_loading = true, loadFiles(directory.file.replace(''+$attrs.company.uid+'/', '')), current_directory.folder_name = directory.file.replace(''+$attrs.company.uid+'/', '').replace(''+current_directory.file+'/',''), current_directory.file = directory.file.replace(''+$attrs.company.uid+'/', '')"
                                  >
                      <div class="card shadow-none border">
                        <div class="card-body p-3">
                          <div class="">
                            <div class="float-right ml-2">
                              <b-dropdown
                                toggle-class="font-size-16 text-muted p-0"
                                class="mb-2"
                                variant="white"
                                right
                              >
                                <template #button-content>
                                  <i class="mdi mdi-dots-horizontal"></i>
                                </template>

                                <b-dropdown-item href="#">Open</b-dropdown-item>
                                <b-dropdown-item href="#">Edit</b-dropdown-item>
                                <b-dropdown-item href="#"
                                  >Rename</b-dropdown-item
                                >
                                <b-dropdown-divider></b-dropdown-divider>
                                <b-dropdown-item href="#"
                                  >Remove</b-dropdown-item
                                >
                              </b-dropdown>
                            </div>
                            <div class="avatar-xs mr-3 mb-3">
                              <div class="avatar-title bg-transparent rounded">
                                <i
                                  class="bx bxs-folder font-size-24 text-warning"
                                ></i>
                              </div>
                            </div>
                            <div class="d-flex">
                              <div class="overflow-hidden mr-auto">
                                <h5 class="font-size-14 text-truncate mb-1">
                                  <a href="javascript:;" class="d-flex align-items-center" @click="files_loading = true, loadFiles(directory.file.replace(''+$attrs.company.uid+'/', '')), current_directory.folder_name = directory.file.replace(''+$attrs.company.uid+'/', '').replace(''+current_directory.file+'/',''), current_directory.file = directory.file.replace(''+$attrs.company.uid+'/', '')"
                                  ><span class="mr-auto text-capitalize text-truncate">{{(directory.file.replace(''+$attrs.company.uid+'/', '')).replace(''+current_directory.file+'/','').replace('public/', '')}}</span></a
                                >
                                </h5>
                                <p class="text-muted text-truncate mb-0">
                              
                                </p>
                              </div>
                              <div class="align-self-end ml-2">
                                <p class="text-muted mb-0"></p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                    </div>
                    <div v-if="current_directory.file == 'Root'" class="col-md-12 d-flex">
                      <h5 class="my-auto mr-auto">Protected Files</h5>
                      <i class="bx bx-lock font-size-20 text-primary"></i>
                    </div>
                      <div v-if="current_directory.file == 'Root'" class="col-md-12 ">
                        <hr class="mt-4">
                    </div>
                    <div class="col-xl-4 col-sm-6"  v-for="directory in directories.filter(item => item.file.includes('protected/'))">
                      <a href="javascript:;" class="" @click="files_loading = true, loadFiles(directory.file.replace(''+$attrs.company.uid+'/', '')), current_directory.folder_name = directory.file.replace(''+$attrs.company.uid+'/', '').replace(''+current_directory.file+'/',''), current_directory.file = directory.file.replace(''+$attrs.company.uid+'/', '')"
                                  >
                      <div class="card shadow-none border">
                        <div class="card-body p-3">
                          <div class="">
                            <div class="float-right ml-2">
                              <b-dropdown
                                toggle-class="font-size-16 text-muted p-0"
                                class="mb-2"
                                variant="white"
                                right
                              >
                                <template #button-content>
                                  <i class="mdi mdi-dots-horizontal"></i>
                                </template>

                                <b-dropdown-item href="#">Open</b-dropdown-item>
                                <b-dropdown-item href="#">Edit</b-dropdown-item>
                                <b-dropdown-item href="#"
                                  >Rename</b-dropdown-item
                                >
                                <b-dropdown-divider></b-dropdown-divider>
                                <b-dropdown-item href="#"
                                  >Remove</b-dropdown-item
                                >
                              </b-dropdown>
                            </div>
                            <div class="avatar-xs mr-3 mb-3">
                              <div class="avatar-title bg-transparent rounded">
                                <i
                                  class="bx bxs-folder font-size-24 text-warning"
                                ></i>
                              </div>
                            </div>
                            <div class="d-flex">
                              <div class="overflow-hidden mr-auto">
                                <h5 class="font-size-14 text-truncate mb-1">
                                  <a href="javascript:;" class="d-flex align-items-center" @click="files_loading = true, loadFiles(directory.file.replace(''+$attrs.company.uid+'/', '')), current_directory.folder_name = directory.file.replace(''+$attrs.company.uid+'/', '').replace(''+current_directory.file+'/',''), current_directory.file = directory.file.replace(''+$attrs.company.uid+'/', '')"
                                  ><span class="mr-auto text-capitalize text-truncate">{{(directory.file.replace(''+$attrs.company.uid+'/', '')).replace(''+current_directory.file+'/','').replace('protected/', '')}}</span></a
                                >
                                </h5>
                                <p class="text-muted text-truncate mb-0">
                              
                                </p>
                              </div>
                              <div class="align-self-end ml-2">
                                <p class="text-muted mb-0"></p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                    </div>
                    <!-- end col -->
                  </div>
                  <!-- end row -->
                </div>

                <div class="mt-4">
                  <div class="d-flex flex-wrap">
                    <h5 class="font-size-14 mr-3">Files in <span class="text-info text-capitalize">{{current_directory.folder_name}}</span></h5>

                    <div class="ml-auto">
                      <a href="javascript: void(0);">View All</a>
                    </div>
                  </div>
                  <hr class="mt-2" />

                  <div class="table-responsive"  style="min-height: 300px">
                    <table
                      class="table table-centered table-nowrap table-hover mb-0"
                    >
                      <thead>
                        <tr>
                          <th scope="col">Name</th>
                          <th scope="col">Date modified</th>
                          <th scope="col" colspan="2">Size</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr  v-for="object in files">
                          <td>
                            <a
                              href="javascript: void(0);"
                              class="text-dark font-weight-medium"
                              ><i
                                class="mdi font-size-16 align-middle mr-2" :class="{'mdi-image text-success': object.file_extension == ('jpg' || 'png'), 'mdi-folder-zip' : object.file_extension == ('zip'), 'mdi-file-document text-primary' : object.file_extension == ('doc' || 'docx'), 'mdi-file-pdf text-danger' : object.file_extension == ('pdf')}"
                              ></i>
                              {{((object.file).replace(''+$attrs.company.uid+'/', '')).replace(''+current_directory.file+'/', '')}}</a
                            >
                          </td>
                          <td>{{object.last_modified }}</td>
                          <td>{{object.size}} MB</td>
                          <td>
                            <b-dropdown
                              toggle-class="font-size-16 text-muted p-0"
                              variant="white"
                              right
                            >
                              <template #button-content>
                                <i class="mdi mdi-dots-horizontal"></i>
                              </template>

                              <b-dropdown-item href="javascript:;" @click="openFile(object.file)">Open</b-dropdown-item>
                              <b-dropdown-item href="javascript:;" @click="downloadFile(object.file, (object.file).replace(''+$attrs.company.uid+'/', ''))">Download</b-dropdown-item>
                              <b-dropdown-item href="#" v-b-modal.modal-rename >Rename</b-dropdown-item>
                              <b-dropdown-item href="#" v-b-modal.modal-move>Move</b-dropdown-item>
                              <b-dropdown-divider></b-dropdown-divider>
                              <b-dropdown-item href="#" v-b-modal.modal-delete>Delete</b-dropdown-item>
                            </b-dropdown>
                            <b-modal id="modal-rename" title="Rename File" centered hide-footer title-class="font-18">
                             

                              <b-form-group label="Current Name">
                                <b-form-input type="text" disabled :value="object.file.replace(''+$attrs.company.uid+'/', '')" />
                              </b-form-group>
                              <b-form-group label="New Name">
                                <b-form-input type="text" v-model="move_to" />
                              </b-form-group>
                              <a class="btn btn-success btn-block" @click="moveFile(object.file, move_to)">Rename File</a>
                            </b-modal>
                            <b-modal id="modal-move" title="Move File" centered hide-footer title-class="font-18">
                             

                              <b-form-group label="Current Name">
                                <b-form-input type="text" disabled :value="object.file.replace(''+$attrs.company.uid+'/', '')" />
                              </b-form-group>
                              <b-form-group label="New Name">
                                <b-form-input type="text" v-model="move_to" />
                              </b-form-group>
                              <a class="btn btn-success btn-block" @click="moveFile(object.file.replace(''+$attrs.company.uid+'/', ''), move_to)">Rename File</a>
                            </b-modal>
                            <b-modal id="modal-delete" title="Confirm Delete" centered hide-footer title-class="font-18">
                             <a class="btn btn-danger btn-block" @click="deleteFile(object.file)">Yes, Delete this File!</a>
                            </b-modal>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <!-- end card -->
          </div>
          <!-- end w-100 -->
        </div>
      </div>

       <!-- <div class="card filemanager-sidebar ml-lg-2">
        <div class="card-body">
         

        <div class="mt-4">
            <div class="card border shadow-none mb-2">
              <a href="javascript: void(0);" class="text-body">
                <div class="p-2">
                  <div class="d-flex">
                    <div class="avatar-xs align-self-center mr-2">
                      <div
                        class="avatar-title rounded bg-transparent text-success font-size-20"
                      >
                        <i class="mdi mdi-image"></i>
                      </div>
                    </div>

                    <div class="overflow-hidden mr-auto">
                      <h5 class="font-size-13 text-truncate mb-1">Images</h5>
                      <p class="text-muted text-truncate mb-0">176 Files</p>
                    </div>

                    <div class="ml-2">
                      <p class="text-muted">6 GB</p>
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <div class="card border shadow-none mb-2">
              <a href="javascript: void(0);" class="text-body">
                <div class="p-2">
                  <div class="d-flex">
                    <div class="avatar-xs align-self-center mr-2">
                      <div
                        class="avatar-title rounded bg-transparent text-danger font-size-20"
                      >
                        <i class="mdi mdi-play-circle-outline"></i>
                      </div>
                    </div>

                    <div class="overflow-hidden mr-auto">
                      <h5 class="font-size-13 text-truncate mb-1">Video</h5>
                      <p class="text-muted text-truncate mb-0">45 Files</p>
                    </div>

                    <div class="ml-2">
                      <p class="text-muted">4.1 GB</p>
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <div class="card border shadow-none mb-2">
              <a href="javascript: void(0);" class="text-body">
                <div class="p-2">
                  <div class="d-flex">
                    <div class="avatar-xs align-self-center mr-2">
                      <div
                        class="avatar-title rounded bg-transparent text-info font-size-20"
                      >
                        <i class="mdi mdi-music"></i>
                      </div>
                    </div>

                    <div class="overflow-hidden mr-auto">
                      <h5 class="font-size-13 text-truncate mb-1">Music</h5>
                      <p class="text-muted text-truncate mb-0">21 Files</p>
                    </div>

                    <div class="ml-2">
                      <p class="text-muted">3.2 GB</p>
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <div class="card border shadow-none mb-2">
              <a href="javascript: void(0);" class="text-body">
                <div class="p-2">
                  <div class="d-flex">
                    <div class="avatar-xs align-self-center mr-2">
                      <div
                        class="avatar-title rounded bg-transparent text-primary font-size-20"
                      >
                        <i class="mdi mdi-file-document"></i>
                      </div>
                    </div>

                    <div class="overflow-hidden mr-auto">
                      <h5 class="font-size-13 text-truncate mb-1">Document</h5>
                      <p class="text-muted text-truncate mb-0">21 Files</p>
                    </div>

                    <div class="ml-2">
                      <p class="text-muted">2 GB</p>
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <div class="card border shadow-none">
              <a href="javascript: void(0);" class="text-body">
                <div class="p-2">
                  <div class="d-flex">
                    <div class="avatar-xs align-self-center mr-2">
                      <div
                        class="avatar-title rounded bg-transparent text-warning font-size-20"
                      >
                        <i class="mdi mdi-folder"></i>
                      </div>
                    </div>

                    <div class="overflow-hidden mr-auto">
                      <h5 class="font-size-13 text-truncate mb-1">Others</h5>
                      <p class="text-muted text-truncate mb-0">20 Files</p>
                    </div>

                    <div class="ml-2">
                      <p class="text-muted">1.4 GB</p>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>-->
    </div>
    <b-modal id="add-file" hide-footer centered size="lg" title="Add File">
      <form id="add-file-form" method="post" enctype="multipart/form-data">
       <vue-dropzone id="dropzone" ref="myVueDropzone" :use-custom-slot="true" :options="dropzoneOptions" @vdropzone-file-added="fileAdded"  @vdropzone-drop="fileAdded">
                        <div class="dropzone-custom-content">
                            <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                            <h4>Drop file here or click to upload.</h4>
                        </div>
                        </vue-dropzone>
                        <a class="btn btn-primary btn-block mt-3" @click="addFile()">
                          Upload File
                        </a>
                      </form>
    </b-modal>
     <b-modal id="add-folder" hide-footer centered title="Add Directory">
      <form id="add-folder-form" method="post" enctype="multipart/form-data">
      <p class="bg-soft bg-danger text-danger p-3 rounded-3" v-if="directory_error !== ''">
        {{directory_error}}
      </p>
       <b-form-group label="Path">
        <b-input-group :prepend="current_directory.file" @keydown.enter.prevent="addDirectory(current_directory.folder_name, newdirectory)">
          <b-form-input type="text" name="newdirectory" v-model="newdirectory">
          </b-form-input>
        </b-input-group>
      
       </b-form-group>
       <b-form-group class="mt-3" label="Folder Access"  v-if="current_directory.file == 'Root'">
        <b-form-radio-group v-model="protectedfile" name="protected" :options="[{text: 'Public', value: false}, {text: 'Protected', value: true}]"></b-form-radio-group>
        </b-form-group>
       <a class="btn btn-primary btn-block mt-3" @click="addDirectory(current_directory.folder_name, newdirectory, protectedfile)">
                         Create Directory
                        </a>
                      </form>
    </b-modal>
    <!-- end row -->
  </Layout>
</template>