<script type="text/javascript">
	import EventBus from '../public-app'
	import AccountOverview from './account/overview'
	import Login from "../public-views/account/onpageLogin";
	import PageHeader from "../components/widgets/ecommerce/public/PageHeader";
	import vue2Dropzone from 'vue2-dropzone';

	export default{
		components: {AccountOverview, Login, PageHeader, vueDropzone: vue2Dropzone},
		data(){
			return{
				csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
				updateAvatar: false,
				breadcrumbs: [],
				cardExpanded: '',
				orders: '',
				bread: {
					title: '',
					link: '',
					id: 1,
				},

				avatarDropzoneOptions: {
		        url: 'https://httpbin.org/post',
		        thumbnailHeight: 150,
		        thumbnailWidth: 150,
		        autoProcessQueue: false,
		        maxFiles: 1,
		        acceptedFiles: '.jpg, .png, .webp',
		      },
				account_tabs: [
					{
						title: 'Overview',
						link: '',
						icon: '',
						active: true,
						id: 1,
					},
					{
						title: 'Addresses',
						link: 'addresses',
						icon: '',
						active: false,
						id: 2,
					},
					{
						title: 'Orders',
						link: 'orders',
						icon: '',
						active: false,
						id: 3,
					},
					/**{
						title: 'Payment Details',
						link: '',
						icon: '',
						active: false,
						id: 3,
					},**/
					{
						title: 'Messages',
						link: 'messages',
						icon: '',
						active: false,
						id: 3,
					},
					{
						title: 'Wishlist',
						link: 'wishlist',
						icon: '',
						active: false,
						id: 3,
					},
					{
						title: 'Favourites',
						link: 'favourites',
						icon: '',
						active: false,
						id: 3,
					},
				]
			}
		},
		props: {
			user: {
				type: Object
			}
		},
		mounted() {
			//this.cardExpanded = (location.hash).replace('#', '');
			this.cardExpanded = this.$route.params.section;
			if(this.cardExpanded !== '' || null){
			this.title = this.cardExpanded;
			this.title[0].toUpperCase();
			}
			this.breadcrumbs = this.$route.path.split('/');
			this.breadcrumbs[1] = this.breadcrumbs[1].replaceAll('-',' ');
				if(this.breadcrumbs.length > 0){
				this.title = this.breadcrumbs[this.breadcrumbs.length - 1];
				this.breadcrumbs[0] = "Home";
				}
				let self = this;
				EventBus.$on('remove-hash', function(){
					self.removeHash();
				})
			
		},
		methods: {
			removeHash () { 
			    var scrollV, scrollH, loc = window.location;
			    if ("pushState" in history)
			        history.pushState("", document.title, loc.pathname + loc.search);
			    else {
			        // Prevent scrolling by storing the page's current scroll offset
			        scrollV = document.body.scrollTop;
			        scrollH = document.body.scrollLeft;

			        loc.hash = "";

			        // Restore the scroll offset, should be flicker free
			        document.body.scrollTop = scrollV;
			        document.body.scrollLeft = scrollH;
			    }
			},
			avatarFileAdded(file){
				this.user.avatar = file;
			},
			uploadAvatar(){
        	this.loadingUploadWindow = true;
        	var postForm = new FormData(document.getElementById("upload-avatar"));
        	postForm.append('avatar', this.company_update.logo);
        	axios.post('/api/company/update-logo', postForm, { headers: {
        		'Authorization': 'Bearer '+this.$attrs.user.api_token
        	} }).then(response => {

        		this.success_text = 'Congratulations, your default logo has been updated!';
              	this.success_count = 5;
              	this.success_message = true;

              	let self = this;
        		setTimeout(function () {
        			self.loadingUploadWindow = false;
        		}, 300);

        		setTimeout(function () {
        			self.success_message = false;
        		}, 500);
        	}).catch(error => {
        		this.error_reponse = error;
        		this.error_message = true;
        	});
        },
		},
		watch: {
        $route() {
        	this.$nextTick(() => {
        		 //this.cardExpanded = (location.hash).replace('#', '');
							this.cardExpanded = this.$route.params.section;
							this.title = this.cardExpanded;
							this.title[0].toUpperCase();
        		});
        	}
        }
		
	}

</script>
<template>
	<div>

		<section >
			 <PageHeader :data="{name: title, description: ''}" />
		  <div class="container-fullwidth" style="background: #fff">

		   
		    <b-row class="pb-4">
		    	<b-col md="12" class="profile-user-wid">
		    	<b-row class="pb-5" v-if="user == ''">
			    		<b-col md="6" class="mx-auto">
				    		<Login submit-url="http://gemesys.co.uk/my-account/login" auth-error="">
		             <input type="hidden" name="_token" :value="csrf">
		        		</Login>
	        		</b-col>
		    	</b-row>
		    	<b-row class="pb-5" v-else>
		    		<b-col md="7" class="ml-auto profile-user-wid">
		      	<AccountOverview :user="user" :cardExpanded="cardExpanded"></AccountOverview>
		        </b-col>
		        <b-col md="3" class="mr-auto">
		        	<div class="avatar-xl profile-user-wid mb-2 mr-auto w-100">
		        		<b-card class="border-radius-1em">
		        			<div class="avatar-xl profile-user-wid mb-2 mx-auto position-relative" style="margin-top: -75px !important;" @mouseenter="updateAvatar = !updateAvatar" @mouseleave="updateAvatar = !updateAvatar">
                  <img
                  	v-if="user.avatar" :src="storageurl + user.avatar"
                    alt
                    class="img-thumbnail rounded-circle"
                  />
                   <img
                  	v-else 
                    src="/images/product-placeholder.png"
                    alt
                    class="img-thumbnail rounded-circle"
                  />
                <div class="avatar-xl bg-soft avatar-title top-0 mb-2 mx-auto position-absolute rounded-circle w-100 opacity-75 text-uppercase font-size-10" v-if="updateAvatar == true" v-b-modal.update-avatar>
                	
                	Update Avatar
                </div>
                </div>

		        			<h5 class="text-center" v-if="user">{{user.name}} {{user.lastname}}</h5>
		        			<p  class="text-muted mb-4 text-center">
			                    <a href="javascript:;">{{user.email}}</a>
			                  </p>
			            
		        			<router-link v-for="tab in account_tabs" class="btn btn-outline-light btn-rounded btn-block mb-2" :to="'/my-account/'+tab.link" >{{tab.title}}<i :class="tab.icon"></i></router-link>

		        			<a class="btn btn-danger btn-rounded btn-block mt-3 mb-2" href="/my-account/logout" >Logout<i class="bx bx-power-off"></i></a>
		        		
		        		</b-card>
		        	</div>
		        </b-col>
		    	</b-row>
		    	</b-col>
		  	</b-row>
		    <!-- end row -->
		  </div>
		  <b-modal id="update-avatar" hide-footer size="lg">
		  		<b-card class="border-radius-1em border shadow-none">
                        		<b-form id="upload-avatar">
                        			<input type="hidden" name="avatar" v-model="user.avatar">
                        		</b-form>
                        		 <vue-dropzone id="dropzone" ref="myVueDropzone" :use-custom-slot="true" :options="avatarDropzoneOptions" @vdropzone-file-added="avatarFileAdded"  @vdropzone-drop="avatarFileAdded">
			                        <div class="dropzone-custom-content d-flex flex-column justify-content-center align-items-center" style="height: 500px;">
			                            <i class="display-4 text-muted bx bxs-cloud-upload"></i>
			                            <h4>Drop files here or click to upload.</h4>
			                        </div>
                        		</vue-dropzone>
                        		<div class="d-flex">
                        		<a class="btn btn-primary mb-auto w-50 mx-auto btn-rounded mt-1 mr-1" @click="uploadAvatar()" v-b-modal.uploadModal>Upload</a>
                        		<a class="btn btn-danger mb-auto w-50 mx-auto btn-rounded mt-1 ml-1" @click="$bvModal.hide('update-avatar')">Cancel</a>
                        		</div>
                    		</b-card>
		  </b-modal>
		</section>
</div>
</template>