<script type="text/javascript">
	import CategoryListWidgetCategoryCard from "./CategoryListWidget-CategoryCards";
	import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper';
	import { EventBus } from "../../../../../app";
		
	export default{
		data(){
			return {
				contentReloading: false,
				current_page: 1,
				query: '',
				loadingCategories: false,
				hoverIndex: -1,
				hoverLink: -1,
				categories: [],
				swiperOptions: {
                  direction: 'horizontal',
                  loop: true,
                  slidesPerView: 3,
                  spaceBetween: 10,
                  pagination: {
                    el: '.swiper-pagination',
                    clickable: true
                  },
                  navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                  }
            },
	        limit: this.$attrs.filters.limit.value,
					limits: 
						[ 
							{value: this.$attrs.filters.limit.value, text: "16"},
		        	{value: this.$attrs.filters.limit.value * 2, text: "32"},
		        	{value: ((this.$attrs.filters.limit.value * 2) * 2), text: "64"}
		        ]
			}
		},
		props: {
			company: {
				type: Object
			},
			resource: {
				type: String,
				default: () => 'categories'
			}
		},
		components: {
			CategoryListWidgetCategoryCard,
			Swiper,
		  SwiperSlide
		},
		directives: {
		    swiper: directive
		 },
		created(){
			this.getResource();
                if(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel[this.filterStylesKey(this.$attrs.content, 'show')].show.value !== ''){
                    this.swiperOptions.slidesPerView = parseFloat(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel[this.filterStylesKey(this.$attrs.content, 'show')].show.value).toFixed(0);
                }else{
                    this.swiperOptions.slidesPerView = 3;
                }
                if(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel[this.filterStylesKey(this.$attrs.content, 'carousel')].loop.value !== ''){
                    this.swiperOptions.loop = this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel[this.filterStylesKey(this.$attrs.content, 'carousel')].loop.value;
                }else{
                    this.swiperOptions.loop = false;
                }
                if(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel[this.filterStylesKey(this.$attrs.content, 'margin')].margin.value !== ''){
                    this.swiperOptions.spaceBetween = this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel[this.filterStylesKey(this.$attrs.content, 'margin')].margin.value;
                }else{
                    this.swiperOptions.spaceBetween = 10;
                }
                if(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel[this.filterStylesKey(this.$attrs.content, 'direction')].direction.value !== ''){
                    this.swiperOptions.direction = this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel[this.filterStylesKey(this.$attrs.content, 'direction')].direction.value;
                }else{
                    this.swiperOptions.direction = 'horizontal';
                }
		},
		mounted(){
			if(this.$attrs.content.view.value == 'carousel'){
				console.log('Current Swiper instance object', this.swiper);
	      this.swiper.slideTo(this.startSlide, 1000, false);
	     
			}
			 let self = this;
                EventBus.$on('update-widget', function(){
                    self.getResource();
                    self.contentReloading = true;
                    setTimeout(function(){
                        self.contentReloading = false;
                    }, 1000);
                });
		},
		methods: {
            filterStylesKey(styles, key){
                for(var i = 0; i < styles.length; i++){
                   var keys = Object.keys(styles[i])
                   if(keys.includes(key)){
                     return i;
                   }
                }
            },
			getResource(){
				this.createQueryString();
				this.loadingCategories = true;
				if(this.$attrs.liveMode == true){
					axios.get('/website-api/widget/'+this.resource+'?page='+this.current_page+this.query).then(response => {
						this.categories = response.data;
						let self = this;
					          setTimeout(function(){
					            self.loadingCategories = false;
					          }, 500);
					}).catch(error => {
						this.categories = [];
						console.log(error);
						let self = this;
					          setTimeout(function(){
					            self.loadingCategories = false;
					          }, 500);
					});
				}else if(this.$attrs.liveMode == false){
					axios.get('/api/company/web/page/widgets/'+this.resource+'?page='+this.current_page+this.query, {headers: {'Authorization' : 'Bearer '+this.$attrs.user.api_token}}).then(response => {
						this.categories = response.data;
						let self = this;
					          setTimeout(function(){
					            self.loadingCategories = false;
					          }, 500);
					}).catch(error => {
						let self = this;
					          setTimeout(function(){
					            self.loadingCategories = false;
					          }, 500);
					});
				}
			},
			createQueryString(){
				this.query = '';
				for(var key in this.$attrs.filters){
					if(key !== 'page'){
						this.query += '&'+key+'='+this.$attrs.filters[key].value+'';
					}
					if(key == 'limit'){
						this.limits[0].value = this.$attrs.filters.limit.value;
						this.limits[0].text = this.$attrs.filters.limit.value;
						this.limits[1].value = this.$attrs.filters.limit.value * 2;
						this.limits[1].text = this.$attrs.filters.limit.value * 2;
						this.limits[2].value = (this.$attrs.filters.limit.value * 2) * 2;
						this.limits[2].text = (this.$attrs.filters.limit.value * 2) * 2;
					}
				}
			},
			compileElementStyles(fieldname, types){
				var style = '';
				if(types.includes('background') == true){
					if(fieldname !== 'column'){
				  		style += 'background-color:'+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_colour')][fieldname+'_colour'].value+' !important;'
				  	}else{
				  		style += 'background-color:'+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'background_colour')].background_colour.value+' !important;'
				  	}
				}
				if(types.includes('color') == true){
					if(fieldname !== 'column'){
						style += 'color:'+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_text_colour')][fieldname+'_text_colour'].value+' !important;'
					}else{
						style += 'color:'+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'text_colour')].text_colour.value+' !important;'
					}
				}
				if(types.includes('border') == true){
					if(fieldname !== 'column'){
						style +=  'border-color: '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_border_colour')][fieldname+'_border_colour'].value+' !important; border-style:'+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_border_style')][fieldname+'_border_style'].value+' !important; border-radius:'+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_border_radius')][fieldname+'_border_radius'].top_left+' '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_border_radius')][fieldname+'_border_radius'].top_right+' '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_border_radius')][fieldname+'_border_radius'].bottom_right+' '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_border_radius')][fieldname+'_border_radius'].bottom_left+' !important; border-left:'+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_border')][fieldname+'_border'].left+'px !important; border-right: '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_border')][fieldname+'_border'].right+'px !important; border-top: '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_border')][fieldname+'_border'].top+'px !important; border-bottom: '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_border')][fieldname+'_border'].bottom+'px !important;'
					}else{
						style +=  'border-color: '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'border_colour')].border_colour.value+' !important;'

						style += 'border-style:'+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'border_style')].border_style.value+' !important;'

						style += 'border-radius:'+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'border_radius')].border_radius.top_left+' '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'border_radius')].border_radius.top_right+' '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'border_radius')].border_radius.bottom_right+' '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'border_radius')].border_radius.bottom_left+' !important;'
						style += 'border-left:'+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'border')].border.left+'px !important; border-right: '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'border')].border.right+'px !important; border-top: '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'border')].border.top+'px !important; border-bottom: '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'border')].border.bottom+'px !important;'
					}

				}
				if(types.includes('margin') == true){
					if(fieldname !== 'column'){
					style +=  'margin-left:'+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_margin')][fieldname+'_margin'].left+'px !important;';
					style +=  'margin-right: '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_margin')][fieldname+'_margin'].right+'px !important;';
					style +=  'margin-top: '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_margin')][fieldname+'_margin'].top+'px !important;';
					style +=  'margin-bottom: '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_margin')][fieldname+'_margin'].bottom+'px !important;';
					}else{
					style +=  'margin-left:'+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'margin')]['margin'].left+'px !important;'
					style +=  'margin-right: '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'margin')]['margin'].right+'px !important;'
					style +=  'margin-top: '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'margin')]['margin'].top+'px !important;'
					style +=  'margin-bottom: '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'margin')]['margin'].bottom+'px !important;'
					}
				}
				if(types.includes('padding') == true){
					if(fieldname !== 'column'){
					style +=  'padding-left:'+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_padding')][fieldname+'_padding'].left+'px !important;'
					style +=  'padding-right: '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_padding')][fieldname+'_padding'].right+'px !important;'
					style +=  'padding-top: '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_padding')][fieldname+'_padding'].top+'px !important;'
					style +=  'padding-bottom: '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_padding')][fieldname+'_padding'].bottom+'px !important;'
					}else{
						style +=  'padding-left:'+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'padding')]['padding'].left+'px !important;'
					style +=  'padding-right: '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'padding')]['padding'].right+'px !important;'
					style +=  'padding-top: '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'padding')]['padding'].top+'px !important;'
					style +=  'padding-bottom: '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'padding')]['padding'].bottom+'px !important;'
					}
				}
				if(types.includes('size') == true){
					if(fieldname !== 'column'){
					style +=  'height:'+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_height')][fieldname+'_height'].value+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_height')][fieldname+'_height'].units+' !important;';
					style +=  'max-height:'+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_max_height')][fieldname+'_max_height'].value+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_max_height')][fieldname+'_max_height'].units+' !important;';
					style +=  'width:'+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_width')][fieldname+'_width'].value+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_width')][fieldname+'_width'].units+' !important;'
					style +=  'max-width:'+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_max_width')][fieldname+'_max_width'].value+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_max_width')][fieldname+'_max_width'].units+' !important;'
					}else{
					style +=  'height:'+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'height')]['height'].value+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'height')]['height'].units+' !important;';
					style +=  'max-height:'+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'max_height')]['max_height'].value+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'max_height')]['max_height'].units+' !important;';
					style +=  'width:'+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'width')]['width'].value+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'width')]['width'].units+' !important;'
					style +=  'max-width:'+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'max_width')]['max_width'].value+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'max_width')]['max_width'].units+' !important;'
					} 
				}
				
				if(types.includes('text') == true){
				  style += 'font-family: \"'+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'font_family')].font_family.value.family+'\", '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'font_family')].font_family.value.category+' !important; font-size:'+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'font_size')].font_size.value+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'font_size')].font_size.units+' !important; text-transform: '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'text_transform')].text_transform.value+'!important;';
				}

				return style;
			}
		},
		 watch: {
            '$attrs.content[*].carousel.show.value'(){
                if(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel[this.filterStylesKey(this.$attrs.content, 'show')].show.value !== ''){
                    this.swiperOptions.slidesPerView = parseFloat(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel[this.filterStylesKey(this.$attrs.content, 'show')].show.value).toFixed(0);
                    this.swiper.loopedSlide = parseFloat(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel[this.filterStylesKey(this.$attrs.content, 'show')].show.value).toFixed(0);
                }else{
                    this.swiperOptions.slidesPerView = 3;
                }
              
            },
            '$attrs.content[*].carousel.margin.value'(){
                if(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel[this.filterStylesKey(this.$attrs.content, 'margin')].margin.value !== ''){
                    this.swiperOptions.loop = this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel[this.filterStylesKey(this.$attrs.content, 'margin')].margin.value;
                }else{
                    this.swiperOptions.loop = 10;
                }
                
            },
            '$attrs.content[*].carousel.loop.value'(){
                if(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel[this.filterStylesKey(this.$attrs.content, 'loop')].loop.value !== ''){
                    this.swiperOptions.loop = this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel[this.filterStylesKey(this.$attrs.content, 'loop')].loop.value;
                }else{
                    this.swiperOptions.loop = false;
                }
               
            },
            '$attrs.content[*].carousel.direction.value'(){
                if(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel[this.filterStylesKey(this.$attrs.content, 'direction')].direction.value !== ''){
                    this.swiperOptions.direction = this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel[this.filterStylesKey(this.$attrs.content, 'direction')].direction.value;
                }else{
                    this.swiperOptions.direction = 'horizontal';
                }
                
            }
        }
	}

</script>
<template>
	<div class="py-4 mb-4 default-content categories-collections">
		<b-overlay :show="loadingCategories" no-wrap></b-overlay>
		<b-row v-if="$attrs.content[filterStylesKey($attrs.content, 'view')].view.value == 'grid'">
			<b-col  :class="'col-xl-'+$attrs.content[filterStylesKey($attrs.content, 'columns')].columns.value.xl+' col-lg-'+$attrs.content[filterStylesKey($attrs.content, 'columns')].columns.value.lg+' col-md-'+$attrs.content[filterStylesKey($attrs.content, 'columns')].columns.value.md+' col-sm-'+$attrs.content[filterStylesKey($attrs.content, 'columns')].columns.value.sm+' col-xs-'+$attrs.content[filterStylesKey($attrs.content, 'columns')].columns.value.xs" v-for="(category, index) in categories.data" :style="compileElementStyles('column', ['image', 'border', 'margin', 'padding', 'sizing'])">
				<CategoryListWidgetCategoryCard :company="company" :resource="resource" :liveMode="$attrs.liveMode" :category="category" :content="$attrs.content"></CategoryListWidgetCategoryCard>
			</b-col>
			<b-col md="12">

				<b-pagination v-if="categories.length < 0" v-model="categories.current_page" :total-rows="categories.total" :per-page="categories.per_page" align="right"></b-pagination>
			</b-col>
		</b-row>
		<b-row v-else-if="$attrs.content[filterStylesKey($attrs.content, 'view')].view.value == 'list'">
			<b-col md="12" >
				<CategoryListWidgetCategoryCard :company="company" :resource="resource" v-for="(category, index) in categories.data" :liveMode="$attrs.liveMode" :category="category" :content="$attrs.content"></CategoryListWidgetCategoryCard>
			</b-col>
			<b-col md="12">
				<b-pagination v-if="categories.length < 0" v-model="categories.current_page" :total-rows="categories.total" :per-page="categories.per_page" align="right"></b-pagination>
			</b-col>
		</b-row>
		<swiper v-else-if="($attrs.content[filterStylesKey($attrs.content, 'view')].view.value == 'carousel') && (contentReloading == false)" ref="productGalleryCarousel" :options="swiperOptions" class="overflow-hidden swiper" >
        <swiper-slide  v-for="(category, index) in categories.data" class="h-100" >
              <CategoryListWidgetCategoryCard :company="company" :resource="resource" :liveMode="$attrs.liveMode" :category="category" :index="index" :content="$attrs.content"></CategoryListWidgetCategoryCard>
        </swiper-slide>
  	</swiper>
  	<b-overlay :show="contentReloading" v-else-if="contentReloading == true" no-wrap></b-overlay>
	</div>
</template>