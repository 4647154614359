<script>
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import VueSlideBar from "vue-slide-bar";
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
import CompanyProductSearch from '../../components/widgets/CompanyProductSearch';
import CompanyProductList from '../../components/widgets/CompanyProductList';
import SearchCompanyLocation from '../../components/widgets/SearchCompanyLocation';
import SideSlidePanel from "../../components/widgets/SideSlidePanel";
import simplebar from "simplebar-vue";
import Swal from "sweetalert2";
  import QrcodeVue from 'qrcode.vue';

export default{
	components:{VueSlideBar, Layout, PageHeader, SideSlidePanel, ckeditor: CKEditor.component, CompanyProductSearch, CompanyProductList, SearchCompanyLocation, simplebar, QrcodeVue},
	data() {
    	return {
          csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
          editor: ClassicEditor,
          storageurl: 'https://'+this.$attrs.company.nickname+'.s3.eu-west-2.amazonaws.com',
    		  brandsData: [],
          products_search: '',
          product_to_transfer: '',
          transfer_to_search: '',
    		  categoryProducts: [],
    		  selectedCategory: [],
    		  brands_error: [],
    		  products_error: [],
          addProductToggle: false,
          error_reponse: [],
          error_message: false,
          success_message: false,
          success_text: '',
          loadingForm: false, 
          loadingList: false,
    		  view: 'list',
    		  search: '',
    		  limit: '25',
    		  sortby: 'id',
    		  order: 'desc',
          currentPage: 1,
          currentProductPage: 1,
    		  selectedids: [],
          transferstep: 0,
          transfer_result: null,
          edittransfer: {},
          transfer: {
            transfer_from: '',
            transfer_to: '', 
            date_required: '',
            date_requested: '',
            products: [],
            send_email: 0,
            send_store_notification: 0,
            priority: 'Low',
            status: 'Booked',
          },
          transfer_raw: {
            transfer_from: '',
            transfer_to: '', 
            date_required: '',
            date_requested: '',
            products: [],
            send_email: 0,
            send_store_notification: 0,
            priority: 'Low',
            status: 'Booked',
          },
          editcollection: {
            name: '',
            description: '',
            image: '',
            cover_image: '',
            order: '',
            slug: '',
            active: 0,
          },
          isCheckAll: false,
          addNewPanel: false,
          editPanel: false,
          addPanelClass: 'edit-sidepanel-fullwidth',
          expandable: 0,
    		  getProductsPanel: 0,
    		  selected_parent: {id: null},
    		  limits: [
		        {value: 25, text: "25"},
		        {value: 50, text: "50"},
		        {value: 100, text: "100"},
		      ],
		      title: "Transfers",
		      items: [
		        {
		          text: "Stock",
		          href: "javascript:;",
		        },
		        {
		          text: "Transfers",
		          active: true,
		        },
		      ],
  				}	
	},
	methods:{
    addProductToTransfer(product){
      var exists = this.transfer.products.findIndex(item => item.id == product.id);
      if(exists > -1){
        this.transfer.products[exists].transfer_quantity += product.transfer_quantity;
      }else{
        this.transfer.products.push(product);
      }
      this.$bvModal.hide('quantity-modal'); 
    },
    getListingsQuantity(id, listings){
      var listing = listings.filter(item => item.company_listing_id == id);
      console.log(listing);
      return listing[0].quantity;
    },
    selectFromListing(payload){
      this.transfer.transfer_from = payload;
      this.getProducts(payload.uid);
    },
    selectToListing(payload){
      this.transfer.transfer_to = payload;
    },
    removeFromResource(product){
            axios.post('/api/company/product/collection/'+this.selectedCategory.id+'/remove-product', {product_id: product.uid}, {
              headers: {
                'Authorization' : 'Bearer '+this.$attrs.user.api_token
              }
            }).then(response => {
              this.$emit('product-removed', response.data);
              this.success_text = 'Product Removed from Collection';
              this.success_message = true;
              this.getProducts(this.selectedCategory.id);
              let self = this;
              setTimeout(function(){
                self.success_message = false;
              }, 1000);
            }).catch(error => {
              this.$emit('resource-error', error.data);
            });
    },
		brandQuery(){
			axios.get('/api/company/product/transfers?&page='+this.currentPage+'&limit='+this.limit+'&sortby='+this.sortby+'&order='+this.order+'&search='+this.search+'&parent_id='+this.selected_parent.id+'&active=&public_active=&trade_active=', {headers : {
        'Authorization' : 'Bearer '+this.$attrs.user.api_token
      }}).then(response => {
				this.brandsData = response.data;
			}).catch(error => {
				this.brands_error = error.data;
			});
		},
		getProducts(category_id){
      this.categoryProducts = [];
      this.loadingForm = false;
			axios.get('/api/company/product/collection/'+category_id+'/products',  { headers : {
        'Authorization': 'Bearer ' + this.$attrs.user.api_token
      } }).then(response => {
        this.categoryProducts = response.data;
         let self = this;
          setTimeout(function(){
            self.loadingForm = false;
          }, 500);
      }).catch(error => {
        this.products_error = error.data;
      });
		},
    productTransferQuantity(product){
      if(this.transfer.transfer_to !== ''){
        this.product_to_transfer = JSON.parse(JSON.stringify(product));
        this.product_to_transfer['transfer_quantity'] = 1;
        this.$bvModal.show('quantity-modal'); 
      }else{
        Swal.fire({
            position: "top-end",
            icon: "error",
            title: "You must add a listing to transfer to",
            showConfirmButton: false,
            timer: 1500
          });
      }
    },
    bookTransfer(){
      this.loadingForm = true;
      //var postForm = new FormData(document.getElementById("booktransfer"));
      //postForm.append('products', this.transfer.products);
     // postForm.append('tfrom', JSON.stringify(this.transfer.transfer_from));
      //postForm.append('tto', JSON.stringify(this.transfer.transfer_to));
      axios.post('/api/company/product/transfer/create', this.transfer, { headers : {
        'Authorization': 'Bearer ' + this.$attrs.user.api_token
      } }).then(response => {
        console.log(response);
        this.success_text = 'Congratulations, your new category has been added.';
        this.success_message = true;
        this.transfer_result = this.transfer;
        this.transfer_result['uid'] = response.data.uid;
        this.transferstep++;

        this.transfer = this.transfer_raw;
        //this.resetProjectData();
        //this.addPanel = false;
        let self = this;
          setTimeout(function(){
            self.loadingForm = false;
            //self.addNewPanel = false;
            self.brandQuery();
          }, 1500);
      }).catch( error => {
        
        this.error_reponse = error.response.data.errors;
        this.error_message = true;
        //this.resetProjectData();
        let self = this;
          setTimeout(function(){
            self.loadingForm = false;
          }, 1500);
      });
    },
    updateCollection(){
      this.success_message = false;
      this.error_message = false;
      this.loadingForm = true;
      var postForm = new FormData(document.getElementById("updatecollectionform"));
      axios.post('/api/company/product/collection/'+this.editcollection.id+'/update', postForm,  { headers : {
        'Authorization': 'Bearer ' + this.$attrs.user.api_token
      } }).then(response => {
        console.log(response);
        this.success_text = 'Congratulations, your category has been updated.';
        this.success_message = true;
        //this.resetProjectData();
        this.addPanel = false;
        let self = this;
          setTimeout(function(){
            self.loadingForm = false;
            self.editPanel = false;
            self.brandQuery();
          }, 500);
      }).catch( error => {
        
        this.error_reponse = error.response.data.errors;
        this.error_message = true;
        //this.resetProjectData();
        let self = this;
          setTimeout(function(){
            self.loadingForm = false;
          }, 500);
      });

    },
    getProducts(id){
      axios.get('/api/company/listing/'+id+'/products', { headers : {
        'Authorization': 'Bearer ' + this.$attrs.user.api_token
      } }).then(response => {
        this.categoryProducts = response.data;
        //this.showListingProducts = 1;
      }).catch(error => {
        this.products_error = error.data;
      });
    },
    showStatusMessage(result){
      if(result == false){
        this.error_reponse = {error: ["Product Already Exists in Category"]};
        this.error_message = true;
      }else{
        this.success_message = true;
        this.success_text = 'Congratulations, your action has been saved.';
        this.getProducts(this.selectedCategory.id)
      }
    },
		priceIncVat(price, tax_percentage){
			var tax = 1 + (tax_percentage / 100);
			return price * tax;
		},
    filterSelected(id){
        if(this.selectedids.length > 0){
          var exists = this.selectedids.filter(item => item == id);
          if(exists.length > 0){
            return true;
          }else{
            return false
          }
        }else{
          return false;
        }
      },
      checkAll: function(){ 
                 this.isCheckAll = !this.isCheckAll; 
                 this.selectedids = []; 
                 if(this.isCheckAll){ 
                     // Check all 
                     for (var key in this.brandsData.data) {          
                        this.selectedids.push(this.brandsData.data[key].id); 
                     } 
                 } 
              }, 
      updateCheckall: function(){ 
                 if(this.selectedids.length == this.brandsData.data.length){
                    this.isCheckAll = true; 
                 }else{ 
                    this.isCheckAll = false; 
                 } 
      },
      sanitizeTitle: function(title) {
            var nickname = "";
            // Change to lower case
            var titleLower = title.toLowerCase();
            // Letter "e"
            nickname = titleLower.replace(/e|é|è|ẽ|ẻ|ẹ|ê|ế|ề|ễ|ể|ệ/gi, 'e');
            // Letter "a"
            nickname = nickname.replace(/a|á|à|ã|ả|ạ|ă|ắ|ằ|ẵ|ẳ|ặ|â|ấ|ầ|ẫ|ẩ|ậ/gi, 'a');
            // Letter "o"
            nickname = nickname.replace(/o|ó|ò|õ|ỏ|ọ|ô|ố|ồ|ỗ|ổ|ộ|ơ|ớ|ờ|ỡ|ở|ợ/gi, 'o');
            // Letter "u"
            nickname = nickname.replace(/u|ú|ù|ũ|ủ|ụ|ư|ứ|ừ|ữ|ử|ự/gi, 'u');
            // Letter "d"
            nickname = nickname.replace(/đ/gi, 'd');
            // Trim the last whitespace
            nickname = nickname.replace(/\s*$/g, '');
            // Change whitespace to "-"
            nickname = nickname.replace(/\s+/g, '-');
            // Remove quotations
            nickname = nickname.replace(/["']/g, "");
            // Remove symbols
            nickname = nickname.replace(/@|#|!/g, '');
            return nickname;
        }
	},
	created(){
		this.brandQuery();
  }
}
</script>

<template>
	<Layout>
		<PageHeader :title="title" :items="items" />
		    <div class="row">
          <b-col md="12">
                    <transition name="slide-success" mode="out-in">
                  <b-alert id="success-message" variant="success" :show="success_message"  dismissible @dismissed="success_message = !success_message">
                      <p class="font-size-14">{{success_text}}</p>
                    </b-alert>
                  </transition>
                  </b-col>
      <div class="col-md-12">
 <div class="row mb-3">
          <div class="col-xl-1 col-sm-6">
            <div class="mt-2">
              <b-form-select class="form-control" v-model="limit" :options="limits" @input="brandQuery()"></b-form-select>
            </div>
          </div>
         
          <div class="col-lg-2 col-sm-6 d-flex">
          <form class="my-auto">
            <small class="mx-auto my-2 text-center d-block">Results {{brandsData.from}} - {{brandsData.to}} of {{brandsData.total}}</small>
          </form>
        </div>
        <div class="col-lg-3">
          <b-form-group class="my-auto ml-auto mr-3">
            <b-form-radio-group id="btn-radios-1" button-variant="outline-light"
        size="md" v-model="marketplace_brands" :options="quickfilter_options" buttons @input="brandQuery()"></b-form-radio-group>
      
          
          </b-form-group>
        </div>
        <div class="col-lg-3 col-sm-6 d-flex">
          
            <div class="mt-sm-0 form-inline w-100" >
              <div class="search-box mr-2 ml-auto">
                <div class="position-relative">
                  <input
                    type="text"
                    class="form-control w-100 border-0"
                    placeholder="Search name, reference or description..."
                    v-model="search"
                    @keyup.enter="brandQuery()"
                  />
                  <i class="bx bx-search-alt search-icon"></i>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-sm-6 d-flex">
            <div class="d-flex ml-auto">
              <ul class="nav nav-pills product-view-nav">
                
                <!--<li class="nav-item">
                  <a class="nav-link" :class="{'active': view == 'grid' }" href="javascript: void(0);" @click="view = 'grid'">
                    <i class="bx bx-grid-alt"></i>
                  </a>
                </li>-->
                <li class="nav-item">
                  <a class="nav-link" :class="{'active': view == 'list' }" href="javascript: void(0);" @click="view = 'list'">
                    <i class="bx bx-list-ul"></i>
                  </a>
                </li>
                <li class="nav-item">
                 <b-dropdown text="Bulk Actions" variant="primary" class="rounded">
                  <b-dropdown-item :disabled="($attrs.user.companies[0].userrole.name !== 'Owner') && !$attrs.user.companies[0].permissions.edit.includes($attrs.active_menu.id)" href="javascript: void(0);">Duplicate</b-dropdown-item>
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-item :disabled="($attrs.user.companies[0].userrole.name !== 'Owner') && !$attrs.user.companies[0].permissions.delete.includes($attrs.active_menu.id)" href="javascript: void(0);">Cancel</b-dropdown-item>
                </b-dropdown>
                </li>
                <li class="nav-item">
                 <b-button
                    variant="success"
                    :disabled="($attrs.user.companies[0].userrole.name !== 'Owner') && !$attrs.user.companies[0].permissions.add.includes($attrs.active_menu.id)"
                    class="btn btn-success btn-rounded mb-2 mr-2 text-white"
                    href="javascript:;"
                    @click="transferstep = 0, transfer = JSON.parse(JSON.stringify(transfer_raw)), transfer_result = null, addNewPanel = !addNewPanel"
                  >
                    <i class="mdi mdi-plus mr-1"></i> New
                  </b-button>
                </li>
              </ul>
              <div class="text-sm-right">
                  
                </div>
          </div>
        </div>
      </div>
      <div class="d-flex flex-column justify-content-center h-100 restricted-col" v-if="(!$attrs.user.companies[0].userrole.name == 'Owner') && !$attrs.user.companies[0].permissions.read.includes($attrs.active_menu.id)" >
          <AccessRestricted :page="false"></AccessRestricted>
        </div>
        <div v-else>
        <div class="card" v-if="view == 'list'" >
          <div class="card-body p-0">
            <div class="table-responsive">
              <table class="table table-centered table-hover">

                <thead class="thead-light">
                  <tr>
                    
                    <th scope="col" style="max-width: 150px;">
                      <div class="d-flex">
                      <div
                        class="form-check form-switch form-switch-sm mb-0 ml-2 mr-4 p-0 my-auto d-flex"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input mx-auto my-auto" 
                          type="checkbox"
                          id="SwitchCheckSizesm"
                         
                          v-model="isCheckAll"
                          @click='checkAll()'
                          name="active"
                        />
                    </div>
                    <span>Transfer From</span>
                    </div></th>
                    <th></th>
                    <th scope="col" style="max-width: 150px;">Transfer To</th>
                    <th scope="col">Products</th>
                    <th scope="col">Date Booked</th>
                    <th scope="col">Approved</th>
                    <!--<th scope="col">Sent</th>
                    <th scope="col">Received</th>-->
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
               
                <tbody>
                  
                  <tr v-for="list in brandsData.data" :key="list.id" valign="middle">
                   
                    <td style="max-width: 150px;">
                      <div class="media" v-if="'name' in list.transfer_from">
                        <div class="avatar-sm mr-3" v-if="list.transfer_from.logo !== null">
                          <i v-if="filterSelected(list.id)" class="bx bx-check"></i>
                          <img v-else class="avatar-sm rounded-circle" :src="storageurl+list.transfer_from.logo">
                        
                        </div>
                        <div class="avatar-sm mr-3" v-else-if="list.transfer_from.banner !== null">
                          <i v-if="filterSelected(list.id)" class="bx bx-check"></i>
                          <img v-else class="avatar-sm rounded-circle" :src="storageurl+list.transfer_from.banner">
                        </div>
                        <div class="text-center mr-3"  >
                        <b-form-checkbox class="select-row-btn" v-model="selectedids" :value="list.id" button-variant="primary" @input="updateSelected" button>

                          <i v-if="filterSelected(list.id)" class="bx bx-check"></i><span class="avatar-title rounded-circle" v-else>{{(list.transfer_from.name).slice(0,1)}}</span></b-form-checkbox>
                        </div>
                        <div class="media-body my-auto text-truncate">
                          <h5 class="mb-0 font-size-16">{{list.transfer_from.name}}</h5>
                          <p class="text-truncate mb-0">{{list.transfer_from.address_1}} {{list.transfer_from.address_2}} {{list.transfer_from.address_3}} <span v-if="list.transfer_from.county !== null">{{list.transfer_from.county.name}}</span> {{list.transfer_from.postcode}}</p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div class="d-flex flex-column justify-content-center align-items-center">
                        <i class="bx bx-right-arrow font-size-20 text-primary my-auto"></i>
                      </div>
                    </td>
                    <td style="max-width: 150px;">
                       <div class="media" v-if="'name' in list.transfer_to">
                        <div class="avatar-sm me-3">
                          <img v-if="list.transfer_to.logo !== null" class="avatar-sm rounded-circle" :src="storageurl+list.transfer_to.logo">
                          <img v-else-if="list.transfer_to.banner !== null" class="avatar-sm rounded-circle" :src="storageurl+list.transfer_to.banner">
                          <span class="avatar-title rounded-circle" v-else>{{(list.transfer_to.name).slice(0,1)}}</span>
                        </div>
                        <div class="media-body my-auto text-truncate">
                          <h5 class="mb-0 font-size-16">{{list.transfer_to.name}}</h5>
                          <p class="text-truncate mb-0">{{list.transfer_to.address_1}} {{list.transfer_to.address_2}} {{list.transfer_to.address_3}} <span v-if="list.transfer_to.county !== null">{{list.transfer_to.county.name}}</span> {{list.transfer_to.postcode}}</p>
                        </div>
                      </div>
                    </td>
                    <td><a href="javascript:;" @click="selectedCategory = list, getProducts(list.id), getProductsPanel = 1" class="btn btn-outline-light btn-rounded"><i class="bx bx bx-purchase-tag-alt"></i></a>
                    </td>
                    <td>
                      <i class="bx bx-calendar font-size-18"></i>
                      {{list.date_requested | luxon}}
                    </td>
                    <td>
                      <span class="d-flex" v-if="list.approved == 1">
                      <i class="bx bx-check-circle text-success font-size-18"></i>
                      Approved
                      </span>
                      <span class="d-flex" v-else-if="list.approved == 0">
                      <i class="bx bx-x-circle text-danger font-size-18"></i>
                      Not Approved
                      </span>
                      <span class="d-flex" v-else-if="list.approved == null"> 
                      <i class="bx bx-info-circle text-primary font-size-18"></i> 
                      Awaiting Approval
                      </span>
                    </td>
                   <!-- <td>
                      <i class="bx bx-calendar font-size-18"></i>
                      {{list.date_requested}}
                    </td>
                    <td>
                     <i class="bx bx-calendar font-size-18"></i>
                      {{list.date_requested}}
                    </td>-->
                    <td>
                      <ul class="list-inline font-size-20 contact-links mb-0">
                        <li class="list-inline-item px-2"><a v-if="($attrs.user.companies[0].userrole.name == 'Owner') || $attrs.user.companies[0].permissions.edit.includes($attrs.active_menu.id)" @click="edittransfer = list, editPanel = true" v-b-tooltip.hover title="Edit" placement="left"><i class="bx bx-edit-alt"></i>
                          </a>
                        </li>
                        <li class="list-inline-item px-2">

                        </li>
                        <!--<li class="list-inline-item px-2">
                           <b-dropdown v-b-tooltip.hover title="More Options" placement="left"dropleft class="more-options-dropdown">
			                  <template v-slot:button-content>
			                     <i class="bx bx-dots-horizontal-rounded"></i>
			                  </template>
			                  <b-dropdown-item>Action</b-dropdown-item>
			                  <b-dropdown-item>Another Action</b-dropdown-item>
			                  <b-dropdown-item>Something else here</b-dropdown-item>
			                </b-dropdown>
                        </li>-->
                      </ul>
                    </td>
                  </tr>
              	</tbody>
              </table>
            </div>
            <div class="row" v-if="'data' in brandsData">
              <div class="col-lg-12">
                <b-pagination
              v-if="brandsData.data.length > 0"
              class="justify-content-center"
              pills
              v-model="currentPage"
              :total-rows="brandsData.total"
              :per-page="brandsData.per_page"
              aria-controls="my-table"
              @input="brandQuery"
            ></b-pagination>
             <small class="mx-auto my-2 text-center d-block">Records {{brandsData.from}} - {{brandsData.to}} of {{brandsData.total}} // Page {{brandsData.current_page}} of {{brandsData.last_page}}</small>
              </div>
            </div>
          </div>
      </div>
    </div>

    </div>
</div>
	<SideSlidePanel :editPanel="getProductsPanel" :panelClass="'edit-sidepanel'" @close-panel="getProductsPanel = 0">
		<div class="row">
				<div class="d-flex flex-column justify-content-center h-100 restricted-col" v-if="($attrs.user.companies[0].userrole.name !== 'Owner') && !$attrs.user.companies[0].permissions.edit.includes($attrs.active_menu.id)" >
          <AccessRestricted :page="false"></AccessRestricted>
        </div>
				<div class="col-md-12">
				<b-card>
        <div class="d-flex mb-3">
          <img v-if="selectedCategory.image" :src="selectedCategory.image" class="rounded-circle avatar-md mr-3">
          <img v-else src="/images/product-placeholder.png" class="rounded-circle avatar-md mr-3">
					<h4 class="my-auto mr-auto ml-3">Products in <strong class="text-primary">{{selectedCategory.name}}</strong></h4>
           <a id="tooltip-add" class="btn btn-outline-light font-size-16 btn-rounded ml-auto mr-3 my-auto" @click="addProductToggle = !addProductToggle"><i class="mdi mdi-plus"></i></a><b-tooltip target="tooltip-add">Add Product</b-tooltip>
        </div>
					<CompanyProductSearch v-if="addProductToggle" class="mt-1 mb-4" :resource="'category'" :resource_id="selectedCategory.id" :api_token="$attrs.user.api_token" :resource_route="'/api/company/product/collection/'+selectedCategory.id+'/add-product?'" @product-added="showStatusMessage"></CompanyProductSearch>
            <b-overlay
                            id="overlay-background"
                            :show="loadingForm"
                            variant="white"
                            opacity="0.85"
                            blur="1px"
                            rounded="md"
                          >
              <CompanyProductList :resource="'collection'" :product_list="categoryProducts" :expanded="isExpanded" @remove-product="removeFromResource"></CompanyProductList>
            </b-overlay>
				</b-card>
				</div>
		</div>
	</SideSlidePanel>
   <SideSlidePanel :editPanel="addNewPanel" :panelClass="addPanelClass" @close-panel="addNewPanel = !addNewPanel" :expandable="1"  @expand="addPanelClass = 'edit-sidepanel-large'" @contract="addPanelClass = 'edit-sidepanel'">
      <div class="row">
        <div class="d-flex flex-column justify-content-center h-100 restricted-col" v-if="($attrs.user.companies[0].userrole.name !== 'Owner') && !$attrs.user.companies[0].permissions.add.includes($attrs.active_menu.id)" >
          <AccessRestricted :page="false"></AccessRestricted>
        </div>
        <div class="col-md-12" v-else>
        <b-card class="shadow-none">
          <h4 class="my-3" v-if="transferstep < 2">Book Transfer</h4>
          <b-overlay
                        id="overlay-background"
                        :show="loadingForm"
                        variant="white"
                        opacity="0.85"
                        blur="1px"
                        rounded="md"
                      >
         <b-form id="booktransfer" method="POST" enctype="multipart/form-data">
                   <input type="hidden" name="_token" :value="csrf">
           <b-row>
                     <b-col md="12">
                    <transition name="slide-error" mode="out-in">
                    <b-alert id="errors" variant="danger" :show="error_message"  dismissible @dismissed="error_message = !error_message">
                      <p class="font-size-14">Oops! Please correct the following errors in your submission</p>
                      <ul>
                        <li v-for="(error, key) in error_reponse">
                          {{error[0]}}
                        </li>
                      </ul>
                    </b-alert>
                    
                  </transition>
                  </b-col>
          </b-row>
          <b-row v-if="transferstep == 0 && transfer_result == null">
            <b-col md="7">
               <b-card class="border-radius-1em border shadow-none">
           <b-form-group class="mt-2 mb-3">
            <h5>Transfer from:</h5>
                    <SearchCompanyLocation :id="transfer.invoice_address" :api_token="$attrs.user.api_token" @listing-selected="selectFromListing"></SearchCompanyLocation>
                    <input type="hidden" name="transfer_from" v-model="transfer.transfer_from">
            </b-form-group>
            <h5 v-if="transfer.transfer_from !== ''" >Products Available in {{transfer.transfer_from.name}}</h5>
         
            
            
            <div class="mt-sm-0 form-inline w-100" v-if="transfer.transfer_from !== ''" >
              <div class="search-box mb-2 ml-auto">
                <div class="position-relative">
                  <input
                    type="text"
                    class="form-control w-100 border-0"
                    placeholder="Search name, reference or description..."
                    v-model="products_search"
                    @keyup.enter="brandQuery()"
                  />
                  <i class="bx bx-search-alt search-icon"></i>
                </div>
              </div>
            </div>
              <div v-if="transfer.transfer_from !== '' && 'data' in categoryProducts" class="img-scrollbar" style="max-height: 400px; overflow-y: auto;">
                  <b-list-group class="border-radius-1em shadow-none">
                    <b-list-group-item v-for="product in categoryProducts.data" class="p-0 d-flex"  no-body>
                      <img v-if="product.cover_image !== null" :src="storageurl + product.cover_image.src" class="avatar-xl" />
                      <img v-else :src="'/images/product-placeholder.png'" class="avatar-xl" />
                      <div class="p-3 w-50 d-flex flex-column">
                      <span class="badge bg-primary position-absolute top-0 left-0">{{product.reference}}</span>
                      <h5 class="card-title font-size-14 mb-0 mt-auto">{{product.name}}</h5>
                      <p class="text-truncate mb-0 paragraph-truncate-line2 with-v-html" v-html="product.short_description"></p>
                       <p class="mt-auto">Quantity at This Listing: <span class="text-primary">{{getListingsQuantity(transfer.transfer_from.id, product.listings)}}</span></p>
                      </div>
                      <div class="ml-auto mr-4 justify-content-center align-items-center d-flex">
                        <a class="btn btn-primary btn-rounded my-auto" @click="productTransferQuantity(product)">
                          <i class="mdi mdi-plus"></i>
                        </a>
                      </div>
                    </b-list-group-item>
                  </b-list-group>
                </div>
                <b-pagination v-if="transfer.transfer_from !== ''"
             
              class="justify-content-center mt-3"
              pills
              v-model="currentProductPage"
              :total-rows="categoryProducts.total"
              :per-page="categoryProducts.per_page"
              aria-controls="my-table"
              @input="getProducts(transfer.transfer_from.uid)"
                >
                </b-pagination>
              </b-card>
              <b-card class="border-radius-1em border shadow-none">
            <h5>Products In Transfer</h5>
                      <div v-if="transfer.transfer_to !== ''" class="img-scrollbar" style="max-height: 400px; overflow-y: auto;">
                      <b-list-group class="border-radius-1em shadow-none">
                        <b-list-group-item v-for="(product, index) in transfer.products" class="p-0 d-flex"  no-body>
                          <img v-if="product.cover_image !== null" :src="storageurl + product.cover_image.src" class="avatar-xl" />
                          <img v-else :src="'/images/product-placeholder.png'" class="avatar-xl" />
                          <div class="p-3 w-50 d-flex flex-column">
                          <span class="badge bg-primary position-absolute top-0 left-0">{{product.reference}}</span>
                          <h5 class="card-title font-size-14 mb-0 mt-auto">{{product.name}}</h5>
                          <p class="text-truncate mb-0 paragraph-truncate-line2 with-v-html" v-html="product.short_description"></p>
                           <p class="mt-auto mb-0">Quantity at This Listing: <span class="text-primary">{{getListingsQuantity(transfer.transfer_from.id, product.listings)}}</span></p>
                           <p class="mt-0 mb-0">Quantity to Transfer: <span class="text-primary">{{product.transfer_quantity}}</span></p>
                          </div>
                          <div class="ml-auto mr-4 justify-content-center align-items-center d-flex">
                            <a class="btn btn-primary btn-rounded my-auto" @click="transfer.products.splice(index, 1)"v-b-modal.quantity-modal>
                              <i class="bx bx-trash-alt"></i>
                            </a>
                          </div>
                        </b-list-group-item>
                      </b-list-group>
                    </div>
                </b-card>
          </b-col>
          <!-- <b-col md="12" class="d-flex justify-content-center align-items-center mb-3">
            <i class="text-white font-size-48 bx bx-down-arrow bg-primary bg-soft rounded-circle p-2"></i>
          </b-col>-->
           <b-col md="5">
             <b-card class="border-radius-1em border shadow-none">
            <b-form-group class="mt-2 mb-3" label="">
              <h5>Transfer to:</h5>
                    <SearchCompanyLocation :id="transfer.invoice_address" :api_token="$attrs.user.api_token" @listing-selected="selectToListing"></SearchCompanyLocation>
                    <input type="hidden" name="transfer_to" v-model="transfer.transfer_to">
            </b-form-group>
             <h5 v-if="transfer.transfer_to !== ''">Transferring to <span>{{transfer.transfer_to.name}}</span></h5>
            <!-- <div class="mt-sm-0 form-inline w-100" v-if="transfer.transfer_to !== ''" >
              <div class="search-box mb-2 ml-auto">
                <div class="position-relative">
                  <input
                    type="text"
                    class="form-control w-100 border-0"
                    placeholder="Search name, reference or description..."
                    v-model="transfer_to_search"
                    @keyup.enter="brandQuery()"
                  />
                  <i class="bx bx-search-alt search-icon"></i>
                </div>
              </div>
            </div>-->
          </b-card>
          <b-card class="border-radius-1em border shadow-none">
          
              <h5>Date Requested</h5>
          <b-form-group class="mt-2" label="">
              <b-form-input type="date" v-model="transfer.date_requested"></b-form-input>
            </b-form-group>
          </b-card>
          <b-card class="border-radius-1em border shadow-none">
          
              <h5>Date Required By</h5>
          <b-form-group class="mt-2" label="">
              <b-form-input type="date" v-model="transfer.date_required"></b-form-input>
            </b-form-group>
          </b-card>
           <b-card class="border-radius-1em border shadow-none">
             <h5>Status</h5>
              <b-form-select class="form-control" readonly v-model="transfer.status" :options="['Booked', 'Approved', 'Shipped', 'Received']">
              </b-form-select>
           </b-card>
           <b-card class="border-radius-1em border shadow-none">
             <h5>Prority & Notifications</h5>
             <b-form-select v-model="transfer.priority" class="form-control" :options="['High', 'Medium', 'Low']">
            </b-form-select>
              
             <div
                        class="form-check form-switch form-switch-sm mb-0 mt-auto"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          id="newbrand-owner"
                          v-model="transfer.send_store_notification" value="true"
                          unchecked-value="false"
                          name="active"
                        />
                        <label class="form-check-label" for="newbrand-owner" 
                          > Send In-Store Notification</label
                        >
               </div>
              <div
                        class="form-check form-switch form-switch-sm mb-0 mt-auto"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          id="newbrand-owner"
                          v-model="transfer.send_email" value="true"
                          unchecked-value="false"
                          name="active"
                        />
                        <label class="form-check-label" for="newbrand-owner" 
                          > Send Email to Store</label
                        >
               </div>
           </b-card>
        </b-col>
       
            
          </b-row>
          <div class="d-flex flex-row mt-3" v-if="transferstep == 0">
          <b-button variant="success" class="w-md btn-rounded" @click="transferstep++"> <i class=" bx bx-save"></i> Booking Summary</b-button>
          </div>
           <b-row md="12" v-if="transferstep == 1">
              <b-col md="6">
                <b-card  v-if="transfer.transfer_from !== ''" class="border-radius-1em border shadow-none">
                <h5>Transfer From</h5>
                <h5 >{{transfer.transfer_from.name}}</h5>
                <p v-if="transfer.transfer_from.address_1 !== null">{{transfer.transfer_from.address_1}}</p>
                <p v-if="transfer.transfer_from.address_2 !== null">{{transfer.transfer_from.address_2}}</p>
                <p v-if="transfer.transfer_from.county !== null">{{transfer.transfer_from.county.name}}</p>
                <p v-if="transfer.transfer_from.postcode !== null">{{transfer.transfer_from.postcode}}</p>
                </b-card>
              </b-col> 
              <b-col md="6">
                <b-card v-if="transfer.transfer_to !== ''" class="border-radius-1em border shadow-none">
                <h5>Transfer To</h5>
                <h5>{{transfer.transfer_to.name}}</h5>
                <p v-if="transfer.transfer_to.address_1 !== null">{{transfer.transfer_to.address_1}}</p>
                <p v-if="transfer.transfer_to.address_2 !== null">{{transfer.transfer_to.address_2}}</p>
                <p v-if="transfer.transfer_to.county !== null">{{transfer.transfer_to.county.name}}</p>
                <p v-if="transfer.transfer_to.postcode !== null">{{transfer.transfer_to.postcode}}</p>
                </b-card>
              </b-col>
              <b-col md="8">
                 <b-card class="border-radius-1em border shadow-none">
            <h5>Products In Transfer</h5>
                      <div v-if="transfer.transfer_to !== ''" class="img-scrollbar" style="max-height: 400px; overflow-y: auto;">
                      <b-list-group class="border-radius-1em shadow-none">
                        <b-list-group-item v-for="(product, index) in transfer.products" class="p-0 d-flex"  no-body>
                          <img v-if="product.cover_image !== null" :src="storageurl + product.cover_image.src" class="avatar-xl" />
                          <img v-else :src="'/images/product-placeholder.png'" class="avatar-xl" />
                          <div class="p-3 w-50 d-flex flex-column">
                          <span class="badge bg-primary position-absolute top-0 left-0">{{product.reference}}</span>
                          <h5 class="card-title font-size-14 mb-0 mt-auto">{{product.name}}</h5>
                          <p class="text-truncate mb-0 paragraph-truncate-line2 with-v-html" v-html="product.short_description"></p>
                           <p class="mt-auto mb-0">Quantity at This Listing: <span class="text-primary">{{getListingsQuantity(transfer.transfer_from.id, product.listings)}}</span></p>
                           <p class="mt-0 mb-0">Quantity to Transfer: <span class="text-primary">{{product.transfer_quantity}}</span></p>
                          </div>
                          <div class="ml-auto mr-4 justify-content-center align-items-center d-flex">
                            <a class="btn btn-primary btn-rounded my-auto" @click="transfer.products.splice(index, 1)"v-b-modal.quantity-modal>
                              <i class="bx bx-trash-alt"></i>
                            </a>
                          </div>
                        </b-list-group-item>
                      </b-list-group>
                    </div>
                </b-card>
              </b-col>
              <b-col md="4">
                <b-card class="border-radius-1em border shadow-none">
                    <h5>Date Required By:</h5>
                    <p>{{transfer.date_required | luxon}}</p>
                </b-card>
                <b-card class="border-radius-1em border shadow-none">
                    <h5>Priority</h5>
                     <p>{{transfer.priority}}</p>
                </b-card>
                <b-card class="border-radius-1em border shadow-none">
                    <h5>Status</h5>
                     <p>{{transfer.status}}</p>
                </b-card>
                <b-card class="border-radius-1em border shadow-none">
                    <h5>Notifications</h5>
                    <p><i class="bx bx-check-circle mr-3 text-success" v-if="transfer.send_store_notification == true">
                    </i> <i v-else class="bx bx-x-circle mr-3 text-danger"></i> Send in Store Notification</p>

                    <p><i class="bx bx-check-circle mr-3 text-success" v-if="transfer.send_email == true">
                    </i> <i v-else class="bx bx-x-circle mr-3 text-danger"></i> Send Store Email</p>
                </b-card>
              </b-col>
          </b-row>
          <div class="d-flex flex-row mt-3" v-if="transferstep == 1">
          <b-button variant="info" class="w-md btn-rounded mr-1" @click="transferstep--"> <i class=" bx bx-save"></i> Edit Details</b-button><b-button variant="success" class="ml-1 w-md btn-rounded" @click="bookTransfer()"> <i class=" bx bx-save"></i> Submit Transfer</b-button>
          </div>
          <b-row v-if="transferstep == 2">
            <b-col md="8">
              <b-row>
                <b-col md="12">
                    <b-card class="border-radius-1em border shadow-none">
                  <h4 class="text-center">Transfer Requested Successfully</h4>
                <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                  <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none"/>
                  <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
                </svg>

              </b-card>
                </b-col>
                <b-col md="12">
                  <b-card class="border-radius-1em border shadow-none">
                    <h4>Reference:  #{{transfer_result.uid}}</h4>
                  </b-card>
                </b-col>
              </b-row>
              <b-row>
               <b-col md="6">
                <b-card  v-if="transfer_result.transfer_from !== ''" class="border-radius-1em border shadow-none">
                <h5>Transfer From</h5>
                <h5 >{{transfer_result.transfer_from.name}}</h5>
                <p v-if="transfer_result.transfer_from.address_1 !== null">{{transfer_result.transfer_from.address_1}}</p>
                <p v-if="transfer_result.transfer_from.address_2 !== null">{{transfer_result.transfer_from.address_2}}</p>
                <p v-if="transfer_result.transfer_from.county !== null">{{transfer_result.transfer_from.county.name}}</p>
                <p v-if="transfer_result.transfer_from.postcode !== null">{{transfer_result.transfer_from.postcode}}</p>
                </b-card>
              </b-col> 
              <b-col md="6">
                <b-card v-if="transfer_result.transfer_to !== ''" class="border-radius-1em border shadow-none">
                <h5>Transfer To</h5>
                <h5>{{transfer_result.transfer_to.name}}</h5>
                <p v-if="transfer_result.transfer_to.address_1 !== null">{{transfer_result.transfer_to.address_1}}</p>
                <p v-if="transfer_result.transfer_to.address_2 !== null">{{transfer_result.transfer_to.address_2}}</p>
                <p v-if="transfer_result.transfer_to.county !== null">{{transfer_result.transfer_to.county.name}}</p>
                <p v-if="transfer_result.transfer_to.postcode !== null">{{transfer_result.transfer_to.postcode}}</p>
                </b-card>
              </b-col>
              <b-col md="12">
                 <b-card class="border-radius-1em border shadow-none">
            <h5>Products In Transfer</h5>
                      <div v-if="transfer_result !== null" class="img-scrollbar" style="max-height: 400px; overflow-y: auto;">
                      <b-list-group class="border-radius-1em shadow-none">
                        <b-list-group-item v-for="(product, index) in transfer_result.products" class="p-0 d-flex"  no-body>
                          <img v-if="product.cover_image !== null" :src="storageurl + product.cover_image.src" class="avatar-xl" />
                          <img v-else :src="'/images/product-placeholder.png'" class="avatar-xl" />
                          <div class="p-3 w-50 d-flex flex-column">
                          <span class="badge bg-primary position-absolute top-0 left-0">{{product.reference}}</span>
                          <h5 class="card-title font-size-14 mb-0 mt-auto">{{product.name}}</h5>
                          <p class="text-truncate mb-0 paragraph-truncate-line2 with-v-html" v-html="product.short_description"></p>
                           <p class="mt-auto mb-0">Quantity at This Listing: <span class="text-primary">{{getListingsQuantity(transfer_result.transfer_from.id, product.listings)}}</span></p>
                           <p class="mt-0 mb-0">Quantity to Transfer: <span class="text-primary">{{product.transfer_quantity}}</span></p>
                          </div>
                          <div class="ml-auto mr-4 justify-content-center align-items-center d-flex">
                            <a class="btn btn-primary btn-rounded my-auto" @click="transfer_result.products.splice(index, 1)"v-b-modal.quantity-modal>
                              <i class="bx bx-trash-alt"></i>
                            </a>
                          </div>
                        </b-list-group-item>
                      </b-list-group>
                    </div>
                </b-card>
              </b-col>
            </b-row>
          </b-col>
               <b-col md="4">
                <b-card class="border-radius-1em border shadow-none" body-class="text-center">
                  <qrcode-vue  :value="JSON.stringify({type: 'stock-transfer', reference: transfer_result.uid, uid: transfer_result.uid, id: transfer_result.id})" level="L" foreground="#000000" background="#ffffff" margin="0" class="px-1"
                   size="230" />
                </b-card>
                <b-card class="border-radius-1em border shadow-none">
                    <h5>Date Required By:</h5>
                    <p>{{transfer_result.date_required | luxon}}</p>
                </b-card>
                <b-card class="border-radius-1em border shadow-none">
                    <h5>Priority</h5>
                     <p>{{transfer_result.priority}}</p>
                </b-card>
                <b-card class="border-radius-1em border shadow-none">
                    <h5>Status</h5>
                     <p>{{transfer_result.status}}</p>
                </b-card>
                <b-card class="border-radius-1em border shadow-none">
                    <h5>Notifications</h5>
                    <p><i class="bx bx-check-circle mr-3 text-success" v-if="transfer_result.send_store_notification == true">
                    </i> <i v-else class="bx bx-x-circle mr-3 text-danger"></i> Send in Store Notification</p>

                    <p><i class="bx bx-check-circle mr-3 text-success" v-if="transfer_result.send_email == true">
                    </i> <i v-else class="bx bx-x-circle mr-3 text-danger"></i> Send Store Email</p>
                </b-card>
              </b-col>
          </b-row>
        </b-form>
      </b-overlay>
        </b-card>
        </div>
      </div>
    </SideSlidePanel> 
    <b-modal id="quantity-modal" centered hide-header hide-footer>
      <div>
        <b-form-group label="Quantity to Transfer">
          <b-form-input v-model="product_to_transfer.transfer_quantity" type="number"></b-form-input>
        </b-form-group>
        <p>You are requesting transfer of <span class="text-primary font-weight-bold">{{product_to_transfer.transfer_quantity}}</span> items to <span v-if="transfer.transfer_to !== ''">{{transfer.transfer_to.name}}</span></p>
        <a class="btn btn-success btn-rounded btn-block mt-3" @click="addProductToTransfer(product_to_transfer)">
          Add to Transfer
        </a>
      </div>
    </b-modal>
    <!--<b-modal id="available-products" centered size="xl">
      <b-row>
        <b-col md="6">
          <h5>Products Available in {{transfer.transfer_from.name}}</h5>
          <b-card class="border-radius-1em border shadow-none">
            
            
            <div class="mt-sm-0 form-inline w-100" >
              <div class="search-box mb-2 ml-auto">
                <div class="position-relative">
                  <input
                    type="text"
                    class="form-control w-100 border-0"
                    placeholder="Search name, reference or description..."
                    v-model="products_search"
                    @keyup.enter="brandQuery()"
                  />
                  <i class="bx bx-search-alt search-icon"></i>
                </div>
              </div>
            </div>
              <div class="img-scrollbar" style="max-height: 400px; overflow-y: auto;">
                  <b-list-group class="border-radius-1em shadow-none">
                    <b-list-group-item v-for="product in categoryProducts.data" class="p-0 d-flex"  no-body>
                      <img v-if="product.cover_image !== null" :src="product.cover_image.src" class="avatar-xl" />
                      <img v-else :src="'/images/product-placeholder.png'" class="avatar-xl" />
                      <div class="p-3 w-50 d-flex flex-column">
                      <span class="badge bg-primary position-absolute top-0 left-0">{{product.reference}}</span>
                      <h5 class="card-title font-size-14 mb-0 mt-auto">{{product.name}}</h5>
                      <p class="text-truncate mb-0" v-html="product.short_description"></p>
                       <p class="mt-auto">Quantity at This Listing: <span class="text-primary">{{getListingsQuantity(transfer.transfer_from.id, product.listings)}}</span></p>
                      </div>
                      <div class="ml-auto mr-4 justify-content-center align-items-center d-flex">
                        <a class="btn btn-primary btn-rounded my-auto"  v-b-modal.quantity-modal @click="product_to_transfer = product, product_to_transfer['transfer_quantity'] = 1">
                          <i class="mdi mdi-plus"></i>
                        </a>
                      </div>
                    </b-list-group-item>
                  </b-list-group>
                </div>
                <b-pagination 
                v-if="categoryProduct"
              class="justify-content-center"
              pills
              v-model="currentProductPage"
              :total-rows="categoryProducts.total"
              :per-page="categoryProducts.per_page"
              aria-controls="my-table"
              @input="getProducts(transfer.transfer_from.uid)"
                >
                </b-pagination>
              </b-card>
                </b-col>
                <b-col md="6">
                  <h5>Transferring to <span v-if="transfer.transfer_to !== ''">{{transfer.transfer_to.name}}</span></h5>
                  <b-card class="border-radius-1em border shadow-none">
                      <div class="img-scrollbar" style="max-height: 400px; overflow-y: auto;">
                      <b-list-group class="border-radius-1em shadow-none">
                        <b-list-group-item v-for="(product, index) in transfer.products" class="p-0 d-flex"  no-body>
                          <img v-if="product.cover_image !== null" :src="product.cover_image.src" class="avatar-xl" />
                          <img v-else :src="'/images/product-placeholder.png'" class="avatar-xl" />
                          <div class="p-3 w-50 d-flex flex-column">
                          <span class="badge bg-primary position-absolute top-0 left-0">{{product.reference}}</span>
                          <h5 class="card-title font-size-14 mb-0 mt-auto">{{product.name}}</h5>
                          <p class="text-truncate mb-0" v-html="product.short_description"></p>
                           <p class="mt-auto">Quantity at This Listing: <span class="text-primary">{{getListingsQuantity(transfer.transfer_from.id, product.listings)}}</span></p>
                            <p class="mt-1">Quantity to Transfer: <span class="text-primary">{{product.transfer_quantity}}</span></p>
                          </div>
                          <div class="ml-auto mr-4 justify-content-center align-items-center d-flex">
                            <a class="btn btn-primary btn-rounded my-auto" @click="transfer.products.splice(index, 1)"v-b-modal.quantity-modal>
                              <i class="bx bx-trash-alt"></i>
                            </a>
                          </div>
                        </b-list-group-item>
                      </b-list-group>
                    </div>
                </b-card>
                </b-col>
      </b-row>
    </b-modal>-->
      <SideSlidePanel :editPanel="editPanel" :panelClass="addPanelClass" @close-panel="editPanel = !editPanel"  :expandable="1"  @expand="addPanelClass = 'edit-sidepanel-large'" @contract="addPanelClass = 'edit-sidepanel'">
      <b-row v-if="editPanel == (true || 1)" class="px-3">
            <b-col md="8">
              <b-row>
               
                <b-col md="12">
                  <b-card class="border-radius-1em border shadow-none">
                    <h4>Reference:  #{{edittransfer.uid}}</h4>
                  </b-card>
                </b-col>
              </b-row>
              <b-row>
               <b-col md="6">
                <b-card  v-if="edittransfer.transfer_from !== ''" class="border-radius-1em border shadow-none">
                <h5>Transfer From</h5>
                <h5 >{{edittransfer.transfer_from.name}}</h5>
                <p class="mb-0" v-if="edittransfer.transfer_from.address_1 !== null">{{edittransfer.transfer_from.address_1}}</p>
                <p class="mb-0" v-if="edittransfer.transfer_from.address_2 !== null">{{edittransfer.transfer_from.address_2}}</p>
                <p class="mb-0" v-if="edittransfer.transfer_from.county !== null">{{edittransfer.transfer_from.county.name}}</p>
                <p class="mb-0" v-if="edittransfer.transfer_from.postcode !== null">{{edittransfer.transfer_from.postcode}}</p>
                </b-card>
              </b-col> 
              <b-col md="6">
                <b-card v-if="edittransfer.transfer_to !== ''" class="border-radius-1em border shadow-none">
                <h5>Transfer To</h5>
                <h5>{{edittransfer.transfer_to.name}}</h5>
                <p class="mb-0" v-if="edittransfer.transfer_to.address_1 !== null">{{edittransfer.transfer_to.address_1}}</p>
                <p class="mb-0" v-if="edittransfer.transfer_to.address_2 !== null">{{edittransfer.transfer_to.address_2}}</p>
                <p class="mb-0" v-if="edittransfer.transfer_to.county !== null">{{edittransfer.transfer_to.county.name}}</p>
                <p class="mb-0" v-if="edittransfer.transfer_to.postcode !== null">{{edittransfer.transfer_to.postcode}}</p>
                </b-card>
              </b-col>
              <b-col md="12">
                 <b-card class="border-radius-1em border shadow-none">
            <h5>Products In Transfer</h5>
                      <div v-if="edittransfer !== null" class="img-scrollbar" style="max-height: 400px; overflow-y: auto;">
                      <b-list-group class="border-radius-1em shadow-none">
                        <b-list-group-item v-for="(product, index) in edittransfer.products" class="p-0 d-flex"  no-body>
                          <img v-if="product.product.cover_image !== null" :src="storageurl + product.product.cover_image.src" class="avatar-xl" />
                          <img v-else :src="'/images/product-placeholder.png'" class="avatar-xl" />
                          <div class="p-3 w-50 d-flex flex-column">
                          <span class="badge bg-primary position-absolute top-0 left-0">{{product.product.reference}}</span>
                          <h5 class="card-title font-size-14 mb-0 mt-auto">{{product.product.name}}</h5>
                          <p class="text-truncate mb-0 paragraph-truncate-line2 with-v-html" v-html="product.product.short_description"></p>
                          
                           <p class="mt-0 mb-0">Quantity to Transfer: <span class="text-primary">{{product.quantity}}</span></p>
                          </div>
                          <div class="ml-auto mr-4 justify-content-center align-items-center d-flex">
                            <a v-if="status == 'Booked'" class="btn btn-primary btn-rounded my-auto" @click="edittransfer.products.splice(index, 1)"v-b-modal.quantity-modal>
                              <i class="bx bx-trash-alt"></i>
                            </a>
                          </div>
                        </b-list-group-item>
                      </b-list-group>
                    </div>
                </b-card>
              </b-col>
            </b-row>
          </b-col>
               <b-col md="4">
                <b-card class="border-radius-1em border shadow-none" body-class="text-center">
                  <qrcode-vue  :value="JSON.stringify({type: 'stock-edittransfer', reference: edittransfer.uid, uid: edittransfer.uid, id: edittransfer.id})" level="L" foreground="#000000" background="#ffffff" margin="0" class="px-1"
                   size="230" />
                </b-card>
                <b-card class="border-radius-1em border shadow-none">
                    <h5>Requested By:</h5>
                    <div v-if="edittransfer.requested_by !== null">
                      <p class="mb-0">{{edittransfer.requested_by.user.name}} {{edittransfer.requested_by.user.lastname}}</p>
                      <a href="#">{{edittransfer.requested_by.user.email}}</a>
                    </div>
                </b-card>
                <b-card class="border-radius-1em border shadow-none">
                    <h5>Date Required By:</h5>
                    <p>{{edittransfer.date_required}}</p>
                </b-card>
                <b-card class="border-radius-1em border shadow-none">
                    <h5>Priority</h5>
                     <span class="badge badge font-size-16" :class="{'bg-danger text-white' : edittransfer.priority == 'High', 'bg-warning text-white' : edittransfer.priority == 'High', 'bg-info text-white' : edittransfer.priority == 'Low'}">{{edittransfer.priority}}</span>
                </b-card>
                <b-card class="border-radius-1em border shadow-none">
                    <h5>Status</h5>
                     <span class="badge badge bg-primary font-size-16">{{edittransfer.status}}</span>
                </b-card>
                <b-card class="border-radius-1em border shadow-none">
                    <h5>Notifications</h5>
                    <p><i class="bx bx-check-circle mr-3 text-success" v-if="edittransfer.send_store_notification == true">
                    </i> <i v-else class="bx bx-x-circle mr-3 text-danger"></i> Send in Store Notification</p>

                    <p><i class="bx bx-check-circle mr-3 text-success" v-if="edittransfer.send_email == true">
                    </i> <i v-else class="bx bx-x-circle mr-3 text-danger"></i> Send Store Email</p>
                </b-card>
              </b-col>
          </b-row>
    </SideSlidePanel> 

</Layout>
</template>

<style scoped>
  .checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #7ac142;
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}
.checkmark {
  width: 128px;
  height: 128px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #fff;
  stroke-miterlimit: 10;
  margin: 5% auto;
  background: #7ac142;
  box-shadow: inset 0px 0px 0px #7ac142;
  animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
}
.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes scale {
  0%, 100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}
@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px #7ac142;
  }
}
</style>