<script type="text/javascript">
	import DefaultContentProductCard from "./DefaultContent-ProductCard";

	export default{
		data(){
			return {
				hoverIndex: -1,
				hoverLink: -1,
				categories: [],
				routearray: [],
			}
		},
		props: {
			company: {
				type: Object
			},
			resource: {
				type: String,
				default: () => 'category'
			}
		},
		components: {
			DefaultContentProductCard
		},
		created(){
			this.getResource(this.resource);
		},
		methods: {
			getResource(resource){
				this.routearray = this.$route.path.substring(1).split('/');
				axios.get('/website-api/widget/'+resource+'/'+this.routearray[this.routearray.length-1]).then(response => {
					this.categories = response.data;
				}).catch(error => {
					this.categories = [];
					console.log(error);
				});
			}
		},
		  watch: {
        $route() {
        	this.$nextTick(() => {
        		 //this.loadingPage = true;
        		 this.getResource(this.resource);

        		});
        	}
        }
	}

</script>
<template>
	<b-container fluid class="py-4 mb-4 default-content category-collection">
		<b-row>
			<b-col md="12">
				<h1 class="text-capitalize">{{categories.name}}</h1>
					<p>{{categories.description}}</p>
			</b-col>
			<b-col md="3" v-for="(category, index) in categories.products">
				<DefaultContentProductCard :product="category.product" :company="company"></DefaultContentProductCard>
				<!--<router-link :to="'/'+($route.path.substring(1).split('/')[0]).replace('ies', 'y')+'/'+category.slug">
					<b-card no-body class="border shadow-none border-radius-1em" @mouseenter="hoverIndex = index" @mouseleave="hoverIndex = -1" >
						<b-card-img v-if="category.image" :src="category.image" />
						<b-card-img v-else :src="'/images/product-placeholder.png'" />
						<b-card-text class="p-4 peach-border-top-5 d-flex">
							<h3 class="font-size-18 my-auto py-2">{{category.name}}</h3>
						<transition name="fade-right">
							<router-link v-if="hoverIndex == index" class="border-bottom border-primary text-uppercase font-size-12 ml-auto text-dark my-auto py-2" :to="'/'+($route.path.substring(1).split('/')[0]).replace('ies', 'y')+'/'+category.slug">
								See Products <i class="bx bx-chevron-right"></i>
							</router-link>
						</transition>
						</b-card-text>
					</b-card>
				</router-link>-->
			</b-col>
			<b-col md="12">

				<b-pagination v-if="categories.length < 0" v-model="categories.current_page" :total-rows="categories.total" :per-page="categories.per_page" align="right"></b-pagination>
			</b-col>
		</b-row>
	</b-container>
</template>


