<script type="text/javascript">
	import DefaultContentContact from './DefaultContent-Contact';
	import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper';
	import SwiperClass, { Pagination } from 'swiper'
 	import 'swiper/swiper.min.css'
 	import DefaultContentProductCard from './DefaultContent-ProductCard';
 	import DefaultContentCategoryCard from "./DefaultContent-CategoryCard";

	export default {
		components: {
			DefaultContentProductCard,
			DefaultContentCategoryCard,
			DefaultContentContact,
			Swiper,
		  	SwiperSlide
		},
		data(){ return{
				products: [],
				categories: [],
				collections: [],
				swiperOptions: {
	           	direction: 'horizontal',
		          slidesPerView: 4,
		          spaceBetween: 15,
		          pagination: {
		            el: '.swiper-pagination',
		            clickable: true
		          },
		          navigation: {
		            nextEl: '.swiper-button-next',
		            prevEl: '.swiper-button-prev'
		          }
	        	},
			}
		},
		directives: {
		    swiper: directive
		},
		mounted(){
			this.getResource('products')
			this.getResource('categories')
			this.getResource('collections')
		},
		methods: {
			getResource(resource){
				this.routearray = this.$route.path.substring(1).split('/');
				axios.get('/website-api/widget/'+resource+'/'+this.routearray[this.routearray.length-1]).then(response => {
					this.$data[resource] = response.data;
					
				}).catch(error => {
					this.product = [];
					console.log(error);
				});
			},

		},
		props: {
			company: {
				type: Object
			}
		}
	}
</script>

<template>
	<section>
		<b-container fluid class="container-fullwidth bg-peach-soft">
			<b-row class="py-5">
				<b-col class="my-4 py-5 d-flex flex-column justify-content-center align-items-center" >
					<img src="/images/product-placeholder.png" class="avatar-md rounded-circle border-2 border-white">
					<h1>{{company.name}}</h1>
						
					<router-link to="/shop" class="btn btn-light btn-rounded btn-lg my-3">
						Visit Our Shop
					</router-link>
				</b-col>
			</b-row>
		</b-container>
		<b-container fluid class="py-5">
			<b-row class="py-5">
				<b-col>
					<h2>Latest Products</h2>

					<swiper ref="productGalleryCarousel" :options="swiperOptions" class="overflow-hidden w-100" >
					        <swiper-slide v-for="(product, index) in products.data" >

								<DefaultContentProductCard ref="latestProductsCarousel" :product="product" :company="company" :index="index"></DefaultContentProductCard>
					   		</swiper-slide>
					  	</swiper>

					<router-link to="/shop" class="btn btn-peach btn-rounded btn-lg float-right">
						Shop Our Collection
					</router-link>
				</b-col>

			</b-row>
		</b-container>

		<b-container fluid class="py-5">
			<b-row class="py-5">
				<b-col>
					<h2>Top Categories</h2>

					<swiper ref="productCategoryCarousel" :options="swiperOptions" class="overflow-hidden w-100" >
					        <swiper-slide v-for="(category, index) in categories.data" >
					        	
								<DefaultContentCategoryCard :category="category" :link_route="/category/" :company="company"></DefaultContentCategoryCard>
								
					   		</swiper-slide>
					  	</swiper>
					<router-link to="/categories" class="btn btn-peach btn-rounded btn-lg float-right">
						See Our Categories
					</router-link>
				</b-col>
			</b-row>
		</b-container>

		<b-container fluid class="py-5">
			<b-row class="py-5">
				<b-col>
					<h2>Featured Collections</h2>
					<swiper ref="productCategoryCarousel" :options="swiperOptions" class="overflow-hidden w-100" >
					        <swiper-slide v-for="(collection, index) in collections.data" >

								<DefaultContentCategoryCard :category="collection"  :link_route="/collection/" :company="company"></DefaultContentCategoryCard>
					   		</swiper-slide>
					  	</swiper>
					<router-link to="/collections" class="btn btn-peach btn-rounded btn-lg float-right">
						Shop Our Collections
					</router-link>
				</b-col>
			</b-row>
		</b-container>
		<b-container fluid class="py-5 container-fullwidth">
			<hr>
			<b-row>
				<DefaultContentContact :company="company"></DefaultContentContact>
			</b-row>
		</b-container>
	</section>
</template>