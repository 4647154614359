<script type="text/javascript">
	import DefaultContentProductCard from './DefaultContent-ProductCard';
	export default{
		components:{
			DefaultContentProductCard
		},
		data(){
			return{
				loadingProducts: true,
				products: null,
				expanded: true,
				query: '',
				view: 'grid',
				layouttoggle: true,
				search: '',
				showFilters: false,
				current_page: 1,
				limit: 16,
				limits: 
				[ 
					{value: 16, text: "16"},
		        	{value: 32, text: "32"},
		        	{value: 64, text: "64"}
		        ]
			}
		},
		created(){
			this.getProducts();
		},
		methods: {
			getProducts(){
				this.createQueryString();
				this.loadingProducts = true;
				axios.get('/website-api/widget/products?page='+this.current_page+this.query).then(response => {
					this.products = response.data;
					let self = this;
				          setTimeout(function(){
				            self.loadingProducts = false;
				          }, 500);
				}).catch(error => {
					let self = this;
				          setTimeout(function(){
				            self.loadingProducts = false;
				          }, 500);
				});
			},
			createQueryString(){
				this.query = '&limit='+this.limit+'&search='+this.search;
			}
		}
	}

</script>

<template>
		<b-container fluid class="py-4 mb-4 default-content products">
		<b-row>
			<b-col md="12">
				<h1 class="text-capitalize">{{$attrs.resource}}</h1>
					<p>Browse the full collection from {{$attrs.company.name}}.</p>
			</b-col>
			<b-col class="col-md-12">
						<div class="row mb-3">
        
          <div class="col-lg-3 col-sm-6 d-flex">
          <form class="my-auto">
            <small class="mx-auto my-2 text-left d-block" v-if="products !== null">Results {{products.from}} - {{products.to}} of {{products.total}}</small>
          </form>
        </div>  
        <div class="col-lg-6 col-sm-6 d-flex">
            <div class="mt-sm-0 form-inline w-100" >
              <div class="app-search search-box mr-2 ml-auto">
                <div class="position-relative">
                  <input
                    type="text"
                    class="form-control w-100 border"
                    placeholder="Search name, reference or description..."
                    v-model="search"
                    @keyup.enter="getProducts()"
                  />
                  <span class="bx bx-search-alt search-icon"></span>
                </div>
              </div>
            </div>
          </div>

         
			
			<b-col md="3" class="d-flex">
				<ul class="nav nav-pills product-view-nav my-auto ml-auto d-flex" v-if="layouttoggle == true">
					<li class="nav-item  ml-auto mr-2">
                  <a class="nav-link" :class="{'active': showFilters == true }" href="javascript: void(0);" @click="showFilters = !showFilters, expanded = !expanded">
                    <i class="bx bx-filter-alt"></i>
                  </a>
                </li>
          		<!--<li class="nav-item px-1">
                  <a class="nav-link" :class="{'active': view == 'grid' }" href="javascript: void(0);" @click="view = 'grid'">
                    <i class="bx bx-grid-alt"></i>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" :class="{'active': view == 'list' }" href="javascript: void(0);" @click="view = 'list'">
                    <i class="bx bx-list-ul"></i>
                  </a>
                </li>-->
               </ul>
          	 <b-form-select class="form-control my-auto w-25 pl-1" v-model="limit" :options="limits" @input="getProducts"></b-form-select>
          </b-col>
		</div>
	</b-col>
			<b-col md="12" v-if="products !== null">
				<b-overlay :show="loadingProducts">
				<b-row>
					<b-col md="3" v-for="(product, index) in products.data">
						<DefaultContentProductCard :product="product" :company="$attrs.company" :index="index"></DefaultContentProductCard>
					</b-col>
					<b-col md="12" v-if="products.data.length == 0">
						<b-card class="border-radius-1em shadow-none border py-4 text-center">
							<img src="/images/Management.png" class="img-fluid" style="max-width: 500px">
							<h1>We're Working On It!</h1>
							<p>There's no products here yet, but we're adding some - just watch this space!</p>
						</b-card>
					</b-col>
				</b-row>
				</b-overlay>
			</b-col>
			<b-col v-else>
				<b-card class="border-radius-1em shadow-none border py-4 text-center">
					<img src="/images/Management.png" class="img-fluid" style="max-width: 500px">
					<h1>We're Working On It!</h1>
					<p>There's no products here yet, but we're adding some - just watch this space!</p>
				</b-card>
			</b-col>
			<b-col md="12">
				<b-pagination v-if="products !== null" v-model="current_page" :total-rows="products.total" :per-page="products.per_page" align="right" @input="getProducts()"></b-pagination>
			</b-col>
		</b-row>
		<transition name="fade-left">
		<div v-if="showFilters == true" style="" class="filter-sidebar position-fixed w-100 bg-white top-0 left-0 bottom-0 shadow-lg">
			<b-row>
				<b-col md="12" class="px-4 pt-4 pb-1 d-flex">
					<a @click="showFilters = !showFilters" class="btn btn-outline-light btn-rounded ml-auto my-auto mr-3">
						<i class="mdi mdi-close font-size-20"></i>
					</a>
				</b-col>
				<b-col md="12" class="p-4">
					<div class="px-3">
					<div>
               <div class="my-3 pt-3">
              <h5 class="font-size-14">Price</h5>
              <vue-slider v-model="priceRange" :min="0" :max="100 + priceRange[1]"  @drag-end="productQuery()" ></vue-slider>
              <b-form-group label="Min Price" class="w-50 d-inline float-left mt-2 mb-3 px-2">
              <b-form-input
                type="number"
                v-model="priceRange[0]"
                @input="productQuery"></b-form-input>
              </b-form-group>
              <b-form-group label="Max Price" class="w-50 d-inline float-left mt-2 mb-3 px-2">
                 <b-form-input
                 label="Max Price"
                 type="number"
                 v-model="priceRange[1]"
                 @input="productQuery"></b-form-input>
              </b-form-group>
            </div>
                <h5 class="my-3">Categories</h5>
                <!--<FilterListTree></FilterListTree>-->
               
                <b-overlay :show="loadingFilters">
                  <FilterCompanyProductCategories :api_token="$attrs.user.api_token" :selectedids="categories" :category_data="category_data"  :marketplace_categories="'trade'" ></FilterCompanyProductCategories>
              </b-overlay>
                <h5 v-b-toggle.collapse-brands  class="pb-3 border-bottom">Brands <i class="mdi mdi-plus ml-auto hide-on-collapse float-right"></i><i class="mdi mdi-minus ml-auto show-on-collapse float-right"></i></h5>
                 <b-collapse id="collapse-brands" class="mt-2">
               <FilterProductBrands v-on:brands-filtered="filterBrands" :api_token="$attrs.user.api_token"></FilterProductBrands>
             </b-collapse>
               <h5 v-b-toggle.collapse-gemstones  class="py-3">Gemstone <i class="mdi mdi-plus ml-auto hide-on-collapse float-right"></i><i class="mdi mdi-minus ml-auto show-on-collapse float-right"></i></h5>
               <b-collapse id="collapse-gemstones" class="mt-2">
                <b-form-group>
                  <b-form-checkbox-group stacked v-model="keywordslist" @change="productQuery()" :options="productGemstones"></b-form-checkbox-group>
                </b-form-group>
              </b-collapse>
                <h5 v-b-toggle.collapse-metal class="py-3">Metal <i class="mdi mdi-plus ml-auto hide-on-collapse float-right"></i><i class="mdi mdi-minus ml-auto show-on-collapse float-right"></i></h5>
                <b-collapse id="collapse-metal" class="mt-2">
                <b-form-group>
                  <b-form-checkbox-group stacked v-model="keywordslist"  @change="productQuery()" :options="productMetals"></b-form-checkbox-group>
                </b-form-group>
              </b-collapse>
                <h5 v-b-toggle.collapse-condition class="py-3">Condition <i class="mdi mdi-plus ml-auto hide-on-collapse float-right"></i><i class="mdi mdi-minus ml-auto show-on-collapse float-right"></i></h5>
                <b-collapse id="collapse-condition" class="mt-2">
                <b-form-group>
                  <b-form-checkbox-group stacked v-model="condition"  @change="productQuery()" :options="productConditions"></b-form-checkbox-group>
                </b-form-group>
              </b-collapse>
               
            </div>
          </div>
				</b-col>
			</b-row>
		</div>
	</transition>
	</b-container>
</template>