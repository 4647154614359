<script>
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import DateTime from 'luxon/src/datetime.js';
import Interval from 'luxon/src/interval.js';
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
import SideSlidePanel from "../../components/widgets/SideSlidePanel"
import Toolbar from "../../components/widgets/admin/email/EmailToolbar";
import Sidepanel from "../../components/widgets/admin/support/SupportSidepanel";
import EmailViewer from "../../components/widgets/admin/email/EmailViewer";
import Emailer from "../../components/widgets/admin/email/Emailer"
import CompanyCustomerSupportThreads from "../../components/widgets/admin/support/CompanyCustomerSupportThreads"
import CompanyCustomerSupportTwilioThreads from "../../components/widgets/admin/support/CompanyCustomerSupportTwilioThreads"
//import { emailData } from "./data-inbox";

/**
 * Email-inbox component
 */
export default {
  components: {
    Layout,
    PageHeader,
    Toolbar,
    ckeditor: CKEditor.component,
    Sidepanel,
  },
  components: {
    Layout,
    PageHeader,
    Toolbar,
    Sidepanel,
    EmailViewer,
    SideSlidePanel,
    Emailer,
    DateTime,
    Interval,
    CompanyCustomerSupportTwilioThreads,
    CompanyCustomerSupportThreads
  },
  data() {
    return {
      unreadEmails: 0,
      loadingMessages: false,
   	  success_message: false,
   	  success_messagetext: '',
      loadEmailViewer: false,
      emailViewerPanel:  false,
      emailViewerData: true,
      emailData: '',
      emailDataRaw: '',
      active_filter: '',
      send_email: {
      	id: '',
        notification_thread_id: '',
        email_to: [],
    		email_cc: [],
    		email_bcc: [],
    		subject: '',
    		message: '',
        replying_to: [],
      },
      send_email_raw: {
        id: '',
        notification_thread_id: '',
        email_to: [],
    		email_cc: [],
    		email_bcc: [],
    		subject: '',
    		message: '',
        replying_to: [],
      },
      paginatedEmailData: '',
      title: "Customer Service",
      items: [
        {
          text: "Customer",
          href: "/",
        },
        {
          text: "Customer Service",
          active: true,
        },
      ],
      // page number
      currentPage: 1,
      // default page size
      perPage: 15,
      emailIds: [],
      // start and end index
      startIndex: 1,
      endIndex: 15,
    };
  },
  computed: {
    awaitingAssignment(){
      if(this.emailData.length > 0){
        var awating = this.emailData.filter(item => item.assigned_to_id == null);
        return awating.length;
      }
    },
    rows() {
      	return this.emailData.length;
    },
    unread(){
    	if(this.emailDataRaw.length > 0){
    		return this.emailDataRaw.filter(item => item.read_at == null).length;
    	}else{
    		return 0;
    	}
    }
  },
  mounted() {
    
    
  },
  created() {
    var url = window.location.href;
    if(url.search("support_type=") > 0){
              var support_source = url.substring(url.lastIndexOf("=") + 1);
              //this.source = support_source;
              this.getEnquiries(support_source);
              //console.log(cthread_uid);
              
          }else{
  	          this.getEnquiries('');
        }
    this.startIndex = 0;
    this.endIndex = this.perPage;

    
  },
  methods: {
  	getDate(date){
  		return DateTime.fromISO(date).toHTTP();
  	},
  	sendEmail(email){
  		if(email !== this.send_email_raw){
  			this.send_email = email;
  		}else{
  			this.send_email = this.send_email_raw;
  		}
  		this.$bvModal.show('send-email');
   	},
  	filterNotifications(params){
  		this.active_filter = params;
      this.$attrs.id = '';
      this.getEnquiries(params);
  		/*if(params !== ''){
  		this.emailData = this.emailDataRaw.filter(item => item.data.source == params);
  		this.paginatedEmailData = this.emailData.slice(
			      0,
			      this.perPage
			    );
  		}else{
  			this.emailData = this.emailDataRaw;
  			this.onPageChange();
  		}*/
  	},
  	readEmail(email){
  		this.loadEmailViewer = true;
  		this.emailViewerPanel = true;
  		this.emailViewerData = email;
  		this.markAsRead(email.id, true);
  		 var self = this;
                setTimeout(function(){
                 //Vue.swal("Avatar Updated");
                 self.loadEmailViewer = false;
                }, 500);
  	},
  	getEnquiries(enquirytype){
      //this.loadingMessages = true;
      ///this.emailData = '';
  		axios.get('/api/company/communication/support-threads?type='+enquirytype, {headers : {
  			'Authorization' : 'Bearer '+this.$attrs.user.api_token
  		}}).then(response => {
  			this.emailData = response.data[0];
  			this.emailDataRaw = response.data[0];
        this.unreadEmails = response.data[1]
        /*if(enquirytype == ''){
          this.emailData.reverse();
        }*/
        let self = this;
        setTimeout(function(){
          self.loadingMessages = false;
        }, 600);
  			this.paginatedEmailData = this.emailData.slice(
			      this.startIndex,
			      this.endIndex
			    );

        
  		}).catch(error => {

  		});
  	},
  	markAsRead(id, source, isread){
  		axios.post('/api/company/communication/support-thread/'+id[0]+'/read', {from: id[1], read: id[2]}, {headers : {
  			'Authorization' : 'Bearer '+this.$attrs.user.api_token
  		}}).then(response => {
  			 this.getEnquiries(this.active_filter);
  		}).catch(error => {

  		});
  	},
    markInDispute(id, source, isread){
      axios.post('/api/company/communication/support-thread/'+id[0]+'/indispute', {from: id[1], read: id[2]}, {headers : {
        'Authorization' : 'Bearer '+this.$attrs.user.api_token
      }}).then(response => {
         this.getEnquiries(this.active_filter);
      }).catch(error => {

      });
    },
    markComplete(id, source, isread){
      axios.post('/api/company/communication/support-thread/'+id[0]+'/complete', {from: id[1], read: id[2]}, {headers : {
        'Authorization' : 'Bearer '+this.$attrs.user.api_token
      }}).then(response => {
         this.getEnquiries(this.active_filter);
      }).catch(error => {

      });
    },
    markArchived(id, source, isread){
      axios.post('/api/company/communication/support-thread/'+id[0]+'/archived', {from: id[1], read: id[2]}, {headers : {
        'Authorization' : 'Bearer '+this.$attrs.user.api_token
      }}).then(response => {
         this.getEnquiries(this.active_filter);
      }).catch(error => {

      });
    },
    onPageChange() {
    	if(this.active_filter !== ''){
	      this.startIndex = (this.currentPage - 1) * this.perPage;
	      this.endIndex = (this.currentPage - 1) * this.perPage + this.perPage;

	      this.paginatedEmailData = this.emailData.slice(
	        this.startIndex,
	        this.endIndex
	      );
  		}else{
  			this.startIndex = (this.currentPage - 1) * this.perPage;
	      this.endIndex = (this.currentPage - 1) * this.perPage + this.perPage;

	      this.paginatedEmailData = this.emailDataRaw.slice(
	        this.startIndex,
	        this.endIndex
	      );
  		}
    },
  },
};
</script>

<template>
  <Layout  :nickname="$attrs.nickname" :user="$attrs.user" :menuItems="$attrs.menuItems">
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <!-- Right Sidebar -->
      <b-col md="12">
                    <transition name="slide-success" mode="out-in">
                  <b-alert id="success-message" variant="success" :show="success_message"  dismissible @dismissed="success_message = !success_message">
                      <p class="font-size-14">{{success_messagetext}}</p>
                    </b-alert>
                  </transition>
                  </b-col>
      <div class="col-12">
        <b-row>
          <b-col>
            <b-card class="shadow-sm border-radius-1em">
              <div class="d-flex align-items-center mb-3"><div class="avatar-xs mr-3"><span class="avatar-title rounded-circle bg-soft-primary text-white font-size-18"><i class="bx bx-mail-send"></i></span></div> <h5 class="font-size-14 mb-0">Outstanding Messages</h5></div> <div class="text-muted mt-4"><h4>{{unreadEmails}}</h4> </div>
            </b-card>
          </b-col>
           <b-col>
            <b-card class="shadow-sm border-radius-1em">
              <div class="d-flex align-items-center mb-3"><div class="avatar-xs mr-3"><span class="avatar-title rounded-circle bg-soft-warning text-white font-size-18"><i class="bx bx-timer"></i></span></div> <h5 class="font-size-14 mb-0">Average Response Time</h5></div> <div class="text-muted mt-4"><h4>5 mins</h4> </div>
            </b-card>
          </b-col>
           <b-col>
            <b-card class="shadow-sm border-radius-1em">
              <div class="d-flex align-items-center mb-3"><div class="avatar-xs mr-3"><span class="avatar-title rounded-circle bg-soft-primary text-white font-size-18"><i class="bx bx-warning"></i></span></div> <h5 class="font-size-14 mb-0">In Dispute</h5></div> <div class="text-muted mt-4"><h4>0</h4> </div>
            </b-card>
          </b-col>
          <b-col>
            <b-card class="shadow-sm border-radius-1em">
              <div class="d-flex align-items-center mb-3"><div class="avatar-xs mr-3"><span class="avatar-title rounded-circle bg-soft-primary text-white font-size-18"><i class="bx bx-user"></i></span></div> <h5 class="font-size-14 mb-0">Awaiting Assignment</h5></div> <div class="text-muted mt-4"><h4>{{awaitingAssignment}}</h4> </div>
            </b-card>
          </b-col>
        </b-row>
        <b-row>
      
          <b-col md="12">
        <div class="mb-3">
          <div class="card">
            <!--<div class="btn-toolbar p-3">
              <Toolbar /> 
            </div>-->
            <div class="px-4">
              <b-overlay :show="loadingMessages">
              <!--<CompanyCustomerSupportThreads v-if="emailData !== ''" :thread_list="emailData" :expanded="isExpanded" :view="'list'" @mark-read="markAsRead" :user="$attrs.user" :thread_uid="$attrs.id"></CompanyCustomerSupportThreads>
              -->
              <CompanyCustomerSupportTwilioThreads ref="threads" :thread_list="emailData" :expanded="isExpanded" :view="'list'" @mark-read="markAsRead" @mark-archived="markArchived" @mark-disputed="markInDispute" @mark-complete="markComplete" :user="$attrs.user" :thread_uid="$attrs.id" :company="$attrs.company" @filter="filterNotifications"></CompanyCustomerSupportTwilioThreads>
              </b-overlay>

              <!--<ul class="message-list">
                <li
                  :class="{ 'unread': email.read_at == null }"
                  v-for="(email,index) in paginatedEmailData"
                  :key="index"
                >
                  <a >
                    <div class="col-mail col-mail-1">
                      <div class="checkbox-wrapper-mail">
                        <input :id="`chk-${index}`" type="checkbox" />
                        <label :for="`chk-${index}`"></label>
                      </div>
                  
                      <span class="badge font-size-11" :class="{'bg-peach text-dark' : email.data.source == 'website', 'bg-primary text-dark' : email.data.source == 'public', 'bg-secondary' : email.data.source == 'trade'}" v-b-tooltip.hover :title="email.data.source+' enquiry'" >{{email.data.source}}</span>
                      <a href="javascript:;" @click="readEmail(email), email.read_at = true" class="title">{{email.data.name}}</a>
                    </div>
                    <div class="col-mail col-mail-2">
                      <a href="javascript:;" @click="readEmail(email), email.read_at = true" class="subject">{{email.data.message}}</a>
                      <div class="date px-0">{{getDate(email.created_at)}}</div>
                    </div>
                  </a>
                </li>
              </ul>-->
            </div>
           
          </div>
          <div class="row justify-content-md-between align-items-md-center">
            <div class="col-xl-7">Showing {{startIndex}} - {{endIndex}} of {{rows}}</div>
            <!-- end col-->
            <div class="col-xl-5">
              <div class="text-md-end float-xl-end mt-2 pagination-rounded">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="rows"
                  :per-page="perPage"
                  @input="onPageChange"
                ></b-pagination>
              </div>
            </div>
            <!-- end col-->
          </div>
        </div>
      </b-col>
    </b-row>
      </div>
        <SideSlidePanel :editPanel="emailViewerPanel" :panelClass="'edit-sidepanel'" @close-panel="emailViewerPanel = false">
        	<b-overlay :show="loadEmailViewer">
    			<EmailViewer :email="emailViewerData" @send-email="sendEmail"></EmailViewer>
    		</b-overlay>
	    </SideSlidePanel>
	    <b-modal id="send-email" size="lg" centered hide-footer content-class="border-radius-1em">
        	<Emailer :email="send_email" :user="$attrs.user"></Emailer>
        </b-modal>
    </div>

  </Layout>
</template>
