<template>
	<swiper class="overflow-hidden" :ref="'sliderWidgetCarousel-'+$attrs.id" v-if="$attrs.content[filterStylesKey($attrs.content, 'layout')].layout.value == 'Layout 1'" :modules="modules" :pagination="{ clickable: true }"> 
		<swiper-slide v-for="slide in $attrs.content[filterStylesKey($attrs.content, 'list')].list" :caption="slide[filterStylesKey(slide, 'title')].value">
			<b-card overlay class="shadow-none" :img-src="slide[filterStylesKey(slide, 'image')].image.value" >
				<div class="d-flex flex-column">
					<h5>{{slide[filterStylesKey(slide, 'title')].title.value}}</h5>
				</div>
			</b-card>
		</swiper-slide>
	</swiper>
	<b-carousel v-else-if="$attrs.content[filterStylesKey($attrs.content, 'layout')].layout.value == 'Layout 2'"
		id="carousel-fade"
		style="text-shadow: 0px 0px 2px #000"
		controls
		fade
		indicators
		:img-width="checkExists($attrs.styles[filterStylesKey($attrs.styles, 'width')].width.value, $attrs.styles[filterStylesKey($attrs.styles, 'width')].width.units)"
		:img-height="checkExists($attrs.styles[filterStylesKey($attrs.styles, 'height')].height.value, $attrs.styles[filterStylesKey($attrs.styles, 'height')].height.units)"
	>
		<b-carousel-slide v-for="slide in $attrs.content[filterStylesKey($attrs.content, 'list')].list" :caption="slide[filterStylesKey(slide, 'title')].value" :img-src="slide[filterStylesKey(slide, 'image')].image.value"></b-carousel-slide>
	</b-carousel>
</template>

<script type="text/javascript">
	import SwiperClass, { Pagination } from 'swiper'
	import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
	//import 'swiper/css'
  	import 'swiper/swiper.min.css'
	export default {
		components: {
		    Swiper,
		    SwiperSlide,
		},
		setup() {
	      return {
	        modules: [Pagination]
	      }
	    },
		directives: {
		    swiper: directive
		},
		data() {
	      return {
	      	storageurl: 'https://'+this.$attrs.company.nickname+'.gemesys.co.uk/image/',
	      }
	    },
	    computed: {
	      swiper() {
	        return this.$refs['sliderWidgetCarousel-'+this.$attrs.id].$swiper
	      }
	    },
	    mounted() {
		    console.log('Current Swiper instance object', this.swiper);
		      this.swiper.slideTo(this.startSlide, 1000, false);
		},
	    methods: {
	    	checkExists(value, units){
	    		if(value !== ''){
	    			return value+units;
	    		}else{
	    			return null;
	    		}
	    	},
		    filterStylesKey(styles, key){
	            for(var i = 0; i < styles.length; i++){
	               var keys = Object.keys(styles[i])
	               if(keys.includes(key)){
	                 return i;
	               }
	            }
	        },
	    }
	}

</script>