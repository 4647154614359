<script type="text/javascript">
	import EventBus from '../../public-app'
	import DateTime from 'luxon/src/datetime.js';
  	import Interval from 'luxon/src/interval.js';
  	import Swiper from "../../components/widgets/carousel/Swiper";
  	import SellerSwiper from "../../components/widgets/carousel/SellerSwiper";
  	import PublicMarketplaceSellerList from "../../components/widgets/PublicMarketplaceSellerList";
  	import PublicMarketplaceProductList from "../../components/widgets/PublicMarketplaceProductList";
  	import CustomerAddressForm from "../../components/widgets/forms/website/CustomerAddressForm";
  	import CustomerSupportForm from "../../components/widgets/forms/website/CustomerSupportForm";
  	import CustomerAddresses from "../../components/widgets/lists/website/WebsiteCustomerAddresses";
  	import OrderList from '../../components/widgets/ecommerce/website/OrderList';
  	import WebsiteCustomerMessages from '../../components/widgets/lists/website/WebsiteCustomerMessages';
	export default{
		components: { Swiper, SellerSwiper, CustomerAddressForm, CustomerAddresses, PublicMarketplaceSellerList, PublicMarketplaceProductList, OrderList, WebsiteCustomerMessages, CustomerSupportForm },
		data(){
			return{
				message_threads: [],
				loadingAddressesForm: false,
				updateAddress: true,
				showControls: -1,
				orders_page: 1,
				orders_limit: 15,
				orders: '',
				form: '',
				action: '',
				address: {
					id: '',
					customer_id: '',
					address_1: '',
					address_2: '',
					address_3: '',
					city: '',
					county: {
						id: '',
					},
					country: {
						id: '',
					},
					postcode: '',
					alias: '',
					delivery_default: 0,
					invoice_default: 0,
					active: 1,

				},
				address_data: {
					id: '',
					customer_id: '',
					address_1: '',
					address_2: '',
					address_3: '',
					city: '',
					county: {
						id: '',
					},
					country: {
						id: '',
					},
					postcode: '',
					alias: '',
					delivery_default: 0,
					invoice_default: 0,
					active: 1,

				},
				swiperOptions: {
		          slidesPerView: 4,
		          spaceBetween: 5,
		          loop: false,
		          loopFillGroupWithBlank: false,
		          pagination: {
		            el: '.swiper-pagination',
		            clickable: true,
		          },
		          // Some Swiper option/callback...
		        },
		        favourites: [],
		        wishlist: [],
			}
		},
		props: {
			user: {
				type: Object
			},
			cardExpanded: {
				type: String,
				default: () => ''
			}
		},
		methods: {
			markAsRead(params){
				console.log(params);
				var id = params[0];
				var isread = params[1];
		  		axios.post('/website-api/user/message/'+id+'/read', {read: isread}, {headers : {
		  			'Authorization' : 'Bearer '+this.user.api_token
		  		}}).then(response => {
		  			
		  		}).catch(error => {

		  		});
		  	},
			addressAddedUpdated(address){
				this.loadingAddressesForm = true;
				var self = this;
                setTimeout(function(){
                 //Vue.swal("Avatar Updated");
                 self.loadingAddressesForm = false;
                }, 500);
			},
			loadUserOrders(limit,page){
				axios.get('/website-api/user/orders?page='+page+'&limit='+limit, {headers: {
					'Authorization': 'Bearer ' + this.user.api_token
				}
				}).then(response => {
					this.orders = response.data;
				}).catch(error => {

				});
			},
			loadUserMessages(limit,page){
				axios.get('/website-api/user/messages?page='+page+'&limit='+limit, {headers: {
					'Authorization': 'Bearer ' + this.user.api_token
				}
				}).then(response => {
					this.message_threads = response.data;
				}).catch(error => {

				});
			},
			dateParser(date){
				return DateTime.fromISO(date).toLocaleString(DateTime.DATE_FULL);
			},
			updateAddressForm(payload){
				this.address = payload;
				console.log(payload);
				this.form = 'address';
				this.action = 'update';
			},
			getFavourties(limit){
				axios.get('/website-api/user/favourites?limit='+limit , {headers: {
			            'Authorization': 'Bearer ' + this.user.api_token
			        } }).then(response => {
					this.favourites = response.data;
				}).catch(error => {
					alert("Error loading favourites");
				});
			},
			getWishlist(limit){
				axios.get('/website-api/user/wishlist?limit='+limit , {headers: {
			            'Authorization': 'Bearer ' + this.user.api_token
			        } }).then(response => {
					this.wishlist = response.data;
				}).catch(error => {
					alert("Error loading wishlist");
				});
			},
			goBack(){
					this.$router.push({ path: '/account' });
			},
			removeHash () { 
			    var scrollV, scrollH, loc = window.location;
			    if ("pushState" in history){
			        history.pushState("", document.title, loc.pathname + loc.search);
			        this.$router.push({ query:  {}, hash: ''});
				}else {
			        // Prevent scrolling by storing the page's current scroll offset
			        scrollV = document.body.scrollTop;
			        scrollH = document.body.scrollLeft;

			        loc.hash = "";

			        // Restore the scroll offset, should be flicker free
			        document.body.scrollTop = scrollV;
			        document.body.scrollLeft = scrollH;
			    }
			    
			},
			removeUid(){
				if(Object.keys(this.$route.query).includes('uid')){
					this.$router.push({ query:  {}, params: {root: 'account', child: this.view}});
				}
			}
		},
		mounted(){
				var url = location.hash;
        		if(url.search("uid=") > 0){
             			 var uid = url.substring(0, url.lastIndexOf("?")).replace('#', '').replace('?', '');
             			 this.cardExpanded = uid;
             	 }else{
        		 	this.cardExpanded = this.$route.params.child;
        			}

				this.getFavourties(12);
				this.getWishlist(12);
				this.loadUserOrders(5,1);
				this.loadUserMessages(5,1);
		},
		watch: {
        $route() {
        	this.$nextTick(() => {
        		var url = location.hash;
        		if(url.search("uid=") > 0){
             			 var uid = url.substring(0, url.lastIndexOf("?")).replace('#', '').replace('?', '');
             			 this.cardExpanded = uid;
             	 }else{
        		 	this.cardExpanded = this.$route.params.child;
        			}
        		 if(this.cardExpanded == 'favourites'){
        		 	this.getFavourties(0);
        		 }
        		 if(this.cardExpanded == 'wishlist'){
        		 	this.getWishlist(0);
        		 }
        		 if(this.cardExpanded == 'orders'){
        		 	this.loadUserOrders(this.orders_limit, this.orders_page);
        		 }
        		 if(this.cardExpanded == 'messages'){
        		 	this.loadUserMessages(15, this.page);
        		 }
        		 if(this.cardExpanded == ''){
        		 	this.getFavourties(12);
					this.getWishlist(12);
					this.loadUserOrders(5,1);
					this.loadUserMessages(5,1);;
        		 }
        	 });
            //this.$nextTick();
           // let self = this;
			
         }  
    	},
	}
</script>

<template>
	<div>
	<b-card class="border-radius-1em mt-0" v-if="(cardExpanded == '') || (cardExpanded == 'profile')">
		<h5 class="mb-4">Basic Details</h5>
		<b-row>
			
			<b-col>
				<label>Name</label>
				<p>{{user.name}} {{user.lastname}}</p>

				<label>Email</label>
				<p>{{user.email}} <i class="bx bx-check-circle bg-success text-white font-size-18 rounded-circle" v-if="user.email_verified_at !== null" v-b-tooltip.hover title="Email Address Verified" ></i></p>

				<label>Password</label>
				<p>*********</p>
			</b-col>

			<b-col>
				<label>Phone</label>
				<p>{{user.phone}}</p>

				<label>Mobile Phone</label>
				<p>{{user.mobile_phone}} <i class="bx bx-check-circle bg-success text-white font-size-18 rounded-circle" v-if="user.email_verified_at !== null" v-b-tooltip.hover title="Email Address Verified" ></i></p>

				<label>Joined</label>
				<p>{{ dateParser(user.created_at) }}</p>
			</b-col>
			<b-col md="12" class="d-flex">
				<a class="text-primary ml-auto" href="javascript:;" @click="">Edit</a>
			</b-col>
		</b-row>
	</b-card>

	<b-card class="border-radius-1em" v-if="(cardExpanded == '') || (cardExpanded == 'addresses')">
		<h5 class="mb-4">Addresses</h5>
		<b-row>
			<b-col>
				<h6>Default Delivery Address</h6>
				<div v-if="user.default_delivery_address">
					<b-card class="border-radius-1em shadow-none border" @mouseenter="showControls = 'delivery'" @mouseleave="showControls = -1">
				<div class="media d-flex flex-column">
					<i v-b-tooltip.hover title="Default Delivery Address" class="bx bxs-truck position-absolute bg-success text-white top-0 right-0 mt-2 mr-2 font-size-18 rounded-circle p-1"></i>
						<div class="avatar-sm my-auto mx-auto">
							
							<a v-b-modal.modal-edit @click="updateAddressForm(user.default_delivery_address)"><span class="avatar-title rounded-circle">
								<i v-if="showControls == 'delivery'" class="bx bx-pencil font-size-18 text-white"></i>
							<i class="bx bx-home font-size-18"  v-else></i>
							</span></a>
		                 </div>
		                 <div class="media-body position-relative mt-2 text-center mx-auto">       
		                 <h5 class="font-size-14 mb-1">
		                    {{user.default_delivery_address.alias}}
		                 </h5>
		                 <div>
							<span v-if="user.default_delivery_address.address_1">{{user.default_delivery_address.address_1}}</span>
							<span v-if="user.default_delivery_address.address_2">{{user.default_delivery_address.address_2}}</span>
							<span v-if="user.default_delivery_address.address_3">{{user.default_delivery_address.address_3}}</span>
							<span v-if="user.default_delivery_address.city">{{user.default_delivery_address.city}}</span>
							<span v-if="user.default_delivery_address.country">{{user.default_delivery_address.country.name}}</span>
							<span v-if="user.default_delivery_address.county">{{user.default_delivery_address.county.name}}</span>
							<span v-if="user.default_delivery_address.postcode">{{user.default_delivery_address.postcode}}</span>
						</div>
						

					</div>
				</div>
			</b-card>
				</div>
				<div v-else class="border border-dashed border-radius-1em p-5 mt-2 text-center position-relative" @mouseenter="updateAddress = 'delivery'" @mouseleave="updateAddress = ''">
					<i class="bx bx-home text-primary font-size-18"></i>
					<p class="text-muted mb-0">No default address set</p>
				<transition name="fade-top">
					<div class="bg-white avatar-title text-dark top-0 bottom-0 left-0 right-0 mb-2 mx-auto position-absolute w-100 text-uppercase border-radius-1em" v-if="updateAddress == 'delivery'">
                	<a class="btn btn-primary btn-rounded w-75" v-b-modal.modal-edit @click="address = JSON.parse(JSON.stringify(address_data)), form = 'address', action = 'create', address.delivery_default = 1" >Add New Address</a>
                	</div>
                </transition>
				</div>
			</b-col>
			<b-col>
				<h6>Default Billing Address</h6>
				<div v-if="user.default_invoice_address">
					<b-card class="border-radius-1em shadow-none border" @mouseenter="showControls = 'invoice'" @mouseleave="showControls = -1">
				<div class="media d-flex flex-column">
					<i v-b-tooltip.hover title="Default Billing Address" class="bx bx-money position-absolute bg-success text-white top-0 right-0 mt-2 mr-2 font-size-18 rounded-circle p-1"></i>
						<div class="avatar-sm my-auto mx-auto">
							
							<a v-b-modal.modal-edit @click="updateAddressForm(user.default_invoice_address)"><span class="avatar-title rounded-circle">
								<i v-if="showControls == 'invoice'" class="bx bx-pencil font-size-18 text-white"></i>
							<i class="bx bx-home font-size-18"  v-else></i>
							</span></a>
		                 </div>
		                 <div class="media-body position-relative mt-2 text-center mx-auto">       
		                 <h5 class="font-size-14 mb-1">
		                    {{user.default_invoice_address.alias}}
		                 </h5>
		                 <div>
							<span v-if="user.default_invoice_address.address_1">{{user.default_invoice_address.address_1}}</span>
							<span v-if="user.default_invoice_address.address_2">{{user.default_invoice_address.address_2}}</span>
							<span v-if="user.default_invoice_address.address_3">{{user.default_invoice_address.address_3}}</span>
							<span v-if="user.default_invoice_address.city">{{user.default_invoice_address.city}}</span>
							<span v-if="user.default_invoice_address.country">{{user.default_invoice_address.country.name}}</span>
							<span v-if="user.default_invoice_address.county">{{user.default_invoice_address.county.name}}</span>
							<span v-if="user.default_invoice_address.postcode">{{user.default_delivery_address.postcode}}</span>
						</div>
						

					</div>
				</div>
			</b-card>
				</div>
				<div v-else class="border border-dashed border-radius-1em p-5 mt-2 text-center position-relative" @mouseenter="updateAddress = 'invoice'" @mouseleave="updateAddress = ''">
					<i class="bx bx-home text-primary font-size-18"></i>
					<p class="text-muted mb-0">No default address set</p>
					<transition name="fade-top">
					<div class="bg-white avatar-title text-dark top-0 bottom-0 left-0 right-0 mb-2 mx-auto position-absolute w-100 text-uppercase border-radius-1em" v-if="updateAddress == 'invoice'">
                	<a class="btn btn-primary btn-rounded w-75" v-b-modal.modal-edit @click="address = JSON.parse(JSON.stringify(address_data)), form = 'address', action = 'create', address.invoice_default = 1" >Add New Address</a>
                	</div>
                </transition>

				</div>
				
			</b-col>
			
			<b-col md="12" class="d-flex mt-3" v-if="(cardExpanded !== 'addresses')">
				
				<router-link class="text-primary ml-auto" to="/account/addresses" @click="cardExpanded = 'addresses'">View All</router-link>
			</b-col>
		</b-row>
	</b-card>

	<b-card class="border-radius-1em" v-if="(cardExpanded == 'addresses')">
		<b-overlay :show="loadingAddressesForm">
				<CustomerAddresses v-if="loadingAddressesForm == false" :user="user" @create-address="address = JSON.parse(JSON.stringify(address_data)), form = 'address', action = 'create'" @update-address="updateAddressForm"></CustomerAddresses>
		</b-overlay>
	</b-card>

	

	<b-card class="border-radius-1em" v-if="(cardExpanded == '') || (cardExpanded == 'orders')">
		<h5 class="mb-4">Latest Orders</h5>
		<b-row>
		<b-col>
			<div v-if="orders">
				<OrderList :product_list="orders" :expanded="isExpanded" :view="'list'" ></OrderList>
			</div>
			<div v-else class="border border-dashed border-radius-1em p-5 mt-2 text-center position-relative" >
					<i class="bx bxs-cube-alt text-primary font-size-18"></i>
					<p class="text-muted mb-0">No orders to show</p>
					

				</div>
				<b-pagination
				v-if="cardExpanded == 'orders'"
              class="justify-content-end"
              pills
              v-model="page"
              :total-rows="orders.total"
              :per-page="orders.per_page"
              aria-controls="my-table"
              @input="loadUserOrders(15, page)"
            ></b-pagination>
		</b-col>
		<b-col md="12" class="d-flex mt-3">
				<router-link class="text-primary ml-auto"  to="/account/orders" @click="orders_limt = 15, cardExpanded = 'orders'">View All</router-link>
			</b-col>
		</b-row>
	</b-card>	

	<b-card class="border-radius-1em" v-if="(cardExpanded == '') || (cardExpanded == 'messages')">
		<h5 class="mb-4 d-inline">Latest messages</h5>
		<a v-b-modal.modal-messages class="btn btn-primary float-right btn-rounded text-white" href="javascript:;">
			Send New Message
		</a>
		<b-row class="mt-4">
		<b-col>
			<div v-if="message_threads">
				<WebsiteCustomerMessages :thread_list="message_threads.data" :expanded="isExpanded" :view="'list'" @mark-read="markAsRead" :user="user" ></WebsiteCustomerMessages>
			</div>
			<div v-else class="border border-dashed border-radius-1em p-5 mt-2 text-center position-relative" >
					<i class="bx bxs-cube-alt text-primary font-size-18"></i>
					<p class="text-muted mb-0">No messages to show</p>
					

				</div>

				 <b-pagination
				 v-if="cardExpanded == 'messages'"
              class="justify-content-end"
              pills
              v-model="page"
              :total-rows="message_threads.total"
              :per-page="message_threads.per_page"
              aria-controls="my-table"
              @input="loadUserMessages(15, page)"
            ></b-pagination>
		</b-col>
		<b-col md="12" class="d-flex mt-3">
				<router-link class="text-primary ml-auto"  to="/account/messages" @click="messages_limt = 15, cardExpanded = 'messages', removeUid()">View All</router-link>
			</b-col>
		</b-row>
	</b-card>	

	<b-card class="border-radius-1em" v-if="(cardExpanded == '') || (cardExpanded == 'wishlist')">
		<h5 class="mb-4">Product Wishlist</h5>
		<b-row>
		<b-col class="overflow-hidden" v-if="user.wishlist">
			<Swiper v-if="(cardExpanded == '') || (cardExpanded !== 'wishlist')" :items="wishlist" :swiperOptions="swiperOptions" :startSlide="1"></Swiper>
			<PublicMarketplaceProductList v-if="(cardExpanded !== '') || (cardExpanded == 'wishlist')" :product_list="wishlist" :expanded="0" :layouttoggle="true" :view="'grid'" :marketplace="'public'" ></PublicMarketplaceProductList>
		</b-col>
		<b-col v-else>
			<div class="border border-dashed border-radius-1em p-5 mt-2 text-center position-relative" >
					<i class="bx bxs-cube-alt text-primary font-size-18"></i>
					<p class="text-muted mb-0">No products in your wishlist</p>
					

				</div>
		</b-col>
		<b-col md="12" class="d-flex">
				<router-link class="text-primary ml-auto"  to="/account/wishlist" @click="cardExpanded = 'wishlist'">View All</router-link>
			</b-col>
		</b-row>
	</b-card>

	<b-card class="shadow-none " v-if="(cardExpanded !== '')">
		<b-row>
			<b-col md="12">
				<a class="text-primary mr-auto" href="javascript:;" @click="goBack(), cardExpanded = ''"><i class="bx bx-chevron-left"></i> Back</a>
			</b-col>
		</b-row>
	</b-card>

	<b-modal ref="modal-edit" id="modal-edit" centered content-class="border-radius-1em" header-class="border-none" hide-footer no-close-on-backdrop>
	<div  v-if="form == 'address'" class="p-3">	
		<CustomerAddressForm :address="address" :action="action" :user="user"  @address-added="addressAddedUpdated" @address-updated="addressAddedUpdated"></CustomerAddressForm>
    </div>
	</b-modal>

	 <b-modal no-close-on-backdrop ref="modal-messages" id="modal-messages" centered content-class="border-radius-1em" header-class="border-none" size="lg" hide-footer>
    	<CustomerSupportForm :user="user" @load-orders="loadUserOrders(0,1)" :orders="orders.data"></CustomerSupportForm>
    </b-modal>


	</div>
</template>