<script>
	export default{
		props:{
			expandable: Number,
			editPanel: Boolean,
			transitionName: {
				type: String,
				default: 'slide-fadepanel'
			}
		},
		methods:{
			closePanel(){
				document.body.classList.remove('overflow-hidden');
				this.$emit('close-panel');
				//document.getElementById("html").className = "scroll";
				this.expanded = 0;
				if(this.$attrs.id == 'related-action'){
					//document.getElementById("html").className = "scroll";
				}
			},
			expandPanel(){
				this.$emit('expand');
				this.expanded = 1;

			},
			contractPanel(){
				this.$emit('contract');
				this.expanded = 0;
			},
		},
		data(){
			return{
				name: 'side-panel',
				expanded: 0,
				expandable: 0,
			}
		},
		watch:{
				editPanel: function() {
				 if(this.editPanel == ("1" || 1 || true)){
					if((this.$attrs.id !== 'filter-project-panel')){
					document.body.classList.add( 'overflow-hidden');
					}
				}else{
					document.body.classList.remove('overflow-hidden');
				}
			}
		},
		mounted(){
			
			//document.getElementsById('#app').addClass('newClass');
			//document.getElementById("html").className = "no-scroll";
		},
		created(){
			//console.log('panel loaded');
		}
	}
</script>
<template>
	 <transition :name="transitionName">
	      <div :class="$attrs.panelClass" v-if="editPanel == 1" >
	      	<b-container fluid>
	      		
	      		<b-row>
	      			<b-col md="12">
	      				<div v-if="expandable == 1">
					      	<a href="javascript:;" @click="expandPanel" v-if="expanded == 0" class="btn btn-rounded btn-outline-light float-left m-2"><i class="mdi mdi-arrow-left noti-icon font-size-20"></i></a>
					      	<a href="javascript:;" @click="contractPanel" v-else class="btn btn-rounded btn-outline-light float-left m-2"><i class="mdi mdi-arrow-right noti-icon font-size-20"></i></a>
	      				</div>
	        				<a href="javascript:;" @click="closePanel" class="btn btn-rounded btn-outline-light float-right m-2"><i class="mdi mdi-close noti-icon font-size-20"></i></a>
	        		</b-col>
	        		<b-col md="12">
	        			<div v-if="$attrs.formResponse != null">
	        				{{$attrs.formResponse}}
	        			</div>
	        		</b-col>
	        	</b-row>
	        </b-container>
	        <div class="container-fluid">
	       		<slot />
	      	</div>
	      </div>
      </transition>
</template>