
<script>
import { EventBus } from '../app'
import i18n from "../i18n";
import simplebar from "simplebar-vue";
import ModalWindow from "../components/widgets/ModalWindow";
//import TradeCartSummary from "../components/widgets/ecommerce/TradeCartSummary";

/**
 * Nav-bar Component
 */
export default {
  data() {
    return {
      languages: [
        {
          flag: "/images/flags/uk.jpg",
          language: "en",
          title: "English",
        },
        /*{
          flag: "/images/flags/french.jpg",
          language: "fr",
          title: "French",
        },
        {
          flag: "/images/flags/spain.jpg",
          language: "es",
          title: "Spanish",
        },
        {
          flag: "/images/flags/chaina.png",
          language: "zh",
          title: "Chinese",
        },
        {
          flag: "/images/flags/arabic.png",
          language: "ar",
          title: "Arabic",
        },*/
      ],
      cart: {
        products: [],
        uid: '',
        id: '',
      },
      cartLoading: false,
      initiateCheckout: false,
      cartPanelData: [],
      cartPanel: false,
      showCart: false, 
      lan: i18n.locale,
      text: null,
      flag: null,
      value: null,
    };
  },
  props: {
    user: {
      type: Object,
      default: () => {api_token: ''}
    },
    company: {
      type: Object,
      default: () => {}
    }
  },
  components: { simplebar, ModalWindow },
  created() {
    var session_cart = window.sessionStorage.getItem("tradecart");
    session_cart = JSON.parse(session_cart);
    if(session_cart == null){
      this.checkAndGetCart();
    }else{
      this.cart = session_cart;
    }
  },
  mounted() {
    this.value = this.languages.find((x) => x.language === i18n.locale);
    this.text = this.value.title;
    this.flag = this.value.flag;
    //window.sessionStorage.setItem("test", JSON.stringify(this.cart));
    
    let self = this;
    EventBus.$on('add-to-cart', function(product, qty, variants, combination){
      console.log('Cart Event Triggered');
       self.addToCart(product, qty, variants, combination);
    });
     EventBus.$on('cart-cleared', function(result){
      self.cart = { products: [], uid: '', id: ''};
    });
     EventBus.$on('delete-from-cart', function(product, qty, variants, combination){
      console.log('Cart Event Triggered');
       self.cart.filter(item => item.id = product.id);
       self.removeProduct();
    });
  },
  computed: {
    /*cartTotal: function(){
          var score = 0;
          if(this.cart.products.length > 1){
            for(var i = 0; i < this.cart.products.length; i++){
              var itemscore = (parseFloat(this.cart.products[i].price_exc) * parseFloat(this.cart.products[i].quantity));
              score = score + itemscore;
            }
          }else{
            score = this.cart.products[0].price_exc * this.cart.products[0].quantity;
          }
          return (score).toFixed(2);
        },*/
      cartCombinationTotals(){
          var combitotal = 0.00;
          var combitotalexc = 0.00;
          for(var i = 0; i < this.cart.products.length; i++){
            for(var ci = 0; ci < this.cart.products[i].combinations.length; ci++){
              combitotalexc += parseFloat(this.cart.products[i].combinations[ci].price_difference) * parseFloat(this.cart.products[i].combinations[ci].quantity); 
                combitotal += parseFloat(parseFloat(this.addTax(this.cart.products[i].product, this.cart.products[i].combinations[ci].price_difference)) * parseFloat(this.cart.products[i].combinations[ci].quantity) );           
            
            }
          }
          return [combitotalexc.toFixed(2), combitotal.toFixed(2)];
        },
        cartVariationsTotals(){
          var variantstotal = 0.00;
          var variantstotalexc = 0.00;
          for(var i = 0; i < this.cart.products.length; i++){
            if(this.cart.products[i].variants !== null){
              for(var vi = 0; vi < this.cart.products[i].variants.length; vi++){
              variantstotalexc += parseFloat(this.cart.products[i].variants[vi].price_difference);
              variantstotal += parseFloat(this.addTax(this.cart.products[i].product, this.cart.products[i].variants[vi].price_difference));
              }
            }
          }
          return [variantstotalexc.toFixed(2), variantstotal.toFixed(2)];
        },
    },
  methods: {
    checkAndGetCart(){
      axios.get('/api/company/trade/cart/read?cart_uid='+this.cart.uid, {headers: {
                  'Authorization': 'Bearer ' + this.user.api_token
              }}).then(response => {
        console.log(response);
          if(response.data !== 0){
            this.cart = response.data;
            window.sessionStorage.setItem("tradecart", JSON.stringify(response.data));
          }else{
            window.sessionStorage.setItem("tradecart", JSON.stringify(this.cart))
          }
        
      }).catch(error => {
        this.error_reponse = error.response.data.errors;
      });
    },
    cartTotal(cart){
          var score = 0;
          if(cart.products.length > 1){
            for(var i = 0; i < cart.products.length; i++){
              var itemscore = (parseFloat(cart.products[i].price_exc) * parseFloat(cart.products[i].quantity));
              score = score + itemscore;
            }
          }else{
            score = cart.products[0].price_exc * cart.products[0].quantity;
          }
          return (score).toFixed(2);
        },
    /*addToCart(productdata, qty){
      this.cartLoading = true;
      if(this.cart.uid == ''){
        this.cart.products.push({product: productdata});
        this.createCart(productdata);
      }else{
        axios.post('/api/company/trade/cart/'+this.cart.uid+'/add-product?api_token='+this.user.api_token, {product: productdata, quantity: qty}).then(response => {
        var carindex = this.cart.products.findIndex(item => item.product_id == productdata.id);
            if(carindex !== -1){
              this.cart.products[carindex] = response.data;
              this.cart.products[carindex]["product"] = productdata;
              window.sessionStorage.setItem("tradecart", JSON.stringify(this.cart));
              this.productAdded(productdata);
            }else if(carindex == -1){
              this.cart["products"].push(response.data);
              this.cart["products"][(this.cart.products.length - 1)]["product"] = productdata;
              window.sessionStorage.setItem("tradecart", JSON.stringify(this.cart));
              this.productAdded(productdata);
            }
      }).catch(error => {
        this.error_reponse = error.response.data.errors;
      });
      } 
    },*/
    addToCart(productdata, qty, variantsdata, combinationdata){
      this.cartLoading = true;
      if(this.cart.uid == ''){
        this.cart.products.push({product: productdata});
        this.createCart(productdata, qty, variantsdata, combinationdata);
      }else{
        axios.post('/api/company/trade/cart/'+this.cart.uid+'/add-product', {product: productdata, quantity: qty, variants: variantsdata, combination: combinationdata}, {headers: {
                  'Authorization': 'Bearer ' + this.user.api_token
              }}).then(response => {
        var carindex = this.cart.products.findIndex(item => item.product_id == productdata.id);
            if(carindex !== -1){
              this.cart.products[carindex] = response.data;
              this.cart.products[carindex]["product"] = productdata;
              window.sessionStorage.setItem("tradecart", JSON.stringify(this.cart));
              this.productAdded(productdata);
            }else if(carindex == -1){
              this.cart["products"].push(response.data);
              this.cart["products"][(this.cart.products.length - 1)]["product"] = productdata;
              window.sessionStorage.setItem("tradecart", JSON.stringify(this.cart));
              this.productAdded(productdata);
            }
      }).catch(error => {
        this.error_reponse = error.response.data.errors;
      });
      } 
    },
    removeProduct(id, index){
      axios.post('/api/company/trade/cart/product/'+id+'/delete', this.cart, {headers: {
                  'Authorization': 'Bearer ' + this.user.api_token
              }}).then(response => {
          this.cart.products.splice(index, 1);
          window.sessionStorage.setItem("tradecart", JSON.stringify(this.cart));
        }).catch(error => {
          this.error_reponse = error.response.data.errors;
        });
    },
    productAdded(product){
      //this.cartPanelData = product;
      //this.cartPanel = true;
      this.cartLoading = false;
      this.$refs.cart.show(true);
    },
    /*createCart(product){
      axios.post('/api/company/trade/cart/create?api_token='+this.user.api_token, product).then(response => {
        this.cart = response.data[0];
        this.cart["products"] = [];
        this.cart.products.push(response.data[1]);
        this.cart.products[0]["product"] = product;
        window.sessionStorage.setItem("tradecart", JSON.stringify(this.cart));
      }).catch(error => {

      });
    },*/
    createCart(productdata, qty, variantsdata, combinationdata){
      axios.post('/api/company/trade/cart/create', {product: productdata, quantity: qty, variants: variantsdata, combination: combinationdata}, {headers: {
                  'Authorization': 'Bearer ' + this.user.api_token
              }}).then(response => {
        this.cart = response.data[0];
        this.cart["products"] = [];
        this.cart.products.push(response.data[1]);
        this.cart.products[0]["product"] = productdata;
        window.sessionStorage.setItem("tradecart", JSON.stringify(this.cart));
        this.productAdded(product);
      }).catch(error => {

      });
    },
    toggleMenu() {
      this.$parent.toggleMenu();
    },
    toggleRightSidebar() {
      this.$parent.toggleRightSidebar();
    },
    initFullScreen() {
      document.body.classList.toggle("fullscreen-enable");
      if (
        !document.fullscreenElement &&
        /* alternative standard method */ !document.mozFullScreenElement &&
        !document.webkitFullscreenElement
      ) {
        // current working methods
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen(
            Element.ALLOW_KEYBOARD_INPUT
          );
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        }
      }
    },
    setLanguage(locale, country, flag) {
      this.lan = locale;
      this.text = country;
      this.flag = flag;
      i18n.locale = locale;
      localStorage.setItem("locale", locale);
    },
  },
};
</script>

<template>
  <header id="page-topbar">
    <div class="navbar-header">
      <div class="d-flex">
        <!-- LOGO -->
        <div class="navbar-brand-box">
          <a href="/" class="logo logo-dark">
            <span class="logo-sm">
              <div class="cube-wrapper float-left"><div class="cube-folding"><span class="leaf1"></span><span class="leaf2"></span><span class="leaf3"></span><span class="leaf4"></span></div></div>
            </span>
            <span class="logo-lg">
              <div class="cube-wrapper float-left"><div class="cube-folding"><span class="leaf1"></span><span class="leaf2"></span><span class="leaf3"></span><span class="leaf4"></span></div></div>
              <div class="d-flex logo-text-holder"><span class="logo-text text-dark">Gemesys</span></div>
            </span>
          </a>

          <a href="/" class="logo logo-light">
            <span class="logo-sm">
              <div class="cube-wrapper float-left"><div class="cube-folding"><span class="leaf1"></span><span class="leaf2"></span><span class="leaf3"></span><span class="leaf4"></span></div></div>
            </span>
            <span class="logo-lg">
              <div class="cube-wrapper float-left"><div class="cube-folding"><span class="leaf1"></span><span class="leaf2"></span><span class="leaf3"></span><span class="leaf4"></span></div></div>
              <div class="d-flex logo-text-holder"><span class="logo-text text-white">Gemesys</span></div>
            </span>
          </a>
        </div>

        <button
          id="vertical-menu-btn"
          type="button"
          class="btn btn-sm px-3 font-size-16 header-item"
          @click="toggleMenu"
        >
          <i class="fa fa-fw fa-bars"></i>
        </button>

        <!-- App Search-->
        <form class="app-search d-none d-lg-block">
          <div class="position-relative">
            <input type="text" class="form-control" :placeholder="$t('navbar.search.text')" />
            <span class="bx bx-search-alt"></span>
          </div>
        </form>

      
      </div>

      <div class="d-flex">
        <b-dropdown
          class="d-inline-block d-lg-none ms-2"
          variant="black"
          menu-class="dropdown-menu-lg p-0"
          toggle-class="header-item noti-icon"
          right
        >
          <template v-slot:button-content>
            <i class="mdi mdi-magnify"></i>
          </template>

          <form class="p-3">
            <div class="form-group m-0">
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Search ..."
                  aria-label="Recipient's username"
                />
                <div class="input-group-append">
                  <button class="btn btn-primary" type="submit">
                    <i class="mdi mdi-magnify"></i>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </b-dropdown>

        <b-dropdown variant="white" right toggle-class="header-item">
          <template v-slot:button-content>
            <img class :src="flag" alt="Header Language" height="16" />
            {{text}}
          </template>
          <b-dropdown-item
            class="notify-item"
            v-for="(entry, i) in languages"
            :key="`Lang${i}`"
            :value="entry"
            @click="setLanguage(entry.language, entry.title, entry.flag)"
            :class=" {'active' : lan === entry.language}"
          >
            <img :src="`${entry.flag}`" alt="user-image" class="me-1" height="12" />
            <span class="align-middle">{{ entry.title }}</span>
          </b-dropdown-item>
        </b-dropdown>

        <b-dropdown
          class="d-none d-lg-inline-block noti-icon"
          menu-class="dropdown-menu-lg calculators-menu dropdown-menu-end apps-menu"
          right
          toggle-class="header-item"
          variant="black"
        >
          <template v-slot:button-content>
            <i class="bx bx-calculator"></i>
          </template>

          <div class="px-lg-2">
            <div class="row no-gutters">
              <div class="col">
                <a class="dropdown-icon-item calc-icon" href="javascript: void(0);" @click="$emit('showCalculatorWindow', 0)">
               
                  <img src="/images/diamonds/ver1Pear.png" class="avatar-xl">
                   <span>Gemstone</span>
                </a>
              </div>
              <div class="col">
                <a class="dropdown-icon-item calc-icon" href="javascript: void(0);"  @click="$emit('showCalculatorWindow', 1)">
                  <img src="/images/diamonds/ver1Round.png" class="avatar-xl">
                  <span>Diamond</span>
                </a>
              </div>
              <div class="col">
                <a class="dropdown-icon-item calc-icon" href="javascript: void(0);"  @click="$emit('showCalculatorWindow', 2)">
                  <img src="/images/diamonds/ver1Oval.png" class="avatar-xl">
                  <span>Cabochon</span>
                </a>
              </div>
            </div>

            <div class="row no-gutters">
              <div class="col">
                <a class="dropdown-icon-item calc-icon" href="javascript: void(0);"  @click="$emit('showCalculatorWindow', 3)">
                  <img src="/images/diamonds/ver1Cushion.png" class="avatar-xl">
                  <span>Specific Gravity</span>
                </a>
              </div>
              <div class="col">
                <a class="dropdown-icon-item calc-icon" href="javascript: void(0);"  @click="$emit('showCalculatorWindow', 4)">
                 <img src="/images/diamonds/ver1Round.png" class="avatar-xl">
                  <span>Crown Angle</span>
                </a>
              </div>
              <div class="col">
                <a class="dropdown-icon-item calc-icon" href="javascript: void(0);"  @click="$emit('showCalculatorWindow', 5)">
                  <img src="/images/diamonds/ver1Cushion.png" class="avatar-xl">
                  <span>Pavillion Angle</span>
                </a>
              </div>
            </div>
          </div>
        </b-dropdown>


        <b-dropdown
          class="d-none d-lg-inline-block noti-icon"
          menu-class="dropdown-menu-lg dropdown-menu-end apps-menu"
          right
          toggle-class="header-item"
          variant="black"

        >
          <template v-slot:button-content>
            <i class="bx bx-customize"></i>
          </template>

          <div class="px-lg-2">
            <div class="row no-gutters">
              <div class="col">
                <a class="dropdown-icon-item logo-items" href="https://gemesys.co.uk">
                  <img src="/images/logo-square-flat.png" alt="Gemesys Public Marketplace" />
                  <span>Public Market</span>
                </a>
              </div>
               <div class="col">
                <a class="dropdown-icon-item logo-items" href="/trade/marketplace">
                  <img src="/images/logo-trade-flat.png" alt="dribbble" />
                  <span>Trade Market</span>
                </a>
              </div>
              <div class="col">
                <a class="dropdown-icon-item logo-items" href="/sell/pos">
                  <img src="/images/logo-pos-flat.png" alt="bitbucket" />
                  <span>ePOS</span>
                </a>
              </div>
              <div class="col">
                <a class="dropdown-icon-item logo-items" href="/web/pages">
                  <img src="/images/logo-web-flat.png" alt="dribbble" />
                  <span>Website</span>
                </a>
              </div>
             
            </div>
          </div>
        </b-dropdown>

        <div class="dropdown d-none d-lg-inline-block ms-1">
          <button type="button" class="btn header-item noti-icon" @click="initFullScreen">
            <i class="bx bx-fullscreen"></i>
          </button>
        </div>

         <div class="dropdown d-none d-lg-inline-block ms-1">
          <button type="button" class="btn header-item noti-icon" @click="$emit('showMetalPricing')">
            <i class="bx bx-line-chart"></i>
          </button>
        </div>

        <b-dropdown
          right
          menu-class="dropdown-menu-lg p-0 dropdown-menu-end cart-dropdown"
          toggle-class="header-item noti-icon"
          variant="black"
          ref="cart"
        >
          <template v-slot:button-content>
            <i class="bx bx-shopping-bag"></i>
            <span
              class="badge bg-danger rounded-pill"
            >{{cart.products.length}}</span>
          </template>

          <div class="p-3">
            <div class="row align-items-center">
              <div class="col">
                <h6 class="m-0">Shopping Cart</h6>
              </div>
              <div class="col-auto">
                <a href="#" class="small">View Cart</a>
              </div>
            </div>
          </div>
          <simplebar style="max-height: 530px;">
          <b-list-group v-if="cartLoading == false">
            <b-list-group-item  v-for="(productitem, index) in cart.products" href="javascript: void(0);" class="text-reset notification-item px-0 py-3 m-0">
              <div class="media">
                <div class="avatar-sm me-3">
                  <img v-if="productitem.product.cover_image" :src="productitem.product.cover_image" class="avatar-title rounded-circle">
                  <span v-else class="avatar-title bg-primary rounded-circle font-size-16" >
                    <i class="bx bx-cart"></i>
                  </span>
                </div>
                <div class="media-body my-auto flex-grow-10">
                  <h6 class="mt-0 mb-1">{{productitem.product.name}}</h6>
                </div>
                <div class="my-auto flex-grow-2 text-right d-flex flex-column">
                  <i class="bx bx-chevron-up mb-auto mx-auto"></i>
                  <b-form-input type="number"  v-model="productitem.quantity" class="visible mx-auto my-auto" style="width: 25%;">
                  </b-form-input>
                  <span class="mx-auto my-auto">{{productitem.quantity}}</span>
                  <i class="bx bx-chevron-down mt-auto mx-auto"></i>
                </div>
                <div class="my-auto flex-grow-2 text-center">
                  <strong class="text-primary mr-2">£</strong>{{productitem.price_exc}}
                </div>
                <div class="my-auto flex-grow-2 text-center">
                  <strong class="text-primary mr-2">£</strong>{{(productitem.price_exc * productitem.quantity).toFixed(2)}}
                </div>
                <div class="my-auto flex-grow-1 text-center">
                 <a class="btn btn-outline-light btn-rounded" @click="removeProduct(productitem.id, index)"><i class="bx bx-trash-alt"></i></a>
                </div>
              </div>
            </b-list-group-item>
            <b-list-group-item v-if="cart.products.length == 0">
              <div class="p-5 text-center border">
                <i class="bx bx-shopping-bag font-size-42 text-secondary mb-2"></i>
                <h6>No Products in Cart</h6>
              </div>
            </b-list-group-item>
          </b-list-group>
            

          </simplebar>
          <b-overlay
            :show="cartLoading">
              <div class="p-4 border-top d-flex bg-light" v-if="(cart.products.length !== 0) && (cartLoading == false)">
                <strong class="mr-auto flex-grow-2"> Total: </strong>
                  <span class="mx-auto flex-grow-1 text-center"><strong class="text-primary mr-2">£</strong> {{cartTotal(cart)}}</span>
              </div>
          </b-overlay>
         <div class="p-2 border-top d-grid" v-if="cart.products.length !== 0">
            <a class="btn btn-sm btn-link font-size-14 text-center btn-primary text-white" href="/trade/order" >
              <i class=" me-1"></i>
              <span key="t-view-more"> Checkout Now </span>
            </a>
          </div>
        </b-dropdown>

        <b-dropdown
          right
          menu-class="dropdown-menu-lg p-0 dropdown-menu-end"
          toggle-class="header-item noti-icon"
          variant="black"
        >
          <template v-slot:button-content>
            <i class="bx bx-bell bx-tada"></i>
            <span
              class="badge bg-danger rounded-pill"
            >0</span>
          </template>

          <div class="p-3">
            <div class="row align-items-center">
              <div class="col">
                <h6 class="m-0">{{ $t('navbar.dropdown.notification.text')}}</h6>
              </div>
              <div class="col-auto">
                <a href="#" class="small">{{ $t('navbar.dropdown.notification.subtext')}}</a>
              </div>
            </div>
          </div>
          <simplebar style="max-height: 230px;" class="px-3" >
            <!--<a href="javascript: void(0);" class="text-reset notification-item">
              <div class="media">
                <div class="avatar-xs me-3">
                  <span class="avatar-title bg-primary rounded-circle font-size-16">
                    <i class="bx bx-cart"></i>
                  </span>
                </div>
                <div class="media-body">
                  <h6 class="mt-0 mb-1">{{ $t('navbar.dropdown.notification.order.title')}}</h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">{{ $t('navbar.dropdown.notification.order.text')}}</p>
                    <p class="mb-0">
                      <i class="mdi mdi-clock-outline"></i>
                      {{ $t('navbar.dropdown.notification.order.time')}}
                    </p>
                  </div>
                </div>
              </div>
            </a>-->
            <NothingHere :addNew="false" ></NothingHere>
            
          </simplebar>
         <div class="p-2 border-top d-grid">
            <a class="btn btn-sm btn-link font-size-14 text-center" href="javascript:void(0)">
              <i class="mdi mdi-arrow-down-circle me-1"></i>
              <span key="t-view-more"> {{ $t('navbar.dropdown.notification.button')}} </span>
            </a>
          </div>
        </b-dropdown>

        <b-dropdown right variant="black" toggle-class="header-item" menu-class="dropdown-menu-end">
           <template v-slot:button-content>
            <img v-if="user.avatar !== null"
              class="rounded-circle header-profile-user"
              :src="user.avatar"
              alt="Header Avatar"
            />
              <span v-else class="border-radius-1em header-profile-user p-3 px-4 mr-2">{{user.name.slice(0, 1)}}</span>
            <span class="d-none d-xl-inline-block ml-1">{{ user.name }} {{ user.lastname }}</span>
            <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
          </template>
          <!-- item-->
      
            <b-dropdown-item href="/account/profile">
              <i class="bx bx-user font-size-16 align-middle me-1"></i>
              {{ $t('navbar.dropdown.henry.list.profile') }}
            </b-dropdown-item>
        
          <b-dropdown-item href="javascript: void(0);">
            <i class="bx bx-wallet font-size-16 align-middle me-1"></i>
            {{ $t('navbar.dropdown.henry.list.mywallet') }}
          </b-dropdown-item>
          <b-dropdown-item class="d-block" href="javascript: void(0);">
            <span class="badge bg-success float-end">11</span>
            <i class="bx bx-wrench font-size-16 align-middle me-1"></i>
            {{ $t('navbar.dropdown.henry.list.settings') }}
          </b-dropdown-item>
          <b-dropdown-item href="javascript: void(0);">
            <i class="bx bx-lock-open font-size-16 align-middle me-1"></i>
            {{ $t('navbar.dropdown.henry.list.lockscreen') }}
          </b-dropdown-item>
          <b-dropdown-divider></b-dropdown-divider>
          <a href="/logout" class="dropdown-item text-danger">
            <i class="bx bx-power-off font-size-16 align-middle me-1 text-danger"></i>
            {{ $t('navbar.dropdown.henry.list.logout') }}
          </a>
        </b-dropdown>

        <div class="dropdown d-inline-block">
          <button
            type="button"
            class="btn header-item noti-icon right-bar-toggle toggle-right"
            @click="toggleRightSidebar"
          >
            <i class="bx bx-cog bx-spin toggle-right"></i>
          </button>
        </div>
      </div>
      <ModalWindow :editModal="cartPanel" :panelClass="'product-modal d-flex flex-column justify-content-center'" @close-modal="cartPanel = !cartPanel" :formReponse="''">
        <b-row>
          <b-col>
            <img v-if="cartPanelData.cover_image" :src="cartPanelData.cover_image" class="avatar-lg rounded-circle">
            <h2>cartPanelData.name</h2> 
          </b-col>
        </b-row>
      </ModalWindow>
    </div>
    
  </header>
</template>
