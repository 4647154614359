<script type="text/javascript">
	import EventBus from '../../public-app'
	import DateTime from 'luxon/src/datetime.js';
  	import Interval from 'luxon/src/interval.js';
  	import Swiper from "../../components/widgets/carousel/Swiper";
  	import SellerSwiper from "../../components/widgets/carousel/SellerSwiper";
  	import PublicMarketplaceSellerList from "../../components/widgets/PublicMarketplaceSellerList";
  	import PublicMarketplaceProductList from "../../components/widgets/PublicMarketplaceProductList";
  	import CustomerSupportForm from "../../components/widgets/forms/public/CustomerSupportForm";
  	import CustomerAddressForm from "../../components/widgets/forms/public/CustomerAddressForm";
  	import CustomerAddresses from "../../components/widgets/lists/public/CustomerAddresses";
  	import OrderList from '../../components/widgets/ecommerce/public/OrderList';
  	import PublicCustomerMessages from '../../components/widgets/lists/public/PublicCustomerMessages';
  	import PublicCustomerTwilioMessages from '../../components/widgets/lists/public/PublicCustomerTwilioMessages';
	export default{
		components: { Swiper, SellerSwiper, CustomerAddressForm, CustomerAddresses, PublicMarketplaceSellerList, PublicMarketplaceProductList, OrderList, PublicCustomerMessages, PublicCustomerTwilioMessages, CustomerSupportForm },
		data(){
			return{
				messagesLoaded: false,
				message_threads: [],
				updateAddress: true,
				showControls: -1,
				orders_page: 1,
				orders_limit: 5,
				favourites_page: 1,
				favourites_limit: 15,
				orders: '',
				form: '',
				action: '',
				address: {
					id: '',
					customer_id: '',
					address_1: '',
					address_2: '',
					address_3: '',
					city: '',
					county: {
						id: '',
					},
					country: {
						id: '',
					},
					postcode: '',
					alias: '',
					delivery_default: 0,
					invoice_default: 0,
					active: 1,

				},
				address_data: {
					id: '',
					customer_id: '',
					address_1: '',
					address_2: '',
					address_3: '',
					city: '',
					county: {
						id: '',
					},
					country: {
						id: '',
					},
					postcode: '',
					alias: '',
					delivery_default: 0,
					invoice_default: 0,
					active: 1,

				},
				swiperOptions: {
		          slidesPerView: 4,
		          spaceBetween: 5,
		          loop: false,
		          loopFillGroupWithBlank: false,
		          pagination: {
		            el: '.swiper-pagination',
		            clickable: true,
		          },
		          // Some Swiper option/callback...
		        },
		        favourites: [],
		        wishlist: [],
		        items2: [
			        {
			          cover_image: '/images/product-placeholder.png',
			          name: 'Moira Fine Jewellery',
			          logo: '/images/product-placeholder.png',
			          link: '/category/jewellery',
			          price: '2000.00',
			        },
			        {
			          cover_image: '/images/product-placeholder.png',
			          logo: '/images/product-placeholder.png',
			          name: 'Robert Bicknell Fine Jewellery',
			          link: '/category/jewellery',
			          price: '2000.00',
			        },
			        {
			          cover_image: '/images/product-placeholder.png',
			          name: 'Edwardian Sapphire And Diamond Bracelet, Circa 1910',
			          logo: '/images/product-placeholder.png',
			          link: '/category/jewellery',
			          price: '2000.00',
			        },
			        {
			          cover_image: '/images/product-placeholder.png',
			          name: 'David Webb Rock Crystal Diamond Gold Earrings',
			          logo: '/images/product-placeholder.png',
			          link: '/category/jewellery',
			          price: '2000.00',
			        },
			        {
			          cover_image: '/images/product-placeholder.png',
			          name: 'Earrings',
			          logo: '/images/product-placeholder.png',
			          link: '/category/jewellery',
			          price: '2000.00',
			        },
			        {
			          cover_image: '/images/product-placeholder.png',
			          name: 'Earrings',
			          logo: '/images/product-placeholder.png',
			          link: '/category/jewellery',
			          price: '2000.00',
			        },
			        {
			          cover_image: '/images/product-placeholder.png',
			          name: 'Earrings',
			          logo: '/images/product-placeholder.png',
			          link: '/category/jewellery',
			          price: '2000.00',
			        },
			        {
			          cover_image: '/images/product-placeholder.png',
			          name: 'Earrings',
			          logo: '/images/product-placeholder.png',
			          link: '/category/jewellery',
			          price: '2000.00',
			        },
			        {
			          cover_image: '/images/product-placeholder.png',
			          name: 'Earrings',
			          logo: '/images/product-placeholder.png',
			          link: '/category/jewellery',
			          price: '2000.00',
			        },
			        {
			          cover_image: '/images/product-placeholder.png',
			          name: 'Earrings',
			          logo: '/images/product-placeholder.png',
			          link: '/category/jewellery',
			          price: '2000.00',
			        },
			        {
			          cover_image: '/images/product-placeholder.png',
			          name: 'Earrings',
			          logo: '/images/product-placeholder.png',
			          link: '/category/jewellery',
			          price: '2000.00',
			        },
			        {
			          cover_image: '/images/product-placeholder.png',
			          name: 'Earrings',
			          logo: '/images/product-placeholder.png',
			          link: '/category/jewellery',
			          price: '2000.00',
			        },
			      ],
			      items: [
			        {
			          cover_image: '/images/product-placeholder.png',
			          name: 'Modern Aquamarine Diamond And White Gold Chandelier Drop Earrings',
			          link: '/category/jewellery',
			          price: '2000.00',
			        },
			        {
			          cover_image: '/images/product-placeholder.png',
			          name: 'Vintage 18ct Gold Necklace And Bracelet Suite, Circa 1980',
			          link: '/category/jewellery',
			          price: '2000.00',
			        },
			        {
			          cover_image: '/images/product-placeholder.png',
			          name: 'Edwardian Sapphire And Diamond Bracelet, Circa 1910',
			          link: '/category/jewellery',
			          price: '2000.00',
			        },
			        {
			          cover_image: '/images/product-placeholder.png',
			          name: 'David Webb Rock Crystal Diamond Gold Earrings',
			          link: '/category/jewellery',
			          price: '2000.00',
			        },
			        {
			          cover_image: '/images/product-placeholder.png',
			          name: 'Earrings',
			          link: '/category/jewellery',
			          price: '2000.00',
			        },
			        {
			          cover_image: '/images/product-placeholder.png',
			          name: 'Earrings',
			          link: '/category/jewellery',
			          price: '2000.00',
			        },
			        {
			          cover_image: '/images/product-placeholder.png',
			          name: 'Earrings',
			          link: '/category/jewellery',
			          price: '2000.00',
			        },
			        {
			          cover_image: '/images/product-placeholder.png',
			          name: 'Earrings',
			          link: '/category/jewellery',
			          price: '2000.00',
			        },
			        {
			          cover_image: '/images/product-placeholder.png',
			          name: 'Earrings',
			          link: '/category/jewellery',
			          price: '2000.00',
			        },
			        {
			          cover_image: '/images/product-placeholder.png',
			          name: 'Earrings',
			          link: '/category/jewellery',
			          price: '2000.00',
			        },
			        {
			          cover_image: '/images/product-placeholder.png',
			          name: 'Earrings',
			          link: '/category/jewellery',
			          price: '2000.00',
			        },
			        {
			          cover_image: '/images/product-placeholder.png',
			          name: 'Earrings',
			          link: '/category/jewellery',
			          price: '2000.00',
			        },
			      ]
			}
		},
		props: {
			user: {
				type: Object
			},
			cardExpanded: {
				type: String,
				default: () => ''
			}
		},
		methods: {
			goBack(){
					this.$router.push({ name: 'CustomerAccount', path: '/my-account' });
			},
			markAsRead(event){

			},
			loadUserOrders(limit,page){
				axios.get('/customer-api/user/orders?page='+page+'&limit='+limit, {headers: {
					'Authorization': 'Bearer ' + this.user.api_token
				}
				}).then(response => {
					this.orders = response.data;
				}).catch(error => {

				});
			},
			dateParser(date){
				return DateTime.fromISO(date).toLocaleString(DateTime.DATE_FULL);
			},
			loadUserMessages(limit,page){
				this.messagesLoaded = false;
				axios.get('/customer-api/user/messages?page='+page+'&limit='+limit, {headers: {
					'Authorization': 'Bearer ' + this.user.api_token
				}
				}).then(response => {
					this.message_threads = response.data;
					this.messagesLoaded = true;
				}).catch(error => {

				});
			},
			updateAddressForm(payload){
				this.address = payload;
				console.log(payload);
				this.form = 'address';
				this.action = 'update';
			},
			getFavourties(limit){
				axios.get('/customer-api/user/favourites?limit='+limit , {headers: {
			            'Authorization': 'Bearer ' + this.user.api_token
			        } }).then(response => {
					this.favourites = response.data;
				}).catch(error => {
					alert("Error loading favourites");
				});
			},
			getWishlist(limit){
				axios.get('/customer-api/user/wishlist?limit='+limit , {headers: {
			            'Authorization': 'Bearer ' + this.user.api_token
			        } }).then(response => {
					this.wishlist = response.data;
				}).catch(error => {
					alert("Error loading favourites");
				});
			},
			removeHash () { 
			    var scrollV, scrollH, loc = window.location;
			    if ("pushState" in history){
			        history.pushState("", document.title, loc.pathname + loc.search);
				}else {
			        // Prevent scrolling by storing the page's current scroll offset
			        scrollV = document.body.scrollTop;
			        scrollH = document.body.scrollLeft;

			        loc.hash = "";

			        // Restore the scroll offset, should be flicker free
			        document.body.scrollTop = scrollV;
			        document.body.scrollLeft = scrollH;
			    }
			    
			}
		},
		mounted(){
				this.cardExpanded = this.$route.params.section;
				if(this.cardExpanded == 'favourites'){
        		 	this.getFavourties(0);
        		 }
        		 if(this.cardExpanded == 'wishlist'){
        		 	this.getWishlist(0);
        		 }
        		 if(this.cardExpanded == 'orders'){
        		 	this.orders_limit = 5;
        		 	this.loadUserOrders(this.orders_limit, this.orders_page);
        		 }
        		 if((this.cardExpanded == 'messages')){
        		 	this.loadUserMessages(this.orders_limit, this.orders_page);
       
        		 }
        		 if(!this.cardExpanded){
        		 	this.getFavourties(12);
					this.getWishlist(12);
					this.loadUserOrders(5,1);
					this.loadUserMessages(this.orders_limit,1);
        		 }
				
		},
		watch: {
        $route() {
        	this.$nextTick(() => {
        		 this.cardExpanded = this.$route.params.section;
        		 if(this.cardExpanded == 'favourites'){
        		 	this.getFavourties(0);
        		 }
        		 if(this.cardExpanded == 'wishlist'){
        		 	this.getWishlist(0);
        		 }
        		 if(this.cardExpanded == 'orders'){
        		 	this.orders_limit = 5;
        		 	this.loadUserOrders(this.orders_limit, this.orders_page);
        		 }
        		 if((this.cardExpanded == 'messages') && (!Object.keys(this.$route.query).includes('uid')) && (this.message_threads.length == 0)){
        		 	this.loadUserMessages(this.orders_limit, this.orders_page);
       
        		 }
        		 if(!this.cardExpanded){
        		 	this.getFavourties(12);
					this.getWishlist(12);
					this.loadUserOrders(5,1);
        		 }
        	 });
            //this.$nextTick();
           // let self = this;
			
         }  
    	},
	}
</script>

<template>
	<div>
	<b-card class="border-radius-1em mt-0" body-class="d-flex flex-column" v-if="(cardExpanded == '') || (cardExpanded == 'profile')">
		<h3 class="mb-4 section-info-header position-relative mr-auto">Basic Details</h3>
		<b-row>
			
			<b-col>
				<label>Name</label>
				<p>{{user.name}} {{user.lastname}}</p>

				<label>Email</label>
				<p>{{user.email}} <i class="bx bx-check-circle bg-success text-white font-size-18 rounded-circle" v-if="user.email_verified_at !== null" v-b-tooltip.hover title="Email Address Verified" ></i></p>

				<label>Password</label>
				<p>*********</p>
			</b-col>

			<b-col>
				<label>Phone</label>
				<p>{{user.phone}}</p>

				<label>Mobile Phone</label>
				<p>{{user.mobile_phone}} <i class="bx bx-check-circle bg-success text-white font-size-18 rounded-circle" v-if="user.email_verified_at !== null" v-b-tooltip.hover title="Email Address Verified" ></i></p>

				<label>Joined</label>
				<p>{{ dateParser(user.created_at) }}</p>
			</b-col>
			<b-col md="12" class="d-flex">
				<a class="text-primary ml-auto" href="javascript:;" @click="">Edit</a>
			</b-col>
		</b-row>
	</b-card>

	<b-card class="border-radius-1em" body-class="d-flex flex-column" v-if="(cardExpanded == '') || (cardExpanded == 'addresses')">
		<h3 class="mb-4 section-info-header position-relative mr-auto">Addresses</h3>
		<b-row>
			<b-col>
				<h6>Default Delivery Address</h6>
				<div v-if="user.default_delivery_address">
					<b-card class="border-radius-1em shadow-none border" @mouseenter="showControls = 'delivery'" @mouseleave="showControls = -1">
				<div class="media d-flex flex-column">
					<i v-b-tooltip.hover title="Default Delivery Address" class="bx bxs-truck position-absolute bg-success text-white top-0 right-0 mt-2 mr-2 font-size-18 rounded-circle p-1"></i>
						<div class="avatar-sm my-auto mx-auto">
							
							<a v-b-modal.modal-edit @click="updateAddressForm(user.default_delivery_address)"><span class="avatar-title rounded-circle">
								<i v-if="showControls == 'delivery'" class="bx bx-pencil font-size-18 text-white"></i>
							<i class="bx bx-home font-size-18"  v-else></i>
							</span></a>
		                 </div>
		                 <div class="media-body position-relative mt-2 text-center mx-auto">       
		                 <h5 class="font-size-14 mb-1">
		                    {{user.default_delivery_address.alias}}
		                 </h5>
		                 <div>
							<span v-if="user.default_delivery_address.address_1">{{user.default_delivery_address.address_1}}</span>
							<span v-if="user.default_delivery_address.address_2">{{user.default_delivery_address.address_2}}</span>
							<span v-if="user.default_delivery_address.address_3">{{user.default_delivery_address.address_3}}</span>
							<span v-if="user.default_delivery_address.city">{{user.default_delivery_address.city}}</span>
							<span v-if="user.default_delivery_address.country">{{user.default_delivery_address.country.name}}</span>
							<span v-if="user.default_delivery_address.county">{{user.default_delivery_address.county.name}}</span>
							<span v-if="user.default_delivery_address.postcode">{{user.default_delivery_address.postcode}}</span>
						</div>
						

					</div>
				</div>
			</b-card>
				</div>
				<div v-else class="border border-dashed border-radius-1em p-5 mt-2 text-center position-relative" @mouseenter="updateAddress = 'delivery'" @mouseleave="updateAddress = ''">
					<i class="bx bx-home text-primary font-size-18"></i>
					<p class="text-muted mb-0">No default address set</p>
				<transition name="fade-top">
					<div class="bg-white avatar-title text-dark top-0 bottom-0 left-0 right-0 mb-2 mx-auto position-absolute w-100 text-uppercase border-radius-1em" v-if="updateAddress == 'delivery'">
                	<a class="btn btn-primary btn-rounded w-75" v-b-modal.modal-edit @click="address = JSON.parse(JSON.stringify(address_data)), form = 'address', action = 'create', address.delivery_default = 1" >Add New Address</a>
                	</div>
                </transition>
				</div>
			</b-col>
			<b-col>
				<h6>Default Billing Address</h6>
				<div v-if="user.default_invoice_address">
					<b-card class="border-radius-1em shadow-none border" @mouseenter="showControls = 'invoice'" @mouseleave="showControls = -1">
				<div class="media d-flex flex-column">
					<i v-b-tooltip.hover title="Default Billing Address" class="bx bx-money position-absolute bg-success text-white top-0 right-0 mt-2 mr-2 font-size-18 rounded-circle p-1"></i>
						<div class="avatar-sm my-auto mx-auto">
							
							<a v-b-modal.modal-edit @click="updateAddressForm(user.default_invoice_address)"><span class="avatar-title rounded-circle">
								<i v-if="showControls == 'invoice'" class="bx bx-pencil font-size-18 text-white"></i>
							<i class="bx bx-home font-size-18"  v-else></i>
							</span></a>
		                 </div>
		                 <div class="media-body position-relative mt-2 text-center mx-auto">       
		                 <h5 class="font-size-14 mb-1">
		                    {{user.default_invoice_address.alias}}
		                 </h5>
		                 <div>
							<span v-if="user.default_invoice_address.address_1">{{user.default_invoice_address.address_1}}</span>
							<span v-if="user.default_invoice_address.address_2">{{user.default_invoice_address.address_2}}</span>
							<span v-if="user.default_invoice_address.address_3">{{user.default_invoice_address.address_3}}</span>
							<span v-if="user.default_invoice_address.city">{{user.default_invoice_address.city}}</span>
							<span v-if="user.default_invoice_address.country">{{user.default_invoice_address.country.name}}</span>
							<span v-if="user.default_invoice_address.county">{{user.default_invoice_address.county.name}}</span>
							<span v-if="user.default_invoice_address.postcode">{{user.default_delivery_address.postcode}}</span>
						</div>
						

					</div>
				</div>
			</b-card>
				</div>
				<div v-else class="border border-dashed border-radius-1em p-5 mt-2 text-center position-relative" @mouseenter="updateAddress = 'invoice'" @mouseleave="updateAddress = ''">
					<i class="bx bx-home text-primary font-size-18"></i>
					<p class="text-muted mb-0">No default address set</p>
					<transition name="fade-top">
					<div class="bg-white avatar-title text-dark top-0 bottom-0 left-0 right-0 mb-2 mx-auto position-absolute w-100 text-uppercase border-radius-1em" v-if="updateAddress == 'invoice'">
                	<a class="btn btn-primary btn-rounded w-75" v-b-modal.modal-edit @click="address = JSON.parse(JSON.stringify(address_data)), form = 'address', action = 'create', address.invoice_default = 1" >Add New Address</a>
                	</div>
                </transition>

				</div>
				
			</b-col>
			
			<b-col md="12" class="d-flex mt-3" v-if="(cardExpanded !== 'addresses')">
				
				<router-link class="text-primary ml-auto" to="/my-account/addresses" @click="cardExpanded = 'addresses'">View All</router-link>
			</b-col>
		</b-row>
	</b-card>

	<b-card class="border-radius-1em" v-if="(cardExpanded == 'addresses')">

				<CustomerAddresses :user="user" @create-address="address = JSON.parse(JSON.stringify(address_data)), form = 'address', action = 'create'" @update-address="updateAddressForm"></CustomerAddresses>
	</b-card>

	

	<b-card class="border-radius-1em" body-class="d-flex flex-column" v-if="(cardExpanded == '') || (cardExpanded == 'orders')">
		<h3 class="mb-4 section-info-header position-relative mr-auto">Latest Orders</h3>
		<b-row>
		<b-col>
			<div v-if="orders">
				<OrderList :product_list="orders" :expanded="isExpanded" :view="'list'" ></OrderList>
				<b-pagination
					class="mt-3 float-end"
					v-if="orders.per_page > 5"
					v-model="orders_page"
					@change="loadUserOrders(orders_limit, orders_page)"
					:total-rows="orders.total"
      				:per-page="orders.per_page"
					>
				</b-pagination>
			</div>
			<div v-else class="border border-dashed border-radius-1em p-5 mt-2 text-center position-relative" >
					<i class="bx bxs-cube-alt text-primary font-size-18"></i>
					<p class="text-muted mb-0">No orders to show</p>
					

				</div>
		</b-col>
		<b-col md="12" class="d-flex mt-3">
				<router-link class="text-primary ml-auto"  to="/my-account/orders" @click="orders_limt = 15, cardExpanded = 'orders'">View All</router-link>
			</b-col>
		</b-row>
	</b-card>	

	<b-card class="border-radius-1em" body-class="d-flex flex-column" v-if="(cardExpanded == '') || (cardExpanded == 'messages')">
		<h5 class="mb-4 d-inline">Latest messages</h5>
		<a v-b-modal.modal-messages class="btn btn-primary float-right btn-rounded text-white" href="javascript:;">
			Send New Message
		</a>
		<b-row class="mt-4">
		<b-col>
			
			<div v-if="message_threads">
			
				<!--<PublicCustomerMessages ref="support-messages" :thread_list="message_threads" :expanded="isExpanded" :view="'list'" @mark-read="markAsRead" :user="user" @reload-threads="this.loadUserMessages(this.orders_limit, this.orders_page)" ></PublicCustomerMessages>-->

				<PublicCustomerTwilioMessages ref="support-messages" :thread_list="message_threads" :expanded="isExpanded" :view="'list'" @mark-read="markAsRead" :user="user" @reload-threads="this.loadUserMessages(15, this.orders_page)" ></PublicCustomerTwilioMessages>
				
			</div>
			
			<div v-else class="border border-dashed border-radius-1em p-5 mt-2 text-center position-relative" >
					<i class="bx bxs-cube-alt text-primary font-size-18"></i>
					<p class="text-muted mb-0">No messages to show</p>
					

				</div>
		</b-col>
		<b-col md="12" class="d-flex mt-3">
				<router-link class="text-primary ml-auto"  to="/my-account/messages" @click="messages_limt = 15, cardExpanded = 'messages'">View All</router-link>
			</b-col>
		</b-row>
	</b-card>	

	<b-card class="border-radius-1em" body-class="d-flex flex-column" v-if="(cardExpanded == '') || (cardExpanded == 'favourites')">
		<h3 class="mb-4 section-info-header position-relative mr-auto">Favourite Stores</h3>
		<b-row>
		<b-col class="overflow-hidden" v-if="favourites">
			<SellerSwiper v-if="(cardExpanded == '') || (cardExpanded !== 'favourites')" :items="favourites" :swiperOptions="swiperOptions" :favourites="user.favourites" :startSlide="1"></SellerSwiper>
		<PublicMarketplaceSellerList v-if="(cardExpanded !== '') && (cardExpanded == 'favourites')" :product_list="user.favourites" :expanded="1" :layouttoggle="true" :view="'grid'" :marketplace="'public'" ></PublicMarketplaceSellerList>
		</b-col>
		<b-col v-else>
			<div class="border border-dashed border-radius-1em p-5 mt-2 text-center position-relative" >
					<i class="bx bxs-cube-alt text-primary font-size-18"></i>
					<p class="text-muted mb-0">No favourite stores</p>
					

				</div>
		</b-col>
		<b-col md="12" class="d-flex">
				<router-link class="text-primary ml-auto"  to="/my-account/favourites" @click="cardExpanded = 'favourites'">View All</router-link>
			</b-col>
		</b-row>
	</b-card>

	<b-card class="border-radius-1em" body-class="d-flex flex-column" v-if="(cardExpanded == '') || (cardExpanded == 'wishlist')">
		<h3 class="mb-4 section-info-header position-relative mr-auto">Product Wishlist</h3>
		<b-row>
		<b-col class="overflow-hidden" v-if="user.wishlist">
			<Swiper v-if="(cardExpanded == '') || (cardExpanded !== 'wishlist')" :items="wishlist" :swiperOptions="swiperOptions" :startSlide="1" :use="'public'"></Swiper>
			<PublicMarketplaceProductList v-if="(cardExpanded !== '') || (cardExpanded == 'wishlist')" :product_list="wishlist" :expanded="0" :layouttoggle="true" :view="'grid'" :marketplace="'public'" ></PublicMarketplaceProductList>
		</b-col>
		<b-col v-else>
			<div class="border border-dashed border-radius-1em p-5 mt-2 text-center position-relative" >
					<i class="bx bxs-cube-alt text-primary font-size-18"></i>
					<p class="text-muted mb-0">No products in your wishlist</p>
					

				</div>
		</b-col>
		<b-col md="12" class="d-flex">
				
				<router-link class="text-primary ml-auto"  to="/my-account/wishlist" @click="favourites_limt = 15, cardExpanded = 'favourites'">View All</router-link>
			</b-col>
		</b-row>
	</b-card>

	<b-card class="shadow-none " v-if="(cardExpanded !== '')">
		<b-row>
			<b-col md="12">
				<a class="text-primary mr-auto" href="javascript:;" @click="goBack(), cardExpanded = ''"><i class="bx bx-chevron-left"></i> Back</a>
			</b-col>
		</b-row>
	</b-card>

	<b-modal ref="modal-edit" id="modal-edit" centered content-class="border-radius-1em" header-class="border-none" hide-footer>
	<div v-if="form == 'address'" class="p-3">	
		<CustomerAddressForm :address="address" :action="action" :user="user"></CustomerAddressForm>
    </div>

	</b-modal>

	 <b-modal no-close-on-backdrop ref="modal-messages" id="modal-messages" centered content-class="border-radius-1em" header-class="border-none" size="lg" hide-footer>
    	<CustomerSupportForm :user="user" @load-orders="loadUserOrders(0,1)" :orders="orders.data" :orderlast_page="orders.last_page" @updateorderslist="orders_limit = orders_limit + 85, loadUserOrders(orders_limit, orders_page)"></CustomerSupportForm>
    </b-modal>
	</div>
</template>