<template>
<div>
<p class="mb-2 text-muted font-size-14">REFERENCE: {{product.reference}}</p>
				<h1 class="text-capitalize mb-3">{{product.name}}</h1>
</div>
</template>

<script type="text/javascript">
	export default{
		props: {
			product: {
				type: Object,
			}
		}
	}

</script>