<script>
import VueSlideBar from "vue-slide-bar";
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
//import FilterCompanyProductCategories from "../../views/stock/product/category/_partials/FilterCompanyProductCategories";
import AccessRestricted from "../../views/utility/access-restricted";
import FilterCompanyProductCategories from "../../components/widgets/FilterCompanyProductCategories";
import FilterProductBrands from "../../components/widgets/FilterProductBrands";
import FilterProductCollections from "../../components/widgets/FilterProductCollections";
import { clothsData } from "./data-products";
import SideSlidePanel from "../../components/widgets/SideSlidePanel";
import ProductLabel from "../../components/widgets/admin/print/ProductLabel";
import SelectCompanyCategoryTree from "../../components/widgets/SelectCompanyCategoryTree";


/**
 * Products component
 */
export default {
  components: { VueSlideBar, Layout, PageHeader, FilterCompanyProductCategories, FilterProductBrands, FilterProductCollections, SideSlidePanel, ProductLabel, SelectCompanyCategoryTree, AccessRestricted },
  data() {
    return {
      all_categories: '',
      categories: [],
      categories_type: 'company',
      success_message: false,
      success_text: 'Congratulations, your action has been saved.',
      showActiveOn: -1,
      deleteMode: 'single',
      moveMode: 'single',
      printMode: false,
      product_parent_id: '',
      clothsData: clothsData,
      productsData: '',
      title: "Products",
      items: [
        {
          text: "Ecommerce",
          href: "/",
        },
        {
          text: "Products",
          active: true,
        },
      ],
      qrData: {
        type: 'product',
        reference: '',
        uid: '',
        id: '',
      },
      selected_product_id: '',
      search: '',
      storageurl: 'https://'+this.$attrs.company.nickname+'.s3.eu-west-2.amazonaws.com',
      showFilters: false,
      sliderPrice: 800,
      priceRange: [0,999999],
      currentPage: 1,
      limit: 24,
      selectedids: [],
      orderDesc: true,
      order: 'desc',
      sortby: 'id',
      trade_active: '',
      public_active: '',
      brands: '',
      collections: '',
      selectedids: [],
      isCheckAll: false,
      active: '',
      view: 'list',
      fields: [
        { key: "id", sortable: true },
        { key: "name", sortable: true },
        ],
      filter: null,
      filterOn: [],
      limits: [
        {value: 24, text: "24"},
        {value: 48, text: "48"},
        {value: 96, text: "96"},
      ],
      discountRates: [],
    };
  },
  methods: {
    addTax(product, price){
            var tax_rule = product.tax_rule;
            console.log(tax_rule);
          if(this.$attrs.company.trading_country_id == '' || this.$attrs.company.trading_country_id == null){
            var country_id = this.$attrs.company.country_id;
          }else{
            var country_id = this.$attrs.company.trading_country_id;
          }
          if(tax_rule !== null && tax_rule.country_rules.length > 0){
            var selected_rule = null;
            var selected_ruleIndex = -1;
            for(var ti = 0; ti < tax_rule.country_rules.length; ti++){
              selected_ruleIndex = tax_rule.country_rules[ti].countries.findIndex(item => item == country_id);
              if(selected_ruleIndex !== -1){
                selected_rule = tax_rule.country_rules[ti];
                ti = tax_rule.country_rules.length;
              }
            }
            console.log(selected_rule);
            if(tax_rule.type == 1){
                if(selected_rule !== null){
                  var percentage = (parseFloat(selected_rule.value) / 100) + 1;
                  return price * percentage;
                }else{
                  return price;
                }
              }else{
                if(selected_rule !== null){
                  return price + selected_rule.value;
                }else{
                  return price;
                }
              }
            }else if(tax_rule !== null && tax_rule.country_rules.length > 0){
              var percentage = (parseFloat(tax_rule.percentage) / 100) + 1;
              return price * percentage;
            }else {
              return price;
            }
          },
    setPrintMode(){
      if(this.selectedids.length > 1){
        this.printMode = true;
      }else{
        this.printMode = false;
      }
    },
    valuechange(value) {
      this.clothsData = clothsData.filter(function (product) {
        return product.newprice <= value.currentValue;
      });
    },

    searchFilter(e) {
      const searchStr = e.target.value;
      this.productQuery();
    },
    filterSelectedCategories(value){
      console.log('test:' + value);
      var index = this.categories.findIndex(item => item == value);
      if(index == -1){
        this.categories.push(value);
      }else{
        this.categories.splice(index, 1);
      }
      this.productQuery();
    },
    filterBrands(value){
      this.brands = value;
      this.productQuery();
    },
     filterCollections(value){
      this.collections = value;
      this.productQuery();
    },
    editProductCategory(id){
      this.product_parent_id = id;
    },
    discountLessFilter(e, percentage) {
      if (e === "accepted" && this.discountRates.length === 0) {
        this.clothsData = clothsData.filter((product) => {
          return product.discount < percentage;
        });
      } else {
        this.clothsData = clothsData.filter((product) => {
          return product.discount >= Math.max.apply(null, this);
        }, this.discountRates);
      }
    },
      getAllCategories(){
      if(this.all_categories == ''){
        axios.get('/api/company/product/categories?page=1&limit=1000&sortby=id&order=desc&search=&parent_id=null&marketplace_categories='+this.categories_type, {headers: {
                  'Authorization': 'Bearer ' + this.$attrs.user.api_token
              }}).then(response => {
          this.all_categories = response.data;
        }).catch(error => {
          this.brands_error = error.data;
        });
      }
    },
    setActive(active, area){
            axios.post('/api/company/product/set-active', {ids: this.selectedids, active: active, market: area}, {headers: {
              'Authorization' : 'Bearer '+this.$attrs.user.api_token
            }}).then(response => {
              
              this.success_text = 'Congratulations, your action has been saved';
              this.success_message = true;
              if(this.selectedids.length > 1){
                return this.productQuery();
              }else{
                return true;
              }
                
            }).catch(error => {

            });
    },
    discountMoreFilter(e, percentage) {
      if (e === "accepted") {
        this.discountRates.push(percentage);
      } else {
        this.discountRates.splice(this.discountRates.indexOf(percentage), 1);
      }
      this.clothsData = clothsData.filter((product) => {
        return product.discount >= Math.max.apply(null, this);
      }, this.discountRates);
    },
    destroyProduct(product_id){
            axios.post('/api/company/product/'+this.selected_product_id+'/destroy', {}, {headers: {
              'Authorization' : 'Bearer '+this.$attrs.user.api_token
            }}).then(response => {
                this.$bvModal.hide('delete-modal');
                return this.productQuery();
            }).catch(error => {

            });
    },
     bulkDestroyProduct(){
            axios.post('/api/company/product/bulk-destroy', {ids: this.selectedids}, {headers: {
              'Authorization' : 'Bearer '+this.$attrs.user.api_token
            }}).then(response => {
                this.$bvModal.hide('delete-modal');
                this.selectedids = [];
                return this.productQuery();
            }).catch(error => {

            });
     },
     productQuery(){
      if(this.productsData != ''){
        if(this.limit > this.productsData.total){
          this.currentPage = 1;
        }
      }
      if(this.orderDesc == true){
        this.order = 'desc';
      }else{
        this.order = 'asc';
      }
      axios.get('/api/company/products?page='+this.currentPage+'&limit='+this.limit+'&sortby='+this.sortby+'&order='+this.order+'&search='+this.search+'&trade_active='+this.trade_active+'&public_active='+this.public_active+'&active='+this.active+'&brands='+this.brands+'&collections='+this.collections+'&categories='+this.categories+'&pricerange='+this.priceRange, {headers: {'Authorization' : 'Bearer '+this.$attrs.user.api_token}}).then(response => {
        console.log(response);
        this.productsData = response.data;
      });
    },
     filterSelected(id){
        if(this.selectedids.length > 0){
          var exists = this.selectedids.filter(item => item == id);
          if(exists.length > 0){
            return true;
          }else{
            return false
          }
        }else{
          return false;
        }
      },
      checkAll: function(){ 
                 this.isCheckAll = !this.isCheckAll; 
                 this.selectedids = []; 
                 if(this.isCheckAll){ 
                     // Check all 
                     for (var key in this.productsData.data) {          
                        this.selectedids.push(this.productsData.data[key].id); 
                     } 
                 } 
              }, 
      updateCheckall: function(){ 
                 if(this.selectedids.length == this.productsData.data.length){
                    this.isCheckAll = true; 
                 }else{ 
                    this.isCheckAll = false; 
                 } 
      },
  },
  beforeMount(){
      this.productQuery();
      if(this.$attrs.user.companies[0].permissions == null) {
        this.$attrs.user.companies[0].permissions = {add: [], edit: [], read: [], view: [], delete: []};
      }
  },
  computed:{
   companyCurrency(){
      var currency = this.$attrs.currencies.filter(item => item.id == this.$attrs.company.trading_currency_id);
      return currency[0].symbol;
    },
  }
};
</script>

<template>
  <Layout  :nickname="$attrs.nickname" :user="$attrs.user" :menuItems="$attrs.menuItems">
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <b-col md="12">
                    <transition name="slide-success" mode="out-in">
                  <b-alert id="success-message" variant="success" :show="success_message"  dismissible @dismissed="success_message = !success_message">
                      <p class="font-size-14">{{success_text}}</p>
                    </b-alert>
                  </transition>
                  </b-col>
      <div :class="{'col-lg-12': showFilters == true, 'col-lg-12': showFilters == false}">
        <div class="row mb-3">
          <div class="col-xl-1 col-sm-6">
            <div class="mt-2">
              <b-form-select class="form-control" v-model="limit" :options="limits" @input="productQuery()"></b-form-select>
            </div>
          </div>
         
          <div class="col-lg-2 col-sm-6 d-flex">
          <form class="my-auto">
            <small class="mx-auto my-2 text-center d-block">Results {{productsData.from}} - {{productsData.to}} of {{productsData.total}}</small>
          </form>
        </div>
        <div class="col-lg-5 col-sm-6 d-flex">
            <div class="mt-sm-0 form-inline w-100" >
              <div class="search-box mr-2 ml-auto">
                <div class="position-relative">
                  <input
                    type="text"
                    class="form-control w-100 border-0"
                    placeholder="Search name, reference or description..."
                    v-model="search"
                    @keyup.enter="searchFilter($event)"
                  />
                  <i class="bx bx-search-alt search-icon"></i>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-sm-6 d-flex">
            <div class="d-flex ml-auto">
              <ul class="nav nav-pills product-view-nav">
                <li class="nav-item">
                  <a class="nav-link" :class="{'active': showFilters == true }" href="javascript: void(0);" @click="showFilters = !showFilters">
                    <i class="bx bx-filter-alt"></i>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" :class="{'active': view == 'grid' }" href="javascript: void(0);" @click="view = 'grid'">
                    <i class="bx bx-grid-alt"></i>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" :class="{'active': view == 'list' }" href="javascript: void(0);" @click="view = 'list'">
                    <i class="bx bx-list-ul"></i>
                  </a>
                </li>
                <li class="nav-item">
                 <b-dropdown text="Bulk Actions" variant="primary" class="rounded">
                   <b-dropdown-text class="list-group list-group-flush" text-class="list-group-item mb-0" @mouseenter="showActiveOn = 'active'" @mouseleave="showActiveOn = -1">

                      <i class="bx bx-check-circle font-size-14 text-success">
                        </i> Set Active On:
                   <transition name="fade-right">
                        <b-list-group v-if="showActiveOn == 'active'" flush class="pages-submenu hide" style="position: absolute; left: -100%; top: 0; width: 100%" >
                           <b-list-group-item :disabled="($attrs.user.companies[0].userrole.name !== ('Owner' || 'Manager')) && !$attrs.user.companies[0].permissions.edit.includes($attrs.active_menu.id)" href="javascript: void(0);" @click="setActive(true, 'active')">Shops</b-list-group-item>
                            <b-list-group-item :disabled="($attrs.user.companies[0].userrole.name !== ('Owner' || 'Manager')) && !$attrs.user.companies[0].permissions.edit.includes($attrs.active_menu.id)" href="javascript: void(0);" @click="setActive(true, 'public')">Public Market</b-list-group-item>
                            <b-list-group-item :disabled="($attrs.user.companies[0].userrole.name !== ('Owner' || 'Manager')) && !$attrs.user.companies[0].permissions.edit.includes($attrs.active_menu.id)" href="javascript: void(0);" @click="setActive(true, 'trade')">Trade Market</b-list-group-item>
                        </b-list-group>
                      </transition>
                    </b-dropdown-text>
                    <b-dropdown-text class="list-group list-group-flush" text-class="list-group-item mb-0" @mouseenter="showActiveOn = 'inactive'" @mouseleave="showActiveOn = -1">

                      <i class="bx bx-x-circle font-size-14 text-danger">
                        </i> Set Inactive On:
                   <transition name="fade-right">
                        <b-list-group v-if="showActiveOn == 'inactive'" flush class="pages-submenu hide" style="position: absolute; left: -100%; top: 0; width: 100%" >
                           <b-list-group-item :disabled="($attrs.user.companies[0].userrole.name !== ('Owner' || 'Manager')) && !$attrs.user.companies[0].permissions.edit.includes($attrs.active_menu.id)" href="javascript: void(0);" @click="setActive(false, 'active')">Shops</b-list-group-item>
                            <b-list-group-item :disabled="($attrs.user.companies[0].userrole.name !== ('Owner' || 'Manager')) && !$attrs.user.companies[0].permissions.edit.includes($attrs.active_menu.id)" href="javascript: void(0);"  @click="setActive(false, 'public')">Public Market</b-list-group-item>
                            <b-list-group-item :disabled="($attrs.user.companies[0].userrole.name !== ('Owner' || 'Manager')) && !$attrs.user.companies[0].permissions.edit.includes($attrs.active_menu.id)" href="javascript: void(0);"  @click="setActive(false, 'trade')">Trade Market</b-list-group-item>
                        </b-list-group>
                      </transition>
                    </b-dropdown-text>
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-item href="javascript: void(0);" disabled><i class="bx bx-copy-alt font-size-14"></i> Duplicate</b-dropdown-item>
                  <b-dropdown-item :disabled="($attrs.user.companies[0].userrole.name !== ('Owner' || 'Manager')) && !$attrs.user.companies[0].permissions.edit.includes($attrs.active_menu.id)" href="javascript: void(0);" v-b-modal.move-modal @click="all_categories = '', categories_type = 'company', getAllCategories(), moveMode = 'bulk'"><i class="bx bx-move"></i> Move Category</b-dropdown-item>
                  <b-dropdown-item href="javascript: void(0);" v-b-modal.print-label @click="setPrintMode()"><i class="bx bx-printer font-size-14"></i> Print Labels</b-dropdown-item>
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-item href="javascript: void(0);" v-b-modal.delete-modal @click="deleteMode = 'bulk'" :disabled="($attrs.user.companies[0].userrole.name !== ('Owner' || 'Manager')) && !$attrs.user.companies[0].permissions.delete.includes($attrs.active_menu.id)"><i class="bx bx-trash-alt font-size-14"></i> Delete</b-dropdown-item>
                </b-dropdown>
                </li>
                <li class="nav-item">
                 <b-button
                  :disabled="($attrs.user.companies[0].userrole.name !== ('Owner' || 'Manager')) && !$attrs.user.companies[0].permissions.add.includes($attrs.active_menu.id)"
                    variant="success"
                    class="btn btn-success btn-rounded mb-2 mr-2 text-white"
                    href="product/add"
                  >
                    <i class="mdi mdi-plus mr-1"></i> New
                  </b-button>
                </li>
              </ul>
              <div class="text-sm-right">
                  
                </div>
          </div>
        </div>
      </div>
      <b-modal id="move-modal" size="md" hide-footer hide-header centered>
        <b-card class="border-radius-1em border shadow-none">
          <h4>Move Category</h4>
           <p class="mt-3">New Default Category</p>
          <!--<FilterCompanyProductCategories :category_data="brandsData.data"></FilterCompanyProductCategories>-->
          <!--<input type="hidden" name="parent_id" :value="update_parent_id">-->
          <b-tabs pills content-class="pt-3">
            <b-tab title="Shop"  @click="all_categories = '', categories_type = 'company', getAllCategories()">
              <div class="border border-radius-1em widget-scrollbox">
                <b-overlay :show="all_categories == ''">
                <SelectCompanyCategoryTree v-if="all_categories !== ''" :selectedid="product_parent_id" :category_data="all_categories.data" :company="$attrs.company" @category-select="editProductCategory"></SelectCompanyCategoryTree>
              </b-overlay>
              </div>
            </b-tab>
            <b-tab title="Consumer" @click="all_categories = '', categories_type = 'public', getAllCategories()">
              <div class="border border-radius-1em widget-scrollbox">
                <b-overlay :show="all_categories == ''">
                <SelectCompanyCategoryTree v-if="all_categories !== ''" :selectedid="product_parent_id" :category_data="all_categories.data" @category-select="editProductCategory"></SelectCompanyCategoryTree>
              </b-overlay>
              </div>
            </b-tab>
            <b-tab title="Trade"  @click="all_categories = '', categories_type = 'trade', getAllCategories()">
              <div class="border border-radius-1em widget-scrollbox">
                <b-overlay :show="all_categories == ''">
                <SelectCompanyCategoryTree v-if="all_categories !== ''" :selectedid="product_parent_id" :category_data="all_categories.data" @category-select="editProductCategory"></SelectCompanyCategoryTree>
              </b-overlay>
              </div>
            </b-tab>
          </b-tabs>
          <a class="btn btn-primary btn-rounded btn-block mt-3 w-100" v-if="moveMode == 'single'" @click="moveCategory()">
            Move Category
          </a>
          <a class="btn btn-primary btn-rounded btn-block mt-3 w-100" v-if="moveMode == 'bulk'" @click="bulkMoveCategory()">
            Move Category
          </a>
        </b-card>
      </b-modal>
      <b-modal id="delete-modal" size="md" hide-footer hide-header centered>
        <b-card class="border-radius-1em border shadow-none">
          <h4>Are you sure?</h4>
          <p v-if="deleteMode == 'bulk'">You are about to delete {{selectedids.length}} products. Are you sure you want to do this? This action is not reversable</p>
           <p v-if="deleteMode == 'single'">You are about to delete this product. Are you sure you want to do this? This action is not reversable</p>

           <div class="d-flex">
            <b-button variant="danger" :disabled="!$attrs.user.companies[0].permissions.delete.includes($attrs.active_menu.id)" class="btn btn-danger" v-if="deleteMode == 'bulk'" @click="bulkDestroyProduct">
              Delete
            </b-button>
             <b-button variant="danger" class="btn btn-danger" :disabled="!$attrs.user.companies[0].permissions.delete.includes($attrs.active_menu.id)"  v-if="deleteMode == 'single'" @click="destroyProduct">
              Delete
            </b-button>
            <a class="btn btn-success" @click="$bvModal.hide('delete-modal')">
              Cancel
            </a>
          </div>
        </b-card>
      </b-modal>
            <b-modal id="print-label" size="xl" hide-footer centered>
            <ProductLabel :code_type="'barcode'"  :qr_value="qrData" :isMultiple="printMode" :item_count="selectedids.length" :items="selectedids"></ProductLabel>
          </b-modal>
        <!--<div class="row">
          <div class="col-lg-12">
            <b-pagination
              v-if="productsData.data.length > 0"
              class="justify-content-center mb-0"
              pills
              v-model="currentPage"
              :total-rows="productsData.total"
              :per-page="productsData.per_page"
              aria-controls="my-table"
              @input="productQuery"
            ></b-pagination>
             <small class="mx-auto my-2 text-center d-block">Records {{productsData.from}} - {{productsData.to}} of {{productsData.total}} // Page {{productsData.current_page}} of {{productsData.last_page}}</small>
          </div>
        </div>-->
        <div  class="d-flex flex-column justify-content-center h-100 restricted-col" v-if="($attrs.user.companies[0].userrole.name !== ('Owner' || 'Manager')) && (!$attrs.user.companies[0].permissions.read.includes($attrs.active_menu.id))">
          <AccessRestricted></AccessRestricted>
        </div>
        <div v-else>
        <div class="row">
          <div id="my-table" v-if="view == 'grid'" v-for="data in productsData.data" :key="data.id" class="col-xl-3 col-sm-6">
             <div class="card overflow-hidden">
           <div class="bg-soft-primary bg-light border-radius-top-right-1em border-radius-top-left-1em" >
            <div class="row">
              
              <div class="col-12 align-self-end" style="min-height: 150px; height: 320px;">
                <a :href="'/stock/product/'+data.uid+''" >
                <img v-if="data.cover_image !== null && 'gallery' in data.cover_image" :src="`${storageurl+data.cover_image.gallery.replace('//', '/')}`" alt class="w-100 h-100" style="object-fit: cover" />

               <img v-else-if="data.cover_image !== null && 'thumb' in data.cover_image" :src="`${storageurl+data.cover_image.thumb.replace('//', '/')}`" alt class="w-100 h-100" style="object-fit: cover" />

                            

                            <img v-else-if="data.cover_image !== null && 'src' in data.cover_image" :src="`${storageurl+data.cover_image.src.replace('//', '/')}`" alt class="w-100 h-100" style="object-fit: cover" />
                          </a>
                
              </div>
            </div>
          </div>
          <div class="card-body pt-0 bg-white">

            <div class="row">
              <div class="col-sm-12 text-center px-4 mb-2">
               <!-- <div class="avatar-xl profile-user-wid mb-4">
                  <img class="img-thumbnail rounded-circle h-100" :src="`/public/storage/${list.image}`" alt />
                </div>-->
                <div class="avatar-sm profile-user-wid mx-auto">
                  
                    <div class="text-center" >
                        <b-form-checkbox class="select-row-btn" v-model="selectedids" :value="data.id" button-variant="primary" @input="updateSelected" button>

                          <i v-if="filterSelected(data.id)" class="bx bx-check"></i><span class="avatar-title rounded-circle text-uppercase" v-else><i class="bx bx-shopping-bag"></i></span></b-form-checkbox>
                    </div> 
                </div>
                <div class="mx-auto mt-2">
                       <span class="font-size-12 badge badge-light bg-light text-dark"><i class="bx bx-hash text-primary"></i> {{data.reference}}</span>
                      </div>
               <a class="text-muted mb-0 paragraph-truncate-line2" :href="'/order/details/'+data.uid+''"><h5 class="mt-2">{{data.name}}</h5></a>
                
                      <div class="mt-1" >
                      <p class="paragraph-truncate-line2" v-html="data.short_description"></p>
                      </div>
                     
                      
              </div>

            
               <div class="col-sm-6 text-center border-right">
                <h6>
                <span class="currency">{{companyCurrency}}</span> {{data.sales_price}} exc. VAT
                </h6>
              </div>
              <div class="col-sm-6 text-center">
                <h6>
                <span class="currency">{{companyCurrency}}</span> {{data.sales_price}} inc. VAT
                </h6>
              </div>
              <b-col md="12" class="d-flex pt-3">
                <div
                        class="form-check form-switch form-switch-sm ml-auto my-auto"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          :id="'SwitchCheckSizeactive'+data.id"
                          v-model="data.active"
                          :value="data.active"
                          name="active"
                          :disabled="($attrs.user.companies[0].userrole.name !== ('Owner' || 'Manager')) && (!$attrs.user.companies[0].permissions.edit.includes($attrs.active_menu.id))"
                          @change="selectedids = [], selectedids.push(data.id), setActive(data.active, 'active')"
                        />
                        <label class="form-check-label" :for="'SwitchCheckSizeactive'+data.id" 
                          >Active</label
                        >
                    </div>
                    <div
                        class="form-check form-switch form-switch-sm mx-2 my-auto"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          :id="'SwitchCheckSizetrade'+data.id"
                          v-model="data.trade"
                          :value="data.trade"
                          name="active"
                          :disabled="($attrs.user.companies[0].userrole.name !== ('Owner' || 'Manager')) && (!$attrs.user.companies[0].permissions.edit.includes($attrs.active_menu.id))"
                          @change="selectedids = [], selectedids.push(data.id), setActive(data.trade, 'trade')"
                        />
                        <label class="form-check-label" :for="'SwitchCheckSizetrade'+data.id" 
                          >Trade</label
                        >
                    </div>
                    <div
                        class="form-check form-switch form-switch-sm mr-auto my-auto"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          :id="'SwitchCheckSizepub'+data.id"
                          v-model="data.public"
                          :value="data.public"
                          name="active"
                          :disabled="($attrs.user.companies[0].userrole.name !== ('Owner' || 'Manager')) && (!$attrs.user.companies[0].permissions.edit.includes($attrs.active_menu.id))"
                          @change="selectedids = [], selectedids.push(data.id), setActive(data.public, 'public')"
                        />
                        <label class="form-check-label" :for="'SwitchCheckSizepub'+data.id" 
                          >Public</label
                        >
                    </div>
              </b-col>
            </div>
          </div>

          <div class="card-footer  bg-transparent border-top">
                  <div class="d-flex">
                    <div><a :href="'/stock/product/'+data.uid+''" class="btn btn-outline-light btn-rounded"><i class="mdi mdi-eye-outline my-1"></i></a>
                    <b-dropdown  placement="left" variant="outline-light" dropup class="dropup more-options-dropdown btn-rounded ml-2 mr-auto" menu-class="dropdown-menu-left">
                        <template v-slot:button-content>
                           <i class="bx bx-dots-horizontal-rounded"></i>
                        </template>
            
                   
                  <!--<b-dropdown-item href="javascript: void(0);" @click="bulkDuplicateResource()"><i class="bx bx-copy-alt font-size-14"></i> Duplicate</b-dropdown-item>-->
                  <b-dropdown-divider></b-dropdown-divider>
                 
                 
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-item href="javascript: void(0);" @click="setCancelled(true)"><i class="bx bx-no-entry font-size-14 text-danger"></i> Mark Cancelled</b-dropdown-item>
                  <b-dropdown-item href="javascript: void(0);" :disabled="($attrs.user.companies[0].userrole.name !== ('Owner' || 'Manager')) && (!$attrs.user.companies[0].permissions.delete.includes($attrs.active_menu.id))" v-b-modal.delete-modal @click="deleteMode = 'bulk'"><i class="bx bx-trash-alt font-size-14"></i> Delete</b-dropdown-item>
                      </b-dropdown>
                     </div>
                      
                     
                  </div>
          </div>
        </div>
            
          </div>
        </div>
         <div class="row" v-if="view == 'grid'">
              <div class="col-lg-12" v-if="productsData !== ''">
                <b-pagination
              class="justify-content-center"
              pills
              v-model="currentPage"
              :total-rows="productsData.total"
              :per-page="productsData.per_page"
              aria-controls="my-table"
              @input="productQuery"
            ></b-pagination>
             <small class="mx-auto my-2 text-center d-block">Records {{productsData.from}} - {{productsData.to}} of {{productsData.total}} // Page {{productsData.current_page}} of {{productsData.last_page}}</small>
              </div>
            </div>
        <!-- end row -->

        
        <div class="card" v-if="view == 'list'" >
          <div class="card-body p-0">
            <div class="table-responsive">
              <table class="table table-centered table-hover">
                <thead class="thead-light">
                  <tr>
                   <th scope="col"  style="width: 100px" class="text-center">
                     <div
                        class="form-check form-switch form-switch-sm mb-0 mx-auto p-0 mt-auto d-flex"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input mx-auto my-auto" 
                          type="checkbox"
                          id="SwitchCheckSizesm"
                         
                          v-model="isCheckAll"
                          @click='checkAll()'
                          name="active"
                        />
                    </div>
                  </th>
                    <th scope="col"><a :class="{'text-dark': sortby != 'reference'}" href="javascript: void(0);" @click="orderDesc = !orderDesc, sortby = 'reference', productQuery()">SKU <i v-if="orderDesc == true" class="bx bx-caret-up"></i><i v-if="orderDesc == false" class="bx bx-caret-down"></i></a></th>
                    <th scope="col" style="max-width: 300px"><a :class="{'text-dark': sortby != 'name'}" href="javascript: void(0);" @click="orderDesc = !orderDesc,  sortby = 'name', productQuery()">Name & Description <i v-if="orderDesc == true" class="bx bx-caret-up"></i><i v-if="orderDesc == false" class="bx bx-caret-down"></i></a></th>
                    
                    <th scope="col">Brand</th>
                    <th scope="col">Main Category</th>
                    <!--<th scope="col">Collections</th>-->
                      <th scope="col"><a :class="{'text-dark': sortby != 'sales_price'}" href="javascript: void(0);" @click="orderDesc = !orderDesc,  sortby = 'sales_price', productQuery()">Price <i v-if="orderDesc == true" class="bx bx-caret-up"></i><i v-if="orderDesc == false" class="bx bx-caret-down"></i></a></th>
                    <th scope="col">Quantity</th>
                    <th scope="col">Status</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="list in productsData.data" :key="list.id" valign="middle">
                    <td>
                      <div class="text-center" v-if="list.cover_image">
                      <b-form-checkbox class="select-row-btn " v-model="selectedids" :value="list.id" button-variant="primary" @input="updateSelected" button>
                        <i v-if="filterSelected(list.id)" class="bx bx-check"></i>
                        <div v-else>
                            <img v-if="list.cover_image !== null && 'thumb' in list.cover_image" :src="`${storageurl+list.cover_image.thumb.replace('//', '/')}`" alt class="avatar-sm rounded-circle" />

                            <img v-else-if="list.cover_image !== null && 'gallery' in list.cover_image" :src="`${storageurl+list.cover_image.gallery.replace('//', '/')}`" alt class="avatar-sm rounded-circle" />

                            <img v-else-if="list.cover_image !== null && 'src' in list.cover_image" :src="`${storageurl+list.cover_image.src.replace('//', '/')}`" alt class="avatar-sm rounded-circle" />
                        </div>
                      </b-form-checkbox>
                    </div>
                    <div class="text-center" v-else >
                        <b-form-checkbox class="select-row-btn" v-model="selectedids" :value="list.id" button-variant="light" @input="updateSelected" button>

                          <i v-if="filterSelected(list.id)" class="bx bx-check"></i><span class="avatar-title rounded-circle" v-else>{{(list.name).slice(0,1)}}</span></b-form-checkbox>
                    </div> 
                    </td>
                    <td>
                      <a :href="`/stock/product/${list.uid}`+'#basic-info'"><p class="text-primary mb-0">{{list.reference}}</p></a>
                    </td>
                    <td style="max-width: 350px">
                        <a :href="`/stock/product/${list.uid}`+'#basic-info'" class="mb-0"><p class="mb-0">{{list.name}}</p></a>
                        <p class="d-md-none d-lg-block mb-0 paragraph-truncate-line2 with-v-html" v-html="list.short_description"></p>
                    </td>
                    <td><a 
                          href="javascript: void(0);"
                          class="badge bg-light p-2 border-radius-1em bg-soft rounded-pill font-size-11 m-1"
                         v-if="list.brand != null"><img v-if="list.brand.logo" class="rounded-circle avatar-xs" :src="`${storageurl + '/' + list.brand.logo}`" alt />  <img v-else  src="/images/banner-placeholder.png" alt="" class="rounded-circle avatar-xs"> <span class="text-dark">{{list.brand.name}}</span></a></td>
                    <td>
                      <div>

                        <a 
                          href="javascript: void(0);"
                          class="badge bg-light p-2 border-radius-1em bg-soft rounded-pill font-size-11 m-1"
                         v-if="list.company_defaultcategory != null"><img class="rounded-circle avatar-xs" v-if="list.company_defaultcategory.image" :src="`${storageurl + '/' +list.company_defaultcategory.image}`" alt /> <img v-else  src="/images/banner-placeholder.png" alt="" class="rounded-circle avatar-xs"> <span class="text-dark">{{list.company_defaultcategory.name}}</span></a>

                         <!--<a 
                          href="javascript: void(0);"
                          class="badge badge-soft-light rounded-pill font-size-11 m-1"
                          v-for="category in list.company_categories"><img v-if="category.category.image" class="rounded-circle avatar-xs" :src="`${category.category.image}`" alt /> <img v-else src="/images/banner-placeholder.png" alt="" class="rounded-circle avatar-xs">  <span class="text-dark">{{category.category.name}}</span></a>
                            -->
                        
                      </div>
                    </td>
                    <!--<td>
                      

                    </td>-->
                    <td> <a href="javascript: void(0);"
                          class="badge badge-soft-light bg-light p-2 border-radius-1em font-size-11 text-dark m-1"
                        >{{companyCurrency}}{{list.sales_price}} exc. tax</a><br><a href="javascript: void(0);"
                          class="badge badge-soft-light bg-light p-2 border-radius-1em font-size-11 text-dark m-1"
                        >{{companyCurrency}}{{addTax(list, list.sales_price)}} inc. tax</a></td>
                    <td>
                      <a href="javascript: void(0);"
                          class="badge badge-soft-light text-dark font-size-11 m-1"
                        >{{list.total_qty}}</a>
                      </td>
                    </td>
                    <td>
                      <div
                        class="form-check form-switch form-switch-sm mb-0 mt-auto"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          :id="'SwitchCheckSizeactive'+list.id"
                          v-model="list.active"
                          :value="list.active"
                          name="active"
                          :disabled="($attrs.user.companies[0].userrole.name !== ('Owner' || 'Manager')) && (!$attrs.user.companies[0].permissions.edit.includes($attrs.active_menu.id))"
                          @change="selectedids = [], selectedids.push(list.id), setActive(list.active, 'active')"
                        />
                        <label class="form-check-label" :for="'SwitchCheckSizeactive'+list.id" 
                          >Active</label
                        >
                    </div>
                    <div
                        class="form-check form-switch form-switch-sm mb-0"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          :id="'SwitchCheckSizetrade'+list.id"
                          v-model="list.trade"
                          :value="list.trade"
                          name="active"
                          :disabled="($attrs.user.companies[0].userrole.name !== ('Owner' || 'Manager')) && (!$attrs.user.companies[0].permissions.edit.includes($attrs.active_menu.id))"
                          @change="selectedids = [], selectedids.push(list.id), setActive(list.trade, 'trade')"
                        />
                        <label class="form-check-label" :for="'SwitchCheckSizetrade'+list.id" 
                          >Trade</label
                        >
                    </div>
                    <div
                        class="form-check form-switch form-switch-sm mb-auto"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          :id="'SwitchCheckSizepub'+list.id"
                          v-model="list.public"
                          :value="list.public"
                          name="active"
                          :disabled="($attrs.user.companies[0].userrole.name !== ('Owner' || 'Manager')) && (!$attrs.user.companies[0].permissions.edit.includes($attrs.active_menu.id))"
                          @change="selectedids = [], selectedids.push(list.id), setActive(list.public, 'public')"
                        />
                        <label class="form-check-label" :for="'SwitchCheckSizepub'+list.id" 
                          >Public</label
                        >
                    </div>
                    </td>
                    <td>
                      <ul class="list-inline font-size-20 contact-links mb-0">
                        <li class="list-inline-item px-2">
                          <a v-b-tooltip.hover title="View" placement="left" :href="`/stock/product/${list.uid}`+'?edit=false#basic-info'" >
                            <i class="mdi mdi-eye-outline"></i>
                          </a>
                        </li>
                        <li class="list-inline-item px-2" >
                          <a v-b-tooltip.hover title="Edit" placement="left" :href="`/stock/product/${list.uid}`+'#basic-info'">
                            <i class="bx bx-edit-alt"></i>
                          </a>
                        </li>
                        <li class="list-inline-item px-2">
                           
                            <b-dropdown v-b-tooltip.hover title="More Options" 
                            placement="left"
                            dropleft
                             class="more-options-dropdown" menu-class="dropdown-menu-right">
                  <template v-slot:button-content>
                     <i class="bx bx-dots-horizontal-rounded"></i>
                  </template>
                  <b-dropdown-item href="javascript: void(0);" disabled><i class="bx bx-copy-alt font-size-14"></i> Duplicate</b-dropdown-item>
                  <b-dropdown-item href="javascript: void(0);" v-b-modal.move-modal @click="all_categories = '', categories_type = 'company', getAllCategories(), moveMode = 'single'"><i class="bx bx-move"></i> Move Category</b-dropdown-item>
                  <b-dropdown-item v-b-modal.print-label @click="setPrintMode()"><i class="bx bx-printer"></i> Print Label</b-dropdown-item>
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-item @click="selected_product_id = list.id, deleteMode = 'single'" v-b-modal.delete-modal :disabled="($attrs.user.companies[0].userrole.name !== ('Owner' || 'Manager')) && (!$attrs.user.companies[0].permissions.delete.includes($attrs.active_menu.id))"><i class="bx bx-trash"></i> Delete</b-dropdown-item>
                </b-dropdown>
                          </a>
                        </li>
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="row">
              <div class="col-lg-12" v-if="productsData !== ''">
                <b-pagination
              class="justify-content-center"
              pills
              v-model="currentPage"
              :total-rows="productsData.total"
              :per-page="productsData.per_page"
              aria-controls="my-table"
              @input="productQuery"
            ></b-pagination>
             <small class="mx-auto my-2 text-center d-block">Records {{productsData.from}} - {{productsData.to}} of {{productsData.total}} // Page {{productsData.current_page}} of {{productsData.last_page}}</small>
              </div>
            </div>
          </div>
          </div>
        </div>
      </div>
       <SideSlidePanel :editPanel="showFilters" :panelClass="'edit-sidepanel-slim'" @close-panel="showFilters = !showFilters">     
         <div class="col-lg-12" v-if="showFilters == true">
        <div class="">
          <div class="">
            
            <h4 class="card-title">Source</h4>
              <div class="row pb-3">
                <div class="col-md-12 d-flex">
                  <a href="javascript:;" class="badge badge-light bg-light m-2 font-size-14"  v-if="active == 1" @click="active = '', productQuery()" >
                    <i class="bx bx-check-circle text-success"></i>
                    Active
                  </a>
                  <a href="javascript:;" v-else class="badge badge-light bg-light m-2 font-size-14" @click="active = 1, productQuery()" >
                    Active
                  </a>
                  <a href="javascript:;" class="badge badge-light bg-light m-2 font-size-14" v-if="public_active == 1" @click="public_active = '', productQuery()" >
                    <i class="bx bx-check-circle text-success" ></i>
                   Public Active
                  </a>
                   <a href="javascript:;" class="badge badge-light bg-light m-2 font-size-14" v-else @click="public_active = 1, productQuery()" >
                   
                   Public Active
                  </a>
                  <a href="javascript:;" class="badge badge-light bg-light m-2 font-size-14" v-if="trade_active == 1" @click="trade_active = '', productQuery()" >
                    <i class="bx bx-check-circle text-success"></i>
                    Trade Active
                  </a>
                   <a href="javascript:;" v-else class="badge badge-light bg-light m-2 font-size-14" @click="trade_active = 1, productQuery()" >
                    Trade Active
                  </a>
                   
                </div>
              </div>
            <div>
             
               <div class="my-3 pt-3">
              <h4 class="card-title">Price</h4>
              <b-form-group label="Min Price" class="w-50 d-inline float-left mt-2 mb-3 px-2">
              <b-form-input
                type="number"
                v-model="priceRange[0]"
                @input="productQuery"></b-form-input>
              </b-form-group>
              <b-form-group label="Max Price" class="w-50 d-inline float-left mt-2 mb-3 px-2">
                 <b-form-input
                 label="Max Price"
                 type="number"
                 v-model="priceRange[1]"
                 @input="productQuery"></b-form-input>
              </b-form-group>
            </div>
         
                <h4 class="card-title">Categories</h4>
                
                <b-tabs pills content-class="pt-3 mb-2">
                  <b-tab title="My Shops">
                    <b-card class="border-radius-1em border shadow-none mb-0">
                <FilterCompanyProductCategories  :company="$attrs.company" :api_token="$attrs.user.api_token"  :category_data="category_data" @category-select="filterSelectedCategories" :marketplace_categories="'company'" ></FilterCompanyProductCategories>
              </b-card>
                  </b-tab>
                  <b-tab title="Public">
                    <b-card class="border-radius-1em border shadow-none mb-0">
                <FilterCompanyProductCategories  :company="$attrs.company" :api_token="$attrs.user.api_token"  :category_data="category_data" @category-select="filterSelectedCategories" :marketplace_categories="'public'" ></FilterCompanyProductCategories>
              </b-card>
                  </b-tab>
                  <b-tab title="Trade">
                    <b-card class="border-radius-1em border shadow-none mb-0">
                <FilterCompanyProductCategories  :company="$attrs.company" :api_token="$attrs.user.api_token"  :category_data="category_data" @category-select="filterSelectedCategories" :marketplace_categories="'trade'" ></FilterCompanyProductCategories>
              </b-card>
                  </b-tab>
                </b-tabs>
           
              
                <h4 class="card-title mb-3">Brands</h4>
                <b-tabs pills content-class="pt-3">
                  <b-tab title="My Shops">
               <FilterProductBrands  :company="$attrs.company"  v-on:brands-filtered="filterBrands" :api_token="$attrs.user.api_token" :marketplace_brands="'false'"></FilterProductBrands>
             </b-tab>
                <b-tab title="Trade / Public">
                   <FilterProductBrands  :company="$attrs.company" v-on:brands-filtered="filterBrands" :api_token="$attrs.user.api_token" :marketplace_brands="'true'"></FilterProductBrands>
                </b-tab>
              </b-tabs>
           
               <h5 class="font-size-14 mb-3">Collections</h5>
               <FilterProductCollections  :company="$attrs.company" v-on:collections-filtered="filterCollections" :companies="$attrs.companies" :default_company="$attrs.company.id" :api_token="$attrs.user.api_token"></FilterProductCollections>
          

            </div>
           

          <!-- <div class="mt-4 pt-3">
              <h5 class="font-size-14 mb-3">Customer Rating</h5>
              <div>
                <b-form-checkbox
                  id="checkbox-1"
                  name="checkbox-1"
                  value="accepted"
                  unchecked-value="not_accepted"
                >
                  4
                  <i class="bx bx-star text-warning"></i> & Above
                </b-form-checkbox>

                <b-form-checkbox
                  id="checkbox-2"
                  class="mt-2"
                  name="checkbox-2"
                  value="accepted"
                  unchecked-value="not_accepted"
                >
                  3
                  <i class="bx bx-star text-warning"></i> & Above
                </b-form-checkbox>

                <b-form-checkbox
                  id="checkbox-3"
                  class="mt-2"
                  name="checkbox-3"
                  value="accepted"
                  unchecked-value="not_accepted"
                >
                  2
                  <i class="bx bx-star text-warning"></i> & Above
                </b-form-checkbox>
                <b-form-checkbox
                  id="checkbox-4"
                  class="mt-2"
                  name="checkbox-4"
                  value="accepted"
                  unchecked-value="not_accepted"
                >
                  1
                  <i class="bx bx-star text-warning"></i>
                </b-form-checkbox>
              </div>
            </div>-->
          </div>
        </div>
      </div>
    </SideSlidePanel>
    </div>
    <!-- end row -->
  </Layout>
</template>
