<script type="text/javascript">
	import Layout from "../../layouts/main";
	import PageHeader from "../../components/page-header";
	import TradeCartSummary from "../../components/widgets/ecommerce/TradeCartSummary";
	import TradeOrderConfirmation from "../../components/widgets/ecommerce/TradeOrderConfirmation";
	import TradeOrderSummary from "../../components/widgets/ecommerce/TradeOrderSummary";
	import { EventBus } from '../../app'
	export default{
		components: {Layout, PageHeader, TradeCartSummary, TradeOrderConfirmation, TradeOrderSummary},
		data() {
		    return {
		    		listing: null,
		    	  carduid: '',
		    	  id: '',
		      	  cart: '',
		      	  order: '',
		      	  orderconfirmuid: '',
		      	  order_paid: false,
		      	  show_order_summary: false,
		      	  show_payment_form: false,
		      	  order_created: false,
		      	  paymentResult: '',
			      title: "Order",
			      items: [
			        {
			          text: "Trade",
			          href: "/",
			        },
			        {
			          text: "Order",
			          active: true,
			        },
			      ],
			  }
	  	   },
	  	   created(){
	  	   	if(this.$attrs.id !== ''){
	  	   		this.id = this.$attrs.id;
	  	   	}else{
	  	   		this.id = '';
	  	   	}

	  	   	var session_cart = sessionStorage.getItem("tradecart");
		    session_cart = JSON.parse(session_cart);
		    console.log(session_cart);
		    if(session_cart == null){
		    	session_cart = {};
		    }
		    //var cartparams = window.sessionStorage.getItem("cartparams");
		    //cartparams = JSON.parse(cartparams);
	  	 		if(Object.keys(session_cart).length > 0){
	  	 			this.cartuid = session_cart['uid'];
	  	 			axios.get('/api/company/trade/cart/read?cart_uid='+this.cartuid, {headers: {
				            'Authorization': 'Bearer ' + this.$attrs.user.api_token
				        }}).then(response => {
				        console.log(response);
				          if(response.data !== 0){
				            this.cart = response.data;
				            window.sessionStorage.setItem("tradecart", JSON.stringify(response.data));
				          }else{
				            window.sessionStorage.setItem("tradecart", JSON.stringify(this.cart));
				          }
				        
				      }).catch(error => {
				        this.error_reponse = error.response.data.errors;
				      });
	  	 		}else{
	  	 			axios.get('/api/company/trade/cart/read?cart_uid='+this.cartuid, {headers: {
				            'Authorization': 'Bearer ' + this.$attrs.user.api_token
				        }}).then(response => {
				        console.log(response);
				          if(response.data !== 0){
				            this.cart = response.data;
				            window.sessionStorage.setItem("tradecart", JSON.stringify(response.data));
				          }else{
				            window.sessionStorage.setItem("tradecart", JSON.stringify(this.cart))
				          }
				        
				      }).catch(error => {
				        this.error_reponse = error.response.data.errors;
				      });
	  	 		}
	  	   },
	  	   mounted(){
	  	   
	  	   	let self = this;

	  	   	EventBus.$on('currency-updated', function(symbol, currency, rates){
					//self.refetchCart();  	
			    setTimeout(function(){
			    self.$refs['summary'].cartTotal(self.cart);
			  	self.$refs['summary'].companiesTotalIncVat(null, null);
					}, 500);
				});
	  	   
	  	   	EventBus.$on('process-cart', function(){
		      self.$refs['summary'].processCart();
		    });
		    EventBus.$on('cancel-payment', function(product, qty){
		      self.show_payment_form = false;
		    });
		    EventBus.$on('order-paid', function(result){
		      self.paymentResult = result;
		      if(result.status == "succeeded"){
		    	  self.order_paid = true;
		  		}else{
		  		  self.order_paid = false;
		  		}
		    });
		    EventBus.$on('order-updated', function(order, paid){
		    	self.show_payment_form = false;
		     	self.order = order;
		     	self.orderconfirmuid = order["trade_order"]["uid"];
		     	self.order_created = true;
		     	self.title = "Order Confirmation";
		     	self.destroyCart();
		    });
	  	   },
	  	   methods: {
	  	   	refetchCart(){
	  	   			var session_cart = sessionStorage.getItem("tradecart");
		    session_cart = JSON.parse(session_cart);
		    console.log(session_cart);
		    if(session_cart == null){
		    	session_cart = {};
		    }
		    //var cartparams = window.sessionStorage.getItem("cartparams");
		    //cartparams = JSON.parse(cartparams);
	  	 		if(Object.keys(session_cart).length > 0){
	  	 			this.cartuid = session_cart['uid'];
	  	 			axios.get('/api/company/trade/cart/read?cart_uid='+this.cartuid, {headers: {
				            'Authorization': 'Bearer ' + this.$attrs.user.api_token
				        }}).then(response => {
				        console.log(response);
				          if(response.data !== 0){
				            this.cart = response.data;
				            window.sessionStorage.setItem("tradecart", JSON.stringify(response.data));
				          }else{
				            window.sessionStorage.setItem("tradecart", JSON.stringify(this.cart));
				          }
				        
				      }).catch(error => {
				        this.error_reponse = error.response.data.errors;
				      });
	  	 		}else{
	  	 			axios.get('/api/company/trade/cart/read?cart_uid='+this.cartuid, {headers: {
				            'Authorization': 'Bearer ' + this.$attrs.user.api_token
				        }}).then(response => {
				        console.log(response);
				          if(response.data !== 0){
				            this.cart = response.data;
				            window.sessionStorage.setItem("tradecart", JSON.stringify(response.data));
				          }else{
				            window.sessionStorage.setItem("tradecart", JSON.stringify(this.cart))
				          }
				        
				      }).catch(error => {
				        this.error_reponse = error.response.data.errors;
				      });
	  	 		}
	  	   	},
	  	   	updateCart(cart){
	  	   		this.cart = cart[2];
	  	   		this.show_payment_form = true;
	  	   	},
	  	   	destroyCart(){
	  	   		axios.post('/api/company/trade/cart/order-created', {cartuid: uid }, {
              headers: {
                'Authorization' : 'Bearer '+this.$attrs.user.api_token
              }
            }).then(response => {
	  	   			this.cartDeleted = true;
	  	   			EventBus.$emit('clear-cart', this.cart.uid);
	  	   		}).catch(error => {
	  	   			this.cartDeleted = false;
	  	   		});
	  	   	}
	  	   }
	}

</script>

<template>
	<Layout :nickname="$attrs.nickname" :user="$attrs.user" :company="$attrs.company" :currencies="$attrs.currencies" :exchange_rates="$attrs.exchange_rates" :selected_currency="$attrs.selected_currency" :selected_currency_symbol="$attrs.selected_currency_symbol">
    <PageHeader :title="title" :items="items" />
    	<b-row>
    		<b-col md="12">
				<TradeCartSummary ref="summary" v-if="(cart !== '') && (id == '') && (order_created == false)" :user="$attrs.user" :company="$attrs.company" @update-cart="updateCart" :cart="cart" :selected_currency="$attrs.selected_currency" :selected_currency_symbol="$attrs.selected_currency_symbol" :exchange_rates="$attrs.exchange_rates" @refetch-cart="refetchCart"></TradeCartSummary>
				<!--<TradeOrderConfirmation v-if="(id == '') && (order !== '') && (order_created == true)"
				:user="$attrs.user" :company="$attrs.company" :order="order" :paid="order_paid"
				></TradeOrderConfirmation>-->
				<TradeOrderSummary v-if="(id !== '') && (order_created == false)" :uid="id" :user="$attrs.user" :company="''" :paid="order_paid" ></TradeOrderSummary>
				<TradeOrderSummary v-if="(orderconfirmuid !== '') && (order_created == true)"
				:user="$attrs.user" :company="''" :paid="order_paid" :uid="orderconfirmuid"
				></TradeOrderSummary>
				<!--<a class="btn btn-success" v-if="step == 0, title = 'Payment', items[1].text = 'Payment'" @click="step++">Process Order</a>-->
			</b-col>
		</b-row>
	</Layout>

</template>