<script>
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import VueSlideBar from "vue-slide-bar";
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
import CompanyProductSearch from '../../components/widgets/CompanyProductSearch';
import CompanyProductList from '../../components/widgets/CompanyProductList';
import SearchCompanyLocation from '../../components/widgets/SearchCompanyLocation';
import SideSlidePanel from "../../components/widgets/SideSlidePanel";
import { shippingStatuses } from "./shippingStatuses";
import simplebar from "simplebar-vue";
import Swal from "sweetalert2";
import Calendar from "../service/calendar"

export default{
	components:{VueSlideBar, Layout, PageHeader, SideSlidePanel, ckeditor: CKEditor.component, CompanyProductSearch, CompanyProductList, SearchCompanyLocation, simplebar, Calendar},
	data() {
    	return {
          csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
          editor: ClassicEditor,
          shippingStatuses: shippingStatuses,
          storageurl: 'https://'+this.$attrs.company.nickname+'.s3.eu-west-2.amazonaws.com',
          selected_shipping: '',
          shipping_apikeys: '',
          selectedShippingStatus: '',
          all_carrier_integrations: '',
          active_carrier_integrations: '',
          loadingDeliveryBooking: '',
          loadingTracking: '',
    		  brandsData: [],
          deliveriesData: [],
          products_search: '',
          product_to_transfer: '',
          transfer_to_search: '',
    		  categoryProducts: [],
    		  selectedCategory: [],
    		  brands_error: [],
    		  products_error: [],
          addProductToggle: false,
          error_reponse: [],
          error_message: false,
          success_message: false,
          success_text: '',
          loadingForm: false, 
          loadingList: false,
    		  view: 'list',
    		  search: '',
    		  limit: '25',
    		  sortby: 'collection_date',
    		  order: 'desc',
          currentPage: 1,
          currentProductPage: 1,
    		  selectedids: [],
          transfer: {
            transfer_from: '',
            transfer_to: '', 
            date_required: '',
            date_requested: '',
            products: [],
          },
          editcollection: {
            name: '',
            description: '',
            image: '',
            cover_image: '',
            order: '',
            slug: '',
            active: 0,
          },
          isCheckAll: false,
          addNewPanel: false,
          editPanel: false,
          addPanelClass: 'edit-sidepanel-large',
          expandable: 0,
    		  getProductsPanel: 0,
    		  selected_parent: {id: null},
    		  limits: [
		        {value: 25, text: "25"},
		        {value: 50, text: "50"},
		        {value: 100, text: "100"},
		      ],
		      title: "Transfers",
		      items: [
		        {
		          text: "Stock",
		          href: "javascript:;",
		        },
		        {
		          text: "Transfers",
		          active: true,
		        },
		      ],
  				}	
	},
	methods:{
    getCourierApiKey(){
          if((this.shipping_apikeys == '')){
            axios.get('/api/company/delivery-links', {headers: {
              'Authorization' : 'Bearer '+this.$attrs.user.api_token
            }}).then(response => {
                      this.shipping_apikeys = response.data;
                        //console.log(this.shipping_apikeys.token);
                        return this.getAllShippingIntegrations();
                    }).then(error => {
                      console.log(error);
                    });
          }
      },
      getAllShippingIntegrations(){
        this.getShippingIntegrations();
        this.getActiveShippingIntegrations();
      },
      getShippingIntegrations(){
        this.loadingDeliveryBooking = true;
        if(this.all_carrier_integrations.length == 0){
          axios.get('https://production.courierapi.co.uk/api/couriers/v1/list-couriers', { headers: { 
              'api-user': this.$attrs.company.nickname, 
              'api-token': this.shipping_apikeys.token,
              'Content-Type': 'application/json'
          }}).then(response => {
            console.log(response.data);
            this.all_carrier_integrations = response.data.couriers;
            this.loadingDeliveryBooking = false;
          }).catch(error => {
            console.log(error.data);
          });
        }
      },
      getActiveShippingIntegrations(){
        this.loadingDeliveryBooking = true;
        if(this.all_carrier_integrations.length == 0){
          axios.get('https://production.courierapi.co.uk/api/couriers/v1/list-registered-couriers', { headers: { 
              'api-user': this.$attrs.company.nickname, 
              'api-token': this.shipping_apikeys.token,
              'Content-Type': 'application/json'
          }}).then(response => {
            console.log(response.data);
            this.active_carrier_integrations = response.data;
            this.loadingDeliveryBooking = false;
          }).catch(error => {
            console.log(error.data);
          });
        }
      },
    addProductToTransfer(product){
      var exists = this.transfer.products.findIndex(item => item.id == product.id);
      if(exists > -1){
        this.transfer.products[exists].transfer_quantity += product.transfer_quantity;
      }else{
        this.transfer.products.push(product);
      }
      this.$bvModal.hide('quantity-modal'); 
    },
    getListingsQuantity(id, listings){
      var listing = listings.filter(item => item.id == id);
      return listing[0].quantity;
    },
    selectFromListing(payload){
      this.transfer.transfer_from = payload;
      this.getProducts(payload.uid);
    },
    selectToListing(payload){
      this.transfer.transfer_to = payload;
    },
    removeFromResource(product){
            axios.post('/api/company/product/collection/'+this.selectedCategory.id+'/remove-product', {product_id: product.uid}, {
              headers: {
                'Authorization' : 'Bearer '+this.$attrs.user.api_token
              }
            }).then(response => {
              this.$emit('product-removed', response.data);
              this.success_text = 'Product Removed from Collection';
              this.success_message = true;
              this.getProducts(this.selectedCategory.id);
              let self = this;
              setTimeout(function(){
                self.success_message = false;
              }, 1000);
            }).catch(error => {
              this.$emit('resource-error', error.data);
            });
    },
		transfersQuery(){
			axios.get('/api/company/product/transfers?&page='+this.currentPage+'&limit='+this.limit+'&sortby='+this.sortby+'&order='+this.order+'&search='+this.search+'&parent_id='+this.selected_parent.id+'&active=&public_active=&trade_active=', {headers : {
        'Authorization' : 'Bearer '+this.$attrs.user.api_token
      }}).then(response => {
				this.brandsData = response.data;
			}).catch(error => {
				this.brands_error = error.data;
			});
		},
    deliveriesQuery(){
      axios.get('/api/company/order/deliveries?&page='+this.currentPage+'&limit='+this.limit+'&sortby='+this.sortby+'&order='+this.order+'&search='+this.search+'&parent_id='+this.selected_parent.id+'&active=&public_active=&trade_active=', {headers : {
        'Authorization' : 'Bearer '+this.$attrs.user.api_token
      }}).then(response => {
        this.deliveriesData = response.data;
      }).catch(error => {
        this.brands_error = error.data;
      });
    },
		getProducts(category_id){
      this.categoryProducts = [];
      this.loadingForm = false;
			axios.get('/api/company/product/collection/'+category_id+'/products',  { headers : {
        'Authorization': 'Bearer ' + this.$attrs.user.api_token
      } }).then(response => {
        this.categoryProducts = response.data;
         let self = this;
          setTimeout(function(){
            self.loadingForm = false;
          }, 500);
      }).catch(error => {
        this.products_error = error.data;
      });
		},
    getShippingImage(name){
        var integrations = this.all_carrier_integrations.filter(item => item.key == name);
        if(integrations.length == 0){
          return null
        }else{
          return integrations[0].logo;
        }
      },
    productTransferQuantity(product){
      if(this.transfer.transfer_to !== ''){
        this.product_to_transfer = JSON.parse(JSON.stringify(product));
        this.product_to_transfer['transfer_quantity'] = 1;
        this.$bvModal.show('quantity-modal'); 
      }else{
        Swal.fire({
            position: "top-end",
            icon: "error",
            title: "You must add a listing to transfer to",
            showConfirmButton: false,
            timer: 1500
          });
      }
    },
    getCalDeliveryStatus(shipping_id){
      var project = this.deliveriesData.data.filter(item => (item.id == shipping_id));
      this.getDeliveryStatus(project[0]);
    },
    getDeliveryStatus(shipping){
        this.loadingTracking = true;
        this.selected_shipping = shipping;
        axios.get('https://production.courierapi.co.uk/api/shipments.json?dc_request_id='+shipping.dc_request_id, { headers: { 
              'api-user': this.$attrs.company.nickname, 
              'api-token': this.shipping_apikeys.token,
              'Content-Type': 'application/json'
          }}).then(response => {
          this.selectedShippingStatus = response.data;
          return this.loadingTracking = false;
        }).catch(error => {

        });
      },
    bookTransfer(){
      this.loadingForm = true;
      var postForm = new FormData(document.getElementById("booktransfer"));
      postForm.append('products', this.transfer.products);
      axios.post('/api/company/product/transfer/create', postForm, { headers : {
        'Authorization': 'Bearer ' + this.$attrs.user.api_token
      } }).then(response => {
        console.log(response);
        this.success_text = 'Congratulations, your new category has been added.';
        this.success_message = true;
        //this.resetProjectData();
        //this.addPanel = false;
        let self = this;
          setTimeout(function(){
            self.loadingForm = false;
            self.addNewPanel = false;
            self.deliveriesQuery();
          }, 1500);
      }).catch( error => {
        
        this.error_reponse = error.response.data.errors;
        this.error_message = true;
        //this.resetProjectData();
        let self = this;
          setTimeout(function(){
            self.loadingForm = false;
          }, 1500);
      });
    },
    getShippingStatusText(status_id){
        var status = this.shippingStatuses.filter(item => item.id == status_id);
        return status[0].text;
    },
    updateCollection(){
      this.success_message = false;
      this.error_message = false;
      this.loadingForm = true;
      var postForm = new FormData(document.getElementById("updatecollectionform"));
      axios.post('/api/company/product/collection/'+this.editcollection.id+'/update', postForm,  { headers : {
        'Authorization': 'Bearer ' + this.$attrs.user.api_token
      } }).then(response => {
        console.log(response);
        this.success_text = 'Congratulations, your category has been updated.';
        this.success_message = true;
        //this.resetProjectData();
        this.addPanel = false;
        let self = this;
          setTimeout(function(){
            self.loadingForm = false;
            self.editPanel = false;
            self.deliveriesQuery();
          }, 500);
      }).catch( error => {
        
        this.error_reponse = error.response.data.errors;
        this.error_message = true;
        //this.resetProjectData();
        let self = this;
          setTimeout(function(){
            self.loadingForm = false;
          }, 500);
      });

    },
    getProducts(id){
      axios.get('/api/company/listing/'+id+'/products', { headers : {
        'Authorization': 'Bearer ' + this.$attrs.user.api_token
      } }).then(response => {
        this.categoryProducts = response.data;
        //this.showListingProducts = 1;
      }).catch(error => {
        this.products_error = error.data;
      });
    },
    showStatusMessage(result){
      if(result == false){
        this.error_reponse = {error: ["Product Already Exists in Category"]};
        this.error_message = true;
      }else{
        this.success_message = true;
        this.success_text = 'Congratulations, your action has been saved.';
        this.getProducts(this.selectedCategory.id)
      }
    },
		priceIncVat(price, tax_percentage){
			var tax = 1 + (tax_percentage / 100);
			return price * tax;
		},
    filterSelected(id){
        if(this.selectedids.length > 0){
          var exists = this.selectedids.filter(item => item == id);
          if(exists.length > 0){
            return true;
          }else{
            return false
          }
        }else{
          return false;
        }
      },
      checkAll: function(){ 
                 this.isCheckAll = !this.isCheckAll; 
                 this.selectedids = []; 
                 if(this.isCheckAll){ 
                     // Check all 
                     for (var key in this.brandsData.data) {          
                        this.selectedids.push(this.brandsData.data[key].id); 
                     } 
                 } 
              }, 
      updateCheckall: function(){ 
                 if(this.selectedids.length == this.brandsData.data.length){
                    this.isCheckAll = true; 
                 }else{ 
                    this.isCheckAll = false; 
                 } 
      },
      sanitizeTitle: function(title) {
            var nickname = "";
            // Change to lower case
            var titleLower = title.toLowerCase();
            // Letter "e"
            nickname = titleLower.replace(/e|é|è|ẽ|ẻ|ẹ|ê|ế|ề|ễ|ể|ệ/gi, 'e');
            // Letter "a"
            nickname = nickname.replace(/a|á|à|ã|ả|ạ|ă|ắ|ằ|ẵ|ẳ|ặ|â|ấ|ầ|ẫ|ẩ|ậ/gi, 'a');
            // Letter "o"
            nickname = nickname.replace(/o|ó|ò|õ|ỏ|ọ|ô|ố|ồ|ỗ|ổ|ộ|ơ|ớ|ờ|ỡ|ở|ợ/gi, 'o');
            // Letter "u"
            nickname = nickname.replace(/u|ú|ù|ũ|ủ|ụ|ư|ứ|ừ|ữ|ử|ự/gi, 'u');
            // Letter "d"
            nickname = nickname.replace(/đ/gi, 'd');
            // Trim the last whitespace
            nickname = nickname.replace(/\s*$/g, '');
            // Change whitespace to "-"
            nickname = nickname.replace(/\s+/g, '-');
            // Remove quotations
            nickname = nickname.replace(/["']/g, "");
            // Remove symbols
            nickname = nickname.replace(/@|#|!/g, '');
            return nickname;
        }
	},
	created(){
		//this.deliveriesQuery();
    this.deliveriesQuery();
    this.getCourierApiKey();
  }
}
</script>

<template>
	<Layout>
		<PageHeader :title="title" :items="items" />
		    <div class="row">
          <b-col md="12">
                    <transition name="slide-success" mode="out-in">
                  <b-alert id="success-message" variant="success" :show="success_message"  dismissible @dismissed="success_message = !success_message">
                      <p class="font-size-14">{{success_text}}</p>
                    </b-alert>
                  </transition>
                  </b-col>
      <div class="col-md-12">
 <div class="row mb-3">
          <div class="col-xl-1 col-sm-6">
            <div class="mt-2">
              <b-form-select class="form-control" v-model="limit" :options="limits" @input="deliveriesQuery()"></b-form-select>
            </div>
          </div>
         
          <div class="col-lg-2 col-sm-6 d-flex">
          <form class="my-auto">
            <small class="mx-auto my-2 text-center d-block">Results {{brandsData.from}} - {{brandsData.to}} of {{brandsData.total}}</small>
          </form>
        </div>
        <div class="col-lg-3">
         
        </div>
        <div class="col-lg-3 col-sm-6 d-flex">
          
            <div class="mt-sm-0 form-inline w-100" >
              <div class="search-box mr-2 ml-auto">
                <div class="position-relative">
                  <input
                    type="text"
                    class="form-control w-100 border-0"
                    placeholder="Search name, reference or description..."
                    v-model="search"
                    @keyup.enter="deliveriesQuery()"
                  />
                  <i class="bx bx-search-alt search-icon"></i>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-sm-6 d-flex">
            <div class="d-flex ml-auto">
              <ul class="nav nav-pills product-view-nav">
                
                <li class="nav-item">
                  <a class="nav-link" :class="{'active': view == 'grid' }" href="javascript: void(0);" @click="view = 'grid'">
                    <i class="bx bx-grid-alt"></i>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" :class="{'active': view == 'calendar' }" href="javascript: void(0);" @click="limit = 0, view = 'calendar'">
                    <i class="bx bx-calendar-alt"></i>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" :class="{'active': view == 'list' }" href="javascript: void(0);" @click="view = 'list'">
                    <i class="bx bx-list-ul"></i>
                  </a>
                </li>
                <li class="nav-item">
                 <b-dropdown text="Bulk Actions" variant="primary" class="rounded">
                  <b-dropdown-item href="javascript: void(0);">Action</b-dropdown-item>
                  <b-dropdown-item href="javascript: void(0);">Another action</b-dropdown-item>
                  <b-dropdown-item href="javascript: void(0);">Something else here</b-dropdown-item>
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-item href="javascript: void(0);">Separated link</b-dropdown-item>
                </b-dropdown>
                </li>
                <li class="nav-item">
                 <a
                    class="btn btn-success btn-rounded mb-2 mr-2 text-white"
                    href="javascript:;"
                    @click="addNewPanel = !addNewPanel"
                  >
                    <i class="mdi mdi-plus mr-1"></i> New
                  </a>
                </li>
              </ul>
              <div class="text-sm-right">
                  
                </div>
          </div>
        </div>
      </div>
      <div class="card" v-if="view == 'calendar'" >
          <div class="card-body p-0">
       <div class="row" >
                <calendar :meetings="deliveriesData.data" :type="'delivery'" @edit-event="getCalDeliveryStatus" ></calendar>

              </div>
            </div>
          </div>
        <div class="card" v-if="view == 'list'" >
          <div class="card-body p-0">
            <div class="table-responsive">
              <table class="table table-centered table-hover">

                <thead class="thead-light">
                  <tr>
                    <th scope="col"  style="width: 100px" class="text-center"><div
                        class="form-check form-switch form-switch-sm mb-0 mx-auto p-0 mt-auto d-flex"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input mx-auto my-auto" 
                          type="checkbox"
                          id="SwitchCheckSizesm"
                         
                          v-model="isCheckAll"
                          @click='checkAll()'
                          name="active"
                        />
                    </div></th>
                    <th scope="col">Booking Id</th>
                    <th scope="col" style="max-width: 150px;">Ship From</th>
                    <th></th>
                    <th scope="col" style="max-width: 150px;">Ship To</th>
                    <th scope="col">Booked With Courier</th>
                    <th scope="col">Date Booked</th>
                    <th scope="col">Tracking Code</th>
                    <!--<th scope="col">Sent</th>
                    <th scope="col">Received</th>-->
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
               
                <tbody>
                  <tr v-for="shipping in deliveriesData.data">
                    <td valign="middle">
                       <div class="text-center" v-if="getShippingImage(shipping.courier)">
                      <b-form-checkbox class="select-row-btn" v-model="selectedids" :value="shipping.id" button-variant="primary" @input="updateSelected" button>
                        <i v-if="filterSelected(shipping.id)" class="bx bx-check"></i>
                        <img v-else :src="getShippingImage(shipping.courier)" alt class="avatar-sm rounded-circle" />
                      </b-form-checkbox>
                    </div>
                    <div class="text-center" v-else >
                        <b-form-checkbox class="select-row-btn" v-model="selectedids" :value="shipping.id" button-variant="primary" @input="updateSelected" button>

                          <i v-if="filterSelected(shipping.id)" class="bx bx-check"></i><span class="avatar-title rounded-circle" v-else><i class="bx bx-calendar"></i></span></b-form-checkbox>
                    </div> 
                    </td>
                     <td valign="middle" class="my-auto">
                        <span>#{{shipping.dc_request_id}}</span>
                      </td>
                    <td valign="middle"  style="max-width: 150px;">
                      <div v-if="shipping.shipping_details !== null">
                      <span v-if="shipping.shipping_details.ship_from_address_1">{{shipping.shipping_details.ship_from_address_1}}, </span>
                      <span v-if="shipping.shipping_details.ship_from_address_2">{{shipping.shipping_details.ship_from_address_2}}, </span>
                      <span v-if="shipping.shipping_details.ship_from_address_3">{{shipping.shipping_details.ship_from_address_3}}, </span>
                      <span v-if="shipping.shipping_details.ship_from_address_county">{{shipping.shipping_details.ship_from_address_county}}, </span>
                      <span v-if="shipping.shipping_details.ship_from_address_postcode">{{shipping.shipping_details.ship_from_address_postcode}}, </span>
                      <span v-if="shipping.shipping_details.ship_from_country_iso">{{shipping.shipping_details.ship_from_country_iso}}, </span>
                      </div>
                      <div v-else>
                      <span class="text-muted">No Addess Information</span>
                      </div>
                    </td>
                    <td valign="middle">
                      <div class="d-flex flex-column justify-content-center align-items-center">
                        <i class="bx bx-right-arrow font-size-20 text-primary my-auto"></i>
                      </div>
                    </td>
                    <td valign="middle"  style="max-width: 150px;">
                      <div v-if="shipping.shipping_details !== null">
                      <span v-if="shipping.shipping_details.ship_to_address_1">{{shipping.shipping_details.ship_to_address_1}}, </span>
                      <span v-if="shipping.shipping_details.ship_to_address_2">{{shipping.shipping_details.ship_to_address_2}}, </span>
                      <span v-if="shipping.shipping_details.ship_to_address_3">{{shipping.shipping_details.ship_to_address_3}}, </span>
                      <span v-if="shipping.shipping_details.ship_to_address_county">{{shipping.shipping_details.ship_to_address_county}}, </span>
                      <span v-if="shipping.shipping_details.ship_to_address_postcode">{{shipping.shipping_details.ship_to_address_postcode}}, </span>
                      <span v-if="shipping.shipping_details.ship_to_country_iso">{{shipping.shipping_details.ship_to_country_iso}}, </span>
                      </div>
                      <div v-else>
                      <span class="text-muted">No Addess Information</span>
                      </div>
                    </td>
                     
                       <td valign="middle" class="my-auto">
                        <span>{{shipping.courier}}</span>
                      </td>
                      <td valign="middle" class="my-auto">
                        <span v-if="shipping.collection_date !== null">{{shipping.collection_date | luxon({output: 'meds'}) }}</span>
                      </td>
                      <td valign="middle" >
                        <span class="badge badge-light bg-light font-size-12"># {{shipping.delivery_reference}}</span>
                      </td>
                      <!--<td valign="middle">
                        <a class="btn btn-outline-light btn-rounded" >Download Label <i class="mdi mdi-download"></i></a>
                     </td>
                     <td valign="middle">
                        <a class="btn btn-outline-light btn-rounded" >Open & Print Label <i class="bx bx-printer"></i></a>
                      </td>-->
                      <td valign="middle">
                        <a class="btn btn-primary btn-rounded" v-b-modal.action-modal  @click="getDeliveryStatus(shipping)" >Delivery Details & Tracking <i class="bx bx-print"></i></a>
                      </td>
                    </tr>
                  </tbody>
                </table>
            
            </div>
            
            <div class="row">
              <div class="col-lg-12">
                <b-pagination
              v-if="deliveriesData.data.length > 0"
              class="justify-content-center"
              pills
              v-model="currentPage"
              :total-rows="deliveriesData.total"
              :per-page="deliveriesData.per_page"
              aria-controls="my-table"
              @input="deliveriesQuery"
            ></b-pagination>
             <small class="mx-auto my-2 text-center d-block">Records {{deliveriesData.from}} - {{deliveriesData.to}} of {{deliveriesData.total}} // Page {{deliveriesData.current_page}} of {{deliveriesData.last_page}}</small>
              </div>
            </div>
          </div>
      </div>
<b-modal id="action-modal" centered :size="'xl'" centered content-class="border-radius-1em" header-class="border-none" hide-footer scrollable >
<b-card>
        <b-overlay :show="loadingTracking">
           
              <b-row v-if="selected_shipping !== ''">
                  <b-col md="12">
                     <b-card class="shadow-none border-radius-1em border">
                          <h4 class="text-left">Shipping Reference: #{{selected_shipping.uid}}</h4>
                    </b-card>
                </b-col>
                <b-col md="6">
              <b-card class="shadow-none border-radius-1em border">
                <h4><i class="bx bxs-truck text-primary mr-2"></i>Courier:</h4>
                {{selectedShippingStatus.shipments[0].courier}}
              </b-card>
                </b-col>

                <b-col md="6">
                <b-card class="shadow-none border-radius-1em border">
                  <h4><i class="bx bx-calendar-alt text-primary mr-2"></i> Collection Date:</h4>
                  {{selected_shipping.collection_date}}
                </b-card>
              </b-col>
              <b-col md="12">

                <b-card class="shadow-none border-radius-1em border" body-class="d-flex">
                  <div class="w-50">
                  <h4><i class="bx bx-note text-primary mr-2"></i> Label</h4>
                    <p v-if="packingInfo" class="bg-info bg-soft p-2 text-info mb-2 border-3">
                      Scan this code once your parcel is packed to mark the parcel packed and ready to be collected by your courier.
                    </p>
                </div>
                <div class="w-25 ml-auto">
                  <qrcode-vue :value="JSON.stringify({type: 'delivery-packed', shipping_reference: selected_shipping.reference, company_id: $attrs.company.id, parcel_index: pindex})" :size="120" level="L" foreground="#000000" background="#ffffff" margin="0" class="ml-auto px-1"/>
                      <small @click="packingInfo = !packingInfo">Packed Code <i class="bx bx-info-circle text-info"></i></small>
                  </div>
                  <div class="w-25">
                    <a class="btn btn-primary btn-rounded btn-block mb-2"  @click="markPackedTracked(selectedShippingStatus.shipments[0].reference)">Book Collection</a>
                    <a href="javascript:;" class="btn btn-info btn-rounded btn-block mb-2" @click="openFile(selected_shipping.delivery_label_url)" >
                       Mark Packed
                    </a>
                    <a href="javascript:;" class="btn btn-primary btn-rounded btn-block mb-2" @click="openFile(selected_shipping.delivery_label_url)" >
                       Download Label
                    </a>
                    <a class="btn btn-success btn-rounded btn-block" @click="renewLabelRequest(selectedShippingStatus.shipments[0].label_key)">Refetch Label</a>
                  </div>
                </b-card>
            </b-col>
                <b-col md="6">
                <b-card class="shadow-none border-radius-1em border" v-if="selected_shipping.shipping_details !== null">
                <h4><i class="bx bx-store text-primary mr-2"></i> Shipped From:</h4>
                {{selected_shipping.shipping_details.ship_from_address_1}}
                {{selected_shipping.shipping_details.ship_from_address_2}}
                {{selected_shipping.shipping_details.ship_from_address_3}}
                {{selected_shipping.shipping_details.ship_from_county}}
                {{selected_shipping.shipping_details.ship_from_postcode}}
                {{selected_shipping.shipping_details.ship_from_country}}
              </b-card>
            </b-col>

                <b-col md="6">
              <b-card class="shadow-none border-radius-1em border" v-if="selected_shipping.shipping_details !== null">
                <h4><i class="bx bx-home-alt text-primary mr-2"></i> Shipped To:</h4>
                {{selected_shipping.shipping_details.ship_to_address_1}}
                {{selected_shipping.shipping_details.ship_to_address_2}}
                {{selected_shipping.shipping_details.ship_to_address_3}}
                {{selected_shipping.shipping_details.ship_to_county}}
                {{selected_shipping.shipping_details.ship_to_postcode}}
                {{selected_shipping.shipping_details.ship_to_country}}
              </b-card>
            </b-col>
            <b-col md="12">
              
            </b-col>
                <b-col md="12">
                <b-card class="shadow-none border-radius-1em border">

                  <h4><i class="bx bx-cube-alt text-primary mr-2"></i> Parcels</h4>

                  <p v-if="bookingInfo" class="bg-info bg-soft p-2 text-info mb-2 border-3">
                      Add this code to your parcel to enable booking in of stock at another store.
                    </p>
                <b-list-group>
                  <b-list-group-item v-for="(parcel, pindex) in selected_shipping.parcels" class="d-flex flex-column">
                    <div class="d-flex">
                    <h5 class="mt-2 mr-4"><i class="bx bx-cube-alt text-primary mr-2"></i> Parcel {{pindex + 1}}</h5>
                    <!--<p class="mt-2">Dimensions: W {{parcel.dim_width}}{{parcel.dim_unit}} x H {{parcel.dim_height}}{{parcel.dim_unit}} x L  {{parcel.dim_length}}{{parcel.dim_unit}}<br>
                    Weight: {{parcel.weight}} {{parcel.weight_unit}}-->

                    
                  </p>
                    <div class="d-flex flex-column ml-auto">
                    <qrcode-vue :value="JSON.stringify({type: 'delivery-booking-in', shipping_reference_2: order.uid, shipping_reference: selected_shipping.uid, company_id: $attrs.company.id, parcel_index: pindex})" :size="150" level="L" foreground="#000000" background="#ffffff" margin="0" class="ml-auto px-1"/>
                      <small @click="bookingInfo = !bookingInfo">Booking in Code <i class="bx bx-info-circle text-info"></i></small>
                                        
                    </div>
                  </div>
                  <div class="d-flex flex-column">
                    <h5 class="mt-3">{{parcel.items.length}} product(s) in this parcel: </h5>
                    <b-list-group flush class="mt-3">
                      <b-list-group-item v-for="item in parcel.items" class="px-0">
                        <b-row>
                          <b-col md="3" class="d-flex flex-column justify-content-center">
                            #{{item.product.reference}}
                          </b-col>
                          <b-col class="d-flex flex-column justify-content-center" md="6">
                            <p class="text-truncate my-0">{{item.product.name}}</p>
                          </b-col>
                          <b-col class="d-flex flex-column justify-content-center" md="1">
                            {{item.quantity}}
                          </b-col>
                          <b-col class="d-flex justify-content-center" md="2">
                            <span class="currency">£</span>{{parseFloat(item.value).toFixed(2)}}
                          </b-col>
                        </b-row>
                      </b-list-group-item>
                    </b-list-group>
                  </div>
                  </b-list-group-item>
                </b-list-group>
                </b-card>
                </b-col>
                  <b-col md="12">
                    <b-card class="border-radius-1em border shadow-none">
                        <h5 class="font-size-16">Status:</h5>
                        <hr>
                        <span class="">{{getShippingStatusText(selectedShippingStatus.shipments[0].tracking_status_code)}}</span>
                    </b-card>
                  </b-col>
                </b-row>
      
          
          
        </b-overlay>
      </b-card>
      </b-overlay>
    </b-modal>
    </div>
</div>


</Layout>
</template>