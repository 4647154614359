<script type="text/javascript">
	import simplebar from 'simplebar-vue'
	export default {
		components: {simplebar},
		props: {
			user: {
				type: Object
			},
			resource: {
		        type: String,
		        default: () => '',
		      },
		      resource_id: {
		        type: Number,
		        default: () => '',
		      },
		      api_token: {
		        type: String,
		        default: () => '',
		      },
		      resource_route: {
		        type: String,
		        default: () => '',
		      },
		      view: {
		        type: String,
		        default: () => 'list',
		      },
		      thread_list: {
		        type: Array,
		        default: () => '',
		      },
		      expanded:{
		      	type: Number,
		      	default: () => 0
		      }
		  },
		  data(){
			return{
				supportStatuses: [{text: "Awaiting Receipt", value: 1},{text: "Awaiting Seller Response", value: 2},{text: "In Progress", value: 3},{text: "Resolved", value: 4},{text: "Completed", value: 5},{text: "Archived", value: 6}],
		        quickviewproduct: '',
		        active_thread: '',
		        thread_messages: [],
		        success_message: false,
		        success_messagetext: '',
		        echoChannel: '',
		        loadingMessages: false,
		        data: {
		        	thread_uid: '',
		        	support_type: '',
							support_order_id: '',
							support_contact: '',
							status: 'seller-response',
							name: '',
							email: '',
							telephone: '',
							mobile: '',
							subject: '',
							message: '',
							parent_id: '',
							customer_id: '',
		        },
		        support_types: [
					{
						text: 'Order Enquiry', value: 'order'
					},
					{
						text: 'Order Cancellation', value: 'cancellation'
					},
					{
						text: 'Delivery Issue', value: 'delivery'
					},
					{
						text: 'Product Enquiry', value: 'product'
					},
					{
						text: 'Technical Issue', value: 'tech'
					},
					{
						text: 'Account Issue', value: 'account'
					},
					{
						text: 'Pre-Sales Question', value: 'presales'
					}
				],
				statuses: [
					{
						text: 'Awaiting Customer Response', value: 'customer-response'
					},
					{
						text: 'Awaiting Seller Response', value: 'seller-response'
					},
					{
						text: 'Completed', value: 'completed'
					},
					{
						text: 'Archived', value: 'archived'
					},
				]
								}
		  },
		  methods: {
		  	sendReply(originalmessage){
		  			if(this.data.message.length > 0){	
		  				this.loadingMessages = true;
		  				this.data.thread_uid = originalmessage.uid;
		  				this.data.support_type = originalmessage.support_type;
							this.data.support_order_id = originalmessage.support_order_id;
							this.data.support_contact = originalmessage.support_contact;
							this.data.name = originalmessage.name;
							this.data.email = originalmessage.email;
							this.data.telephone = originalmessage.telephone;
							this.data.subject = originalmessage.subject;
							this.data.customer_id = this.activeThread.customer.id;
							this.data.parent_id = this.activeThread.id;
							axios.post('/customer-api/user/message/send-reply', {data: this.data, customer: this.activeThread.customer, company: this.activeThread.company } , {
								headers: {'Authorization' : 'Bearer '+this.user.api_token}
							}).then(response => {

								
								this.success_messagetext = "Your message has been sent";
								//this.activeThread.thread_items.push(response.data);
								this.getConversation(this.$route.query.uid);
								let self = this;
								setTimeout(function(){
									self.loadingMessages = false;
									self.success_message = true;
									
								}, 150);
							}).catch(error => {
								this.error_reponse = error.response.error.errors;
								let self = this;
								setTimeout(function(){
									self.loadingMessages = false;
									self.error_message = true;
									
								}, 150);
							});
					}
		  	},
		  	async uncollapseThread(thread){
		  		this.thread_messages = await this.getConversation(thread.uid);
		  		/*if(thread.from == 'company'){
		  			this.$emit('mark-read', [thread.id, true]);
		  		}*/
		  		//var data = '{uid : ['+thread.tread_start+']}';
		  		this.$router.push({ query:  {uid : thread.uid}});
		  		//history.pushState(data, '', "?uid="+thread.thread_start.id);
		  			this.echoChannel = window.Echo.channel('chat.support.public.'+this.cthread_uid+'');
        						this.echoChannel.listen('.App\\Events\\NewSupportMessage', (e) => {
								this.getConversation(thread.uid);
				    		});
		  	},
		  	getConversation(uid){
		  		axios.get('/customer-api/user/message/'+uid, {
								headers: {'Authorization' : 'Bearer '+this.user.api_token}
							}).then(response => {
								console.log(response.data);
		  			this.thread_messages = response.data;
		  			let self = this;
					setTimeout(function(){
		  					var container = document.querySelector('#containerElement .simplebar-content-wrapper');
							var containerHeight = document.querySelector('#containerElement .simplebar-content-wrapper .simplebar-content');
							container.scrollTo({ top: (containerHeight.offsetHeight), behavior: "smooth" });
					}, 500);


		  		}).catch(error => {
		  			return error;
		  		});
		  	},
		  	getSupportType(value){
		  		var support_item = this.support_types.filter(item => item.value == value);
		  		if(support_item.length > 0){
			  		return support_item[0].text;
			  	}else{
			  		return '';
			  	}

		  	},
		  	removeResource(){

		  	},
		  	priceIncVat(price, tax_percentage){
		      var tax = 1 + (tax_percentage / 100);
		      return price * tax;
		    },
		    collapseThread(){
		    
									this.active_thread = '';
		    },
		    async getActiveThread(uid){
		    	if(uid !== ''){
		    	//this.active_thread = this.thread_list.filter(item => item.uid == uid)[0];
		    	//return this.thread_list.filter(item => item.uid == uid)[0];
		    		const messages = await this.getConversation(uid);
		    		this.thread_messages = messages;
		    	}

		    },
		    getMessageFrom(index, thread){
		    	return thread[index]["from"];
		    }
		  },
		  /*mounted(){
		  	if(Object.keys(this.$route.query).includes('uid')){
		  		console.log(this.$route.query.uid);
		  					this.active_thread = this.thread_list[this.$route.query.uid];
		  	}else{
						  		this.active_thread = '';
						  	}
		  },*/
		  /*created(){
		  	if(Object.keys(this.$route.query).includes('uid')){
		  		console.log(this.$route.query.uid);
		  		//this.getActiveThread(this.$route.query.uid);
		  		this.active_thread = this.thread_list[this.$route.query.uid];
		  	}else{
		  		this.active_thread = '';
		  	}
		  },*/
		  created(){
		  	this.echoChannel = window.Echo.channel('chat.support.public.'+this.$route.query.uid+'');
        						this.echoChannel.listen('.App\\Events\\NewSupportMessage', (e) => {
								this.getActiveThread(this.$route.query.uid);
				    		});
		  },
		  computed: {
		   activeThread(){
		  		if(Object.keys(this.$route.query).includes('uid')){
		  		console.log(this.$route.query.uid);
		  					 //this.active_thread = this.thread_list[this.$route.query.uid];
		  					 var threads = JSON.parse(JSON.stringify(this.thread_list.filter(item => item.uid == this.$route.query.uid)[0]));
		  					 this.getActiveThread(this.$route.query.uid);
		  					//var threads = await this.getConversation(this.$route.query.uid);
		  					 return threads;
		  				}else{
						  		//this.active_thread = '';
						  		return '';
						  	}
		  		
		  	},
		  	threadMessages(){
		  		return this.getConversation(this.$route.query.uid);
		  	}
		  },
		  watch: {
		  	$route() {
			  		this.$nextTick(() => {
								//this.cardExpanded = window.location.href;
								
								if(Object.keys(this.$route.query).includes('uid')){
									console.log(this.$route.query.uid);
						  		//this.getActiveThread(this.$route.query.uid);
						  		this.getActiveThread(this.$route.query.uid);
						  		var container = document.querySelector('#containerElement .simplebar-content-wrapper');
									var containerHeight = document.querySelector('#containerElement .simplebar-content-wrapper .simplebar-content');
									container.scrollTo({ top: (containerHeight.offsetHeight + 200), behavior: "smooth" });

									this.echoChannel = window.Echo.channel('chat.support.'+support_source+'.'+this.cthread_uid+'');
        						this.echoChannel.listen('.App\\Events\\NewSupportMessage', (e) => {
								this.getActiveThread(this.$route.query.uid);
				    		});
						  	}else{
						  		this.active_thread = '';
						  	}
								
						});
		  	}
		  }
	}
</script>

<template>
	<b-row>
		<transition name="fade-left">

		</transition>
		<b-col md="12" class="mt-4">
				<b-list-group class="list-group product-list w-100 no-shadow" v-if="(thread_list.length !== 0) && (view == 'list')">
								<transition name="fade-top">
									<div  v-if="activeThread == ''">
                              <b-list-group-item v-for="(messagethread, key) in thread_list" class="list-group-item d-flex px-4 py-3 border product-list-item mb-2 w-100 border-radius-1em w-100" :to="'?uid='+messagethread.uid">
                              	 <div class="mr-auto ml-2 my-auto mr-3 d-flex" v-if="messagethread.company !== null">
                              	 	<img class="avatar-sm rounded-circle" v-if="messagethread.company.logo !== null" :src="'https://'+messagethread.company.nickname+'.s3.eu-west-2.amazonaws.com'+messagethread.company.logo">
                              	 </div>
                              	 <div class="d-flex flex-column w-100">
                              	 <div class="mr-auto ml-2 my-auto mr-3 mb-2">
                                	
                                	<!--<p class="my-auto mr-2"></p>-->
                                	<h5 class="my-auto">
                                	<span v-if="messagethread.support_type !== null">{{getSupportType(messagethread.support_type)}}</span>:
						            					{{messagethread.subject}}
						            					</h5>

						            <span class="text-muted ml-auto my-auto position-absolute top-0 right-0 mt-3 mr-3" v-if="messagethread.thread_items.length > 0">
						            		<div v-if="getMessageFrom(messagethread.thread_items.length - 1, messagethread.thread_items) == 'customer'">
						            			<span class="badge badge-primary bg-primary text-white">Awaiting Reply from Seller</span>
						            		</div>
						            		<div v-if="getMessageFrom(messagethread.thread_items.length - 1, messagethread.thread_items) == 'company'">
						            			<span class="badge badge-danger bg-danger text-white">Awaiting Your Response</span>
						            		</div>

						            </span>
						            <span class="text-muted ml-auto my-auto position-absolute top-0 right-0 mt-3 mr-3" v-else>
						            	<div>
						            			<span class="badge badge-primary bg-primary text-white">Awaiting Reply from Seller</span>
						            		</div>
						            </span>
                                </div>
                              	<div class="d-flex product-list-item-container w-100">
                              	
                             <div class="mr-auto ml-2 my-auto mr-3">
                                
                             <div>
						            		
						            		<span v-if="messagethread.thread_items.length !== 0">Last Message From: <span class="badge badge-light bg-light">{{getMessageFrom(messagethread.thread_items.length - 1, messagethread.thread_items)}}</span></span>
						            		<span v-else>Last Message From: <span class="badge badge-light bg-light">{{messagethread.from}}</span></span>
						            		<br>
						            		<!--<span v-if="messagethread.read_at !== null">{{messagethread.read_at | luxon({input: 'sql', output: 'time'}) }}</span>-->
						            		</div>
						            		
                                </div>
                                <!--<span class="text-muted italic no-margin my-auto ml-auto mr-auto"><i class="bx bx-calendar text-primary font-size-14"></i> {{messagethread.created_at | luxon({input: 'server', output: 'time'})}}</span>-->
                                <span class="text-muted italic no-margin my-auto ml-auto"><i class="bx bx-calendar text-primary font-size-14"></i> {{messagethread.updated_at | luxon({input: 'server', output: 'time'})}}</span>
						            
						            <strong class="text-muted my-auto"></strong>
                                <!--<div class=" ml-3 my-auto d-flex">
                                	<a class="btn btn-outline-light btn-rounded rounded-circle ml-auto my-auto flex-grow-3" @click="uncollapseThread(messagethread)" href="javascript:;"><i class="mdi mdi-eye-outline"></i></a>
                                <a class="btn btn-outline-light btn-rounded ml-auto my-auto" href="javascript:;" @click="removeResource(product)"><i class="py-1 px-2 bx bx-trash-alt"></i></a>
                            	</div>-->
              
                              </div>
                          </div>
                              </b-list-group-item>
                            </div>
                          </transition>
                          <transition name="fade-bottom">
                          <b-list-group-item v-if="activeThread !== ''">
                          	<b-overlay :show="loadingMessages">
                          	<b-card class="border-radius-1em border shadow-none">	
                          		<b-row>
                          			<b-col md="12">
                          		<span class="text-capitalize">From: {{activeThread.from}}</span>
                          		<span class="text-muted italic no-margin my-auto ml-auto mr-auto"><i class="bx bx-calendar text-primary font-size-14"></i> {{activeThread.created_at | luxon({input: 'server'})}}</span>
                          		<span>
                          			{{getSupportType(activeThread.support_type)}}
                          		</span>
                          		<h4 class="mt-3">
                          			{{activeThread.subject}}
                          		</h4>

                          		<!--<div class="messages-content bg-light border-radius-1em p-3 mb-3" :class="{'text-right' : message.from == 'company'}" v-for="message in activeThread">
                          			<h6 class="text-capitalize">From: {{message.from}}</h6>
                          			{{message.message}}
                          		</div>-->
                          	</b-col>
                          </b-row>
                          		<div class="chat-conversation p-3">
									              <simplebar
									                style="max-height: 470px"
									                id="containerElement"
									                ref="current"
									              >
									                <ul class="list-unstyled">
									                  <li>
									                    <div class="chat-day-title">
									                      <span class="title">Conversation Started: {{activeThread.created_at | luxon({input: 'server'})}}</span>
									                    </div>
									                  </li>
									                  <!--<li  :class="{'right' : activeThread.from == 'customer'}" >
									                    <div class="conversation-list">
									                     <b-dropdown
									                        variant="white"
									                        menu-class="dropdown-menu-end"
									                      >
									                        <template v-slot:button-content>
									                          <i class="bx bx-dots-vertical-rounded"></i>
									                        </template>
									                        <b-dropdown-item>Copy</b-dropdown-item>
									                        <b-dropdown-item>Save</b-dropdown-item>
									                        <b-dropdown-item>Forward</b-dropdown-item>
									                        <b-dropdown-item>Delete</b-dropdown-item>
									                      </b-dropdown>
									                      <div class="ctext-wrap">
									                        <div class="conversation-name">
									                        	<span v-if="activeThread.from == 'company'">
									                        		Company
									                        	</span>
									                        	<span v-if="activeThread.from == 'customer'">
									                        		You
									                        	</span>
									                        </div>
									                        <p>{{activeThread.message}}</p>
									                        <p class="chat-time mb-0">
									                          <i class="bx bx-time-five align-middle me-1"></i>
									                          {{activeThread.created_at | luxon({input: 'server', output: 'time'})}}
									                        </p>
									                      </div>
									                    </div>
									                  </li>-->
									                  <li v-if="thread_messages.length > 0" v-for="message in thread_messages" :class="{'right' : message.attributes.from == 'customer'}" >
									                    <div class="conversation-list">
									                     <!--<b-dropdown
									                        variant="white"
									                        menu-class="dropdown-menu-end"
									                      >
									                        <template v-slot:button-content>
									                          <i class="bx bx-dots-vertical-rounded"></i>
									                        </template>
									                        <b-dropdown-item>Copy</b-dropdown-item>
									                        <b-dropdown-item>Save</b-dropdown-item>
									                        <b-dropdown-item>Forward</b-dropdown-item>
									                        <b-dropdown-item>Delete</b-dropdown-item>
									                      </b-dropdown>-->
									                      <div class="ctext-wrap">
									                        <div class="conversation-name">
									                        	<span v-if="message.attributes.from == 'company'">
									                        		Company
									                        	</span>
									                        	<span v-if="message.attributes.from == 'customer'">
									                        		You
									                        	</span>
									                        </div>
									                        <p>{{message.body}}</p>
									                        <p class="chat-time mb-0">
									                          <i class="bx bx-time-five align-middle me-1"></i>
									                          {{message.created_at.date | luxon({output: 'time'})}}
									                        </p>
									                      </div>
									                    </div>
									                  </li>
									                </ul>
									              </simplebar>
									            </div>
									            <div class="p-3 chat-input-section">
									            	  <b-row class="position-absolute top-0 right-0 mr-2 mt-3">
									            	  	<transition name="fade-top">
											              	<b-col v-if="success_message == true">
											              		<b-alert variant="success" dismissible @dismissed="success_message = !success_message" :show="true">{{success_messagetext}}</b-alert>
											              	</b-col>
									              	</transition>
									              </b-row>
              <div class="row">
                <div class="col">
                  <div class="position-relative">
                    <!--<input
                      type="text"
                      class="form-control chat-input"
                      placeholder="Enter Message..."
                    />-->
                    <textarea
                      type="text"
                      class="form-control chat-input"
                      placeholder="Enter Message..."
                      v-model="data.message"
                    ></textarea>
                    <div class="chat-input-links">
                      <ul class="list-inline mb-0">
                        <li class="list-inline-item">
                          <a
                            href="#"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Emoji"
                            ><i class="mdi mdi-emoticon-happy-outline"></i
                          ></a>
                        </li>
                        <li class="list-inline-item">
                          <a
                            href="#"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Images"
                            ><i class="mdi mdi-file-image-outline"></i
                          ></a>
                        </li>
                        <li class="list-inline-item">
                          <a
                            href="#"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Add Files"
                            ><i class="mdi mdi-file-document-outline"></i
                          ></a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="col-auto">
                  <button v-if="activeThread.status < 5"
                    type="submit"
                    class="btn btn-primary btn-rounded chat-send w-md waves-effect waves-light"
                    @click="sendReply(activeThread)"
                  >
                    <span class="d-none d-sm-inline-block me-2">Send</span>
                    <i class="mdi mdi-send"></i>
                  </button>
                </div>
              </div>
            
            </div>
                          	</b-card>
													</b-overlay>
                          </b-list-group-item>
                        </transition>

                              <!--<li class="list-group-item py-4 text-center" ><a class="btn btn-outline-light btn-rounded">Load More</a>
                              </li>-->
           </b-list-group>
               
		</b-col>
	</b-row>
</template>