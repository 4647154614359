<template>
	<div>
		<simplebar style="max-height: 420px; min-height: 420px">
					<b-list-group flush v-if="notifications !== null">
                                    <b-list-group-item v-for="(notification, key) in notifications" class="position-relative">
                                            <a :href="Object.keys(notification.data).includes('resource_url') ? notification.data['resource_url'] : 'javascript:;'" class="d-flex text-muted" >
                                                <div class="avatar-sm mr-3">
                                                    <span :class="getNotificationKey(notification.type, 'bg') +' avatar-title rounded-circle'">
                                                    	<i :class="getNotificationKey(notification.type, 'icon')+'  font-size-18'"></i>
                                                    </span>
                                                </div>
                                                <div class="d-flex flex-column my-auto">
                                                    <h6 class="mb-0">{{getNotificationKey(notification.type, 'title')}}</h6>
                                                    <p class="mb-0">{{getNotificationKey(notification.type, 'text')}}</p>
                                                </div>
                                                
                                                <div class="ml-auto my-auto mr-2">
	                                                	<i class="text-muted">
	                                                		{{notification.created_at | luxon({input: 'server', output: 'time'})}}
	                                                	</i>
	                                                </div>
                                                <a class="btn btn-close btn-outline-light p-3 border rounded-circle my-auto mr-2"  href="javascript:;">
                                            	</a>
                                            </a>
                                            
                                            
                                            <!--<b-list-group flush v-if="(key !== '') && (expand == key)" class="position-relative" >
                                            	<b-list-group-item v-for="subnote in notifications[key]" class="d-flex">

                                            		<div class="avatar-sm mr-3">
                                                    <span class="avatar-title bg-primary rounded-circle">
                                                    	<i class="bx bx-bell"></i>
	                                                    </span>
	                                                </div>
	                                                <div class="d-flex flex-column">
	                                                    <h6>Notification Title</h6>
	                                                    <p>Here's some text with a notification for you</p>
	                                                </div>
	                                                <div>
	                                                	<i class="text-muted">
	                                                		{{subnote.created_at | luxon({input: 'server', output: 'time'})}}
	                                                	</i>
	                                                </div>
                                            	</b-list-group-item>
                                            </b-list-group>-->
                                        </b-list-group-item>
                                    </b-list-group>
    	</simplebar>
    </div>
</template>

<script type="text/javascript">
import simplebar from 'simplebar-vue';

export default{
	components: {simplebar},
	props: {
		notifications: {
			type: Object,
			default: null 
		}
	},
	data() {
		return {
			expand: '',
			notification_types: [{
				bg: 'bg-primary',
				color: 'primary',
				icon: 'bx bx-cart',
				type: 'App\\Notifications\\PublicOrderReceived',
				text: 'You\'ve received an order from Gemesys Public Marketplace.',
				title: 'Public Marketplace Order Received'
			},{
				bg: 'bg-blue',
				color: 'blue',
				icon: 'bx bx-cart',
				type: 'App\\Notifications\\TradeOrderReceived',
				text: 'You\'ve received an order from Gemesys Trade Marketplace.',
				title: 'Trace Marketplace Order Received'
			},{
				bg: 'bg-primary',
				color: 'primary',
				icon: 'bx bx-chat',
				type: 'App\\Notifications\\PublicCustomerSupportRequest',
				text: 'You\'ve received a customer service message from Gemesys Public Marketplace.',
				title: 'Public Marketplace Support Message Received'
			},{
				bg: 'bg-primary',
				color: 'primary',
				icon: 'bx bx-chat',
				type: 'App\\Notifications\\PublicCustomerSupportResponse',
				text: 'You\'ve received a customer service message from Gemesys Public Marketplace.',
				title: 'Public Marketplace Support Message Received'
			},{
				bg: 'bg-blue',
				color: 'blue',
				icon: 'bx bx-chat',
				type: 'App\\Notifications\\TradeSupportRequest',
				text: 'You\'ve received a customer service message from Gemesys Trade Marketplace.',
				title: 'Trade Marketplace Support Message Received'
			}]
		}
	},
	methods: {
		getNotificationKey(data, key){
			var typedata = this.notification_types.filter(item => item.type == data);
			if(typedata.length !== 0){
			return typedata[0][key];
			}
		}
	}

}

</script>