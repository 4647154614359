<script type="text/javascript">
	import PageHeader from "../components/widgets/ecommerce/public/PageHeader";
	import CartSummary from "../components/widgets/ecommerce/public/CartSummary";
	import OrderConfirmation from "../components/widgets/ecommerce/public/OrderConfirmation";
	import OrderSummary from "../components/widgets/ecommerce/public/OrderSummary";
	import Login from "./account/onpageLogin";
	import { EventBus } from '../public-app'
	export default{
		components: { CartSummary, OrderConfirmation, OrderSummary, Login, PageHeader},
		data() {
		    return {
		    	csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
		      	  cartuid: '',
		      	  cart: '',
		      	  order: '',
		      	  orderconfirmuid: '',
		      	  order_paid: false,
		      	  show_order_summary: false,
		      	  show_payment_form: false,
		      	  order_created: false, 
		      	  paymentResult: '',
		      	  breadcrumbs: [],
					bread: {
						title: '',
						link: '',
						id: 1,
					},
			      title: "Order",
			      items: [
			        {
			          text: "Trade",
			          href: "/",
			        },
			        {
			          text: "Order",
			          active: true,
			        },
			      ],
			  }
	  	   },
	  	   props: {
	  	   	 user: {
	  	   	 	type: Object,
	  	   	 },
	  	   	 id: {
	  	   	 	type: Number,
	  	   	 	default: () => '',
	  	   	 }
	  	   },
	  	   created(){
	  	 	var session_cart = sessionStorage.getItem("publiccart");
		    session_cart = JSON.parse(session_cart);
		    console.log(session_cart);
		    if(session_cart == null){
		    	session_cart = {};
		    }
		    //var cartparams = window.sessionStorage.getItem("cartparams");
		    //cartparams = JSON.parse(cartparams);
	  	 		if(Object.keys(this.$route.params).length > 0){
	  	 			this.cartuid = this.$route.params.cart.uid;
		  	 			axios.get('/customer-api/cart/read?cart_uid='+this.cartuid, {headers: {
				            'Authorization': 'Bearer ' + this.user.api_token
				        }}).then(response => {
				        console.log(response);
				          if(response.data !== 0){
				            this.cart = response.data;
				            window.sessionStorage.setItem("publiccart", JSON.stringify(response.data));
				          }else{
				            window.sessionStorage.setItem("publiccart", JSON.stringify(this.cart))
				          }
				        
				      }).catch(error => {
				        this.error_reponse = error.response.data.errors;
				      });
	  	 		}else if(Object.keys(session_cart).length > 0){
	  	 			this.cartuid = session_cart['uid'];
	  	 			axios.get('/customer-api/cart/read?cart_uid='+this.cartuid, {headers: {
				            'Authorization': 'Bearer ' + this.user.api_token
				        }}).then(response => {
				        console.log(response);
				          if(response.data !== 0){
				            this.cart = response.data;
				            window.sessionStorage.setItem("publiccart", JSON.stringify(response.data));
				          }else{
				            window.sessionStorage.setItem("publiccart", JSON.stringify(this.cart));
				          }
				        
				      }).catch(error => {
				        this.error_reponse = error.response.data.errors;
				      });
	  	 		}else{
	  	 			axios.get('/customer-api/cart/read?cart_uid='+this.cartuid, {headers: {
				            'Authorization': 'Bearer ' + this.user.api_token
				        }}).then(response => {
				        console.log(response);
				          if(response.data !== 0){
				            this.cart = response.data;
				            window.sessionStorage.setItem("publiccart", JSON.stringify(response.data));
				          }else{
				            window.sessionStorage.setItem("publiccart", JSON.stringify(this.cart))
				          }
				        
				      }).catch(error => {
				        this.error_reponse = error.response.data.errors;
				      });
	  	 		}
	  	   },
	  	   mounted(){
	  	   	this.breadcrumbs = this.$route.path.split('/');
				if(this.breadcrumbs.length > 0){
				
				this.breadcrumbs[0] = "Home";
				}
			if(this.breadcrumbs.length == 3){
					this.id = this.breadcrumbs[this.breadcrumbs.length - 1];
					this.title = "Order Summary";
				}
				
	  	  let self = this;
				EventBus.$on('currency-updated', function(symbol, currency, rates){
					self.refetchCart();
	        if(self.user.default_delivery_address !== null){			  		
			  		self.$refs['summary'].companiesTotalIncVat(self.user.default_delivery_address.country.country_zone_id, self.user.default_delivery_address.country.id);
					}
					if(self.user.default_invoice_address !== null){
							//this.invoice_address = this.user.default_invoice_address;
							//this.customer.customer_invoice_address_id = this.user.default_invoice_address.id;
						self.$refs['summary'].companiesTotalIncVat(self.user.default_invoice_address.country.country_zone_id, self.user.default_invoice_address.country.id);
					}
				});
		    EventBus.$on('process-cart', function(){
		      self.$refs['summary'].processCart();
		    });
		    EventBus.$on('cancel-payment', function(product, qty){
		      self.show_payment_form = false;
		    });
		    EventBus.$on('order-paid', function(result){
		      self.paymentResult = result;
		      if(result.status == "succeeded"){
		    	  self.order_paid = true;
		  		}else{
		  		  self.order_paid = false;
		  		}
		    });
		    EventBus.$on('order-updated', function(order, paid){
		    	self.show_payment_form = false;
		     	self.order = order;
		     	self.orderconfirmuid = order["order"]["uid"];
		     	self.order_created = true;
		     	self.title = "Order Confirmation";
		     	self.destroyCart();
		     	
		    });
	  	   },
	  	   methods: {
	  	   	updateCart(cart){
	  	   		this.cart = cart[2];
	  	   		this.show_payment_form = true;
	  	   	},
	  	   	refetchCart(){
	  	   		this.cart = '';
	  	   		var session_cart = window.sessionStorage.getItem("publiccart");
				    session_cart = JSON.parse(session_cart);
				    console.log(session_cart);
				    if(session_cart == null){
				    	session_cart = {};
				    }
		    		//var cartparams = window.sessionStorage.getItem("cartparams");
		    		//cartparams = JSON.parse(cartparams);
	  	 		if(Object.keys(this.$route.params).length > 0){
	  	 			this.cartuid = this.$route.params.cart.uid;
		  	 			axios.get('/customer-api/cart/read?cart_uid='+this.cartuid, {headers: {
				            'Authorization': 'Bearer ' + this.user.api_token
				        }}).then(response => {
				        console.log(response);
				          if(response.data !== 0){
				            this.cart = response.data;
				            window.sessionStorage.setItem("publiccart", JSON.stringify(response.data));
				          }else{
				            window.sessionStorage.setItem("publiccart", JSON.stringify(this.cart))
				          }
				        
				      }).catch(error => {
				        this.error_reponse = error.response.data.errors;
				      });
	  	 		}else if(Object.keys(session_cart).length > 0){
	  	 			this.cartuid = session_cart['uid'];
	  	 			axios.get('/customer-api/cart/read?cart_uid='+this.cartuid, {headers: {
				            'Authorization': 'Bearer ' + this.user.api_token
				        }}).then(response => {
				        console.log(response);
				          if(response.data !== 0){
				            this.cart = response.data;
				            window.sessionStorage.setItem("publiccart", JSON.stringify(response.data));
				          }else{
				            window.sessionStorage.setItem("publiccart", JSON.stringify(this.cart));
				          }
				        
				      }).catch(error => {
				        this.error_reponse = error.response.data.errors;
				      });
	  	 		}else{
	  	 			axios.get('/customer-api/cart/read?cart_uid='+this.cartuid, {headers: {
				            'Authorization': 'Bearer ' + this.user.api_token
				        }}).then(response => {
				        console.log(response);
				          if(response.data !== 0){
				            this.cart = response.data;
				            window.sessionStorage.setItem("publiccart", JSON.stringify(response.data));
				          }else{
				            window.sessionStorage.setItem("publiccart", JSON.stringify(this.cart))
				          }
				        
				      }).catch(error => {
				        this.error_reponse = error.response.data.errors;
				      });
	  	 		}
	  	   	},
	  	   	destroyCart(){
	  	   		axios.post('/customer-api/cart/order-created', {cartuid: uid }, {
              headers: {
                'Authorization' : 'Bearer '+this.user.api_token
              }
            }).then(response => {
	  	   			this.cartDeleted = true;
	  	   			EventBus.$emit('clear-cart', this.cart.uid);
	  	   		}).catch(error => {
	  	   			this.cartDeleted = false;
	  	   		});
	  	   	}
	  	   }
	}

</script>

<template>
	<section>
		 <PageHeader :data="{name: title, description: ''}" />
		 <!--<b-row class="mb-4" style="min-height: 400px; background-image: url('/images/large-banner-bg.png'); background-position:  right bottom; background-size: 50%; background-repeat: no-repeat">

		      <b-col md="10" class="d-flex flex-column justify-content-center align-items-start mx-auto">
		      	
		      	<div class="d-flex flex-column mt-4 pt-4">
		      	
		      		<b-row class="">
				 	<b-col md="12" class="d-flex flex-row align-items-start mx-auto">
				 	<div  v-for="(bread, index) in breadcrumbs" class="d-flex flex-row">
		    		<span class="text-muted font-size-12 text-capitalize my-auto"><i class="bx bx-home my-auto font-size-10 mr-1" v-if="index == 0"></i>{{bread}}</span>
		    		 <i class="bx bx-chevron-right my-auto" v-if="index !== (breadcrumbs.length -1)"></i>
		    		</div>

				    	</b-col>
				    </b-row>
		        <h1 class="font-size-32 text-primary my-1 text-capitalize">{{title}}</h1>
		      	</div>
		      </b-col>
		    </b-row>-->
		<b-row class="pb-4"  v-if="user == ''">
				    <b-col md="12">
				    	<b-row class="pb-5">
					    		<b-col md="6" class="mx-auto">
						    		<Login submit-url="http://gemesys.co.uk/my-account/login" auth-error="">
				             <input type="hidden" name="_token" :value="csrf">
				        		</Login>
			        		</b-col>
				    	</b-row>
				    </b-col>
				</b-row>
    	<b-row v-else class="seller-top-row">
    		<b-col md="12" class="profile-user-wid">
				<CartSummary ref="summary" v-if="(cart !== '') && (id == '') && (order_created == false)" :user="user" :company="''" @update-cart="updateCart" :cart="cart" :selected_currency="$attrs.selected_currency" :selected_currency_symbol="$attrs.selected_currency_symbol" :exchange_rates="$attrs.exchange_rates" @refetch-cart="refetchCart"></CartSummary>
				<!--<OrderConfirmation v-if="(id !== '') && (order !== '')"
				:user="user" :company="''" :order="order" :paid="order_paid" 
				></OrderConfirmation>-->
				<OrderSummary v-if="(id !== '') && (order_created == false)"
				:user="user" :company="''" :paid="order_paid" :uid="id"
				></OrderSummary>
				<OrderSummary v-if="(orderconfirmid !== '') && (order_created == true)"
				:user="user" :company="''" :paid="order_paid" :uid="orderconfirmuid"
				></OrderSummary>
				<!--<TradeOrderDetails v-if="id !== ''" ></TradeOrderDetails>-->
				<!--<a class="btn btn-success" v-if="step == 0, title = 'Payment', items[1].text = 'Payment'" @click="step++">Process Order</a>-->
			</b-col>
				
		</b-row>
	</section>
</template>