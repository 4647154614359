<template>
    <div>
           
            <component :is="component_name" :id="id" :slug="slug" :nickname="$attrs.nickname" :user="user" :company="company"  :userpermissions="userpermissions" :isowner="isowner" :validsubscription="validsubscription" :subscriptiondetails="subscriptiondetails" :active_menu="activeMenuItem" :selected_currency="selected_currency" :selected_currency_symbol="selected_currency_symbol" :exchange_rates="exchange_rates" :currencies="currencies" />
        
    </div>
</template>
<script>
import { EventBus } from '../app'

export default {
    props: {
        component: {
            type: String,
            required: true
        },
        id: {
            type: String,
            required: false,
            default: () => ""
        },
        user:{
            type: Object,
            required: false,
            default: () => ""
        },
        company:{
            type: Object,
            required: false,
            default: () => ""
        },
        userpermissions:{
            type: Object,
            required: false,
            default: () => ""
        },
        isowner:{
            type: String,
            required: false,
            default: () => ""
        },
        validsubscription:{
            type: Number,
            required: false,
            default: () => 0
        },
        subscriptiondetails:{
            type: Object,
            required: false,
            default: () => 0
        },
        slug:{
            type: String,
            required: false,
            default: () => null
        }
    },
    data(){
        return {
            currencykey: process.env.MIX_CURRENCY_CONVERTER,
            hasstripe_id: false,
            menu: [],
            active_menu: '',
            selected_currency: '',
              selected_currency_symbol: '',
              exchange_rates: '',
              currencies: [],
        }
    },
    created(){
        this.user = JSON.parse(this.user);
        this.company = JSON.parse(this.company);
        this.userpermissions = JSON.parse(this.userpermissions);
        this.isowner = JSON.parse(this.isowner);
        this.validsubscription = JSON.parse(this.validsubscription);
        this.subscriptiondetails = JSON.parse(this.subscriptiondetails);
        if(this.user.companies[0].permissions == null) {
            this.user.companies[0].permissions = {add: [], edit: [], read: [], view: [], delete: []};
        }
        if (window.document.cookie.indexOf("admin_logged") < 0) {
          // Cookie with the name 'cookie_name' doesn't exist, so we can set it
          window.document.cookie = "admin_logged="+btoa(this.company.nickname)+"; domain=gemesys.co.uk; path=/;";
         
        } else {
          // Cookie with the name 'cookie_name' already exists
           
        }
         axios.get('/api/menu-route?url='+this.component+'').then(response => {
              this.active_menu = response.data;
            }).catch(error => {

            });
            this.getCurrencies();
            var storedcurrency = window.localStorage.getItem("currency");
            if(storedcurrency){
              var parsecurrency = JSON.parse(storedcurrency);
              console.log(parsecurrency["currency_code"]);
              this.convertCurrency(parsecurrency["currency_code"], null, parsecurrency["currency_symbol"]);
            }else if(this.selected_currency == ''){
              this.convertCurrency('GBP', null, '£');
            }


    },
    mounted(){
        EventBus.$on('logged-out', function() {
          window.document.cookie = "admin_logged=true; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=gemesys.co.uk;";
        });

        let self = this;
        EventBus.$on('currency-updated', function (symbol, currency, rates) {
          self.selected_currency = currency;
          self.exchange_rates = rates.data;
          self.selected_currency_symbol = symbol; 
          var currencyItems = document.getElementsByClassName('currency');
          /*for(var i = 0; i < currencyItems.length; i++){
              document.getElementsByClassName('currency')[i].innerHTML = symbol;
          }*/
          window.localStorage.setItem("currency", JSON.stringify({currency_code: currency, currency_symbol: symbol}))
        });
    },
    methods: {
      getCurrencies(){
        axios.get('/api/currencies', {headers: {'Authorization' : 'Bearer '+this.user.api_token}}).then(response => {
          this.currencies = response.data;
        }).catch(error => {

        });
      },
        convertCurrency(from, to, symbol){
            axios.get('https://api.freecurrencyapi.com/v1/latest?apikey='+this.currencykey+'&currencies=EUR,USD,GBP&base_currency='+from+'').then(response => {
              this.conversion_rates = response.data;
              EventBus.$emit('currency-updated', symbol, from, response.data);
            }).catch(error => {

            });
        },
    },
    computed: {

        component_name() {
            return !!this.$root.$options.components[this.component] ? this.component : "pages-404";
        },
         menuItems(){
          return this.$root.menu;
        },
        activeMenuItem(){
          return this.active_menu;
        }
    }
};
</script>
