<script type="text/javascript">

	import EventBus from '../../app'
	import DateTime from 'luxon/src/datetime.js';
  	import Interval from 'luxon/src/interval.js';
  	import Swiper from "../../components/widgets/carousel/Swiper";
  	import SellerSwiper from "../../components/widgets/carousel/SellerSwiper";
  	import PublicMarketplaceSellerList from "../../components/widgets/PublicMarketplaceSellerList";
  	import PublicMarketplaceProductList from "../../components/widgets/PublicMarketplaceProductList";
  	import CustomerAddressForm from "../../components/widgets/forms/website/CustomerAddressForm";
  	import CompanyCustomerSupportForm from "../../components/widgets/forms/company/CompanyCustomerSupportForm";
  	import CustomerAddresses from "../../components/widgets/lists/website/WebsiteCustomerAddresses";
  	import OrderList from '../../components/widgets/ecommerce/trade/OrderList';
  	import CompanyCustomerSupportThreads from '../../components/widgets/admin/support/CompanyCustomerSupportThreads';
  	import CompanyCustomerSupportTwilioThreads from '../../components/widgets/admin/support/CompanyCustomerSupportTwilioThreads';
	export default{
		components: { Swiper, SellerSwiper, CustomerAddressForm, CustomerAddresses, PublicMarketplaceSellerList, PublicMarketplaceProductList, OrderList, CompanyCustomerSupportThreads, CompanyCustomerSupportTwilioThreads, CompanyCustomerSupportForm },
		data(){
			return{
				uid: '',
				loadingCard: false,
				message_threads: [],
				loadingAddressesForm: false,
				updateAddress: true,
				showControls: -1,
				orders_page: 1,
				orders_limit: 5,
				orders: '',
				form: '',
				action: '',
				address: {
					id: '',
					customer_id: '',
					address_1: '',
					address_2: '',
					address_3: '',
					city: '',
					county: {
						id: '',
					},
					country: {
						id: '',
					},
					postcode: '',
					alias: '',
					delivery_default: 0,
					invoice_default: 0,
					active: 1,

				},
				address_data: {
					id: '',
					customer_id: '',
					address_1: '',
					address_2: '',
					address_3: '',
					city: '',
					county: {
						id: '',
					},
					country: {
						id: '',
					},
					postcode: '',
					alias: '',
					delivery_default: 0,
					invoice_default: 0,
					active: 1,

				},
				swiperOptions: {
		          slidesPerView: 4,
		          spaceBetween: 5,
		          loop: false,
		          loopFillGroupWithBlank: false,
		          pagination: {
		            el: '.swiper-pagination',
		            clickable: true,
		          },
		          // Some Swiper option/callback...
		        },
		        favourites: [],
		        wishlist: [],
			}
		},
		props: {
			user: {
				type: Object
			},
			cardExpanded: {
				type: String,
				default: () => ''
			}
		},
		methods: {
			markAsRead(params){
				console.log(params);
				var id = params[0];
				var isread = params[1];
		  		axios.post('/website-api/user/message/'+id+'/read', {read: isread}, {headers : {
		  			'Authorization' : 'Bearer '+this.user.api_token
		  		}}).then(response => {
		  			
		  		}).catch(error => {

		  		});
		  	},
			addressAddedUpdated(address){
				this.loadingAddressesForm = true;
				var self = this;
                setTimeout(function(){
                 //Vue.swal("Avatar Updated");
                 self.loadingAddressesForm = false;
                }, 500);
			},
			loadUserOrders(limit,page){
				axios.get('/api/company/trade/orders?page='+page+'&limit='+limit, {headers: {
					'Authorization': 'Bearer ' + this.user.api_token
				}
				}).then(response => {
					this.orders = response.data;
				}).catch(error => {

				});
			},
			loadUserMessages(limit,page){
				axios.get('/api/company/trade/messages?page='+page+'&limit='+limit, {headers: {
					'Authorization': 'Bearer ' + this.user.api_token
				}
				}).then(response => {
					this.message_threads = response.data;
				}).catch(error => {

				});
			},
			dateParser(date){
				return DateTime.fromISO(date).toLocaleString(DateTime.DATE_FULL);
			},
			collapseCard(card){
				this.loadingCard = true;
					history.pushState({}, '', "/trade/account/"+card);
					let self = this;
					setTimeout(function(){
						self.loadingCard = false;
					}, 500);
			},
			updateAddressForm(payload){
				this.address = payload;
				console.log(payload);
				this.form = 'address';
				this.action = 'update';
			},
			getFavourties(limit){
				axios.get('/website-api/user/favourites?limit='+limit , {headers: {
			            'Authorization': 'Bearer ' + this.user.api_token
			        } }).then(response => {
					this.favourites = response.data;
				}).catch(error => {
					//alert("Error loading favourites");
				});
			},
			getWishlist(limit){
				axios.get('/website-api/user/wishlist?limit='+limit , {headers: {
			            'Authorization': 'Bearer ' + this.user.api_token
			        } }).then(response => {
					this.wishlist = response.data;
				}).catch(error => {
					//alert("Error loading wishlist");
				});
			},
			removeHash () { 
			    var scrollV, scrollH, loc = window.location;
			    if ("pushState" in history){
			        history.pushState("", document.title, loc.pathname + loc.search);
				}else {
			        // Prevent scrolling by storing the page's current scroll offset
			        scrollV = document.body.scrollTop;
			        scrollH = document.body.scrollLeft;

			        loc.hash = "";

			        // Restore the scroll offset, should be flicker free
			        document.body.scrollTop = scrollV;
			        document.body.scrollLeft = scrollH;
			    }
			    
			}
		},
		mounted(){
			const params = new URLSearchParams(window.location.search);
			const uid = params.get("uid");
			this.uid = uid;
				this.cardExpanded = this.$attrs.id;
				if(this.$attrs.id == 'favourites'){
        		 	this.getFavourties(0);
        		 }
        		 if(this.$attrs.id == 'wishlist'){
        		 	this.getWishlist(0);
        		 }
        		 if(this.$attrs.id == 'orders'){
        		 	this.loadUserOrders(this.orders_limit, this.orders_page);
        		 }
				if(this.$attrs.id == 'support'){
        		 	this.loadUserMessages(this.orders_limit, this.orders_page);
        		 }
				this.getFavourties(12);
				this.getWishlist(12);
				this.loadUserOrders(this.orders_limit,1);
				this.loadUserMessages(5,1);
		},
		watch: {
        $route() {
        	this.$nextTick(() => {
        		 this.cardExpanded = this.$attrs.id;
        		 if(this.cardExpanded == 'favourites'){
        		 	this.getFavourties(0);
        		 }
        		 if(this.cardExpanded == 'wishlist'){
        		 	this.getWishlist(0);
        		 }
        		 if(this.cardExpanded == 'orders'){
        		 	this.loadUserOrders(this.orders_limit, this.orders_page);
        		 }
        		 if(this.cardExpanded == 'support'){
        		 	this.loadUserMessages(this.orders_limit, this.orders_page);
        		 }
        		 if(this.cardExpanded == ''){
        		 	this.getFavourties(12);
					this.getWishlist(12);
					this.loadUserOrders(5,1);
        		 }
        	 });
            //this.$nextTick();
           // let self = this;
			
         }  
    	},
	}
</script>

<template>
	<div>

	<b-card class="border-radius-1em mt-0" v-if="(cardExpanded == '') || (cardExpanded == 'profile')">
		<h5 class="mb-4">Basic Details</h5>
		<b-row>
			
			<b-col>
				<label>Name</label>
				<p>{{user.name}} {{user.lastname}}</p>

				<label>Email</label>
				<p>{{user.email}} <i class="bx bx-check-circle bg-success text-white font-size-18 rounded-circle" v-if="user.email_verified_at !== null" v-b-tooltip.hover title="Email Address Verified" ></i></p>

				<label>Password</label>
				<p>*********</p>
			</b-col>

			<b-col>
				<label>Phone</label>
				<p>{{user.phone}}</p>

				<label>Mobile Phone</label>
				<p>{{user.mobile_phone}} <i class="bx bx-check-circle bg-success text-white font-size-18 rounded-circle" v-if="user.email_verified_at !== null" v-b-tooltip.hover title="Email Address Verified" ></i></p>

				<label>Joined</label>
				<p>{{ dateParser(user.created_at) }}</p>
			</b-col>
			<b-col md="12" class="d-flex">
				<a class="text-primary ml-auto" href="javascript:;" @click="">Edit</a>
			</b-col>
		</b-row>
	</b-card>
		<!--
	<b-card class="border-radius-1em" v-if="(cardExpanded == '') || (cardExpanded == 'addresses')">
		<h5 class="mb-4">Addresses</h5>
		<b-row>
			<b-col>
				<h6>Default Delivery Address</h6>
				<div v-if="user.default_delivery_address">
					<b-card class="border-radius-1em shadow-none border" @mouseenter="showControls = 'delivery'" @mouseleave="showControls = -1">
				<div class="media d-flex flex-column">
					<i v-b-tooltip.hover title="Default Delivery Address" class="bx bxs-truck position-absolute bg-success text-white top-0 right-0 mt-2 mr-2 font-size-18 rounded-circle p-1"></i>
						<div class="avatar-sm my-auto mx-auto">
							
							<a v-b-modal.modal-edit @click="updateAddressForm(user.default_delivery_address)"><span class="avatar-title rounded-circle">
								<i v-if="showControls == 'delivery'" class="bx bx-pencil font-size-18 text-white"></i>
							<i class="bx bx-home font-size-18"  v-else></i>
							</span></a>
		                 </div>
		                 <div class="media-body position-relative mt-2 text-center mx-auto">       
		                 <h5 class="font-size-14 mb-1">
		                    {{user.default_delivery_address.alias}}
		                 </h5>
		                 <div>
							<span v-if="user.default_delivery_address.address_1">{{user.default_delivery_address.address_1}}</span>
							<span v-if="user.default_delivery_address.address_2">{{user.default_delivery_address.address_2}}</span>
							<span v-if="user.default_delivery_address.address_3">{{user.default_delivery_address.address_3}}</span>
							<span v-if="user.default_delivery_address.city">{{user.default_delivery_address.city}}</span>
							<span v-if="user.default_delivery_address.country">{{user.default_delivery_address.country.name}}</span>
							<span v-if="user.default_delivery_address.county">{{user.default_delivery_address.county.name}}</span>
							<span v-if="user.default_delivery_address.postcode">{{user.default_delivery_address.postcode}}</span>
						</div>
						

					</div>
				</div>
			</b-card>
				</div>
				<div v-else class="border border-dashed border-radius-1em p-5 mt-2 text-center position-relative" @mouseenter="updateAddress = 'delivery'" @mouseleave="updateAddress = ''">
					<i class="bx bx-home text-primary font-size-18"></i>
					<p class="text-muted mb-0">No default address set</p>
				<transition name="fade-top">
					<div class="bg-white avatar-title text-dark top-0 bottom-0 left-0 right-0 mb-2 mx-auto position-absolute w-100 text-uppercase border-radius-1em" v-if="updateAddress == 'delivery'">
                	<a class="btn btn-primary btn-rounded w-75" v-b-modal.modal-edit @click="address = JSON.parse(JSON.stringify(address_data)), form = 'address', action = 'create', address.delivery_default = 1" >Add New Address</a>
                	</div>
                </transition>
				</div>
			</b-col>
			<b-col>
				<h6>Default Billing Address</h6>
				<div v-if="user.default_invoice_address">
					<b-card class="border-radius-1em shadow-none border" @mouseenter="showControls = 'invoice'" @mouseleave="showControls = -1">
				<div class="media d-flex flex-column">
					<i v-b-tooltip.hover title="Default Billing Address" class="bx bx-money position-absolute bg-success text-white top-0 right-0 mt-2 mr-2 font-size-18 rounded-circle p-1"></i>
						<div class="avatar-sm my-auto mx-auto">
							
							<a v-b-modal.modal-edit @click="updateAddressForm(user.default_invoice_address)"><span class="avatar-title rounded-circle">
								<i v-if="showControls == 'invoice'" class="bx bx-pencil font-size-18 text-white"></i>
							<i class="bx bx-home font-size-18"  v-else></i>
							</span></a>
		                 </div>
		                 <div class="media-body position-relative mt-2 text-center mx-auto">       
		                 <h5 class="font-size-14 mb-1">
		                    {{user.default_invoice_address.alias}}
		                 </h5>
		                 <div>
							<span v-if="user.default_invoice_address.address_1">{{user.default_invoice_address.address_1}}</span>
							<span v-if="user.default_invoice_address.address_2">{{user.default_invoice_address.address_2}}</span>
							<span v-if="user.default_invoice_address.address_3">{{user.default_invoice_address.address_3}}</span>
							<span v-if="user.default_invoice_address.city">{{user.default_invoice_address.city}}</span>
							<span v-if="user.default_invoice_address.country">{{user.default_invoice_address.country.name}}</span>
							<span v-if="user.default_invoice_address.county">{{user.default_invoice_address.county.name}}</span>
							<span v-if="user.default_invoice_address.postcode">{{user.default_delivery_address.postcode}}</span>
						</div>
						

					</div>
				</div>
			</b-card>
				</div>
				<div v-else class="border border-dashed border-radius-1em p-5 mt-2 text-center position-relative" @mouseenter="updateAddress = 'invoice'" @mouseleave="updateAddress = ''">
					<i class="bx bx-home text-primary font-size-18"></i>
					<p class="text-muted mb-0">No default address set</p>
					<transition name="fade-top">
					<div class="bg-white avatar-title text-dark top-0 bottom-0 left-0 right-0 mb-2 mx-auto position-absolute w-100 text-uppercase border-radius-1em" v-if="updateAddress == 'invoice'">
                	<a class="btn btn-primary btn-rounded w-75" v-b-modal.modal-edit @click="address = JSON.parse(JSON.stringify(address_data)), form = 'address', action = 'create', address.invoice_default = 1" >Add New Address</a>
                	</div>
                </transition>

				</div>
				
			</b-col>
			
			<b-col md="12" class="d-flex mt-3" v-if="(cardExpanded !== 'addresses')">
				
				<a class="text-primary ml-auto" href="/trade/account/addresses" @click="cardExpanded = 'addresses'">View All</a>
			</b-col>
		</b-row>
	</b-card>

	<b-card class="border-radius-1em" v-if="(cardExpanded == 'addresses')">
		<b-overlay :show="loadingAddressesForm">
				<CustomerAddresses v-if="loadingAddressesForm == false" :user="user" @create-address="address = JSON.parse(JSON.stringify(address_data)), form = 'address', action = 'create'" @update-address="updateAddressForm"></CustomerAddresses>
		</b-overlay>
	</b-card>
		-->
	

	<b-card class="border-radius-1em" v-if="(cardExpanded == '') || (cardExpanded == 'orders')">
		<h5 class="mb-4">Latest Orders</h5>
		<b-row>
		<b-col>
			<div v-if="orders">
				<OrderList :shopsData="orders" :expanded="isExpanded" :view="'list'" :currentPage="orders_page" :user="user" @change-page="loadUserOrders(orders_limit, $event)" :showFilters="false"></OrderList>
			</div>
			<div v-else class="border border-dashed border-radius-1em p-5 mt-2 text-center position-relative" >
					<i class="bx bxs-cube-alt text-primary font-size-18"></i>
					<p class="text-muted mb-0">No orders to show</p>
					

				</div>
		</b-col>
		<b-col md="12" class="d-flex mt-3">
				<a class="text-primary ml-auto"  href="/trade/account/orders" @click="orders_limt = 15, cardExpanded = 'orders'">View All</a>
			</b-col>
		</b-row>
	</b-card>	

	<b-card class="border-radius-1em" v-if="(cardExpanded == '') || (cardExpanded == 'support')">
		<h5 class="mb-4 d-inline">Latest messages</h5>
		<a v-b-modal.modal-messages class="btn btn-primary float-right btn-rounded text-white" href="javascript:;">
			Send New Message
		</a>
		<b-row class="mt-4">
		<b-col>
			<div v-if="message_threads">
				<b-overlay :show="loadingCard">
						<!--<CompanyCustomerSupportThreads ref="supportThreads" :thread_list="message_threads" :expanded="isExpanded" :view="'list'" @mark-read="markAsRead" :user="user" :userview="'customer'" :reply_route="'/api/company/trade/message/send-reply'" :thread_uid="uid"  ></CompanyCustomerSupportThreads>-->

						<CompanyCustomerSupportTwilioThreads ref="supportThreads" :thread_list="message_threads" :expanded="isExpanded" :view="'list'" @mark-read="markAsRead" :user="user" :userview="'customer'" :reply_route="'/api/company/trade/message/send-reply'" :thread_uid="uid"  ></CompanyCustomerSupportTwilioThreads>
				</b-overlay>
			</div>
			<div v-else class="border border-dashed border-radius-1em p-5 mt-2 text-center position-relative" >
					<i class="bx bxs-cube-alt text-primary font-size-18"></i>
					<p class="text-muted mb-0">No messages to show</p>
					

				</div>
		</b-col>
		<b-col md="12" class="d-flex mt-3">
				<a class="text-primary ml-auto"  @click="$refs['supportThreads'].active_thread = '', uid = '', collapseCard('support'), messages_limt = 15, cardExpanded = 'support'">View All</a>
			</b-col>
		</b-row>
	</b-card>	

	<b-card class="border-radius-1em" v-if="(cardExpanded == '') || (cardExpanded == 'favourites')">
		<h5 class="mb-4">Favourite Stores</h5>
		<b-row>
		<b-col class="overflow-hidden" v-if="favourites">
			<SellerSwiper v-if="(cardExpanded == '') || (cardExpanded !== 'favourites')" :items="favourites" :swiperOptions="swiperOptions" :favourites="user.favourites" :startSlide="1"></SellerSwiper>
			<PublicMarketplaceSellerList v-if="(cardExpanded !== '') || (cardExpanded == 'favourites')" :product_list="favourites" :expanded="1" :layouttoggle="true" :view="'grid'" :marketplace="'public'" ></PublicMarketplaceSellerList>
		</b-col>
		<b-col v-else>
			<div class="border border-dashed border-radius-1em p-5 mt-2 text-center position-relative" >
					<i class="bx bxs-cube-alt text-primary font-size-18"></i>
					<p class="text-muted mb-0">No favourite stores</p>
					

				</div>
		</b-col>
		<b-col md="12" class="d-flex">
				<a class="text-primary ml-auto"  href="/trade/account/favourites" @click="cardExpanded = 'favourites'">View All</a>
			</b-col>
		</b-row>
	</b-card>

	<b-card class="border-radius-1em" v-if="(cardExpanded == '') || (cardExpanded == 'wishlist')">
		<h5 class="mb-4">Product Wishlist</h5>
		<b-row>
		<b-col class="overflow-hidden" v-if="user.wishlist">
			<Swiper v-if="(cardExpanded == '') || (cardExpanded !== 'wishlist')" :items="wishlist" :swiperOptions="swiperOptions" :startSlide="1"></Swiper>
			<PublicMarketplaceProductList v-if="(cardExpanded !== '') || (cardExpanded == 'wishlist')" :product_list="wishlist" :expanded="0" :layouttoggle="true" :view="'grid'" :marketplace="'public'" ></PublicMarketplaceProductList>
		</b-col>
		<b-col v-else>
			<div class="border border-dashed border-radius-1em p-5 mt-2 text-center position-relative" >
					<i class="bx bxs-cube-alt text-primary font-size-18"></i>
					<p class="text-muted mb-0">No products in your wishlist</p>
					

				</div>
		</b-col>
		<b-col md="12" class="d-flex">
				<a class="text-primary ml-auto"  href="/trade/account/wishlist" @click="cardExpanded = 'wishlist'">View All</a>
			</b-col>
		</b-row>
	</b-card>

	<b-card class="shadow-none " v-if="(cardExpanded !== '')">
		<b-row>
			<b-col md="12">
				<a class="text-primary mr-auto" href="javascript:;" @click="removeHash(), cardExpanded = ''"><i class="bx bx-chevron-left"></i> Back</a>
			</b-col>
		</b-row>
	</b-card>

	<b-modal ref="modal-edit" id="modal-edit" centered content-class="border-radius-1em" header-class="border-none" hide-footer no-close-on-backdrop>
	<div  v-if="form == 'address'" class="p-3">	
		<CustomerAddressForm :address="address" :action="action" :user="user"  @address-added="addressAddedUpdated" @address-updated="addressAddedUpdated"></CustomerAddressForm>
    </div>
	</b-modal>

	 <b-modal no-close-on-backdrop ref="modal-messages" id="modal-messages" centered content-class="border-radius-1em" header-class="border-none" size="lg" hide-footer>
    	<CompanyCustomerSupportForm :user="user" @load-orders="loadUserOrders(0,1)" :orders="orders.data" :company="$attrs.company" ></CompanyCustomerSupportForm>
    </b-modal>

	</div>

</template>