<script>
import VueSlideBar from "vue-slide-bar";

/**
 * Pricing component
 */
export default {
  components: { VueSlideBar },
  data() {
    return {
    	stripeAPIToken: process.env.MIX_STRIPE_KEY,
        payment_term: 'monthly',
        intentToken: '',
		cardholder_name: '',
		addPaymentStatus: 0,
    paymentResult: '',
    paymentFormLoading: false,
		addPaymentStatusError: '',
		show_payment_form: false,
		selected_plan: '',
    current_price: 0.00,
    current_flat_price: 0.00,
		stripe: '',
    user_quantity: 1,
    shop_quantity: 1,
		elements: '',
		plans: '',
     loader: null,
      loadingValue: 0,
      customStyle: 800,
      lineHeight: 10,
		card: '',
      	title: 'Pricing',
      	name: 'account-subscription',
      	items: [
        {
          text: 'Utility',
          href: '/',
        },
        {
          text: 'Pricing',
          active: true,
        },
      ],
    }
  },
  computed:{
   /* userSubscriptionPrice: function(){
     var index = this.plans.findIndex(item => (item.users[0] <= this.user_quantity) && (item.users[1] >= this.user_quantity));
        return ((parseFloat(this.plans[index].price_inc) * parseFloat(this.user_quantity)) + parseFloat(this.plans[index].flat_price_inc)).toFixed(2);
    },*/

    userSubscriptionPrice: function(){
      if(this.user_quantity < 6){
        this.current_price = parseFloat(8.99).toFixed(2);
        this.current_flat_price = parseFloat(50.00).toFixed(2);
        this.shop_quantity = 1;
      }else if((this.user_quantity > 5) && (this.user_quantity <= 50)){
        this.current_price = parseFloat(7.99);
        this.current_flat_price = parseFloat(100.00).toFixed(2);
        if(this.shop_quantity >= 10){
        this.shop_quantity = 2;
        }
      }else if((this.user_quantity > 50) && (this.user_quantity <= 200)){
        this.current_price = parseFloat(6.99);
        this.current_flat_price = parseFloat(200.00).toFixed(2);
        if(this.shop_quantity < 10){
        this.shop_quantity = 10;
        }
      }
      return parseFloat(parseFloat(this.user_quantity * this.current_price) + parseFloat(this.current_flat_price)).toFixed(2);
    },
    userYearlySubscriptionPrice: function(){
      return parseFloat(parseFloat(this.userSubscriptionPrice) * parseFloat(12)).toFixed(2);
    },
  },
  methods:{
            subscriptionConfirmation(){
              this.$emit('payment-success', this.paymentResult, this.selected_plan, this.user_quantity, this.current_price, this.current_flat_price);
            },
            loadPaymentForm(plan){
                    this.selected_plan = plan;
                axios.get('/api/company/subscription/setup-intent?uid='+this.$attrs.company.uid+'&price='+this.userSubscriptionPrice+'', {headers: {
                  'Authorization': 'Bearer ' + this.$attrs.user.api_token
              }})
                .then( function( response ){
                    this.intentToken = response.data;
                    return this.includeStripe('js.stripe.com/v3/', function(){
                    this.configureStripe();

                  }.bind(this) );

                }.bind(this));
                      
            },
            flatPrice(plan){
            if(this.user_quantity < 6){
              this.current_price = parseFloat(8.99).toFixed(2);
              this.current_flat_price = parseFloat(50.00).toFixed(2);
              this.shop_quantity = 1;
            }else if((this.user_quantity > 5) && (this.user_quantity <= 50)){
              this.current_price = parseFloat(7.99);
              this.current_flat_price = parseFloat(100.00).toFixed(2);
              if(this.shop_quantity >= 10){
              this.shop_quantity = 2;
              }
            }else if((this.user_quantity > 50) && (this.user_quantity <= 200)){
                this.current_price = parseFloat(6.99);
                this.current_flat_price = parseFloat(200.00).toFixed(2);
                if(this.shop_quantity < 10){
                this.shop_quantity = 10;
                }
              }
            },
            includeStripe( URL, callback ){
                        let documentTag = document, tag = 'script',
                            object = documentTag.createElement(tag),
                            scriptTag = documentTag.getElementsByTagName(tag)[0];
                        object.src = '//' + URL;
                        if (callback) { object.addEventListener('load', function (e) { callback(null, e); }, false); }
                        scriptTag.parentNode.insertBefore(object, scriptTag);
            },
            configureStripe(){
                /*this.stripe = Stripe( this.stripeAPIToken );

                this.elements = this.stripe.elements();
                this.card = this.elements.create('card');

                this.card.mount('#card-element');
                */

                this.stripe = Stripe( this.stripeAPIToken );

                this.elements = this.stripe.elements({clientSecret: this.intentToken.client_secret});
                this.card = this.elements.create('payment', {clientSecret: this.intentToken.client_secret});
                this.show_payment_form = true;
                this.card.mount('#payment-element');
            },
            submitPaymentMethod(){
                    this.addPaymentStatus = 1;
                    this.paymentFormLoading = true;
                    this.stripe.confirmSetup({
                          elements: this.elements,
                          transfer_group: this.$attrs.company.uid,
                          confirmParams: {
                            return_url: 'http://'+this.$attrs.company.nickname+'.gemesys.co.uk/company/complete-setup?confirm='+this.intentToken.id,
                            payment_method_data: {
                              billing_details: {
                                                name: this.cardholder_name,
                                                email: this.$attrs.company.email,
                                  //address: {
                                   //line1: this.cardholder_line1,
                                 //line2: this.cardholder_line2,
                                 //line3: this.cardholder_line3,
                                   // city: this.cardholder_city,
                                  //}
                                            }
                            }
                          },
                          // Uncomment below if you only want redirect for redirect-based payments
                           redirect: 'if_required',

                        }
                    ).then(function(result) {
                      console.log(result);
                        if (result.error) {
                            this.addPaymentStatus = 3;
                            this.addPaymentStatusError = result.error.message;
                            let self = this;
                            setTimeout(function(){
                              self.paymentFormLoading = false;
                              //self.error_message = false;
                            }, 500);
                        } else {
                            this.savePaymentMethod( result.setupIntent.payment_method );
                            this.addPaymentStatus = 2;
                            this.card.clear();
                            this.cardholder_name = '';
                            this.$emit('payment-success', result, this.selected_plan, this.user_quantity, this.current_price, this.current_flat_price);
                               let self = this;
                              setTimeout(function(){
                                self.paymentFormLoading = false;
                                self.card.unmount('#payment-element');
                                //self.error_message = false;
                              }, 1000);
                              setTimeout(function(){
                              //window.location = ''+company.nickname+'.compliancecube.co.uk/login';
                               }, 3000);
                        }
                         let self = this;
                          setTimeout(function(){
                            self.paymentFormLoading = false;
                            //self.error_message = false;
                          }, 1000);
                    }.bind(this));
                },
            /* submitPaymentMethod(){
                    this.addPaymentStatus = 1;
                    this.paymentFormLoading = true;
                    this.stripe.confirmCardSetup(
                        this.intentToken.client_secret, {
                            payment_method: {
                                card: this.card,
                                billing_details: {
                                    name: this.cardholder_name
                                }
                            }
                        }
                    ).then(function(result) {
                        if (result.error) {
                            this.addPaymentStatus = 3;
                            this.addPaymentStatusError = result.error.message;
                        } else {
                            this.savePaymentMethod( result.setupIntent.payment_method );
                            this.paymentResult = result;
                            this.addPaymentStatus = 2;
                            this.card.clear();
                            this.cardholder_name = '';
                            this.$emit('payment-success', result, this.selected_plan, this.user_quantity, this.current_price);
                            /*setTimeout(function(){
                            	window.location.replace('/company/');
                            	 }, 5000);
                        */
              
                        /*
                               let self = this;
                              setTimeout(function(){
                                self.paymentFormLoading = false;
                                //self.error_message = false;
                              }, 1000);
                              setTimeout(function(){
                              //window.location = ''+company.nickname+'.compliancecube.co.uk/login';
                               }, 3000);
                        }
                         let self = this;
                          setTimeout(function(){
                            self.paymentFormLoading = false;
                            //self.error_message = false;
                          }, 1000);
                    }.bind(this));
                },
            savePaymentMethod( method ){
                    axios.post('/api/company/subscription/payments?api_token='+this.$attrs.user.api_token, {
                        payment_method: method,
                        plan_name: this.selected_plan.stripe_plan,
                        companyuid: this.$attrs.company.uid,
                        quantity: this.user_quantity,
                    }).then( function(){
                        //this.loadPaymentMethods();
                    }.bind(this));
                },*/

            savePaymentMethod( method ){
                    if(this.payment_term == 'monthly'){
                      var total_cost = this.userSubscriptionPrice;
                      var plan_price_exc = this.selected_plan.price_exc;
                      var plan_price_inc = this.selected_plan.price_inc;
                    }else if(this.payment_term == 'yearly'){
                      var total_cost = this.userYearlySubscriptionPrice;
                      var plan_price_exc = parseFloat(parseFloat(this.selected_plan.price_exc) * 12).toFixed(2);
                      var plan_price_inc = parseFloat(parseFloat(this.selected_plan.price_inc) * 12).toFixed(2);

                    }
                    axios.post('/api/company/subscription/payments', {
                        payment_method: method,
                        plan_name: this.selected_plan.stripe_plan,
                        companyuid: this.$attrs.company.uid,
                        quantity: this.user_quantity,
                        plan_cost_inc: plan_price_inc,
                        plan_cost_exc: plan_price_exc,
                        total_subscription_price: total_cost,
                        term: this.payment_term,
                    }, {headers: {
                  'Authorization': 'Bearer ' + this.$attrs.user.api_token
              }}).then( function(){
                        //this.loadPaymentMethods();
                       
                    }.bind(this));
                },
            getSubscriptionPlans(){
              axios.get('/api/subscription/plans?term='+this.payment_term, {headers: {
                  'Authorization': 'Bearer ' + this.$attrs.user.api_token
              }}).then(response => {
                      this.plans = response.data;
                  });
            },
            startLoad() {
              this.loader = setInterval(() => {
                this.loadingValue++;
                if (this.loadingValue === 100) {
                  clearInterval(this.loader);
                }
              }, 100);
            }
        },

  mounted() {
            this.includeStripe('js.stripe.com/v3/', function(){
                this.configureStripe();
            }.bind(this) );
            /*axios.get('/api/company/subscription/setup-intent?uid='+this.$attrs.company.uid+'&api_token='+this.$attrs.user.api_token)
                .then( function( response ){
                    this.intentToken = response.data;
                    

                }.bind(this));*/
            //this.loadPaymentForm();
        },
   created(){
   		this.getSubscriptionPlans();
   }
}
</script>

<template>
	<b-container>
	 <div class="row justify-content-center mt-4 pt-4">
      <div class="col-lg-6 text-center">
        <div class="text-center mb-5">
          <h4>Choose your Pricing plan</h4>
          <p
            class="text-muted"
          >Select a pricing plan to reflect the size of your organisation and the document storage you require.</p>
        </div>
        <div class="btn-group btn-group-example mb-3 mx-auto text-center" role="group">
                      <b-button variant="outline-primary" class="w-sm" :class="{'active' : payment_term == 'monthly'}" @click="payment_term = 'monthly', getSubscriptionPlans()">Monthly</b-button>
                      <b-button variant="outline-primary" class="w-sm" :class="{'active' : payment_term == 'yearly'}" @click="payment_term = 'yearly', getSubscriptionPlans()">Yearly</b-button>
                    </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <h5>No. of Users</h5>
        <div class="px-3 d-flex py-4">
           
            <vue-slide-bar class="w-75 mr-4 pb-4" v-model="user_quantity" :min="1"  :max="200" />

             <div class="w-25 my-auto">
            <b-form-input type="number" v-model="user_quantity" :max="200"></b-form-input>
          </div>
        </div>

        <h5>No. of Shops</h5>
        <div class="px-3 d-flex py-4">
           
            <vue-slide-bar class="w-75 mr-4 pb-4" v-model="shop_quantity" :min="1" :max="20"  />

             <div class="w-25 my-auto">
            <b-form-input type="number" v-model="shop_quantity" :max="20"></b-form-input>
          </div>
        </div>
        
      </div>
      <!--<div class="col-md-3">
        <div class="px-3 py-3">
            <b-form-input type="number" v-model="user_quantity"></b-form-input>
          </div>
        </div>-->
      <div class="col-md-3 py-4">
        <h3 class="text-center text-primary">{{user_quantity}}<small> users</small></h3>
      </div>
    <div class="col-md-1 py-4">
      <h3 class="font-size-20 text-center">X</h3>
    </div>
    <div class="col-md-3 py-4">
        <h3 class="text-center text-primary">£{{current_price}}</h3>
      </div>
       <div class="col-md-1 py-4">
      <h3 class="font-size-20 text-center">=</h3>
    </div>
      <div class="col-md-4 py-4" v-if="payment_term == 'monthly'">
        <h3 class="text-center text-primary">£{{userSubscriptionPrice}}</h3>
        <p class="text-center">per month</p>
      </div>
      <div class="col-md-4 py-4" v-else-if="payment_term == 'yearly'">
        <h3 class="text-center text-primary">£{{userYearlySubscriptionPrice}}</h3>
        <p class="text-center">per year</p>
      </div>
    </div>
    <div class="row justify-content-center">
      <div v-if="plans.length > 0" v-for="(plan, planindex) in plans" :key="plan.id" class="col-xl-4 col-md-6">
        <div class="card plan-box" :class="{'card border border-primary': plan.price_inc == current_price}">
          <div class="card-body p-4">
            <div class="media">
              <div class="media-body">
				<h5>{{plan.name}}</h5>
				<p class="text-muted">{{plan.description}}</p>
              </div>
              <div class="ml-3">
				<i :class="`bx ${plan.icon} h1 text-primary`"></i>
              </div>
            </div>
            <div class="py-4">
              <h2>
				<sup>
                  <small>£</small>
                </sup>
                {{plan.price_inc}}
                <span class="font-size-13" v-if="plan.payment_term == 'monthly'">Per user/month</span>
                <span class="font-size-13" v-else>Per user/year</span>
              </h2>
            </div>
            <div class="text-center plan-btn">
              <a v-if="(user_quantity >= plan.users[0]) && (user_quantity <= plan.users[1])"
                href="javascript: void(0);"
                class="btn btn-primary btn-sm position-relative"
               @click="loadPaymentForm(plan)">Subscribe to this plan</a>
            </div>

            <div class="plan-features mt-5">
              <p v-for="item in JSON.parse(plan.features)">
                <i class="bx bx-checkbox-square text-primary mr-2"></i> {{item.text}}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
      <transition name="fade">
            <div class="payment-modal px-3" v-show="show_payment_form == true" :class="{'d-flex': show_payment_form == true}">
              <b-overlay
                        id="report-generating-overlay"
                        :show="paymentFormLoading"
                        variant="white"
                        opacity="0.85"
                        blur="1px"
                        rounded="md"
                        class="w-100"
                        :class="{'d-flex': show_payment_form == true}"
                      > 
            <div class="payment-modal-inner col-12 col-sm-10 col-md-8 col-lg-6 col-xl-5 mx-auto my-auto p-4 bg-white shadow border-radius-1em mx-auto my-auto p-4 bg-white shadow">
            <div class="payment-modal-header">
            	<a href="javascript:;" @click="show_payment_form = false" class="btn btn-light btn-rounded float-right"><i class="mdi mdi-close noti-icon font-size-16"></i></a>
            </div>
            <div v-if="addPaymentStatus == 2" class="py-4 my-4 text-center">
            	<h4>Hurrah! Your payment has succeed</h4>
            	<p>We're just getting your confirmation ready. You'll be redirected to your confirmation shortly.</p>

            	<!--<a class="btn btn btn-block btn-rounded" @click="subscriptionConfirmation()">Go To Confirmation</a>-->
            </div>
            <div v-if="addPaymentStatus != 2">
            <h4 class="my-4 pb-4 card-title text-secondary" v-if="addPaymentStatus == 3">Oops! An Error Occurred</h4>
              <h4 class="my-4 pb-4 card-title text-secondary" v-else>Enter Your Payment Information</h4>
            <b-alert variant="danger" v-if="addPaymentStatus == 3">{{addPaymentStatusError}}</b-alert>
            <!--<h5 class="text-center">{{selected_plan.name}}</h5>-->
            <h3 class="text-center text-primary" v-if="payment_term == 'monthly'">£{{userSubscriptionPrice}}</h3>
            <h3 class="text-center text-primary" v-if="payment_term == 'yearly'">£{{userYearlySubscriptionPrice}}</h3>
            <p class="text-center" v-if="payment_term == 'monthly'">per month</p>
            <p class="text-center" v-if="payment_term == 'yearly'">per year</p>
            <label>Card Holder Name</label>
                <input id="card-holder-name" type="text" v-model="cardholder_name" name="name" class="form-control border-0 mb-2" placeholder="Name on card">

  
                <div id="payment-element" class="form-control-group" :show="addPaymentStatus !== 2">

                </div>
                <div class="d-flex mt-4">
                <a class="btn btn-block btn-success mt-3 ml-auto mr-1" id="add-card-button" v-on:click="submitPaymentMethod()">
                    Subscribe
                </a>
                <a class="btn btn-block btn-danger close-btn mt-3 mr-auto ml-1" href="javascript:;" @click="show_payment_form = false, selected_plan = ''">Cancel</a>
                </div>
            </div>
            </div>
          </b-overlay>
            </div>
        </transition>
</b-container>
</template>

<style lang="scss" module></style>