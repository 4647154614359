<script>
import Swal from "sweetalert2";
import VueSlideBar from "vue-slide-bar";
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
//import FilterListTree from "../../views/stock/product/category/_partials/FilterListTree";
import FilterListTree from "../../components/widgets/FilterListTree";
import FilterProductBrands from "../../components/widgets/FilterProductBrands";
import FilterProductCollections from "../../components/widgets/FilterProductCollections";
import SideSlidePanel from "../../components/widgets/SideSlidePanel";
import vue2Dropzone from 'vue2-dropzone';


/**
 * Products component
 */
export default {
  components: { VueSlideBar, Layout, PageHeader, FilterListTree, FilterProductBrands, FilterProductCollections, SideSlidePanel, vueDropzone: vue2Dropzone, Swal },
  data() {
    return {
      deleteMode: 'single',
      edit_area: '',
      showTemplateTools: -1,
      pagesData: '',
      title: "Templates",
      items: [
        {
          text: "Web",
          href: "/",
        },
        {
          text: "Templates",
          active: true,
        },
      ],
      error_reponse: '',
      error_message: false,
      success_message: false,
      success_text: 'Your action was successful',
      search: '',
      storageurl: 'https://'+this.$attrs.company.nickname+'.s3.eu-west-2.amazonaws.com',
      showFilters: false,
      sliderPrice: 800,
      priceRange: [0,999999],
      currentPage: 1,
      templateModal: false,
      limit: 25,
      pageslimit: 25,
      pages: '',
      pagessearch: '',
      selectedids: [],
      resourceTypes: [
        {name: 'pages',
        icon: 'bx bx-file',
        children: [
          ],
        },
        {name: 'product',
        icon: 'bx bx-purchase-tag-alt',
        children: [
          {name: 'list', icon: 'bx bx-list-ul'},
          {name: 'page', icon: 'bx bx-file'},
          ],
        },
        {name: 'collections',
        icon: 'bx bx-grid',
        children: [
          {name: 'list', icon: 'bx bx-list-ul'},
          {name: 'page', icon: 'bx bx-file'},
          ],
        },
        {name: 'category',
        icon: 'bx bx-grid',
        children: [
          {name: 'list', icon: 'bx bx-list-ul'},
          {name: 'page', icon: 'bx bx-file'},
          ],
        },
      ],
      selectedResource: '',
      selectedChildResource: '',
      selectedResourceHasChild: false,
      orderDesc: true,
      order: 'desc',
      sortby: 'id',
      trade_active: '',
      public_active: '',
      default_template: {
        id: '',
        uid: '',
        name: 'Default Template',
        description: 'Applied to all pages where no template section is defined',
        header: null,
        content: null,
        footer: null,
        resource: '',
        type: '',
        thumbnail: '',
      },
      template_raw: {
        id: '',
        uid: '',
        name: '',
        description: '',
        header: null,
        content: null,
        footer: null,
        resource: '',
        type: '',
        thumbnail: '',
      },
      template: '',
      brands: '',
      collections: '',
      selectedids: [],
      hoverTemplateSection: -1,
      isCheckAll: false,
      active: '',
      view: 'grid',
      fields: [
        { key: "id", sortable: true },
        { key: "name", sortable: true },
        ],
      filter: null,
      filterOn: [],
      limits: [
        {value: 25, text: "25"},
        {value: 50, text: "50"},
        {value: 100, text: "100"},
      ],
      discountRates: [],
      dropzoneOptions: {
            url: 'https://httpbin.org/post',
            thumbnailHeight: 150,
            thumbnailWidth: 150,
            autoProcessQueue: false,
          },
    };
  },
  created(){
    this.pagesQuery();
    var url = window.location.href;
    if(url.includes('?view=add')){
      this.templateModal = true;
    }
  },
  methods: {
    filterTemplateCopy(data, section){
      return data.filter(item => item[section] !== null);
    },
    addPageToTemplate(template_uid, template_id, page_id){
      axios.post('/api/company/web/template/'+template_uid+'/add-page', {temp_id: template_id, pag_id: page_id}, {headers: {
          'Authorization' : 'Bearer '+this.$attrs.user.api_token
        }}).then(response => {
        var index = this.pagesData.data.findIndex(item => item.uid == template_uid);
        this.pagesData.data[index].pages = response.data;
      }).catch(error => {

      });
    },
    removePageFromTemplate(template_uid, template_id, page_id){
       axios.post('/api/company/web/template/'+template_uid+'/remove-page', {temp_id: template_id, pag_id: page_id}, {headers: {
          'Authorization' : 'Bearer '+this.$attrs.user.api_token
        }}).then(response => {
        var index = this.pagesData.data.findIndex(item => item.uid == template_uid);
        this.pagesData.data.splice(index, 1);
      }).catch(error => {

      });
    },
    templateOnPage(pages, page_uid){
      var index = pages.findIndex(item => item.uid == page_uid);
      if(index > -1){
        return true;
      }else{
        return false;
      }
    },
    valuechange(value) {
      this.clothsData = clothsData.filter(function (product) {
        return product.newprice <= value.currentValue;
      });
    },
    copyTemplateSection(copyfrom, copyto, section){
      var index = this.pagesData.data.findIndex(item => item.id == copyto.id);
      if(index > -1){
        this.pagesData.data[index][section] = JSON.parse(JSON.stringify(copyfrom[section]));
        this.template = this.pagesData.data[index];
        this.edit_area = section;
        this.updateTemplate();
      }
    },
    updateTemplate(){
        axios.post('/api/company/web/template/'+this.template.uid+'/update', {area: this.edit_area, template: this.template}, {headers: {
          'Authorization' : 'Bearer '+this.$attrs.user.api_token
        }}).then(response => {
          //this.page = response.data;
          var self = this;
                  setTimeout(function(){
                   //Vue.swal("Avatar Updated");
                    Swal.fire("Saved", "Your template has been updated.", "success");
                    
                    //this.success_text = "Your page has been saved";
            //this.success_message = true;
                  }, 500);
        }).catch(error => {

        });
      },
    duplicateTemplate(base_template){
      axios.post('/api/company/web/template/create', base_template, {headers: {
        'Authorization' : 'Bearer '+this.$attrs.user.api_token
      }}).then(response => {
        this.pagesData.data.push(response.data);
        this.templateModal = false;
      }).catch(error => {

      })
    },
    addNewTemplate(){
      axios.post('/api/company/web/template/create', this.template, {headers: {
        'Authorization' : 'Bearer '+this.$attrs.user.api_token
      }}).then(response => {
        this.pagesData.data.push(response.data);
        this.templateModal = false;
        var self = this;
                  setTimeout(function(){
                   //Vue.swal("Avatar Updated");
                    Swal.fire("Saved", "Your template has been updated.", "success");
                    
                    //this.success_text = "Your page has been saved";
            //this.success_message = true;
                  }, 500);
      }).catch(error => {

      })
    },
    setActive(active){
            axios.post('/api/company/web/template/set-active', {ids: this.selectedids, active: active}, {headers: {
              'Authorization' : 'Bearer '+this.$attrs.user.api_token
            }}).then(response => {
              
              this.success_text = 'Congratulations, your action has been saved';
              this.success_message = true;
              if(this.selectedids.length > 1){
                return this.productQuery();
              }else{
                return true;
              }
                
            }).catch(error => {

            });
    },
    makeDefault(active){
            axios.post('/api/company/web/template/make-default', {ids: this.selectedids, active: active}, {headers: {
              'Authorization' : 'Bearer '+this.$attrs.user.api_token
            }}).then(response => {
              
              this.success_text = 'Congratulations, your default template has been updated!';
              this.success_message = true;
                return this.productQuery();
              
                
            }).catch(error => {

            });
    },
    destroyTemplate(uid){
      axios.post('/api/company/web/template/'+uid+'/delete', {}, {headers: {
        'Authorization' : 'Bearer '+this.$attrs.user.api_token
      }}).then(response => {
        var index = this.pagesData.data.findIndex(item => item.uid == uid);
        this.pagesData.data.splice(index, 1);
      }).catch(error => {

      });
    },
    destroyTemplateSection(section, uid){
      axios.post('/api/company/web/template/'+uid+'/delete-section', {template_area: section}, {headers: {
        'Authorization' : 'Bearer '+this.$attrs.user.api_token
      }}).then(response => {
        var index = this.pagesData.data.findIndex(item => item.uid == uid);
        this.pagesData.data[index][section] = null;
      }).catch(error => {

      });
    },
    imageAdded(file){
              this.template.thumbnail = file;
          },
    searchFilter(e) {
      const searchStr = e.target.value;
      this.productQuery();
    },

    filterBrands(value){
      this.brands = value;
      this.productQuery();
    },

     filterCollections(value){
      this.collections = value;
      this.productQuery();
    },
     pagesQuery(){
      axios.get('/api/company/web/pages?page=1&limit='+this.pageslimit+'&sortby=id&order=DESC&search='+this.pagessearch+'&active=1', {headers: {'Authorization' : 'Bearer '+this.$attrs.user.api_token}}).then(response => {
        console.log(response);
        this.pages = response.data;
      }).catch(error => {
        this.error_reponse = error.response.error;
        this.error_message = true;
      });
    },
     productQuery(){
      if(this.pagesData != ''){
        if(this.limit > this.pagesData.total){
          this.currentPage = 1;
        }
      }
      if(this.orderDesc == true){
        this.order = 'desc';
      }else{
        this.order = 'asc';
      }
      axios.get('/api/company/web/templates?page='+this.currentPage+'&limit='+this.limit+'&sortby='+this.sortby+'&order='+this.order+'&search='+this.search+'&active='+this.active, {headers: {'Authorization' : 'Bearer '+this.$attrs.user.api_token}}).then(response => {
        console.log(response);
        this.pagesData = response.data;
      }).catch(error => {
        this.error_reponse = error.response.error;
        this.error_message = true;
      });
    },
     filterSelected(id){
        if(this.selectedids.length > 0){
          var exists = this.selectedids.filter(item => item == id);
          if(exists.length > 0){
            return true;
          }else{
            return false
          }
        }else{
          return false;
        }
      },
      checkAll: function(){ 
                 this.isCheckAll = !this.isCheckAll; 
                 this.selectedids = []; 
                 if(this.isCheckAll){ 
                     // Check all 
                     for (var key in this.pagesData.data) {          
                        this.selectedids.push(this.pagesData.data[key].id); 
                     } 
                 } 
              }, 
      updateCheckall: function(){ 
                 if(this.selectedids.length == this.pagesData.data.length){
                    this.isCheckAll = true; 
                 }else{ 
                    this.isCheckAll = false; 
                 } 
      },
  },
  beforeMount(){
      this.productQuery();
  },
  computed:{
   
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
       <b-col md="12">
                    <transition name="slide-success" mode="out-in">
                  <b-alert id="success-message" variant="success" :show="success_message"  dismissible @dismissed="success_message = !success_message">
                      <p class="font-size-14">{{success_text}}</p>
                    </b-alert>
                  </transition>
                  </b-col>
      <div :class="{'col-lg-12': showFilters == true, 'col-lg-12': showFilters == false}">
        <div class="row mb-3">
          <div class="col-xl-1 col-sm-6">
            <div class="mt-2">
              <b-form-select class="form-control" v-model="limit" :options="limits" @input="productQuery()"></b-form-select>
            </div>
          </div>
         
          <div class="col-lg-2 col-sm-6 d-flex">
          <form class="my-auto mr-auto">
            <small class="mx-auto my-2 text-center d-block">Results {{pagesData.from}} - {{pagesData.to}} of {{pagesData.total}}</small>
          </form>
          <div v-if="view == 'grid'" class="text-center my-auto ml-auto"><div
                        class="form-check form-switch form-switch-sm mb-0 mr-auto p-0 mt-auto d-flex"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input mx-auto my-auto" 
                          type="checkbox"
                          id="SwitchCheckSizeCheckall"
                         
                          v-model="isCheckAll"
                          @click='checkAll()'
                          name="active"
                        />
                        <label class="d-inline-block my-auto ml-2" for="SwitchCheckSizeCheckall">Select All</label>
                    </div></div>
        </div>
        <div class="col-lg-5 col-sm-6 d-flex">
            <div class="mt-sm-0 form-inline w-100" >
              <div class="search-box mr-2 ml-auto">
                <div class="position-relative">
                  <input
                    type="text"
                    class="form-control w-100 border-0"
                    placeholder="Search name, reference or description..."
                    v-model="search"
                    @keyup.enter="searchFilter($event)"
                  />
                  <i class="bx bx-search-alt search-icon"></i>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-sm-6 d-flex">
            <div class="d-flex ml-auto">
              <ul class="nav nav-pills product-view-nav">
                <li class="nav-item">
                  <a class="nav-link" :class="{'active': showFilters == true }" href="javascript: void(0);" @click="showFilters = !showFilters">
                    <i class="bx bx-filter-alt"></i>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" :class="{'active': view == 'grid' }" href="javascript: void(0);" @click="view = 'grid'">
                    <i class="bx bx-grid-alt"></i>
                  </a>
                </li>
               <!-- <li class="nav-item">
                  <a class="nav-link" :class="{'active': view == 'list' }" href="javascript: void(0);" @click="view = 'list'">
                    <i class="bx bx-list-ul"></i>
                  </a>
                </li>-->
                <li class="nav-item">
                 <b-dropdown text="Bulk Actions" variant="primary" class="rounded">
                  <b-dropdown-item href="javascript: void(0);" @click="setActive(true)"><i class="bx bx-check-circle font-size-14 text-success"></i> Set Active</b-dropdown-item>
                  <b-dropdown-item href="javascript: void(0);" @click="setActive(false)"><i class="bx bx-x-circle font-size-14 text-danger"></i> Set Inactive</b-dropdown-item>
                  <b-dropdown-item href="javascript: void(0);" @click="bulkDuplicateResource()"><i class="bx bx-copy-alt font-size-14"></i> Duplicate</b-dropdown-item>
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-item href="javascript: void(0);" v-b-modal.delete-modal @click="deleteMode = 'bulk'"><i class="bx bx-trash-alt font-size-14"></i> Delete</b-dropdown-item>
                </b-dropdown>
                </li>
                <li class="nav-item">
                 <a
                    class="btn btn-success btn-rounded mb-2 mr-2 text-white"
                    @click="templateModal = true"
                  >
                    <i class="mdi mdi-plus mr-1"></i> New
                  </a>
                </li>
              </ul>
              <div class="text-sm-right">
                  
                </div>
          </div>
        </div>
      </div>
        <!--<div class="row">
          <div class="col-lg-12">
            <b-pagination
              v-if="pagesData.data.length > 0"
              class="justify-content-center mb-0"
              pills
              v-model="currentPage"
              :total-rows="pagesData.total"
              :per-page="pagesData.per_page"
              aria-controls="my-table"
              @input="productQuery"
            ></b-pagination>
             <small class="mx-auto my-2 text-center d-block">Records {{pagesData.from}} - {{pagesData.to}} of {{pagesData.total}} // Page {{pagesData.current_page}} of {{pagesData.last_page}}</small>
          </div>
        </div>-->
      <b-row>
        <!--<div id="my-table" v-if="view == 'grid'" class="col-xl-4 col-sm-6">
        <div class="card overflow-hidden border-radius-1em">
          <div class="bg-soft-primary min-h-100">
            <div class="row">
              
              <div class="col-12 align-self-end">
                <div class="row">
                 <div class="col-md-12 position-absolute"> 
                
                <a id="tooltip-add"  :href="'/web/page/'+default_template.uid" class="mt-3 mr-3 btn btn-primary font-size-16 btn-rounded ml-auto float-right"><i class="mdi mdi-eye-outline"></i></a>
              </div>
              </div>
               <b-card class="border shadow-none mb-0">
                <div class="d-flex w-100">
                  <a class="mr-2 ml-auto mb-2 btn btn-white bg-white">
                        <i class="bx bx-cog font-size-18"></i>
                      </a>
                    </div>
                  <b-card class="shadow-none border-radius-1em border-bottom" @mouseenter="hoverTemplateSection = default_template.uid+'-header'" @mouseleave="hoverTemplateSection = -1">
                    <div class="w-100 text-center d-flex" v-if="default_template.header !== null" :class="{'text-dark' : hoverTemplateSection !== default_template.uid+'-header', 'text-primary' : hoverTemplateSection == default_template.uid+'-header'}">
                      <span class="mr-auto my-2">Header</span>
                      <div class="d-flex my-auto" v-if="hoverTemplateSection == default_template.uid+'-header'">
                      <a class="btn btn-light btn-rounded mr-2" :href="'/web/template/'+default_template.uid+'?action=edit&section=header'">
                        <i class="bx bx-pencil text-info"></i>
                      </a>
                      <a class="btn btn-light btn-rounded">
                        <i class="bx bx-trash-alt text-danger"></i>
                      </a>
                      </div>
                    </div>
                    <div class="w-100 text-center d-flex" v-else :class="{'text-dark' : hoverTemplateSection !== default_template.uid+'-header', 'text-primary' : hoverTemplateSection == default_template.uid+'-header'}">
                      <span class="text-muted mr-auto my-2"><i>Add Header Template</i></span>
                      <div class="d-flex my-auto" v-if="hoverTemplateSection == default_template.uid+'-header'">
                      <a class="btn btn-light btn-rounded mr-2" :href="'/web/template/'+default_template.uid+'?action=add&section=header&default=true'">
                        Add Section <i class="mdi mdi-plus text-info"></i>
                      </a>
                     
                      </div>
                    </div>
                  </b-card>
                  <b-card class="shadow-none border-radius-1em border" @mouseenter="hoverTemplateSection = default_template.uid+'-body'" @mouseleave="hoverTemplateSection = -1">
                    <div class="w-100 text-center d-flex"  v-if="default_template.content !== null" :class="{'text-dark' : hoverTemplateSection !== default_template.uid+'-body', 'text-primary' : hoverTemplateSection == default_template.uid+'-body'}">
                      <span class="mr-auto my-2">Body</span>
                      <div class="d-flex my-auto" v-if="hoverTemplateSection == default_template.uid+'-body'">
                      <a class="btn btn-light btn-rounded mr-2" :href="'/web/template/'+default_template.uid+'?action=edit&section=body'">
                        <i class="bx bx-pencil text-info"></i>
                      </a>
                      <a class="btn btn-light btn-rounded">
                        <i class="bx bx-trash-alt text-danger"></i>
                      </a>
                      </div>
                    </div>
                    <div class="w-100 text-center d-flex" v-else :class="{'text-dark' : hoverTemplateSection !== default_template.uid+'-body', 'text-primary' : hoverTemplateSection == default_template.uid+'-body'}">
                      <span class="text-muted mr-auto my-2"><i>Add Body Template</i></span>
                      <div class="d-flex my-auto" v-if="hoverTemplateSection == default_template.uid+'-body'">
                      <a class="btn btn-light btn-rounded mr-2" :href="'/web/template/'+default_template.uid+'?action=add&section=body&default=true'">
                        Add Section <i class="mdi mdi-plus text-info"></i>
                      </a>
                     
                      </div>
                    </div>
                  </b-card>
                  <b-card class="shadow-none border-radius-1em border" @mouseenter="hoverTemplateSection = default_template.uid+'-footer'" @mouseleave="hoverTemplateSection = -1">
                    <div class="w-100 text-center d-flex" v-if="default_template.footer !== null"  :class="{'text-dark' : hoverTemplateSection !== default_template.uid+'-footer', 'text-primary' : hoverTemplateSection == default_template.uid+'-footer'}">
                      <span class="mr-auto my-2">Footer</span>
                      <div class="d-flex my-auto" v-if="hoverTemplateSection == default_template.uid+'-footer'">
                      <a class="btn btn-light btn-rounded mr-2" :href="'/web/template/'+default_template.uid+'?action=edit&section=footer'">
                        <i class="bx bx-pencil text-info"></i>
                      </a>
                      <a class="btn btn-light btn-rounded">
                        <i class="bx bx-trash-alt text-danger"></i>
                      </a>
                      </div>
                    </div>
                    <div class="w-100 text-center d-flex" v-else :class="{'text-dark' : hoverTemplateSection !== default_template.uid+'-footer', 'text-primary' : hoverTemplateSection == default_template.uid+'-footer'}">
                      <span class="text-muted mr-auto my-2"><i>Add Footer Template</i></span>
                      <div class="d-flex my-auto" v-if="hoverTemplateSection == default_template.uid+'-footer'">
                      <a class="btn btn-light btn-rounded mr-2" :href="'/web/template/'+default_template.uid+'?action=add&section=footer&default=true'">
                        Add Section <i class="mdi mdi-plus text-info"></i>
                      </a>
                     
                      </div>
                    </div>
                  </b-card>
              
                </b-card>
              </div>
            </div>
          </div>
          <div class="card-body pt-0">
            <div class="row">
              <div class="col-sm-12 text-center pt-3 px-4 mb-2">
               <div class="avatar-xl profile-user-wid mb-4">
                  <img class="img-thumbnail rounded-circle h-100" :src="`/public/storage/${default_template.image}`" alt />
                </div>
                <h4 class="font-size-18 text-truncate mt-2">{{default_template.name}}</h4>
                <p class="text-muted mb-0 text-truncate" v-html="default_template.description"></p>
              </div>

              <div class="col-sm-12">
              <div dir="ltr" class="form-check form-switch form-switch-sm mb-0 mr-auto p-0 d-flex mb-auto">
                    Active
                    <input type="checkbox" id="SwitchCheckDefault" name="ssl" class="form-check-input ml-2 mb-auto">
                  </div>
              </div>
            </div>
          </div>
        </div>
    </div>-->
      <div id="my-table" v-if="view == 'grid'" v-for="(list, index) in pagesData.data" :key="list.id" class="col-xl-4 col-sm-6">
        <div class="card border-radius-1em">
          <div class="bg-soft-primary min-h-100">
            <div class="row">
              
              <div class="col-12 align-self-end">

                <div class="">
                 <div class="position-absolute"> 
                
                <a v-b-tooltip.hover title="Preview"  :href="'/web/page/'+list.uid" class="mt-3 ml-3 btn btn-primary font-size-16 btn-rounded ml-auto float-left z-index-5"><i class="mdi mdi-eye-outline"></i></a>
              </div>
              </div>
               <b-card class="border shadow-none mb-0">
                <div class="d-flex w-100">
                  <a class="btn btn-outline-light btn-rounded text-muted ml-auto my-auto" href="javascript:;" v-b-modal.update-tempate @click="template = list">
                    <i class="bx bx-edit-alt"></i>
                  </a>
                  <b-button variant="outline-light" :disabled="list.default == 1" href="javascript:;" @click="selectedids = [], selectedids.push(list.id), makeDefault(list.active)" class="btn btn-outline-light btn-rounded ml-2"><i class="bx bx-star my-1" :class="{'text-warning' : list.default == 1}"></i></b-button>
                  <b-dropdown id="template-menu" variant="outline-light" class="more-options-dropdown btn-rounded ml-2" right>
                   <template #button-content>
                        <i class="bx bx-dots-vertical font-size-18"></i>
                      </template>
                      <b-dropdown-text class="list-group list-group-flush" text-class="list-group-item">
                        <a v-if="showTemplateTools == -1"  @click="showTemplateTools = index">
                     <i class="mdi mdi-plus">
                        </i> Apply to {{list.resource}}
                      </a>
                      <a v-else @click="showTemplateTools = -1">
                        <i class="mdi mdi-close-circle">
                        </i> Apply to {{list.resource}}
                      </a>
                        <transition name="fade-right">
                        <b-list-group v-if="showTemplateTools == index" flush class="pages-submenu hide" style="position: absolute; left: -150%; top: 0; width: 150%" >
                        <b-list-group-item v-if="list.resource == 'pages'" v-for="page in pages.data">
                          <span class="d-flex" v-if="templateOnPage(list.pages, page.uid) == true">
                            <span class="my-auto mr-auto">{{page.name}}</span>
                            <a href="javascript:;" class="btn btn-light btn-rounded" v-b-tooltip.hover.right title="Remove From Template" @click="removePageFromTemplate(list.uid, list.id, page.id)">
                            <i class="bx bx-check-circle text-success"></i>
                            </a>
                          </span>
                          <span class="d-flex" v-else>
                            <span class="my-auto mr-auto">{{page.name}}</span>
                            <a href="javascript:;" class="btn btn-light btn-rounded" v-b-tooltip.hover.right title="Add to Template" @click="addPageToTemplate(list.uid, list.id, page.id)">
                            <i class="mdi mdi-plus"></i>
                          </a>
                          </span>
                        </b-list-group-item>

                        <SelectCompanyCategoryTree v-else-if="list.resource == 'category'" :selectedid="newcategory.parent_id" :category_data="all_categories.data" @category-select="addParentCategory" :company="$attrs.company"></SelectCompanyCategoryTree>
                        </b-list-group>
                      </transition>
                      </b-dropdown-text>
                    </b-dropdown-item>
                      <b-dropdown-item href="javascript:;" @click="duplicateTemplate(list)">
                        Duplicate
                      </b-dropdown-item>
                      <b-dropdown-item href="javascript:;" v-b-modal.delete-modal @click="selectedids = [], selectedids.push(list.uid)">
                        Delete
                      </b-dropdown-item>
                    </b-dropdown>
                    </div>
                  <b-card class="shadow-none border-radius-1em border-bottom" @mouseenter="hoverTemplateSection = list.uid+'-header'" @mouseleave="hoverTemplateSection = -1"  :class="{'border-5-primary' : list.header !== null}">
                    <div class="w-100 text-center d-flex" v-if="list.header !== null" :class="{'text-dark' : hoverTemplateSection !== list.uid+'-header', 'text-primary' : hoverTemplateSection == list.uid+'-header'}">
                      <span class="mr-auto my-2">Header</span>
                      <div class="d-flex my-auto" v-if="hoverTemplateSection == list.uid+'-header'">
                      <a class="btn btn-light btn-rounded mr-2" :href="'/web/template/'+list.uid+'?action=edit&section=header'">
                        <i class="bx bx-pencil text-info"></i>
                      </a>
                      <a class="btn btn-light btn-rounded" @click="destroyTemplateSection('header', list.uid)">
                        <i class="bx bx-trash-alt text-danger"></i>
                      </a>
                      </div>
                    </div>
                    <div class="w-100 text-center d-flex" v-else :class="{'text-dark' : hoverTemplateSection !== list.uid+'-header', 'text-primary' : hoverTemplateSection == list.uid+'-header'}">
                      <span class="text-muted mr-auto my-2"><i>Add Header Template</i></span>
                      <div class="d-flex my-auto" v-if="hoverTemplateSection == list.uid+'-header'">
                      <b-dropdown variant="light" class="rounded" right>
                        <template #button-content>
                           Add Section <i class="mdi mdi-plus text-info"></i>
                        </template>
                        <b-dropdown-item :href="'/web/template/'+list.uid+'?action=add&section=header'">Build Custom</b-dropdown-item>
                        <b-dropdown-divider ></b-dropdown-divider>
                        <b-dropdown-text class="px-3 py-3 mb-3 bg-light text-center text-uppercase font-size-12" style="width: 340px;" text-class="m-0 spaced-title">Copy Existing Template Header</b-dropdown-text>
                        <b-dropdown-item v-for="copyitem in filterTemplateCopy(pagesData.data, 'header')" @click="copyTemplateSection(copyitem, list, 'header')" href="javascript:;">{{copyitem.name}}</b-dropdown-item>
                      </b-dropdown>  
                      </div>
                    </div>
                  </b-card>
                  <b-card class="shadow-none border-radius-1em border" @mouseenter="hoverTemplateSection = list.uid+'-body'" @mouseleave="hoverTemplateSection = -1" :class="{'border-5-primary' : list.content !== null}">
                    <div class="w-100 text-center d-flex"  v-if="list.content !== null" :class="{'text-dark' : hoverTemplateSection !== list.uid+'-body', 'text-primary' : hoverTemplateSection == list.uid+'-body'}">
                      <span class="mr-auto my-2">Body</span>
                      <div class="d-flex my-auto" v-if="hoverTemplateSection == list.uid+'-body'">
                      <a class="btn btn-light btn-rounded mr-2" :href="'/web/template/'+list.uid+'?action=edit&section=body'">
                        <i class="bx bx-pencil text-info"></i>
                      </a>
                      <a class="btn btn-light btn-rounded"  @click="destroyTemplateSection('content', list.uid)">
                        <i class="bx bx-trash-alt text-danger"></i>
                      </a>
                      </div>
                    </div>
                    <div class="w-100 text-center d-flex" v-else :class="{'text-dark' : hoverTemplateSection !== list.uid+'-body', 'text-primary' : hoverTemplateSection == list.uid+'-body'}">
                      <span class="text-muted mr-auto my-2"><i>Add Body Template</i></span>
                      <div class="d-flex my-auto" v-if="hoverTemplateSection == list.uid+'-body'">
                      <b-dropdown variant="light" class="rounded" right>
                        <template #button-content>
                           Add Section <i class="mdi mdi-plus text-info"></i>
                        </template>
                        <b-dropdown-item :href="'/web/template/'+list.uid+'?action=add&section=body'">Build Custom</b-dropdown-item>
                        <b-dropdown-divider ></b-dropdown-divider>
                        <b-dropdown-text class="px-3 py-3 mb-3 bg-light text-center text-uppercase font-size-12" style="width: 340px;" text-class="m-0 spaced-title">Copy Existing Template Content</b-dropdown-text>
                        <b-dropdown-item v-for="copyitem in filterTemplateCopy(pagesData.data, 'content')" @click="copyTemplateSection(copyitem, list, 'content')" href="javascript:;">{{copyitem.name}}</b-dropdown-item>
                      </b-dropdown>  
                                       
                      </div>
                    </div>
                  </b-card>
                  <b-card class="shadow-none border-radius-1em border" @mouseenter="hoverTemplateSection = list.uid+'-footer'" @mouseleave="hoverTemplateSection = -1" :class="{'border-5-primary' : list.footer !== null}">
                    <div class="w-100 text-center d-flex" v-if="list.footer !== null"  :class="{'text-dark' : hoverTemplateSection !== list.uid+'-footer', 'text-primary' : hoverTemplateSection == list.uid+'-footer'}">
                      <span class="mr-auto my-2">Footer</span>
                      <div class="d-flex my-auto" v-if="hoverTemplateSection == list.uid+'-footer'">
                      <a class="btn btn-light btn-rounded mr-2" :href="'/web/template/'+list.uid+'?action=edit&section=footer'">
                        <i class="bx bx-pencil text-info"></i>
                      </a>
                      <a class="btn btn-light btn-rounded"  @click="destroyTemplateSection('footer', list.uid)">
                        <i class="bx bx-trash-alt text-danger"></i>
                      </a>
                      </div>
                    </div>
                    <div class="w-100 text-center d-flex" v-else :class="{'text-dark' : hoverTemplateSection !== list.uid+'-footer', 'text-primary' : hoverTemplateSection == list.uid+'-footer'}">
                      <span class="text-muted mr-auto my-2"><i>Add Footer Template</i></span>
                      <div class="d-flex my-auto" v-if="hoverTemplateSection == list.uid+'-footer'">
                      <b-dropdown variant="light" class="rounded" right>
                        <template #button-content>
                           Add Section <i class="mdi mdi-plus text-info"></i>
                        </template>
                        <b-dropdown-item :href="'/web/template/'+list.uid+'?action=add&section=footer'">Build Custom</b-dropdown-item>
                        <b-dropdown-divider ></b-dropdown-divider>
                        <b-dropdown-text class="px-3 py-3 mb-3 bg-light text-center text-uppercase font-size-12" style="width: 340px;" text-class="m-0 spaced-title">Copy Existing Template Footer</b-dropdown-text>
                        <b-dropdown-item v-for="copyitem in filterTemplateCopy(pagesData.data, 'footer')" @click="copyTemplateSection(copyitem, list, 'footer')" href="javascript:;">{{copyitem.name}}</b-dropdown-item>
                      </b-dropdown>  
                     
                      </div>
                    </div>
                  </b-card>
              
                </b-card>
              </div>
            </div>
          </div>
          <div class="card-body pt-0">
            <div class="row">
              <div class="col-sm-12 text-center px-4 mb-2">
               <!-- <div class="avatar-xl profile-user-wid mb-4">
                  <img class="img-thumbnail rounded-circle h-100" :src="`/public/storage/${list.image}`" alt />
                </div>-->
                <div class="avatar-sm profile-user-wid mx-auto">
                   <div class="text-center" v-if="list.image">
                      <b-form-checkbox class="select-row-btn w-image" v-model="selectedids" :value="list.id" button-variant="primary" @input="updateSelected" button>
                        <i v-if="filterSelected(list.id)" class="bx bx-check"></i>
                        <img v-else :src="`${list.image}`" alt class="avatar-sm img-thumbnail rounded-circle h-100" />
                      </b-form-checkbox>
                    </div>
                    <div class="text-center" v-else >
                        <b-form-checkbox class="select-row-btn" v-model="selectedids" :value="list.id" button-variant="primary" @input="updateSelected" button>

                          <i v-if="filterSelected(list.id)" class="bx bx-check"></i><span class="avatar-title rounded-circle" v-else>{{(list.name).slice(0,1)}}</span></b-form-checkbox>
                    </div> 
                </div>
                <h4 class="font-size-18 text-truncate mt-3">{{list.name}}</h4>
                <p class="text-muted mb-0 text-truncate" v-html="list.description"></p>
              </div>
            </div>
          </div>
          <div class="card-footer bg-transparent border-top py-4">
            <div class="row">
              <div class="col-sm-12 d-flex">

              <div :class="{'mr-auto': list.default == 0}" dir="ltr" class="form-check form-switch form-switch-sm mb-0 p-0 d-flex mb-auto">
                    
                    <input type="checkbox" :id="'SwitchCheckSizeactive'+list.id"
                          v-model="list.active"
                          :value="list.active"
                          name="active"
                          @change="selectedids = [], selectedids.push(list.id), setActive(list.active)"
                          class="form-check-input ml-2 mb-auto">
                          <label class="form-check-label ml-2" :for="'SwitchCheckSizeactive'+list.id">Active</label>
                  </div>
                  <div
                        class="form-check form-switch-sm my-auto d-flex ml-0 mr-auto"
                        dir="ltr"
                        v-if="list.default == 1"
                      >
                      <i class="bx bx-star font-size-18 text-warning"></i> <label class="form-check-label">Default</label>
                    </div>
              <div>
                <span class="badge badge-light font-size-12 bg-light text-dark text-capitalize">
                  {{list.resource}}
                </span>
                <span v-if="list.type !== null" class="badge badge-light font-size-12 bg-light text-dark text-capitalize">
                  {{list.type}}
                </span>
              </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  </b-row>
        
        <!-- end row -->

        
       
            
            <div class="row">
              <div class="col-lg-12" v-if="pagesData !== ''">
                <b-pagination
              class="justify-content-center"
              pills
              v-model="currentPage"
              :total-rows="pagesData.total"
              :per-page="pagesData.per_page"
              aria-controls="my-table"
              @input="productQuery"
            ></b-pagination>
             <small class="mx-auto my-2 text-center d-block">Records {{pagesData.from}} - {{pagesData.to}} of {{pagesData.total}} // Page {{pagesData.current_page}} of {{pagesData.last_page}}</small>
              </div>
            </div>
          </div>
        </div>
    <!-- end row -->
     <b-modal id="delete-modal" size="md" hide-footer hide-header centered>
        <b-card class="border-radius-1em border shadow-none">
          <h4>Are you sure?</h4>
          <p v-if="deleteMode == 'bulk'">You are about to delete {{selectedids.length}} products. Are you sure you want to do this? This action is not reversable</p>
           <p v-if="deleteMode == 'single'">You are about to delete this category. Are you sure you want to do this? This action is not reversable</p>

           <div class="d-flex">
            <a class="btn btn-danger" v-if="deleteMode == 'bulk'" @click="bulkDestroyResource">
              Delete
            </a>
             <a class="btn btn-danger"  v-if="deleteMode == 'single'" @click="destroyTemplate(selectedids[0])">
              Delete
            </a>
            <a class="btn btn-success" @click="$bvModal.hide('delete-modal')">
              Cancel
            </a>
          </div>
        </b-card>
      </b-modal>
    <b-modal id="update-tempate" hide-header hide-footer centered size="lg"> 
      <h4 class="mb-3">Update Template</h4>
      <b-row>
       <b-col md="12">
             <b-card class="border border-radius-1em shadow-none">
            <b-form-group label="Template Name" class="mt-2">
                <b-form-input type="text" v-model="template.name"></b-form-input>
              </b-form-group>
              <b-form-group label="Description" class="mt-2">
                <b-form-textarea type="text" v-model="template.description"></b-form-textarea>
              </b-form-group>
              <b-form-group label="Template Thumbnail" class="mt-2">
                <vue-dropzone id="dropzone" ref="myVueDropzone" :use-custom-slot="true" :options="dropzoneOptions" @vdropzone-file-added="imageAdded"  @vdropzone-drop="imageAdded">
                              <div class="dropzone-custom-content">
                                  <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                                  <h4>Drop files here or click to upload.</h4>
                              </div>
                              </vue-dropzone>
              </b-form-group>
              <input type="hidden" name="thumbnail" v-model="template.thumbnail" />
              <b-form-group label="Active" class="mt-2">
                         <div
                                class="form-check form-switch form-switch-lg w-75 d-flex"
                                dir="ltr"
                              >
                         
                              <input
                                  class="form-check-input ml-auto my-auto" 
                                  type="checkbox"
                                  id="SwitchCheckSizesm"
                                 
                                  v-model="template.active"
                                
                                  name="active"
                                />
                            </div>
              </b-form-group>
              </b-card>
            </b-col>
            <b-col md="12"  @click="updateTemplate()">
            <a class="btn btn-success btn-block btn-rounded">
              Update
            </a>
          </b-col>
          </b-row>
    </b-modal>
    <b-modal id="template-type" v-model="templateModal" hide-header hide-footer centered size="lg">

      <b-card class="border border-radius-1em shadow-none">
        <h4 class="mb-3">Select Template Type</h4>
        <b-row>
          <b-col md="3" v-for="resource in resourceTypes">
            <b-card v-if="resource.children.length > 0" class="border border-radius-1em shadow-none" body-class="text-center" @click="template = JSON.parse(JSON.stringify(template_raw)), selectedResourceHasChild = true, selectedResource = resource, template.resource = resource.name" :class="{'border-primary border-2' : selectedResource == resource}">
              <i class="font-size-42 mt-2" :class="[{'text-primary' : selectedResource == resource}, {'text-muted' : selectedResource !== resource}, resource.icon]"></i>
              <h5 class="text-capitalize">{{resource.name}}</h5>
            </b-card>
            <b-card v-else class="border border-radius-1em shadow-none" body-class="text-center" @click="template = JSON.parse(JSON.stringify(template_raw)), selectedResourceHasChild = false, selectedResource = resource, template.resource = resource.name"  :class="{'border-primary border-2' : selectedResource == resource}">
              <i class="font-size-42 mt-2" :class="[{'text-primary' : selectedResource == resource}, {'text-muted' : selectedResource !== resource}, resource.icon]"></i>
              <h5 class="text-capitalize">{{resource.name}}</h5>
            </b-card>
          </b-col>
        </b-row>
        <b-row v-if="(selectedResource !== '') && (selectedResourceHasChild == true)">
          <b-col md="6" v-for="cresource in selectedResource.children">
            <b-card class="border border-radius-1em shadow-none" body-class="text-center d-flex justify-content-center align-items-center"  :class="{'border-primary border-2' : selectedChildResource == cresource}" @click="selectedChildResource = cresource, template.type = cresource.name">
              <i class="font-size-18 my-auto mr-1"  :class="[{'text-primary' : selectedChildResource == cresource}, {'text-muted' : selectedChildResource !== cresource}, cresource.icon]"></i>
              <h5 class="text-capitalize my-auto">{{cresource.name}}</h5>
            </b-card>
          </b-col>
           <b-col md="12"  v-if="(selectedChildResource !== '')">
             <b-card class="border border-radius-1em shadow-none">
            <b-form-group label="Template Name" class="mt-2">
                <b-form-input type="text" v-model="template.name"></b-form-input>
              </b-form-group>
              <b-form-group label="Description" class="mt-2">
                <b-form-textarea type="text" v-model="template.description"></b-form-textarea>
              </b-form-group>
              <b-form-group label="Template Thumbnail" class="mt-2">
                <vue-dropzone id="dropzone" ref="myVueDropzone" :use-custom-slot="true" :options="dropzoneOptions" @vdropzone-file-added="imageAdded"  @vdropzone-drop="imageAdded">
                              <div class="dropzone-custom-content">
                                  <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                                  <h4>Drop files here or click to upload.</h4>
                              </div>
                              </vue-dropzone>
              </b-form-group>
              <input type="hidden" name="thumbnail" v-model="template.thumbnail" />
              <b-form-group label="Active" class="mt-2">
                         <div
                                class="form-check form-switch form-switch-lg w-75 d-flex"
                                dir="ltr"
                              >
                         
                              <input
                                  class="form-check-input ml-auto my-auto" 
                                  type="checkbox"
                                  id="SwitchCheckSizesm"
                                 
                                  v-model="template.active"
                                
                                  name="active"
                                />
                            </div>
              </b-form-group>
              </b-card>
            </b-col>
          <b-col md="12"  @click="addNewTemplate()">
            <a class="btn btn-success btn-block btn-rounded">
              Save
            </a>
          </b-col>
        </b-row>
        <b-row v-if="(selectedResource !== '') && (selectedResourceHasChild == false)">
          <b-col md="12">
             <b-card class="border border-radius-1em shadow-none">
            <b-form-group label="Template Name" class="mt-2">
                <b-form-input type="text" v-model="template.name"></b-form-input>
              </b-form-group>
              <b-form-group label="Description" class="mt-2">
                <b-form-textarea type="text" v-model="template.description"></b-form-textarea>
              </b-form-group>
              <b-form-group label="Template Thumbnail" class="mt-2">
                <vue-dropzone id="dropzone" ref="myVueDropzone" :use-custom-slot="true" :options="dropzoneOptions" @vdropzone-file-added="imageAdded"  @vdropzone-drop="imageAdded">
                              <div class="dropzone-custom-content">
                                  <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                                  <h4>Drop files here or click to upload.</h4>
                              </div>
                              </vue-dropzone>
              </b-form-group>
              <input type="hidden" name="thumbnail" v-model="template.thumbnail" />
              <b-form-group label="Active" class="mt-2">
                         <div
                                class="form-check form-switch form-switch-lg w-75 d-flex"
                                dir="ltr"
                              >
                         
                              <input
                                  class="form-check-input ml-auto my-auto" 
                                  type="checkbox"
                                  id="SwitchCheckSizesm"
                                 
                                  v-model="template.active"
                                
                                  name="active"
                                />
                            </div>
              </b-form-group>
              </b-card>
            </b-col>
            <b-col md="12">
            <a class="btn btn-success btn-block btn-rounded" @click="addNewTemplate()">
              Save
            </a>
          </b-col>
        </b-row>
      </b-card>
    </b-modal>
  </Layout>
</template>
