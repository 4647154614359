<script>
import Layout from '../../layouts/main'
import PageHeader from '../../components/page-header'
import Subscription from '../../components/widgets/subscription'
import vue2Dropzone from 'vue2-dropzone'
import VueGoogleAutocomplete from "vue-google-autocomplete";
import Multiselect from "vue-multiselect";
import simplebar from "simplebar-vue";
import { companyTerms } from "./terms";
import SearchCompanyUser from "../../components/widgets/SearchCompanyUser";
import Swal from 'sweetalert2';

	export default{
		components: { vueDropzone: vue2Dropzone, Layout, PageHeader, Subscription, VueGoogleAutocomplete, Multiselect, simplebar, SearchCompanyUser, Swal},
		data(){
			return{
				companies_house_search: '',
				autoSetupComplete: false,
				loadingListingForm: false,
				loadingWebsiteForm: false,
				loadingUsersForm: false,
				loadingProfessionalBodies: false,
				setupprogress: 0,
				hover: -1,
				user_roles: [],
				listing_types: [],
				stripeAccount: [],
				newListing: {
					cover_image: '',
					logo: '',
					listing_type_id: 0,
	        address1: '',
	        address2: '',
	        address3: '',
	        postcode: '',
	        city: '',
	        longitude: 0,
	        latitude: 0,
	        gplace_id: '',
	        country_id: 0,
	        county_id: 0,
	        manager_id: 0,
	        manager: null,
	        active: 0,
	        public: 0, 
	        trade: 0,
	      },
	      companies_search_results: [],
	      vat_check_result: '',
	      show_vat_result: false,
	      vat_company_match: false,
	      show_search_results: false,
	      selected_registered_company: '',
				name: 'company-create',
		      	title: 'Add Company',
		      	load_error: '',
		      	googleSearchAddress: '',
		      	googleSearchRegisteredAddress: '',
		      	googleSearchListingAddress: '',
		      	subscription_result: {
							payment_intent: '',
							subscription: '',
							quantity: 0,
							price_per_unit: 0.00,
							flat_price: 0.00,
						},
						setupoptions: {
				      department: false,
				      projectstatuses: false,
				      actiontypes: false,
				      reviewtypes: false,
				      companyid: 0,
				      nickname: '',
				      import: 0,
				      users: [],
				      introtour: 1,
				      auto: 0,
				      gemesys_website: 1,
				      payments: 0,
				    },
		      	company_types: [],
		      	privacy_policy: companyTerms,
		      	error_reponse: [],
		      	error_message: false,
		      	csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
		      	professional_bodies: [{text: 'NAJ', value: 'NAJ'}, {text: 'CMJ', value: 'CMJ'}, {text: 'Houlden Group', value: 'Houlden Group'}],
						professional_body_index: -1,
						professional_body: '',
						membership_no: '',
		      	newFormData: {
			        companyname: '',
			        nickname: '',
			        website: '',
			        logo: [],
			        description: '',
			        privacy_policy: '',
			        email: '',
			        telephone: '',
			        address1: '',
			        address2: '',
			        address3: '',
			        country_id: '',
			        country: '',
			        county: '',
			        county_id: '',
			        gplace_id: '',
			        longitude: 0.000000000,
			        latitude: 0.000000000,
			        postcode: '',
			        accpted_terms: '',
			        privacy_policy: '',
			        active: 0,
			        public: 0,
			        trade: 0,
			        company_type_id: '',
			        company_legal_status: '',
			        company_registration_settings: {
			        	address1: '',
				        address2: '',
				        address3: '',
				        country_id: '',
				        country: '',
				        county: '',
				        county_id: '',
				        gplace_id: '',
				        longitude: 0.000000000,
				        latitude: 0.000000000,
				        postcode: '',
				        vat_no: '',
				        vat_registered: 0,
				        registration_no: '',
				        nag_membership_no: '',
				        cmj_membership_no: '',
				        holden_membership_no: '',
				        professional_bodies: [],
			        }
		      	},
		      	public_categories: [],
		      	selected_categories: [],
		      	brands: {data: []}, 
		      	selected_brands: [],
		      	brandspage: 1,
		      	brandslimit: 24,
		      	brandssearch: '',
		      	countries: [],
		      	counties: [],
		      	registrationcounties: [],
		      	stepsLoading: false, 
		      	step: 1,
		      	form_result: null,
		      	user: '',
		      	nickname_unique: false,
		      	dropzoneOptions: {
			        url: 'https://httpbin.org/post',
			        thumbnailHeight: 300,
			        thumbnailWidth: 300,
			        autoProcessQueue: false,
			        maxFiles: 1,
			        resizeWidth: 800,
			        resizeHeight: 800,
			      },
		      	items: [
			        {
			          text: 'Company',
			          href: '/',
			        },
			        {
			          text: 'Add New',
			          active: true,
			        },
		      	],
			}
		},
		beforeCreate(){
			axios.get('/user').then( response =>{
				this.user = response.data;
				this.getCountries(response.data.api_token);
			}).catch(error => {
				//window.location.replace('/company/add');
			})
			axios.get('/request/company/check').then( response =>{
				if(response.data == true){
				window.location.replace('/');
				}
			}).catch(error => {
				//window.location.replace('/company/add');
			})

		},
		created(){
			this.getCompanyTypes();
			
		},
		mounted(){
			var url = window.location.href;
    	if(url.search("step=") > 0){
    		//url.substring(url.lastIndexOf("=") + 1).charAt(0);
    		if(url.search("strresponse=") > 0){
    		const startIndex = url.indexOf("step=") + 5; // Add 5 to skip "step=" and start at the next character
				const endIndex = url.indexOf("&", startIndex); // Find the next "&" character after the "step=" substring
				const stepValue = url.substring(startIndex, endIndex); // Extract the substring between the start and end indexes
				this.step = stepValue;
				this.refetchCompany();
				}else{
					this.step = url.substring(url.lastIndexOf("=") + 1).charAt(0);
				}
    	}
    	if(url.search("strresponse=") > 0){
    		//url.substring(url.lastIndexOf("=") + 1);
    		
				this.stripeAccount = url.substring(url.lastIndexOf("=") + 1);
    	}
    	if(url.search("&nickname=") > 0){
    		//url.substring(url.lastIndexOf("=") + 1);
    		const startIndex = url.indexOf("step=") + 5; // Add 5 to skip "step=" and start at the next character
				const endIndex = url.indexOf("&", startIndex); // Find the next "&" character after the "step=" substring
				const stepValue = url.substring(startIndex, endIndex); // Extract the substring between the start and end indexes
				this.step = stepValue;
				var nick = url.substring(url.lastIndexOf("=") + 1);
				this.refetchCompany(nick);
    	}
			//this.includeGoogle('maps.googleapis.com/maps/api/js?key=AIzaSyCO-2c9bJAX3z7cP3WN2O6x7ndipzOvZsU&libraries=places');
		},
		methods:{
			updateProfessionalBody(){
				this.newFormData.company_registration_settings.professional_bodies[this.professional_body_index].name = this.professional_body;
   			 this.newFormData.company_registration_settings.professional_bodies[this.professional_body_index].membership_no = this.membership_no;
   			this.professional_body_index = -1;
   			this.professional_body = '';
	   		this.membership_no = '';
			},
			editProfessionalBody(index){
   			this.professional_body = this.newFormData.company_registration_settings.professional_bodies[index].name;
   			this.membership_no = this.newFormData.company_registration_settings.professional_bodies[index].membership_no;
   			this.professional_body_index = index;
   		},
   		addProfessionalBody(){
   			this.loadingProfessionalBodies = true;
   			var body = {name: this.professional_body, membership_no: this.membership_no};
   			var indexExists = this.newFormData.company_registration_settings.professional_bodies.findIndex(item => item.name == this.professional_body);

   			if(this.professional_body == ''){
   				Swal.fire("Insufficient Information", "You must add a membership body and number", "error");

   				let self = this;
	   			setTimeout(function(){
	   				self.loadingProfessionalBodies = false;
	   			}, 800);
   			}else if(this.membership_no == ''){
   				Swal.fire("Insufficient Information", "You must add a membership body and number", "error");

   				let self = this;
	   			setTimeout(function(){
	   				self.loadingProfessionalBodies = false;
	   			}, 800);
   			}else if(indexExists == -1){
   				this.newFormData.company_registration_settings.professional_bodies.push(body);
	   			this.professional_body = '';
	   			this.membership_no = '';
	   			//this.professional_body = '';
	   			let self = this;
	   			setTimeout(function(){
	   				self.loadingProfessionalBodies = false;
	   			}, 800);
   			}else{

   				Swal.fire("Body Already Exists", "You cannot add two membership numbers for the same organisation", "error");

   				let self = this;
	   			setTimeout(function(){
	   				self.loadingProfessionalBodies = false;
	   			}, 800);

   			}

   			
   		},
			searchVATNo(){
				if(this.newFormData.company_registration_settings.vat_no.length == (9 || 12)){
				axios.get('https://api.service.hmrc.gov.uk/organisations/vat/check-vat-number/lookup/'+this.newFormData.company_registration_settings.vat_no+'', {headers: {'Host' : 'api.service.hmrc.gov.uk'}}).then(response => {
					console.log(response);
						this.vat_check_result = response.data;
						this.show_vat_result = true;

						return this.checkCompanyAgainstVAT(response.data);
					}).catch(error => {

					});
				}

			},
			checkCompanyAgainstVAT(){

			},
			searchCompaniesHouse(){
				axios.get('https://api.company-information.service.gov.uk/search/companies?q='+this.companies_house_search+'', {headers: {'Host' : 'api.company-information.service.gov.uk', 'Authorization' : 'Basic '+btoa('0258811b-736a-461f-84ac-8ccc2be9ba0b')+''}}).then(response => {
					console.log(response);
						this.companies_search_results = response.data;
						this.show_search_results = true;
					}).catch(error => {

					});
			},
			selectedRegisteredCompany(item){
					  // Get a reference to the vue-google-autocomplete component
					  const addressAutocomplete = this.$refs['registeredaddressmapref'];

					  // Set the value of the component to the new address
					  addressAutocomplete.autocompleteText = item.title.toLowerCase()+' '+item.address_snippet;
					  // Trigger the place_changed event to search for the new address
					  this.$refs['registeredaddressmapref'].focus();
					  this.$refs['registeredaddressmapref'].$emit("place_changed");
					  					  addressAutocomplete.$emit("place_changed");
					 	this.$refs['registeredaddressmapref'].blur();
					  this.selected_registered_company = item;
					  this.newFormData.company_registration_settings.registration_no = item.company_number;
					  this.$refs['registeredaddressmapref'].focus();

			},
			runSetup(){
				this.step = 10;
				this.setupprogress = 10;

				let self = this;
          setTimeout(function(){
          	self.setupprogress = 50;

          }, 1000);

          setTimeout(function(){
          	self.setupprogress = 72;

          }, 1500);

          setTimeout(function(){
          	self.setupprogress = 100;

          }, 3000);

          setTimeout(function(){
          	window.location.href = 'https://'+self.form_result.nickname+'.gemesys.co.uk/login';

          }, 3500);

			},
				refetchCompany(nickname = ''){
					/*
					axios.get('/api/company/setup/company/vn34uhn038918hbcns', {headers: {'Authorization' : 'Bearer '+ this.$attrs.user.api_token}}).then(response => {
		      	this.form_result = response.data;
		      }).catch(error => {
		        this.shops_error = error.data;
		      });*/
		      if(nickname == ''){
		      this.form_result = this.$attrs.user.companies[this.$attrs.user.companies.length - 1].company;
		    	}else{
		    		var index = this.$attrs.user.companies.findIndex(item => item.company.nickname == nickname);
		    		this.form_result = this.$attrs.user.companies[index].company;
		    	}
		      //this.newFormData = this.$attrs.user.companies[this.$attrs.user.companies.length - 1].company;
				},
				connectStripeAccount(){
	      	axios.get('/api/company/settings/payments/'+this.form_result.uid+'/on-boarding?req='+this.form_result.nickname+'', {headers: {
	      		'Authorization' : 'Bearer '+this.$attrs.user.api_token+''
	      	}}).then(response => {
	      		window.open(response.data, "_blank");
	      	}).catch(error => {
	      		this.error_reponse = error;
	      	});
	      },
	      disconnectStripeAccount(){
	      	axios.post('/api/company/settings/payments/'+this.stripeAccount[0].account_id+'/destroy', {headers: {
	      		'Authorization' : 'Bearer '+this.$attrs.user.api_token
	      	}}).then(response => {
	      		window.open(response.data, "_blank");
	      	}).catch(error => {
	      		this.error_reponse = error;
	      	});
	      },
			userRoles(){
      axios.get('/api/user/roles', {headers: {
                  'Authorization': 'Bearer ' + this.$attrs.user.api_token
              }}).then(response => {
        this.user_roles = response.data;

      }).catch(error => {
        this.load_error = error.data;
      });
   	 },
			getListingTypes(){
	      axios.get('/api/company/setup/listing-types', {headers: {
                  'Authorization': 'Bearer ' + this.$attrs.user.api_token
              }}).then(response => {
	      this.listing_types = response.data;
	      }).catch(error => {
	        this.shops_error = error.data;
	      });
	    },
			runCategoriesSetup(){
				axios.post('/api/company/autosetup', {}, {headers: {'Authorization' : this.$attrs.user.api_token}}).then(response => {

				}).catch(error => {

				});
			},
			includeGoogle( URL, callback ){
                        let documentTag = document, tag = 'script',
                            object = documentTag.createElement(tag),
                            scriptTag = documentTag.getElementsByTagName(tag)[0];
                        object.src = 'https://' + URL;
                        if (callback) { object.addEventListener('load', function (e) { callback(null, e); }, false); }
                        scriptTag.parentNode.insertBefore(object, scriptTag);
            },
			imageAdded(file){
	          this.newFormData.logo = file;
	        },
	        listingImageAdded(file){
          this.newListing.cover_image = file;
        },
        logoAdded(file){
          this.newListing.logo = file;
        },
			validateStepsForm(step, skip = false){
				this.error_reponse = [];
				if(step == 1){
				if(this.newFormData.companyname == ''){
					this.error_reponse.push(["Please Enter Company Name"])
				}
				if(this.newFormData.nickname == ''){
					this.error_reponse.push(["Please Enter Company Nickname"])
				}
				if(this.newFormData.description == ''){
					this.error_reponse.push(["Please Enter Company Description"])
				}
				if(this.newFormData.logo == []){
					this.error_reponse.push(["Please Upload a Logo"])
				}
				if(this.newFormData.website == ''){
					this.error_reponse.push(["Please Enter Company Website"])
				}
				if(this.newFormData.email == ''){
					this.error_reponse.push(["Please Enter Company Email"])
				}
				if(this.newFormData.telephone == ''){
					this.error_reponse.push(["Please Enter Company Telephone"])
				}
				}
				if(step == 2){
				if(this.newFormData.address1 == ''){
					this.error_reponse.push(["Please Enter Company Head Office Address line 1"])
				}
			
				if(this.newFormData.county == ''){
					this.error_reponse.push(["Please Enter Company Country"])
				}
				if(this.newFormData.postcode == ''){
					this.error_reponse.push(["Please Enter Company Postcode"])
				}
				if(this.newFormData.company_legal_status !== 'self-employed' && (this.newFormData.company_registration_settings.registration_no.length !== ('' || 8))){
					this.error_reponse.push(["Company Registration No. must be between 8 characters"])
				}
				if(this.newFormData.company_legal_status !== 'self-employed' && (this.newFormData.company_registration_settings.address1 == '')){
					this.error_reponse.push(["Please Enter Company Registered Address line 1"])
				}
				/*if(this.newFormData.company_registration_settings.address2 == ''){
					this.error_reponse.push(["Please Enter Company Registered Address line 2"])
				}
				if(this.newFormData.company_registration_settings.county == ''){
					this.error_reponse.push(["Please Enter Company County"])
				}*/
				if(this.newFormData.company_legal_status !== 'self-employed' && (this.newFormData.company_registration_settings.postcode == '')){
					this.error_reponse.push(["Please Enter Company Registered Address Postcode"])
				}
				if(this.newFormData.company_registration_settings.vat_no.length !== (null || '' || 9 || 12)){
					this.error_reponse.push(["Vat No. must be between 9 and 12 characters"])
				}
				}
				if(step == 3){
					if(this.newFormData.accpted_terms == 0){
						this.error_reponse.push(["Please Enter Accept Agreement to Continue"]);
					}
				}
				if(step == 6){
					this.getListingTypes();
					this.getCategories('public');
					this.getBrands();


					this.newListing.manager_id = this.$attrs.user.companies.filter(item => item.company.nickname == this.form_result.nickname).id;
				}
				if(step == 7 && skip == false){

					return this.submitListing();

				}else if(step == 7 && skip == true){
					this.userRoles();
					//this.step++;
				}
				if(step == 8 && skip == false){

					return this.submitUsers();

				}
				if(step == 9 && skip == false){

					return this.submitUsers();

				}
				if(this.error_reponse.length == 0){
					this.step++;
					//this.submitForm();
				}else{
					//this.step = step;
					this.error_message = true;
					setTimeout(function(){
						document.getElementById('register-form').scrollIntoView({
						  behavior: 'smooth'
						});
					}, 150);
				}

			},
			submitWebsiteSettings(){
				this.loadingWebsiteForm = false;
				axios.post('/api/company/setup/run/website/create', {},  {headers: {'Authorization' : 'Bearer '+this.$attrs.user.api_token}}).then(response => {
          this.success = response.data;
          this.success_message = true;
          //this.$emit('action-created');
          //this.newAction = [];
          let self = this;
          setTimeout(function(){
          	self.step++;
            self.loadingWebsiteForm = false;
            self.addNewListing = false;
            self.searchResource();
          }, 1500);    
        }).catch(error => {
          this.error_reponse = error.response.data.errors;
          this.error_message = true;
          let self = this;
          setTimeout(function(){
            self.loadingListingForm = false;
          }, 1500);
          
        });
			},
			submitUsers(){
				this.loadingUsersForm = true;
				axios.post('/api/company/setup/run/users/create', {users: this.setupoptions.users, nickname: this.form_result.nickname},  {headers: {'Authorization' : 'Bearer '+this.$attrs.user.api_token}}).then(response => {
					 this.success = response.data;
          this.success_message = true;
          //this.$emit('action-created');
          //this.newAction = [];
          let self = this;
          setTimeout(function(){
          	self.step++;
            self.loadingUsersForm = false;
            self.addNewListing = false;
            self.searchResource();
          }, 1500);  
				}).catch(error => {

				});
			},
			submitListing(){
       this.loadingListingForm = true;
        var postForm = new FormData(document.getElementById("addnewlisting"));
       	postForm.append('listing_type_id', this.newListing.listing_type_id);
        postForm.append('banner', this.newListing.cover_image);
        if(this.newListing.logo !== ''){
        	postForm.append('logo', this.newListing.logo);
      	}
        postForm.append('longitude', this.newListing.longitude);
        postForm.append('latitude', this.newListing.latitude);
        postForm.append('gplace_id', this.newListing.gplace_id);
        if(this.newListing.manager_id){
        	postForm.append('manager_id', this.newListing.manager_id);
      	}
        postForm.append('active', this.newListing.active);
        postForm.append('trade_active', this.newListing.trade);
        postForm.append('public_active', this.newListing.public);
        postForm.append('nickname', this.form_result.nickname);
        axios.post('/api/company/setup/run/listing/create', postForm, {headers: {'Authorization' : 'Bearer '+this.$attrs.user.api_token}}).then(response => {
          this.success = response.data;
          this.success_message = true;
          //this.$emit('action-created');
          //this.newAction = [];
          let self = this;
          setTimeout(function(){
          	self.step++;
            self.loadingListingForm = false;
            self.addNewListing = false;
            self.userRoles();
          }, 1500);    
        }).catch(error => {
          this.error_reponse = error.response.data.errors;
          this.error_message = true;
          let self = this;
          setTimeout(function(){
            self.loadingListingForm = false;
          }, 1500);
          
        });
    },
			submitForm(event){
				if((this.step < 3) && (this.step > 3)){
				event.preventDefault();
				}
				this.stepsLoading = true;
				var postForm = new FormData(document.getElementById("addcompany"));
				postForm.append('logo', this.newFormData.logo);
				postForm.append('company_registration_settings_professional_bodies', this.newFormData.company_registration_settings.professional_bodies);
				axios.post('/api/company/create', postForm, {headers: {'Authorization' : 'Bearer '+this.user.api_token}}).then(response => {
						
						if(response.data == false){
							this.nickname_unique = false;
							this.error_reponse.push(["Nickname Already in Use - Please Enter a Custom Nickname"]);
							let self = this;
					          setTimeout(function(){
					            self.stepsLoading = false;
					            self.error_message = false;
					            self.step = 1;
					          }, 500);
						}else{
							this.form_result = response.data;
							this.step = 4;
							let self = this;
					          setTimeout(function(){
					            self.stepsLoading = false;
					            self.error_message = false;
					          }, 500);
						}
						let self = this;
					          setTimeout(function(){
					            self.stepsLoading = false;
					            self.error_message = false;
					          }, 500);
					}).catch(error => {
						this.error_reponse = error.response.data.errors;
						this.error_message = true;
						let self = this;
					          setTimeout(function(){
					            self.stepsLoading = false;
					          }, 500);
					});
			},
			preventDefaultEvent(event){
				if(this.step > 3){
				event.preventDefault();
				}
			},
			 getAddressData: function (addressData, placeResultData, id) {
			 	
				
		        this.googleSearchAddress = addressData;
		        this.googleSearchAddress["place"] = placeResultData;
		        console.log(placeResultData);
		        if(placeResultData.name){
		         //this.newFormData.address1 = placeResultData.name;
		         if(addressData.street_number){
		              this.newFormData.address1 = addressData.street_number + ' ' +addressData.route; 
		          }else{
		              this.newFormData.address1 = addressData.route; 
		          }
		        }else{
		         if(!addressData.street_number){
		              this.newFormData.address1 = addressData.route; 
		          }
		        }
		        if(addressData.locality){
		          this.newFormData.address3 = addressData.locality;
		        }
		      		
		      	  for(var i = 0; i < this.googleSearchAddress.place.address_components.length; i++){
		        			if(this.googleSearchAddress.place.address_components[i].types.includes('street_number')){
		        					this.newFormData.address1 = this.googleSearchAddress.street_number; 
		        				}
		        				if(this.googleSearchAddress.place.address_components[i].types.includes('route')){
		        					this.newFormData.address1 += ' '+this.googleSearchAddress.route; 
		        				}
		        				if(this.googleSearchAddress.place.address_components[i].types.includes('postal_town')){
		        					
		        						this.newFormData.address3 = this.googleSearchAddress.place.address_components[i].long_name; 
		        				}
		        				if(this.googleSearchAddress.place.address_components[i].types.includes('locality')){
		        					this.newFormData.address3 = this.googleSearchAddress.street_number + ' ' +this.googleSearchAddress.route; 
		        				}
		        		}
		        if((!this.newFormData.address1) || this.newFormData.address1 == '') {
		        	this.newFormData.address1 = this.googleSearchAddress.place.formatted_address.split(',', 1)[0];
		        }
		        if(this.newFormData.address2 == ''){
		        	this.newFormData.address2 = this.googleSearchAddress.place.formatted_address.split(',', 2)[1];
		        }	
		        this.newFormData.postcode = addressData.postal_code;
		        var country = this.countries.filter(item => item.name == addressData.country);
		        this.newFormData.country_id = country[0].id;
		        this.newFormData.country = country[0];
		        this.selected_country_id = country[0].id;
		          
		        this.newFormData.latitude = this.googleSearchAddress.latitude;
		        this.newFormData.longitude = this.googleSearchAddress.longitude;
		        this.newFormData.gplace_id = placeResultData.place_id;
		        
		        if(country.length > 0){
		            //this.getCounties(country[0].id);
		            var self = this;
		            axios.all([getCounties(country[0].id, self.$attrs.user.api_token, addressData.administrative_area_level_2)]).then(axios.spread(function (response) {
		              //companyList[ia].push(response.data)
		              console.log(response);
		              self.counties = response[0].data;
		              var county = response[0].data.filter(item => item.name == response[1]);
		              self.newFormData.county = county[0];
		              self.newFormData.county_id = county[0].id;
		              //self.selected_county_id = county[0].id;
		            }));
		            
		          }
		        /**for(var i = 0; i < placesData.address_components.length; i++){
		          var county = placesData.address_components[i].types.filter(item == 'route');
		          var county = placesData.address_components[i].types.filter(item == 'street_number');
		          
		          var town = placesData.address_components[i].types.filter(item == 'locality');
		          var city = placesData.address_components[i].types.filter(item == 'postal_town');
		          var country = placesData.address_components[i].types.filter(item == 'country');

		          this.newFormData.address3 = town[0].long_name;

		        
		        }**/
		        async function getCounties(country_id, api_token, county_name){
		               return [await axios.get('/api/country/'+country_id+'/counties', {headers: {
                  'Authorization': 'Bearer ' + api_token
              }}), county_name];
		              }

		      },
		      getRegisteredAddressData: function (addressData, placeResultData, id) {
			 	
				
		        this.googleSearchRegisteredAddress = addressData;
		        this.googleSearchRegisteredAddress["place"] = placeResultData;
		        if(placeResultData.name){
		         this.newFormData.company_registration_settings.address1 = placeResultData.name;
		         if(addressData.street_number){
		              this.newFormData.company_registration_settings.address2 = addressData.street_number + ' ' +addressData.route; 
		          }else{
		              this.newFormData.company_registration_settings.address2 = addressData.route; 
		          }
		        }else{
		         if(addressData.street_number){
		              this.newFormData.company_registration_settings.address1 = addressData.street_number + ' ' +addressData.route; 
		          }else{
		              this.newFormData.company_registration_settings.address1 = addressData.route; 
		          }
		        }
		        if(addressData.locality){
		          this.newFormData.company_registration_settings.address3 = addressData.locality;
		        }
		        this.newFormData.company_registration_settings.postcode = addressData.postal_code;

		         for(var i = 0; i < this.googleSearchAddress.place.address_components.length; i++){
		        			if(this.googleSearchAddress.place.address_components[i].types.includes('street_number')){
		        					this.newFormData.company_registration_settings.address1 = this.googleSearchAddress.street_number; 
		        				}
		        				if(this.googleSearchAddress.place.address_components[i].types.includes('route')){
		        					this.newFormData.company_registration_settings.address1 += ' '+this.googleSearchAddress.route; 
		        				}
		        				if(this.googleSearchAddress.place.address_components[i].types.includes('postal_town')){
		        					
		        						this.newFormData.company_registration_settings.address3 = this.googleSearchAddress.place.address_components[i].long_name; 
		        				}
		        				if(this.googleSearchAddress.place.address_components[i].types.includes('locality')){
		        					this.newFormData.company_registration_settings.address3 = this.googleSearchAddress.street_number + ' ' +this.googleSearchAddress.route; 
		        				}
		        		}
		        if((!this.newFormData.company_registration_settings.address1) || this.newFormData.company_registration_settings.address1 == '') {
		        	this.newFormData.company_registration_settings.address1 = this.googleSearchAddress.place.formatted_address.split(',', 1)[0];
		        }
		        if(this.newFormData.company_registration_settings.address2 == ''){
		        	this.newFormData.company_registration_settings.address2 = this.googleSearchAddress.place.formatted_address.split(',', 2)[1];
		        }	
		        var country = this.countries.filter(item => item.name == addressData.country);
		        this.newFormData.company_registration_settings.country_id = country[0].id;
		        this.newFormData.company_registration_settings.country = country[0];
		        this.selected_country_id = country[0].id;
		          
		        this.newFormData.company_registration_settings.latitude = this.googleSearchRegisteredAddress.latitude;
		        this.newFormData.company_registration_settings.longitude = this.googleSearchRegisteredAddress.longitude;
		        this.newFormData.company_registration_settings.gplace_id = placeResultData.place_id;
		        
		        if(country.length > 0){
		            //this.getCounties(country[0].id);
		            var self = this;
		            axios.all([getCounties(country[0].id, self.$attrs.user.api_token, addressData.administrative_area_level_2)]).then(axios.spread(function (response) {
		              //companyList[ia].push(response.data)
		              console.log(response);
		              self.registrationcounties = response[0].data;
		              var county = response[0].data.filter(item => item.name == response[1]);
		              self.newFormData.company_registration_settings.county = county[0];
		              self.newFormData.company_registration_settings.county_id = county[0].id;
		              //self.selected_county_id = county[0].id;
		            }));
		            
		          }
		        /**for(var i = 0; i < placesData.address_components.length; i++){
		          var county = placesData.address_components[i].types.filter(item == 'route');
		          var county = placesData.address_components[i].types.filter(item == 'street_number');
		          
		          var town = placesData.address_components[i].types.filter(item == 'locality');
		          var city = placesData.address_components[i].types.filter(item == 'postal_town');
		          var country = placesData.address_components[i].types.filter(item == 'country');

		          this.newFormData.address3 = town[0].long_name;

		        
		        }**/
		        async function getCounties(country_id, api_token, county_name){
		               return [await axios.get('/api/country/'+country_id+'/counties', {headers: {
                  'Authorization': 'Bearer ' + api_token
              }}), county_name];
		              }

		      },
		       getListingAddressData: function (addressData, placeResultData, id) {
			 	
				
		        this.googleSearchListingAddress = addressData;
		        if(placeResultData.name){
		         this.newListing.address1 = placeResultData.name;
		         if(addressData.street_number){
		              this.newListing.address2 = addressData.street_number + ' ' +addressData.route; 
		          }else{
		              this.newListing.address2 = addressData.route; 
		          }
		        }else{
		         if(addressData.street_number){
		              this.newListing.address1 = addressData.street_number + ' ' +addressData.route; 
		          }else{
		              this.newListing.address1 = addressData.route; 
		          }
		        }
		        if(addressData.locality){
		          this.newListing.address3 = addressData.locality;
		        }
		        this.newListing.postcode = addressData.postal_code;
		        var country = this.countries.filter(item => item.name == addressData.country);
		        this.newListing.country_id = country[0].id;
		        this.newListing.country = country[0];
		        this.selected_country_id = country[0].id;
		          
		        this.newListing.latitude = this.googleSearchListingAddress.latitude;
		        this.newListing.longitude = this.googleSearchListingAddress.longitude;
		        this.newListing.gplace_id = placeResultData.place_id;
		        this.googleSearchListingAddress["place"] = placeResultData;
		        if(country.length > 0){
		            //this.getCounties(country[0].id);
		            var self = this;
		            axios.all([getCounties(country[0].id, self.$attrs.user.api_token, addressData.administrative_area_level_2)]).then(axios.spread(function (response) {
		              //companyList[ia].push(response.data)
		              console.log(response);
		              self.counties = response[0].data;
		              var county = response[0].data.filter(item => item.name == response[1]);
		              self.newListing.county = county[0];
		              self.newListing.county_id = county[0].id;
		              //self.selected_county_id = county[0].id;
		            }));
		            
		          }
		        /**for(var i = 0; i < placesData.address_components.length; i++){
		          var county = placesData.address_components[i].types.filter(item == 'route');
		          var county = placesData.address_components[i].types.filter(item == 'street_number');
		          
		          var town = placesData.address_components[i].types.filter(item == 'locality');
		          var city = placesData.address_components[i].types.filter(item == 'postal_town');
		          var country = placesData.address_components[i].types.filter(item == 'country');

		          this.newFormData.address3 = town[0].long_name;

		        
		        }**/
		        async function getCounties(country_id, api_token, county_name){
		               return [await axios.get('/api/country/'+country_id+'/counties', {headers: {
                  'Authorization': 'Bearer ' + api_token
              }}), county_name];
		              }

		      },
		    loadCounties(country){
		    	this.getCounties(country.id);
		    },
		    getBrands(){
		    	axios.get('/api/company/setup/brands?page='+this.brandspage+'&limit='+this.brandslimit+'&sortby=name&order=desc&search='+this.brandssearch+'&marketplace_categories=true', {headers: {
                  'Authorization': 'Bearer ' + this.$attrs.user.api_token
              }}).then(response => {
			       	this.brands = response.data;
					}).catch(error => {
						this.response_error = error.data;
					});
		    },
			getCountries(api_token){
				// get countries on component load
				axios.get('/api/countries', {headers: {
                  'Authorization': 'Bearer ' + api_token
              }}).then(response => {
				this.countries = response.data;
				}).catch(error => {
					this.load_error = error.data;
				})
			},
			getCounties(country_id){
				// get counties as country selected
				axios.get('/api/country/'+country_id+'/counties', {headers: {
                  'Authorization': 'Bearer ' + this.$attrs.user.api_token
              }}).then(response => {
				this.counties = response.data;
				}).catch(error => {
					this.load_error = error.data;
				})
			},
			getCompanyTypes(){
	      	axios.get('/api/company/types', {headers: {'Authorization' : 'Bearer '+this.$attrs.user.api_token}}).then(response => {
	          this.company_types = response.data;
	        }).catch(error => {
	          this.load_error = error.data;
	        })
	      },
	      getCategories(type){
      axios.get('/api/company/setup/categories?api_token='+this.$attrs.user.api_token+'&page='+this.currentPage+'&limit='+this.limit+'&sortby='+this.sortby+'&order='+this.order+'&search='+this.search+'&marketplace_categories='+type).then(response => {
        if(type == 'company'){
          this.categories = response.data;
        }
        if(type == 'public'){
          this.public_categories = response.data;
        }
        if(type == 'trade'){
          this.trade_categories = response.data;
        }
        //return response.data;

      }).catch(error => {
        this.response_error = error.data;
      });
    },
    activeInBrands(category){
    	if(this.selected_brands.length > 0){
                var activeCategories = this.selected_brands.filter(item => item.category.id == category.id);
                if(activeCategories.length > 0){
                  return true;
                }else{
                  return false;
                }
              }
    },
    updateActiveBrands(category){
    	var activeCategories = this.selected_brands.findIndex(item => item.id == category.id);
              if(activeCategories < 0){
                category["category"] = category;
                this.selected_brands.push(category);
              }else{
                this.selected_brands.splice(activeCategories, 1);
              }
    },
    updateActiveCategories(category, type){
            if(type == 'company'){
              var activeCategories = this.productData.company_categories.findIndex(item => item.id == category.id);
              if(activeCategories < 0){
                category["category"] = category;
                this.productData.company_categories.push(category);
              }else{
                this.productData.company_categories.splice(activeCategories, 1);
              }
            }
            if(type == 'public'){

             var activeCategories = this.selected_categories.findIndex(item => item.id == category.id);
              if(activeCategories < 0){
                category["category"] = category;
                this.selected_categories.push(category);
              }else{
                this.selected_categories.splice(activeCategories, 1);
              }


            }
            if(type == 'trade'){
              

              var activeCategories = this.productData.trade_marketplace_categories.findIndex(item => item.id == category.id);
              if(activeCategories < 0){
                category["category"] = category;
                this.productData.trade_marketplace_categories.push(category);
              }else{
                this.productData.trade_marketplace_categories.splice(activeCategories, 1);
              }
            }
          },
          /*subscriptionSuccess(){
          	this.subscription_result = response;
          	this.step++;
          },*/
          subscriptionSuccess(intent, subscription, quantity, price_per_unit, flat_fee){
          	this.subscription_result["payment_intent"] = intent;
          	this.subscription_result["subscription"] = subscription;
          	this.subscription_result["quantity"] = quantity;
          	this.subscription_result["price_per_unit"] = price_per_unit;
          	this.subscription_result["flat_price"] = flat_fee;
          	this.step++;
          },
					activeInCategories(category, type){
            if(type == 'company'){
              if(this.productData.company_categories.length > 0){
                var activeCategories = this.productData.company_categories.filter(item => item.category.id == category.id);
                if(activeCategories.length > 0){
                  return true;
                }else{
                  return false;
                }
              }
            }
            if(type == 'public'){
              if(this.selected_categories.length > 0){
                var activeCategories = this.selected_categories.filter(item => item.category.id == category.id);
                if(activeCategories.length > 0){
                  return true;
                }else{
                  return false;
                }
              }
            }
            if(type == 'trade'){
              if(this.productData.trade_marketplace_categories.length > 0){
                var activeCategories = this.productData.trade_marketplace_categories.filter(item => item.category.id == category.id);
                if(activeCategories.length > 0){
                  return true;
                }else{
                  return false;
                }
              }
            }

          },
				sanitizeTitle: function(title) {
			      var nickname = "";
			      // Change to lower case
			      var titleLower = title.toLowerCase();
			      // Letter "e"
			      nickname = titleLower.replace(/e|é|è|ẽ|ẻ|ẹ|ê|ế|ề|ễ|ể|ệ/gi, 'e');
			      // Letter "a"
			      nickname = nickname.replace(/a|á|à|ã|ả|ạ|ă|ắ|ằ|ẵ|ẳ|ặ|â|ấ|ầ|ẫ|ẩ|ậ/gi, 'a');
			      // Letter "o"
			      nickname = nickname.replace(/o|ó|ò|õ|ỏ|ọ|ô|ố|ồ|ỗ|ổ|ộ|ơ|ớ|ờ|ỡ|ở|ợ/gi, 'o');
			      // Letter "u"
			      nickname = nickname.replace(/u|ú|ù|ũ|ủ|ụ|ư|ứ|ừ|ữ|ử|ự/gi, 'u');
			      // Letter "d"
			      nickname = nickname.replace(/đ/gi, 'd');
			      // Trim the last whitespace
			      nickname = nickname.replace(/\s*$/g, '');
			      // Change whitespace to "-"
			      nickname = nickname.replace(/\s+/g, '-');
			      // Remove quotations
			      nickname = nickname.replace(/["']/g, "");
			      
			      return nickname;
    		}
		},
		computed: {
		    nickname: function() {
		    	// Take Company Name as Typing and Sanitize for Nickname
		      var name = this.newFormData.companyname;
		      this.newFormData.nickname = this.sanitizeTitle(name);
		      return this.newFormData.nickname;
		    }
		  },
		
	}
</script>
<template>
<b-container fluid>
    	<div class="row justify-content-center py-4 my-4" id="register-form">
    		<div class="col-sm-12 col-md-8 col-lg-10" :class="{'col-xl-6 col-md-8': step !== 4, 'col-xl-8 col-md-10' : step == 4}">
    			<div class="card overflow-hidden border-radius-1em">
		            <div class="bg-soft-primary">
		                <div class="row"  style="background-image: url('/images/diamond-bg.webp'); background-size: cover; min-height: 150px">
		                    <div class="col-12 text-center">
		                        <div class="text-light p-4 d-flex flex-column justify-content-center" style="background: rgba(94, 59, 176, 0.75); height: 100%">
		                        	<div class="avatar-lg position-relative mx-auto">
                <span class="rounded-circle">
                  <!--<i class="bx bx-cube-alt text-dark" style="font-size: 48px"></i>
                  <img src="/images/compiance.svg" alt width="60%" style="margin-left: -25px;" />-->
                </span>
              </div>
		                            <h5 class="text-light" v-if="step < 5" >Register Your Company</h5>
		                            <h5 class="text-light" v-if="step == 5" >Success!</h5>
		                            <div class="row"><div class="col-md-8 mx-auto"><p v-if="step < 4" >Add some basic information about your company</p>
		                            	<p v-if="step == 3" >You must agree to the following terms and privacy policy to continue</p>
		                            <p v-if="step == 4">Your company has been registered, but you need a subscription to continue using Gemesys. Please select the users and stores you require below and we'll select the appropriate tariff for you!</p>
		                            <p v-if="step == 5" >Your payment has been accepted. Your company can now be accessed directly at
		                            <a class="text-light" :href="'http://'+newFormData.nickname+'.gemesys.co.uk/login'">{{newFormData.nickname}}.gemesys.co.uk</a></p></div></div>
		                        </div>
		                    </div>
		                </div>
		            </div>
			          <div class="card-body border-radius-1em">
			          	<div style="margin-top: -55px;"><a href="/"><div class="avatar-md profile-user-wid mb-4 mx-auto position-relative"><span class="avatar-title rounded-circle bg-light  text-primary font-size-20">{{step}}</span></div></a></div>
			          <b-form id="addcompany" method="POST" enctype="multipart/form-data" @submit="submitForm" @keyup.enter.prevent @keypress.enter.prevent>
			          	 <input type="hidden" name="_token" :value="csrf">
			          	<transition name="slide-error" mode="out-in">
		                    <b-alert id="errors" variant="danger" :show="error_message"  dismissible @dismissed="error_message = !error_message, error_reponse = []">
		                      <p class="font-size-14">Oops! Please correct the following errors in your submission</p>
		                      <ul>
		                        <li v-for="(error, key) in error_reponse">
		                          {{error[0]}}
		                        </li>
		                      </ul>
		                    </b-alert>
		                    
		                  </transition>
		                <b-overlay
                        id="report-generating-overlay"
                        :show="stepsLoading"
                        variant="white"
                        opacity="0.85"
                        blur="1px"
                        rounded="md"
                        class="w-100"
                      >
			          	<div v-show="step == 1">
			          	<h4 class="text-center">Basic Information</h4>
			          	<b-card class="border-radius-1em border shadow-none">
			          <b-form-group label="Name" class="required mt-2">
			            <b-form-input required name="companyname" v-model="newFormData.companyname"></b-form-input>
			             <small id="nickname">Your admin panel will be accessible via: <strong>{{nickname}}.gemesys.co.uk</strong></small>
			            </b-form-group>
			          <b-form-group label="Nickname" class="required mt-2">
			          	 <b-form-input name="nickname" :disabled="nickname_unique == true" :class="{'bg-gray': nickname_unique == true}" v-model="newFormData.nickname"></b-form-input>
			          </b-form-group>
			          <input type="hidden" name="nickname" v-if="nickname_unique == false" :value="nickname" />

			          <b-form-group label="Description" class="required mt-2">
			            <b-form-textarea name="description" v-model="newFormData.description" rows="5"></b-form-textarea>
			          </b-form-group>
			          <b-form-group label="Logo" class="mt-2">
			            <!--<vue-dropzone
			              id="dropzone"
			              ref="myVueDropzone"
			              :use-custom-slot="true"
			              :options="dropzoneOptions"
			              name
			            >
			              <div class="dropzone-custom-content">
			                <div class="mb-1">
			                  <i class="display-4 text-muted bx bxs-cloud-upload"></i>
			                </div>
			                <h4 class="text-center">Drop files here or click to upload.</h4>
			              </div>
			            </vue-dropzone>
			            <b-form-file v-model="newFormData.logo" name="logo"></b-form-file>-->
			            <vue-dropzone id="dropzone" ref="myVueDropzone" :use-custom-slot="true" :options="dropzoneOptions" @vdropzone-file-added="imageAdded"  @vdropzone-drop="imageAdded">
                        <div class="dropzone-custom-content">
                            <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                            <h4>Drop files here or click to upload.</h4>
                        </div>
                        </vue-dropzone>
			            <small>Your logo must be 1:1 square ratio and no larger than 800px x 800px</small>
			          </b-form-group>
			      	<b-form-group label="Main Business Activity" class="required mt-2" >
									<b-form-select class="form-control border btn-rounded px-3 custom-select" v-model="newFormData.company_type_id">
										<b-form-select-option v-for="type in company_types" :value="type.id">
											{{type.name}}
										</b-form-select-option>
									</b-form-select>
								</b-form-group>
							<input type="hidden" name="company_type_id" :value="newFormData.company_type_id" >
			          <b-form-group label="Website" class="required mt-2">
			            <b-form-input name="website" v-model="newFormData.website"></b-form-input>
			          </b-form-group>
			          <b-form-group label="E-mail" class="required mt-2">
			            <b-form-input name="email" v-model="newFormData.email"></b-form-input>
			          </b-form-group>
			          <b-form-group label="Telephone" class="required mt-2">
			            <b-form-input name="telephone" v-model="newFormData.telephone"></b-form-input>
			          </b-form-group>
			      </b-card>
			      <b-card class="border-radius-1em border shadow-none">
			      		<h5 class="heading-label required">Directory Listing</h5>
			      		<p>Display my company on the following directories:</p>
			      		 <div class="d-flex">
			       
			              <b-button
			                      class=" flex-grow-1 my-0 w-sm border-radius-1em mr-1"
			                      value="0"
			                      :class="{'active' : newFormData.public == (true || 1 || '1')}"
			                      variant="outline-light"
			                      @click="newFormData.public = !newFormData.public"
			                    ><i v-if="newFormData.public == (true || 1 ||  '1')" class="bx bx-check-circle font-size-24 d-block text-success my-2"></i> 
			                    <i v-else class="bx bx-basket font-size-24 d-block text-primary my-2"></i> Public Market
			              </b-button>
			              <b-button
			                      class=" flex-grow-1 my-0 w-sm border-radius-1em ml-1"
			                      value="0"
			                      :class="{'active' : newFormData.trade == (true || 1 || '1')}"
			                      variant="outline-light"
			                      @click="newFormData.trade = !newFormData.trade"
			                    ><i v-if="newFormData.trade == (true || 1 || '1')" class="bx bx-check-circle font-size-24 d-block text-success my-2"></i> 
			                    <i v-else class="bx bx-basket font-size-24 d-block text-primary my-2"></i> Trade Market
			              </b-button>
			              <input type="hidden" name="active" :value="newFormData.active">
			              <input type="hidden" name="public_active" :value="newFormData.public">
			              <input type="hidden" name="trade_active" :value="newFormData.trade">
			            </div>
			            <small>You can change this setting later or assign an alternative address.</small>
			      	  </b-card>
			      		</div>
			      		<div v-show="step == 2">
			          <h4 class="text-center">Head Office</h4>
			          <b-card class="border-radius-1em border shadow-none">
		                <b-form-group label="Search Address">
		                  <vue-google-autocomplete @keyup.enter.prevent @keypress.enter.prevent id="map" :enableGeolocation="false" types="establishment" :fields="['address_components', 'adr_address', 'alt_id', 'formatted_address', 'geometry', 'icon', 'id', 'name', 'business_status', 'photo', 'place_id', 'scope', 'type', 'url', 'utc_offset', 'vicinity', ]" classname="form-control" placeholder="Start typing" v-on:placechanged="getAddressData">
		                    </vue-google-autocomplete>
		                </b-form-group>
		                <gmap-map v-if="googleSearchAddress !== ''" :center="{ lat: googleSearchAddress.latitude, lng: googleSearchAddress.longitude }" :zoom="10" style="height: 350px" class="mt-2 border-radius-1em">
		                  <gmap-marker
		                    
		                    :key="index"
		                    :position="{ lat: googleSearchAddress.latitude, lng: googleSearchAddress.longitude }"
		                    :clickable="true"
		                    :draggable="true"
		                    @click="center = { lat: googleSearchAddress.latitude, lng: googleSearchAddress.longitude }"
		                  ></gmap-marker>
		                </gmap-map>
		              </b-card>
		              <b-card class="border-radius-1em border shadow-none border-primary" body-class="d-flex" v-if="googleSearchAddress !== ''">
		                <div v-html="googleSearchAddress.place.formatted_address"></div>
		              
		                <a class="btn btn-danger ml-auto" @click="googleSearchAddress = '', newFormData.gplace_id = '', newFormData.longitude = 0, newFormData.latitude = 0">Remove Listing</a>
		              </b-card>
		              <b-card class="border-radius-1em border shadow-none">
			          <b-form-group label="Address 1" class="required mt-2">
			            <b-form-input name="address1" v-model="newFormData.address1"></b-form-input>
			          </b-form-group>
			          <b-form-group label="Address 2" class="mt-2">
			            <b-form-input name="address2" v-model="newFormData.address2"></b-form-input>
			          </b-form-group>
			          <b-form-group label="Address 3" class="mt-2">
			            <b-form-input name="address3" v-model="newFormData.address3"></b-form-input>
			          </b-form-group>
			                   <b-form-group v-if="countries.length > 0" class="my-2" label="Country">
          <multiselect v-model="newFormData.country" track-by="name" label="name" :options="countries" :option-height="104" class="" @input="loadCounties">
                     <!--<template slot="singleLabel" slot-scope="{ option }">{{ option.name }} - {{ option.jobtitle }}</template>-->
                     <template slot="singleLabel" slot-scope="{ option }"><div class="d-flex w-100 h-100">
                     <div class="avatar-xs mr-4 my-auto" v-if="newFormData.country.id !== ''">
                        <span class="avatar-title rounded-circle" >{{(option.iso_code).slice(0,2)}}</span>
                       
                      </div>
                      <div class="option__desc d-flex flex-row flex-grow-1"><span v-if="newFormData.country.id !== ''"  class="option__title ml-2 my-auto w-100">{{ option.name }}</span>
                        <span v-else class="option__title my-auto w-100">Select Country</span>

                      </div>
                      <div class="avatar-xs my-auto" v-if="newFormData.country.id == ''">
                      <span class="avatar-title rounded-circle bg-light text-primary"><i class="bx bx-chevron-down"></i></span>
                     </div>

                      </div></template>
                    <template slot="option" slot-scope="{ option }">
                      <div class="d-flex"><div class="avatar-xs mr-4">
                                        <span class="avatar-title rounded-circle">{{(option.iso_code).slice(0,2)}}</span>
                                      </div>
                      <div class="option__desc d-flex flex-row w-50"><span class="option__title ml-2 my-auto w-100">{{ option.name }}</span>
                        
                      </div>
                      </div>
                    </template>
                  </multiselect>
      </b-form-group>
      <input type="hidden" name="country_id" v-model="newFormData.country_id">
      <b-form-group v-if="counties.length > 0" class="my-2"  label="County/State">
          <multiselect v-model="newFormData.county" track-by="name" label="name" :options="counties" :option-height="104" class="" >
                     <!--<template slot="singleLabel" slot-scope="{ option }">{{ option.name }} - {{ option.jobtitle }}</template>-->
                     <template slot="singleLabel" slot-scope="{ option }">
                     <div class="d-flex w-100 h-100">
                     <div class="avatar-xs mr-4 my-auto" v-if="newFormData.county.id !== ''">
                        <span class="avatar-title rounded-circle" >{{(option.name).slice(0,2)}}</span>
                       
                      </div>
                      <div class="option__desc d-flex flex-row flex-grow-1"><span v-if="newFormData.county.id !== ''"  class="option__title ml-2 my-auto w-100">{{ option.name }}</span>
                        <span v-else class="option__title my-auto w-100">Select State/County</span>

                      </div>
                      <div class="avatar-xs my-auto" v-if="newFormData.county.id == ''">
                      <span class="avatar-title rounded-circle bg-light text-primary"><i class="bx bx-chevron-down"></i></span>
                     </div>

                      </div></template>
                    <template slot="option" slot-scope="{ option }">
                      <div class="d-flex"><div class="avatar-xs mr-4">
                                        <span class="avatar-title rounded-circle">{{(option.name).slice(0,2)}}</span>
                                      </div>
                      <div class="option__desc d-flex flex-row w-50"><span class="option__title ml-2 my-auto w-100">{{ option.name }}</span>
                        
                      </div>
                      </div>
                    </template>
                  </multiselect>
      </b-form-group>
       <input type="hidden" name="county_id" v-model="newFormData.county_id">
      <!--
			          <b-form-group label="Country" class="required mt-2">
			            <b-form-select name="country_id" v-model="newFormData.country_id" @input="getCounties(newFormData.country_id)">
			            	<b-form-select-option v-for="country in countries" :value="country.id">
			            		{{country.name}}
			            	</b-form-select-option>
			            </b-form-select>
			          </b-form-group>
			          <b-form-group label="County/State/Region" class="required mt-2">
			            <b-form-select name="county_id" v-model="newFormData.county_id" :disabled="(newFormData.country_id == '') && (counties.length == 0)">
			            	<b-form-select-option v-for="county in counties" :value="county.id">
			            		{{county.name}}
			            	</b-form-select-option>
			            </b-form-select>
			          </b-form-group>-->
			          <b-form-group label="Postcode/Zip" class="required mt-2">
			            <b-form-input name="postcode" v-model="newFormData.postcode"></b-form-input>
			          </b-form-group>

			      		</b-card>
			      		
			      	</div>
			      	<div v-show="step == 2">
			      		<b-card class="border-radius-1em border shadow-none">
			         	<h5 class="heading-label required">Business Legal Status</h5>
			          <div class="d-flex">
			          <b-button
			                      class=" flex-grow-1 my-0 w-sm border-radius-1em mr-1"
			                      value="0"
			                      :class="{'active' : newFormData.company_legal_status == 'self-employed'}"
			                      variant="outline-light"
			                      @click="newFormData.company_legal_status = 'self-employed'"
			                    ><i v-if="newFormData.company_legal_status == 'self-employed'" class="bx bx-check-circle font-size-24 d-block text-success my-2"></i> 
			                    <i v-else class="bx bx-user font-size-24 d-block text-primary my-2"></i> Self-Employed
			              </b-button>
			              <b-button
			                      class=" flex-grow-1 my-0 w-sm border-radius-1em ml-1"
			                      value="0"
			                      :class="{'active' : newFormData.company_legal_status == 'limited'}"
			                      variant="outline-light"
			                      @click="newFormData.company_legal_status = 'limited'"
			                    ><i v-if="newFormData.company_legal_status == 'limited'" class="bx bx-check-circle font-size-24 d-block text-success my-2"></i> 
			                    <i v-else class="bx bx-store font-size-24 d-block text-primary my-2"></i> Limited Company
			              </b-button>
			              <b-button
			                      class=" flex-grow-1 my-0 w-sm border-radius-1em ml-1"
			                      value="0"
			                      :class="{'active' : newFormData.company_legal_status == 'partnership'}"
			                      variant="outline-light"
			                      @click="newFormData.company_legal_status = 'partnership'"
			                    ><i v-if="newFormData.company_legal_status == 'partnership'" class="bx bx-check-circle font-size-24 d-block text-success my-2"></i> 
			                    <i v-else class="mdi mdi-handshake-outline  font-size-24 d-block text-primary my-2"></i> Partnership
			              </b-button>
			            </div>
			            <input type="hidden" name="company_legal_stauts" :value="newFormData.company_legal_status">
			          </b-card>
			        </div>
			      	<div v-show="step == 2">
			          <h4  v-if="newFormData.company_legal_status !== 'self-employed'" class="text-center">Registered Office</h4>

			          <b-card v-if="newFormData.company_legal_status !== 'self-employed'" class="border-radius-1em border shadow-none">

			          	<b-form-group label="Search Companies House" class="position-relative">
			      			<div class="d-flex mb-2">
			      			<b-form-input type="input" @keypress="newFormData.company_registration_settings.registration_no.length > 3 ? searchCompaniesHouse() : null" v-model="companies_house_search" name="company_registration_settings_registration_no" placeholder="Search company number, name or address..."></b-form-input>
			      			<a class="btn btn-primary btn-rounded ml-2" @click="searchCompaniesHouse()" >
			      				Search
			      			</a>
			      			</div>
			      			<b-list-group class="position-absolute left-0 right-0 z-index-2 widget-scrollbox" v-if="'items' in companies_search_results && show_search_results == true">
			      				<b-list-group-item v-for="item in companies_search_results.items" @click="selectedRegisteredCompany(item), show_search_results = false" class="py-2">
			      					<h4 class="mb-0">{{item.title}}</h4>
			      					<p class="text-muted mb-0">{{item.address_snippet}}</p>
			      				</b-list-group-item>
			      			</b-list-group>
			      		</b-form-group>
		                <b-form-group label="Search Address">
		                  <vue-google-autocomplete @keyup.enter.prevent @keypress.enter.prevent id="registeredaddress-map" ref="registeredaddressmapref" :enableGeolocation="false" types="establishment" :fields="['address_components', 'adr_address', 'alt_id', 'formatted_address', 'geometry', 'icon', 'id', 'name', 'business_status', 'photo', 'place_id', 'scope', 'type', 'url', 'utc_offset', 'vicinity', ]" classname="form-control" placeholder="Start typing" v-on:placechanged="getRegisteredAddressData">
		                    </vue-google-autocomplete>
		                </b-form-group>
		                <gmap-map v-if="googleSearchRegisteredAddress !== ''" :center="{ lat: googleSearchRegisteredAddress.latitude, lng: googleSearchRegisteredAddress.longitude }" :zoom="10" style="height: 350px" class="mt-2 border-radius-1em">
		                  <gmap-marker
		                    
		                    :key="index"
		                    :position="{ lat: googleSearchRegisteredAddress.latitude, lng: googleSearchRegisteredAddress.longitude }"
		                    :clickable="true"
		                    :draggable="true"
		                    @click="center = { lat: googleSearchRegisteredAddress.latitude, lng: googleSearchRegisteredAddress.longitude }"
		                  ></gmap-marker>
		                </gmap-map>
		              </b-card>
		              <b-card  class="border-radius-1em border shadow-none border-primary" body-class="d-flex" v-if="googleSearchRegisteredAddress !== ''">
		                <div v-html="googleSearchRegisteredAddress.place.formatted_address"></div>
		              
		                <a class="btn btn-danger ml-auto" @click="googleSearchRegisteredAddress = '', newFormData.company_registration_settings.gplace_id = '', newFormData.company_registration_settings.longitude = 0, newFormData.company_registration_settings.latitude = 0">Remove Listing</a>
		              </b-card>
		              <b-card class="border-radius-1em border shadow-none" v-if="newFormData.company_legal_status !== 'self-employed'">
		              	<b-form-group label="Company Registration No." class="required mt-2">
			            <b-form-input name="company_registration_settings_registration_no" v-model="newFormData.company_registration_settings.registration_no"></b-form-input>
			          </b-form-group>
			          <b-form-group label="Address 1" class="required mt-2">
			            <b-form-input name="company_registration_settings_address1" v-model="newFormData.company_registration_settings.address1"></b-form-input>
			          </b-form-group>
			          <b-form-group label="Address 2" class="mt-2">
			            <b-form-input name="company_registration_settings_address2" v-model="newFormData.company_registration_settings.address2"></b-form-input>
			          </b-form-group>
			          <b-form-group label="Address 3" class="mt-2">
			            <b-form-input name="company_registration_settings_address3" v-model="newFormData.company_registration_settings.address3"></b-form-input>
			          </b-form-group>
			                   <b-form-group v-if="countries.length > 0" class="my-2" label="Country">
          <multiselect v-model="newFormData.company_registration_settings.country" track-by="name" label="name" :options="countries" :option-height="104" class="" @input="loadCounties">
                     <!--<template slot="singleLabel" slot-scope="{ option }">{{ option.name }} - {{ option.jobtitle }}</template>-->
                     <template slot="singleLabel" slot-scope="{ option }"><div class="d-flex w-100 h-100">
                     <div class="avatar-xs mr-4 my-auto" v-if="newFormData.company_registration_settings.country.id !== ''">
                        <span class="avatar-title rounded-circle" >{{(option.iso_code).slice(0,2)}}</span>
                       
                      </div>
                      <div class="option__desc d-flex flex-row flex-grow-1"><span v-if="newFormData.company_registration_settings.country.id !== ''"  class="option__title ml-2 my-auto w-100">{{ option.name }}</span>
                        <span v-else class="option__title my-auto w-100">Select Country</span>

                      </div>
                      <div class="avatar-xs my-auto" v-if="newFormData.company_registration_settings.country.id == ''">
                      <span class="avatar-title rounded-circle bg-light text-primary"><i class="bx bx-chevron-down"></i></span>
                     </div>

                      </div></template>
                    <template slot="option" slot-scope="{ option }">
                      <div class="d-flex"><div class="avatar-xs mr-4">
                                        <span class="avatar-title rounded-circle">{{(option.iso_code).slice(0,2)}}</span>
                                      </div>
                      <div class="option__desc d-flex flex-row w-50"><span class="option__title ml-2 my-auto w-100">{{ option.name }}</span>
                        
                      </div>
                      </div>
                    </template>
                  </multiselect>
      </b-form-group>
      <input type="hidden" name="company_registration_settings_country_id" v-model="newFormData.company_registration_settings.country_id">
      <b-form-group v-if="registrationcounties.length > 0" class="my-2"  label="County/State">
          <multiselect v-model="newFormData.company_registration_settings.county" track-by="name" label="name" :options="registrationcounties" :option-height="104" class="" >
                     <!--<template slot="singleLabel" slot-scope="{ option }">{{ option.name }} - {{ option.jobtitle }}</template>-->
                     <template slot="singleLabel" slot-scope="{ option }">
                     <div class="d-flex w-100 h-100">
                     <div class="avatar-xs mr-4 my-auto" v-if="newFormData.company_registration_settings.county.id !== ''">
                        <span class="avatar-title rounded-circle" >{{(option.name).slice(0,2)}}</span>
                       
                      </div>
                      <div class="option__desc d-flex flex-row flex-grow-1"><span v-if="newFormData.company_registration_settings.county.id !== ''"  class="option__title ml-2 my-auto w-100">{{ option.name }}</span>
                        <span v-else class="option__title my-auto w-100">Select State/County</span>

                      </div>
                      <div class="avatar-xs my-auto" v-if="newFormData.company_registration_settings.county.id == ''">
                      <span class="avatar-title rounded-circle bg-light text-primary"><i class="bx bx-chevron-down"></i></span>
                     </div>

                      </div></template>
                    <template slot="option" slot-scope="{ option }">
                      <div class="d-flex"><div class="avatar-xs mr-4">
                                        <span class="avatar-title rounded-circle">{{(option.name).slice(0,2)}}</span>
                                      </div>
                      <div class="option__desc d-flex flex-row w-50"><span class="option__title ml-2 my-auto w-100">{{ option.name }}</span>
                        
                      </div>
                      </div>
                    </template>
                  </multiselect>
      </b-form-group>
       <input type="hidden" name="company_registration_settings_county_id" v-model="newFormData.company_registration_settings.county_id">
      <!--
			          <b-form-group label="Country" class="required mt-2">
			            <b-form-select name="country_id" v-model="newFormData.company_registration_settings.country_id" @input="getCounties(newFormData.company_registration_settings.country_id)">
			            	<b-form-select-option v-for="country in countries" :value="country.id">
			            		{{country.name}}
			            	</b-form-select-option>
			            </b-form-select>
			          </b-form-group>
			          <b-form-group label="County/State/Region" class="required mt-2">
			            <b-form-select name="county_id" v-model="newFormData.company_registration_settings.county_id" :disabled="(newFormData.company_registration_settings.country_id == '') && (counties.length == 0)">
			            	<b-form-select-option v-for="county in counties" :value="county.id">
			            		{{county.name}}
			            	</b-form-select-option>
			            </b-form-select>
			          </b-form-group>-->
			          <b-form-group label="Postcode/Zip" class="required mt-2">
			            <b-form-input name="company_registration_settings_postcode" v-model="newFormData.company_registration_settings.postcode"></b-form-input>
			          </b-form-group>

			      		</b-card>
			      		<b-card class="border-radius-1em border shadow-none">
			      		<h5 class="heading-label required">VAT Registration.</h5>
			      		<b-form-group class="mb-2" label="Vat Registered">
			      			<b-form-radio-group :options="[{text: 'Yes', value: 1},{text: 'No', value: 0}]" v-model="newFormData.company_registration_settings.vat_registered"> </b-form-radio-group>
			      		</b-form-group>
			      		
			      		<b-form-group class="mb-2" label="Vat No." v-if="newFormData.company_registration_settings.vat_registered !== 0">
			      			<b-form-input type="input" name="company_registration_settings_vat_no" v-model="newFormData.company_registration_settings.vat_no"  @keydown.enter="searchVATNo()"></b-form-input>
			      		</b-form-group>
			      		<div v-if="vat_check_result !== ''  && show_vat_result !== false">
			      				<p class="p-3 rounded-4 bg-soft bg-success text-success" v-if="vat_company_match == true">
			      					Company Registration Address and VAT Match
			      				</p>
			      				<p class="p-3 rounded-4 bg-soft bg-danger text-danger" v-if="vat_company_match == false">
			      					Company Registration Address and VAT Address do not match
			      				</p>
			      		</div>
			      	</b-card>
			      	<b-card class="border-radius-1em border shadow-none">
			      		<h5 class="heading-label required">Professional Bodies Membership No(s).</h5>
			      		<b-form-group label="Professional Body">
			      			<b-form-select class="form-control"  v-model="professional_body">
			      				<b-form-select-option v-for="bodies in professional_bodies" :value="bodies.value">
			      					{{bodies.text}}
			      				</b-form-select-option>
			      			</b-form-select>
			      		</b-form-group>
			      		<b-form-group label="Membership No.">
			      			<b-form-input type="text" name="membership_no" v-model="membership_no"></b-form-input>
			      		</b-form-group>

			      		<a v-if="professional_body_index == -1" class="btn btn-primary btn-rounded mt-2 mb-3 btn-block" @click="addProfessionalBody()" >Add Professional Body</a>
			      		<a v-else class="btn btn-primary btn-rounded mt-2 mb-3 btn-block" @click="updateProfessionalBody()" >Update Professional Body</a>
			      		<b-overlay :show="loadingProfessionalBodies" >
			      		<b-list-group v-if="newFormData.company_registration_settings.professional_bodies.length > 0">
			      			<b-list-group-item class="d-flex">
			      				<strong class="my-auto mr-2 w-25">Name</strong>
								<strong class="my-auto mr-auto w-50">Membership No.</strong>
			      			</b-list-group-item>
			      			<b-list-group-item v-if="newFormData.company_registration_settings.professional_bodies.length > 0" v-for="(professionalbod, index) in newFormData.company_registration_settings.professional_bodies" class="d-flex">
			      			
								<span class="my-auto mr-2 w-25">{{professionalbod.name}}</span>
								<span class="my-auto mr-auto w-50">{{professionalbod.membership_no}}</span>
								
								<a class="btn btn-outline-light btn-rounded my-auto ml-auto" @click="editProfessionalBody(index)">
									<i class="bx bx-pencil"></i>
								</a>
								<a class="btn btn-outline-light btn-rounded my-auto ml-2" @click="newFormData.company_registration_settings.professional_bodies.splice(index, 1)">
									<i class="bx bx-trash-alt text-danger"></i>
								</a>
			      			</b-list-group-item>
			      		</b-list-group>
			      		<b-card v-else class="border-radius-1em border shadow-none py-4" body-class="d-flex flex-column  justify-content-center text-center">
			      			<i class="bx bx-sad mx-auto font-size-48 text-muted mb-2"></i>
			      			<h5 class="text-muted">No Professional Bodies Listed</h5>
			      		</b-card>
			      		</b-overlay>
			      	  </b-card>
			      	<!--<b-card class="border-radius-1em border shadow-none">
			      		<h5 class="heading-label required">Professional Bodies Membership No(s).</h5>
			      		<b-form-group class="mb-2" label="NAJ Membership No.">
			      			<b-form-input type="input" name="company_registration_settings_nag_membership_no" v-model="newFormData.company_registration_settings.nag_membership_no"></b-form-input>
			      		</b-form-group>
			      		<b-form-group class="mb-2" label="CMJ Membership No.">
			      			<b-form-input type="input" name="company_registration_settings_cmj_membership_no" v-model="newFormData.company_registration_settings.cmj_membership_no"></b-form-input>
			      		</b-form-group>
			      		<b-form-group label="Holden Membership No.">
			      			<b-form-input type="input" name="company_registration_settings_holden_membership_no" v-model="newFormData.company_registration_settings.holden_membership_no"></b-form-input>
			      		</b-form-group>
			      	  </b-card>-->


			      	</div>
			      	<div v-show="step == 3">
			      		<h4 class="text-center">Terms & Privacy Policy</h4>
			      	<b-card class="border-radius-1em border shadow-none">
			          
			       	  <h4>Terms of Service</h4>
			          <b-card class="shadow-none border-radius-1em border">
			          <simplebar style="min-height: 300px; max-height: 300px;">
			          	<div v-html="privacy_policy[0].text">
			          		
			          	</div>
			          </simplebar>
			      </b-card>
			          <b-card class="shadow-none border-radius-1em border" :class="{'border-primary' : newFormData.accpted_terms == 0, 'border-success' : newFormData.accpted_terms == 1}">
			          <h4>Agreements</h4>
			          <b-form-group class="required mt-2" label="I agree to the Gemesys Terms of Service.">
			           
			            <a class="btn btn-danger btn-rounded ml-auto"  @click="newFormData.accpted_terms = 0">
			            	Decline
			            </a>
			            <a class="btn btn-primary btn-rounded" @click="newFormData.accpted_terms = 1">
			            	Accept
			            </a>
			            <a v-if="newFormData.accpted_terms == 1" class="btn btn-outline-success btn-rounded float-right" @click="newFormData.accpted_terms = 1">
			            	Accepted <i class="bx bx-check"></i>
			            </a>
			         </b-form-group>
			         <input type="hidden" name="accpted_terms" v-model="newFormData.accpted_terms">
			     	</b-card>
			       	  <h4>Privacy Policy</h4>
			          <b-card class="shadow-none border-radius-1em border">
			          <simplebar style="min-height: 300px; max-height: 300px;">
			          	<div v-html="privacy_policy[0].text">
			          		
			          	</div>
			          </simplebar>
			          </b-card>
			          <b-card class="shadow-none border-radius-1em border" :class="{'border-primary' : newFormData.privacy_policy == 0, 'border-success' : newFormData.privacy_policy == 1}">
			          <h4>Agreements</h4>
			          <b-form-group class="required mt-2" label="I agree to the Gemesys Privacy Policy.">
			           
			            <a class="btn btn-danger btn-rounded ml-auto"  @click="newFormData.privacy_policy = 0">
			            	Decline
			            </a>
			            <a class="btn btn-primary btn-rounded" @click="newFormData.privacy_policy = 1">
			            	Accept
			            </a>
			            <a v-if="newFormData.privacy_policy == 1" class="btn btn-outline-success btn-rounded float-right" @click="newFormData.privacy_policy = 1">
			            	Accepted <i class="bx bx-check"></i>
			            </a>
			         </b-form-group>
			         <input type="hidden" name="privacy_policy" v-model="newFormData.privacy_policy">

			     	</b-card>
			         </b-card>
			          <a class="btn btn-primary btn-rounded float-left w-25" v-if="(step > 1) && (step < 5)" @click="step--">Previous</a>
			          <button type="submit" class="btn btn-success btn-rounded float-right w-25" @click.prevent="submitForm()">Submit</button>
			        </div>
			        <div v-show="step == 4">
			        	<Subscription v-if="form_result !== null" :company="form_result" :user="user" @payment-success="subscriptionSuccess" > </Subscription>
			        </div>
			        <div v-show="step == 5">
			        	<h4 class="text-center">Subscription Details</h4>
			      		<b-card class="border-radius-1em border shadow-none" v-if="subscription_result.subscription !== ''" >

			      			  <h4 class="text-center pt-4">Congratulations! Your payment has succeed</h4>
		              <p class="text-center">Here's your subscription details</p>
		  
		              <b-card class="border border-radius-1em shadow-none">
		              	
		              	<div class="d-flex pt-3 border-bottom">
		              	<p>User Plan</p>
		              	<h4 class="ml-auto font-size-16" v-if="subscription_result.subscription !== ''">{{subscription_result.subscription.name}}</h4>
		              	</div>
		              	<div class="d-flex pt-3 border-bottom">
		              	<p>Frequency</p>
		              	<h4 class="ml-auto font-size-16" v-if="subscription_result.subscription !== ''">{{subscription_result.subscription.payment_term}}</h4>
		              	</div>
		              	<div class="d-flex pt-3 border-bottom">
		              	<p>Users</p>
		              	<h4 class="ml-auto font-size-16">{{subscription_result.quantity}}</h4>
		              	</div>
		              	<div class="d-flex pt-3 border-bottom">
		              	<p>Price Per User</p>
		              	<h4 class="ml-auto font-size-16"><small>£</small>{{subscription_result.price_per_unit}}</h4>
		              	</div>
		              	<div class="d-flex pt-3 border-bottom">
		              	<p>Total User Price</p>
		              	<h4 class="ml-auto font-size-16"><small>£</small>{{(parseFloat(subscription_result.price_per_unit) * parseFloat(subscription_result.quantity)).toFixed(2)}}</h4>
		              	</div>
		              	<div class="d-flex pt-3 border-bottom border-2 border-primary">
		              	<p>Total Flat Price</p>
		              	<h4 class="ml-auto font-size-16"><small>+ £</small>{{parseFloat(subscription_result.flat_price).toFixed(2)}}</h4>
		              	</div>
		              	<div class="d-flex pt-3">
		              	<p>Total</p>
		              	<h3 class="ml-auto"><small>= £</small>{{(parseFloat(subscription_result.price_per_unit) * parseFloat(subscription_result.quantity) + parseFloat(subscription_result.flat_price)).toFixed(2)}}</h3>
		              	</h3>
		              	</div>
		              </b-card>
		            
		              <h4 class="text-center pt-4">Getting started with your account</h4>
		              <p class="text-center">To get started with your account...</p>
		              <b-card class="border border-radius-1em shadow-none" v-if="form_result !== null" body-class="d-flex">
		              	<div class="w-75 my-auto">	
		                Your company url is:<br>
		                <a :href="'https://'+form_result.nickname+'.gemesys.co.uk/'" target="_blank">{{form_result.nickname}}.gemesys.co.uk</a>
		                <input :value="'https://'+form_result.nickname+'.gemesys.co.uk/'" type="text" class="visibility-hidden">
		              	</div>
		              	<div class="w-25 my-auto">
		              		<a class="btn btn-primary btn-block btn-rounded" :href="'https://'+form_result.nickname+'.gemesys.co.uk/login'" target="_blank">Sign in <i class="bx bx-link-external"></i></a>
		              	</div>
		              </b-card>
		              <b-card class="border border-radius-1em shadow-none">

		              
		              <a href="javascript:;"><b-card class="border-radius-1em border shadow-none" :class="{'bg-light': setupoptions.introtour == 1}"  @click="setupoptions.introtour = !setupoptions.introtour">
		                <i class="bx bx-bus font-size-24 w-100"></i>
		                Take Set-up Tour<br>
		                <small class="text-dark">Want some help setting up? Click here and we'll run you through how to configure your company.</small>
		                <i v-if="setupoptions.introtour == 1" class="bx bx-check-circle font-size-24 d-block text-success my-2 position-absolute top-1 position-absolute top-0 right-0 mr-3 mt-3"></i>
		              </b-card></a>
		              <p>Select Your Set-Up Option</p>
		              <div class="d-flex">
		              <a href="javascript:;" class="w-50"><b-card class="border-radius-1em border shadow-none mr-1" :class="{'bg-primary': (hover == 1) && (setupoptions.auto == 0), 'bg-light' : setupoptions.auto == 1}" @click="setupoptions.auto = 1">
		                <i class="bx bx-cog font-size-24 w-100"></i>
		                Auto Set-up<br>
		                <small class="text-dark">Can't wait to get started? Click here and we'll add some resources to get you started straight away.</small>
		                  <i v-if="setupoptions.auto == 1" class="bx bx-check-circle font-size-24 d-block text-success my-2 position-absolute top-1 position-absolute top-0 right-0 mr-3 mt-3"></i>
		              </b-card></a>
		              <a href="javascript:;" class="w-50"><b-card class="border-radius-1em border shadow-none ml-1"  :class="{'bg-primary': (hover == 2) && (setupoptions.auto == 1), 'bg-light' : setupoptions.auto == 0}" @click="setupoptions.auto = 0">
		                <i class="bx bx-cog font-size-24 w-100"></i>
		                Manual Set-up<br>
		                <small class="text-dark">Know what you're doing? Sounds good! Set-up manually and configure the system yourself.</small>
		                <i v-if="setupoptions.auto == 0" class="bx bx-check-circle font-size-24 d-block text-success my-2 position-absolute top-0 right-0 mr-3 mt-3"></i>
		              </b-card></a>
		            	</div>
		               </b-card>             
			        	

			        	<!--<a class="btn btn-success btn-rounded btn-block" @click="setUpFormTrigger()">
			        		Start Auto Set-up
			        	</a>-->

			      		</b-card>
			        </div>
			         <div v-show="step == 6">
			      		<h4 class="text-center">Payment Options</h4>
			      		<b-card class="border-radius-1em border shadow-none">
			      		<h5>Accept Marketplace, Website and ePos Payments</h5>
			      		<p>To use Gemesys marketplace, website or epos you will need to set-up you payment account. </p>

			      		<b-button
                      class=" w-100 border-radius-1em my-0 w-xl"
                      value="0"
                      :class="{'active' : setupoptions.payments == (true || 1 || '1')}"
                      variant="outline-light"
                      @click="connectStripeAccount(), setupoptions.payments = !setupoptions.payments"
                      v-if="stripeAccount.length == 0"
                    ><i class="bx bx-pound font-size-24 d-block py-4 text-primary my-2"></i> 
                    Setup Payments Account
              </b-button>
              <b-card v-else-if="stripeAccount == 'success'" class="border-radius-1em border shadow-none" body-class=" d-flex flex-column align-items-center">
              	<i class="bx bx-check-circle font-size-24 d-block py-4 text-primary mx-auto"></i> Payments Account Successfully Linked
              </b-card>

			      	</b-card>
			      	</div>
			        <div v-show="step == 7">
			      		<h4 class="text-center">Configuration Options</h4>
			      		<b-card class="border-radius-1em border shadow-none">
			      		<h5>Create Your First Shop</h5>
			      		<p>Start using gemesys by creating your first shop to enable listing products in your point of sale and adding card terminals to your selling locations.</p>
			      		<b-form id="addnewlisting" method="POST" enctype="multipart/form-data">
			      		<b-row>
			      			
			      			<b-col md="12">
           <b-overlay
                        id="overlay-background"
                        :show="loadingListingForm"
                        variant="white"
                        opacity="0.85"
                        blur="1px"
                        rounded="md"
                      >
          <b-row>
            <b-col md="12">
              <h5 class="d-flex">Baisc Info <i class="bx bx-right-arrow-circle text-primary font-size-20 ml-2"></i></h5>
            </b-col>
 
            <b-col md="12">
            <b-row>
         
            
              <b-col md="12">
              <b-card class="border-radius-1em border shadow-none">
                  <!--<b-form-group label="Listing Type">
                    <b-form-radio-group
                      id="radio-group-1"
                      name="listing_type_id"
                    >
                      <b-form-radio v-for="type in listing_types" :value="type.id" class="d-inline mr-3 radio-inline">
                        {{type.name}}
                      </b-form-radio>
                    </b-form-radio-group>
                  </b-form-group>-->

                  <label>Listing Type</label>
                  <b-row v-if="listing_types.length !== 0">
                  	<b-col md="4" v-for="type in listing_types">
	                  	<b-card class="border-radius-1em border shadow-none"  @click="newListing.listing_type_id = type.id" :class="{'border-primary' : newListing.listing_type_id == type.id}" >
	                  		{{type.name}}

	                  		<i class="bx bx-check-circle text-success right-0 top-0 bottom-0" v-if="newListing.listing_type_id == type.id"></i>
	                  	</b-card>
                  	</b-col>
                  </b-row>
                <b-form-group class="mt-2" label="Listing Name">
                  <b-form-input type="text" name="name"></b-form-input>
                </b-form-group>
                <b-form-group class="mt-2" label="Listing Description">
                  <b-form-textarea name="description" rows="5"></b-form-textarea>
                </b-form-group>
              </b-card>
            </b-col>
          </b-row>
        </b-col>
          <b-col md="12">
            <h5 class="d-flex">Images <i class="bx bx-right-arrow-circle text-primary font-size-20 ml-2"></i></h5>
            </b-col>
            <b-col md="12">
            <b-row>
            <b-col md="6">
              <b-card class="border-radius-1em border shadow-none">
                 <b-form-group label="Alternative Logo">

                  <vue-dropzone id="dropzone" ref="myVueDropzone" :use-custom-slot="true" :options="dropzoneOptions" @vdropzone-file-added="logoAdded"  @vdropzone-drop="logoAdded">
                        <div class="dropzone-custom-content">
                            <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                            <h4>Drop files here or click to upload.</h4>
                        </div>
                        </vue-dropzone>
                 <!--<input class="form-control mb-2" name="logo" type="file">-->
                </b-form-group>
              </b-card>
             </b-col>
             <b-col md="6">
              <b-card class="border-radius-1em border shadow-none">
                <b-form-group label="Banner Image">
                  <vue-dropzone id="dropzone2" ref="myVueDropzone" :use-custom-slot="true" :options="dropzoneOptions" @vdropzone-file-added="listingImageAdded"  @vdropzone-drop="listingImageAdded">
                        <div class="dropzone-custom-content">
                            <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                            <h4>Drop files here or click to upload.</h4>
                        </div>
                        </vue-dropzone>
                  <!--<input class="form-control mb-2" name="banner_image" type="file">-->
                </b-form-group>
              </b-card>
            </b-col>
          </b-row>
        </b-col>
        
          <b-col md="12">
            <h5 class="d-flex">Location Details <i class="bx bx-right-arrow-circle text-primary font-size-20 ml-2"></i></h5>
            </b-col>
            <b-col md="12">
            <b-row>
              <b-col md="12">
                <b-card class="border-radius-1em border shadow-none">
                <b-form-group label="Search Address">
                  <vue-google-autocomplete id="listing-map" :enableGeolocation="false" types="establishment" :fields="['address_components', 'adr_address', 'alt_id', 'formatted_address', 'geometry', 'icon', 'id', 'name', 'photo', 'place_id', 'scope', 'type', 'url', 'vicinity', ]" classname="form-control" placeholder="Start typing" v-on:placechanged="getListingAddressData">
                    </vue-google-autocomplete>
                </b-form-group>
                <gmap-map v-if="googleSearchListingAddress !== ''" :center="{ lat: googleSearchListingAddress.latitude, lng: googleSearchListingAddress.longitude }" :zoom="10" style="height: 350px" class="mt-2 border-radius-1em">
                  <gmap-marker
                    
                    :key="index"
                    :position="{ lat: googleSearchListingAddress.latitude, lng: googleSearchListingAddress.longitude }"
                    :clickable="true"
                    :draggable="true"
                    @click="center = { lat: googleSearchListingAddress.latitude, lng: googleSearchListingAddress.longitude }"
                  ></gmap-marker>
                </gmap-map>
              </b-card>
              <b-card class="border-radius-1em border shadow-none border-primary" body-class="d-flex" v-if="googleSearchListingAddress !== ''">
                <div v-html="googleSearchListingAddress.place.formatted_address"></div>
              
                <a class="btn btn-danger ml-auto" @click="googleSearchListingAddress = '', newListing.gplace_id = '', newListing.longitude = 0, newListing.latitude = 0">Remove Listing</a>
              </b-card>
              <b-card class="border-radius-1em border shadow-none">
                <b-form-group class="mt-2"  label="Address 1">
                  <b-form-input type="text" name="address1" v-model="newListing.address1"></b-form-input>
                </b-form-group>
                <b-form-group class="mt-2" label="Address 2">
                  <b-form-input type="text" name="address2" v-model="newListing.address2"></b-form-input>
                </b-form-group>
                <b-form-group class="mt-2" label="Address 3">
                  <b-form-input type="text" name="address3" v-model="newListing.address3"></b-form-input>
                </b-form-group>
                
                            <b-form-group v-if="countries.length > 0" class="my-2" label="Country">
          <multiselect v-model="newListing.country" track-by="name" label="name" :options="countries" :option-height="104" class="" @input="loadCounties">
                     <!--<template slot="singleLabel" slot-scope="{ option }">{{ option.name }} - {{ option.jobtitle }}</template>-->
                     <template slot="singleLabel" slot-scope="{ option }"><div class="d-flex w-100 h-100">
                     <div class="avatar-xs mr-4 my-auto" v-if="newListing.country.id !== ''">
                        <span class="avatar-title rounded-circle" >{{(option.iso_code).slice(0,2)}}</span>
                       
                      </div>
                      <div class="option__desc d-flex flex-row flex-grow-1"><span v-if="newListing.country.id !== ''"  class="option__title ml-2 my-auto w-100">{{ option.name }}</span>
                        <span v-else class="option__title my-auto w-100">Select Country</span>

                      </div>
                      <div class="avatar-xs my-auto" v-if="newListing.country.id == ''">
                      <span class="avatar-title rounded-circle bg-light text-primary"><i class="bx bx-chevron-down"></i></span>
                     </div>

                      </div></template>
                    <template slot="option" slot-scope="{ option }">
                      <div class="d-flex"><div class="avatar-xs mr-4">
                                        <span class="avatar-title rounded-circle">{{(option.iso_code).slice(0,2)}}</span>
                                      </div>
                      <div class="option__desc d-flex flex-row w-50"><span class="option__title ml-2 my-auto w-100">{{ option.name }}</span>
                        
                      </div>
                      </div>
                    </template>
                  </multiselect>
      </b-form-group>
      <input type="hidden" name="country_id" v-model="newListing.country_id">
      <b-form-group v-if="counties.length > 0" class="my-2"  label="County/State">
          <multiselect v-model="newListing.county" track-by="name" label="name" :options="counties" :option-height="104" class="" >
                     <!--<template slot="singleLabel" slot-scope="{ option }">{{ option.name }} - {{ option.jobtitle }}</template>-->
                     <template slot="singleLabel" slot-scope="{ option }">
                     <div class="d-flex w-100 h-100">
                     <div class="avatar-xs mr-4 my-auto" v-if="newListing.county.id !== ''">
                        <span class="avatar-title rounded-circle" >{{(option.name).slice(0,2)}}</span>
                       
                      </div>
                      <div class="option__desc d-flex flex-row flex-grow-1"><span v-if="newListing.county.id !== ''"  class="option__title ml-2 my-auto w-100">{{ option.name }}</span>
                        <span v-else class="option__title my-auto w-100">Select State/County</span>

                      </div>
                      <div class="avatar-xs my-auto" v-if="newListing.county.id == ''">
                      <span class="avatar-title rounded-circle bg-light text-primary"><i class="bx bx-chevron-down"></i></span>
                     </div>

                      </div></template>
                    <template slot="option" slot-scope="{ option }">
                      <div class="d-flex"><div class="avatar-xs mr-4">
                                        <span class="avatar-title rounded-circle">{{(option.name).slice(0,2)}}</span>
                                      </div>
                      <div class="option__desc d-flex flex-row w-50"><span class="option__title ml-2 my-auto w-100">{{ option.name }}</span>
                        
                      </div>
                      </div>
                    </template>
                  </multiselect>
      </b-form-group>
       <input type="hidden" name="county_id" v-model="newListing.county_id">
                <b-form-group class="mt-2" label="City">
                  <b-form-input type="text" name="city" v-model="newListing.city"></b-form-input>
                </b-form-group>
                <b-form-group class="mt-2" label="Postcode/Zip">
                  <b-form-input type="text" name="postcode" v-model="newListing.postcode"></b-form-input>
                </b-form-group>
              </b-card>
              </b-col>
            </b-row>
          </b-col>
          <b-col md="12">
            <h5 class="d-flex">Location Details <i class="bx bx-right-arrow-circle text-primary font-size-20 ml-2"></i></h5>
            </b-col>
            <b-col md="12">
             <b-row>
              <b-col>
                <b-card class="border-radius-1em border shadow-none">
                <b-form-group  class="mt-2" label="Email">
                  <b-form-input type="text" name="email"></b-form-input>
                </b-form-group>
                <b-form-group class="mt-2" label="Phone">
                  <b-form-input type="text" name="phone"></b-form-input>
                </b-form-group>
                <b-form-group class="mt-2" label="Website">
                  <b-form-input type="text" name="website"></b-form-input>
                </b-form-group>
               </b-card>
              </b-col>
              </b-row>
            </b-col>
            <b-col md="12">
            <h5 class="d-flex">Visibility <i class="bx bx-right-arrow-circle text-primary font-size-20 ml-2"></i></h5>
            </b-col>
            <b-col md="12">
              <b-card class="border-radius-1em border shadow-none">
              <b-row>
              <b-col md="12">
                <div class="d-flex">
            <b-button
                      class="flex-grow-1 my-0 w-sm"
                      :class="{'active' : newListing.active == (true || 1 || '1')}"
                      variant="outline-light"
                      @click="newListing.active = !newListing.active"
                    ><i v-if="newListing.active == (true || 1 || '1')" class="bx bx-check-circle font-size-24 d-block text-success my-2"></i> 
                    <i v-else class="bx bx-basket font-size-24 d-block text-primary my-2"></i> My Shops
              </b-button>
              <b-button
                      class=" flex-grow-1 my-0 w-sm"
                      :class="{'active' : newListing.public == (true || 1 || '1')}"
                      variant="outline-light"
                      @click="newListing.public = !newListing.public"
                    ><i v-if="newListing.public == (true || 1 ||  '1')" class="bx bx-check-circle font-size-24 d-block text-success my-2"></i> 
                    <i v-else class="bx bx-basket font-size-24 d-block text-primary my-2"></i> Public Market
              </b-button>
              <b-button
                      class=" flex-grow-1 my-0 w-sm"
                      :class="{'active' : newListing.trade == (true || 1 || '1')}"
                      variant="outline-light"
                      @click="newListing.trade = !newListing.trade"
                    ><i v-if="newListing.trade == (true || 1 || '1')" class="bx bx-check-circle font-size-24 d-block text-success my-2"></i> 
                    <i v-else class="bx bx-basket font-size-24 d-block text-primary my-2"></i> Trade Market
              </b-button>
            
            </div>
                <!--<div
                          class="form-check form-switch form-switch-sm d-inline-block mr-2 mb-0 mt-auto"
                          dir="ltr"
                        >
                        <input
                            class="form-check-input"
                            type="checkbox"
                            id="SwitchCheckSizesm"
                          
                            value="1"
                            unchecked-value="0"
                            name="active"
                          />
                          <label class="form-check-label" for="SwitchCheckSizesm" 
                            >Active</label
                          >
                      </div>
                      <div
                          class="form-check form-switch form-switch-sm d-inline-block mr-2 mb-0"
                          dir="ltr"
                        >
                        <input
                            class="form-check-input"
                            type="checkbox"
                            id="SwitchCheckSizesm"
                         
                            value="1"
                            unchecked-value="0"
                            name="trade"
                          />
                          <label class="form-check-label" for="SwitchCheckSizesm" 
                            >Trade</label
                          >
                      </div>
                      <div
                          class="form-check form-switch form-switch-sm d-inline-block mr-2 mb-auto"
                          dir="ltr"
                        >
                        <input
                            class="form-check-input"
                            type="checkbox"
                            id="SwitchCheckSizesm"
                           
                            value="1"
                            unchecked-value="0"
                            name="public"
                          />
                          <label class="form-check-label" for="SwitchCheckSizesm" 
                            >Public</label
                          >
                      </div>-->
                    </b-col>
                  </b-row>
                </b-card>
                  <input type="hidden" name="featured" value="0">
              </b-col>
              
              <!--<b-col md="12">
                <b-button variant="success" class="w-100 mt-3" @click="submitListing()">Save</b-button>
              </b-col>-->
              
			      		</b-row>
			      	</b-overlay>
			      </b-col>
			    </b-row>
			  </b-form>
				      	</b-card>
			      	</div>

			        <div v-show="step == 8">
			        		<div class="row">
              		<div class="col-md-12">
              			<h4 class="text-center">
              				Company Staff
              			</h4>
              			<p class="mb-4 text-center">
              			 To start appraising your staff set-up company values which they will be measured against. These provide the basis of understanding the attitude and values of your staff and how they relate to your company values. 
              			</p>
              			<b-card class="border-radius-1em border shadow-none">
              			<div v-for="(value, index) in setupoptions.users" class="d-flex mb-3">
              				<b-form-group class="mb-0 mt-auto mr-2 w-25"  v-if="setupoptions.users.length !== 0" :label="'Firstname '+ (index + 1)">
              					<b-form-input type="text" v-model="setupoptions.users[index].name"></b-form-input>
              				</b-form-group>
              				<b-form-group class="mb-0 mt-auto mr-2 w-25"  v-if="setupoptions.users.length !== 0" :label="'Lastname '+ (index + 1)">
              					<b-form-input type="text" v-model="setupoptions.users[index].lastname"></b-form-input>
              				</b-form-group>
              				<b-form-group class="mb-0 mt-auto mr-2 w-25"  v-if="setupoptions.users.length !== 0" :label="'Email '+ (index + 1)">
              					<b-form-input type="email" v-model="setupoptions.users[index].email"></b-form-input>
              				</b-form-group>
              				<b-form-group class="mb-0 mt-auto mr-2 w-25"  v-if="setupoptions.users.length !== 0" :label="'User Role '+ (index + 1)">
              					<b-form-select class="form-control" v-model="setupoptions.users[index].role_id">
              						<b-form-select-option v-for="role in user_roles" :value="role.id">
              							{{role.name}}
              						</b-form-select-option>
              					</b-form-select>
              				</b-form-group>
              				<a class="btn btn-light mt-auto" @click="setupoptions.users.splice(index, 1)">
              						<i class="mdi mdi-minus"></i>
              				</a>
              				</div>
              				<a class="btn btn-outline-light btn-block btn-rounded" v-if="setupoptions.users.length <= subscription_result.quantity" @click="setupoptions.users.push({name: '', lastname: '', email: '', jobtitle: '', role_id: '', company_job_role_id: null})">
              					Add New Value <i class="mdi mdi-plus"></i>
              				</a>
              				<!--<a class="btn btn-primary btn-block btn-rounded mt-3" @click="step++, startAutoSetup()">
              					Next Step
              				</a>-->
              			</b-card>
              		</div>
              	</div>
			        </div>
			        <div v-show="step == 9">

			        	<h4 class="text-center">E-Commerce Options</h4>
			        		<b-card class="border-radius-1em border shadow-none">
					      		<h5>My Website</h5>
					      		<p>You can use Gemesys website builder to create your e-commerce website or you can sync to an existing e-commerce store.</p>
					      		<p>Please select the most appropriate option for your company below:</p>
					      		<b-card  class="border-radius-1em border shadow-none" body-class="d-flex w-100"  @click="setupoptions.gemesys_website = 1, setupoptions.import = 0"><h5>I'm starting afresh with Gemesys ecommerce and will input products manually.</h5>
					      			<i v-if="setupoptions.gemesys_website == 1  && setupoptions.import == 0" class="bx bx-check-circle text-success ml-auto my-auto font-size-24"></i>
					      		</b-card>
					      		<b-card  class="border-radius-1em border shadow-none" body-class="d-flex w-100"  @click="setupoptions.gemesys_website = 1, setupoptions.import = 1"><h5>I'm migrating to Gemesys ecommerece and I want to import my products from an existing e-commerce store or CSV file. </h5>
					      			<i v-if="setupoptions.gemesys_website == 1 && setupoptions.import == 1" class="bx bx-check-circle text-success ml-auto my-auto font-size-24"></i>
					      		</b-card>
					      		<b-card class="border-radius-1em border shadow-none" body-class="d-flex w-100"  @click="setupoptions.gemesys_website = 0, setupoptions.import = 1"><h5>I want to use my existing e-commerce store for ecommerce and use Gemesys for epos and other features. </h5>
					      			<i  v-if="setupoptions.gemesys_website == 0 && setupoptions.import == 1" class="bx bx-check-circle text-success ml-auto my-auto font-size-24"></i>
					      		</b-card>
							       <small>
							       	These settings can be updated later logged into your dashboard via Company > Settings > Website & E-Commerce
							       </small>
					      	</b-card>

					      	<b-card class="border-radius-1em border shadow-none" v-if="setupoptions.gemesys_website == 1  && setupoptions.import == 0">
			      		<h5>Categories</h5>
			      		<p>To get started, pre-fill your organisation with categories from our public or trade marketplaces. This allows you to list categories on your website without any additional set-up!</p>


			      		      <div class="row px-2">
                             <b-button v-for="collection in public_categories"
                        class="custom-radio flex-grow-0 border-radius-1em inset-border py-4 custom-radio-outline position-relative custom-radio-primary my-0 w-25 w-sm col-md-4"
                        value="0"
                        :class="{'active' : activeInCategories(collection, 'public')}"
                        variant="outline-light"
                         @click="updateActiveCategories(collection, 'public')"
                      >
                      <img class="avatar d-block avatar-md rounded-circle mx-auto" v-if="collection.image !== null" :src="collection.image">
                      <img v-else class="avatar d-block avatar-md rounded-circle mx-auto" src="/images/product-placeholder.png">
                      <i v-if="activeInCategories(collection, 'public')" class="bx bx-check-circle font-size-24 d-block text-success my-2 position-absolute top-1"></i> {{collection.name}}
                </b-button>
              </div>
			      	</b-card>
			      	<b-card class="border-radius-1em border shadow-none" v-if="setupoptions.gemesys_website == 1  && setupoptions.import == 0">
			      		<h5>Brands</h5>
			      		<p>To get started, pre-fill your organisation with brands from our public or trade marketplaces. This allows you to list brands on your website without any additional set-up!</p>
			      		<div class="w-100 d-flex" v-if="brands.data.length > 0">
			      			<a class="btn btn-outline-light btn-rounded my-auto" v-if="brands.current_page > 1" @click="brandspage--, getBrands()">
			      				<i class="bx bx-chevron-left"></i>
			      			</a>
			      			<div class="mt-sm-0 form-inline w-100" >
			                  <div class="search-box mr-2 ml-auto mb-3">
			                    <div class="position-relative">
			                      <input
			                        type="text"
			                        class="form-control w-100 border-0"
			                        placeholder="Search name, reference or description..."
			                        v-model="brandssearch" @keydown.enter="getBrands()" 
			                      />
			                      <i class="bx bx-search-alt search-icon"></i>
			                    </div>
			                  </div>
			                </div>
			      			<a class="btn btn-outline-light btn-rounded my-auto" v-if="(brands.current_page > 1) && (brands.current_page < brands.total)" @click="brandspage++, getBrands()">
			      				<i class="bx bx-chevron-right"></i>
			      			</a>
			      		</div>
			      		<b-button v-for="brand in brands.data"
                        class="custom-radio flex-grow-0 border-radius-1em inset-border py-4 custom-radio-outline position-relative custom-radio-primary my-0 w-25 w-sm col-md-4"
                        value="0"
                        :class="{'active' : activeInBrands(brand)}"
                        variant="outline-light"
                         @click="updateActiveBrands(brand)"
                      >
                      <img class="avatar d-block avatar-md rounded-circle mx-auto" v-if="brand.logo !== (null || '') " :src="brand.logo">
                      <img v-else class="avatar d-block avatar-md rounded-circle mx-auto" src="/images/product-placeholder.png">
                      <i v-if="activeInBrands(brand)" class="bx bx-check-circle font-size-24 d-block text-success my-2 position-absolute top-1"></i> {{brand.name}}
                </b-button>
			      	</b-card>
			        </div>
			       <div v-show="step == 10">
			       	<div v-if="(autoSetupLoading == true)">
		              <b-card class="border shadow-none">
		                  <div><b-progress v-model="setupprogress" :max="100" animated show-value height="20px"  ></b-progress>
		                    <h5  class="mt-2 text-center" v-if="(setupprogress < 100)"><i class="mdi mdi-wrench bx bx-tada"></i> Setting Up Your Account...</h5>
		                    <h5  class="mt-2 text-center" v-else><i class="bx bx-check-circle bx-tada text-success"></i> Account Set-up Complete. Redirecting...</h5>
		                  </div>
		              </b-card>
		            </div>
		            <div v-else>
		              <b-card class="border shadow-none">
		                  <div><b-progress v-model="setupprogress" :max="100" animated show-value height="20px"  ></b-progress>
		                    <h5  class="mt-2 text-center" v-if="(setupprogress < 100)"><i class="mdi mdi-wrench bx bx-tada"></i> Setting Up Your Account...</h5>
		                    <h5  class="mt-2 text-center" v-else><i class="bx bx-check-circle bx-tada text-success"></i> Account Set-up Complete. Redirecting...</h5>
		                  </div>
		              </b-card>
		            </div>

			       </div>
			      	
			    	</b-overlay>
			        <a class="btn btn-primary btn-rounded float-left w-25" v-if="(step > 1) && (step < 3)" @click="step--">Previous</a>
			        <a class="btn btn-primary btn-rounded float-right w-25" :class="{'w-100' : step == 1}" v-if="step < 3" @click="validateStepsForm(step)">Next</a>
			        <a class="btn btn-light btn-rounded mt-2 w-100" v-if="(step == 5) && (setupoptions.auto == 1)" @click="validateStepsForm(step)">Go to Set-up</a>
			        <a class="btn btn-light btn-rounded mt-2 w-100" v-if="(step == 5) && (setupoptions.auto == 0)" :href="'https://'+newFormData.nickname+'.gemesys.co.uk'">Go to Set-up</a>
			        <a class="btn btn-primary btn-rounded float-right w-100" v-if="step > 5 && step < 9" @click="validateStepsForm(step)">Next Step</a>
			         <a class="btn btn-primary btn-rounded float-right w-100" v-if="step == 9" @click="runSetup()">Run Setup</a>
			        <a class="btn btn-light btn-rounded float-right w-100" v-if="step > 5 && step < 7" @click="validateStepsForm(step, false)">Skip this Step</a>
			        <a class="btn btn-light btn-rounded float-right w-100" v-if="step > 6 && step < 9" @click="validateStepsForm(step, true)">Skip this Step</a>
			        <b-button variant="primary" class="btn btn-primary btn-rounded float-right w-100" v-if="step == 10"  :href="'https://'+form_result.nickname+'.gemesys.co.uk'" :disabled="autoSetupComplete == false" >Finish & Login</b-button>
			          </b-form>
			          </div>
			      
    					</div>

    		</div>
    	</div>
</b-container>
</template>

<style lang="scss" module></style>