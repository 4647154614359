<script type="text/javascript">
	import PageBuilderProcessor from "./PageBuilderProcessor";
	import WebsiteMenus from "./widgets/web/WebsiteMenus";
	import ImageGalleryWidget from "./widgets/web/ImageGalleryWidget";
	import ImageWidget from "./widgets/web/ImageWidget";
	import TextWidget from "./widgets/web/TextWidget";
	import ProductsListWidget from "./widgets/web/ProductsListWidget";
	import CategoryListWidget from "./widgets/web/CategoryListWidget";
	import SpacerDividerWidget from "./widgets/web/SpacerDividerWidget";
	import ListingWidget from "./widgets/web/ListingWidget";
	import MapWidget from "./widgets/web/MapWidget";
	import SocialWidget from "./widgets/web/SocialWidget";
	import FormWidget from "./widgets/web/FormWidget";
	import SearchWidget from "./widgets/web/SearchWidget";
	import SliderWidget from "./widgets/web/SliderWidget";
	import CardsListWidget from "./widgets/web/CardsListWidget";
	import ProductImageGallery from "./widgets/template/product-image-gallery";
	import ProductTitleBlock from "./widgets/template/product-title-block";
	import ProductPriceBlock from "./widgets/template/product-price-block";
	import ProductCartButton from "./widgets/template/product-cart-button";
	import ProductFeatures from "./widgets/template/product-features";
	import ProductDesc from "./widgets/template/product-desc";
	export default{
		name: 'PageBuilderProcessor',
		components: {ProductsListWidget, WebsiteMenus, PageBuilderProcessor, ProductImageGallery,ProductTitleBlock, ProductPriceBlock, ProductCartButton, ProductFeatures, ProductDesc,
			ImageWidget, TextWidget, ProductsListWidget, CategoryListWidget, SpacerDividerWidget, ListingWidget, MapWidget, SocialWidget, FormWidget, SearchWidget, SliderWidget, CardsListWidget},
		data(){
			return {
				storageurl: 'https://'+this.$attrs.company.nickname+'.s3.eu-west-2.amazonaws.com',
				template_areas: ['page-content', 'product-title-block', 'product-image-gallery', 'product-desc', 'product-short-desc', 'product-cart-button'],
				resource_result: '',
				selectedvariantstotal: 0.00,
				selectedvariants: {},
				selectedcombination: null,
				slug: '',
				page: '',
			}
		},
		props: {
			content: {
				type: Array
			},
			inner_content:{
				type: Object
			},
			resource: {
				type: String
			},
			isLive: {
				type: Boolean,
				default: true
			}
		},
		created(){
			if(this.resource){
				this.getResource(this.resource);
			}
		},
		watch: {
        $route() {
        	this.$nextTick(() => {
			        		if(this.resource){
							this.getResource(this.resource);
						}
						window.scrollTo(0,0);
        	});
        }
      },
		computed: {
			variantsTotal(){
        		var total = 0.00;
        		for(let key in this.selectedvariants){
        			console.log(key);
        			total =+ parseFloat(this.selectedvariants[key].price_difference);
        		}
        		return total;
        	},
        	priceIncVat(){
        		if(this.resource_result.tax_rule !== null){
        			return this.addTax(this.resource_result, this.resource_result.sales_price);
         		}else{
         			return this.resource_result.sales_price;
         		}
        	},
        	
        	combinationPrice(){
        		if(this.selectedcombination !== null){
        		return parseFloat(this.selectedcombination.price_difference);
        		}else{
        			return parseFloat(0.00);
        		}
        	}
		},
		methods: {
			isKeyValueActive(key, value){
				if(this.selectedvariants[key] == value){
					return true;
				}else{
					return false;
				}
			},
 			calculateVariantsTotal(){
        		var total = 0.00;
        		for(let key in this.selectedvariants){
        			console.log(key);
        			total += parseFloat(this.selectedvariants[key].price_difference);
        		}
        		this.selectedvariantstotal = this.addTax(total);
        	},
			getProducts(){
				this.createQueryString();
				this.loadingProducts = true;
				axios.get('/website-api/widget/'+resource+'/page='+this.current_page+this.query).then(response => {
					this.resource_result = response.data;
					let self = this;
				          setTimeout(function(){
				            self.loadingProducts = false;
				          }, 500);
				}).catch(error => {
					let self = this;
				          setTimeout(function(){
				            self.loadingProducts = false;
				          }, 500);
				});
			},
			getResource(resource){
				this.routearray = this.$route.path.substring(1).split('/');
				axios.get('/website-api/widget/'+resource+'/'+this.routearray[this.routearray.length-1]+'?page='+this.page).then(response => {
					this.resource_result = response.data;
					
				}).catch(error => {
					this.resource_result = [];
					console.log(error);
				});
			},
			createQueryString(){
				this.query = '&limit='+this.limit+'&search='+this.search;
			},
			templateArea(widget_name){
				var index = this.template_areas.findIndex(item => item == widget_name);
				if(index > -1){
					return true;
				}else{
					return false;
				}
			},
			filterStylesKey(styles, key){
	       		for(var i = 0; i < styles.length; i++){
	       			 var keys = Object.keys(styles[i])
	       			 if(keys.includes(key)){
	       			 	 return i;
	       			 }
	       		}
       		},
			compiledStyles(styles){
	        	var style = 'will-change: auto; ';
	        	for(var i = 0; i < (styles.length - 1); i++ ){
	        		for(var key in styles[i] ){
	        			
	        		if(styles[i][key].active == true){
		        		if(key.includes('_label') == false){

		        			if(key == 'background'){
		        				//var stylename = key.replace('_', '-');
		        				if(styles[i][key].value[0] == 'background-image'){
		        					style += ''+styles[i][key].value[0]+': url('+styles[i][key].value[1]+'); '
		        				}else{
		        					style += ''+styles[i][key].value[0]+': '+styles[i][key].value[1]+'; '
		        				}
		        				style += 'background-size: '+styles[i][key].size+'; '
		        				style += 'background-position: '+styles[i][key].position+'; '
		        				style += 'background-repeat: '+styles[i][key].repeat+'; '
		        			}
		        			else if(styles[i][key].type == 'numbergroup'){
		        				//var stylename = key.replace('_', '-');
		        				for(var numgroupkey in styles[i][key]){
		        					if((numgroupkey !== 'parentlabel') || (numgroupkey !== 'type')){
		        						if((key.includes('_radius') == false) && (key.includes('border') == true)){

		        							style += ''+key+'-'+numgroupkey.replace('_','-')+': '+styles[i][key][numgroupkey]+'px '+styles[this.filterStylesKey(styles, 'border_style')]["border_style"].value+' '+styles[this.filterStylesKey(styles, 'border_colour')]["border_colour"].value+' !important; '

		        						}else	if(key.includes('_radius') == false){

		        							style += ''+key+'-'+numgroupkey.replace('_','-')+': '+styles[i][key][numgroupkey]+'px !important; '
		        						
		        						}else{
		        						
		        							style += ''+key.replace('_radius', '')+'-'+numgroupkey.replace('_','-')+'-radius: '+styles[i][key][numgroupkey]+'px !important; '
		        						
		        						}
		        					}
		        				}
		        			}
		        			else if(styles[i][key].type == 'range'){
		        					if('units' in styles[i][key]){
		        						//console.log(styles[i][key].value);
		        						if(styles[i][key].value !== null){
			        						var stylename = key.replace('_', '-');
					        				style += ''+stylename+': '+parseFloat(styles[i][key].value)+''+styles[i][key].units+'; '; 
				        					}
		        					 }else{
		        					 		style += ''+stylename+': '+styles[i][key].value+'; '
		        					 }

		        			}else{
		        				var stylename = key.replace('_', '-');
		        				stylename = stylename.replace('colour', 'color');
		        				if(styles[i][key].value.length !== 0){
		        					style += ''+stylename+': '+styles[i][key].value+' !important; '
		        				}else{
		        					style += ''+stylename+': '+styles[i][key].value+' !important; '
		        				}
		        			}
		        		}
	        		}
	        	}
	        }
	        	return style;
	        },
		}
	}
</script>

<template>
	<div>
		<section v-for="(section, sindex) in content.sections" :style="compiledStyles(section.styles)" :class="section.styles[1].containerStyle.value" >
			<b-row v-for="(row, rindex) in section.rows" :style="compiledStyles(row.styles)">
				<b-col v-for="(col, cindex) in row.cols" :class="['col-xl-'+col.styles[filterStylesKey(col.styles, 'size')].size.xl+' col-lg-'+col.styles[filterStylesKey(col.styles, 'size')].size.lg+' col-md-'+col.styles[filterStylesKey(col.styles, 'size')].size.md+' col-sm-'+col.styles[filterStylesKey(col.styles, 'size')].size.sm+' col-xs-'+col.styles[filterStylesKey(col.styles, 'size')].size.xs]"
                       :style="[{'min-height: 100px' : col.styles.isDivider == false}, compiledStyles(col.styles)]" class="page-builder-element-outline col handle2">
					<div class="fe-widget" v-for="(block, bindex) in col.blocks">
						<div v-if="block.widgettype == 'text'">
                        	<TextWidget :id="'widget'+block.widgettype+'-'+sindex+''+rindex+''+cindex+''+bindex+''" :content="block.widgetcontent" :style="compiledStyles(block.widgetstyles)" />
                        </div>
                        <div v-if="block.widgettype == 'image'" :class="block.widgetcontent[filterStylesKey(block.widgetcontent, 'text_align')].text_align.value">
                          <ImageWidget :id="'widget'+block.widgettype+'-'+sindex+''+rindex+''+cindex+''+bindex+''" :company="$attrs.company" :user="$attrs.user" :content="block.widgetcontent"  :styles="block.widgetstyles" :style="compiledStyles(block.widgetstyles)" ></ImageWidget>
                        </div>
                        <div v-if="block.widgettype == 'address'">
                          [Store Address]
                        </div>
                        <div v-if="block.widgettype == 'customer-address'">
                          [Customer Address]
                        </div>
                        <div v-if="block.widgettype == 'spacer'" :style="{'height': block.widgetcontent[filterStylesKey(block.widgetcontent, 'spacer_height')].spacer_height.value+block.widgetcontent[filterStylesKey(block.widgetcontent, 'spacer_height')].spacer_height.units, 'width': block.widgetcontent[filterStylesKey(block.widgetcontent, 'spacer_width')].spacer_width.value+block.widgetcontent[filterStylesKey(block.widgetcontent, 'spacer_width')].spacer_width.units, 'max_height': block.widgetcontent[filterStylesKey(block.widgetcontent, 'spacer_max_height')].spacer_max_height.value+block.widgetcontent[filterStylesKey(block.widgetcontent, 'spacer_max_height')].spacer_max_height.units, 'max_width': block.widgetcontent[filterStylesKey(block.widgetcontent, 'spacer_max_width')].spacer_max_width.value+block.widgetcontent[filterStylesKey(block.widgetcontent, 'spacer_max_width')].spacer_max_width.units, 'background-color': block.widgetcontent[filterStylesKey(block.widgetcontent, 'spacer_background_colour')].spacer_background_colour.value+ '!important'}">
                        </div>
                        <hr v-if="block.widgettype == 'divider'" class="text-white" :style="{'border-style': block.widgetcontent[filterStylesKey(block.widgetcontent, 'bordertype')].bordertype.value, 'border-width': block.widgetcontent[filterStylesKey(block.widgetcontent, 'width')].width.value+block.widgetcontent[filterStylesKey(block.widgetcontent, 'width')].width.units, 'border-color': block.widgetcontent[filterStylesKey(block.widgetcontent, 'colour')].colour.value}">
                        <div v-if="block.widgettype == 'signature'">
                          <img :src="storageurl+block.widgetcontent.image">
                          <p class="mb-0">{{block.widgetcontent.firstname}} {{block.widgetcontent.lastname}}</p>
                          <strong>{{block.widgetcontent.companyname}}</strong>
                        </div>
                        <img v-if="block.widgettype == 'stamp'" :src="block.widgetcontent.url">
                        <div v-if="block.widgettype == 'note'" v-html="block.widgetcontent.content"></div>
                        		<div v-if="block.widgettype == 'slider'">
                        	 	<SliderWidget :id="'widget'+block.widgettype+'-'+sindex+''+rindex+''+cindex+''+bindex+''" :company="$attrs.company" :user="$attrs.user" :content="block.widgetcontent" :configs="block.widgetconfigs"  :styles="block.widgetstyles" :style="compiledStyles(block.widgetstyles)" ></SliderWidget>
                            </div>
                             <div v-if="block.widgettype == 'imagegallery'">
                             	<ImageGalleryWidget :id="'widget'+block.widgettype+'-'+sindex+''+rindex+''+cindex+''+bindex+''" :company="$attrs.company" :user="$attrs.user" :content="block.widgetcontent"  :styles="block.widgetstyles" :style="compiledStyles(block.widgetstyles)" ></ImageGalleryWidget>
                            </div>
                            <div v-if="block.widgettype == 'cards'">
                             	<CardsListWidget :id="'widget'+block.widgettype+'-'+sindex+''+rindex+''+cindex+''+bindex+''" :liveMode="isLive" :company="$attrs.company" :user="$attrs.user" :content="block.widgetcontent"  :styles="block.widgetstyles" :filters="block.widgetfilters.active_filters" :style="compiledStyles(block.widgetstyles)" ></CardsListWidget>
	                          </div>
                            <div v-if="block.widgettype == 'products'">
                            	<ProductsListWidget :id="'widget'+block.widgettype+'-'+sindex+''+rindex+''+cindex+''+bindex+''" :company="$attrs.company" :user="$attrs.user" :liveMode="isLive" :content="block.widgetcontent" :configs="block.widgetconfigs" :filters="block.widgetfilters.active_filters" :elements="block.widgetelements.active_elements" :style="compiledStyles(block.widgetstyles)" ></ProductsListWidget>
                            </div>
                            <div v-if="block.widgettype == 'categories'">
                            	<CategoryListWidget :resource="block.widgettype" :id="'widget'+block.widgettype+'-'+sindex+''+rindex+''+cindex+''+bindex+''" :company="$attrs.company" :user="$attrs.user" :liveMode="isLive" :content="block.widgetcontent" :configs="block.widgetconfigs" :filters="block.widgetfilters.active_filters" :elements="block.widgetelements.active_elements" :style="compiledStyles(block.widgetstyles)" ></CategoryListWidget>
                            </div>
                            <div v-if="block.widgettype == 'collections'">
                            	<CategoryListWidget :resource="block.widgettype" :id="'widget'+block.widgettype+'-'+sindex+''+rindex+''+cindex+''+bindex+''" :company="$attrs.company" :user="$attrs.user" :liveMode="isLive" :content="block.widgetcontent" :configs="block.widgetconfigs" :filters="block.widgetfilters.active_filters" :elements="block.widgetelements.active_elements" :style="compiledStyles(block.widgetstyles)" ></CategoryListWidget>
                            </div>
                            <div v-if="(block.widgettype == 'listings') || (block.widgettype == 'listingsmap')">
                            	<ListingWidget :resource="block.widgettype" :id="'widget'+block.widgettype+'-'+sindex+''+rindex+''+cindex+''+bindex+''" :company="$attrs.company" :user="$attrs.user" :liveMode="isLive" :content="block.widgetcontent" :configs="block.widgetconfigs" :filters="block.widgetfilters.active_filters" :elements="block.widgetelements.active_elements" :style="compiledStyles(block.widgetstyles)"></ListingWidget>
                            </div>
                            <div v-if="block.widgettype == 'map'">
                            	<MapWidget :resource="block.widgettype" :id="'widget'+block.widgettype+'-'+sindex+''+rindex+''+cindex+''+bindex+''" :company="$attrs.company" :user="$attrs.user" :liveMode="isLive" :content="block.widgetcontent" :configs="block.widgetconfigs" :elements="block.widgetelements.active_elements" :style="compiledStyles(block.widgetstyles)"></MapWidget>
                            </div>
                            <div v-if="block.widgettype == 'social'">
                            	<SocialWidget :action="block.widgetaction" :id="'widget'+block.widgettype+'-'+sindex+''+rindex+''+cindex+''+bindex+''" :company="$attrs.company" :user="$attrs.user" :liveMode="isLive" :content="block.widgetcontent" :configs="block.widgetconfigs" :style="compiledStyles(block.widgetstyles)"></SocialWidget>
                            </div>
                            <div v-if="block.widgettype == 'search'">
                            	<SearchWidget :action="block.widgetaction" :id="'widget'+block.widgettype+'-'+sindex+''+rindex+''+cindex+''+bindex+''" :company="$attrs.company" :user="$attrs.user" :liveMode="isLive" :content="block.widgetcontent" :configs="block.widgetconfigs" :filters="block.widgetfilters.active_filters" :elements="block.widgetelements.active_elements" :style="compiledStyles(block.widgetstyles)"></SearchWidget>
                            </div>
                            <div v-if="block.widgettype == 'form'">
                            	 <FormWidget :action="block.widgetaction" :id="'widget'+block.widgettype+'-'+sindex+''+rindex+''+cindex+''+bindex+''" :company="$attrs.company" :user="$attrs.user" :liveMode="isLive" :content="block.widgetcontent" :configs="block.widgetconfigs" :style="compiledStyles(block.widgetstyles)" ></FormWidget>
                            </div>
                            <div v-if="block.widgettype == 'menu'" v-on:dblclick="editWidget(rindex, cindex, bindex)">
                            		<WebsiteMenus :id="'widget'+block.widgettype+'-'+sindex+''+rindex+''+cindex+''+bindex+''" :company="$attrs.company" :user="$attrs.user" :nickname="$attrs.company.nickname" :logo="storageurl+$attrs.company.logo" :content="block.widgetcontent"  :styles="block.widgetstyles" :filters="block.widgetfilters.active_filters" :elements="block.widgetelements.active_elements" :style="compiledStyles(block.widgetstyles)" :domain="$attrs.domain" :liveMode="isLive" ></WebsiteMenus>
                            </div>
						<PageBuilderProcessor  id="rendered-page-content" v-if="block.widgettype == 'page-content'" :content="inner_content" :company="$attrs.domain.company" :user="$attrs.user" :domain="$attrs.domain"></PageBuilderProcessor>
							<ProductImageGallery v-if="block.widgettype == 'product-image-gallery'" :product="resource_result" :company="$attrs.company"></ProductImageGallery>
							<ProductTitleBlock v-if="block.widgettype == 'product-title-block'" :product="resource_result"></ProductTitleBlock>
							<ProductDesc v-if="block.widgettype == ('product-short-desc' || 'product-desc')" :description="block.widgettype" :product="resource_result"></ProductDesc>
							<ProductCartButton  v-if="block.widgettype == 'product-cart-button'" :product="resource_result" :selectedvariants="selectedvariants" :selectedcombination="selectedcombination" :company="$attrs.company"></ProductCartButton>
							<ProductFeatures  v-if="block.widgettype == 'product-features'" :product="resource_result"></ProductFeatures>
							<DefaultContentRelatedProductsSwiper :swiperOptions="swiperOptions" :items="resource_result" :startSlide="0">
							</DefaultContentRelatedProductsSwiper>
							<DefaultContentRecentlyViewed v-if="recentlyViewed && recentlyViewed.length > 0"  :swiperOptions="swiperOptions" :items="recentlyViewed" :startSlide="0">
										</DefaultContentRecentlyViewed>
						
					</div>
				</b-col>
			</b-row>
			
		</section>
	</div>

</template>