<script type="text/javascript">
	import Layout from "../layouts/public";
	export default{
		components: { Layout },
		data(){
			return{

			}
		}
	}

</script>

<template>
	<div>
		About
	</div>

</template>