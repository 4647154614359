<script>
import { EventBus } from '../../app'
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
import TradeFooter from '../../components/trade-footer';
import ImageCarousel from "../../components/widgets/carousel/ImageCarousel";
import MiniCardCarousel from "../../components/widgets/carousel/MiniCardCarousel";
import ImgSwiper from "../../components/widgets/carousel/Swiper";
import TradeProductCard from '../../components/widgets/ecommerce/trade/TradeProductCard';
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper';
import SwiperClass, { Pagination } from 'swiper'
  import 'swiper/swiper.min.css'
/**
 * Dashboard Component
 */
export default {
  name: "Homepage",
  components: { Layout, PageHeader, ImgSwiper, ImageCarousel, MiniCardCarousel, TradeProductCard, Swiper, TradeFooter,
        SwiperSlide},
  directives: {
        swiper: directive
   },
  data() {
    return {
      selected_county: '',
        selected_county_id: '',
        selected_country: '',
        selected_country_id: '',
business_type: '',
          showFilters: false,
              sliderPrice: 800,
              priceRange: [0,250000],
              brands: [],
              collections: [],
              categories: [],
              sortby: 'created_at',
              order: 'desc',
              search: '',
              view: 'grid',
              public_active: 1,
              trade_active: '',
              active: '',
              expanded: 1,
              currentPage: 1,
              query: '',
              limit: 5,
          limits: [
                  {value: 25, text: "25"},
                  {value: 50, text: "50"},
                  {value: 100, text: "100"},
                ],
      title: "Trade Maketplace",
      showModal: false,
      products: [],
      sellers: [],
      categories: [],
      swiperOptions: {
          slidesPerView: 4,
          spaceBetween: 20,
          loop: false,
          loopFillGroupWithBlank: false,
          pagination: {
            el: '.swiper-pagination',
            clickable: true,
          },
          // Some Swiper option/callback...
        },
        recentlyAddedOptions: {
          slidesPerView: 3,
          spaceBetween: 5,
          loop: false,
          loopFillGroupWithBlank: false,
          pagination: {
            el: '.swiper-pagination',
            clickable: true,
          },
          // Some Swiper option/callback...
        },
      items: [
              {
                text: 'Trade',
                href: '/',
              },
              {
                text: 'Marketplace',
                active: true,
              },
            ],
    };
  },
  created(){
    this.getProducts();
    this.getSellers();
    this.getCategories();
  },
  methods: {
    getProducts(){
      axios.get('/api/company/trade/product/query-list?type=home', {headers: {
        'Authorization' : 'Bearer '+this.$attrs.user.api_token 
    }}).then(response => {
        this.products = response.data;
      }).catch(error => {

      });
    },
    getSellers(){
      	this.createQueryString();
						//console.log(this.$route.path);
		axios.get('/api/company/trade/directory'+this.query, {headers: {
				'Authorization' : 'Bearer '+this.$attrs.user.api_token 
		}}).then(response => {
					this.sellers = response.data;
		}).catch(error => {
					console.log(error);
		});
    },
    getCategories(){
      axios.get('/api/company/product/categories?page=1&limit=5&sortby=id&order=desc&search=&parent_id=&marketplace_categories=trade', {headers: {
                  'Authorization': 'Bearer ' + this.$attrs.user.api_token
              }}).then(response => {
                     console.log(response);
                    this.categories = response.data;
                });
                //this.clearCategories();
    },
    loadSellers(){
			
			},
	createQueryString(){
				this.query = '?page='+this.currentPage+'&limit='+this.limit+'&sortby='+this.sortby+'&order='+this.order+'&business_type='+this.business_type+'&country_id='+this.selected_country_id+'&county_id='+this.selected_county_id+'&alphabet='+this.selectedalpha+'&search='+this.search+'&brands='+this.brands+'&collections='+this.collections+'&categories='+this.categories+'&pricerange='+this.priceRange+'';
			},
    filterRecentProducts(products, start, end){
      return products.slice(start, end);
    }
  },
   mounted() {
    setTimeout(() => {
      this.showModal = true;
    }, 1500);
  },
};
</script>

<template>
  <Layout :nickname="$attrs.nickname" :user="$attrs.user" :company="$attrs.company" :selected_currency="$attrs.selected_currency" :selected_currency_symbol="$attrs.selected_currency_symbol" :exchange_rates="$attrs.exchange_rates">
    <PageHeader :title="title" :items="items" />	
<div>
  <section class="header-section">
  <div class="container-fullwidth">
    <!--<PageHeader :title="title" :items="items" />-->
    <b-row style="min-height: 350px; background: linear-gradient(45deg, rgba(42, 48, 125,0.15), rgba(239, 242, 247, 0.25))" class="bg-light bg-soft border-radius-1em">
      <b-col md="12" class="d-flex flex-column justify-content-end align-items-center mx-auto" style="backdrop-filter: saturate(0.7)">

        <h1 class="section-info-header section-info-header position-relative">Buy & Sell with Trade Partners</h1>
        <p class="text-dark font-size-16">
            Connect with trade partners and suppliers to select new product lines, jewellery collections and unique pieces.
          </p>
      </b-col>
      <b-col md="6" class="d-flex flex-column justify-content-start align-items-center mx-auto">
        <div class="mt-sm-0 form-inline w-100"><div class="search-box mr-2 ml-auto d-flex"><div class="position-relative w-100"><input type="text" placeholder="Search name, reference or description..." class="form-control w-100 border"> <i class="bx bx-search-alt search-icon"></i></div> <!----></div></div>
      </b-col>
    </b-row>
     <b-row class="categories-top-row">
      <b-col md="8" class="mx-auto">
        <ImageCarousel :items="categories" :grid_limit="5" :end="5" :autoplay="false" :resource="'category'" :liveMode="false"></ImageCarousel>

        
      </b-col>
    </b-row>
    <!-- end row -->
  </div>
</section>
 <section class="pt-0">
  <b-container class="py-4" fluid>
   <b-row class="py-4 my-4">
      <b-col md="12">
        <b-card class="bg-transparent shadow-none h-100" body-class="d-flex flex-column justify-content-center align-items-start">
        <p class="mb-1 text-muted">Editors Picks</p>
        <h2 class="section-info-header section-info-header position-relative">Our Featured Pieces</h2>
        
      </b-card>
      </b-col>
     <b-col md="12" class="mx-auto">
        <!--<MiniCardCarousel :use="'trade'" :items="products" :grid_limit="4" :end="4" :layout_type="'vertical'" :autoplay="false" :customer="$attrs.user"></MiniCardCarousel>-->
        <swiper ref="productGalleryCarousel" :options="swiperOptions" class="overflow-hidden w-100" >
                  <swiper-slide v-for="(product, index) in filterRecentProducts(products['recent_products'], 0, 7)" >

                <TradeProductCard ref="latestProductsCarousel" :product="product" :index="index"></TradeProductCard>
                </swiper-slide>
              </swiper>
      </b-col>
      <b-col md="12">
        <a class="border-bottom border-primary ml-auto text-dark my-auto py-2 d-flex float-end" href="/category/jewellery">
          <h4>All Our Jewellery</h4> <i class="bx bx-chevron-right text-primary font-size-20 ml-2"></i>
        </a>
      </b-col> 
    </b-row>
  </b-container>
</section>
 <section>
  <b-container fluid>
    <b-row>
       <b-col md="4" class="pb-3">
        <b-card class="bg-transparent shadow-none border border-radius-1em h-100" body-class="d-flex flex-column justify-content-center align-items-start">
        <p class="mb-1 text-muted">New In Stock</p>
        <h2 class="section-info-header section-info-header position-relative">Recently Added</h2>
        
      </b-card>
      </b-col>
      <b-col md="8" class="mx-auto overflow-hidden">
        <ImgSwiper :use="'trade'" :nickname="true" :items="filterRecentProducts(products['recent_products'], 0, 7)" :swiperOptions="recentlyAddedOptions" :startSlide="0" :customer="$attrs.user"></ImgSwiper>
        <!--<MiniCardCarousel :items="items2" :grid_limit="3" :end="3" :layout_type="'vertical'" :autoplay="false" :truncate_titles="true"></MiniCardCarousel>-->
      </b-col>
      <b-col md="8" class="mx-auto overflow-hidden">
        <ImgSwiper :use="'trade'" :nickname="true" :items="filterRecentProducts(products['recent_products'], 7, 16)" :swiperOptions="recentlyAddedOptions" :startSlide="1"></ImgSwiper>
        <!--<MiniCardCarousel :items="items2" :grid_limit="3" :end="3" :layout_type="'vertical'" :autoplay="false" :truncate_titles="true"></MiniCardCarousel>-->
      </b-col>
      <b-col md="4" class="pb-3">
        <b-card class="bg-transparent shadow-none border border-radius-1em h-100" body-class="d-flex flex-column justify-content-center align-items-center px-4">

        <p class="px-3 lh-2">
          <i class="bx bxs-quote-left text-primary font-size-20"></i>
          Excepteur sint occaecat cupidatat non proident sunt in 
            culpa qui officia deserunt mollit lorem ipsum anim id est 
            laborum perspiciatis unde.
            <i class="bx bxs-quote-right text-primary font-size-20"></i>
        </p>
        
      </b-card>
      </b-col>
    </b-row>
  </b-container>
</section>
 <!--<section>
  <b-container class="container-fullwidth padded-section bg-light bg-soft">
    <b-row>
       <b-col md="12" class="pb-3 mx-auto">
        <b-card class="bg-transparent shadow-none h-100" body-class="d-flex flex-column justify-content-center align-items-start bg-transparent">
        <p class="mb-1 text-muted">Editors Picks</p>
        <h2  class="section-info-header section-info-header position-relative">Our Featured Collections</h2>
        
      </b-card>
      </b-col>
      <b-col md="10" class="mx-auto">
        <ImageCarousel :items="categories" :grid_limit="5" :end="5" :autoplay="false" :resource="'collection'" :liveMode="true"></ImageCarousel>
      </b-col>
    </b-row>
  </b-container>
</section>-->
 <section>
  <b-container fluid>
    <b-row>
       <b-col md="12" class="pb-3">
  
        <b-card class="bg-transparent shadow-none h-100" body-class="d-flex flex-column justify-content-center align-items-start">
        <p class="mb-1 text-muted">Editors Picks</p>
        <h2  class="section-info-header section-info-header position-relative">Our Antique Gallery</h2>
    
      </b-card>

      </b-col>
      <b-col md="12" class="mx-auto">
        <ImgSwiper ref="gallerySwiper" :use="'trade'" :nickname="true"  :customer="$attrs.user" :items="filterRecentProducts(products['recent_products'], 0, 7)" :swiperOptions="recentlyAddedOptions" :startSlide="2"></ImgSwiper>
      </b-col>
       
       <b-col md="12">
        <a class="border-bottom border-primary ml-auto text-dark my-auto py-2 d-flex float-end" href="/category/antiques">
          <h4>View Antiques</h4> <i class="bx bx-chevron-right text-primary font-size-20 ml-2"></i>
        </a>
      </b-col> 
    </b-row>
  </b-container>
</section>
 <section class="pb-4">
  <b-container fluid class="pb-4">
    <b-row class="pb-4">
       <b-col md="12" class="pb-3">
  
        <b-card class="bg-transparent shadow-none mb-0" body-class="d-flex flex-column justify-content-center align-items-center">
        <p class="mb-1 text-muted">Most Popular</p>
        <h2 class="section-info-header section-info-header position-relative">Featured Brands & Sellers</h2>
        
      </b-card>

      </b-col>
      <b-col md="10">
           <ImageCarousel :items="sellers" :resource="'seller'" :liveMode="true"  :grid_limit="4" :end="4" :autoplay="false"></ImageCarousel>
      </b-col>
       <b-col md="12">
        <a class="border-bottom border-primary ml-auto text-dark my-auto py-2 d-flex float-end" href="/sellers">
          <h4>View Our Trade Directory</h4> <i class="bx bx-chevron-right text-primary font-size-20 ml-2"></i>
        </a>
      </b-col>  
    </b-row>
  </b-container>
</section>
<TradeFooter ></TradeFooter>
 <!--<section class="pt-0">
  <b-container class="py-4" fluid>
   <b-row class="py-4 mt-4">
      <b-col md="12">
        <b-card class="bg-transparent shadow-none h-100" body-class="d-flex flex-column justify-content-center align-items-start">
        <p>From the Blog</p>
        <h2>Fresh Off The Press</h2>
        <i class="bx bx-right-arrow-circle text-primary font-size-20"></i>
      </b-card>
      </b-col>
     <b-col md="12" class="mx-auto">
        <MiniCardCarousel :use="'trade'" :customer="$attrs.user" :items="products" :grid_limit="3" :end="3" :layout_type="'vertical'" :autoplay="false"></MiniCardCarousel>
      </b-col>
    </b-row>
  </b-container>
</section>-->

</div>
</Layout>
</template>

<style scoped>

  section{
    padding: 50px 0;
  }
  section.header-section{
    padding: 0 !important;
  }
  .breadcrumb-section{
    margin-bottom: 0 !important;
    padding: 30px 0;
  }
  .categories-top-row{
    margin-top: -6.5%;
  }

</style>