<template>
	<div class="p-3 mb-3">
	<!--<a href="javascript: void(0);" @click="clearAll">Clear</a>-->
	<b-overlay
		:show="loadingForm"
		variant="white"
                        opacity="0.85"
                        blur="1px"
                        rounded="md"
                        class="w-100 d-flex flex-column justify-content-center"
                        style="min-height: 50px"
	>
	<b-form-group label="" v-if="'data' in brands && brands.data.length > 0">
                  <b-form-checkbox
                    v-for="brand in brands.data"
                    class="brand-checkbox mb-1"
                    :id="'brand-'+brand.id"
                    :name="'brand-'+brand.id"
                    :key="brand.id"
                    :value="brand.id"
                    stacked
                    @change="updateBrands(brand.id)"
                  >
                    <img v-if="brand.logo" :src="(marketplace_brands == 'false' ? storageurl +'/' : '') + brand.logo" class="avatar-xs rounded-circle opacity-75 mr-2">
                    <img v-else src="/images/product-placeholder.png" class="avatar-xs rounded-circle opacity-75 mr-2">
                    {{ brand.name }}
                  </b-form-checkbox>
                  <a class="btn btn-outline-light" href="javascript: void(0);" @click="limit = parseFloat(limit) + 10, searchResource()" v-if="brands.total >= limit">Load More</a>
       			  <a class="btn btn-outline-light" href="javascript: void(0);" @click="limit = parseFloat(limit) - 10, searchResource()" v-if="limit > 10">Load Less</a>
       </b-form-group>
       <NothingHere v-else :addNew="false"></NothingHere>
       <div v-else class="d-flex flex-column justify-content-center" style="min-height: 200px">
       	<h5 class="mx-auto">No Brands to Show</h5>
       </div>
   	</b-overlay>
   </div>
</template>
<script>
	export default{
		name: 'FilterProductBrand',
		props: {
			marketplace_brands: {
				type: String,
				default: 'true'
			}
		},
		data(){
			return{
				brands: '',
				brands_error: '',
				selectedbrands: [],
				limit: 10,
				currentPage: 1,
				loadingForm: false,
				storageurl: 'https://'+this.$attrs.company.nickname+'.s3.eu-west-2.amazonaws.com',
			}
		},
		methods:{
			clearAll(){
			

			},
			updateBrands(value){
				if(this.selectedbrands.length > 0){
			      var filterList = this.selectedbrands.filter(item => item == value);
			      console.log(filterList);
			        if(filterList.length == 0){
			          this.selectedbrands.push(value);
			          this.$emit('brands-filtered', this.selectedbrands);
			        }else{
			          var filterIndx =this.selectedbrands.findIndex(item => item == value);
			          console.log(filterIndx);
			          this.selectedbrands.splice(filterIndx, 1);
			          this.$emit('brands-filtered', this.selectedbrands);
			        }
			      }else{
			        //this.selectedbrands = [];
			        this.selectedbrands.push(value);
			        this.$emit('brands-filtered', this.selectedbrands);
			      }
				
			},
			searchResource(){
				this.loadingForm = true;
				axios.get('/api/company/product/brands?page='+this.currentPage+'&limit='+this.limit+'&sortby=id&order=desc&marketplace_brands='+this.marketplace_brands, {headers: {
                  'Authorization': 'Bearer ' + this.$attrs.api_token
              }}).then(response => {
					this.brands = response.data;
					this.totalpages = response.total;
					var self = this;
	                  setTimeout(function(){
	                  self.loadingForm = !self.loadingForm;
	                  }, 150);
				}).catch(error => {
					this.brands_error = error.data;
					var self = this;
	                  setTimeout(function(){
	                  self.loadingForm = !self.loadingForm;
	                  }, 150);
				});
			}

		},
		created(){
			this.searchResource();
		},
	}
</script>