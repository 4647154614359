<script type="text/javascript">
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
import Stat from "../../components/widgets/stat";
import SubscriptionInvoice from "../../components/widgets/SubscriptionInvoice";
import UpdateSubscription from '../../components/widgets/Updatesubscription';

export default{
	 components: { Layout, PageHeader, Stat, UpdateSubscription, SubscriptionInvoice },
	 data() {
   		return {
   			selected_subscription: '',
   			title: "Settings",
   			settings: "",
   			isCheckAll: false,
   			update_subscription: false,
   			billingportal_url: '',
   			items: [
		        {
		          text: "Company",
		          href: "/"
		        },
		        {
		          text: "Subscriptions",
		          active: true
		        }
		      ]
   		}
   	},
   	methods:{
   		filterSelected(id){
        if(this.selectedids.length > 0){
          var exists = this.selectedids.filter(item => item == id);
          if(exists.length > 0){
            return true;
          }else{
            return false
          }
        }else{
          return false;
        }
      },
      checkAll: function(){ 
                 this.isCheckAll = !this.isCheckAll; 
                 this.selectedids = []; 
                 if(this.isCheckAll){ 
                     // Check all 
                     for (var key in this.users.data) {          
                        this.selectedids.push(this.users.data[key].id); 
                     } 
                 } 
              }, 
      updateCheckall: function(){ 
                   if(this.selectedids.length == this.users.data.length){
                      this.isCheckAll = true; 
                   }else{ 
                      this.isCheckAll = false; 
                   } 
      },
      cancelSubscription(){

      }
   	},
   	created(){
              axios.get('/api/company/subscription/'+this.$attrs.company.uid+'/billing-portal', {headers: {
                  'Authorization': 'Bearer ' + this.$attrs.user.api_token
              }}).then(response => {
                this.billingportal_url = response.data;
              }).catch(error => {
                this.error = error.data;
              });
   	}
}
</script>
<template>
	<Layout :nickname="$attrs.nickname" :user="$attrs.user"  >
		<PageHeader :title="title" :items="items"  />
			<div class="row">
				<div class="col-xl-3 col-lg-4">
		      	<div class="card border-radius-1em">
		          <div class="card-body">
		            <div class="media d-flex my-1">
		              <div class="avatar-sm mr-4">
		                        <span class="avatar-title rounded-circle" :class="{'bg-danger': $attrs.validsubscription == 0, 'bg-success': $attrs.validsubscription == 1}"><i class="font-size-24 bx" :class="{'bx-error-circle': $attrs.validsubscription == 0, 'bx-check-circle': $attrs.validsubscription == 1}"></i></span>
		                      </div>

		              <div class="media-body overflow-hidden d-flex flex-column my-auto">
		                <span>Subscription Status: </span><h5 class="text-truncate font-size-15 mb-0 mt-auto text-danger" v-if="$attrs.validsubscription == 0">Inactive</h5>
		                <h5 class="text-truncate font-size-15 mb-0 mt-auto text-success" v-else>Active</h5>
		              </div>
		            </div>
		         </div>
		        </div>
		        <div class="card border-radius-1em">
		          <div class="card-body pb-0">
		          	<div class="media d-flex mb-2">
		          	    <div class="avatar-sm mr-4">
		                        <span class="avatar-title rounded-circle"><i class="font-size-24 bx bx-credit-card-alt"></i></span>
		                      </div>
		                  <div class="media-body overflow-hidden d-flex flex-column my-auto">
		             	 <h4 class="card-title my-auto">Payment Details</h4>
		             	</div>
		             </div>
		        	</div>
		        	<div v-if="$attrs.validsubscription == 1">
			           	<ul class="list-group list-group-flush">
			           		<li class="list-group-item">Customer ID: <span class="text-capitalize float-right">{{$attrs.company.stripe_id}}</span></li>
			           		<li class="list-group-item">Card Type: <span class="text-capitalize float-right">{{$attrs.company.card_brand}}</span></li>
			           		<li class="list-group-item">Card Number: <span class="text-capitalize float-right">XXXX XXXX XXXX {{$attrs.company.card_last_four}}</span></li>
			           	</ul>
		           	</div>
		           	<div v-else>
			           	<div class="card-body mt-0">
			           		<ul class="list-group list-group-flush">
				           		<li class="list-group-item bg-danger text-white">No Subscription Added</li>
				           	</ul>
				        </div>
		           	</div>
		            <!--<SearchCompanyUser :api_token="this.$attrs.user.api_token" :id="department.manager.user" @user-selected="getManager" @input="getManager" />-->
					<a class="btn btn-light m-4">Update Payment Method</a>
		          </div>
		          <div class="card border-radius-1em">
		          	<div class="card-body">
		          	<div class="media d-flex mb-2">
		          	    <div class="avatar-sm mr-4">
		                        <span class="avatar-title bg-dark rounded-circle"><i class="font-size-24 bx bx-server"></i></span>
		                      </div>
		                  <div class="media-body overflow-hidden d-flex flex-column my-auto">
		             	 <h4 class="card-title my-auto">Storage</h4>
		             	</div>
		             </div>
		        	<div class="row p-4">
		        		<div class="col-6"><h5 class="font-size-15"> GB</h5><p class="text-muted mb-0">Used</p></div><div class="col-6"><h5 class="font-size-15">20 GB</h5><p class="text-muted mb-0">Total</p></div>
		        		<div class="col-md-12">
		        			<b-progress :value="25" :max="100" class="mt-4" variant="success"></b-progress>
		        		</div>

		        	</div>
		        	<a class="btn btn-light btn-block">Add Storage</a>
		        </div>
		    </div>
        </div>
			<div class="col-xl-9 col-lg-8">
				<div class="row">
		        	<div class="col-lg-4">
			          <Stat :icon="'bx bx-group'" :title="'Users'" :value="$attrs.subscriptiondetails.quantity" />
			        </div>
			        <div class="col-lg-4">
		        		<!--<Stat :icon="'bx bx-group'" :title="'Period'" :value="0" />-->
										<div class="card mini-stats-wid border-radius-1em">
				    <div class="card-body">
				      <div class="media">
				        <div class="media-body">
				          <p class="text-muted font-weight-medium mb-3" v-html="'Plan'"></p>
				          <h5 v-if="'items' in $attrs.subscriptiondetails && $attrs.subscriptiondetails.items.length" class="mb-0 d-inline" v-html="$attrs.subscriptiondetails.items[0].plan.name"></h5>
				          <span v-if="'items' in $attrs.subscriptiondetails && $attrs.subscriptiondetails.items.length" class="badge badge-light bg-light"> {{$attrs.subscriptiondetails.items[0].plan.payment_term}} </span>
				        </div>

				        <div class="mini-stat-icon avatar-sm align-self-center rounded-circle bg-primary">
				          <span class="avatar-title" >
				            <i :class="`bx bx-receipt font-size-24`"></i>
				          </span>
				        </div>
				     
				      </div>
				    </div>
    <!-- end card-body -->
 					 </div>
			        </div>
			        <div class="col-lg-4">
		        		<Stat :icon="'bx bx-calendar-alt'" :title="'Valid Until'" :value="($attrs.subscriptiondetails.ends_at == null ? 'Ongoing' : $attrs.subscriptiondetails.ends_at)" />
			        </div>
		        </div>
		        
				<div class="card border-radius-1em" v-if="update_subscription == false">
					<div class="card-body pb-0">
		          	<div class="media d-flex mb-2">
		          	    <div class="avatar-sm mr-4">
		                        <span class="avatar-title rounded-circle bg-secondary"><i class="font-size-24 bx bx-history"></i></span>
		                      </div>
		                  <div class="media-body overflow-hidden d-flex flex-row my-auto">
		             	 <h4 class="card-title my-auto">Current Subscriptions</h4>
		             	 <a class="ml-auto btn btn-light" @click="update_subscription = !update_subscription">Add/Remove Users</a>
		             	 <a class="ml-2 btn btn-light" :href="billingportal_url" target="_blank">Visit Billing Portal <i class="bx bx-link-external"></i></a>
		             	</div>
		             </div>
		        	</div>
					<div class="row p-4">
						<div class="col-md-12 p-4">
							
				            <div class="table-responsive">
				              <table class="table mb-0">
				                <thead>
				                  <tr>
				                    <th scope="col">#Subscription Details</th>
				                    <th>Unit Price</th>
				                    <th>Quantity</th>
				                    <th>Total</th>
				                    <th>Actions</th>
				                  </tr>
				                </thead>
				                <tbody v-for="subscription in $attrs.company.subscriptions">
				                  <tr v-for="sub_item in subscription.items" valign="middle">
				                    <td scope="row">
				                    	<div class="d-flex flex-column">
						                    <strong>{{subscription.stripe_id}}</strong>
						                    <span class="mt-2">Plan Type: {{sub_item.plan.name}}</span>
						                    <span>Term: <span class="badge badge-primary bg-primary mr-auto text-capitalize">{{sub_item.plan.payment_term}}</span></span>
				                  		</div>
				                  </td>
				                    <td>£{{sub_item.plan.price_exc}} <small class="text-muted"><i>Price Exc.</i></small><hr class="my-2">£{{sub_item.plan.price_inc}} <small class="text-muted"><i>Price Inc.</i></small></td>
				                    <td><span class="m-auto">{{subscription.quantity}}</span></td>
				                    <td class="text-center">£{{(sub_item.plan.price_exc * subscription.quantity).toFixed(2)}}<small class="text-muted"><i>Price Exc.</i></small><hr class="my-2">£{{(sub_item.plan.price_inc * subscription.quantity).toFixed(2)}} <small class="text-muted"><i>Price Inc.</i></small></td>
				                    <td>
				                    	 <ul class="list-inline font-size-20 contact-links mb-0">
					                        <li class="list-inline-item pr-1">
					                          <a v-b-tooltip.hover title="View" :href="'javascript:;'">
					                            <i class="mdi mdi-eye-outline"></i>
					                          </a>
					                        </li>
					                        <!--<li class="list-inline-item pr-1">
					                          <a v-b-tooltip.hover title="Reports">
					                            <i class="bx bx-line-chart"></i>
					                          </a>
					                        </li>-->

					                        <li class="list-inline-item pr-1" >
					                          <a v-b-tooltip.hover title="Preview Invoice" @click="selected_subscription = subscription, $bvModal.show('invoice-modal')">
					                            <i class="bx bx-download"></i>
					                          </a>
					                        </li>
					                        <li class="list-inline-item pr-1" >
					                          <a v-b-tooltip.hover title="Cancel Subscription" @click="">
					                            <i class="bx bx-trash"></i>
					                          </a>
					                        </li>
					                      </ul>

				                    </td>
				                  </tr>
				                </tbody>
				              </table>
				            </div>
						</div>

					</div>
				</div>
				<div class="card" v-if="update_subscription == true">
					<div class="card-body pb-0">
		          	<div class="media d-flex mb-2">
		          	    <div class="avatar-sm mr-4">
		                        <span class="avatar-title rounded-circle bg-secondary"><i class="font-size-24 bx bx-user"></i></span>
		                      </div>
		                  <div class="media-body overflow-hidden d-flex flex-row my-auto">
		             	 <h4 class="card-title my-auto">Add/Remove Users</h4>
		             	 <a class="ml-auto btn btn-light" @click="update_subscription = !update_subscription">Subscription History <i class="bx bx-history"></i></a>
		             	</div>
		             </div>
		        	</div>
				<UpdateSubscription :company="this.$attrs.company" :user="this.$attrs.user" :payment_term="this.$attrs.subscriptiondetails.items[0].plan.payment_term" :user_quantity="this.$attrs.subscriptiondetails.quantity" />
				</div>
			</div>
		</div>

		<b-modal id="invoice-modal" size="xl" centered content-class="border-radius-1em" header-class="border-none" hide-footer>
			<SubscriptionInvoice :printable="false" :user="$attrs.user" :company="$attrs.company" :subscription="selected_subscription"></SubscriptionInvoice>
		</b-modal>
	</Layout>
</template>