<template>
  <b-row id="menu-builder">
   <!-- <b-col md="12">
      <a class="btn btn-primary ml-auto mb-3 btn-rounded float-right" @click="addPanel = true"> Add Menu Item </a>
    </b-col> -->
    <b-col md="12" :class="isChild == true ? 'pr-0 ml-2' : ''">
  <draggable class="dragArea" tag="b-list-group" :list="menuitems" :group="{ name: 'g1' }" handle=".mhandle">
    <b-list-group-item v-for="(el, index) in menuitems" :key="el.name" >
      <span class="marker"></span>
      <b-row class="my-3">
        <b-col class="d-flex" md="3">
          <h5 class="my-auto">{{ el.label }}</h5>
          
        </b-col>
        <b-col md="4" class="d-flex">

          <a class="my-auto badge badge-light bg-light text-primary d-flex" target="_blank" v-if="$attrs.domain.verified == 1" :href="'http://'+($attrs.domain.domain+'/'+(el.resource !== ('page' || 'custom' ) ? el.resource+'/' : '')+el.link).replaceAll('//', '/')"><strong class="my-auto mr-1 text-capitalize">{{ el.resource }}</strong>
          <p class="my-auto">{{ el.name }}</p><i class="bx bx-link-external"></i></a>
        </b-col>
         <!--<b-col md="3" class="d-flex" v-if="(el.resource == 'page') || (el.resource == 'custom')">
          <a class="my-auto ml-auto badge badge-light bg-light text-primary" target="_blank" v-if="$attrs.domain.verified == 1" :href="'http://'+($attrs.domain.domain+'/'+el.link).replaceAll('//', '/')">{{ ($attrs.domain.domain+'/'+ el.link).replaceAll('//', '/') }} <i class="bx bx-link-external"></i></a>
          <a class="my-auto ml-auto badge badge-light bg-light text-primary" target="_blank" v-else :href="'http://website.'+($attrs.domain.domain_key+'.gemesys.co.uk/'+ el.link).replaceAll('//', '/')">website.{{ ($attrs.domain.domain_key+'.gemesys.co.uk/'+ el.link).replaceAll('//', '/') }} <i class="bx bx-link-external"></i></a>
        </b-col>
        <b-col md="3" class="d-flex" v-if="el.resource == 'category'">
          <a class="my-auto ml-auto badge badge-light bg-light text-primary" target="_blank" v-if="$attrs.domain.verified == 1" :href="'http://'+$attrs.domain.domain+'/category/'+el.link">{{$attrs.domain.domain}}/category/{{ el.link }} <i class="bx bx-link-external"></i></a>
          <a class="my-auto ml-auto badge badge-light bg-light text-primary" target="_blank" v-else :href="'http://website.'+$attrs.domain.domain_key+'.gemesys.co.uk/category/'+ el.link">website.{{$attrs.domain.domain_key}}.gemesys.co.uk/category/{{ el.link }} <i class="bx bx-link-external"></i></a>
        </b-col>-->
       
        <b-col md="5" class="d-flex">
           <a class="ml-auto my-auto mr-2 btn btn-light btn-rounded" v-b-tooltip.hover.top title="Delete Item" @click="menuitems.splice(index, 1)"><i class="bx bx-trash-alt text-danger"></i> </a>
          <a class="my-auto mr-2 btn btn-light btn-rounded" v-b-tooltip.hover.top title="Edit Item" @click="editMenu(el)"><i class="bx bx-pencil text-primary"></i> </a>
          <a class="my-auto mr-2 btn btn-light btn-rounded" v-b-tooltip.hover.top title="Duplicate Item" @click="menuitems.push(JSON.parse(JSON.stringify(menuitems[index]))), editMenu(menuitems[menuitems.length - 1])"><i class="bx bx-copy-alt text-primary"></i> </a>
          <a v-if="el.menuitems.length == 0" v-b-tooltip.hover.top title="Add Dropdown" class="btn btn-light btn-rounded my-auto mr-2" @click="el.menuitems.push(JSON.parse(JSON.stringify(menulinkdata))), editMenu(el.menuitems[0])"> <i class="bx bx-list-plus text-info"></i></a>
          <a v-else v-b-tooltip.hover.top title="Add Dropdown Item" class="btn btn-light btn-rounded my-auto mr-2" @click="el.menuitems.push(JSON.parse(JSON.stringify(menulinkdata))), editMenu(el.menuitems[el.menuitems.length - 1])"> <i class="bx bx-list-plus text-info"></i></a>
        <a class="my-auto btn btn-light btn-rounded mhandle" @click="menuitems.splice(index, 1)"><i class="bx bx-move"></i> </a>
        </b-col>
        
       

        
      </b-row>
      <nesteddraggable class="py-3" v-if="el.menuitems.length > 0" :domain="$attrs.domain" :parentlink="el.link" :menuitems="el.menuitems.length > 0 ? el.menuitems : []"  @edit-menu-item="editMenu" @add-menu-item="addToMenu" @delete-menu-item="deleteMenuItem" :groupname="el.name+index" :isChild="true" />
      <!--<b-col v-else>
        <b-col>
          <div class="d-flex w-100 border-radius-1em border bg-soft bg-light">
          <a class="btn btn-primary btn-rounded ml-auto my-3 mr-3" @click="el.menuitems.push(JSON.parse(JSON.stringify(menulinkdata))), editMenu(el.menuitems[0])"> Add Dropdown Items</a>
          </div>
        </b-col>
      </b-col>-->
    </b-list-group-item>
    
  </draggable>
  </b-col>
 
</b-row>
</template>
<script>

import draggable from 'vuedraggable'
import { EventBus }  from '../../app'
//import nestedDraggable from "./nestedDraggable";
export default {
  data() {
    return {
      menulinkdata: {
        id: 0,
        company_web_menu_id: '',
        name: '',
        label: 'Add or Drag menu item here',
        resource: '',
        menuitems: [],
        company_web_page_id: 0,
        resource_id: '',
        link: '',
      },
      menulinkdata_raw: {
        id: 0,
        company_web_menu_id: '',
        name: '',
        label: 'Add or Drag menu item here',
        resource: '',
        menuitems: [],
        company_web_page_id: 0,
        resource_id: '',
        link: '',
      },
      addPanel: false,
      itemEditorAction: 'add',
    }
  },
  props: {
    menuitems: {
      required: true,
      type: Array
    },
    groupname: {
      type: String,
      default: 'parent',
    },
    isChild: {
      type: Boolean,
      default: false,
    }
  },
  components: {
    draggable
  },
  name: "nesteddraggable",
  created(){
    let self = this;
    /*EventBus.$on('open-edit-panel', function(){
      self.itemEditorAction = 'add';
      self.addPanel = true;
    });*/
  },
  methods: {
    deleteMenuItem(){
      //
    },
    addDropdown(index){
    
      //EventBus.$emit('add-dropdown', this.menuitems, index);
    },
    addToMenu(type){
                this.menulink.resource = type;
                this.$emit('add-menu-item', this.action, type, this.menulink);
              },
    addResource(type, data){
        this.menulink.resource = type;
        this.menulink.resource_id = data.id;
        this.menulink.name = data.name;
        this.menulink.label = data.name;
          if(type == 'page'){
                this.menulink.company_web_page_id = data.id;
                this.menulink.link = data.url_rewrite;
                  }
                  this.$emit('add-menu-item', this.action, type, this.menulink);
    },
    editMenu(menu){
      this.$emit('edit-menu-item', menu);
    },
    deleteMenu(menu){
      this.$emit('delete-menu-item', menu);
    }
    
  }
};
</script>
<style scoped>
.dragArea {
  min-height: 50px;
  outline: 1px solid #e1e1e1;
}

</style>