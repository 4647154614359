<script type="text/javascript">
	import Layout from "../../layouts/main";
	import PageHeader from "../../components/page-header";
	import simplebar from "simplebar-vue";
	import CompanyProductSearch from '../../components/widgets/CompanyProductSearch';
	import CompanyProductList from '../../components/widgets/CompanyProductList';
	import DateTime from 'luxon/src/datetime.js';
	import Interval from 'luxon/src/interval.js';
	import SearchCompanyLocation from '../../components/widgets/SearchCompanyLocation';
	export default {
	components: {Layout, PageHeader, simplebar, CompanyProductSearch, CompanyProductList, DateTime, Interval, SearchCompanyLocation},
	data(){
		return {
			printer: {
		        id: '',
		        uid: '',
		        reference: '',
		        type: '',
		        connection_type:'',
		        name: '',
		        model: '',
		        description: '',
		        company_listing_id: '',
		        company_cash_register_id: '',
		        mac: '',
		        ip: '',
		      },
			success_message: false,
			success_text: 'Your action has been successful',
			success_count: 0,
			transferApprovalLoading: false,
			selectedTransferApprovalResponse: false,
			loadingEposSettings: false,
				chartDisplay: 'by-date',
				hoverOrderIndex: -1,
				sellersDataLoading: false,
				selectedTransfer: '',
				selectedTransferDirection: '',
				addProductListingQty: 0,
				addProductListingQtyHere: 0,
				addProductToggle: false,
				orderDataLoading: true,
				orderdatabydate: [],
				mapcenter: { lat: 54, lng: 2 },
				mapzoom: 8,
				toplistingsdata: [],
				topsellersdata: [],
				topproductsdata: [],
				ordersstartdate: '',
     		ordersenddate: '',
				users: '',
				staffsearch: '',
				staffpage: 1,
				stafflimit: 15,
				selecteduser: '',
			  newStaffMember: false,
			  shopData: '',
			  shopProducts: '',
			  shopServices: '',
			  shopOrders: [],
			  productsearch: '',
			  addProduct: '',
			  view: 'grid',
 				limit: 25,
				limits: [
				        {value: 25, text: "25"},
				        {value: 50, text: "50"},
				        {value: 100, text: "100"},
				],
				addreader: {
   				location: null,
   				name: '',
   				registration_code: '',
   				listing_id: 0,
   				configuration: {}
   			},
			  productsCurrentPage: 1,
			  storageurl: '/image',
			  hoverIndex: -1,
			  lineChart:{
            series: [
                {
                    name: "Earrnings",
                    type: 'line',
                    data: []
                },
                {
                    name: "OrderValue",
                    type: 'column',
                    data: []
                }
            ],
            chartOptions: {
                chart: {
                    height: 320,
                    type: "line",
                    toolbar: {
                        show: true
                    },
                    zoom: {
                        enabled: true
                    },
                    dropShadow: {
                        enabled: true,
                        color: "#000",
                        top: 18,
                        left: 7,
                        blur: 8,
                        opacity: 0.2
                    }
                },
                labels: [],
                dataLabels: {
                    enabled: false
                },
                colors: ["#5E3BB0", "rgba(52, 195, 143, 0.8)"],

                stroke: {
                    curve: "smooth",
                    width: 3
                },
                xaxis: {
                	type: "datetime",
                    labels: {
                        show: true
                    },
                    max: '',
                    min: '',
                    categories: [],
                },
                yaxis: [{
                    title: {
                        text: "Total Earnings",
                        style: {
                          color: "#00E396"
                        }
                      },
                },
                {
                    seriesName: "OrderValue",
                    opposite: true,
                    title: {
                        text: "Order Value",
                        style: {
                          color: "#00E396"
                        }
                      },
                }
                ]
            }
        },
			  title: "Shop",
		      items: [
		        {
		          text: "Company Shops",
		          href: "/",
		        },
		        {
		          text: "Shop",
		          active: true,
		        },
		      ],
		     
		}
	},
	props: {
		id: {
			type: String,
			default: () => ''
		},
		user: {
			type: Object,
			default: () => ''
		},
		company: {
			type: Object,
			default: () => ''
		}
	},
	mounted() {
		this.ordersstartdate = DateTime.local().minus({months: 1}).toISODate();
    	this.ordersenddate = DateTime.local().plus({days: 1}).toISODate();
		this.loadShopData();
		this.loadShopProducts();
		this.loadShopServices();

	},
	computed: {
			companyCurrency(){
		        if(this.company.currency !== null){
		            return this.company.currency.symbol;
		        }else{
		            return '£';
		        }
		  	}
	},
	methods: {

		getDate(date){
        var createdOn = DateTime.fromISO(date, { zone: 'utc'});
        console.log(createdOn);
        return createdOn.day+' '+createdOn.monthLong+' '+createdOn.weekYear+'';
      },
      updateDates(minus_months){
      	this.ordersstartdate = DateTime.local().minus({months: minus_months}).toISODate();
    	this.ordersenddate = DateTime.local().plus({days: 1}).toISODate();
    	//this.lineChart.chartOptions.xaxis.min = this.ordersstartdate;
    	//this.lineChart.chartOptions.xaxis.max = this.ordersenddate;
    	this.loadShopData();
      },
      setPrinterLocation(event){
      	this.printer.listing = event;
      },
		loadShopData(){
			axios.get('/api/company/listing/'+this.id, { headers : {
				'Authorization': 'Bearer ' + this.user.api_token
			} }).then(response => {
				this.shopData = response.data;
				return this.loadShopOrders(response.data.id);
			}).catch(error => {
				this.error_reponse = error;
			});
		},
		loadShopProducts(){
			axios.get('/api/company/listing/'+this.id+'/products?page='+this.currentPage+'&skip='+this.skip+'&take='+this.take+'&sortby='+this.sortby+'&order='+this.order+'&search='+this.productsearch+'&trade_active='+this.trade_active+'&public_active='+this.public_active+'&active='+this.active+'&brands='+this.brands+'&collections='+this.collections+'&pricerange='+this.priceRange, { headers : {
				'Authorization': 'Bearer ' + this.user.api_token
			} }).then(response => {
				this.shopProducts = response.data;
			}).catch(error => {
				this.error_reponse = error;
			});
		},
		loadShopServices(){
			axios.get('/api/company/listing/'+this.id+'/services', { headers : {
				'Authorization': 'Bearer ' + this.user.api_token
			} }).then(response => {
				this.shopServices = response.data;
			}).catch(error => {
				this.error_reponse = error;
			});
		},
		loadShopOrders(id){
			axios.get('/api/company/listing/'+id+'/orders?startdate='+this.ordersstartdate+'&enddate='+this.ordersenddate, { headers : {
				'Authorization': 'Bearer ' + this.user.api_token
			} }).then(response => {
				this.shopOrders = response.data;
				this.orderDataLoading = false;
				this.orderdatabydate = response.data[2];
				if(this.chartDisplay == 'by-order'){
                return this.compileOrderChart();
            	}else if(this.chartDisplay == 'by-date'){
                 return this.compileChartByDate();
            	}
			}).catch(error => {
				this.error_reponse = error;
			});
		},
		getTopProductsData(){
	        //this.orderDataLoading = true;
	        axios.get('/api/company/listing/'+this.shopData.id+'/top-products', {headers: {'Authorization' : 'Bearer '+this.user.api_token}}).then(response => {
	            this.topproductsdata = response.data;
	            //this.compileOrderChart();
	          
	        }).catch(error => {

	        });
	    },
	    getTopSellersData(){
	        this.sellersDataLoading = true;
	        axios.get('/api/company/listing/'+this.shopData.id+'/top-sellers', {headers: {'Authorization' : 'Bearer '+this.user.api_token}}).then(response => {
	            this.topsellersdata = response.data;
	            //this.compileOrderChart();
	          let self = this;
             setTimeout(() => {
              self.sellersDataLoading = false;
            }, 1500);
	        }).catch(error => {

	        });
	    },
		getCompanyUsers(){
			axios.get('/api/company/users?page='+this.staffpage+'&limit='+this.stafflimit+'&sortby=id&order=desc&search='+this.staffsearch+'&list=full&showp=true', {headers: {
				'Authorization' : 'Bearer '+this.user.api_token
			}}).then(response => {
				this.users = response.data;
			}).catch(error => {
				this.error_reponse = error;
			});
		},
		getCompanyShopUsers(){
			axios.get('/api/company/listing/'+this.id+'/staff').then(response => {
				this.users = response.data;
			}).catch(error => {
				this.error_reponse = error;
			});
		},
		makeManager(staff_member){
			axios.post('/api/company/listing/'+this.shopData.id+'/staff/make-manager', {company_user_id: staff_member.companies[0].id}, {headers: {
				'Authorization' : 'Bearer '+this.user.api_token
			}}).then(response => {
				//this.users = response.data;
				
				this.shopData.manager_id = staff_member.companies[0].id;
				this.shopData.manager = staff_member.companies[0];
				this.shopData.manager['user'] = staff_member;
			}).catch(error => {
				this.error_reponse = error;
			});
		},
		removeManager(){
			axios.post('/api/company/listing/'+this.shopData.id+'/staff/remove-manager', {}, {headers: {
				'Authorization' : 'Bearer '+this.user.api_token
			}}).then(response => {
				//this.users = response.data;
				
				this.shopData.manager_id = null;
				this.shopData.manager = null;
			}).catch(error => {
				this.error_reponse = error;
			});
		},
		addStaffMember(staff_member){
			axios.post('/api/company/listing/'+this.shopData.id+'/staff/add', {company_user_id: staff_member.companies[0].id}, {headers: {
				'Authorization' : 'Bearer '+this.user.api_token
			}}).then(response => {
				//this.users = response.data;
				var responsedata = response.data;
				responsedata["user"] = {id: staff_member.companies[0].id, user: ''};
				responsedata["user"]["user"] = staff_member;
				this.shopData.staff.push(responsedata);
			}).catch(error => {
				this.error_reponse = error;
			});
		},
		removeStaffMember(staff_member){
			axios.post('/api/company/listing/'+this.shopData.id+'/staff/delete', {company_user_id: staff_member.companies[0].id}, {headers: {
				'Authorization' : 'Bearer '+this.user.api_token
			}}).then(response => {
				var staffIndex = this.shopData.staff.findIndex(item => item.company_user_id == staff_member.companies[0].id);
				this.shopData.staff.splice(staffIndex, 1);
			}).catch(error => {
				this.error_reponse = error;
			});
		},
		alreadyStaff(staff_user_id){
			var userexists = this.shopData.staff.findIndex(item => item.company_user_id == staff_user_id);
			if(userexists > -1){
				return true;
			}else{
				return false;
			}
		},
		registerStripeLocation(location){
			this.loadingEposSettings = true;
   			axios.post('/api/company/settings/payments/locations/create',  location, {
   				headers: {
   					'Authorization' : 'Bearer '+this.user.api_token
   				}
   			}).then(response => {
   				//this.stripeAccount[0].locations.data.push(response.data[0]);
   				this.shopData.stripe_id = response.data[0].id;
   				this.loadingEposSettings = false;
   			}).catch(error => {

   			});
   		},
   		registerReader(){
   			this.loadingEposSettings = true;
   			this.addreader.location = this.shopData;
   			axios.post('/api/company/settings/payments/terminal/create', this.addreader, {headers: {
   				'Authorization' : 'Bearer '+this.user.api_token
   			}}).then(response => {
   				let self = this;
   				setTimeout(function(){
   					self.loadingEposSettings = false;
   				}, 800);
   			}).catch(error => {
   				let self = this;
   				setTimeout(function(){
   					self.loadingEposSettings = false;
   				}, 800);
   			});
   		},
   		destroyStripeReader(reader, index){
   			axios.post('/api/company/settings/payments/terminal/'+reader.id+'/destroy', reader, {headers: {
   				'Authorization' : 'Bearer '+this.user.api_token
   			}}).then(response => {

   			}).catch(error => {

   			});
   		},
   		compileChartByDate(){
   			this.lineChart.chartOptions.xaxis.type = "datetime";
    this.orderDataLoading = true;
    this.lineChart.series[0].data = [];
    this.lineChart.series[1].data = [];
    this.lineChart.chartOptions.labels = [];
    this.lineChart.chartOptions.xaxis.labels.show = true;
    var keys = Object.keys(this.orderdatabydate);
    this.lineChart.chartOptions.labels = keys;
    this.lineChart.chartOptions.xaxis.categories = keys;
    console.log(keys);
    for(var date in this.orderdatabydate){
        console.log(date);
            if(this.lineChart.series[0].data.length == 0){
                this.lineChart.series[0].data[0] = parseFloat(this.orderdatabydate[''+date+'']["orders_total"]);
            }else{
                this.lineChart.series[0].data.push(parseFloat(this.orderdatabydate[date]["orders_total"]) + parseFloat(this.lineChart.series[0].data[this.lineChart.series[0].data.length -1]));
            }

            this.lineChart.series[1].data.push(this.orderdatabydate[date]["orders_total"]);
    }

    let self = this;
             setTimeout(() => {
     self.orderDataLoading = false;
    }, 1500);
  },
    compileOrderChart(){
    	this.orderDataLoading = true;
   		this.lineChart.chartOptions.xaxis.type = "category";
        this.lineChart.series[0].data = [];
        this.lineChart.series[1].data = [];
        this.lineChart.chartOptions.xaxis.categories = [];
        for(var i = 0; i < this.shopOrders[0].orders.length; i++){
           if(i == 0){
            this.lineChart.series[0].data.push(parseFloat(this.shopOrders[0].orders[i].total_order_inc));
            }else{
               this.lineChart.series[0].data.push((parseFloat(this.lineChart.series[0].data[i-1]) + parseFloat(this.shopOrders[0].orders[i].total_order_inc) ) ) ; 
            }
            /**	
            if(this.shopOrders[0].orders[i].trade_orders.length > 0){
                this.donutChart.series[4] += parseFloat(this.shopOrders[0].orders[i].total_order_inc);
            }
            if(this.shopOrders[0].orders[i].customer_orders.length > 0){
                this.donutChart.series[3] += parseFloat(this.shopOrders[0].orders[i].total_order_inc);
            }
						**/
            this.lineChart.series[1].data.push(parseFloat(this.shopOrders[0].orders[i].total_order_inc));
            this.lineChart.chartOptions.xaxis.categories.push(this.shopOrders[0].orders[i].reference);
        }
          let self = this;
             setTimeout(() => {
              self.orderDataLoading = false;
            }, 1500);
    },
    addProductOptions(product){
    	this.addProduct = product;
    	this.addProductListingQty = this.listingQuantities(product.listings);
    	this.addProductListingQtyHere = this.quantityInListing(product.listings, this.shopData.id);
    	this.$bvModal.show('add-product');
    },
    quantityInListing(listings, listing_id){
    	var total = 0;
    	for(var i = 0; i < listings.length; i++){
    		if(listings[i].id == listing_id){
    			total += listings[i].quantity;
    		}
    	}
		return total;
    },
    listingQuantities(listings){
    	var total = 0;
    	for(var i = 0; i < listings.length; i++){
    		total += listings[i].quantity;
    	}
    	return total;
    },
    filteredTransfers(transfers, status){
    	return transfers.filter(item => item.approved == status || item.sender_approved == status);
    },
    filteredApprovedTransfers(transfers, status){
    	return transfers.filter(item => item.approved == status && item.sender_approved == status);
    },
    approveTransfer(confirmation){
    	this.transferApprovalLoading = true;
    	axios.post('/api/company/product/transfer/'+this.selectedTransfer.id+'/approve', {direction: this.selectedTransferDirection, approved: confirmation, uid: this.selectedTransfer.uid}, {headers: {'Authorization' : 'Bearer '+this.user.api_token}}).then(response => {
    		this.selectedTransferApprovalResponse = true;
    		this.success_text = 'You have approved a transfer';
    		this.success_message = true;
    		let self = this;
    		setTimeout(function() {
    			self.transferApprovalLoading = false;
    		}, 500);
    	}).catch(error => {

    	});
    }
	}
}
</script>

<template>
	<Layout :nickname="$attrs.nickname" :user="$attrs.user" :menuItems="$attrs.menuItems">
		<PageHeader :title="title" :items="items" />
		<b-row>
			<b-col md="12">
                    <transition name="slide-success" mode="out-in">
                  <b-alert id="success-message" variant="success" :show="success_count" fade dismissible @dismissed="success_message = !success_message, success_count = 0" @dismiss-count-down="countDownChanged">
                      <p class="font-size-14">{{success_text}}</p>
                       <!--<b-progress
					        variant="success"
					        :max="5"
					        :value="success_count"
					        height="4px"
					      ></b-progress>-->
                    </b-alert>
                  </transition>
                  </b-col>
			<b-col md="3">
				<b-card class="card overflow-hidden border-radius-1em shadow-sm seller-profile-card" no-body >
          <div class="bg-soft bg-primary">
            <div class="row">
            	<transition name="fade-left">
		                    	<div class="col-md-12 position-absolute z-index-2"> 
                              	<a id="tooltip-add"  :href="'/seller/'+company.nickname+'/profile'" class="mt-3 ml-3 btn btn-primary font-size-16 btn-rounded ml-auto float-left"><i class="mdi mdi-eye-outline"></i></a>
                              </div>
                          </transition>
                           
                       <div class="col-12">
                
                <gmap-map  v-if="shopData.gplace_id !== ''" :center="{lat: parseFloat(shopData.latitude), lng: parseFloat(shopData.longitude)}" :zoom="mapzoom" style="height: 350px" :options="{
														   zoomControl: true,
														   mapTypeControl: false,
														   scaleControl: false,
														   streetViewControl: false,
														   rotateControl: false,
														   fullscreenControl: true,
														   disableDefaultUi: false
														 }">
		              <gmap-marker
		               
		                :position="{lat: parseFloat(shopData.latitude), lng: parseFloat(shopData.longitude)}"
		                :clickable="true"
		                :draggable="true"
		                @click="mapcenter = {lat: parseFloat(shopData.latitude), lng: parseFloat(shopData.longitude)}, mapzoom = 11"
		              ></gmap-marker>
		            </gmap-map>
		            <img v-else-if="shopData.banner" :src="shopData.banner" alt class="img-thumbnail rounded-circle" />
                <img v-else src="/images/banner-placeholder.png" alt class="img-fluid" />
                 <transition name="fade-right">
		                        <div class="mt-auto mb-3 mx-2 d-flex position-absolute bottom-0 right-0 left-0">
                              	<p  class="text-muted ml-auto my-auto mr-3">
			                    <span class="bx bx-star font-size-14 text-warning"></span>
			                    <span class="bx bx-star font-size-14 text-warning ml-1"></span>
			                    <span class="bx bx-star font-size-14 text-warning ml-1"></span>
			                    <span class="bx bx-star font-size-14 text-warning ml-1"></span>
			                    <span class="bx bx-star font-size-14 ml-1"></span>
			                  </p>
              					</div>
              				</transition>
              </div>
            </div>
          </div>
				<b-card-body class="card-body pt-0">
		            <div class="row">
		              <div class="col-sm-12 pb-4 text-center">
		                <div class="avatar-md profile-user-wid mb-2 mx-auto" style="margin-top: -45px !important;">
		                  <img
		                  	v-if="shopData.logo" :src="shopData.logo"
		                    alt
		                    class="img-thumbnail rounded-circle"
		                  />
		                   <img
		                  	v-else 
		                    src="/images/product-placeholder.png"
		                    alt
		                    class="img-thumbnail rounded-circle"
		                  />
		                </div>
		                <h5 class="font-size-15 text-truncate">{{shopData.name}}</h5>
		                <p class="text-muted mb-0 text-truncate"><span v-if="shopData.county !== null">{{shopData.county.name}},</span> {{shopData.country.name}}</p>
		              </div>
		              <div class="col-md-12">
		              	<span class="badge badge-light border-radius-1em bg-light text-dark font-size-11 lh-16 my-auto p-2">
		              		<i class="bx bx-buildings text-primary"></i> {{shopData.listing_type.name}}
		              	</span>
		              	

		              </div>
		              
		            

		            </div>
		          </b-card-body>

		        </b-card>
		         <b-card class="border-radius-1em shadow-sm mt-3">
		        	<h5>Manager</h5>
		        	<b-card v-if="shopData.manager !== null" class="border-radius-1em shadow-none border  mb-0" body-class="d-flex">
		        		<img v-if="shopData.manager.user.avatar !== null" :src="shopData.manager.user.avatar" class="avatar-sm rounded-circle my-auto mr-2">
		        		<img v-else src="/images/product-placeholder.png" class="avatar-sm rounded-circle my-auto mr-2">
		        		<div class="my-auto d-flex flex-column">
		        			<h5 class="font-size-14 text-primary mb-0">{{shopData.manager.user.name}} {{shopData.manager.user.lastname}}</h5>
		        			<small class="mb-0">{{shopData.manager.user.email}}</small>
		        		</div>
		        		
	
		        		<b-dropdown
				          class="d-inline-block ml-auto"
				          variant="black"
				          menu-class="dropdown-menu-end "
				          toggle-class="header-item noti-icon"
				          left
				        >
				          <template v-slot:button-content>
				            <i class="bx bx-dots-vertical-rounded font-size-20"></i>
				          </template>
				          <b-dropdown-item @click="newStaffMember = !newStaffMember, getCompanyUsers()" v-b-modal.add-new-user>
				          	Change Manager
				          </b-dropdown-item>
				          <b-dropdown-item @click="removeManager()">
				          	Remove Manager
				          </b-dropdown-item>
				      </b-dropdown>
		        	</b-card>
		        	<b-card v-else class="border-radius-1em border shadow-none" body-class="d-flex">
		        		No Manager Selected <a class="btn btn-light btn-rounded ml-auto" @click="newStaffMember = !newStaffMember, getCompanyUsers()" v-b-modal.add-new-user>Select</a>
		        	</b-card>
		        </b-card>
		        <b-card  class="card overflow-hidden border-radius-1em shadow-sm">
		        <div class="col-md-12">
		              	<h6 class="font-size-14 mb-3">Services Enabled</h6>
		              		<span class="badge badge-light border-radius-1em bg-light text-dark font-size-12 lh-16 my-auto p-2 d-inline-flex">
		              		<i class="bx bxl-google text-primary my-auto font-size-12 mr-1"></i> Google Places <i class="bx bx-check-circle my-auto text-success ml-1"  v-b-tooltip.hover title="Linked to Google Places" v-if="shopData.gplace_id !== ''" ></i>
		              		<i class="bx bx-x-circle my-auto text-danger ml-1" v-else ></i>
		              	</span>
		              	<span class="badge badge-light border-radius-1em bg-light text-dark font-size-12 lh-16 my-auto p-2 d-inline-flex"  >
		              		<i class="bx bx-credit-card text-primary my-auto font-size-12 mr-1"></i> ePOS Payments <i class="bx bx-check-circle my-auto text-success ml-1" v-if="shopData.stripe_id !== null" v-b-tooltip.hover title="Terminal Payments Enabled"></i>
		              		<i class="bx bx-x-circle my-auto text-danger ml-1" v-else ></i>

		              	</span>

		              </div>
		             </b-card>
		            <b-card  class="card overflow-hidden border-radius-1em shadow-sm">
		              <div class="col-md-12">
		              	<h6 class="font-size-14 mb-3">Listing Locations</h6>
		              	<span v-if="shopData.gplace_id !== ''" class="badge badge-light border-radius-1em bg-light text-dark font-size-12 lh-16 my-auto p-2 d-inline-flex"  v-b-tooltip.hover title="Terminal Payments Enabled">
		              		<i class="bx bx-credit-card text-primary my-auto font-size-12 mr-1"></i> Trade Market
		              	</span>
		              	<span v-if="shopData.gplace_id !== ''" class="badge badge-light border-radius-1em bg-light text-dark font-size-12 lh-16 my-auto p-2 d-inline-flex"  v-b-tooltip.hover title="Terminal Payments Enabled">
		              		<i class="bx bx-credit-card text-primary my-auto font-size-12 mr-1"></i> Public Market
		              	</span>
		              </div>
		            </b-card>
		       
		      
			</b-col>
			<b-col md="9">
				<b-row>
					<b-col>
						  <b-card class="border-radius-1em shadow-sm">
				        	<div class="d-flex align-items-center mb-3"><div class="avatar-xs mr-3"><span class="avatar-title rounded-circle bg-soft-primary text-white font-size-18"><i class="bx bx-copy-alt"></i></span></div> <h5 class="font-size-14 mb-0">Orders</h5></div> <div class="text-muted mt-4"><h4 v-if="shopOrders.length > 0">{{shopOrders[0].order_count}} <i class="mdi mdi-chevron-up ml-1 text-success"></i></h4> <div class="d-flex"><span class="badge badge-soft-success font-size-12" :class="{'badge-soft-danger': (parseFloat(shopOrders[0].order_count) - parseFloat(shopOrders[1].order_count)) < 1}" v-if="shopOrders.length > 0"> {{parseFloat(shopOrders[0].order_count) - parseFloat(shopOrders[1].order_count)}} </span> <span class="ml-2 text-truncate">From previous period</span></div></div>
				        	
				        </b-card>
				    </b-col>
				    <b-col>
						  <b-card class="border-radius-1em shadow-sm">
				        	<div class="d-flex align-items-center mb-3"><div class="avatar-xs mr-3"><span class="avatar-title rounded-circle bg-soft-primary text-white font-size-18"><i class="bx bx-pound"></i></span></div> <h5 class="font-size-14 mb-0">Revenue</h5></div> <div class="text-muted mt-4"><h4  v-if="shopOrders.length > 0">{{companyCurrency}}{{parseFloat(shopOrders[0].total).toFixed(2)}} <i v-if="(parseFloat(shopOrders[0].total) - parseFloat(shopOrders[1].total)) > 0" class="mdi mdi-chevron-up ml-1 text-success"></i><i v-else class="mdi mdi-chevron-down ml-1 text-danger"></i></h4> <div class="d-flex"><span v-if="shopOrders.length > 0" class="badge badge-soft-success font-size-12" :class="{'badge-soft-danger': (parseFloat(shopOrders[0].total) - parseFloat(shopOrders[1].total)) < 1}"> <span v-if="(parseFloat(shopOrders[0].total) - parseFloat(shopOrders[1].total)) < 1">-</span><span v-else>+</span> {{companyCurrency}}{{parseFloat(parseFloat(shopOrders[0].total) - parseFloat(shopOrders[1].total)).toFixed(2)}} </span> <span class="ml-2 text-truncate">From previous period</span></div></div>
				        </b-card>
				    </b-col>
				    <b-col>
						  <b-card class="border-radius-1em shadow-sm">
				        	<div class="d-flex align-items-center mb-3"><div class="avatar-xs mr-3"><span class="avatar-title rounded-circle bg-soft-primary text-white font-size-18"><i class="bx bx-purchase-tag-alt"></i></span></div> <h5 class="font-size-14 mb-0">Avg. Order Price</h5></div> <div class="text-muted mt-4"><h4>{{companyCurrency}}{{parseFloat(parseFloat(shopOrders[0].total) / parseFloat(shopOrders[0].order_count)).toFixed(2)}} <i class="mdi mdi-chevron-up ml-1 text-success"></i></h4> <div class="d-flex"><span class="badge badge-soft-success font-size-12"> + {{(parseFloat(shopOrders[0].total) / parseFloat(shopOrders[0].order_count)) - (parseFloat(shopOrders[1].total) / parseFloat(shopOrders[1].order_count))}}</span> <span class="ml-2 text-truncate">From previous period</span></div></div>
				        </b-card>
				    </b-col>
				</b-row>
				<b-tabs pills justified>
					<b-tab>
						<template v-slot:title>
                              <span class="d-inline-block d-none">
                               <!--<i class="bx bx-history font-size-16"></i>-->
                              </span>
                              <span class="d-sm-inline-block"> Staff</span>
                            </template>


                           
		        <b-card class="border-radius-1em shadow-sm mt-3">
		        	<h5>Staff Members</h5>

		        	<div class="search-box mr-2 ml-auto my-3 d-flex">
	                <div class="position-relative flex-grow-1 mr-1 overflow-hidden" style="height: 40px;">
	               <transition name="fade-bottom">
	                  <input
	                    type="text"
	                    class="form-control w-100 border-0 bg-light"
	                    placeholder="Search shop staff members"
	                    v-model="staffsearch" @keydown.enter="getCompanyShopUsers()" 
	                  />
	                  

	              </transition>
	            
	                  <i class="bx bx-search-alt search-icon"></i>
	                </div>
	                <a class="btn btn-light btn-rounded my-auto" v-b-tooltip.hover title="Search Current Staff Members" @click="getCompanyShopUsers()">
	                	<i class="bx bx-search-alt"></i>
	                </a>
	                <a class="btn btn-light btn-rounded my-auto" @click="newStaffMember = !newStaffMember, getCompanyUsers()" v-b-tooltip.hover title="Add New Staff Member" v-b-modal.add-new-user>
	                	<i class="mdi mdi-plus"></i>
	                </a>
	                
	                
	              </div>
	              <b-modal id="add-new-user" hide-footer centered size="lg">
	              	
	                 
	             			<b-card class="border-radius-1em border shadow-none">
	             				 <input
	        
	                    type="text"
	                    class="form-control w-100 border-0 bg-light mb-3"
	                    placeholder="Search user to add to staff"
	                    v-model="staffsearch" @keydown.enter="getCompanyUsers()" 
	                  />
	             					<simplebar style="min-height: 250px; max-height: 350px;" class="border-radius-1em shadow-none border mb-3" v-if="users !== ''">
							        		<b-list-group v-if="users.data.length > 0">
							        			<b-list-group-item class="d-flex py-3" v-for="staff_member in users.data">
							        				<img v-if="staff_member.avatar !== null" :src="staff_member.avatar" class="avatar-sm rounded-circle my-auto mr-2">
							        				<img v-else src="/images/product-placeholder.png" class="avatar-sm rounded-circle my-auto mr-2">
							        				<div class="my-auto d-flex flex-column">
									        			<h5 class="font-size-14 text-primary mb-0">{{staff_member.name}} {{staff_member.lastname}}</h5>
									        			<small class="mb-0">{{staff_member.email}}</small>
									        		</div>
									        		<a class="btn btn-light btn-rounded my-auto ml-auto" v-if="alreadyStaff(staff_member.companies[0].id) == false" @click="addStaffMember(staff_member)">
											            <i class="mdi mdi-plus font-size-18 text-primary"></i>
									        		</a>
									        		<a class="btn btn-outline-success btn-rounded my-auto ml-auto" v-if="alreadyStaff(staff_member.companies[0].id) == true" @click="removeStaffMember(staff_member)">
											            <i class="mdi mdi-check font-size-18 text-success"></i>
									        		</a>
									        		<a class="btn btn-rounded my-auto ml-2" :class="{'btn-outline-success' : shopData.manager_id == staff_member.companies[0].id, 'btn-light' : shopData.manager_id !== staff_member.companies[0].id}"v-b-tooltip.hover title="Make Manager" @click="makeManager(staff_member)">
									        			 <i class="mdi mdi-account-tie-outline font-size-18" :class="shopData.manager_id == staff_member.companies[0].id ? 'text-success' : 'text-primary'"></i>
									        		</a>
							        			</b-list-group-item>
							        			<b-list-group-item v-if="users.data.length == 0" class="d-flex flex-column justify-content-center text-center">
							        				<i class="bx bx-group font-size-42 text-primary mt-4"></i>
							             				<p class="mb-4">
							             					No Results
							             				</p>
							        			</b-list-group-item>
							        		</b-list-group>
							        	</simplebar>
							        	  <b-card v-else class="border-radius-1em border shadow-none d-flex flex-column justify-content-center text-center">
			             				<i class="bx bx-group font-size-42"></i>
			             				<p>
			             					No Results
			             				</p>
			             			</b-card>
							        	<b-pagination
							        	v-model="staffpage"
					              :total-rows="users.total"
					              :per-page="users.per_page"
					              aria-controls="my-table"
					              @input="getCompanyUsers()"
					              align="center"
							        	>
							        	</b-pagination>

	             			</b-card>
	             		
	              </b-modal>


		        	<simplebar style="max-height: 530px;" class="border-radius-1em shadow-none border">
		        		<b-list-group flush v-if="shopData.staff.length > 0">
		        			<b-list-group-item class="d-flex" v-for="staff_member in shopData.staff">
		        				<img v-if="staff_member.user.user.avatar !== null" :src="staff_member.user.user.avatar" class="avatar-sm rounded-circle my-auto mr-2">
		        				<img v-else src="/images/product-placeholder.png" class="avatar-sm rounded-circle my-auto mr-2">
		        				<div class="my-auto d-flex flex-column">
				        			<h5 class="font-size-14 text-primary mb-0">{{staff_member.user.user.name}} {{staff_member.user.user.lastname}}</h5>
				        			<small class="mb-0">{{staff_member.user.user.email}}</small>
				        		</div>
				        		<a class="btn btn-light btn-rounded my-auto ml-auto" @click="removeStaffMember(staff_member)" href="javascript:;">
						            <i class="bx bx-trash font-size-20 text-primary"></i>
				        		</a>
				        		
		        			</b-list-group-item>
		        		</b-list-group>
		        	</simplebar>
		        </b-card>
          </b-tab>
					<b-tab>
						<template v-slot:title>
                              <span class="d-inline-block d-none">
                               <!--<i class="bx bx-history font-size-16"></i>-->
                              </span>
                              <span class="d-sm-inline-block"> Products</span>
                            </template>
					<b-card class="border-radius-1em shadow-sm mt-3">
						<h5 class="d-flex mb-3">Products Stocked Here <i class="bx bx-right-arrow-circle text-primary font-size-20 ml-2"></i> <a class="btn btn-light btn-rounded ml-auto float-right" @click="addProductToggle = !addProductToggle"> <span class="my-auto">Add Product</span> <i class="mdi mdi-plus"></i></a></h5>
						<transition name="fade-bottom">
						<CompanyProductSearch v-if="addProductToggle == true" class="mt-3 mb-4" :resource="'category'" :resource_id="shopData.id" :api_token="user.api_token" :resource_route="''" :company="company" @product-added="addProductOptions" ></CompanyProductSearch>
					</transition>
					 <b-modal id="add-product" hide-footer centered size="lg" title="Add Product to Shop">
						 		<b-card class="border-radius-1em border shadow-none">

						 			 <b-row no-gutters>
      							<b-col md="3">
						 			<b-card-img-lazy v-if="(addProduct.cover_image) && ('gallery' in addProduct.cover_image)"class="rounded-0" :src="storageurl+addProduct.cover_image.gallery.replaceAll('//', '/')" ></b-card-img-lazy>
                              	<b-card-img-lazy v-else-if="(addProduct.cover_image) && ('thumb' in addProduct.cover_image)"class="rounded-0" :src="storageurl+addProduct.cover_image.thumb.replaceAll('//', '/')" ></b-card-img-lazy>
                              	<b-card-img-lazy v-else-if="(addProduct.cover_image) && ('src' in addProduct.cover_image)"class="rounded-0" :src="storageurl+addProduct.cover_image.src.replaceAll('//', '/')" ></b-card-img-lazy>
		                        <b-card-img-lazy v-else :src="'/images/product-placeholder.png'" alt="Image" class="rounded-0"></b-card-img-lazy>
		                      </b-col>
		              		<b-col md="9" class="d-flex flex-column">
		              			
                              		<span class="badge badge-primary bg-primary font-size-11 mt-auto mr-auto">{{addProduct.reference}}</span>
                   <h5 class="card-title mr-auto my-1 text-primary">{{addProduct.name}}</h5>
                                	
						            <p class="text-muted italic no-margin mb-auto font-size-12" v-html="addProduct.short_description"></p>
						          
		              		</b-col>
		              </b-row>

						 		</b-card>
						 		<b-card class="border-radius-1em border shadow-none">
						 		<b-row no-gutters class="text-center">
      									<b-col md="4" class="py-2 border-right">
      									<h2 class="text-primary mb-0">{{addProduct.total_qty - addProductListingQty}}</h2>
      									<small>Webstock</small>
						 				</b-col>
						 				<b-col md="4" class="py-2">
						 					<h2 class="text-primary mb-0">{{addProductListingQty}}</h2>
      										<small>Currently listed in shops</small>
						 				</b-col>
						 				<b-col md="4" class="py-2">
						 					<h2 class="text-primary mb-0">{{addProductListingQtyHere}}</h2>
      										<small>Currently stocked here</small>
						 				</b-col>
						 			</b-row>
						 		</b-card>

						 		<b-card class="border-radius-1em border shadow-none" v-if="addProduct !== '' && addProduct.listings.length > 0">
						 			<div class="widget-scrollbox">
						 			<b-list-group>
						 				<b-list-group-item v-for="listing in addProduct.listings">
						 					<div class="media align-items-center">
                                    <div class="avatar-sm me-3" v-if="listing.listing_info.logo">
                                        <span
                                            class="avatar-title rounded-circle bg-light"
                                        >
                                            <!--<img
                                            		
                                                :src="listing.listing_info.logo"
                                                alt=""
                                                height="18"
                                            />-->
                                            
                                        </span>
                                      </div>
                                       <div class="avatar-sm me-3" v-else>
                                        <span class="avatar-title rounded-circle" >{{(listing.listing_info.name).slice(0,1)}}</span>
                                      </div>
                                   
                                    <div class="media-body py-1 d-flex">
                                    	<div class="d-flex flex-column my-auto">
                                        <h5 class="mb-0">
                                            {{listing.listing_info.name}}
                                        </h5>
                                        <p class="text-muted mb-0">
                                            <span v-if="listing.listing_info.address_1">{{listing.listing_info.address_1}},</span>
								          	<span v-if="listing.listing_info.address_2">{{listing.listing_info.address_2}},</span>
								          	<span v-if="listing.listing_info.address_3">{{listing.listing_info.address_3}},</span>
								          	<span v-if="listing.listing_info.county">{{listing.listing_info.county.name}},</span>
								          	<span v-if="listing.listing_info.country">{{listing.listing_info.country.name}},</span>
								          	<span v-if="listing.listing_info.postcode">{{listing.listing_info.postcode}}</span>
                                        </p>
                                    	</div>
                                        <div>

                                        </div>
                                        <div class="ml-auto">
                                            <p class="text-muted mb-0" v-if="listing.listing_info.listing_type">
                                                <i 
                                                    class="bx bx-buildings me-1"
                                                ></i>
                                                {{listing.listing_info.listing_type.name}}
                                            </p>
                                        </div>
                                       
                                    </div>
                                </div>
						 				</b-list-group-item>
						 			</b-list-group>
						 		</div>
						 		</b-card>
						 		<b-card class="border-radius-1em border shadow-none d-flex flex-column justify-content-center align-items-center py-5 text-center" v-else>
						 			<i class="bx bx-buildings font-size-42 text-muted"></i>
						 			<p class="mb-0">Not stocked at any other stores</p>
						 		</b-card>
						 </b-modal>
			            <b-overlay
			                            id="overlay-background"
			                            :show="loadingForm"
			                            variant="white"
			                            opacity="0.85"
			                            blur="1px"
			                            rounded="md"
			                          >
			                          <transition name="fade-top">
			                          	<div class="row mb-3" v-if="addProductToggle == false">
          
        <div class="col-lg-7 col-sm-6 d-flex">
            <div class="mt-sm-0 form-inline w-100" >
              <div class="search-box mr-2 ml-auto d-flex">
                <div class="position-relative w-100">
                  <input
                    type="text"
                    class="form-control w-100 border"
                    placeholder="Search name, reference or description..."
                    v-model="productsearch"
                    @keyup.enter="loadShopProducts()"
                  />
                  <i class="bx bx-search-alt search-icon"></i>
                </div>
                <transition name="fade-right">
                 <a v-if="productsearch.length > 3" class="btn btn-primary btn-rounded" @click="loadShopProducts()">
              	Search
              </a>
            </transition>
              </div>
             
            </div>
          </div>

         
          <div class="col-lg-2 col-sm-6 d-flex">
          <form class="my-auto">
            <small class="mx-auto my-2 text-center d-block">Results {{shopProducts.from}} - {{shopProducts.to}} of {{shopProducts.total}}</small>
          </form>
        </div>
       
          <div class="col-lg-2 col-sm-6 d-flex">
            <div class="d-flex ml-auto">
              <ul class="nav nav-pills product-view-nav">
                <li class="nav-item">
                  <a class="nav-link" :class="{'active': showFilters == true }" href="javascript: void(0);" @click="loadAllCategories(), showFilters = !showFilters, expanded = !expanded">
                    <i class="bx bx-filter-alt"></i>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" :class="{'active': view == 'grid' }" href="javascript: void(0);" @click="view = 'grid'">
                    <i class="bx bx-grid-alt"></i>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" :class="{'active': view == 'list' }" href="javascript: void(0);" @click="view = 'list'">
                    <i class="bx bx-list-ul"></i>
                  </a>
                </li>
              </ul>
              <div class="text-sm-right">
                  
                </div>
          </div>
        </div>
         <div class="col-xl-1 col-sm-6">
            <div class="my-auto">
              <b-form-select class="form-control border btn-rounded" v-model="limit" :options="limits" @input="loadShopProducts()"></b-form-select>
            </div>
          </div>
      </div>
    </transition>
			              <CompanyProductList :product_list="shopProducts.data" :expanded="0" :view="'grid'" :slimline="true" :pricing="'public'" :pricing_title="true" :listing_id="shopData.id" :company="company" ></CompanyProductList>
			            </b-overlay>
			            <b-pagination
			              v-if="shopProducts.data.length > 0"
			              class="justify-content-center mt-4"
			              pills
			              v-model="productsCurrentPage"
			              :total-rows="shopProducts.total"
			              :per-page="shopProducts.per_page"
			              aria-controls="my-table"
			              @input="loadSellerProducts"
			            ></b-pagination>
					</b-card>
					</b-tab>

					<b-tab>
						<template v-slot:title>
                              <span class="d-inline-block d-none">
                               <!--<i class="bx bx-history font-size-16"></i>-->
                              </span>
                              <span class="d-sm-inline-block"> Services</span>
                            </template>
                          <b-card class="border-radius-1em shadow-sm mt-3">
                          	<h5 class="d-flex mb-3">Services Offered Here <i class="bx bx-right-arrow-circle text-primary font-size-20 ml-2"></i> <a class="btn btn-light btn-rounded ml-auto float-right" @click="addProductToggle = !addProductToggle"> <span class="my-auto">Enable Service</span> <i class="mdi mdi-plus"></i></a></h5>

                          	<b-card v-if="shopServices == ''" class="border-radius-1em shadow-none border mt-3 d-flex flex-column text-center py-4">
                          		<i class="bx bx-wrench font-size-42 pt-3 text-muted"></i>
                          		<p class="pb-3">No Services Offered Here</p>
                          	</b-card>
                          </b-card>
					</b-tab>
					<b-tab>
						<template v-slot:title>
                              <span class="d-inline-block d-none">
                               <!--<i class="bx bx-history font-size-16"></i>-->
                              </span>
                              <span class="d-sm-inline-block"> Orders</span>
                            </template>
                          
                          
                          	<b-row>
                          	<b-col md="12">
                          <b-card class="border-radius-1em shadow-sm mt-3 p-3">
             
          
		
             <b-row>
             	<b-col md="2">
                <h5 class="d-flex">Shop Orders <i class="bx bx-right-arrow-circle text-primary font-size-20 ml-2"></i></h5>
                <h3 class="text-primary mt-4">{{shopOrders[0].order_count}}</h3>
                <small>Orders This Month</small>
                <hr>
                <h3 class="text-primary mt-4">{{shopOrders[1].order_count}}</h3>
                <small>Orders Last Month</small>
                <hr>
                <h3 class="text-primary mt-4">{{shopData.orders.length}}</h3>
                <small>Total Orders</small>
                <hr>
                <!--
                <h3 class="text-primary mt-4" v-if="product.listings.length > 0">{{listingsTotalQuantities}}</h3>
                <small>Quantity Assigned to Shops</small>
                <hr>
              	-->
              </b-col>
              <b-col md="10">
             <b-card class="shadow-none border-radius-1em border" v-if="shopOrders.length == 0">
             	<div style="height: 300px;" class="w-100 d-flex justify-content-center align-items-center">
             		<div class="mx-auto my-auto text-center">
             			<i class="bx bx-shopping-bag font-size-42 text-muted d-block mx-auto pb-2"></i>
             			<span class="text-muted">
             				No Orders Listed
             			</span>
             		</div>
             	</div>
             </b-card>
             	<b-card class="shadow-none border-radius-1em border" v-else>
             <div class="table-responsive">
              <table class="table table-centered table-hover">

                <!--<thead class="thead-light">
                  <tr>
                    
                    <th scope="col"><a :class="{'text-dark': sortby != 'order_no'}" href="javascript: void(0);" @click="orderDesc = !orderDesc,  sortby = 'order_no', getProductOrders(product.id)">Order No. <i v-if="orderDesc == true" class="bx bx-caret-up"></i><i v-if="orderDesc == false" class="bx bx-caret-down"></i></a></th>
                    <th scope="col">Customer</th>
                    <th scope="col">Source</th>
                    <th scope="col">Date</th>
                    <th scope="col">Total Exc.</th>
                    <th scope="col">Total Inc.</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>-->
               <thead>
                  	<tr>
                  	<th scope="col">Order No/Reference</th> 
                  	<th scope="col">Source</th>
                  	<th scope="col">Total Inc.</th> 
                  	<th scope="col">Paid</th>
                  	<th scope="col">Payment Method</th>
                  	<th scope="col">Status</th>
                  </tr>
                  </thead>
                <tbody class="widget-scrollbox" v-dragscroll  
                            style="max-height: 600px; height: auto;">
                  

                  <tr v-for="(list, orderindex) in shopData.orders" :key="list.id" valign="middle" @mouseenter="hoverOrderIndex = orderindex" @mouseleave="hoverOrderIndex = -1">
                    
                    <td>
                    	<i class="bx bx-calendar"></i> {{getDate(list.created_at)}} <br>
                      <a :href="'/order/details/'+list.uid+''"><span>#{{list.reference}}</span></a>
                      
                    </td>
                   
                     <td>
                       <div v-if="list.trade_orders.length > 0">
                       <span class="font-size-12 badge badge-light bg-light text-dark"  v-b-tooltip.hover title="Order from Trade Marketplace"><i class="bx bx-transfer-alt text-primary"></i> Trade Marketplace</span>
                      </div>
                      <div v-if="list.customer_orders.length > 0">
                        <span class="font-size-12 badge bg-primary bg-soft text-dark"  v-b-tooltip.hover title="Order from Gemesys Public Marketplace"><i class="bx bxs-user text-dark"></i> Public Marketplace</span>
                      </div>
                      <div v-if="list.website_orders.length > 0">
                        <span class="font-size-12 badge bg-peach-soft text-primary" v-b-tooltip.hover title="Order from Your Website"><i class="bx bx-globe text-primary"></i> Website</span>
                      </div>
                      <div v-if="list.pos_orders.length > 0">
                        <span class="font-size-12 badge bg-peach-soft text-primary" v-b-tooltip.hover title="Order from Your Website"><i class="bx bx-globe text-primary"></i> In Store</span>
                      </div>
                    </td>
                    <!--<td>
                      <a href="javascript:;" v-b-tooltip.hover title="Stock" placement="left" @click="selectedListing = list, getProducts(list.id), showListingProducts = 1" class="btn btn-outline-light btn-rounded"><i class="bx bx bx-purchase-tag-alt"></i></a>
                    </td>-->
                     
                    <td>
                      {{parseFloat(list.total_order_inc).toFixed(2)}} inc. VAT
                    </td>
                     <td class="text-center">
                      <i v-if="list.order_paid == 1" class="bx bx-check-circle text-success font-size-18 mx-auto"></i>
                      <i v-else class="bx bx-x-circle text-danger font-size-18 mx-auto"></i>
                    </td>
                    <td>
                      <div v-if="list.payments.length > 0">
                        <span v-if="list.payments[0].payment_type == 'card'" class="font-size-12 badge badge-soft-primary text-primary">
                          <i class="bx bx-credit-card"></i>
                          Card
                        </span>
                        <span v-if="list.payments[0].payment_type == 'card_present'" class="font-size-12 badge badge-soft-primary text-primary">
                          <i class="bx bx-credit-card"></i>
                          Card Terminal
                        </span>
                      </div>
                      <div v-else>
                         <span  class="font-size-12 badge badge-soft-danger text-danger">
                          <i class="bx bx-no-entry"></i> No Payment Made
                         </span>
                      </div>
                    </td>
                    <td v-if="hoverOrderIndex !== index">
                       <div v-if="list.status !== null">
                        <span class="badge bg-soft font-size-12" :style="{'background-color': list.status.color}" > {{list.status.name}}</span>
                       </div>
                    </td>
                    <td v-else>
                    	<a href="javascript:;" v-b-tooltip.hover title="View" placement="left" :href="'/order/details/'+list.uid+''" class="btn btn-outline-light btn-rounded"><i class="mdi mdi-eye-outline"></i></a>
                    </td>
                  	
                  </tr>
                </tbody>
              </table>
            </div>
         
          </b-card>
          </b-col>
        </b-row>
                          </b-card>
                        </b-col>
                      </b-row>
                     
					</b-tab>
					<b-tab>
						<template v-slot:title>
                              <span class="d-inline-block d-none">
                               <!--<i class="bx bx-history font-size-16"></i>-->
                              </span>
                              <span class="d-sm-inline-block">Bookings</span>
                            </template>
                            <b-card class="border-radius-1em shadow-sm mt-3">
                            	<b-row>
									             	<b-col md="2">
									                <h5 class="d-flex">Bookings <i class="bx bx-right-arrow-circle text-primary font-size-20 ml-2"></i></h5>
									                <h3 class="text-primary mt-4">{{shopOrders[0].order_count}}</h3>
									                <small>Bookings This Month</small>
									                <hr>
									                <h3 class="text-primary mt-4">{{shopOrders[1].order_count}}</h3>
									                <small>Bookings Last Month</small>
									                <hr>
									                <h3 class="text-primary mt-4">{{shopData.orders.length}}</h3>
									                <small>Total Bookings</small>
									                <hr>
									                <!--
									                <h3 class="text-primary mt-4" v-if="product.listings.length > 0">{{listingsTotalQuantities}}</h3>
									                <small>Quantity Assigned to Shops</small>
									                <hr>
									              	-->
									              </b-col>
									              <b-col md="10">
									              		<b-card class="border-radius-1em shadow-none border mt-3 d-flex flex-column text-center py-4">
			                          		<i class="bx bx-calendar font-size-42 pt-3 text-muted"></i>
			                          		<p class="pb-3">No Bookings at this Location</p>
			                          	</b-card>
									              </b-col>
									            </b-row>
                          </b-card>
					</b-tab>
					<b-tab>
						<template v-slot:title>
                              <span class="d-inline-block d-none">
                               <!--<i class="bx bx-history font-size-16"></i>-->
                              </span>
                              <span class="d-sm-inline-block"> Transfers</span>
                            </template>
                            <b-tabs pills vertical class="mt-3">
                            	<b-tab>
                            		<template v-slot:title>
		                              <span class="d-inline-block">
		                               <i class="bx bx-right-arrow-alt"></i>
		                              </span>
		                              <span class="d-sm-inline-block"> Transfers In</span>
                            		</template>
                            		<b-card class="border-radius-1em shadow-sm" v-if="shopData.transfers_in.length > 0">
                  								<h5 class="card-title">Approved</h5>
                  								<simplebar style="max-height: 460px;">
                  								<b-list-group v-if="filteredApprovedTransfers(shopData.transfers_in, 1).length > 0">
								                  <b-list-group-item v-for="transfer in filteredApprovedTransfers(shopData.transfers_in, 1)" class="d-flex flex-column p-0">
								                  	<div class="p-3 d-flex flex-column">
								                  		<div class="my-auto mb-3">
								                  			<h5>Transfering From:</h5>
								                  		</div>
								                  		<div class="media overflow-hidden">
								                        <div class="avatar-sm me-3">
								                          <img v-if="transfer.transfer_from.logo !== null" class="avatar-md border rounded-circle" :src="storageurl+transfer.transfer_from.logo">
								                          <img v-else-if="transfer.transfer_from.banner !== null" class="avatar-md border rounded-circle" :src="storageurl+transfer.transfer_from.banner">
								                          <span class="avatar-title rounded-circle" v-else>{{(transfer.transfer_from.name).slice(0,1)}}</span>
								                        </div>
								                        <div class="media-body my-auto text-truncate">
								                          <h5 class="mb-0 font-size-16">{{transfer.transfer_from.name}}</h5>
								                          <p class="text-truncate mb-0">{{transfer.transfer_from.address_1}} {{transfer.transfer_from.address_2}} {{transfer.transfer_from.address_3}} <span v-if="transfer.transfer_from.county !== null">{{transfer.transfer_from.county.name}}</span> {{transfer.transfer_from.postcode}}</p>
								                        </div>
								                      </div>
								                      <div class="my-auto mr-3" v-if="transfer.approved == null" v-b-modal.approve-transfer @click="selectedTransfer = transfer, selectedTransferDirection = 'in'">
									                    	<a class="btn btn-primary btn-rounded">
									                    		Approve Transfer
									                    	</a>
									                    </div>
								                    </div>
								                    <div class="d-flex border-top py-3 bg-light bg-soft">
								                      <div class="my-auto mx-auto">
									                      <i class="bx bx-calendar font-size-16 text-primary"></i>
									                      {{transfer.date_requested | luxon}}
									                    </div>
									                    <div class="my-auto mx-auto">
									                      <span class="d-flex" v-if="transfer.approved == 1">
									                      <i class="bx bx-check-circle text-success font-size-18"></i>
									                      Receiver Approved
									                      </span>
									                      <span class="d-flex" v-else-if="transfer.approved == 0">
									                      <i class="bx bx-x-circle text-danger font-size-18"></i>
									                      Receiver Not Approved
									                      </span>
									                      <span class="d-flex" v-else-if="transfer.approved == null"> 
									                      <i class="bx bx-info-circle text-primary font-size-18"></i> 
									                      Receiver Awaiting Approval
									                      </span>
									                    </div>
									                    <div class="my-auto mx-auto">
									                      <span class="d-flex" v-if="transfer.sender_approved == 1">
									                      <i class="bx bx-check-circle text-success font-size-18"></i>
									                      Sender Approved
									                      </span>
									                      <span class="d-flex" v-else-if="transfer.sender_approved == 0">
									                      <i class="bx bx-x-circle text-danger font-size-18"></i>
									                      Sender Not Approved
									                      </span>
									                      <span class="d-flex" v-else-if="transfer.sender_approved == null"> 
									                      <i class="bx bx-info-circle text-primary font-size-18"></i> 
									                      Sender Awaiting Approval
									                      </span>
									                    </div>
									                    
									                  </div>
                  									</b-list-group-item>
                  								</b-list-group>
                  								<b-card v-else class="border-radius-1em border shadow-none" body-class="d-flex flex-column justify-content-center align-items-center" style="min-height: 230px;">
                  									<i class="bx bx-transfer-alt font-size-42 pt-3 text-muted"></i>
			                          		<p class="pb-3">No Incoming Transfers Approved</p>
                  								</b-card>
                  							</simplebar>
                  								<h5 class="card-title">Awaiting Approval</h5>
                  								<simplebar style="max-height: 460px;">
                  								<b-list-group v-if="filteredTransfers(shopData.transfers_in, null).length > 0">
								                  <b-list-group-item v-for="transfer in filteredTransfers(shopData.transfers_in, null)" class="d-flex flex-column p-0">
								                  	<div class="p-3 d-flex flex-column">
								                  		<div class="my-auto mb-3">
								                  			<h5>Transfering From:</h5>
								                  		</div>
								                  		<div class="media overflow-hidden">
								                        <div class="avatar-sm me-3">
								                          <img v-if="transfer.transfer_from.logo !== null" class="avatar-md border rounded-circle" :src="storageurl+transfer.transfer_from.logo">
								                          <img v-else-if="transfer.transfer_from.banner !== null" class="avatar-md border rounded-circle" :src="storageurl+transfer.transfer_from.banner">
								                          <span class="avatar-title rounded-circle" v-else>{{(transfer.transfer_from.name).slice(0,1)}}</span>
								                        </div>
								                        <div class="media-body my-auto text-truncate">
								                          <h5 class="mb-0 font-size-16">{{transfer.transfer_from.name}}</h5>
								                          <p class="text-truncate mb-0">{{transfer.transfer_from.address_1}} {{transfer.transfer_from.address_2}} {{transfer.transfer_from.address_3}} <span v-if="transfer.transfer_from.county !== null">{{transfer.transfer_from.county.name}}</span> {{transfer.transfer_from.postcode}}</p>
								                        </div>
								                      </div>
								                      <div class="my-auto ml-auto mr-3">
								                      	<a class="btn btn-light btn-rounded" v-b-tooltip.hover title="Products" v-b-modal.transfer-details @click="selectedTransfer = transfer" >
									                    		<i class="bx bx-purchase-tag-alt"></i>
									                    	</a>
								                      </div>
								                      <div class="my-auto mr-3" v-if="transfer.approved == null" v-b-modal.approve-transfer @click="selectedTransfer = transfer, selectedTransferDirection = 'in'">
									                    	<a class="btn btn-primary btn-rounded">
									                    		Approve Transfer
									                    	</a>
									                    </div>
								                    </div>
								                    <div class="d-flex border-top py-3 bg-light bg-soft">
								                      <div class="my-auto mx-auto">
									                      <i class="bx bx-calendar font-size-16 text-primary"></i>
									                      {{transfer.date_requested | luxon}}
									                    </div>
									                    <div class="my-auto mx-auto">
									                      <span class="d-flex" v-if="transfer.approved == 1">
									                      <i class="bx bx-check-circle text-success font-size-18"></i>
									                      Receiver Approved
									                      </span>
									                      <span class="d-flex" v-else-if="transfer.approved == 0">
									                      <i class="bx bx-x-circle text-danger font-size-18"></i>
									                      Receiver Not Approved
									                      </span>
									                      <span class="d-flex" v-else-if="transfer.approved == null"> 
									                      <i class="bx bx-info-circle text-primary font-size-18"></i> 
									                      Receiver Awaiting Approval
									                      </span>
									                    </div>
									                    <div class="my-auto mx-auto">
									                      <span class="d-flex" v-if="transfer.sender_approved == 1">
									                      <i class="bx bx-check-circle text-success font-size-18"></i>
									                      Sender Approved
									                      </span>
									                      <span class="d-flex" v-else-if="transfer.sender_approved == 0">
									                      <i class="bx bx-x-circle text-danger font-size-18"></i>
									                      Sender Not Approved
									                      </span>
									                      <span class="d-flex" v-else-if="transfer.sender_approved == null"> 
									                      <i class="bx bx-info-circle text-primary font-size-18"></i> 
									                      Sender Awaiting Approval
									                      </span>
									                    </div>
									                    
									                  </div>
                  									</b-list-group-item>
                  								</b-list-group>
                  								<b-card v-else class="border-radius-1em border shadow-none" body-class="d-flex flex-column justify-content-center align-items-center" style="min-height: 230px;">
                  									<i class="bx bx-transfer-alt font-size-42 pt-3 text-muted"></i>
			                          		<p class="pb-3">No Incoming Transfers Approved</p>
                  								</b-card>
                  							</simplebar>
                  	        		</b-card>
                  	        		<b-card class="border-radius-1em shadow-none border d-flex flex-column text-center py-4" v-else>
			                          		<i class="bx bx-transfer-alt font-size-42 pt-3 text-muted"></i>
			                          		<p class="pb-3">No Transfers Coming in to This Location</p>
			                          	</b-card>
                            	</b-tab>
                            	<b-tab>
                            			<template v-slot:title>
		                              <span class="d-inline-block">
		                               <i class="bx bx-left-arrow-alt"></i>
		                              </span>
		                              <span class="d-sm-inline-block"> Transfers Out</span>
                            		</template>
                            		<b-card class="border-radius-1em shadow-sm" v-if="shopData.transfers_out.length > 0">
                  								<h5 class="card-title">Approved</h5>
                  								<simplebar style="max-height: 460px;">
                  								<b-list-group v-if="filteredApprovedTransfers(shopData.transfers_out, 1).length > 0">
								                  <b-list-group-item v-for="transfer in filteredApprovedTransfers(shopData.transfers_out, 1)" class="d-flex flex-column p-0">
								                  	<div class="p-3 d-flex">
								                  		<div class="my-auto mr-3">
								                  			<h5>Transfering To:</h5>
								                  		</div>
								                  		<div class="media overflow-hidden">
								                        <div class="avatar-md me-3">
								                          <img v-if="transfer.transfer_to.logo !== null" class="avatar-md border rounded-circle" :src="storageurl+transfer.transfer_to.logo">
								                          <img v-else-if="transfer.transfer_to.banner !== null" class="avatar-md border rounded-circle" :src="storageurl+transfer.transfer_to.banner">
								                          <span class="avatar-title rounded-circle" v-else>{{(transfer.transfer_to.name).slice(0,1)}}</span>
								                        </div>
								                        <div class="media-body my-auto text-truncate">
								                          <h5 class="mb-0 font-size-16">{{transfer.transfer_to.name}}</h5>
								                          <p class="text-truncate mb-0">{{transfer.transfer_to.address_1}} {{transfer.transfer_to.address_2}} {{transfer.transfer_to.address_3}} <span v-if="transfer.transfer_to.county !== null">{{transfer.transfer_to.county.name}}</span> {{transfer.transfer_to.postcode}}</p>
								                        </div>
								                      </div>
								                      <div class="my-auto ml-auto mr-3">
								                      	<a class="btn btn-light btn-rounded" v-b-tooltip.hover title="Products" v-b-modal.transfer-details @click="selectedTransfer = transfer" >
									                    		<i class="bx bx-purchase-tag-alt"></i>
									                    	</a>
								                      </div>
								                      <div class="my-auto mr-3" v-if="transfer.approved == null" v-b-modal.approve-transfer @click="selectedTransfer = transfer, selectedTransferDirection = 'out'">
									                    	<a class="btn btn-primary btn-rounded">
									                    		Approve Transfer
									                    	</a>
									                    </div>
								                    </div>
								                    <div class="d-flex border-top py-3 bg-light bg-soft">
								                      <div class="my-auto mx-auto">
									                      <i class="bx bx-calendar font-size-16 text-primary"></i>
									                      {{transfer.date_requested | luxon}}
									                    </div>
									                    <div class="my-auto mx-auto">
									                      <span class="d-flex" v-if="transfer.approved == 1">
									                      <i class="bx bx-check-circle text-success font-size-18"></i>
									                      Receiver Approved
									                      </span>
									                      <span class="d-flex" v-else-if="transfer.approved == 0">
									                      <i class="bx bx-x-circle text-danger font-size-18"></i>
									                      Receiver Not Approved
									                      </span>
									                      <span class="d-flex" v-else-if="transfer.approved == null"> 
									                      <i class="bx bx-info-circle text-primary font-size-18"></i> 
									                      Receiver Awaiting Approval
									                      </span>
									                    </div>
									                    <div class="my-auto mx-auto">
									                      <span class="d-flex" v-if="transfer.sender_approved == 1">
									                      <i class="bx bx-check-circle text-success font-size-18"></i>
									                      Sender Approved
									                      </span>
									                      <span class="d-flex" v-else-if="transfer.sender_approved == 0">
									                      <i class="bx bx-x-circle text-danger font-size-18"></i>
									                      Sender Not Approved
									                      </span>
									                      <span class="d-flex" v-else-if="transfer.sender_approved == null"> 
									                      <i class="bx bx-info-circle text-primary font-size-18"></i> 
									                      Sender Awaiting Approval
									                      </span>
									                    </div>
									                    
									                  </div>
                  									</b-list-group-item>
                  								</b-list-group>
                  								<b-card v-else class="border-radius-1em border shadow-none" body-class="d-flex flex-column justify-content-center align-items-center" style="min-height: 230px;">
                  									<i class="bx bx-transfer-alt font-size-42 pt-3 text-muted"></i>
			                          		<p class="pb-3">No Incoming Transfers Approved</p>
                  								</b-card>
                  							</simplebar>
                  								<h5 class="card-title">Awaiting Approval</h5>
                  								<simplebar style="max-height: 460px;">
                  								<b-list-group v-if="filteredTransfers(shopData.transfers_out, null).length > 0">
								                  <b-list-group-item v-for="transfer in filteredTransfers(shopData.transfers_out, null)" class="d-flex flex-column p-0">
								                  	<div class="p-3 d-flex">
								                  		<div class="my-auto mr-3">
								                  			<h5>Transfering To:</h5>
								                  		</div>
								                  		<div class="media overflow-hidden">
								                        <div class="avatar-md me-3">
								                          <img v-if="transfer.transfer_to.logo !== null" class="avatar-md border rounded-circle" :src="storageurl+transfer.transfer_to.logo">
								                          <img v-else-if="transfer.transfer_to.banner !== null" class="avatar-md border rounded-circle" :src="storageurl+transfer.transfer_to.banner">
								                          <span class="avatar-title rounded-circle" v-else>{{(transfer.transfer_to.name).slice(0,1)}}</span>
								                        </div>
								                        <div class="media-body my-auto text-truncate">
								                          <h5 class="mb-0 font-size-16">{{transfer.transfer_to.name}}</h5>
								                          <p class="text-truncate mb-0">{{transfer.transfer_to.address_1}} {{transfer.transfer_to.address_2}} {{transfer.transfer_to.address_3}} <span v-if="transfer.transfer_to.county !== null">{{transfer.transfer_to.county.name}}</span> {{transfer.transfer_to.postcode}}</p>
								                        </div>
								                      </div>
								                      <div class="my-auto ml-auto mr-3">
								                      	<a class="btn btn-light btn-rounded" v-b-tooltip.hover title="Products" v-b-modal.transfer-details @click="selectedTransfer = transfer" >
									                    		<i class="bx bx-purchase-tag-alt"></i>
									                    	</a>
								                      </div>
								                      <div class="my-auto mr-3" v-if="transfer.approved == null" v-b-modal.approve-transfer @click="selectedTransfer = transfer, selectedTransferDirection = 'out'">
									                    	<a class="btn btn-primary btn-rounded">
									                    		Approve Transfer
									                    	</a>
									                    </div>
								                    </div>
								                    <div class="d-flex border-top py-3 bg-light bg-soft">
								                      <div class="my-auto mx-auto">
									                      <i class="bx bx-calendar font-size-16 text-primary"></i>
									                      {{transfer.date_requested | luxon}}
									                    </div>
									                    <div class="my-auto mx-auto">
									                      <span class="d-flex" v-if="transfer.approved == 1">
									                      <i class="bx bx-check-circle text-success font-size-18"></i>
									                      Receiver Approved
									                      </span>
									                      <span class="d-flex" v-else-if="transfer.approved == 0">
									                      <i class="bx bx-x-circle text-danger font-size-18"></i>
									                      Receiver Not Approved
									                      </span>
									                      <span class="d-flex" v-else-if="transfer.approved == null"> 
									                      <i class="bx bx-info-circle text-primary font-size-18"></i> 
									                      Receiver Awaiting Approval
									                      </span>
									                    </div>
									                    <div class="my-auto mx-auto">
									                      <span class="d-flex" v-if="transfer.sender_approved == 1">
									                      <i class="bx bx-check-circle text-success font-size-18"></i>
									                      Sender Approved
									                      </span>
									                      <span class="d-flex" v-else-if="transfer.sender_approved == 0">
									                      <i class="bx bx-x-circle text-danger font-size-18"></i>
									                      Sender Not Approved
									                      </span>
									                      <span class="d-flex" v-else-if="transfer.sender_approved == null"> 
									                      <i class="bx bx-info-circle text-primary font-size-18"></i> 
									                      Sender Awaiting Approval
									                      </span>
									                    </div>
									                    
									                  </div>
                  									</b-list-group-item>
                  								</b-list-group>
                  								<b-card v-else class="border-radius-1em border shadow-none" body-class="d-flex flex-column justify-content-center align-items-center" style="min-height: 230px;">
                  									<i class="bx bx-transfer-alt font-size-42 pt-3 text-muted"></i>
			                          		<p class="pb-3">No Incoming Transfers Approved</p>
                  								</b-card>
                  							</simplebar>
                  	        		</b-card>
                  	        		<b-card class="border-radius-1em shadow-none border d-flex flex-column text-center py-4" v-else>
			                          		<i class="bx bx-transfer-alt font-size-42 pt-3 text-muted"></i>
			                          		<p class="pb-3">No Outgoing Transfers Location</p>
			                          	</b-card>
                            	</b-tab>
                            </b-tabs>
                            <b-modal id="transfer-details" centered hide-footer hide-header size="lg">
                            	<h5 class="text-center mt-3">Products in Transfer</h5>
                            	<b-card class="border-radius-1em border shadow-none">
                            		<b-list-group>
                            			<b-list-group-item v-for="item in selectedTransfer.products" class="d-flex">
                            				<div class="media overflow-hidden">
								                        <div class="avatar-sm me-3" v-if="item.product.cover_image !== null">
								                          <b-card-img-lazy v-if="'thumb' in item.product.cover_image" class="avatar-sm border rounded-circle" :src="storageurl+item.product.cover_image.thumb"></b-card-img-lazy>
								                          <b-card-img-lazy v-else-if="'gallery' in item.product.cover_image" class="avatar-sm border rounded-circle" :src="storageurl+item.product.cover_image.gallery"></b-card-img-lazy>
								                           <b-card-img-lazy v-else-if="'src' in item.product.cover_image" class="avatar-sm border rounded-circle" :src="storageurl+item.product.cover_image.src"></b-card-img-lazy>
								                          <span class="avatar-title rounded-circle" v-else>{{(item.product.name).slice(0,1)}}</span>
								                        </div>
								                        <div class="avatar-sm me-3" v-else>
								                        <span class="avatar-title rounded-circle" >{{(item.product.name).slice(0,1)}}</span>
								                      	</div>

								                        <div class="media-body my-auto text-truncate">
								                          <h5 class="mb-0 font-size-16">{{item.product.name}}</h5>
								                          <p class="text-truncate mb-0" v-html="item.product.short_description"></p>
								                        </div>
								                       
								                       </div>
								                        <div class="my-auto ml-4 text-center">
								                        	<h5 class="text-uppercase font-size-12 text-underline spaced-title mb-0">Quantity:</h5>
								                        </div>
								                        <div class="my-auto ml-4 text-center">
								                        
								                        	<a class="btn btn-outline-light btn-rounded mt-auto" href="javascript:;">{{item.quantity}}</a>
								                        </div>
								                    		<div class="my-auto ml-4 text-center">
								                    			<a class="btn btn-outline-light btn-rounded mt-auto" :href="'/stock/product/'+item.product.uid" target="_blank">
								                    				<i class="mdi mdi-eye-outline"></i>
								                    			</a>
								                    		</div>
                            			</b-list-group-item>
                            		</b-list-group>
                            	</b-card>
                            </b-modal>
                            <b-modal id="approve-transfer" hide-footer hide-header centered>
                            	<b-overlay :show="transferApprovalLoading">
                            	<b-card class="border-radius-1em border shadow-none" v-if="selectedTransfer !== (null || '')">
                            		<b-card class="border-radius-1em border shadow-none" body-class="d-flex">
                            			<img v-if="user.avatar !== null" :src="storageurl+user.avatar" class="avatar-sm mr-3">
                            			<span class="avatar-sm mr-3 rounded-circle">
                            				<span class="avatar-title rounded-circle">{{user.name.slice(0,1)}}</span>
                            			</span>
                            			<div class="my-auto">
                            			<h5 class="mb-0">{{user.name}} {{user.lastname}}</h5>
                            			<p class="mb-0">{{user.email}}</p>
                            			</div>
                            		</b-card>
                            		
                            		
                            		<b-card class="border-radius-1em border shadow-none">
                            			<h5>#{{selectedTransfer.uid}}</h5>
                            			<p>
                            				{{selectedTransfer.date_required_by}}
                            			</p>
                            			<hr>
                            			<h5>From</h5>
                            			<hr>
                            			<p  v-if="selectedTransfer.transfer_from !== null">
                            				<span>{{selectedTransfer.transfer_from.address_1}}, </span>
                            				<span>{{selectedTransfer.transfer_from.address_2}}, </span>
                            				<span>{{selectedTransfer.transfer_from.address_3}}, </span>
                            				<span v-if="selectedTransfer.transfer_from.county !== null">{{selectedTransfer.transfer_from.county.name}}, </span>
                            				<span>{{selectedTransfer.transfer_from.postcode}}, </span>
                            				<span  v-if="selectedTransfer.transfer_from.country !== null">{{selectedTransfer.transfer_from.country.name}} </span>
                            			</p>

                            			<h5>To</h5>
                            			<hr>
                            			<p  v-if="selectedTransfer.transfer_to !== null">
                            				<span>{{selectedTransfer.transfer_to.address_1}}, </span>
                            				<span>{{selectedTransfer.transfer_to.address_2}}, </span>
                            				<span>{{selectedTransfer.transfer_to.address_3}}, </span>
                            				<span v-if="selectedTransfer.transfer_to.county !== null">{{selectedTransfer.transfer_to.county.name}}, </span>
                            				<span>{{selectedTransfer.transfer_to.postcode}}, </span>
                            				<span  v-if="selectedTransfer.transfer_to.country !== null">{{selectedTransfer.transfer_to.country.name}} </span>
                            			</p>

                            			
                            		</b-card>
                            		<b-list-group>
                            				<b-list-group-item v-for="product in selectedTransfer.products" class="d-flex w-100">
                            					<div v-if="product.product.cover_image !== null">
                            						<img v-if="'src' in product.product.cover_image" :src="storageurl + product.product.cover_image.thumb" width="50">
                            						<img v-else-if="'gallery' in product.product.cover_image" :src="storageurl + product.product.cover_image.thumb" width="50">
                            					</div>
                            					<div class="d-flex flex-column justify-content-center pl-2">
	                            					<span>#{{product.product.reference}}</span>
	                            					<div class="d-flex">
		                            					<span class="w-75 text-truncate">{{product.product.name}}</span>
		                            					<span class="ml-auto">QTY {{product.quantity}}</span>
	                            					</div>
                            					</div>
                            				</b-list-group-item>
                            			</b-list-group>
                            	</b-card>
                            	<a class="btn-block btn-rounded btn btn-light text-danger mb-2"  @click="approveTransfer(false)">Deny Transfer Request<i class="bx bx-x-circle"></i></a>
                            		<a class="btn-block btn-rounded btn btn-light text-success" @click="approveTransfer(true)">Accept Transfer Request<i class="bx bx-check-circle"></i></a>
                            	</b-overlay>
                            </b-modal>
          </b-tab>
					<b-tab @click="getTopSellersData(), getTopProductsData()">
						<template v-slot:title>
                              <span class="d-inline-block d-none">
                               <!--<i class="bx bx-history font-size-16"></i>-->
                              </span>
                              <span class="d-sm-inline-block"> Stats</span>
                            </template>
                            <b-card class="border-radius-1em shadow-sm mt-3">
                            	<b-row>
                            		 <div class="col-xl-12">
            <div class="card border-radius-1em shadow-none border">
                <div class="card-body">
                    <div class="clearfix">
                        
                        <h4 class="card-title mb-4">Shop Sales</h4>
                    </div>

                    <div class="row">
                        <div class="col-lg-7">
                            <div class="text-muted">
                                <div>
                                    <p>From {{ordersstartdate | luxon}} to {{ordersenddate | luxon}}</p>
                                </div>
                            </div>
                            <div class="">
                            <div class="input-group input-group mr-5">
                                <b-form-input type="date" v-model="ordersstartdate">
                                </b-form-input>
                
                                <b-form-input type="date" v-model="ordersenddate">
                                </b-form-input>
                                <a class="btn btn-light btn-rounded" @click="loadShopOrders()">
                                    Update
                                </a>
                            </div>
                        </div>
                        <div class="row mt-2">
                        	<div class="col-md-3">
                            <div class="input-group input-group mr-5">
                                <b-form-group label="Chart Display">
                                
                                        <a class="btn btn-light btn-sm" @click="chartDisplay = 'by-order', compileOrderChart()"> 
                                            By Order
                                        </a>
                                        <a class="btn btn-light btn-sm" @click="chartDisplay = 'by-date', compileChartByDate()"> 
                                            By Date
                                        </a>
                                
                                </b-form-group>
                                
                            </div>
                        </div>
                        <div class="col-md-9">
                            <div class="input-group input-group mr-5">
                                	<b-form-group label="Date Range">
                                		<a class="btn btn-light btn-sm" @click="updateDates(1)">
                                			This Month
                                		</a>
                                		<a class="btn btn-light btn-sm" @click="updateDates(1)">
                                			Last Month
                                		</a>
                                		<a class="btn btn-light btn-sm" @click="updateDates(3)">
                                			Last 3 Months
                                		</a>
                                		<a class="btn btn-light btn-sm" @click="updateDates(6)">
                                			Last 6 Months
                                		</a>
                                		<a class="btn btn-light btn-sm" @click="updateDates(12)">
                                			Last 12 Months
                                		</a>
                                	</b-form-group>
                                </div>
                            </div>
                        </div>
                        </div>
                                        <div class="col-lg-3 text-right">
                        	<h4  v-if="(orderDataLoading == false)">{{companyCurrency}}{{parseFloat(shopOrders[0].total).toFixed(2)}}</h4>
                                   
                                    <div v-if="orderDataLoading == false"><span class="badge font-size-12 mr-1" :class="{'badge-soft-success' : (((parseFloat(lineChart.series[0].data[lineChart.series[0].data.length - 1]) - parseFloat(shopOrders[1].total)) / parseFloat(shopOrders[1].total)) * parseFloat(100)).toFixed(2) > 0, 'badge-soft-danger': (((parseFloat(lineChart.series[0].data[lineChart.series[0].data.length - 1]) - parseFloat(shopOrders[1].total)) / parseFloat(shopOrders[1].total)) * parseFloat(100)).toFixed(2) < 0}"> 
                                    <span v-if="(((parseFloat(lineChart.series[0].data[lineChart.series[0].data.length - 1]) - parseFloat(shopOrders[1].total)) / parseFloat(shopOrders[1].total)) * parseFloat(100)).toFixed(2) > 0">+</span>{{(((parseFloat(shopOrders[0].total) - parseFloat(shopOrders[1].total)) / parseFloat(shopOrders[1].total)) * parseFloat(100)).toFixed(2) }} %</span> From previous period</div>
                                     <b-spinner v-else variant="primary" type="grow">
                                     </b-spinner>
                        </div>
                         <div class="col-lg-2 text-right">
                         	<div >
                                    
                                    <h4 v-if="orderDataLoading == false">{{companyCurrency}}{{shopOrders[1].total.toFixed(2)}}</h4>
                                    <b-spinner v-else variant="primary" type="grow">
                                     </b-spinner>
                                     <p class="mb-2">Previous Period</p>
                                </div>
                         </div>
                        <div class="col-lg-12">
                            <apexchart v-if="orderDataLoading == false" class="apex-charts" :options="lineChart.chartOptions" :series="lineChart.series" dir="ltr" height="460"/>
                            <b-spinner v-else variant="primary" type="grow">
                            </b-spinner>
                        </div>
                    </div>
                </div>
            </div>
        </div>
                            	</b-row>
                            </b-card>
                            <b-card class="border-radius-1em shadow-sm mt-3">
                            	 <b-row>
            
       <div class="col-xl-12">
            <div class="card border-radius-1em shadow-none">
                <div class="card-body">
                    <div class="clearfix">
                        <div class="float-right">
                            <div class="input-group input-group-sm">
                                <select class="custom-select custom-select-sm">
                                    <option selected>Jan</option>
                                    <option value="1">Dec</option>
                                    <option value="2">Nov</option>
                                    <option value="3">Oct</option>
                                </select>
                                <div class="input-group-append">
                                    <label class="input-group-text">Month</label>
                                </div>
                            </div>
                        </div>
                        <h4 class="card-title mb-4">Top Salespeople</h4>
                    </div>

                    <div class="card text-muted text-center border border-radius-1em pb-4 shadow-none mb-2" v-if="topsellersdata.length > 0">
                        <div class="w-100 bg-light bg-soft" style="height: 100px">

                        </div>
                        <b-card-body class="card-body pt-0">
                            <div class="row">
                              <div class="col-sm-12 text-center">
                                <div class="avatar-md profile-user-wid mb-2 mx-auto" style="margin-top: -45px !important;">
                        <img v-if="topsellersdata[0].user.avatar !== null" :src="storageurl+topsellersdata[0].user.avatar" class="avatar-md rounded-circle mx-auto">
                        <img v-else src="/images/product-placeholder.png" class="avatar-md rounded-circle mx-auto">
                       
                                </div>
                            </div>
                            <div class="col-md-12 text-center">
                                <span class="badge badge-primary bg-light text-warning">
                                            #1 Top Seller <i class="bx bx-star"></i>
                                        </span>
                        <h5 class="mt-2 mb-0">{{topsellersdata[0].user.name}} {{topsellersdata[0].user.lastname}}</h5>
                        <p class="text-muted"> 
                            {{topsellersdata[0].user.email}}
                        </p>
                                 <h4>{{companyCurrency}}{{(topsellersdata[0].sales_sum_total_order_inc).toFixed(2)}}</h4>
                        <p class="mt-4 mb-0"><a class="btn-sm btn btn-rounded btn-primary" target="_blank" :href="'/company/user/'+topsellersdata[0].uid">
                                User Profile <i class="bx bx-right-arrow-alt"></i>
                            </a></p>
                            </div>
                        </div>
                    </b-card-body>
                    </div>
                    <b-card class="border-radius-1em border shadow-none mb-0" v-else style="min-height: 536px;" body-class="d-flex flex-column justify-content-center align-items-center">
                        <i class="bx bx-user-circle font-size-42 text-muted d-block"></i>
                        <h5 class="text-muted">No Top Sellers</h5>
                        <p>Start selling directly or via your Point of Sale now!</p>
                    </b-card>
                     <simplebar  v-if="topsellersdata.length > 0" style="height: 285px; max-height: 285px" class="border border-radius-1em mt-3">
                    <div class="table-responsive mt-4 mb-0">
                        <table class="table table-centered mb-0">
                            <tbody>
                                <tr v-for="product in topsellersdata">
                                    <td>
                                        <h5 class="font-size-14 mb-1">{{product.user.name}} {{product.user.lastname}}</h5>
                                        <p class="text-muted mb-0 text-truncate"></p>
                                    </td>

                                    <td>
                                        <!--<apexchart class="apex-charts" :options="ChartData.RadialChart1.chartOptions" :height="60" :width="60" :series="ChartData.RadialChart1.series" dir="ltr" />-->
                                    </td>
                                    <td>
                                        <p class="text-muted mb-1">Sold</p>
                                        <h5 class="mb-0">{{product.sales_count}}</h5>
                                    </td>
                                    <td>
                                        <p class="text-muted mb-1">Sales Value</p>
                                        <h5 class="mb-0">{{companyCurrency}}{{(product.sales_sum_total_order_inc).toFixed(2)}}</h5>
                                    </td>
                                </tr>
                               
                            </tbody>
                        </table>
                    </div>
                </simplebar>
                </div>
            </div>
        </div>
        <!--<div class="col-xl-12">
            <div class="card border-radius-1em shadow-none">
                <div class="card-body">
                    <div class="clearfix">
                        <div class="float-right">
                            <div class="input-group input-group-sm">
                                <select class="custom-select custom-select-sm">
                                    <option selected>Jan</option>
                                    <option value="1">Dec</option>
                                    <option value="2">Nov</option>
                                    <option value="3">Oct</option>
                                </select>
                                <div class="input-group-append">
                                    <label class="input-group-text">Month</label>
                                </div>
                            </div>
                        </div>
                        <h4 class="card-title mb-4">Top Salespeople</h4>
                    </div>

                    <div class="text-muted text-center" v-if="topsellersdata.length > 0">
                        <img v-if="topsellersdata[0].user.avatar !== null" :src="storageurl+topsellersdata[0].user.avatar" class="avatar-md rounded-circle mx-auto">
                        <img v-else src="/images/product-placeholder.png" class="avatar-md rounded-circle mx-auto">
                        <p class="mb-2">{{topsellersdata[0].user.name}} {{topsellersdata[0].user.lastname}}</p>
                        <h4>{{companyCurrency}}{{(topsellersdata[0].sales_sum_total_order_inc).toFixed(2)}}</h4>
                        <p class="mt-4 mb-0"><span class="badge badge-soft-success font-size-11 mr-2"> 0.6% <i class="mdi mdi-arrow-up"></i> </span> From previous period</p>
                    </div>
                    <b-card class="border-radius-1em border shadow-none mb-0" v-else style="min-height: 450px;" body-class="d-flex flex-column justify-content-center align-items-center">
                        <i class="bx bx-user-circle font-size-42 text-muted d-block"></i>
                        <h5 class="text-muted">No Top Sellers</h5>
                        <p>Start selling directly or via your Point of Sale now!</p>
                    </b-card>
                     <simplebar  v-if="topsellersdata.length > 0" style="height: 285px; max-height: 285px">
                    <div class="table-responsive mt-4 mb-0">
                        <table class="table table-centered mb-0">
                            <tbody>
                                <tr v-for="product in topsellersdata">
                                    <td>
                                        <h5 class="font-size-14 mb-1">{{product.user.name}} {{product.user.lastname}}</h5>
                                        <p class="text-muted mb-0 text-truncate"></p>
                                    </td>

                                    <td>
                                        
                                    </td>
                                    <td>
                                        <p class="text-muted mb-1">Sold</p>
                                        <h5 class="mb-0">{{product.orders_sum_quantity}}</h5>
                                    </td>
                                    <td>
                                        <p class="text-muted mb-1">Sales Value</p>
                                        <h5 class="mb-0">{{companyCurrency}}{{(product.sales_sum_total_order_inc).toFixed(2)}}</h5>
                                    </td>
                                </tr>
                               
                            </tbody>
                        </table>
                    </div>
                </simplebar>
                </div>
            </div>
        </div>-->
      </b-row>
    </b-card>
    <b-card>
    	<b-row>
        <div class="col-xl-12">
            <div class="card border-radius-1em shadow-none">
                <div class="card-body">
                    <div class="clearfix">
                        <div class="float-right">
                            <div class="input-group input-group-sm">
                                <select class="custom-select custom-select-sm">
                                    <option selected>Jan</option>
                                    <option value="1">Dec</option>
                                    <option value="2">Nov</option>
                                    <option value="3">Oct</option>
                                </select>
                                <div class="input-group-append">
                                    <label class="input-group-text">Month</label>
                                </div>
                            </div>
                        </div>
                        <h4 class="card-title mb-4">Top Selling Products</h4>
                    </div>

                    <div v-if="topproductsdata.length > 0" class="text-muted text-center border border-radius-1em pb-4">
                        <div class="w-100 bg-light bg-soft" style="height: 100px">

                        </div>
                        <b-card-body class="card-body pt-0">
                            <div class="row">
                              <div class="col-sm-12 text-center">
                                <div class="avatar-md profile-user-wid mb-2 mx-auto" style="margin-top: -45px !important;">
                        <div v-if="topproductsdata[0].cover_image !== null" >
                        <img v-if="'thumb' in topproductsdata[0].cover_image"  :src="storageurl+topproductsdata[0].cover_image.thumb" class="avatar-md rounded-circle mx-auto">
                         <img v-else-if="'gallery' in topproductsdata[0].cover_image"  :src="storageurl+topproductsdata[0].cover_image.gallery" class="avatar-md rounded-circle mx-auto">
                          <img v-else-if="'src' in topproductsdata[0].cover_image"  :src="storageurl+topproductsdata[0].cover_image.src" class="avatar-md rounded-circle mx-auto">
                        </div>
                        <img v-else src="/images/product-placeholder.png" class="avatar-md rounded-circle mx-auto">
                    </div>
                </div>
            </div>
            <div class="row">
                              <div class="col-sm-12 text-center">
                        <span class="badge badge-primary bg-primary text-white">
                                            {{topproductsdata[0].reference}}
                                        </span>
                        <h5 class="mt-2 mb-0">{{topproductsdata[0].name}}</h5>
                        <p class="text-muted text-truncate" v-html="topproductsdata[0].short_description">
                        <h4>{{companyCurrency}}{{(topproductsdata[0].orders_sum_price_inc).toFixed(2)}}</h4>
                        <p class="mt-4 mb-0">
                            <a class="btn-sm btn btn-rounded btn-primary" target="_blank" :href="'/stock/product/'+topproductsdata[0].uid">
                                Product Details <i class="bx bx-right-arrow-alt"></i>
                            </a>
                        </p>
                    </div>
                </div>
            </b-card-body>
                    </div>
                     <b-card class="border-radius-1em border shadow-none mb-0" v-else style="min-height: 450px;" body-class="d-flex flex-column justify-content-center align-items-center">
                        <i class="bx bx-user-circle font-size-42 text-muted d-block"></i>
                        <h5 class="text-muted">No Top Products</h5>
                        <p>Import your stock, or add it via your stock list to start selling!</p>

                    </b-card>
                    <simplebar v-if="topproductsdata.length > 0" style="height: 285px; max-height: 285px" class="border border-radius-1em mt-3" >
                    <div class="table-responsive mb-0">
                        <table class="table table-centered mb-0">
                            <tbody>
                                <tr v-for="product in topproductsdata">
                                    <td>
                                        <span class="badge badge-primary bg-primary text-white">
                                            {{product.reference}}
                                        </span>
                                        <h5 class="font-size-14 mb-1">{{product.name}}</h5>
                                        <p class="text-muted mb-0 text-truncate"></p>
                                    </td>

                                    <td>
                                        <!--<apexchart class="apex-charts" :options="ChartData.RadialChart1.chartOptions" :height="60" :width="60" :series="ChartData.RadialChart1.series" dir="ltr" />-->
                                    </td>
                                    <td>
                                        <p class="text-muted mb-1">Sold</p>
                                        <h5 class="mb-0">{{product.orders_sum_quantity}}</h5>
                                    </td>
                                    <td>
                                        <p class="text-muted mb-1">Sales Value</p>
                                        <h5 class="mb-0">{{companyCurrency}}{{(product.orders_sum_price_inc).toFixed(2)}}</h5>
                                    </td>
                                </tr>
                               
                            </tbody>
                        </table>
                    </div>
                </simplebar>
                </div>
            </div>
        </div>
              </b-row>
                          </b-card>
					</b-tab>
					<b-tab>

						<template v-slot:title>
                              <span class="d-inline-block d-none">
                               <!--<i class="bx bx-history font-size-16"></i>-->
                              </span>
                              <span class="d-sm-inline-block"> ePOS Settings</span>
                            </template>
                            <b-overlay :show="loadingEposSettings">
                            <b-card class="border-radius-1em shadow-sm mt-3">
				                            	<div class="media" v-if="shopData.stripe_id !== ('' || null)">
				                            		<div class="media">
				                            			<i class="mdi mdi-check-circle text-success font-size-42"></i>
				                            		</div>
				                            		<div class="media-body my-auto ml-2">
					                            		<h5 class="mb-0">Location is Enabled for Reader Payments</h5>
					                            		<p class="mb-0">Your location {{shopData.stripe_id}} is registered for use with Gemesys terminals / card readers for processing ePOS transactions</p>
					                            		
				                            		</div>
				                            		<a class="btn btn-primary btn-rounded my-auto" href="javascript:;" v-b-modal.add-reader>
				                            			Add Card Terminal to Locaion <i class="mdi mdi-plus"></i>
				                            		</a>
				                            		
				                            	</div>
				                            	<div class="media" v-else>
				                            			<div class="media">
				                            			<i class="mdi mdi-close-circle text-danger font-size-42"></i>
				                            		</div>
				                            		<div class="media-body my-auto ml-2">
				                            		<h5 class="mb-0">Location is Not Enabled for Reader Payments</h5>

				                            		<p class="mb-1">Your location {{shopData.stripe_id}} is NOT registered for use with Gemesys terminals / card readers for processing ePOS transactions. To add card readers to your location, please register this location first</p>
				                            	
				                            		</div>
				                            			<a class="btn btn-primary btn-rounded my-auto" href="javascript:;" @click="registerStripeLocation(shopData)">
				                            			Register This Location <i class="bx bx-buildings"></i>
				                            		</a>
				                            	</div>
				                          </b-card>

				                          <b-modal id="add-reader" centered size="lg" hide-footer title="Add New Reader">
				                          		<div>
				                          			<b-overlay :show="loadingEposSettings">
														<b-card class="border-radius-1em border shadow-none">
															<b-form-group class="mt-2" label="Reader Name" description="Provide a memorable name for your reader and label it appropriately with the same name used here.">
																						<b-form-input type="text" v-model="addreader.name">
																						</b-form-input>
																					</b-form-group>
																					<b-form-group class="mt-2" label="Registration Code">
																						<b-form-input type="text" v-model="addreader.registration_code">
																						</b-form-input>
																					</b-form-group>
																				
																					<a class="btn btn-success btn-rounded" @click="registerReader()">
																						Register Reader
																					</a>
														</b-card>
													</b-overlay>
												</div>
				                          </b-modal>
				                          <b-modal id="add-printer" centered size="lg" hide-footer title="Add New Printer">
				                          		<div>
				                          			<b-overlay :show="loadingEposSettings">
				                          				<b-row>
				                          					<b-col md="12" class="my-3">
              <b-card class="shadow-none border-radius-1em border">
              
                <b-form-group label="Name">
                  <b-form-input type="text" name="name" v-model="printer.name"></b-form-input>
                </b-form-group>
              
              </b-card>
              </b-col>
				<b-col md="12">
                <b-card class="shadow-none border-radius-1em border">
               			<SearchCompanyLocation :api_token="user.api_token" @listing-selected="selectPrinterLocation"></SearchCompanyLocation>
                    </b-card>
              </b-col>
				<b-col md="12">
                <b-card class="shadow-none border-radius-1em border">
                  <b-form-group label="Format Type">
                    <b-form-select class="form-control" v-model="printer.type" :options="['Address Label', 'Product Label', 'Receipts']">
                    </b-form-select>
                  </b-form-group>
                  <b-form-group label="Connection Type">
                    <b-form-select class="form-control" v-model="printer.connection_type" :options="['WLAN/LAN']">
                    </b-form-select>
                  </b-form-group>

                  <div v-if="printer.connection_type == 'WLAN/LAN' && printer.type == 'Receipts'">

                    <b-form-group label="Printer Model" >
                        <b-form-select class="form-control" v-model="printer.model" :options="['Star Micronics mC-Print2', 'Star Micronics mC-Print3']">
                        </b-form-select>
                    </b-form-group>
                    <b-card class="bg-light">
                      <i class="bx bx-warning font-size-42 text-muted"></i>
                      <h3>Link Your Computer to Network</h3>
                      <p>You need to link your printer to your local network via LAN or WLAN</p>
                      <a class="btn btn-info" v-if="printer.model !== ''"  :href="(printer.model == 'Star Micronics mC-Print2' ? 'https://www.star-m.jp/products/s_print/mcprint2/manual/en/settings/settingsCloudPRNT.htm' : 'https://www.star-m.jp/products/s_print/mcprint3/manual/en/settings/settingsCloudPRNT.htm' )">
                        Printer Documentation
                      </a>
                    </b-card>
                    <b-form-group label="Mac Address">
                      <b-form-input v-model="printer.mac" type="text" placeholder="00:00:11:23:GB:10">

                      </b-form-input>
                    </b-form-group>
                  </div>

                  <!--<a class="btn btn-primary mt-2 btn-rounded" @click="connectToDeviceAndSubscribeToUpdates()">
                    Find Bluetooth Printer
                  </a>
                  <a class="btn btn-primary mt-2 btn-rounded" @click="connectToDeviceAndSubscribeToUSB()">
                    Find USB Printer
                  </a>-->
                </b-card>
                <a class="btn btn-success btn-rounded btn-block" @click="createPrinter()">
                  Save <i class="bx bx-save"></i>
                </a>
              </b-col>
				                          				</b-row>
				                          			</b-overlay>
				                          		</div>
				                          </b-modal>
                            	<b-tabs pills vertical>
                            		
				                    <b-tab>
										<template v-slot:title>
				                              <span class="d-inline-block d-none">
				                               <!--<i class="bx bx-history font-size-16"></i>-->
				                              </span>
				                              <span class="d-sm-inline-block"> Terminals</span>
				                            </template>
				                           
				                           <b-card class="border-radius-1em shadow-sm">
				                            	<b-list-group v-if="shopData.stripe_terminals.length > 0">
				                            			<b-list-group-item class="d-flex" v-for="(reader, index) in shopData.stripe_terminals" >
																								<img v-if="reader.name.includes('simulated')" class="bx bx-card-reader d-block mr-4" width="50" src="https://stripe-images.s3.amazonaws.com/terminal/hardware_skus/WisePOS-E.png">
														                   						
																								<span class="my-auto mr-auto text-capitalize">
																								{{reader.name.replaceAll('_', ' ')}}
																								</span>
																								<span class="my-auto">Serial No.</span>
																								<span class="badge badge-light bg-light rounded-3 my-auto mr-auto ml-2 font-size-14" v-b-tooltip.hover title="Click to Reveal Serial No.">xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxxx</span>
																							
																								<a class="btn btn-danger btn-sm btn-rounded ml-3 my-auto" @click="destroyStripeReader(reader, index)">
																									Remove Reader
																								</a>

																							</b-list-group-item>
				                            	</b-list-group>
				                            	<div v-else class="text-center">
				                            		<i class="bx bx-credit-card font-size-42 text-primary mt-3"></i>
				                            		<p class="mb-3">No Readers Registered to this Location</p>

				                            		<a class="btn btn-primary btn-rounded my-auto" href="javascript:;" v-b-modal.add-reader>
				                            			Add Card Terminal <i class="mdi mdi-plus"></i>
				                            	</a>

				                            	</div>
				                          </b-card>
									</b-tab>
									<b-tab>
										<template v-slot:title>
				                              <span class="d-inline-block d-none">
				                               <!--<i class="bx bx-history font-size-16"></i>-->
				                              </span>
				                              <span class="d-sm-inline-block"> Registers</span>
				                            </template>
				                         <b-card class="border-radius-1em shadow-sm mt-3">
				                          </b-card>
				                    </b-tab>
									<b-tab>
										<template v-slot:title>
				                              <span class="d-inline-block d-none">
				                               <!--<i class="bx bx-history font-size-16"></i>-->
				                              </span>
				                              <span class="d-sm-inline-block"> Printers</span>
				                            </template>
				                           

				                              <b-card class="border-radius-1em shadow-sm mt-3">

				                            	<b-list-group v-if="shopData.stripe_terminals.length > 0">
												</b-list-group>
				                            	<div v-else class="text-center">
				                            		<i class="bx bx-credit-card font-size-42 text-primary mt-3"></i>
				                            		<p class="mb-3">No Printers Registered to this Location</p>
				                            		<a class="btn btn-primary btn-rounded my-auto" href="javascript:;" v-b-modal.add-printer>
				                            			Add Printer<i class="mdi mdi-plus"></i>
				                            	</a>
				                            	</div>


				                            	
				                          </b-card>
									</b-tab>
                            	</b-tabs>
                          </b-overlay>
                    </b-tab>
					
				</b-tabs>

			</b-col>
		</b-row>
	</Layout>

</template>