/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require("./bootstrap");
import Vue from "vue";
window.Vue = Vue;

import { BootstrapVue } from "bootstrap-vue";
import { FormRatingPlugin } from 'bootstrap-vue'
import vClickOutside from "v-click-outside";
import VueMask from "v-mask";
import Vuelidate from "vuelidate";
import VueApexCharts from "vue-apexcharts";
import * as VueGoogleMaps from "vue2-google-maps";
import VueSweetalert2 from "vue-sweetalert2";
import i18n from "./websitei18n";
import VueLuxon from "vue-luxon";
import VueRouter from 'vue-router';
import VueAwesomeSwiper from 'vue-awesome-swiper'
 import Homepage from './website-views/page';
 import About from './public-views/about';
 import Contact from './public-views/contact';
 import Sellers from './public-views/sellers';
 import Seller from './public-views/seller';
 import Product from './public-views/product';
 import CustomerAccount from './public-views/customer-account';
 import Category from './public-views/category';
 import Categories from './public-views/categories';
 import Order from './public-views/order';
 import Layout from './layouts/website';
 const routes = [
     {
     path: '/',
     name: 'layout',
     component: Layout,
     children: [
        //{ path: '/product/:id', component: Product, name: 'Product'},
        //{ path: '/my-account', component: CustomerAccount, name: 'CustomerAccount'},
        { path: '/order', component: Homepage, name: 'Order'},
        { path: '/order/:id', component: Homepage, name: 'Order'},
        { path: '/:root?/:child?/:subchild?/:subsubchild?/:subsubsubchild?', component: Homepage, name: 'Home' },
        //{ path: '/category/:id', component: Category, name: 'Category'},
        //{ path: '/about', component: About, name: 'About' },
        //{ path: '/contact', component: Contact, name: 'Contact' },
        { path: '/categories', component: Categories, name: 'Categories' },
        //{ path: '/category', redirect: '/categories' },
        //{ path: '/category/:id', component: Category, name: 'Category'},
        //{ path: '/sellers', component: Sellers, name: 'Sellers'},
        //{ path: '/seller/:id/profile', component: Seller, name: 'Seller'},
        
        ]
    }
];
 
Vue.use(VueRouter);
const router = new VueRouter({
    mode: 'history',
    routes
});

// import style
import 'swiper/swiper-bundle.min.css'

Vue.prototype.$isDev = process.env.MIX_APP_ENV !== "production";
Vue.config.devtools = Vue.prototype.$isDev;
Vue.config.debug = Vue.prototype.$isDev;
Vue.config.silent = !Vue.prototype.$isDev;
//import tinymce from "vue-tinymce-editor";
export const EventBus = new Vue();
import { VueReCaptcha } from 'vue-recaptcha-v3';
Vue.use(VueReCaptcha, { siteKey: '6LeQy8IUAAAAABtOrMfUczpCLmxyZ-GSJ4xghvyA' });

Vue.use(VueLuxon, {
    input: {
        zone: "gmt",
        format: "sql"
    },
    output: {
        zone: "local",
        format: {
            year: "numeric",
            month: "long",
            day: "numeric"
        },
        locale: null,
        relative: {
            round: true,
            unit: null
        }
    }
});
Vue.use(BootstrapVue);
Vue.use(vClickOutside);
Vue.use(VueMask);
Vue.use(Vuelidate);
Vue.use(require("vue-chartist"));
Vue.use(VueSweetalert2);
Vue.use(VueGoogleMaps, {
    load: {
        key: "AIzaSyAbvyBxmMbFhrzP9Z8moyYr6dCr-pzjhBE",
        libraries: "places"
    },
    installComponents: true
});
Vue.component("apexchart", VueApexCharts);
//Vue.component("tinymce", tinymce);
Vue.component(
    "website-component",
    require("./components/website-component").default
);

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */
/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

//import "./public-views";
import Layouts from "./mixins/websitelayouts.mixin";

const websiteapp = new Vue({
    el: "#websiteapp",
    mixins: [Layouts],
    router,
    i18n
});
