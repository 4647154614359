<template>
	<div>
		<p v-if="description == 'product-short-desc'" class="mt-3" v-html="product.short_description"></p>
		<p v-if="description == 'product-desc'" class="mt-3" v-html="product.long_description"></p>
	</div>
</template>

<script type="text/javascript">
	export default{
		name: 'ProductDesc',
		props: {
			product: {
				type: Object,
			},
			description: {
				type: String,
			}
		},
		mounted(){
			/*if(this.description == 'product-short-desc'){
				this.description = 'short';
			}else{
				this.description = 'long';
			}*/
		}
	}

</script>