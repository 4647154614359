<script>
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
import SideSlidePanel from "../../components/widgets/SideSlidePanel";
import vue2Dropzone from 'vue2-dropzone';
import Multiselect from "vue-multiselect";
import AddressLabel from "../../components/widgets/admin/print/AddressLabel";

export default{
	components: {
		Layout,
		PageHeader,
		SideSlidePanel,
		vue2Dropzone,
		Multiselect,
		AddressLabel
	},
	data(){
		return{
			addressLabel: '',
			printWindow: false,
			success_message: false,
			success_text: '',
			countries: [],
			counties: [],
			customerData: '',
			deleteAddressPanel: false,
			addNewPanel: false,
			loadingForm: false,
			error_message: false,
			error_reponse: '',
			addSupplierAddressPanel: false,
			deletesupplieraddress: false,
			contactsPanel: false,
		    addSupplierContactPanel: false,
		    deleteContactPanel: false,
			editsupplieraddress: '',
			newsupplieraddress:{
		        id: null,
		        alias: '',
		        address_1: '',
		        address_2: '',
		        address_3: '',
		        county: '',
		        country: '',
		        city: '',
		        postcode: '',
		        active: 0,
		        invoice_default: 0,
		        delivery_default: 0,
		        type: '',
		      },
		      editsupplieraddress: {
		        id: null,
		        alias: '',
		        address_1: '',
		        address_2: '',
		        address_3: '',
		        county: '',
		        country: '',
		        city: '',
		        postcode: '',
		        active: 0,
		        invoice_default: 0,
		        delivery_default: 0,
		        type: '',
		      },
		      newsuppliercontact:{
		        id: null,
		        name: '',
		        position: '',
		        phone: '',
		        email: '',
		        mobile: '',
		        fax: '',
		        default: 0,
		      },
		      editsuppliercontact: {
		        id: null,
		        name: '',
		        position: '',
		        phone: '',
		        email: '',
		        mobile: '',
		        fax: '',
		        default: 0,
		      },
		}
	},
	props: {
		id: {
			type: String,
			default: () => ''
		},
		user: {
			type: Object,
			default: () => ''
		},
		company: {
			type: Object,
			default: () => ''
		}
	},
	created(){
		this.loadCustomerData();
		this.getCountries();
	},
	mounted(){
		
	},
	methods: {
		loadCustomerData(){
			axios.get('/api/company/customer/'+this.id, { headers : {
				'Authorization': 'Bearer ' + this.user.api_token
			} }).then(response => {
				this.customerData = response.data;
				//return this.loadShopOrders(response.data.id);
			}).catch(error => {
				this.error_reponse = error;
			});
		},
		testAccountLink(type){
			axios.post('/api/comapny/customer/'+this.id+'/link-account/', {account_type: ''}, {headers: {'Authorization' : 'Bearer '+this.user.api_token}}).then(response => {

			}).catch(error => {

			});
		},
		getCountries(){
        if(this.countries.length == 0){
          axios.get('/api/countries', {headers: {
                  'Authorization': 'Bearer ' + this.user.api_token
              }}).then(response => {
            this.countries = response.data;
          }).catch(error => {
            this.load_error = error.data;
          })
        }
      },
      getCounties(country){
        // get counties as country selected
        axios.get('/api/country/'+country.id+'/counties', {headers: {
                  'Authorization': 'Bearer ' + this.user.api_token
              }}).then(response => {
          this.counties = response.data;
        }).catch(error => {
          this.load_error = error.data;
        })
      },
		addSupplierAddress(){
        this.loadingForm = true;
	      var postForm = new FormData(document.getElementById("addsupplieraddressform"));
	      postForm.append('country_id', this.newsupplieraddress.country.id);
	      if(this.newsupplieraddress.county !== ''){
	        postForm.append('county_id', this.newsupplieraddress.county.id);
	      }
	      axios.post('/api/company/customer/'+this.customerData.id+'/address/create', postForm, {headers: {
                  'Authorization': 'Bearer ' + this.user.api_token
              }}).then(response => {
	        console.log(response);
	        this.success_text = 'Congratulations, the supplier address has been added.';
	        this.success_message = true;
	        this.customerAddresses.push(response.data);
	        //this.resetProjectData();
	        //this.addPanel = false;
	        let self = this;
	          setTimeout(function(){
	            self.loadingForm = false;
	            self.addNewPanel = false;
	            self.productQuery();
	          }, 1500);
	      }).catch( error => {
	        
	        this.error_reponse = error.response.data.errors;
	        this.error_message = true;
	        //this.resetProjectData();
	        let self = this;
	          setTimeout(function(){
	            self.loadingForm = false;
	          }, 1500);
	      });
    },
    updateSupplierAddress(){
      this.loadingForm = true;
      
      var postForm = new FormData(document.getElementById("updatesupplieraddressform"));
      postForm.append('country_id', this.editsupplieraddress.country.id);
        if((this.editsupplieraddress.county !== null)) {
          postForm.append('county_id', this.editsupplieraddress.county.id);
        }
      axios.post('/api/company/customer/address/'+this.editsupplieraddress.id+'/update', postForm, {headers: {
                  'Authorization': 'Bearer ' + this.user.api_token
              }}).then(response => {
        console.log(response);
        this.success_text = 'Congratulations, the supplier address has been updated.';
        this.success_message = true;
        
        //this.addPanel = false;
        let self = this;
          setTimeout(function(){
            self.loadingForm = false;
            self.addNewPanel = false;
            self.productQuery();
            self.resetAddressData();
          }, 1500);
      }).catch( error => {
        
        this.error_reponse = error.response.data.errors;
        this.error_message = true;
        //this.resetProjectData();
        let self = this;
          setTimeout(function(){
            self.loadingForm = false;
          }, 1500);
      });
    },
    resetAddressData(){
        this.editsupplieraddress = {
        id: null,
        alias: '',
        address1: '',
        address2: '',
        address3: '',
        county: '',
        country: '',
        city: '',
        postcode: '',
        default: 0,
        invoice: 0,
        delivery: 0,
        type: '',
      };
    },
    destroySupplierAddress(id, index){
        axios.post('/api/company/customer/address/'+id+'/destroy', {headers: {
                  'Authorization': 'Bearer ' + this.user.api_token
              }}).then(response => {
            this.success_text = 'Congratulations, the supplier address has been deleted.';
            this.success_message = true;
        }).catch(error => {
            this.error_reponse = {error: ["Error - could not delete. Please try again."]};
            this.error_message = true;
        });
    },
    addSupplierContact(){
        this.loadingForm = true;
      var postForm = new FormData(document.getElementById("addsuppliercontactform"));
      axios.post('/api/company/customer/'+this.selectedListing.id+'/contact/create', postForm, {headers: {
                  'Authorization': 'Bearer ' + this.user.api_token
              }}).then(response => {
        console.log(response);
        this.success_text = 'Congratulations, the supplier contact has been added.';
        this.success_message = true;
        //this.resetProjectData();
        //this.addPanel = false;
        this.supplierContacts.push(response.data);
        let self = this;
          setTimeout(function(){
            self.loadingForm = false;
            self.addNewPanel = false;
            self.productQuery();
          }, 1500);
      }).catch( error => {
        
        this.error_reponse = error.response.data.errors;
        this.error_message = true;
        //this.resetProjectData();
        let self = this;
          setTimeout(function(){
            self.loadingForm = false;
          }, 1500);
      });
    },
    updateSupplierContact(){
           this.loadingForm = true;
      var postForm = new FormData(document.getElementById("updatesupplieraddressform"));
      axios.post('/api/company/customer/contact/'+this.editsuppliercontact.id+'/update', postForm, {headers: {
                  'Authorization': 'Bearer ' + this.user.api_token
              }}).then(response => {
        console.log(response);
        this.success_text = 'Congratulations, the supplier contact has been updated.';
        this.success_message = true;
        
        //this.addPanel = false;
        let self = this;
          setTimeout(function(){
            self.loadingForm = false;
            self.addNewPanel = false;
            self.productQuery();
            self.resetAddressData();
          }, 1500);
      }).catch( error => {
        
        this.error_reponse = error.response.data.errors;
        this.error_message = true;
        //this.resetProjectData();
        let self = this;
          setTimeout(function(){
            self.loadingForm = false;
          }, 1500);
      });
    },
    destroySupplierContact(id, index){
         axios.post('/api/company/customer/contact/'+id+'/destroy', {headers: {
                  'Authorization': 'Bearer ' + this.user.api_token
              }}).then(response => {
            this.success_text = 'Congratulations, the supplier contact has been deleted.';
            this.success_message = true;
        }).catch(error => {
            this.error_reponse = {error: ["Error - could not delete. Please try again."]};
            this.error_message = true;
        });
    },
	}
}


</script>

<template>
	<Layout>
		<PageHeader :title="title" :items="items" />
		<b-row>
			<b-col md="3">
				<b-card class="card overflow-hidden border-radius-1em shadow-sm seller-profile-card" no-body >
					<div class="bg-light" style="height: 75px;">
			            <div class="row">
			            </div>
			        </div>
					<b-card-body class="card-body pt-0">
		            <div class="row">
		              <div class="col-sm-12 pb-4 text-center">
		                <div class="avatar-md profile-user-wid mb-2 mx-auto" style="margin-top: -45px !important;">
		                  <img
		                  	v-if="customerData.avatar" :src="customerData.avatar"
		                    alt
		                    class="img-thumbnail rounded-circle"
		                  />
		                   <img
		                  	v-else 
		                    src="/images/product-placeholder.png"
		                    alt
		                    class="img-thumbnail rounded-circle"
		                  />
		                </div>
		                <h5 class="font-size-15 text-truncate">{{customerData.name}}</h5>
		                <a class="text-muted mb-0 text-truncate">{{customerData.email}}</a>
		              </div>
		              <div class="col-md-12">
		              	<span class="badge badge-light border-radius-1em bg-light text-dark font-size-11 lh-16 my-auto p-2">
		              		<i class="bx bx-buildings text-primary"></i> {{customerData.created_at | luxon({input: 'server'})}}
		              	</span>
		              	

		              </div>
		              
		            

		            </div>
		          </b-card-body>
				</b-card>
				<b-row>
				<b-col md="12" class="mb-3">
		       			<button class="btn btn-primary btn-soft btn-rounded btn-block">
		       				<div class="d-flex m-2">
								Message Customer  <i class="bx bx-message-alt font-size-20 ml-auto icon-label-end"></i>
									</div>
								</button>
							</b-col>
							<!--<b-col md="12" class="mb-3">
		       			<button class="btn btn-primary btn-soft btn-rounded btn-block">
		       				<div class="d-flex m-2">
								Address Label  <i class="bx bx-printer font-size-20 ml-auto icon-label-end"></i>
									</div>
								</button>
							</b-col>-->
						</b-row>
							</b-col>
			<b-col md="9">
				<b-row>
					<b-col>
						  <b-card class="border-radius-1em shadow-sm">
				        	<div class="d-flex align-items-center mb-3"><div class="avatar-xs mr-3"><span class="avatar-title rounded-circle bg-soft-primary text-white font-size-18"><i class="bx bx-copy-alt"></i></span></div> <h5 class="font-size-14 mb-0">Orders</h5></div> 
				        	<div class="text-muted mt-4">
				        		<h4 >9 <i class="mdi mdi-chevron-up ml-1 text-success"></i></h4>

				        	<div class="d-flex">
				        		<span class="badge badge-soft-success font-size-12"> 3 </span> <span class="ml-2 text-truncate">From previous period</span>
				        	</div>
				        	</div>
				        	
				        </b-card>
				    </b-col>
				    <b-col>
						  <b-card class="border-radius-1em shadow-sm">
				        	<div class="d-flex align-items-center mb-3"><div class="avatar-xs mr-3"><span class="avatar-title rounded-circle bg-soft-primary text-white font-size-18"><i class="bx bx-copy-alt"></i></span></div> <h5 class="font-size-14 mb-0">Orders</h5></div> 
				        	<div class="text-muted mt-4">
				        		<h4 >9 <i class="mdi mdi-chevron-up ml-1 text-success"></i></h4>

				        	<div class="d-flex">
				        		<span class="badge badge-soft-success font-size-12"> 3 </span> <span class="ml-2 text-truncate">From previous period</span>
				        	</div>
				        	</div>
				        	
				        </b-card>
				    </b-col>
				    <b-col>
						  <b-card class="border-radius-1em shadow-sm">
				        	<div class="d-flex align-items-center mb-3"><div class="avatar-xs mr-3"><span class="avatar-title rounded-circle bg-soft-primary text-white font-size-18"><i class="bx bx-copy-alt"></i></span></div> <h5 class="font-size-14 mb-0">Orders</h5></div> 
				        	<div class="text-muted mt-4">
				        		<h4 >9 <i class="mdi mdi-chevron-up ml-1 text-success"></i></h4>

				        	<div class="d-flex">
				        		<span class="badge badge-soft-success font-size-12"> 3 </span> <span class="ml-2 text-truncate">From previous period</span>
				        	</div>
				        	</div>
				        	
				        </b-card>
				    </b-col>
				 </b-row>
				<b-tabs pills justified content-class="pt-3">
					<b-tab title="Addresses" >
						<b-card class="border-radius-1em shadow-none border-none">
       <div class="row px-2">
        <transition name="slide-fadepanel">
          <div class="col-md-12 px-4" v-if="deleteAddressPanel == true">
            <p>You are about to delete {{deletesupplieraddress.alias}}. Are you sure?</p>
            <a class="btn btn-success" @click="destroySupplierAddress(deletesupplieraddress.id, deleteAddressIndex)">Confirm</a>
            <a class="btn btn-danger" @click="deleteAddressPanel = false, resetFeatureValue()">Cancel</a>
          </div>
        </transition>
        <div class="col-md-12">
            <ul class="list-group product-list w-100 no-shadow" v-if="(customerData.addresses.length !== 0) && (deleteAddressPanel == false)">
                              <li v-for="(address, index) in customerData.addresses" class="list-group-item d-flex px-0 py-0 border product-list-item mb-3 shadow-none">
                                <div class="row w-100 py-3">
                                  <div class="col-md-3 d-flex">
                                  <strong class="mr-auto my-auto ml-4 text-primary">{{address.alias}}</strong>
                                </div>
                                  <div class="col-md-6 d-flex">
                        <p class="text-muted italic no-margin d-inline my-auto">{{address.address_1}}, {{address.address_2}}, {{address.address_3}} <span v-if="address.county">{{address.county.name}}</span><span v-if="address.country">{{address.country.name}}</span> {{address.postcode}}</p>
                                </div>
                                <div class="col-md-3 d-flex">
                                	 <a class="btn btn-outline-light btn-rounded ml-auto my-auto mr-2" href="javascript:;" @click="addressLabel = address, addressLabel['name'] = customerData.name+' '+customerData.lastname, printWindow = true"><i class="px-2 bx bx-printer"></i></a>
                                  <a class="btn btn-outline-light btn-rounded my-auto mr-2" href="javascript:;" @click="getCounties(address.country), addSupplierAddressPanel = false, editsupplieraddress = address"><i class="px-2 bx bx-pencil"></i></a>
                                <a class="btn btn-outline-light btn-rounded my-auto" href="javascript:;" @click="deletesupplieraddress = address, deleteAddressIndex = index, deleteAddressPanel = true, addSupplierAddressPanel = false"><i class="py-1 px-2 bx bx-trash-alt"></i></a>
                              </div>
              
                              </div>
                              </li>
                              

                              <!--<li class="list-group-item py-4 text-center" ><a class="btn btn-outline-light btn-rounded">Load More</a>
                              </li>-->
                            </ul>
                <ul class="list-group product-list w-100 no-shadow" v-if="(customerData.addresses.length == 0) && (deleteAddressPanel == false)">
                    <li class="list-group-item d-flex">
                      <i class="bx bx-warning"></i> No Addresses Assigned to this supplier
                    </li> 
                </ul>
                 <a class="btn btn-primary mt-3 w-100 btn-rounded" @click="editsupplieraddress = {id: null}, addSupplierAddressPanel = true"><i class="bx bx-home-alt"></i> Add New Address <i class="mdi mdi-plus"></i></a>
        </div>
        <div class="col-md-12">
      <transition name="slide-fadepanel">
       <b-card class="shadow-none border-radius-1em border mt-3" v-if="(addSupplierAddressPanel == true)  && (deleteAddressPanel == false)">
          <h4 class="my-3">Add New Address</h4>
          <b-overlay
                        id="overlay-background"
                        :show="loadingForm"
                        variant="white"
                        opacity="0.85"
                        blur="1px"
                        rounded="md"
                      >
         <b-form id="addsupplieraddressform" method="POST" enctype="multipart/form-data">
                   <input type="hidden" name="_token" :value="csrf">
           <b-row>
                     <b-col md="12">
                    <transition name="slide-error" mode="out-in">
                    <b-alert id="errors" variant="danger" :show="error_message"  dismissible @dismissed="error_message = !error_message">
                      <p class="font-size-14">Oops! Please correct the following errors in your submission</p>
                      <ul>
                        <li v-for="(error, key) in error_reponse">
                          {{error[0]}}
                        </li>
                      </ul>
                    </b-alert>
                    
                  </transition>
                  </b-col>
          </b-row>
       
           <b-col md="12">
                <b-form-group  label="Address Alias/Name">
                  <b-form-input type="text" class="underline-input" name="alias" v-model="newsupplieraddress.alias" ></b-form-input>
                </b-form-group>
                <b-form-group class="mt-2" label="Address 1">
                  <b-form-input type="text" class="underline-input" name="address_1" v-model="newsupplieraddress.address_1" ></b-form-input>
                </b-form-group>
                <b-form-group class="mt-2" label="Address 2">
                  <b-form-input type="text" class="underline-input" name="address_2" v-model="newsupplieraddress.address_2" ></b-form-input>
                </b-form-group>
                <b-form-group class="mt-2" label="Address 3">
                  <b-form-input type="text" class="underline-input" name="address_3" v-model="newsupplieraddress.address_3" ></b-form-input>
                </b-form-group>
                      <b-form-group v-if="countries.length > 0" class="my-2" label="Country">
          <multiselect v-model="newsupplieraddress.country" track-by="name" label="name" :options="countries" :option-height="104" class="underline-select" @input="getCounties">
                     <!--<template slot="singleLabel" slot-scope="{ option }">{{ option.name }} - {{ option.jobtitle }}</template>-->
                     <template slot="singleLabel" slot-scope="{ option }"><div class="d-flex w-100 h-100">
                     <div class="avatar-xs mr-4 my-auto" v-if="newsupplieraddress.country.id !== ''">
                        <span class="avatar-title rounded-circle" >{{(option.iso_code).slice(0,2)}}</span>
                       
                      </div>
                      <div class="option__desc d-flex flex-row flex-grow-1"><span v-if="newsupplieraddress.country.id !== ''"  class="option__title ml-2 my-auto w-100">{{ option.name }}</span>
                        <span v-else class="option__title my-auto w-100">Select Country</span>

                      </div>
                      <div class="avatar-xs my-auto" v-if="newsupplieraddress.country.id == ''">
                      <span class="avatar-title rounded-circle bg-light text-primary"><i class="bx bx-chevron-down"></i></span>
                     </div>

                      </div></template>
                    <template slot="option" slot-scope="{ option }">
                      <div class="d-flex"><div class="avatar-xs mr-4">
                                        <span class="avatar-title rounded-circle">{{(option.iso_code).slice(0,2)}}</span>
                                      </div>
                      <div class="option__desc d-flex flex-row w-50"><span class="option__title ml-2 my-auto w-100">{{ option.name }}</span>
                        
                      </div>
                      </div>
                    </template>
                  </multiselect>
      </b-form-group>
      <b-form-group v-if="counties.length > 0" class="my-2"  label="County/State">
          <multiselect v-model="newsupplieraddress.county" track-by="name" label="name" :options="counties" :option-height="104" class="underline-select" >
                     <!--<template slot="singleLabel" slot-scope="{ option }">{{ option.name }} - {{ option.jobtitle }}</template>-->
                     <template slot="singleLabel" slot-scope="{ option }">
                     <div class="d-flex w-100 h-100">
                     <div class="avatar-xs mr-4 my-auto" v-if="newsupplieraddress.county.id !== ''">
                        <span class="avatar-title rounded-circle" >{{(option.name).slice(0,2)}}</span>
                       
                      </div>
                      <div class="option__desc d-flex flex-row flex-grow-1"><span v-if="newsupplieraddress.county.id !== ''"  class="option__title ml-2 my-auto w-100">{{ option.name }}</span>
                        <span v-else class="option__title my-auto w-100">Select State/County</span>

                      </div>
                      <div class="avatar-xs my-auto" v-if="newsupplieraddress.county.id == ''">
                      <span class="avatar-title rounded-circle bg-light text-primary"><i class="bx bx-chevron-down"></i></span>
                     </div>

                      </div></template>
                    <template slot="option" slot-scope="{ option }">
                      <div class="d-flex"><div class="avatar-xs mr-4">
                                        <span class="avatar-title rounded-circle">{{(option.name).slice(0,2)}}</span>
                                      </div>
                      <div class="option__desc d-flex flex-row w-50"><span class="option__title ml-2 my-auto w-100">{{ option.name }}</span>
                        
                      </div>
                      </div>
                    </template>
                  </multiselect>
      </b-form-group>
                <!--<b-form-group class="mt-2" label="Country">
                  <b-form-select class="form-control" v-model="newsupplieraddress.country_id" name="country_id"  @input="getCounties(newsupplieraddress.country_id)">
                    <b-form-select-option v-for="country in countries" :value="country.id">
                      {{country.name}}
                    </b-form-select-option>
                  </b-form-select>
                </b-form-group>
                <b-form-group class="mt-2" label="County/State">
                  <b-form-select class="form-control" name="county_id"  v-model="newsupplieraddress.county_id"  :disabled="(counties.length == 0)">
                    <b-form-select-option v-for="county in counties" :value="county.id" >
                      {{county.name}}
                    </b-form-select-option>
                  </b-form-select>
                </b-form-group>-->
                <b-form-group class="mt-2" label="City">
                  <b-form-input type="text" class="underline-input" v-model="newsupplieraddress.city" name="city"></b-form-input>
                </b-form-group>
                <b-form-group class="mt-2" label="Postcode/Zip">
                  <b-form-input type="text" class="underline-input" v-model="newsupplieraddress.postcode" name="postcode"></b-form-input>
                </b-form-group>
                   <div
                         class="form-check form-switch form-switch-sm mt-3 mb-2 ml-auto"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          :id="`SwitchCheckBilling`"
                          v-model="newsupplieraddress.invoice_default"
                          value="1"
                          unchecked-value="0"
                          name="invoice_default"
                        />
                        <label class="form-check-label" :for="`SwitchCheckBilling`" 
                          >Default Billing Address</label
                        >
                    </div>
                    <div
                        class="form-check form-switch form-switch-sm my-2 ml-auto"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          :id="`SwitchCheckDelivery`"
                          v-model="newsupplieraddress.delivery_default"
                          value="1"
                          unchecked-value="0"
                          name="delivery_default"
                        />
                        <label class="form-check-label" :for="`SwitchCheckDelivery`" 
                          >Default Delivery Address</label
                        >
                    </div>
                    <div
                        class="form-check form-switch form-switch-sm my-2 ml-auto"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          :id="`SwitchCheckDefault`"
                          v-model="newsupplieraddress.active"
                          value="1"
                          unchecked-value="0"
                          name="active"
                        />
                        <label class="form-check-label" :for="`SwitchCheckDefault`" 
                          >Active</label
                        >
                    </div>
               
              </b-col>
          <div class="d-flex flex-row mt-3">
          <b-button variant="success" class="w-md w-50 d-inline" @click="addSupplierAddress()"> <i class=" bx bx-save"></i> Save</b-button>
          <b-button variant="danger" class="w-md w-50 d-inline" @click="addSupplierAddressPanel = false"> <i class=" bx bx-save"></i> Cancel</b-button>
          </div>
        </b-form>
      </b-overlay>
        </b-card>
      </transition>
        </div>
        <div class="col-md-12">
      <transition name="slide-fadepanel">
       <b-card class="shadow-none border-radius-1em border mt-3" v-if="(editsupplieraddress.id !== null)  && (deleteAddressPanel == false)">
          <h4 class="my-3">Edit Address</h4>
          <b-overlay
                        id="overlay-background"
                        :show="loadingForm"
                        variant="white"
                        opacity="0.85"
                        blur="1px"
                        rounded="md"
                      >
         <b-form id="updatesupplieraddressform" method="POST" enctype="multipart/form-data">
                   <input type="hidden" name="_token" :value="csrf">
           <b-row>
                     <b-col md="12">
                    <transition name="slide-error" mode="out-in">
                    <b-alert id="errors" variant="danger" :show="error_message"  dismissible @dismissed="error_message = !error_message">
                      <p class="font-size-14">Oops! Please correct the following errors in your submission</p>
                      <ul>
                        <li v-for="(error, key) in error_reponse">
                          {{error[0]}}
                        </li>
                      </ul>
                    </b-alert>
                    
                  </transition>
                  </b-col>
          </b-row>
       
           <b-col md="12">
                <b-form-group  label="Address Alias/Name">
                  <b-form-input type="text" class="underline-input" name="alias" v-model="editsupplieraddress.alias" ></b-form-input>
                </b-form-group>
                <b-form-group  label="Address 1">
                  <b-form-input type="text" class="underline-input" name="address_1" v-model="editsupplieraddress.address_1" ></b-form-input>
                </b-form-group>
                <b-form-group class="mt-2" label="Address 2">
                  <b-form-input type="text" class="underline-input" name="address_2" v-model="editsupplieraddress.address_2" ></b-form-input>
                </b-form-group>
                <b-form-group class="mt-2" label="Address 3">
                  <b-form-input type="text" class="underline-input" name="address_3" v-model="editsupplieraddress.address_3" ></b-form-input>
                </b-form-group>
                            <b-form-group v-if="countries.length > 0" class="my-2" label="Country">
          <multiselect v-model="editsupplieraddress.country" track-by="name" label="name" :options="countries" :option-height="104" class="underline-select" @input="getCounties">
                     <!--<template slot="singleLabel" slot-scope="{ option }">{{ option.name }} - {{ option.jobtitle }}</template>-->
                     <template slot="singleLabel" slot-scope="{ option }"><div class="d-flex w-100 h-100">
                     <div class="avatar-xs mr-4 my-auto" v-if="editsupplieraddress.country.id !== ''">
                        <span class="avatar-title rounded-circle" >{{(option.iso_code).slice(0,2)}}</span>
                       
                      </div>
                      <div class="option__desc d-flex flex-row flex-grow-1"><span v-if="editsupplieraddress.country.id !== ''"  class="option__title ml-2 my-auto w-100">{{ option.name }}</span>
                        <span v-else class="option__title my-auto w-100">Select Country</span>

                      </div>
                      <div class="avatar-xs my-auto" v-if="editsupplieraddress.country.id == ''">
                      <span class="avatar-title rounded-circle bg-light text-primary"><i class="bx bx-chevron-down"></i></span>
                     </div>

                      </div></template>
                    <template slot="option" slot-scope="{ option }">
                      <div class="d-flex"><div class="avatar-xs mr-4">
                                        <span class="avatar-title rounded-circle">{{(option.iso_code).slice(0,2)}}</span>
                                      </div>
                      <div class="option__desc d-flex flex-row w-50"><span class="option__title ml-2 my-auto w-100">{{ option.name }}</span>
                        
                      </div>
                      </div>
                    </template>
                  </multiselect>
      </b-form-group>
      <b-form-group v-if="counties.length > 0" class="my-2"  label="County/State">
          <multiselect v-model="editsupplieraddress.county" track-by="name" label="name" :options="counties" :option-height="104" class="underline-select" >
                     <!--<template slot="singleLabel" slot-scope="{ option }">{{ option.name }} - {{ option.jobtitle }}</template>-->
                     <template slot="singleLabel" slot-scope="{ option }">
                     <div class="d-flex w-100 h-100">
                     <div class="avatar-xs mr-4 my-auto" v-if="editsupplieraddress.county.id !== ''">
                        <span class="avatar-title rounded-circle" >{{(option.name).slice(0,2)}}</span>
                       
                      </div>
                      <div class="option__desc d-flex flex-row flex-grow-1"><span v-if="editsupplieraddress.county.id !== ''"  class="option__title ml-2 my-auto w-100">{{ option.name }}</span>
                        <span v-else class="option__title my-auto w-100">Select State/County</span>

                      </div>
                      <div class="avatar-xs my-auto" v-if="editsupplieraddress.county.id == ''">
                      <span class="avatar-title rounded-circle bg-light text-primary"><i class="bx bx-chevron-down"></i></span>
                     </div>

                      </div></template>
                    <template slot="option" slot-scope="{ option }">
                      <div class="d-flex"><div class="avatar-xs mr-4">
                                        <span class="avatar-title rounded-circle">{{(option.name).slice(0,2)}}</span>
                                      </div>
                      <div class="option__desc d-flex flex-row w-50"><span class="option__title ml-2 my-auto w-100">{{ option.name }}</span>
                        
                      </div>
                      </div>
                    </template>
                  </multiselect>
      </b-form-group>
      <!--
                <b-form-group class="mt-2" label="Country">
                  <b-form-select class="form-control" v-model="editsupplieraddress.country_id" name="country_id"  @input="getCounties(editsupplieraddress.country_id)">
                    <b-form-select-option v-for="country in countries" :value="country.id">
                      {{country.name}}
                    </b-form-select-option>
                  </b-form-select>
                </b-form-group>
                <b-form-group class="mt-2" label="County/State">
                  <b-form-select class="form-control" name="county_id"  v-model="editsupplieraddress.county_id"  :disabled="(counties.length == 0)">
                    <b-form-select-option v-for="county in counties" :value="county.id" >
                      {{county.name}}
                    </b-form-select-option>
                  </b-form-select>
                </b-form-group>-->
                <b-form-group class="mt-2" label="City">
                  <b-form-input type="text" class="underline-input" v-model="editsupplieraddress.city" name="city"></b-form-input>
                </b-form-group>
                <b-form-group class="mt-2" label="Postcode/Zip">
                  <b-form-input type="text" class="underline-input" v-model="editsupplieraddress.postcode" name="postcode"></b-form-input>
                </b-form-group>
                    <div
                         class="form-check form-switch form-switch-sm mt-3 mb-2 ml-auto"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          :id="`SwitchCheckBilling`"
                          v-model="editsupplieraddress.invoice_default"
                          value="1"
                          unchecked-value="0"
                          name="invoice_default"
                        />
                        <label class="form-check-label" :for="`SwitchCheckBilling`" 
                          >Default Billing Address</label
                        >
                    </div>
                    <div
                        class="form-check form-switch form-switch-sm my-2 ml-auto"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          :id="`SwitchCheckDelivery`"
                          v-model="editsupplieraddress.delivery_default"
                          value="1"
                          unchecked-value="0"
                          name="delivery_default"
                        />
                        <label class="form-check-label" :for="`SwitchCheckDelivery`" 
                          >Default Delivery Address</label
                        >
                    </div>
                    <div
                        class="form-check form-switch form-switch-sm my-2 ml-auto"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          :id="`SwitchCheckDefault`"
                          v-model="editsupplieraddress.active"
                          value="1"
                          unchecked-value="0"
                          name="active"
                        />
                        <label class="form-check-label" :for="`SwitchCheckDefault`" 
                          >Active</label
                        >
                    </div>
               
              </b-col>
          <div class="d-flex flex-row mt-3">
          <b-button variant="success" class="w-md w-50 d-inline" @click="updateSupplierAddress()"> <i class=" bx bx-save"></i> Save</b-button>
          <b-button variant="danger" class="w-md w-50 d-inline" @click="editsupplieraddress = {id: null}"> <i class=" bx bx-save"></i> Cancel</b-button>
          </div>
        </b-form>
      </b-overlay>
        </b-card>
      </transition>
        </div>
      </div>
  </b-card>
					</b-tab>

					<b-tab title="Contacts">
						<b-card class="border-radius-1em border-none shadow-none">
							 <div class="row px-2">
				        
				         <transition name="slide-fadepanel">
				          <div class="col-md-12 px-4" v-if="deleteContactPanel == true">
				            <p>You are about to delete {{deletesuppliercontact.name}}. Are you sure?</p>
				            <a class="btn btn-success" @click="destroySupplierContact(deletesuppliercontact.id, deleteContactIndex)">Confirm</a>
				            <a class="btn btn-danger" @click="deleteContactPanel = false">Cancel</a>
				          </div>
				        </transition>
				        <div class="col-md-12">
				            <ul class="list-group product-list w-100 no-shadow" v-if="(customerData.contacts.length !== 0) && (deleteContactPanel == false)">
				                              <li v-for="(contact, index) in customerData.contacts" class="list-group-item d-flex px-0 py-0 border product-list-item mb-2 shadow-sm">
				                                <div class="d-flex product-list-item-container w-100">
				                                  <div class="mr-auto ml-2 my-auto flex-grow-1 mr-3 py-3">
				                                  <strong class="mr-auto my-auto ml-4 text-primary">{{contact.name}}</strong>
				                                  <span class="mx-4">{{contact.email}}</span>
				                                  <span class="mx-4">{{contact.phone}}</span>
				                                  <span class="mx-4">{{contact.mobile}}</span>
				                                  <span class="mx-4">{{contact.fax}}</span>
				                                </div>
				                                <div class="ml-auto my-auto flex-grow-2 mr-3 d-flex">
				                                  <a class="btn btn-outline-light btn-rounded ml-auto my-auto flex-grow-1 mr-2" href="javascript:;" @click="addSupplierContactPanel = false, editsuppliercontact = contact"><i class="px-2 bx bx-pencil"></i></a>
				                                <a class="btn btn-outline-light btn-rounded my-auto" href="javascript:;" ><i class="py-1 px-2 bx bx-trash-alt" @click="deletesuppliercontact = contact, deleteContactIndex = index, deleteContactPanel = true"></i></a>
				                              </div>
				              
				                              </div>
				                              </li>
				                              

				                              <!--<li class="list-group-item py-4 text-center" ><a class="btn btn-outline-light btn-rounded">Load More</a>
				                              </li>-->
				                            </ul>
				                <ul class="list-group product-list w-100 no-shadow" v-if="(customerData.contacts.length == 0) && (deleteContactPanel == false)">
				                    <li class="list-group-item d-flex">
				                      <i class="bx bx-warning"></i> No Addresses Assigned to this customer
				                    </li> 
				                </ul>
				                 <a class="btn btn-primary w-100 btn-rounded mt-3" v-if="(addSupplierContactPanel == false) && (deleteContactPanel == false)" @click="editsuppliercontact.id =  null, addSupplierContactPanel = true"><i class="bx bx-home-alt"></i> Add New Contact <i class="mdi mdi-plus"></i></a>
				        </div>
				        <div class="col-md-12">
				      <transition name="slide-fadepanel">
				       <b-card class="shadow-none border border-radius-1em mt-3" v-if="(addSupplierContactPanel == true) && (deleteContactPanel == false)">
				          <h4 class="my-3">Add New Contact</h4>
				          <b-overlay
				                        id="overlay-background"
				                        :show="loadingForm"
				                        variant="white"
				                        opacity="0.85"
				                        blur="1px"
				                        rounded="md"
				                      >
				         <b-form id="addsuppliercontactform" method="POST" enctype="multipart/form-data">
				                   <input type="hidden" name="_token" :value="csrf">
				           <b-row>
				                     <b-col md="12">
				                    <transition name="slide-error" mode="out-in">
				                    <b-alert id="errors" variant="danger" :show="error_message"  dismissible @dismissed="error_message = !error_message">
				                      <p class="font-size-14">Oops! Please correct the following errors in your submission</p>
				                      <ul>
				                        <li v-for="(error, key) in error_reponse">
				                          {{error[0]}}
				                        </li>
				                      </ul>
				                    </b-alert>
				                    
				                  </transition>
				                  </b-col>
				          </b-row>
				       
				           <b-col md="12">
				                <b-form-group  label="Name">
				                  <b-form-input type="text" name="name" v-model="newsuppliercontact.name" ></b-form-input>
				                </b-form-group>
				                <b-form-group  label="Position">
				                  <b-form-input type="text" name="position" v-model="newsuppliercontact.position" ></b-form-input>
				                </b-form-group>
				                <b-form-group class="mt-2" label="Email">
				                  <b-form-input type="text" name="email" v-model="newsuppliercontact.email" ></b-form-input>
				                </b-form-group>
				                <b-form-group class="mt-2" label="Phone">
				                  <b-form-input type="text" name="phone" v-model="newsuppliercontact.phone" ></b-form-input>
				                </b-form-group>
				                <b-form-group class="mt-2" label="Mobile">
				                  <b-form-input type="text" name="mobile" v-model="newsuppliercontact.mobile" ></b-form-input>
				                </b-form-group>
				                <b-form-group class="mt-2" label="Fax">
				                  <b-form-input type="text" name="fax" v-model="newsuppliercontact.fax" ></b-form-input>
				                </b-form-group>

				               
				              </b-col>
				          <div class="d-flex flex-row mt-3">
				          <b-button variant="success" class="w-md w-50 d-inline" @click="addSupplierContact()"> <i class=" bx bx-save"></i> Save</b-button>
				          <b-button variant="danger" class="w-md w-50 d-inline" @click="addSupplierContactPanel = false"> <i class=" bx bx-save"></i> Cancel</b-button>
				          </div>
				        </b-form>
				      </b-overlay>
				        </b-card>
				      </transition>
				        </div>
				        <div class="col-md-12">
				      <transition name="slide-fadepanel">
				       <b-card class="shadow-none" v-if="(editsuppliercontact.id !== null)  && (deleteContactPanel == false)">
				          <h4 class="my-3">Edit Address</h4>
				          <b-overlay
				                        id="overlay-background"
				                        :show="loadingForm"
				                        variant="white"
				                        opacity="0.85"
				                        blur="1px"
				                        rounded="md"
				                      >
				         <b-form id="updatesuppliercontactform" method="POST" enctype="multipart/form-data">
				                   <input type="hidden" name="_token" :value="csrf">
				           <b-row>
				                     <b-col md="12">
				                    <transition name="slide-error" mode="out-in">
				                    <b-alert id="errors" variant="danger" :show="error_message"  dismissible @dismissed="error_message = !error_message">
				                      <p class="font-size-14">Oops! Please correct the following errors in your submission</p>
				                      <ul>
				                        <li v-for="(error, key) in error_reponse">
				                          {{error[0]}}
				                        </li>
				                      </ul>
				                    </b-alert>
				                    
				                  </transition>
				                  </b-col>
				          </b-row>
				       
				           <b-col md="12">
				                <b-form-group  label="Name">
				                  <b-form-input type="text" name="name" v-model="editsuppliercontact.name" ></b-form-input>
				                </b-form-group>
				                <b-form-group  label="Position">
				                  <b-form-input type="text" name="position" v-model="editsuppliercontact.position" ></b-form-input>
				                </b-form-group>
				                <b-form-group class="mt-2" label="Email">
				                  <b-form-input type="text" name="email" v-model="editsuppliercontact.email" ></b-form-input>
				                </b-form-group>
				                <b-form-group class="mt-2" label="Phone">
				                  <b-form-input type="text" name="phone" v-model="editsuppliercontact.phone" ></b-form-input>
				                </b-form-group>
				                <b-form-group class="mt-2" label="Mobile">
				                  <b-form-input type="text" name="mobile" v-model="editsuppliercontact.mobile" ></b-form-input>
				                </b-form-group>
				                <b-form-group class="mt-2" label="Fax">
				                  <b-form-input type="text" name="fax" v-model="editsuppliercontact.fax" ></b-form-input>
				                </b-form-group>

				               
				              </b-col>
				          <div class="d-flex flex-row mt-3">
				          <b-button variant="success" class="w-md w-50 d-inline" @click="updateSupplierContact()"> <i class=" bx bx-save"></i> Save</b-button>
				          <b-button variant="danger" class="w-md w-50 d-inline" @click="editsuppliercontact = {id: null}"> <i class=" bx bx-save"></i> Cancel</b-button>
				          </div>
				        </b-form>
				      </b-overlay>
				        </b-card>
				      </transition>
				        </div>
				      </div>
						</b-card>
					</b-tab>

					<b-tab title="Orders">
						<b-card class="border-radius-1em shadow-none border">
							 <b-row>
             	<b-col md="2">
                <h5 class="d-flex">Shop Orders <i class="bx bx-right-arrow-circle text-primary font-size-20 ml-2"></i></h5>
                <h3 class="text-primary mt-4">0</h3>
                <small>Orders This Month</small>
                <hr>
                <h3 class="text-primary mt-4">0</h3>
                <small>Orders Last Month</small>
                <hr>
                <h3 class="text-primary mt-4">0</h3>
                <small>Total Orders</small>
                <hr>
                <!--
                <h3 class="text-primary mt-4" v-if="product.listings.length > 0">{{listingsTotalQuantities}}</h3>
                <small>Quantity Assigned to Shops</small>
                <hr>
              	-->
              </b-col>
              <b-col md="10">
             <b-card class="shadow-none border-radius-1em border" v-if="'orders' in customerData == false">
             	<div style="height: 300px;" class="w-100 d-flex justify-content-center align-items-center">
             		<div class="mx-auto my-auto text-center">
             			<i class="bx bx-shopping-bag font-size-42 text-muted d-block mx-auto pb-2"></i>
             			<span class="text-muted">
             				No Orders Listed
             			</span>
             		</div>
             	</div>
             </b-card>
             	<b-card class="shadow-none border-radius-1em border" v-else>
             <div class="table-responsive">
              <table class="table table-centered table-hover">

                <!--<thead class="thead-light">
                  <tr>
                    
                    <th scope="col"><a :class="{'text-dark': sortby != 'order_no'}" href="javascript: void(0);" @click="orderDesc = !orderDesc,  sortby = 'order_no', getProductOrders(product.id)">Order No. <i v-if="orderDesc == true" class="bx bx-caret-up"></i><i v-if="orderDesc == false" class="bx bx-caret-down"></i></a></th>
                    <th scope="col">Customer</th>
                    <th scope="col">Source</th>
                    <th scope="col">Date</th>
                    <th scope="col">Total Exc.</th>
                    <th scope="col">Total Inc.</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>-->
               
                <tbody class="widget-scrollbox" v-dragscroll  
                            style="max-height: 600px; display: table-caption; height: auto;">
                  
                  <tr v-for="list in customerData.orders" :key="list.id" valign="middle">
                    
                    <td>
                    	<i class="bx bx-calendar"></i> {{getDate(list.created_at)}} <br>
                      <a :href="'/order/details/'+list.uid+''"><span>#{{list.reference}}</span></a>
                      
                    </td>
                   
                     <td>
                       <div v-if="list.trade_orders.length > 0">
                       <span class="font-size-12 badge badge-light bg-light text-dark"  v-b-tooltip.hover title="Order from Trade Marketplace"><i class="bx bx-transfer-alt text-primary"></i> Trade Marketplace</span>
                      </div>
                      <div v-if="list.customer_orders.length > 0">
                        <span class="font-size-12 badge bg-primary bg-soft text-dark"  v-b-tooltip.hover title="Order from Gemesys Public Marketplace"><i class="bx bxs-user text-dark"></i> Public Marketplace</span>
                      </div>
                      <div v-if="list.website_orders.length > 0">
                        <span class="font-size-12 badge bg-peach-soft text-primary" v-b-tooltip.hover title="Order from Your Website"><i class="bx bx-globe text-primary"></i> Website</span>
                      </div>
                    </td>
                    <!--<td>
                      <a href="javascript:;" v-b-tooltip.hover title="Stock" placement="left" @click="selectedListing = list, getProducts(list.id), showListingProducts = 1" class="btn btn-outline-light btn-rounded"><i class="bx bx bx-purchase-tag-alt"></i></a>
                    </td>-->
                     
                    <td>
                      {{parseFloat(list.total_order_inc).toFixed(2)}} inc. VAT
                    </td>
                     <td class="text-center">
                      <i v-if="list.order_paid == 1" class="bx bx-check-circle text-success font-size-18 mx-auto"></i>
                      <i v-else class="bx bx-x-circle text-danger font-size-18 mx-auto"></i>
                    </td>
                    <td>
                      <div v-if="list.payments.length > 0">
                        <span v-if="list.payments[0].payment_type == 'card'" class="font-size-12 badge badge-soft-primary text-primary">
                          <i class="bx bx-credit-card"></i>
                          Card
                        </span>
                      </div>
                      <div v-else>
                         <span  class="font-size-12 badge badge-soft-danger text-danger">
                          <i class="bx bx-no-entry"></i> No Payment Made
                         </span>
                      </div>
                    </td>
                    <td>
                       <div v-if="list.status !== null">
                        <span class="badge bg-soft font-size-12" :style="{'background-color': list.status.color}" > {{list.status.name}}</span>
                       </div>
                    </td>
                  
                  </tr>
                </tbody>
              </table>
            </div>
         
          </b-card>
          </b-col>
        </b-row>
						</b-card>
					</b-tab>

					<b-tab title="Linked Accounts">
						<b-card class="border-radius-1em border-none shadow-none">
							<h4>Public Marketplace</h4>
							<b-card v-if="customerData.customer_id == null" class="border-radius-1em border shadow-none">
								<div class="d-flex">
								<img src="/images/logo-square-flat.png" width="120px">
									<div class="d-flex flex-column border-left">
										<h5>Not Linked</h5>
										<p>Customer Not Linked to any public marketplace account. Use the button to see if a public marketplace account exists for this user and request account link.</p>
										<a class="btn btn-light mr-auto" @click="testAccountLink('public')">
											Test Account Link
										</a>
									</div>
								</div>
								
							</b-card>
							<b-card v-else class="border-radius-1em border shadow-none">
								<div class="d-flex">
								<img src="/images/logo-square-flat.png" width="120px">
									<div class="d-flex flex-column border-left">
										<h5>Linked To</h5>
										<p>Customer Not Linked to any public marketplace account. Use the button to see if a public marketplace account exists for this user and request account link.</p>
										<a class="btn btn-light mr-auto"  @click="testAccountLink('website')">
											Unlink Account
										</a>
									</div>
								</div>
							</b-card>
							<h4>Website Customers</h4>
							<b-card v-if="customerData.website_customer_id == null" class="border-radius-1em border shadow-none">
								<div class="d-flex">
								<img src="/images/logo-web-flat.png" width="120px">
									<div class="d-flex flex-column border-left">
										<h5>Not Linked</h5>
										<p>Customer Not Linked to any public marketplace account. Use the button to see if a public marketplace account exists for this user and request account link.</p>
										<a class="btn btn-light mr-auto">
											Test Account Link
										</a>
									</div>
								</div>
							</b-card>
							<b-card v-else class="border-radius-1em border shadow-none">
								<h5>Linked to: </h5>
								<p></p>
								<a class="btn btn-light" >
									Unlink Accounts
								</a>
							</b-card>
						</b-card>
					</b-tab>
				</b-tabs>
			</b-col>
		</b-row>
		 <b-modal hide-footer size="xl" v-model="printWindow">
      <b-card no-body class="shadow-none border-none mb-0">
        <AddressLabel v-if="addressLabel !== ''" :address="addressLabel" :addresses="[]"></AddressLabel>
      </b-card>
    </b-modal>
	</Layout>
</template>
