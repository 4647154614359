<script type="text/javascript">
	import ProductsListWidgetProductCards from './ProductsListWidget-ProductCards';
	import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper';
	import SwiperClass, { Pagination } from 'swiper'
  import 'swiper/swiper.min.css'
  import { EventBus } from "../../../../../app";
		import VueSlider from 'vue-slider-component'
	//import FilterWebsiteProductCategories from '../../../../../components/widgets/FilterWebsiteProductCategories';
	export default{
		components:{
			ProductsListWidgetProductCards,
			Swiper,
		  SwiperSlide,
		  VueSlider,
		},
		directives: {
		    swiper: directive
		  },
		data(){
			return{
				swiperOptions: {
	           	direction: 'horizontal',
		          slidesPerView: 3,
		          spaceBetween: 10,
		          pagination: {
		            el: '.swiper-pagination',
		            clickable: true
		          },
		          navigation: {
		            nextEl: '.swiper-button-next',
		            prevEl: '.swiper-button-prev'
		          }
	        },
				loadingProducts: true,
				products: null,
				expanded: true,
				query: '',
				view: 'grid',
				layouttoggle: true,
				search: '',
				showFilters: false,
				current_page: 1,
				sliderPrice: 800,
		        priceRange: [0,250000],
		        brands: [],
		        collections: [],
		        categories: [],
		        sortby: 'created_at',
		        order: 'desc',
		        search: '',
		        view: 'grid',
		        public_active: 1,
		        trade_active: '',
		        active: '',
		        expanded: 0,
		        query: '',
		        limit: 25,
				limit: this.$attrs.filters.limit.value,
				limits: 
						[ 
							{value: this.$attrs.filters.limit.value, text: "16"},
		        	{value: this.$attrs.filters.limit.value * 2, text: "32"},
		        	{value: ((this.$attrs.filters.limit.value * 2) * 2), text: "64"}
		        ]
			}
		},
		created(){

			this.getProducts();
		},
		updated(){
			//this.getProducts();
		},
		mounted(){
			if(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'view')].view.value == 'carousel'){
				console.log('Current Swiper instance object', this.swiper);
	      this.swiper.slideTo(this.startSlide, 1000, false);
			}
		},
		computed: {
      swiper() {
        return this.$refs.imgGalleryCarousel.$swiper
      }
    },
		methods: {
			filterStylesKey(styles, key){
	            for(var i = 0; i < styles.length; i++){
	               var keys = Object.keys(styles[i])
	               if(keys.includes(key)){
	                 return i;
	               }
	            }
	        },
			getProducts(){
				this.createQueryString();
				this.loadingProducts = true;
				if(this.$attrs.liveMode == true){
					axios.get('/website-api/widget/products?page='+this.current_page+this.query).then(response => {
							this.products = response.data;
							let self = this;
						          setTimeout(function(){
						            self.loadingProducts = false;
						          }, 500);
						}).catch(error => {
							let self = this;
						          setTimeout(function(){
						            self.loadingProducts = false;
						          }, 500);
						});
				}else{
					axios.get('/api/company/web/page/widgets/products?page='+this.current_page+this.query, {headers: {'Authorization' : 'Bearer '+this.$attrs.user.api_token}}).then(response => {
						this.products = response.data;
						let self = this;
					          setTimeout(function(){
					            self.loadingProducts = false;
					          }, 500);
					}).catch(error => {
						let self = this;
					          setTimeout(function(){
					            self.loadingProducts = false;
					          }, 500);
					});
				}
			},
			createQueryString(){
				this.query = '';
				for(var key in this.$attrs.filters){
					if((key !== 'page') && (key !== 'search')){
						this.query += '&'+key+'='+this.$attrs.filters[key].value+'';
					}
					if(key == 'search'){
						if(this.$attrs.filters.search.value == null){
							this.query += '&'+key+'=';
						}else{
							this.query += '&'+key+'='+this.$attrs.filters[key].value+'';
						}
					}
					if(key == 'limit'){
						this.limits[0].value = this.$attrs.filters.limit.value;
						this.limits[0].text = this.$attrs.filters.limit.value;
						this.limits[1].value = this.$attrs.filters.limit.value * 2;
						this.limits[1].text = this.$attrs.filters.limit.value * 2;
						this.limits[2].value = (this.$attrs.filters.limit.value * 2) * 2;
						this.limits[2].text = (this.$attrs.filters.limit.value * 2) * 2;
					}
				}
			}
		}
	}

</script>

<template>
		<div class="py-4 mb-4 default-content products">
		<b-row v-if="($attrs.content[filterStylesKey($attrs.content, 'view')].view.value == 'grid') || ($attrs.content[filterStylesKey($attrs.content, 'view')].view.value == 'list')">
			<b-col class="col-md-12">
					<div class="row mb-3">
        
          <div class="col-lg-3 col-sm-6 d-flex">
          <form class="my-auto">
            <small class="mx-auto my-2 text-left d-block" v-if="products !== null">Results {{products.from}} - {{products.to}} of {{products.total}}</small>
          </form>
        </div>  
        <div class="col-lg-6 col-sm-6 d-flex">
            <div class="mt-sm-0 form-inline w-100" v-if="$attrs.elements[filterStylesKey($attrs.elements, 'show_search')].show_search.value == true" >
              <div class="app-search search-box mr-2 ml-auto">
                <div class="position-relative">
                  <input
                    type="text"
                    class="form-control w-100 border"
                    placeholder="Search name, reference or description..."
                    v-model="$attrs.filters.search.value"
                    @keyup.enter="getProducts()"
                  />
                  <span class="bx bx-search-alt search-icon"></span>
                </div>
              </div>
            </div>
          </div>

         
			
			<b-col md="3" class="d-flex">
				<ul class="nav nav-pills product-view-nav my-auto ml-auto d-flex" v-if="layouttoggle == true">
					<li class="nav-item  ml-auto mr-2" v-if="$attrs.elements[filterStylesKey($attrs.elements, 'allow_filters')].allow_filters.value == true">
                  <a class="nav-link" :class="{'active': showFilters == true }" href="javascript: void(0);" @click="showFilters = !showFilters, expanded = !expanded">
                    <i class="bx bx-filter-alt"></i>
                  </a>
                </li>
          		<!--<li class="nav-item px-1">
                  <a class="nav-link" :class="{'active': view == 'grid' }" href="javascript: void(0);" @click="view = 'grid'">
                    <i class="bx bx-grid-alt"></i>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" :class="{'active': view == 'list' }" href="javascript: void(0);" @click="view = 'list'">
                    <i class="bx bx-list-ul"></i>
                  </a>
                </li>-->
               </ul>
          	 <b-form-select v-if="$attrs.elements[filterStylesKey($attrs.elements, 'show_limits')].show_limits.value == true" class="form-control my-auto w-25 pl-1" v-model="$attrs.filters.limit.value" :options="limits" @input="getProducts"></b-form-select>
          </b-col>
		</div>
	</b-col>
			<b-col md="12" v-if="products !== null">
				<b-overlay :show="loadingProducts" no-wrap></b-overlay>
				<b-row v-if="$attrs.content[filterStylesKey($attrs.content, 'view')].view.value == 'grid'">
					<b-col :class="'col-xl-'+$attrs.content[filterStylesKey($attrs.content, 'columns')].columns.value.xl+' col-lg-'+$attrs.content[filterStylesKey($attrs.content, 'columns')].columns.value.lg+' col-md-'+$attrs.content[filterStylesKey($attrs.content, 'columns')].columns.value.md+' col-sm-'+$attrs.content[filterStylesKey($attrs.content, 'columns')].columns.value.sm+' col-xs-'+$attrs.content[filterStylesKey($attrs.content, 'columns')].columns.value.xs" v-for="(product, index) in products.data">
						<ProductsListWidgetProductCards :company="$attrs.company" :content="$attrs.content" :elements="$attrs.elements" :liveMode="$attrs.liveMode" :product="product" :index="index" :cardtype="'image'"></ProductsListWidgetProductCards>
					</b-col>
					<b-col md="12" v-if="products.data.length == 0">
						<b-card class="border-radius-1em shadow-none border py-4 text-center">
							<img src="/images/Management.png" class="img-fluid" style="max-width: 500px">
							<h1>We're Working On It!</h1>
							<p>There's no products here yet, but we're adding some - just watch this space!</p>
						</b-card>
					</b-col>
				</b-row>
				<b-row v-if="$attrs.content[filterStylesKey($attrs.content, 'view')].view.value == 'list'">
					<b-col md="12" v-for="(product, index) in products.data">
						<ProductsListWidgetProductCards :company="$attrs.company" :content="$attrs.content" :elements="$attrs.elements" :liveMode="$attrs.liveMode" :product="product" :index="index" :cardtype="'horizontal'"></ProductsListWidgetProductCards>
					</b-col>
				</b-row>
				
			</b-col>
			<b-col v-else>
				<b-card class="border-radius-1em shadow-none border py-4 text-center">
					<img src="/images/Management.png" class="img-fluid" style="max-width: 500px">
					<h1>We're Working On It!</h1>
					<p>There's no products here yet, but we're adding some - just watch this space!</p>
				</b-card>
			</b-col>
			<b-col md="12">
				<b-pagination v-if="(products !== null) && ($attrs.elements[filterStylesKey($attrs.elements, 'pagination')].pagination.value == true)" v-model="current_page" :total-rows="products.total" :per-page="products.per_page" :align="$attrs.elements[filterStylesKey($attrs.elements, 'pagination_alignment')].pagination_alignment.value" :class="$attrs.elements[filterStylesKey($attrs.elements, 'pagination_shape')].pagination_shape.value" @input="getProducts()"></b-pagination>
			</b-col>
		</b-row>
		<swiper v-else-if="$attrs.content[filterStylesKey($attrs.content, 'view')].view.value == 'carousel'" ref="productGalleryCarousel" :options="swiperOptions" class="overflow-hidden" >
        <swiper-slide v-for="(product, index) in products.data" class="h-100" >
              <ProductsListWidgetProductCards :company="$attrs.company" :content="$attrs.content" :elements="$attrs.elements" :liveMode="$attrs.liveMode" :product="product" :index="index" :cardtype="'image'"></ProductsListWidgetProductCards>
        </swiper-slide>
  	</swiper>
		<transition name="fade-left">
		<div v-if="showFilters == true" style="" class="filter-sidebar position-fixed w-100 bg-white top-0 left-0 bottom-0 shadow-lg">
			<b-row>
				<b-col md="12" class="px-4 pt-4 pb-1 d-flex">
					<a @click="showFilters = !showFilters" class="btn btn-outline-light btn-rounded ml-auto my-auto mr-3">
						<i class="mdi mdi-close font-size-20"></i>
					</a>
				</b-col>
				<b-col md="12" class="p-4">
					<div class="px-3">
					<div class="mt-sm-0 form-inline w-100" >
              <div class="app-search search-box mr-2 ml-auto">
                <div class="position-relative">
                  <input
                    type="text"
                    class="form-control w-100 border"
                    placeholder="Search name, reference or description..."
                    v-model="search"
                    @keyup.enter="getProducts()"
                  />
                  <span class="bx bx-search-alt search-icon"></span>
                </div>
              </div>
            </div>
           <div class="card p-4 rounded-4 shadow-sm border-radius-1em mt-4">
            <!--<h4 class="card-title mb-4">Filter</h4>-->
            <div>
               <div class="mb-3">
              <h5 class="mb-3">Price</h5>
              <vue-slider v-model="priceRange" :min="0" :max="100 + priceRange[1]"  @drag-end="loadCategory()" ></vue-slider>
              <b-form-group label="Min Price" class="w-50 d-inline float-left mt-2 mb-3 px-2">
              <b-form-input
                type="number"
                v-model="priceRange[0]"
                @input="loadCategory"></b-form-input>
              </b-form-group>
              <b-form-group label="Max Price" class="w-50 d-inline float-left mt-2 mb-3 px-2">
                 <b-form-input
                 label="Max Price"
                 type="number"
                 v-model="priceRange[1]"
                 @input="loadCategory"></b-form-input>
              </b-form-group>
            </div>
                <h5 class="my-3">Jewellery Type</h5>
                <!--<FilterListTree></FilterListTree>-->
                <b-overlay :show="loadingFilters">
                <FilterPublicProductCategories  v-if="loadingFilters == false" :category_data="categoriesdata" ref="categoryfilter"></FilterPublicProductCategories>
              </b-overlay>
                <h5 class="font-size-14 mb-3">Brands</h5>
               <FilterPublicProductBrands v-on:brands-filtered="filterBrands"></FilterPublicProductBrands>
                <h5 class="my-3">Gemstone</h5>
                <b-form-group>
                	<b-form-checkbox-group v-model="keywordslist" @change="loadCategory()" :options="productGemstones"></b-form-checkbox-group>
                </b-form-group>
                <h5 class="my-3">Metal</h5>
                <b-form-group>
                	<b-form-checkbox-group v-model="keywordslist"  @change="loadCategory()" :options="productMetals"></b-form-checkbox-group>
                </b-form-group>
                <h5 class="my-3">Condition</h5>
                <b-form-group>
                	<b-form-checkbox-group v-model="condition"  @change="loadCategory()" :options="productConditions"></b-form-checkbox-group>
                </b-form-group>
            </div>
           

          <!-- <div class="mt-4 pt-3">
              <h5 class="font-size-14 mb-3">Customer Rating</h5>
              <div>
                <b-form-checkbox
                  id="checkbox-1"
                  name="checkbox-1"
                  value="accepted"
                  unchecked-value="not_accepted"
                >
                  4
                  <i class="bx bx-star text-warning"></i> & Above
                </b-form-checkbox>

                <b-form-checkbox
                  id="checkbox-2"
                  class="mt-2"
                  name="checkbox-2"
                  value="accepted"
                  unchecked-value="not_accepted"
                >
                  3
                  <i class="bx bx-star text-warning"></i> & Above
                </b-form-checkbox>

                <b-form-checkbox
                  id="checkbox-3"
                  class="mt-2"
                  name="checkbox-3"
                  value="accepted"
                  unchecked-value="not_accepted"
                >
                  2
                  <i class="bx bx-star text-warning"></i> & Above
                </b-form-checkbox>
                <b-form-checkbox
                  id="checkbox-4"
                  class="mt-2"
                  name="checkbox-4"
                  value="accepted"
                  unchecked-value="not_accepted"
                >
                  1
                  <i class="bx bx-star text-warning"></i>
                </b-form-checkbox>
              </div>
            </div>-->
          </div>
          </div>
				</b-col>
			</b-row>
		</div>
	</transition>
	</div>
</template>