<script type="text/javascript">
	export default {
		data() {
			return {
				formSubmitting: false,
				success_message: false,
				error_message: false,
				form_result: '',
				form: {
					source: '',
					name: '',
					lastname: '',
					email: '',
					customer_id: '',
					company_id: '',
					product_id: '',
					service_id: '',
					share_details: '',
					review: '',
					message: '',
					review_score: 0,
					review_type: '',
				},
			}
		},
		props: {
			company_id: {
				type: Number,
			},
			product_id: {
				type: Number,
				default: 0,
			},
			service_id: {
				type: Number,
				default: 0,
			},
			source: {
				type: String,
			},
			api_token: {
				type: String,
			},
			user: {
				type: String,
			},
			review_type:{
				type: String,
			}
		},
		mounted(){
			if(this.review_type !== ''){
				this.form.review_type = this.review_type;
			}
			if(this.company_id !== ''){
				this.form.company_id = this.company_id;
			}
			if((this.product_id !== 0) && (this.product_id !== '')){
				this.form.product_id = this.product_id;
			}
			if((this.service_id !== 0) && (this.service_id !== '')){
				this.form.service_id = this.service_id;
			}
			if(this.source !== ''){
				this.form.source = this.source;
			}
			if(this.user !== ''){
				this.form.name = this.user.name;
				this.form.lastname = this.user.lastname;
				this.form.email = this.user.email;
				this.form.customer_id = this.user.id;
			}
		},
		methods: {
			postReview(){
				this.formSubmitting = true;
			 	var api_base = 'api/company';
			 	if(this.source == 'public'){
			 		api_base = 'customer-api';
			 	}
			 	if(this.source == 'website'){
			 		api_base = 'website-api';
			 	}
			 	/*if(this.source == 'trade'){
			 		api_base = 'api/company';
			 	}*/
				var query = '/'+api_base+'/review';
				if((this.product_id !== 0) && (this.product_id !== '')){
					query = '/'+api_base+'/product/review';
				}
				if((this.service_id !== 0) && (this.service_id !== '')){
					query = '/'+api_base+'/service/review';
				}
				if((this.review_type == 'company') && (this.source == 'public')){
					query = '/'+api_base+'/seller/review';
				}
				axios.post(query, this.form, {headers : { 'Authorization' : 'Bearer '+this.api_token }}).then(response => {
					this.success_message = true;
					this.form_result = response.data;
					let self = this;
					setTimeout(function(){
						this.formSubmitting = false;
					}, 500);
					
				}).catch(error => {
					this.error_reponse = true;
					this.form_result = response.data;

					let self = this;
					setTimeout(function(){
						this.formSubmitting = false;
					}, 500);
				});
			}
		}
	}

</script>
<template>
	<b-form class="px-2">
		<b-overlay :show="formSubmitting" variant="white" opacity="0.95">
		<div v-if="(form_result == '')">
		<b-form-group>
    		<b-form-input v-model="form.name" class="underline-input my-2" placeholder="First Name" type="text" :readonly="user !== ''"></b-form-input>
    	</b-form-group>
    	<b-form-group>
    		<b-form-input v-model="form.lastname" class="underline-input my-2" placeholder="Last Name" type="text" :readonly="user !== ''"></b-form-input>
    	</b-form-group>
    	<b-form-group>
    		<b-form-input v-model="form.email" class="underline-input my-2" placeholder="Email" type="text" :readonly="user !== ''"></b-form-input>
    	</b-form-group>
    	<b-form-group class="text-center">
    		 <label for="rating-lg-no-border" class="mt-3">Your Rating</label>
    			<b-form-rating id="rating-lg-no-border" variant="warning" v-model="form.review_score" class="text-center" no-border size="lg"></b-form-rating>
    	</b-form-group>
    	<b-form-group>
    		<b-form-textarea
    		class="underline-input my-2"
		      id="textarea"
		      placeholder="Enter review comments"
		      rows="4"
		      v-model="form.review"
		    ></b-form-textarea>
    	</b-form-group>
    	<a class="btn btn-outline-light btn-rounded btn-block mt-3" href="javascript:;" @click="postReview()">Submit Review</a>
    	</div>
    	<div v-else-if="(form_result !== '')">
    	<div class="py-3" v-if="(success_message == true)">
    		<svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
								  <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none"/>
								  <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
								</svg>
    		
    	</div>
    	<div class="py-3" v-if="(success_message == false) && (error_message == true)">
    		<svg class="checkmark checkmark-error" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
								  <circle class="checkmark__circle__error" cx="26" cy="26" r="25" fill="#feb346"/>
								     <rect data-v-0b26b296="" x="45" y="10" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -26.5097 64)" width="8" height="6" class="st0 checkmark__pending" style="
									    transform: translate(-16px, 25px) rotateY(30deg);
									    fill: white;
									"></rect> <rect data-v-0b26b296="" x="98" y="63" transform="translate(-62px, -52px) rotateY(30deg)" width="8" height="20" class="st0 checkmark__cross" style="
									    transform: translate(-62px, -54px) rotateY(30deg);
									    fill: white;
									"></rect>
								</svg>
    		
    	</div>
    	<b-card class="border-radius-1em border shadow-none" v-if="(success_message == true)">
    		<p v-html="form_result.name"></p>
    		<p v-html="form_result.lastname"></p>
    		<p v-html="form_result.email"></p>
    	
    	</b-card>
    	<b-card class="border-radius-1em border shadow-none" v-if="(success_message == true)">
    		 <label for="rating-lg-no-border" class="mt-3">Your Rating</label>
    			<b-form-rating id="rating-lg-no-border" variant="warning" v-model="form_result.rating" class="text-center" no-border size="lg"></b-form-rating>
    	</b-card>
    	<b-card class="border-radius-1em border shadow-none" v-if="(success_message == true)">
		    <p v-html="form_result.review"></p>
		    </b-card>
    	
    	</div>
    </b-overlay>
    </b-form>
</template>


<style scoped>

	.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #7ac142;
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}
.checkmark {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #fff;
  stroke-miterlimit: 10;
  margin: 2% auto;
  background: #7ac142;
  box-shadow: inset 0px 0px 0px #7ac142;
  animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
}
.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}
.checkmark__circle__error {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #F40101;
  fill: #F40101;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}
.checkmark.checkmark-error{
	margin-top: -30%;
}
.checkmark__cross {


  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}
@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes scale {
  0%, 100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}
@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px #7ac142;
  }
}
</style>