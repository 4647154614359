<template>
	<div :id="id">
		<div :id="id+'-title'" v-html="'<'+$attrs.content[filterStylesKey($attrs.content, 'title')].title[filterStylesKey($attrs.content[filterStylesKey($attrs.content, 'title')], 'heading_level')].heading_level.value+'>'+$attrs.content[filterStylesKey($attrs.content, 'title')].title[filterStylesKey($attrs.content[filterStylesKey($attrs.content, 'title')], 'heading_level')].value+'</'+$attrs.content[filterStylesKey($attrs.content, 'title')].title[filterStylesKey($attrs.content[filterStylesKey($attrs.content, 'title')], 'heading_level')].heading_level.value+'>'"></div>
		<div :id="id+'-text'" v-html="$attrs.content[filterStylesKey($attrs.content, '')].text.value"></div>
	</div>
</template>

<script type="text/javascript">
	
	export default{
		props: {
			id:{
				type: String
			}

		},
		mounted(){
			var h = document.getElementsByTagName('head').item(0);
			var f = document.createElement("style");
			f.type = "text/css"; 
			f.id = "customCss";
			h.appendChild(f);
			//this.compileFontStyles;
		},
		methods: {
			filterStylesKey(styles, key){
	            for(var i = 0; i < styles.length; i++){
	               var keys = Object.keys(styles[i])
	               if(keys.includes(key)){
	                 return i;
	               }
	            }
	        },
		},
		computed: {
			compileFontStyles(){
				var style = '';
				for(var key in this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'text')].text){
					if((key !== "active") || (key !== "value") || (key !== "units")){
						style += '#'+this.id+'-text '+key+'{';
						for(var skey in this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'text')].text[key]){
								if((skey !== 'active') || (skey !== 'value') || (skey !== 'units')){
									if(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'text')].text[key][skey].value !== ''){
										if(skey !== 'font_family'){
									style += ''+skey.replaceAll('_', '-')+': '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'text')].text[key][skey].value+''+this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'text')].text[key][skey].units+'; '
									}else{
										style += ''+skey.replaceAll('_', '-')+': \"'+this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'text')].text[key][skey].value.family+'\", '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'text')].text[key][skey].value.category+'; '
									}
								}
							}
						}
						style += '}\n';
					}
				}
				document.getElementById('customCss').innerHTML = style;
				
				return style;
			}
		}
	}
</script>
