<script type="text/javascript">

	import Layout from "../../layouts/pos";
	import simplebar from "simplebar-vue";
	import { EventBus } from '../../app'
	//import PageHeader from "../../components/page-header";
	import CompanyProductList from '../../components/widgets/CompanyProductList';
	import SearchCompanyLocation from '../../components/widgets/SearchCompanyLocation';
	import SearchResource from '../../components/widgets/SearchResource';
	import ProductList from '../../components/widgets/ecommerce/pos/ProductList';
	import SideSlidePanel from "../../components/widgets/SideSlidePanel";
	import StandardCalculator from "../../components/widgets/calculators/StandardCalculator";
	import { StreamBarcodeReader } from "vue-barcode-reader";
	import CompanyCustomerSearch from "../../components/widgets/CompanyCustomerSearch"
	import SearchCompanyCustomerAddress from "../../components/widgets/SearchCompanyCustomerAddress";
	import CompanyCustomerAddressForm from "../../components/widgets/forms/company/CompanyCustomerAddressForm";
	import OrderView from "../../components/widgets/ecommerce/pos/OrderView"
	import Swal from "sweetalert2";
	import DateTime from 'luxon/src/datetime.js';
	import QrcodeVue from 'qrcode.vue';
	import VueBarcode from '@chenfengyuan/vue-barcode';
	//import VueCryptojs from 'vue-cryptojs';

	export default{
		components: {Layout, CompanyProductList, SearchCompanyLocation, ProductList, simplebar, SideSlidePanel, StreamBarcodeReader,StandardCalculator, SearchResource, CompanyCustomerSearch, SearchCompanyCustomerAddress, CompanyCustomerAddressForm, OrderView, DateTime, QrcodeVue, VueBarcode},
		data(){
			return {
				loginStep: 0,
				printerDetails: null,
				printerJobs:[],
				editlineitem: false,
				lineitemnewprice: 0.00,
				signInToken: '',
				loginDetails: '',
				showKeyPad: false,
				qrData: {
			        type: 'order-receipt',
			        reference: '',
			        uid: '',
			        id: '',
			    },
			    qrDataRaw: {
			        type: 'order-receipt',
			        reference: '',
			        uid: '',
			        id: '',
			    },
			    switch_user: false,
			    loadingTransferForm: false,
			    transfer_response: '',
			    transfer_booking_success: false,
			    transfer: {
				    transfer_from: '',
					transfer_to: '',
					customer: '',
					products: '',
					date_required: '',
					is_booked: 0,
					customer_notifications: 0,
				},
				waiting_list_response: null,
				waiting_list: {
					product: '',
					customer: '',
					name: '',
					email: '',
					phone: '',
				},
				printingPanel: false,
			    settingsPanel: false,
			    searchboxPanel: false,
			    scanner_value: '',
			    scanner_progress: 0,
			    loadingForm: true,
				inthisstore: true,
				productsinthisstore: true,
				customerorders: '',
				posorders: '',
				websiteorders: '',
				tradeorders: '',
				searchorderpaid: '',
				searchpaidby: '',
				ordersstartdate: 0,
				ordersenddate: 0,
				orderslistingid: '',			
				scanner_busy: false,
				scanner_mode: 'camera',
				orderViewWindow: false,
				orders: [],
				selectedorder: '',
				orderscurrentPage: 1,
				connectionStatusModal: false,
				connectionStatusText: '',
				terminal_status: 'pending-connection',
				receipt_action: 'print',
				receipt_step: 0,
				receipt_image: '',
				payment_progress: 0,
				paymentSuccess: false,
				order_create_error: '',
				create_order_response: '',
				saved_cart: '',
				lineitemdetail: '',
				loadingPaymentMethodWindow: false,
				pendingPaymentIntentSecret: null,
				terminalIntent: null,
				connectionToken: '',
				loadingScanner: true,
				terminal: '',
				stripeAPIToken: 'pk_test_6TiGSpqR4BtSyY0Yua1T3ztE00FBgdU5xn',
				stripe:'',
				newCustomerWindow: false,
				terminalsRegistersForm: false, 
				existingCustomerWindow: false,
				cartsWindow: false,
				ordersWindow: false,
				selectedvariants: {},
				selectedvariantstotal: [0.00, 0.00],
				cartvariantstotal: [0.00, 0.00],
				selectedcombinationstotal: [0.00, 0.00],
				cartcombinationstotal: [0.00, 0.00],
				selectedcombination: '',
				customerView: false,
				customerListWindow: false,
				customerSelected: false,
				customerAddType: 'add',
				menuShown: false,
				couponsearch: '',
				coupon: '',
				discounts_totalinc: 0.00,
				discounts_total: 0.00,
				couponvalueexc: 0.00,
				couponvalueinc: 0.00,
				payment_method: '',
				payment_methods: [],
				saved_carts: [],
				recent_carts: [],
				paymentMethodWindow: false,
				pos_order: {
					customer: '',
					cart_companies: [
					],
					order_totalinc: 0.00,
					order_totalexc: 0.00,
				},
				newUser:{
			        id: '',
			        name: '',
			        lastname: '',
			        iscompany: 0,
			        companyname: '',
			        email: '',
			        password: '',
			        role_id: '',
			        company_id: this.company.id,
			      },
			      productRow: {"company_cart_id": '',
					"product_id": '',
					"company_listing_id": '',
					"quantity": 0,
					"price_exc": '',
					"price_inc": '',
					"product": [],
					"combinations": [],
					"variants": [],
					"updated_at": '',
					"created_at": '',
					"id": ''},
				 cartRow: {"company_id": '',
					"reference": '',
					"customer": '',
					"listing": '',
					"uid": '',
					"products": [],
					"updated_at": '',
					"created_at": '',
					"id": ''},
					showCalculator: false,
					storageurl: 'https://'+this.company.nickname+'.s3.eu-west-2.amazonaws.com',
					loggedIn: false,
					searchResource: '',
					productsCurrentPage: 1,
					productOptionsData: '',
					selectedproductqty: 1,
					selectedproductlisting: 0,
					sliderPrice: 800,
					resourceSearch: '',
					listings: [],
					selectedcustomer: '',
					customer: '',
					customer_address: '',
					showProductOptionsWindow: false,
					settings: {
						company_listing_id: 0,
						listing: '',
						register_id: '',
						terminal_id: '',
						terminal: '',
						printer_id: '',
						printer: '',
					},
					user_pin: [],
	      			priceRange: [0,999999],
	      			showFilters: false,
	      			cartsearch_query: '',
	      			cartslimit: 25,
	      			cartsoder: 'desc',
	      			cartsstorby: 'created_at',
	      			cartsCurrentPage: 1,
	      			cart_total: 0.00,
			     	cart_totalinc: 0.00,
	      			cart: {
	      				listing: '',
	      				customer: '',
				        products: [],
				        uid: '',
				        id: '',
				      },
				      cart_raw: {
	      				listing: '',
	      				customer: '',
				        products: [],
				        uid: '',
				        id: '',
				      },
				      currency: '',
				      currencyicon: 'bx bx-pound',
				      currencytext: 'GBP',
				      cartLoading: false,
				      previewWindowLoading: false,
				      initiateCheckout: false,
				      cartPanelData: [],
				      cartPanel: false,
				      showCart: false, 
				      showSearch: false,
	      			productview: 'grid',
					search: '',
				      limit: 24,
				      selectedids: [],
				      orderDesc: true,
				      order: 'desc',
				      sortby: 'id',
				      trade_active: '',
				      public_active: '',
				      productsData: {data: []},
				      brands: [],
				      collections: [],
					  active: '',
				      view: 'list',
						limits: [
						        {value: 24, text: "24"},
						        {value: 48, text: "48"},
						        {value: 96, text: "96"},
						      ],
			}
		},
		props: {
			user: {
				type: Object,
				default: () => '',
			},
			company: {
				type: Object,
				default: () => '',
			}
		},
		mounted(){
			if(this.user.companies[0].owner == 0){
				this.loadListings();
			}else{
				this.loadAllListings();
			}
			//this.createBase64Image();
			this.getPaymentMethods();
			//this.ordersstartdate = DateTime.local().minus({months: 1}).toISODate();
      		this.ordersenddate = DateTime.local().plus({days: 1}).toISODate();
			/*this.getMedia({
				  audio: true,
				  video: true,
				});*/
      		var posSettings = window.localStorage.getItem('pos-settings');
			if(posSettings){
				this.settings = JSON.parse(posSettings);
				if(this.settings["listing"] !== ''){
					this.terminalsRegistersForm = true;
				}
				this.posLogin();
			}
			let self = this;
			
			EventBus.$on('add-to-pos', function(product, qty, listing_id, variants, combination){
		      console.log('Cart Event Triggered');
		       self.addToCart(product, qty, listing_id, variants, combination);
		    });
		    EventBus.$on('pos-product-options', function(product){
		      console.log('Cart Event Triggered');
		       self.selectedvariantstotal = [0.00, 0.00];
			   self.cartsvariantstotal = [0.00, 0.00];
		       self.selectedvariants = [];
		       self.productOptions(product);
		    });
		},
		created(){
			//this.triggerToast('b-toaster-bottom-left', true, 'This is my message');
		},
		methods: {
			/*async getMedia(constraints) {
			  let stream = null;

			  try {
			    stream = await navigator.mediaDevices.getUserMedia(constraints);
			    
			  } catch (err) {
			   
			  }
			},*/
			getPrinterJobs(){
				axios.get('/api/company/sell/printer/'+this.settings.printer.id+'/jobs', {headers: {'Authorization' : 'Bearer '+this.user.api_token}} ).then(response => {
		    			this.printerJobs = response.data;
		    		}).catch(error => {
		    			
		    		});
			},
			getPrinterDetails(){
				axios.get('/api/company/sell/printer/'+this.settings.printer.id+'', {headers: {'Authorization' : 'Bearer '+this.user.api_token}} ).then(response => {
		    			this.printerDetails = response.data;
		    		}).catch(error => {
		    			
		    		});
			},
			printReceipt(){

				 var divContents = document.getElementById("print-receipt-window").innerHTML;
		             var map_src = window.open("", "PRINT MAP", "width=800,height=600,top=0,left=0,toolbar=no,scrollbars=no,status=no,resizable=no");
				     map_src.document.write('<html><head>');
				     //map_src.document.write('<link rel="stylesheet"  href="/leaflet-0.5.1/0.7.3/leaflet.css"/>'); ** Remove this line
				     map_src.document.write("</head><body><div style='height:400px;'>");
				     map_src.document.write(divContents);
				     map_src.document.write('</div></body></html>');
				     map_src.document.close();
				     map_src.focus();
				     map_src.print();
			},
			testPrintReceipt(){
				this.createPrintJob(this.settings.printer.id, '[align: center][magnify: width: 3; height:2]Test Print[magnify]', 1);
				
				// Bluetooth printer MAC address and service UUID
				/*const printerMacAddress = '00:11:62:1A:31:30';
				//const serviceUuid = '00001101-0000-1000-8000-00805F9B34FB';

				// Define the receipt content and format it
				const receiptContent = `
				  My Store
				  -------------------------------
				  Item         Price
				  -------------------------------
				  Apple        $0.50
				  Orange       $0.75
				  Banana       $1.00
				  -------------------------------
				  Total        $2.25
				  Payment      Cash
				  -------------------------------
				  Thank you for your business!
				`;
				// Format the receipt to fit the printer's paper size and layout

				// Create a Bluetooth connection and print the receipt
				navigator.bluetooth.requestDevice({
				  filters: [{ services: ["0x1120", "0x1122", "0x1123", "0x1126"] }]
				})
				.then(device => {
				  // Connect to the printer device
					console.log(device);
				  return device.gatt.connect();
				})
				.then(server => {
				  // Get the printer service
				  return server.getPrimaryService(serviceUuid);
				})
				.then(service => {
				  // Get the printer characteristics
				  return Promise.all([
				    service.getCharacteristic('00002A00-0000-1000-8000-00805F9B34FB'), // Device name
				    service.getCharacteristic('00002A01-0000-1000-8000-00805F9B34FB'), // Appearance
				    service.getCharacteristic('0000FFF3-0000-1000-8000-00805F9B34FB'), // Write characteristic
				  ]);
				})
				.then(characteristics => {
				  // Set the device name and appearance (optional)
				  const [deviceName, appearance, writeCharacteristic] = characteristics;
				  return Promise.all([
				    deviceName.writeValue(new TextEncoder().encode('My Printer')),
				    appearance.writeValue(new Uint8Array([0x02, 0x10, 0x00])),
				    writeCharacteristic.writeValue(new TextEncoder().encode(receiptContent)),
				  ]);
				})
				.catch(error => {
				  console.error(error);
				});*/
			},
			triggerToast(toaster, append = false, message = 'Connection Status') {
		        this.$bvToast.toast(`${message}`, {
		          title: `Terminal Connection Status`,
		          toaster: toaster,
		          solid: true,
		          appendToast: append,
		          noAutoHide: true,

		        })
		      },
		    compileWebstockTransfer(transfer_to){
		    	this.loadingTransferForm = true;
				var request = [];
				this.transfer["transfer_from"] = transfer_from;
				this.transfer["transfer_to"] = transfer_to;
				this.transfer["products"] = [];
				this.transfer["products"].push(this.productOptionsData);
				this.transfer["products"][0]["transfer_quantity"] = 0;
				this.transfer["products"][0]["transfer_quantity"] = this.selectedproductqty;
				this.transfer["date_required"] = DateTime.local().plus({days: 3}).toISODate();

				axios.post('/api/company/product/transfer/webstock-create', this.transfer, {
					headers: {
						'Authorization' : 'Bearer '+this.user.api_token
					}
				}).then(response => {
					this.transfer_response = response.data;
					this.transfer_booking_success = true;
					this.loadingTransferForm = false;
				}).catch(error => {

				});
		    },
			compileTransfer(transfer_to, transfer_from){
				this.loadingTransferForm = true;
				var request = [];
				this.transfer["transfer_from"] = transfer_from;
				this.transfer["transfer_to"] = transfer_to["listing_info"];
				this.transfer["products"] = [];
				this.transfer["products"].push(this.productOptionsData);
				this.transfer["products"][0]["transfer_quantity"] = 0;
				this.transfer["products"][0]["transfer_quantity"] = this.selectedproductqty;
				this.transfer["date_required"] = DateTime.local().plus({days: 3}).toISODate();
				this.transfer["status"] = "Booked";
				this.transfer["priority"] = "High";
				axios.post('/api/company/product/transfer/create', this.transfer, {
					headers: {
						'Authorization' : 'Bearer '+this.user.api_token
					}
				}).then(response => {
					this.transfer_response = response.data;
					this.transfer_booking_success = true;
					this.loadingTransferForm = false;
				}).catch(error => {

				});
			},
			loadAddressData(address){
				this.customer_address = address;
				if(this.customer.addresses !== null){
					this.customer.addresses.push(address);
				}else{
					this.customer.addresses = [];
					this.customer.addresses.push(address);
				}
				this.$bvModal.hide('add-address');
			},
			addToWaitingList(){
				axios.post('/api/company/waiting-list/create', this.waiting_list, {headers: {'Authorization' : 'Bearer '+this.user.api_token}}).then(response => {
					this.waiting_list_response = true;
					let self = this;
					setTimeout(function(){
						self.waiting_list_response = null;
						self.$bvModal.hide('waiting-list');
					}, 2000);
				}).catch(error => {
					this.waiting_list_response = false;
					let self = this;
					setTimeout(function(){
						self.waiting_list_response = null;
					}, 2000);
				});
			},
			seeOrder(order){
				this.selectedorder = '';
				if(this.orderViewWindow == true){
					this.orderViewWindow = false;
					this.selectedorder = JSON.parse(JSON.stringify(order));
					let self = this;
					setTimeout(function() {
						self.orderViewWindow = true;
					}, 500);
				}else{
					this.selectedorder = JSON.parse(JSON.stringify(order));
					this.orderViewWindow = true;
				}
			},
			getOrders(){
			      axios.get('/api/company/sell/pos/orders?page='+this.orderscurrentPage+'&limit=20&sortby=created_at&order=desc&search=&startdate='+this.ordersstartdate+'&enddate='+this.ordersenddate+'&inthisstore='+this.inthisstore+'&company_listing_id='+this.settings.listing.id+'&posorders='+this.posorders+'&websiteorders='+this.websiteorders+'&customerorders='+this.customerorders+'&tradeorders='+this.tradeorders+'&searchorderpaid='+this.searchorderpaid+'&searchpaidby='+this.searchpaidby, {headers: {'Authorization' : 'Bearer '+this.user.api_token}}).then(response => {
			        console.log(response);
			        this.orders = response.data;
			      });
			},
			setupStripeScripts(){
				this.includeStripe('js.stripe.com/v3/', function(){
		                this.configureStripe();
			            }.bind(this) );
			this.includeStripeTerminal('js.stripe.com/terminal/v1/', function(){
		              //return this.getTerminalsList();
		              //this.createTerminalToken();
		              return this.initialiseTerminalScript();
			            }.bind(this) );
			},
			posLogin(){
				this.loggedIn = true;
				this.updateSettings();
				this.loadProducts();
				this.setupStripeScripts();
			},
			updateSettings(){
				window.localStorage.setItem('pos-settings', JSON.stringify(this.settings));
			},
			updatePosSettings(){
				window.localStorage.setItem('pos-settings', JSON.stringify(this.settings));
				this.loadProducts();
				this.getConnectReaders();
			},
			getConnectReaders(){
				var config = {simulated: false, location: this.settings.terminal.stripe_terminal_location_id};
				this.terminal.discoverReaders(config).then(discoverResult => {
					console.log(discoverResult);
			    if (discoverResult.error) {
			      console.log('Failed to discover: ', discoverResult.error);
			      this.connectionStatusText = 'Connection discovery failed, please try again.';
			      this.connectionStatusModal = true;
			    } else if (discoverResult.discoveredReaders.length === 0) {
			      console.log('No available readers.');
			      this.connectionStatusText = 'Failed to find your terminal.';
			      this.connectionStatusModal = true;
			    } else {
			      // Just select the first reader here.
			      this.connectionStatusModal = true;
			      this.connectionStatusText = 'Reader discovered, establishing connection...';
			      console.log(discoverResult);
			      var selectedReader = discoverResult.discoveredReaders.filter(item => item.id = this.settings.terminal.stripe_terminal_id);
			      return this.connectReaderHandler(selectedReader[0]);
			      /*return self.terminal.connectReader(selectedReader).then(function(connectResult) {
			        if (connectResult.error) {
			          console.log('Failed to connect: ', connectResult.error);
			        } else {
			          console.log('Connected to reader: ', connectResult.reader.label);
			        }
			      });*/
			    }
			  });
			},
			getReaderFromLocationsList(locations){
				var reader = locations.filter(item = item.id == this.settings.terminal.stripe_terminal_id);
				return this.connectReaderHandler(reader);
			},
			getVirtualTerminal(){
				axios.post('/api/company/sell/pos/terminal/virtual-terminal', {}, {headers: {
					'Authorization' : 'Bearer '+this.user.api_token
				}}).then(response => {
					//this.settings.terminal = response.data;
					return this.connectReaderHandler(response.data);
				}).catch(error => {

				});
			},
			includeStripe( URL, callback ){
                        let documentTag = document, tag = 'script',
                            object = documentTag.createElement(tag),
                            scriptTag = documentTag.getElementsByTagName(tag)[0];
                        object.src = '//' + URL;
                        if (callback) { object.addEventListener('load', function (e) { callback(null, e); }, false); }
                        scriptTag.parentNode.insertBefore(object, scriptTag);
            },
            configureStripe(){
                this.stripe = Stripe( this.stripeAPIToken );
                
            },
			includeStripeTerminal( URL, callback ){
                        let documentTag = document, tag = 'script',
                            object = documentTag.createElement(tag),
                            scriptTag = documentTag.getElementsByTagName(tag)[0];
                        object.src = '//' + URL;
                        if (callback) { object.addEventListener('load', function (e) { callback(null, e); }, false); }
                        scriptTag.parentNode.insertBefore(object, scriptTag);
            },
           	initialiseTerminalScript(){
           		
           		axios.defaults.headers.common["Authorization"] = `Bearer ${this.user.api_token}`;
				/*const tokenfunction = async function createToken(){
				  return await axios.post('/api/company/sell/pos/terminal/connection-token', {});
				}
				
				const tokenfunction1 = async function fetchConnectionToken() {
				  // Your backend should call /v1/terminal/connection_tokens and return the JSON response from Stripe
				  return await axios.post('https://moira-fine-jewellery.gemesys.co.uk/terminal/connection_token', {method: 'POST'})
				    .then(function(response) {
				      return response;
				    });
				    
				};*/
           		const key = this.user.api_token;
				const tokenfunction1 = async function() { 
					const response = await fetch('/api/company/sell/pos/terminal/connection-token', { method: 'post', headers: { Authorization: 'Bearer '+key } });
					const data = await response.json();
					return data.secret;
					 };

				//const tokenfunction = async function createToken(){ return await Stripe.terminal.connectionTokens.create() };
				
				const terminalDisconnected = async function disconnectTerminal(){
					this.$bvModal.show('terminal-connection')
				};
				this.terminal = StripeTerminal.create({
					  onFetchConnectionToken: tokenfunction1,
					  onUnexpectedReaderDisconnect: terminalDisconnected,
					});
				//this.getVirtualTerminal();
				return this.getConnectReaders();		
			},
         	getTerminalsList(location_id){
				axios.get('/api/company/sell/pos/terminals?location_id=', {headers: {
					'Authorization' : 'Bearer '+this.user.api_token
				}}).then(response => {
					this.terminals = response.data;
				}).catch(error => {

				});
			},
			createTerminalToken(){
				/*this.stripe.terminal.connectionTokens.create().then(response => {
					this.connectionToken = response.data;
				});*/	

				axios.post('/terminal/connection-token', {}, {headers: {
					'Authorization' : 'Bearer '+this.user.api_token
				}}).then(response => {
					console.log(response.data.secret);
					return response.data;
				});
			},
			async createTerminalIntent(){
				axios.post('/api/company/sell/pos/terminal/create-payment-intent', {amount: (parseFloat(this.cart_totalinc) + parseFloat(this.cartvariantstotal[1]) + parseFloat(this.cartcombinationstotal[1]) - parseFloat(this.couponvalueinc)).toFixed(2) }, {headers: {
					'Authorization' : 'Bearer '+this.user.api_token
				}}).then(response => {
					this.terminalIntent = response.data;
					this.pendingPaymentIntentSecret = response.data;
					 this.payment_progress = 15;
				  return this.createTerminalPayment(response.data);
				});
			},
			async createTerminalPayment(intent){
				this.pendingPaymentIntentSecret = intent;
				const paymentMethodPromise = this.terminal.collectPaymentMethod(
							       this.pendingPaymentIntentSecret.client_secret
							    );
							    //this.setState({ cancelablePayment: true });
				const result = await paymentMethodPromise;
				return this.confirmPaymentProcessing(result);
							    
			},
			async confirmPaymentProcessing(result){
								if (result.error) {
							      console.log("Collect payment method failed:", result.error.message);
							    } else {
							      const confirmResult = await this.terminal.processPayment(
							        result.paymentIntent
							      );
							      // At this stage, the payment can no longer be canceled because we've sent the request to the network.
							     // this.setState({ cancelablePayment: false });
							      this.payment_progress = 30;
							      return this.createPosOrder(confirmResult);
							    }
			},
			async confirmPaymentResult(confirmResult){
				if (confirmResult.error) {
							        alert(`Confirm failed: ${confirmResult.error.message}`);
							      } else if (confirmResult.paymentIntent) {
							        if (confirmResult.paymentIntent.status !== "succeeded") {
							          try {
							            // Capture the PaymentIntent from your backend client and mark the payment as complete
							            let captureResult = await this.client.capturePaymentIntent({
							              paymentIntentId: confirmResult.paymentIntent.id
							            });
							            //this.pendingPaymentIntentSecret = null;
							            
							            // Confirm Order Paid on Gemesys
							            this.payment_progress = 70;
							            this.confirmOrderPaid();
							            console.log("Payment Successful!");
							            return captureResult;
							          } catch (e) {
							            // Suppress backend errors since they will be shown in logs
							            return;
							          }
							        } else {
							          //this.pendingPaymentIntentSecret = null;
							          // Confirm Order Paid on Gemesys
							        	this.payment_progress = 70;
							            this.confirmOrderPaid();
							          console.log("Single-message payment successful!");
							          //this.loadingPaymentMethodWindow = false;
							          return confirmResult;
							        }
							      }
			},
			async cancelPayment(){
				const confirmResult = this.terminal.cancelCollectPaymentMethod();
				this.loadingPaymentMethodWindow = false;
				this.paymentMethodWindow = false;
				this.payment_method = '';
				console.log(confirmResult);
				return confirmResult;
			},
			terminalUnexpectedDisconnect(){
				this.$bvModal.show('terminal-connection');
			},
			connectReaderHandler(selectedReader) {
				  const config = {simulated: true};

				    this.terminal.connectReader(selectedReader).then(connectResult => {
				    	if (connectResult.error) {
				    		this.connectionStatusModal = true;
					      console.log('Failed to connect: ', connectResult.error);
					    } else {
					      console.log('Connected to reader: ', connectResult);
					    }
				    });
				    
				
			},
			calculateVariantsTotal(variants, tax_rule, variant_quantity){
				
        		var total = 0.00;
        		for(let key in variants){
        			console.log(key);

        			total += (parseFloat(variants[key].price_difference) * variant_quantity);
        		}
        		this.selectedvariantstotal = [total, this.addTax(total, tax_rule)];
        		this.cartvariantstotal[0] += total;
	        	this.cartvariantstotal[1] += this.addTax(total, tax_rule);
        	
        	},
        	calculateCombinationsTotal(combination, tax_rule, variant_quantity){
				
        		var total = 0.00;
        		if(combination !== ''){
	        		total += (parseFloat(combination.price_difference) * variant_quantity);
	        	}
	        		this.selectedcombinationstotal = [total, this.addTax(total, tax_rule)];
	        		this.cartcombinationstotal[0] += total;
		        	this.cartcombinationstotal[1] += this.addTax(total, tax_rule);
	        	
        	
        	},
        	/*calculateVariantsTotals(){
        		for(var i = 0; this.cart.products.length; i++){
        			if(this.cart.products[i].variants.length > 0){
	        			for(var vi = 0; this.cart.product[i].variants; vi++){
	        				this.cartvariantstotal[0] += total;
	        				this.cartvariantstotal[1] += this.addTax(total, tax_rule);
	        			}
        			}
        		}
        	},*/
			selectVariant(key, value){
				this.previewWindowLoading = true;
				this.selectedvariants[key] = value;
				let self = this;
				setTimeout(function(){
			    	self.previewWindowLoading = false;
				}, 150);
			},
			getDiscountCoupon(cuid){
	        	axios.post('/api/company/sell/pos/cart/coupon-search', {uid: cuid, search: this.couponsearch}, { headers : {
					'Authorization': 'Bearer ' + this.user.api_token
				} }).then(response => {
	        		this.coupon = response.data;
	        		if(response.data !== "No coupon with this code"){
	        			//this.success_message_text = 'Discount coupon added to cart';
	        			//this.success_message = true;
	        			this.$bvModal.hide('product-code-reader');
	        			this.quickSuccesMessage('Coupon is Valid', 'Discount coupon added to cart');
	        			return this.cartDiscounts(response.data);
	        		}

	        	}).catch(error => {
	        		this.error_reponse = error;
	        	});
	        },
			toggleMenu(){
				EventBus.$emit('toggle-navbar');
				this.menuShown = !this.menuShown;
			},
			/*onDecode(a, b, c) {
		      console.log(a, b, c);
		      this.text = a;
		      if (this.id) clearTimeout(this.id);
		      this.id = setTimeout(() => {
		        if (this.text === a) {
		          this.text = "";
		        }
		      }, 5000);
		    },*/
		    handleScanner(){
		    	if(this.scanner_value.length > 0){
		    		if(this.scanner_value[0] == "{"){
		    			this.scanner_progress = 100;
		    			this.scanner_value = this.scanner_value.replaceAll('@', '"');
		    			this.onDecode(this.scanner_value + '}');
		    			this.scanner_value = '';
		    			this.scanner_busy = false;
		    		}else{

		    		}
		    	}
		    	
		    },
		    setScannerBusy(){
		    	console.log('Scanning stated, scanner busy');
		    		this.scanner_busy = true;
		    		this.scanner_progress = 10;
		    },
		    onScan(){
		    	this.loadingScanner = false;
		    	let self = this;
		    	setTimeout(function(){
			    	self.$refs['barcode_scan_response'].focus();
		    	}, 500);
		    },
		    onDecode(response){
			    if(response){
			    	console.log(JSON.parse(response));
			    	var product = JSON.parse(response);
			    	this.scanner_busy = true;
			    	this.loadingScanner = true;
			    	if(product.type == 'product'){
			    		this.loadProductFromScanner(product.id, product.uid);
			    	}else if(product.type == 'coupon'){
			    		this.couponsearch = product.reference;
			    		this.getDiscountCoupon(product.uid);
			    		this.scanner_busy = false;
			    	}else if(product.type == 'order-receipt'){
			    		this.quickSuccesMessage("Receipt scanned.", "Fetching order, please wait");
			    		this.selectedorder = [];
			    		this.selectedorder["uid"] = product.uid;
			    		this.$bvModal.hide('product-code-reader');
			    		
			    		this.orderViewWindow = true;
			    	}else if(product.type = this.company.uid.slice(0,5)+'-pos-login'){
			    		this.checkUserID(product);

			    	}
		    	}else{
		    		this.scanner_busy = false;
		    	}
		    },
		    checkUserPin(code){

		    },
		    checkUserID(code){
		    	axios.post('/api/company/sell/pos/login/check', {id: code.id}, {headers: {
		    		'Authorization' : 'Bearer '+this.user.api_token
		    	}}).then(response => {
		    		this.userToSwitch = response.data;
		    		let self = this;
					setTimeout(function(){
		    			self.showKeyPad = true;
		    			self.signInToken = response.data;
		    			self.loginDetails = code;
		    			//return self.initiateSwitchUser(response.data, login_id);
		    		}, 300);
		    	}).catch(error => {

		    	});
		    },
		    async initiateSwitchUser(){
		    	var key = this.signInToken;
		    	var pin = this.user_pin.toString();
		    	//var confirm_key = this.$CryptoJS.AES.encrypt(pin, "testpass");
		    	var confirm_key = pin;
		    	var logid = this.loginDetails.id;
		    	axios.post('/company/sell/pos/login/confirm', {id: logid, confirm: confirm_key}, {headers: {
		    		'Authorization' : 'Bearer '+this.user.api_token
		    	}}).then(response => {
		    		this.user = response.data;
		    		this.$emit('update:user', response.data);
		    		this.loadListings();
		    		if(this.listings.length > 0){
		    			this.settings.listing = this.listings[0];
		    		}
		    		let self = this;
					setTimeout(function(){
		    			self.showKeyPad = false;
		    			self.switch_user = false;
		    			return self.loadProducts();
		    		}, 300);
		    	}).catch(error => {

		    	});
		    },
		    onLoaded() {
		      this.loadingScanner = false;
		      console.log("loaded");
		    },
		    async createBase64Image(fileObject) {
		      /*const reader = new FileReader();
		      this.receipt_image = '';
		      reader.onload = (e) => {
		        this.receipt_image = e.target.result;
		        console.log(e.target.result);
		        //this.uploadImage();
		        //this.editImage = true;
		      };
		      reader.readAsDataURL(fileObject);*/
		      this.receipt_image = await this.toImageBlob(this.company.logo);
		    },
		    async toImageBlob(url) {
		    const response = await fetch(url);
		    const blob = await response.blob();
		    const reader = new FileReader();
		    await new Promise((resolve, reject) => {
		      reader.onload = resolve;
		      reader.onerror = reject;
		      reader.readAsDataURL(blob);
		    });
		    return reader.result;
  			},
		    addTax(price, tax_rule){
		    	console.log(tax_rule);
		    	if(this.customer_address == ''){
		    		var country_id = this.company.country_id;
		    	}else{
		    		var country_id = this.customer_address.country_id;
		    	}
		    	if(tax_rule !== null && tax_rule.country_rules.length > 0){
		    		var selected_rule = null;
		    		var selected_ruleIndex = -1;
		    	for(var ti = 0; ti < tax_rule.country_rules.length; ti++){
		    		selected_ruleIndex = tax_rule.country_rules[ti].countries.findIndex(item => item == country_id);
		    		if(selected_ruleIndex !== -1){
		    			selected_rule = tax_rule.country_rules[ti];
		    			ti = tax_rule.country_rules.length;
		    		}
		    	}
		    	console.log(selected_rule);
		    	if(tax_rule.type == 1){
	        		if(selected_rule !== null){
	        			var percentage = (parseFloat(selected_rule.value) / 100) + 1;
	        			return price * percentage;
	         		}else{
	         			return price;
	         		}
         		}else{
         			if(selected_rule !== null){
	        			return price + selected_rule.value;
	         		}else{
	         			return price;
	         		}
         		}
         		}else{
         			return price;
         		}
        	},
        	getVatValue(price, tax_rule){
		    	console.log(tax_rule);
		    	if(this.customer_address == ''){
		    		var country_id = this.company.country_id;
		    	}else{
		    		var country_id = this.customer_address.country_id;
		    	}
		    	if(tax_rule !== null && tax_rule.country_rules.length > 0){
		    		var selected_rule = null;
		    		var selected_ruleIndex = -1;
		    	for(var ti = 0; ti < tax_rule.country_rules.length; ti++){
		    		selected_ruleIndex = tax_rule.country_rules[ti].countries.findIndex(item => item == country_id);
		    		if(selected_ruleIndex !== -1){
		    			selected_rule = tax_rule.country_rules[ti];
		    			ti = tax_rule.country_rules.length;
		    		}
		    	}
		    	console.log(selected_rule);
		    	if(tax_rule.type == 1){
	        		if(selected_rule !== null){
	        			//var percentage = (parseFloat(selected_rule.value) / 100) + 1;
	        			return selected_rule.value;
	         		}else{
	         			return 0;
	         		}
         		}else{
         			if(selected_rule !== null){
	        			return selected_rule.value;
	         		}else{
	         			return 0;
	         		}
         		}
         		}else{
         			return 0;
         		}
        	},
		    selectCustomer(customer){
		    	this.customer = customer;
		    },
		    selectWaitingListCustomer(customer){
		    	this.customer = customer;
		    },
		    customerIsGuest(){
		    	this.customer = [];
		    	this.customer = {id: 0, firstname: 'Guest', lastname: "Customer", customer: {avatar: null}};
		    	this.customerSelected = true;
 		    },
		    selectAddress(address){
		    	this.customer_address = address;
		    },
		    loadCarts(){
		    	axios.get('/api/company/sell/pos/carts?page='+this.cartsCurrentPage+'&limit='+this.cartslimit+'&sortby='+this.cartssortby+'&order='+this.order+'&search='+this.cartsearch_query+'&trade_active='+this.trade_cartsactive+'&public_active='+this.public_cartsactive+'&active='+this.cartsactive+'&brands='+this.brands+'&collections='+this.collections+'&pricerange='+this.priceRange, {
		    		headers: {
		    			'Authorization' : 'Bearer '+this.user.api_token
		    		}
		    	}).then(response => {
			        console.log(response);
			        this.saved_carts = response.data.data;
			      }).catch(error => {

			      });
		    	this.cartsWindow = true;
		    },
		    focusCustomerInput(){
		    	var self = this;
                setTimeout(function(){
			    	console.log(self.$refs.selectcustomer.$refs.customersearch);
			    	self.$refs.selectcustomer.$refs.customersearch.focus();
			    	self.$refs.selectcustomer.$refs.customersearch.select();
				}, 500);
		    },
		    generatePassword(type){
		        this.newUser.password = Array(14).fill('0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz').map(function(x) { return x[Math.floor(Math.random() * x.length)] }).join('');
		    },
		    addNewUser(){
		      this.addFormSubmitted = true;
		      axios.post('/api/company/customer/store', this.newUser, {headers: {
                  'Authorization': 'Bearer ' + this.user.api_token
              }}).then(response => {
		            this.formResponse = response.data;
		             this.setTimeout(() => {
		          this.addFormSubmitted = false;
		          this.success_message = true;
		          this.newLocation = {
		              id: '',
		              name: '',
		              address1: '',
		              address2: '',
		              address3: '',
		              county_id: '',
		              country_id: '',
		              postcode: '',
		              telephone: '',
		              company_id: this.$attrs.company.id,
		            };
		        });  
		            this.setTimeout(() => {
		                     this.addPanel = false;
		            });
		      }).catch(error => {
		            this.error_reponse = error.response.data.errors;
		            this.error_message = true;
		            this.setTimeout(() => {
		            this.addFormSubmitted = false;
		          });
		      });
		    },
		    quickSuccesMessage(message, subtitle) {
		      let timerInterval;
		      Swal.fire({
		        title: message,
		        html: subtitle,
		        timer: 1000,
		        timerProgressBar: true,
		        onBeforeOpen: () => {
		          Swal.showLoading();
		          timerInterval = setInterval(() => {
		            Swal.getContent().querySelector(
		              "b"
		            ).textContent = Swal.getTimerLeft();
		          }, 100);
		        },
		        onClose: () => {
		          clearInterval(timerInterval);
		        }
		      }).then(result => {
		        if (
		          /* Read more about handling dismissals below */
		          result.dismiss === Swal.DismissReason.timer
		         
		        ) {
		          //console.log("I was closed by the timer"); // eslint-disable-line
		        	
		        }
		      });
		    },
		    loadProductFromScanner(id, uid){
		    	axios.get('/api/company/sell/pos/product/'+uid+'?id='+id, { headers : {
					'Authorization': 'Bearer ' + this.user.api_token
				} }).then(response => {
					//alert(response.data);
					//this.shopProducts = response.data;
					
					
					this.$bvModal.hide('product-code-reader');
					//Swal.fire("Product Found", "Fetching Details...", "success");
					this.quickSuccesMessage("Product Found", "Fetching Details...");
					return this.productOptions(response.data);
				}).catch(error => {
					this.error_reponse = error;
				});
		    },
			loadShopProducts(){
				axios.get('/api/company/listing/'+this.id+'/products', { headers : {
					'Authorization': 'Bearer ' + this.user.api_token
				} }).then(response => {
					this.shopProducts = response.data;
				}).catch(error => {
					this.error_reponse = error;
				});
			},
			loadProducts(){	
				this.company_listing_id = this.settings.listing.id;
				this.loadingForm = true;
				if(this.productsinthisstore == true){
					this.company_listing_id = this.settings.listing.id;
				}else{
					this.company_listing_id = '';
				}
			      axios.get('/api/company/sell/pos/products?page='+this.productsCurrentPage+'&limit='+this.limit+'&sortby='+this.sortby+'&order='+this.order+'&search='+this.search+'&trade_active='+this.trade_active+'&public_active='+this.public_active+'&active=true&brands='+this.brands+'&collections='+this.collections+'&inthisstore='+this.productsinthisstore+'&company_listing_id='+this.company_listing_id+'&pricerange='+this.priceRange, { headers : {
					'Authorization': 'Bearer ' + this.user.api_token
				} }).then(response => {
			        this.productsData = response.data;
			        let self = this;
			        setTimeout(function(){
			      		self.loadingForm = false;
			        }, 200);
			      });

			},
			productOptions(product){
				this.previewWindowLoading = true;
				this.productOptionsData = product;
				this.showProductOptionsWindow = true;
				var self = this;
                setTimeout(function(){
                	self.scanner_busy = false;
	                self.previewWindowLoading = false;                
                }, 500);
			},
			    cartTotal(cart){
			          var score = 0;
			          if(cart.products.length !== 0){
				          if(cart.products.length > 1){
				            for(var i = 0; i < cart.products.length; i++){
				              var itemscore = (parseFloat(cart.products[i].price_exc) * parseFloat(cart.products[i].quantity));
				              score = score + itemscore;
				            }
				          }else{
				            score = cart.products[0].price_exc * cart.products[0].quantity;
				          }
				         }else{
				         	score = 0;
				         }
				       this.cart_total = (score).toFixed(2);
			          return (score).toFixed(2);
			        },
			    addToCart(productdata, qty, listing_id, variants, combination){
			      this.cartLoading = true;
			      if(listing_id == 0){
			      	listing_id = this.settings.company_listing_id;
			      }
			      if(this.cart.products.length == 0){
			        //this.cart.products.push({product: productdata});
			        this.createCart(productdata, qty, listing_id, variants, combination);
			      }else{
			        var carindex = this.cart.products.findIndex(item => item.product_id == productdata.id);
			            if(carindex !== -1){

			            	if(Object.keys(variants).length == 0){
				              this.cart.products[carindex]["company_cart_id"] = null;
							  this.cart.products[carindex]["product_id"] = productdata.id;
							  this.cart.products[carindex]["company_listing_id"] = listing_id;
							  this.cart.products[carindex]["quantity"] += parseFloat(qty);
							  this.cart.products[carindex]["price_exc"] = productdata.sales_price;
							  this.cart.products[carindex]["price_inc"] = productdata.sales_price;
							  this.cart.products[carindex]["updated_at"] = null;
							  this.cart.products[carindex]["created_at"] = null;
							  this.cart.products[carindex]["id"] = null;
				              this.cart.products[carindex]["product"] = productdata;
				              this.cart.products[carindex]["variants"] = variants;
				              this.cart.products[carindex]["combinations"] = combination;
				          }else if(Object.keys(variants).length > 0){
				          	if(this.cart.products[carindex].variants !== variants){
				          	this.cart["products"].push(JSON.parse(JSON.stringify(this.productRow)));
				              this.cart.products[(this.cart.products.length - 1)]["company_cart_id"] = null;
							  this.cart.products[(this.cart.products.length - 1)]["product_id"] = productdata.id;
							  this.cart.products[(this.cart.products.length - 1)]["company_listing_id"] = listing_id;
							  this.cart.products[(this.cart.products.length - 1)]["quantity"] += parseFloat(qty);
							  this.cart.products[(this.cart.products.length - 1)]["price_exc"] = productdata.sales_price;
							  this.cart.products[(this.cart.products.length - 1)]["price_inc"] = productdata.sales_price;
							  this.cart.products[(this.cart.products.length - 1)]["updated_at"] = null;
							  this.cart.products[(this.cart.products.length - 1)]["created_at"] = null;
							  this.cart.products[(this.cart.products.length - 1)]["id"] = null;
				              this.cart["products"][(this.cart.products.length - 1)]["product"] = productdata;
				              this.cart.products[(this.cart.products.length - 1)]["variants"] = variants;
				              this.cart.products[(this.cart.products.length - 1)]["combinations"] = combination;
				          	}else{
				          		this.cart.products[carindex]["quantity"] += parseFloat(qty);
				          	}
				              //this.updateVariantsQuantity(this.cart.products[(this.cart.products.length - 1)]["variants"], parseFloat(qty), this.cart.products.length - 1);
				          
			            
				          }
			              //window.sessionStorage.setItem("poscart", JSON.stringify(this.cart));
			            
			              this.productAdded(productdata);
			            }else if(carindex == -1){


			              this.cart["products"].push(JSON.parse(JSON.stringify(this.productRow)));
			              this.cart.products[(this.cart.products.length - 1)]["company_cart_id"] = null;
						  this.cart.products[(this.cart.products.length - 1)]["product_id"] = productdata.id;
						  this.cart.products[(this.cart.products.length - 1)]["company_listing_id"] = listing_id;
						  this.cart.products[(this.cart.products.length - 1)]["quantity"] += parseFloat(qty);
						  this.cart.products[(this.cart.products.length - 1)]["price_exc"] = productdata.sales_price;
						  this.cart.products[(this.cart.products.length - 1)]["price_inc"] = productdata.sales_price;
						  this.cart.products[(this.cart.products.length - 1)]["updated_at"] = null;
						  this.cart.products[(this.cart.products.length - 1)]["created_at"] = null;
						  this.cart.products[(this.cart.products.length - 1)]["id"] = null;
			              this.cart["products"][(this.cart.products.length - 1)]["product"] = productdata;
			              this.cart.products[(this.cart.products.length - 1)]["variants"] = variants;
			              this.cart.products[(this.cart.products.length - 1)]["combinations"] = combination;
			              //window.sessionStorage.setItem("poscart", JSON.stringify(this.cart));
			             //this.updateVariantsQuantity(this.cart.products[(this.cart.products.length - 1)]["variants"], parseFloat(qty), this.cart.products.length - 1);
			              this.productAdded(productdata);
			            }
			
			      } 
			      
			      if(this.customer_address == ''){
			      	this.cartTotalIncVat(this.company.country.country_zone_id, this.company.country_id);
			  	  }else{
			  	  	this.cartTotalIncVat(this.customer_address.country.country_zone_id, this.customer_address.country_id);
			  	  }
			  	  this.selectedvariants = {};
			  	  this.selectedvariantstotal = [0.00, 0.00];
			      this.selectedcombination = '';
			      this.cartDiscounts(this.coupon);
			    },
			    updateVariantsQuantity(variants, quantity, vindex){
			    	 for(var vi = 0; vi < variants.length; vi++)
				              {
				              	if(variants[vi]){
				              		this.cart.products[vindex]["variants"][vi].quantity += quantity;
				              	}
				              }
			    },
			    cartDiscounts(coupon){
			    	if(coupon !== ''){
			    		if(coupon.discount_type == 1){
			    			var percentage = ((parseFloat(coupon.discount_amount) /100));
			    			var priceincdiscountamount = parseFloat(this.cart_totalinc) * parseFloat(percentage);
			    				this.couponvalueinc = parseFloat(priceincdiscountamount).toFixed(2);
			    				this.discounts_totalinc += priceincdiscountamount;
			    			
			    		    
			    		}else{
			    				var priceincdiscountamount = parseFloat(coupon.discount_amount);
			    				this.couponvalueinc = parseFloat(coupon.discount_amount).toFixed(2);
			    				this.discounts_totalinc += priceincdiscountamount;
			    			
			    		}
			    	}else{
			    		this.couponvalueinc = parseFloat(0.00).toFixed(2);
			    	}
		
			    },
			    cartTotalIncVat(zone_id, country_id){
			   	//var default_country_id = this.company.country_id;
			    this.cartvariantstotal = [0.00, 0.00];
	        	this.cartcombinationstotal = [0.00, 0.00];
		       	this.cart_totalinc = parseFloat(0.00).toFixed(2);
		          	var score = parseFloat(0.00).toFixed(2);
		          if(this.cart.products.length > 0){
		            for(var i = 0; i < this.cart.products.length; i++){
		              var rule = this.cart.products[i].product.tax_rule;
		              console.log(rule);
		              this.cart.products[i].price_inc = parseFloat(this.addTax(this.cart.products[i].price_exc, rule)).toFixed(2);
		              score = parseFloat(score) + (parseFloat(this.cart.products[i].price_inc) * parseFloat(this.cart.products[i].quantity));
		           	  /*var tax_rule = rule.country_rules.filter(item => item.country_zone_id == zone_id);
		           	   console.log(tax_rule);
		           	  if(tax_rule.length > 0){
			           	  var country_rule = tax_rule[0].countries.filter(item => item == country_id);
			           	  if(country_rule.length > 0){
			           	  	var tax_calc = ((parseFloat(tax_rule[0].value) + 100) / 100);
			           	  	var itempriceinc = parseFloat(this.cart.products[i].price_exc) * parseFloat(tax_calc);
			           	  	var itemscore = parseFloat(itempriceinc) * parseFloat(this.cart.products[i].quantity);
			           	  	this.cart.products[i].price_inc = parseFloat(itempriceinc);
			           	  }else{
			           	  	//var tax_calc = ((parseFloat(tax_rule[0].value) + 100) / 100);
			           	  	//var itempriceinc = parseFloat(this.cart.products[i].price_exc) * parseFloat(tax_calc);
			           	  	var itemscore = parseFloat(itempriceinc) * parseFloat(this.cart.products[i].quantity);
			           	  	this.cart.products[i].price_inc = parseFloat(itempriceinc);
			           	  }
		           	  }else{
		           	  	var itemscore = (parseFloat(this.cart.products[i].price_exc) * parseFloat(this.cart.products[i].quantity));
		           	  }*/
		             // score = parseFloat(score) + parseFloat(itemscore);
		              this.selectedvariants = this.cart.products[i].variants;
		              this.calculateVariantsTotal(this.cart.products[i].variants, rule, parseFloat(this.cart.products[i].quantity));
		              this.calculateCombinationsTotal(this.cart.products[i].combinations, rule, parseFloat(this.cart.products[i].quantity));
		              this.selectedvariants = {};
		              console.log(score);
		            }
		          }else{
		          	var rule = this.cart.products[0].product.tax_rule;
		          	console.log(rule);
		              this.cart.products[0].price_inc = this.addTax(this.cart.products[0].price_exc, rule);
		              score = parseFloat(score) + (parseFloat(this.cart.products[0].price_inc) * parseFloat(this.cart.products[0].quantity));
		              this.selectedvariants = this.cart.products[i].variants;
		              this.calculateVariantsTotal(this.cart.products[0].variants, rule, parseFloat(this.cart.products[0].quantity));
		              this.calculateCombinationsTotal(this.cart.products[0].combinations, rule, parseFloat(this.cart.products[0].quantity));
		              this.selectedvariants = {};
		          	/*console.log(rule);
		           	  var tax_rule = [];
		           	  tax_rule = rule.country_rules.filter(item => item.country_zone_id == zone_id);
		           	  console.log(tax_rule);
		           	  if(tax_rule.length > 0){
			           	  var country_rule = tax_rule[0].countries.filter(item => item == country_id);
			           	  console.log(country_rule);
			           	  if(country_rule.length > 0){
			           	  	var tax_calc = ((parseFloat(tax_rule[0].value) + 100) / 100);
			           	  	var itempriceinc = parseFloat(this.cart.products[0].price_exc) * parseFloat(tax_calc);
			           	  	var itemscore = parseFloat(itempriceinc) * parseFloat(this.cart.products[0].quantity);
			           	  	this.cart.products[0].price_inc = parseFloat(itempriceinc);
			           	  }else{
			           		score = parseFloat(this.cart.products[0].price_exc) * parseFloat(this.cart.products[0].quantity);
			           		}

			           	}else{
			           		score = parseFloat(this.cart.products[0].price_exc) * parseFloat(this.cart.products[0].quantity);
			           	}
			           	score = parseFloat(score) + parseFloat(itemscore);
			           	*/
		          }
		          //this.cart.productstotalinc = parseFloat(score).toFixed(2);
		          this.cart_totalinc = parseFloat(score).toFixed(2);

		          //return parseFloat(score).toFixed(2);
		        },
			    removeProduct(id, index){
			    	//this.selectedvariants = this.cart.products[index].variants;
			    	//this.calculateVariantsTotal(this.cart.products[index].product.tax_rule, 0);
			        this.cart.products.splice(index, 1);
			         
			         if(this.customer_address == ''){
			      		this.cartTotalIncVat(this.company.country.country_zone_id, this.company.country_id);
				  	  }else{
				  	  	this.cartTotalIncVat(this.customer_address.country.country_zone_id, this.customer_address.country_id);
				  	  }
				      this.cartDiscounts(this.coupon);

			         window.sessionStorage.setItem("poscart", JSON.stringify(this.cart));
			    },
			    reCalculateTotals(){
			    	if(this.customer_address == ''){
			      		this.cartTotalIncVat(this.company.country.country_zone_id, this.company.country_id);
				  	  }else{
				  	  	this.cartTotalIncVat(this.customer_address.country.country_zone_id, this.customer_address.country_id);
				  	  }
				      this.cartDiscounts(this.coupon);
			    },
			    productAdded(product){
			      //this.cartPanelData = product;
			      //this.cartPanel = true;
			      this.cartLoading = false;
			      //this.$refs.cart.show(true);
			    },
			    createCart(product, qty, listing_id, variants, combination){
			        this.cart = this.cartRow;
			        this.cart["products"] = [];
			        this.cart.products.push(JSON.parse(JSON.stringify(this.productRow)));
			        this.cart.products[0]["company_cart_id"] = null;
					this.cart.products[0]["product_id"] = product.id;
					this.cart.products[0]["company_listing_id"] = listing_id;
					this.cart.products[0]["quantity"] += parseFloat(qty);
					this.cart.products[0]["price_exc"] = product.sales_price;
					this.cart.products[0]["price_inc"] = product.sales_price;
					this.cart.products[0]["updated_at"] = null;
					this.cart.products[0]["created_at"] = null;
					this.cart.products[0]["id"] = null;
			        this.cart.products[0]["product"] = product;
			        this.cart.products[0]["variants"] = variants;
				    this.cart.products[0]["combinations"] = combination;
				    //this.updateVariantsQuantity(this.cart.products[0]["variants"], parseFloat(qty), 0);
			        window.sessionStorage.setItem("poscart", JSON.stringify(this.cart));
			        this.productAdded(product);
			    },
			    createOrder(){
			    	
			    	if(this.payment_method == ''){
			    		this.saveCart();
			    		this.paymentMethodWindow = true;
			    	}else{
			    		this.processOrder();
			    	}

			    },
			    getPaymentMethods(){
			    	axios.get('/api/company/order/payment-options?api_token='+this.user.api_token+'&page='+this.currentPage+'&limit='+this.limit+'&sortby='+this.sortby+'&order='+this.order+'&search='+this.search+'&trade_active='+this.trade_active+'&public_active='+this.public_active+'&active='+this.active+'&brands='+this.brands+'&collections='+this.collections+'&pricerange='+this.priceRange, {headers: {
                  'Authorization': 'Bearer ' + this.user.api_token
              }}).then(response => {
				        console.log(response);
				        this.payment_methods = response.data.data;
				      });
			    },
			    async assemblePosOrder(){
			    	if(this.customer !== '' && this.customer['id'] !== 0){
			    		this.pos_order.customer = this.customer;
			    	}else{
			    		this.pos_order.customer = {};
			    		this.customer = '';
			    	}
			    	this.pos_order.customer["customer_invoice_address_id"] = this.customer_address.id;
			    	this.pos_order.customer["customer_delivery_address_id"] = this.customer_address.id; 
			    	this.pos_order.cart_companies = [];
			    	this.pos_order.cart_companies[0] = this.company;
			    	this.pos_order.cart_companies[0]["variantstotal"] = this.cartvariantstotal[0];
                    this.pos_order.cart_companies[0]["variantstotalinc"] = this.cartvariantstotal[1];
                    this.pos_order.cart_companies[0]["combinationstotal"] = this.cartcombinationstotal[0];
                    this.pos_order.cart_companies[0]["combinationstotalinc"] = this.cartcombinationstotal[1];
                    this.pos_order.cart_companies[0]["productstotal"] = this.cart_total;
                    this.pos_order.cart_companies[0]["productstotalinc"] = this.cart_totalinc;
			    	this.pos_order.cart_companies[0]["products"] = this.cart.products;
			    	return this.createTerminalIntent();
			    },
			    async createPosOrder(confirmResult){
			    	axios.post('/api/company/sell/pos/order/create', {cart_id: this.saved_cart["cart"]["id"], cart_uid: this.saved_cart["cart"]["uid"], customer: this.customer, cart_companies: this.pos_order.cart_companies, total_inc: this.cart_totalinc, total_exc: this.cart_total, listing_id: this.settings.listing.id, user_id: this.user.companies[0].id}, {headers: {
			            'Authorization': 'Bearer ' + this.user.api_token
			        }}).then(response => {
						this.create_order_response = response.data;
						this.create_order_response["delivery_address"] = this.customer_address;
						this.create_order_response["invoice_address"] = this.customer_address;
						this.payment_progress = 60;
						if((this.payment_method == 'card') || (this.payment_method == 'contactless')){
								this.payment_method == '';
			   					return this.confirmPaymentResult(confirmResult); 

			    		}
						  
					}).catch(error => {
						this.order_create_error = true;
						this.loadingPaymentMethodWindow = false;
						console.log(error);
					});
			    	
			    },
			    async processOrder(){
			    	this.pos_order.order_totalinc = (parseFloat(this.cart_totalinc) + parseFloat(this.cartvariantstotal[1]) - parseFloat(this.couponvalueinc)).toFixed(2);
			    	if((this.payment_method == 'card') || (this.payment_method == 'contactless')){
			    				this.loadingPaymentMethodWindow = true;
			    				this.assemblePosOrder();
			    				
			    				// Process Terminal Payment
			    			
			    				
			    	}
			    	if(this.payment_method == 'cheque'){
			    		this.assemblePosOrder();
			    	}
			    	if(this.payment_method == 'cash'){
			    		this.assemblePosOrder();
			    	}
			    },
			    confirmOrderPaid(){
		    	axios.post('/api/company/sell/pos/order/confirm-payment',  {token_id: this.pendingPaymentIntentSecret.id, order: this.create_order_response}, {headers: {'Authorization' : 'Bearer '+this.user.api_token}}).then(response => {

		    			  let self = this;
				          setTimeout(function(){
				          	
				            //self.cartLoading = false;
				            //self.orderCreated = true;
				           	self.payment_progress = 100;
				            self.payment_process_msg = 'Order Complete';
				            self.paymentSuccess = true;
				            self.payment_method = '';
				            self.loadingPaymentMethodWindow = false;
				            //self.paymentMethodWindow = false;
				            self.pendingPaymentIntentSecret = null;
				            self.create_order_response["line_items"] = self.cart.products;
				            self.cart = self.cart_raw;
				            self.create_order_response["payments"] = [];
				            self.create_order_response["payments"].push(response.data);
				            self.cart_totalinc = 0.00;
				            self.cart_total = 0.00;
				            self.cartvariantstotal = [0.00, 0.00];
				            self.cartcombinationstotal = [0.00, 0.00];
				            self.couponvalueinc = 0.00;
				            return self.printOrderReceipt();
				            }, 1000);

				          	

			    	}).catch(error => {

			    	});
		    	},
		    	async printOrderReceipt(){
		    		this.printCardReceipt(1);
		    		//this.printTillReceipt(0);
		    	},
		    	async printTillReceipt(logo){
		    		var total_paid = parseFloat(this.create_order_response.payments[this.create_order_response.payments.length - 1].order_payments[this.create_order_response.payments[this.create_order_response.payments.length - 1].order_payments.length - 1].amount) + parseFloat(this.create_order_response.payments[this.create_order_response.payments.length - 1].order_payments[this.create_order_response.payments[this.create_order_response.payments.length - 1].order_payments.length - 1].fee);
		    		var card_network = this.create_order_response.payments[this.create_order_response.payments.length - 1].order_payments[this.create_order_response.payments[this.create_order_response.payments.length - 1].order_payments.length - 1].card_network;

		    		var receiptmarkup = '[feed: length 2mm]\\ [align: left][magnify: width 2; height 1]'+this.create_order_response.payments[this.create_order_response.payments.length - 1].order_payments[this.create_order_response.payments[this.create_order_response.payments.length - 1].order_payments.length - 1].payment_type.replaceAll('_', ' ')+'[magnify]\\ [magnify: width 2; height 1]'+this.create_order_response.payments[this.create_order_response.payments.length - 1].order_payments[this.create_order_response.payments[this.create_order_response.payments.length - 1].order_payments.length - 1].card_network+'[magnify]\\ [align: left]';
		    		
		    		receiptmarkup += '[feed: length 2mm]\\ [magnify: width 2; height 1]Sale[magnify]';
		    		receiptmarkup += '[column: left: Amount Paid; right: '+total_paid+']';
		    		receiptmarkup += '[column: left: Order Total.; right: '+this.create_order_response.company_orders[0].total_order_inc+']';
		    		receiptmarkup += '[feed: length 2mm]\\ ';
		    		//receiptmarkup += '[column: left: PAYMENT TYPE; right: '+this.create_order_response.payments[this.create_order_response.payments.length - 1].order_payments[this.create_order_response.payments[this.create_order_response.payments.length - 1].order_payments.length - 1].payment_type+']\ ';
		    		receiptmarkup += '[column: left: CHARGE REF; right: '+this.create_order_response.payments[this.create_order_response.payments.length - 1].order_payments[this.create_order_response.payments[this.create_order_response.payments.length - 1].order_payments.length - 1].charge_id+']';
		    		receiptmarkup += '[column: left: TRANSACTION REF; right: '+this.create_order_response.payments[this.create_order_response.payments.length - 1].order_payments[this.create_order_response.payments[this.create_order_response.payments.length - 1].order_payments.length - 1].charge_id+']\\ ';
		    		receiptmarkup += '[feed: length 2mm][cut] ';
									                	
		    		await this.createPrintJob(this.settings.printer.id, receiptmarkup, logo)
		    	},
		    	async printCardReceipt(logo){

		    		var receiptmarkup = '[feed: length 2mm]\\ [align: center][magnify: width 2; height 1]Items[magnify][align: left]';
		    		for(var index = 0; index < this.create_order_response.line_items.length; index++){
		    			receiptmarkup += '[column: left: '+this.create_order_response.line_items[index].product.reference+'; right: '+this.create_order_response.line_items[index].product.name+']\\ ';
		    			receiptmarkup += '[column: left: QTY '+ this.create_order_response.line_items[index].quantity+'; right: '+ this.create_order_response.line_items[index].price_inc+']\\ ';
		    		}
		    		receiptmarkup += '[feed: length 2mm]\\ [align: center][magnify: width 2; height 1]Order Total[magnify][align: left]';
		    		receiptmarkup += '[column: left: Total Tax Exc.; right: '+this.create_order_response.company_orders[0].total_order_exc+'] ';
		    		receiptmarkup += '[column: left: Total Tax Inc.; right: '+this.create_order_response.company_orders[0].total_order_inc+']\\ ';
		    		receiptmarkup += '[feed: length 2mm]\\ [align: center] \n [barcode: type qr; data {type: \'order-receipt\', reference: '+this.create_order_response.company_orders[0].reference+', uid: '+this.create_order_response.company_orders[0].uid+', id: '+this.create_order_response.company_orders[0].id+'}]';
		    		receiptmarkup += '[barcode: type code128; data '+this.company.id+'-'+this.create_order_response.company_orders[0].uid.slice(0,7)+'-'+this.create_order_response.company_orders[0].id+'][cut]';      
					var total_paid = parseFloat(this.create_order_response.payments[this.create_order_response.payments.length - 1].order_payments[this.create_order_response.payments[this.create_order_response.payments.length - 1].order_payments.length - 1].amount) + parseFloat(this.create_order_response.payments[this.create_order_response.payments.length - 1].order_payments[this.create_order_response.payments[this.create_order_response.payments.length - 1].order_payments.length - 1].fee);
		    		var card_network = this.create_order_response.payments[this.create_order_response.payments.length - 1].order_payments[this.create_order_response.payments[this.create_order_response.payments.length - 1].order_payments.length - 1].card_network;
		    		if(!card_network){
		    			card_network = 'Test Card';
		    		}
		    		receiptmarkup += '[feed: length 2mm]\\[align: left][magnify: width 2; height 1]'+this.create_order_response.payments[this.create_order_response.payments.length - 1].order_payments[this.create_order_response.payments[this.create_order_response.payments.length - 1].order_payments.length - 1].payment_type.replaceAll('_', ' ')+'[magnify]\\[magnify: width 2; height 1]'+card_network+'[magnify]\\[align: left]';
		    		
		    		receiptmarkup += '[feed: length 2mm] [magnify: width 2; height 1]Sale[magnify]';
		    		receiptmarkup += '[column: left: Amount Paid; right: '+total_paid+'] ';
		    		receiptmarkup += '[column: left: Order Total.; right: '+this.create_order_response.company_orders[0].total_order_inc+'] \\';
		    		receiptmarkup += '[feed: length 2mm] ';
		    		//receiptmarkup += '[column: left: PAYMENT TYPE; right: '+this.create_order_response.payments[this.create_order_response.payments.length - 1].order_payments[this.create_order_response.payments[this.create_order_response.payments.length - 1].order_payments.length - 1].payment_type+']\ ';
		    		receiptmarkup += '[align: left] CHARGE REF:\\ ';
		    		receiptmarkup += ''+this.create_order_response.payments[this.create_order_response.payments.length - 1].order_payments[this.create_order_response.payments[this.create_order_response.payments.length - 1].order_payments.length - 1].charge_id+'\\ ';
		    		receiptmarkup += '[align: left] TRANSACTION REF:\\ '; 
		    		receiptmarkup +=' '+this.create_order_response.payments[this.create_order_response.payments.length - 1].order_payments[this.create_order_response.payments[this.create_order_response.payments.length - 1].order_payments.length - 1].charge_id+'\\ ';
		    		receiptmarkup += '[feed: length 2mm]';				                	
		    		await this.createPrintJob(this.settings.printer.id, receiptmarkup, logo)
		    	},
		    	async createPrintJob(printer_id, data, logo){
		    		axios.post('/api/company/sell/printer/job/'+printer_id+'/create', {type: 'Receipt', content: data, status: 'Queued', show_logo: logo}, {headers: {'Authorization' : 'Bearer '+this.user.api_token}} ).then(response => {
		    			return response.data;
		    		}).catch(error => {
		    			return error.data;
		    		});
		    	},
			    saveCart(){
			    	//this.recent_carts.push(this.cart);
			    	//window.sessionStorage.setItem("pos-recentcarts", JSON.stringify(this.recent_carts));
			    	this.cart.customer = this.customer;
			    	this.cart.listing = this.settings.listing;
			    	axios.post('/api/company/sell/pos/cart/create', this.cart, {headers: {
			    		'Authorization' : 'Bearer '+this.user.api_token
			    	}}).then(response => {
			    		this.saved_cart = response.data;
			    	}).catch(error => {

			    	});
			    	//var recentcarts = window.sessionStorage.getItem("pos-recentcarts");
			    	//var allcarts = JSON.parse(recentcarts);
			    	//allcarts.push(this.carts);
			    	
			    },
			    async loadListings(){
			    	await axios.get('/api/company/sell/pos/listings', {headers: {
			    		'Authorization' : 'Bearer ' + this.user.api_token
			    	}}).then(response => {
			    		this.listings = response.data;
			    		return response.data;
			    	}).catch(error => {

			    	});
			    },
			     async loadAllListings(){
			    	await axios.get('/api/company/listings?limit=0', {headers: {
			    		'Authorization' : 'Bearer ' + this.user.api_token
			    	}}).then(response => {
			    		this.listings = response.data;
			    		return response.data;
			    	}).catch(error => {

			    	});
			    },
			    listingSelected(payload){
			    	this.settings.company_listing_id = payload.id;
			    	this.settings.listing = payload;
			    	this.cart.listing = payload;
			    	let self = this;
					setTimeout(function(){
				    	self.terminalsRegistersForm = true;
					}, 150);
			    },
			    registerSelected(){

			    },
			    terminalSelected(payload){
			    	this.settings.terminal = payload;
			    },
			    printerSelected(payload){
			    	this.settings.printer = payload;
			    },
			    productAtThisListing(listings){
    				var index = -1;
    				var quantity_calc = 0;
    			  	if(listings.length > 0){
    			  		index = listings.filter(item => item.company_listing_id == this.settings.company_listing_id);
    			  		for(var i = 0; i < index.length; i++){
    			  			quantity_calc += index[i].quantity;
    			  		}
    				}
    				if(index.length > 0){
			      		return quantity_calc;
			      	}else{
			      		return 0;
			      	}
				}
		},
		computed: {
			availableStockListing(){
				return this.productOptionsData["listings"].filter(item => item.quantity > 0);
			},
			companyCurrency(){
		      var currency = this.$attrs.currencies.filter(item => item.id == this.company.trading_currency_id);
		      return currency[0].symbol;
		    },
		}
	}

</script>
<template>
	<Layout>

		<div v-if="!user" class="justify-content-center align-items-center d-flex w-100 bg-white position-fixed top-0 bottom-0">
		</div>
		</div>
		<div class="justify-content-center align-items-center d-flex w-100 bg-white position-fixed top-0 bottom-0" v-else-if="loggedIn == false">
			
			<b-row class="w-100">
			  <b-col md="8" xl="4" class="mx-auto">
			  	<b-card class="border-radius-1em border shadow-sm">
			  	  <div class="avatar-lg profile-user-wid mb-2 mx-auto" style="margin-top: -65px !important;">
		                  <img
		                  	v-if="company.logo" :src="storageurl + company.logo"
		                    alt
		                    class="img-thumbnail rounded-circle"
		                  />
		                   <img
		                  	v-else 
		                    src="/images/product-placeholder.png"
		                    alt
		                    class="img-thumbnail rounded-circle"
		                  />
		                </div>
			  	  <h3 class="text-center text-primary">Login to Point of Sale</h3>
			  	   <div v-if="loginStep == 0"><b-form-group label="User">
			  	  	<div class="media border-radius-1em border p-2">
			  	  		<img v-if="user.avatar" :src="user.avatar" class="rounded-circle avatar-sm mr-2">
			  	  		<img v-else src="/images/product-placeholder.png" class="rounded-circle avatar-sm mr-2">
			  	  		<h5 class="font-size-15 my-auto">{{user.name}} {{user.lastname}}</h5>
			  	  	</div>
			  	  </b-form-group>
			  	  <b-form-group label="Shop" class="mt-2">
			  	  	<SearchResource :id="settings.listing" :options="listings" :resource="'address'" @resource-selected="listingSelected"></SearchResource>
			  	  </b-form-group></div>
			  	  	<div v-if="terminalsRegistersForm">
					  	 <div v-if="loginStep == 0">
					  	 <b-form-group class="my-2" label="Register" v-if="settings.listing !== ''">
					  	  	<SearchResource :id="settings.register" :options="settings.listing.registers" :resource="'terminals'" @resource-selected="registerSelected"></SearchResource>
					  	  </b-form-group>
					  	  <b-form-group class="my-2" label="Terminal" v-if="settings.listing !== ''">
					  	  	<SearchResource :id="settings.terminal" :options="settings.listing.stripe_terminals" :resource="'terminals'" @resource-selected="terminalSelected"></SearchResource>
					  	  </b-form-group>
					  	  <b-form-group class="my-2" label="Printer" v-if="settings.listing !== ''">
					  	  	<div class="d-flex">
					  	  	<SearchResource class="w-100" :id="settings.printer" :options="settings.listing.printers" :resource="'terminals'" @resource-selected="printerSelected"></SearchResource>
					  	  	<a class="btn btn-primary" @click="testPrintReceipt()"><i class="bx bx-printer"></i> Test Print</a>
					  	  	</div>
					  	  </b-form-group>
					  	</div>
					  	  <!--<b-form-group class="my-2" label="User Pin">
					  	  	 <input v-model="user_pin" v-mask="'###-###'" type="text" class="form-control" />
					  	  </b-form-group>-->
					  	  	<div class="w-100" v-if="loginStep == 1">
					  	  <div  class="d-flex flex-column w-100 align-items-center pb-4">

								<b-form-group  class="my-2">
					  	  	 		<!--<input id="partitioned" v-model="user_pin" v-mask="'######'" type="password" class="form-control" />-->
					  	  	 		<div class="d-flex">
						  	  	 		<input v-model="user_pin[0]" v-mask="'#'" type="password" class="form-control mx-2" />
						  	  	 		<input v-model="user_pin[1]" v-mask="'#'" type="password" class="form-control mx-2" />
						  	  	 		<input v-model="user_pin[2]" v-mask="'#'" type="password" class="form-control mx-2" />
						  	  	 		<input v-model="user_pin[3]" v-mask="'#'" type="password" class="form-control mx-2" />
						  	  	 		<input v-model="user_pin[4]" v-mask="'#'" type="password" class="form-control mx-2" />
						  	  	 		<input v-model="user_pin[5]" v-mask="'#'" type="password" class="form-control mx-2" />
					  	  	 		</div>
					  	  		</b-form-group>
							</div>
						
							<div class="d-flex w-100">
								<b-button :disabled="user_pin.length > 5" variant="light" class="btn w-100 btn-light d-flex mx-1 mb-2" href="javascript:;" @click="user_pin.push('1')">
									<span class="font-size-20 my-auto mx-auto py-4">1</span>
								</b-button>
								<b-button :disabled="user_pin.length > 5" variant="light" class="btn w-100 btn-light d-flex mx-1 mb-2" href="javascript:;" @click="user_pin.push('2')">
									<span class="font-size-20 my-auto mx-auto py-4">2</span>
								</b-button>
								<b-button :disabled="user_pin.length > 5" variant="light" class="btn w-100 btn-light d-flex mx-1 mb-2" href="javascript:;" @click="user_pin.push('3')">
									<span class="font-size-20 my-auto mx-auto py-4">3</span>
								</b-button>
							</div>

							<div class="d-flex w-100">
								<b-button :disabled="user_pin.length > 5" variant="light" class="btn w-100 btn-light d-flex mx-1 mb-2" href="javascript:;" @click="user_pin.push('4')">
									<span class="font-size-20 my-auto mx-auto py-4">4</span>
								</b-button>
								<b-button :disabled="user_pin.length > 5" variant="light" class="btn w-100 btn-light d-flex mx-1 mb-2" href="javascript:;" @click="user_pin.push('5')">
									<span class="font-size-20 my-auto mx-auto py-4">5</span>
								</b-button>
								<b-button :disabled="user_pin.length > 5" variant="light" class="btn w-100 btn-light d-flex mx-1 mb-2" href="javascript:;" @click="user_pin.push('6')">
									<span class="font-size-20 my-auto mx-auto py-4">6</span>
								</b-button>
							</div>

							<div class="d-flex w-100">
								<b-button :disabled="user_pin.length > 5" variant="light" class="btn w-100 btn-light d-flex mx-1 mb-2" href="javascript:;" @click="user_pin.push('7')">
									<span class="font-size-20 my-auto mx-auto py-4">7</span>
								</b-button>
								<b-button :disabled="user_pin.length > 5" variant="light" class="btn w-100 btn-light d-flex mx-1 mb-2" href="javascript:;" @click="user_pin.push('8')">
									<span class="font-size-20 my-auto mx-auto py-4">8</span>
								</b-button>
								<b-button :disabled="user_pin.length > 5" variant="light" class="btn w-100 btn-light d-flex mx-1 mb-2" href="javascript:;" @click="user_pin.push('9')">
									<span class="font-size-20 my-auto mx-auto py-4">9</span>
								</b-button>
							</div>
							<div class="d-flex w-100">
								
								<b-button :disabled="user_pin.length > 5" variant="light" class="btn flex-grow-3 btn-light d-flex mx-1 mb-2" href="javascript:;" @click="user_pin.push('0')">
									<span class="font-size-20 my-auto mx-auto py-4">0</span>
								</b-button>
								<a class="btn flex-grow-1 btn-light d-flex mx-1 mb-2 w-25" href="javascript:;" @click="user_pin.splice(user_pin.length - 1, 1)">
									<span class="font-size-20 my-auto mx-auto py-4"><i class="bx bx-arrow-back font-size-20"></i></span>
								</a>
							</div>
						</div>
							<div class="d-flex w-100" v-if="loginStep == 1">
								<b-button :disabled="user_pin.length !== 6" variant="primary" class="btn w-100 btn-primary d-flex mx-1 mb-2" @click="posLogin()">
										<span class="font-size-20 my-auto mx-auto py-4">ENTER PIN</span>
								</b-button>
							</div>
					  	  <b-button v-if="loginStep == 0" variant="success" class="btn-rounded btn-block mt-2" @click="loginStep++">
					  	  	<span class="font-size-20 my-auto mx-auto py-4">Next Step</span>
					  	  </b-button>
			  		</div>
			  	 </b-card>
			  </b-col>
			</b-row>

				

		</div>
		<b-container class="px-0 pt-3" fluid v-else>
			
			<b-row class="position-fixed pos-product-frame overflow-hidden" :class="{'top-0' : menuShown == false }">
				

				<b-col md="7" class="h-100 overflow-auto pos-frame-inner-left">
					<SideSlidePanel :editPanel="printingPanel" :transitionName="'slide-fadepanelright'" :panelClass="'h-100 overflow-auto pos-frame-inner-left col-md-7 position-fixed z-index-5 bg-white'" @close-panel="printingPanel = !printingPanel">
						<b-row style="height: 80vh;" class="d-flex flex-column justify-content-center">
							<b-col md="8" class="d-flex flex-column justify-content-center mx-auto" >

							<b-form-group class="my-2" label="Printer" v-if="settings.listing !== ''">
					  	  	<div class="d-flex">
					  	  	<SearchResource class="w-100" :id="settings.printer" :options="settings.listing.printers" :resource="'terminals'" @resource-selected="printerSelected, getPrinterJobs()"></SearchResource>
					  	  		<a class="btn btn-primary" @click="testPrintReceipt()"><i class="bx bx-printer"></i> Test Print</a>
					  	  	</div>
					  	  </b-form-group>
								<b-list-group v-if="('data' in printerJobs)" class="widget-scrollbox" style="overflow-x: hidden;">
									<b-list-group-item v-for="job in printerJobs.data" class="d-flex">
										<div class="mr-2 my-auto">
										<h4 >{{job.type}} Job #JB{{job.id}}</h4>
										<p class="mb-0" >Printer ID: {{settings.printer.uid}}</p>
										<p >Printer MAC: {{settings.printer.mac}}</p>
										</div>
										<span class="my-auto badge badge-soft-success bg-success bg-soft text-success" v-if="job.printed == 1">Printed</span>
										<span class="my-auto badge badge-soft-warning bg-warning bg-soft text-dark" v-if="job.printed == 0">Queued</span>
										<span class="ml-auto my-auto mr-2">{{job.created_at  | luxon({ input: "server" })}}</span>
										<a class="btn btn-primary btn-rounded my-auto " @click="createPrintJob(settings.printer.id, job.content, job.show_logo)"> 
											<i class="bx bx-printer font-size-18"></i>
										</a>
									</b-list-group-item>
								</b-list-group>
							</b-col>
						</b-row>
					</SideSlidePanel>
					<SideSlidePanel :editPanel="settingsPanel" :transitionName="'slide-fadepanelright'" :panelClass="'h-100 overflow-auto pos-frame-inner-left col-md-7 position-fixed z-index-5 bg-white'" @close-panel="settingsPanel = !settingsPanel">
						<b-row style="height: 80vh;" class="d-flex flex-column justify-content-center">
							<b-col md="8" class="d-flex flex-column justify-content-center mx-auto" >
						<b-form-group label="User">
			  	  	<div class="media border-radius-1em border p-2 position-relative">
			  	  		<img v-if="user.avatar" :src="user.avatar" class="rounded-circle avatar-sm mr-2">
			  	  		<img v-else src="/images/product-placeholder.png" class="rounded-circle avatar-sm mr-2">
			  	  		<h5 class="font-size-15 my-auto">{{user.name}} {{user.lastname}}</h5>
			  	  		<div class="position-absolute top-0 bottom-0 right-0 d-flex">
			  	  	<a class="btn btn-light waves-effect waves-light w-sm" @click="switch_user = !switch_user">
			  	  		<i class="bx bx-user btn-block text-primary font-size-20"></i>
			  	  		Switch User
			  	  	</a>
			  	  	<a class="btn btn-light waves-effect waves-light w-sm text-danger">
			  	  		<i class="bx bx-exit btn-block text-danger font-size-20"></i>
			  	  		Logout
			  	  	</a>
			  	  	</div>
			  	  	</div>
			  	  </b-form-group>
			  	  <b-form-group label="Shop" class="mt-2" v-if="switch_user == false">
			  	  	<SearchResource :id="settings.listing" :options="listings" :resource="'address'" @resource-selected="listingSelected"></SearchResource>
			  	  	
			  	  </b-form-group>
			  	  	<div v-if="switch_user == false && terminalsRegistersForm">
					  	  <b-form-group class="my-2" label="Register" v-if="settings.listing !== ''">
					  	  	<SearchResource :id="settings.register" :options="settings.listing.registers" :resource="'terminals'" @resource-selected="registerSelected"></SearchResource>
					  	  </b-form-group>
					  	  <b-form-group class="my-2" label="Terminal" v-if="settings.listing !== ''">
					  	  	<SearchResource :id="settings.terminal" :options="settings.listing.stripe_terminals" :resource="'terminals'" @resource-selected="terminalSelected"></SearchResource>
					  	  	<div v-if=
					  	  	"terminal['connectionStatus'] == 'connected'" class="d-flex mt-2">
					  	  		<i class="mdi mdi-circle mr-2 text-success ml-auto" ></i>
					  	  		Connected to {{settings.terminal.name}}
					  	    </div>
					  	  	<div  v-if=
					  	  	"terminal['connectionStatus'] == 'not_connected'" class="d-flex mt-2">
					  	  		<i class="mdi mdi-circle mr-2 text-danger ml-auto" ></i>
					  	  		Not Connected 
					  	   </div>
					  	  	
					  	  </b-form-group>
					  	  <b-form-group class="my-2" label="Printer" v-if="settings.listing !== ''">
					  	  	<div class="d-flex">
					  	  	<SearchResource class="w-100" :id="settings.printer" :options="settings.listing.printers" :resource="'terminals'" @resource-selected="printerSelected"></SearchResource>
					  	  		<a class="btn btn-primary" @click="testPrintReceipt()"><i class="bx bx-printer"></i> Test Print</a>
					  	  	</div>
					  	  </b-form-group>
					  	  
					  	  <b-button variant="success" class="btn-rounded btn-block mt-2" @click="updatePosSettings()">
					  	  	Update Settings
					  	  </b-button>
			  		</div>

			  		<div v-if="(switch_user == true)">
			  			<b-row>
			  				<b-col md="12">
			  					<h5 class="mx-auto my-auto text-center mt-4 mb-3">Scan Login Card or Enter ID</h5>
			  				</b-col>
			             			<b-col>
			             				<b-card class="border-radius-1em border shadow-none" :class="{'border-primary border-2 text-primary' : scanner_mode == 'camera'}" @click="scanner_mode = 'camera'">
			             					<i class="mdi mdi-camera-enhance-outline"></i>
			             					Camera
			             				</b-card>
			             			</b-col>
			             			<b-col>
			             				<b-card class="border-radius-1em border shadow-none" @click="scanner_mode = 'scanner', onScan()" :class="{'border-primary border-2 text-primary' : scanner_mode == 'scanner'}">

			             					<i class="mdi mdi-barcode-scan"></i>
			             					Scanner
			             				</b-card>
			             			</b-col>
			             			<b-col>
			             				<b-card class="border-radius-1em border shadow-none" :class="{'border-primary border-2 text-primary' : scanner_mode == 'manual'}" @click="scanner_mode = 'manual'">
			             					<i class="mdi mdi-camera-enhance-outline"></i>
			             					Enter ID
			             				</b-card>
			             			</b-col>
			             		</b-row>
			             		<b-row v-if="scanner_mode == 'camera'">
			             			<b-col md="12" style="min-height: 380px;">
			             				<b-overlay :show="scanner_busy" class="w-75" variant="white" opacity="1">
												<template #overlay>
													<b-row>
								      			<b-col md="12" >
								      			<b-card class="border-radius-1em border shadow-none mb-0">	
										            <div class="cube-wrapper mx-auto"><div class="cube-folding"><span class="leaf1"></span><span class="leaf2"></span><span class="leaf3"></span><span class="leaf4"></span></div></div>
										            <h6 class="text-center" >Scanning in Progress. Please wait...</h6>
										             <b-progress class="w-50 mx-auto" v-model="scanner_progress" variant="primary"></b-progress>
								         		</b-card>
								      			</b-col>
								      			</b-row>
												</template>
							              <StreamBarcodeReader 
							              	v-if="scanner_busy == false"
										    @decode="onDecode"
										    @loaded="onLoaded"
										></StreamBarcodeReader>
									</b-overlay>
									</b-col>
									
								</b-row>
								<b-row v-if="scanner_mode == 'scanner'">
									<b-col md="12" style="min-height: 380px;">
										<b-card class="border-radius-1em border shadow-none h-100" body-class="d-flex flex-column justify-content-center align-items-center mx-4">
											<h5 class="text-primary mb-3 text-center">
												Please Use Your Handheld Barcode Scanner
											</h5>
											<b-overlay :show="scanner_busy" class="w-75" variant="white" opacity="1">
												<template #overlay>
													<b-row>
			      			<b-col md="12" >
			      			<b-card class="border-radius-1em border shadow-none mb-0">	
					            <div class="cube-wrapper mx-auto"><div class="cube-folding"><span class="leaf1"></span><span class="leaf2"></span><span class="leaf3"></span><span class="leaf4"></span></div></div>
					            <h6 class="text-center">Scanning in Progress. Please wait...</h6>
					             <b-progress class="w-50 mx-auto" v-model="scanner_progress" variant="primary"></b-progress>
			         		</b-card>
			      			</b-col>
			      			</b-row>
												</template>
												<input ref="barcode_scan_response" id="barcode_scan_response" name="barcode_scan_response" class="form-control w-75 mx-auto" type="text" v-model="scanner_value" @keydown.{="setScannerBusy()" @keydown.}="handleScanner()">
											</b-overlay>
										</b-card>
									</b-col>
								</b-row>
								<b-row v-if="scanner_mode == 'manual'"> 
								</b-row>
								<b-row>
									<b-col md="12" class="d-flex">
										<a class="btn btn-danger btn-rounded ml-auto" @click="loadingScanner = true, $bvModal.hide('product-code-reader')">Close <i class="bx bx-x"></i></a>
									</b-col>
								</b-row>
			  		</div>

			  	</b-col>
			  </b-row>
					</SideSlidePanel>
					<SideSlidePanel :editPanel="orderViewWindow" :transitionName="'slide-fadepanelright'" :panelClass="'h-100 overflow-auto pos-frame-inner-left col-md-7 position-fixed z-index-5 bg-white'" @close-panel="orderViewWindow = !orderViewWindow">
						<OrderView v-if="selectedorder !== ''" :id="selectedorder.uid" :company="company" :user="user"></OrderView>
					</SideSlidePanel>
					<transition name="slide-fadepanel">
						<div class="position-sticky top-0 right-0 z-index-1" v-if="showCalculator == true">
							<div class="position-absolute p-2 right-0">
							<div class="w-100 d-flex mb-2">
								<a href="javascript:;" @click="showCalculator = !showCalculator" class="ml-auto btn btn-primary p-3 rounded-circle"><i class="bx bx-x font-size-20"></i></a>
							</div>
								<StandardCalculator></StandardCalculator>
							</div>
						</div>
					</transition>
					<b-row class="my-3">
						
						<div class="col-lg-6 col-sm-6 d-flex">
							<b-button variant="primary" class="btn-rounded mr-2 my-auto d-flex" @click="searchboxPanel = !searchboxPanel">
								<i class="bx bx-search-alt"></i>
								 Search
							</b-button>
							<b-dropdown variant="primary" class="btn-rounded mr-2">
								<template #button-content>
									Scan <i class="bx bx-barcode"></i>
								</template>
								<b-dropdown-item  v-b-modal.product-code-reader @click="scanner_mode = 'camera'">
									Camera <i class="bx bx-camera"></i>
								</b-dropdown-item>
								<b-dropdown-item  v-b-modal.product-code-reader @click="scanner_mode = 'scanner', scanner_value = '', onScan()">
									Scanner <i class="bx bx-barcode"></i>
								</b-dropdown-item>
							</b-dropdown>
							
				            
				         	
				         	<b-form-group class="my-auto">
					         	<b-form-radio-group
					         	v-model="productsinthisstore"
					         	:options="[{text: 'This Store', value: true}, {text: 'All Stores', value: false}]"
					         	@input="productsCurrentPage = 1, loadProducts()"
					         	buttons
					         	button-variant="outline-primary"
					         	class="pill-btn-group my-auto"
					         	>

					         	</b-form-radio-group>
				         	</b-form-group>
				         </div>
				          <div class="col-lg-2 col-sm-6 d-flex">
				          <form class="my-auto">
				            <small class="mx-auto my-2 text-center d-block">{{productsData.from}} - {{productsData.to}} of {{productsData.total}}</small>
				          </form>
				        </div>
				       
				          <div class="col-lg-3 col-sm-6 d-flex">
				            <div class="d-flex ml-auto">
				              <ul class="nav nav-pills product-view-nav">
				                <li class="nav-item">
				                  <a class="nav-link" :class="{'active': showFilters == true }" href="javascript: void(0);" @click="loadAllCategories(), showFilters = !showFilters">
				                    <i class="bx bx-filter-alt"></i>
				                  </a>
				                </li>
				                <li class="nav-item">
				                  <a class="nav-link" :class="{'active': productview == 'grid' }" href="javascript: void(0);" @click="productview = 'grid'">
				                    <i class="bx bx-grid-alt"></i>
				                  </a>
				                </li>
				                <li class="nav-item">
				                  <a class="nav-link" :class="{'active': productview == 'list' }" href="javascript: void(0);" @click="productview = 'list'">
				                    <i class="bx bx-list-ul"></i>
				                  </a>
				                </li>
				              </ul>
				              <div class="text-sm-right">
				                  
				                </div>
				          </div>
				        </div>
				         <div class="col-xl-1 col-sm-6">
				            <div class="my-auto">
				              <b-form-select class="form-control border btn-rounded" v-model="limit" :options="limits" @input="loadProducts()"></b-form-select>
				            </div>
				          </div>
				          <transition name="fade-left">
				          	<div class="col-md-12" v-if="searchboxPanel == true">
							<div class="d-flex mt-2">
							
							<transition name="fade-left">
								<div v-if="searchboxPanel == true" class="w-100 my-auto">
									<div class="my-auto form-inline w-100" >
							              <div class="search-box mr-3">
							                <div class="position-relative">
							                  <input
							                  	
							                    type="text"
							                    class="form-control w-100 border"
							                    placeholder="Search name, reference or description..."
							                    v-model="search"
							                    @keyup.enter="loadProducts($event)"
							                  />
							                  <i class="bx bx-search-alt search-icon" ></i>
							                </div>
							              </div>
							            </div>
								</div>
							</transition>
							<transition name="fade-left">
							<a class="btn btn-primary btn-md my-auto btn-rounded ml-2 mr-2" v-if="searchboxPanel == true" @click="loadProducts()">
								<i  class="bx bx-search font-size-16 py-1"></i>
							</a>
							</transition>
							<a class="btn btn-primary btn-md my-auto btn-rounded ml-auto mr-0" @click="searchboxPanel = !searchboxPanel">
								<i v-if="searchboxPanel == false" class="bx bx-search font-size-20 py-2"></i>
								<i v-else class="bx bx-x font-size-16 py-1"></i>
							</a>
							</div>
						</div>
						</transition>
					</b-row>
					
					 <b-overlay
			                            id="overlay-background"
			                            :show="loadingForm"
			                            variant="white"
			                            opacity="0.85"
			                            blur="1px"
			                            rounded="md"
			                          >
			              <ProductList :product_list="productsData.data" :view="productview" :expanded="1" :pos_settings="settings" :company="company"></ProductList>
			            </b-overlay>
			            <b-pagination
			              v-if="productsData.data.length > 0"
			              class="justify-content-center pb-4"
			              pills
			              size="lg"
			              v-model="productsCurrentPage"
			              :total-rows="productsData.total"
			              :per-page="productsData.per_page"
			              aria-controls="my-table"
			              @input="loadProducts"
			            ></b-pagination>
			             <b-modal id="product-code-reader" hide-footer hide-header size="lg" >
			             	<b-overlay :show="loadingScanner" style="min-height: 380px;" variant="white" :opacity="1">
			             		<b-row>
			             			<b-col>
			             				<b-card class="border-radius-1em border shadow-none" :class="{'border-primary border-2 text-primary' : scanner_mode == 'camera'}" @click="scanner_mode = 'camera'">
			             					<i class="mdi mdi-camera-enhance-outline"></i>
			             					Camera
			             				</b-card>
			             			</b-col>
			             			<b-col>
			             				<b-card class="border-radius-1em border shadow-none" @click="scanner_mode = 'scanner', onScan()" :class="{'border-primary border-2 text-primary' : scanner_mode == 'scanner'}">

			             					<i class="mdi mdi-barcode-scan"></i>
			             					Scanner
			             				</b-card>
			             			</b-col>
			             		</b-row>
			             		<b-row v-if="scanner_mode == 'camera'">
			             			<b-col md="12" style="min-height: 380px;">
							              <StreamBarcodeReader 
										    @decode="onDecode"
										    @loaded="onLoaded"
										></StreamBarcodeReader>
									</b-col>
									<b-col md="12" class="d-flex">
										<a class="btn btn-danger btn-rounded ml-auto" @click="loadingScanner = true, $bvModal.hide('product-code-reader')">Close <i class="bx bx-x"></i></a>
									</b-col>
								</b-row>
								<b-row v-if="scanner_mode == 'scanner'">
									<b-col md="12" style="min-height: 380px;">
										<b-card class="border-radius-1em border shadow-none h-100" body-class="d-flex flex-column justify-content-center align-items-center mx-4">
											<h5 class="text-primary mb-3 text-center">
												Please Use Your Handheld Barcode Scanner
											</h5>
											<b-overlay :show="scanner_busy" class="w-75" variant="white" opacity="1">
												<template #overlay>
													<b-row>
			      			<b-col md="12" >
			      			<b-card class="border-radius-1em border shadow-none mb-0">	
					            <div class="cube-wrapper mx-auto"><div class="cube-folding"><span class="leaf1"></span><span class="leaf2"></span><span class="leaf3"></span><span class="leaf4"></span></div></div>
					            <h6 class="text-center">Scanning in Progress. Please wait...</h6>
					             <b-progress class="w-50 mx-auto" v-model="scanner_progress" variant="primary"></b-progress>
			         		</b-card>
			      			</b-col>
			      			</b-row>
												</template>
												<input ref="barcode_scan_response" id="barcode_scan_response" name="barcode_scan_response" class="form-control w-75 mx-auto" type="text" v-model="scanner_value" @keydown.{="setScannerBusy()" @keydown.}="handleScanner()">
											</b-overlay>
										</b-card>
									</b-col>
								</b-row>
							</b-overlay>
						</b-modal>
				<SideSlidePanel :editPanel="showKeyPad" :panelClass="'pos-sidepanel'" @close-panel="showKeyPad = !showKeyPad">
					<b-row>
						<b-col md="8" class="mx-auto d-flex flex-column justify-content-center inner-window-list">
							<div class="d-flex flex-column w-100">
								<h4 class="text-center">Enter User Pin</h4>
							</div>
							<div  class="d-flex flex-column w-100 align-items-center pb-4">

								<b-form-group  class="my-2">
					  	  	 		<!--<input id="partitioned" v-model="user_pin" v-mask="'######'" type="password" class="form-control" />-->
					  	  	 		<div class="d-flex">
						  	  	 		<input v-model="user_pin[0]" v-mask="'#'" type="password" class="form-control mx-2" />
						  	  	 		<input v-model="user_pin[1]" v-mask="'#'" type="password" class="form-control mx-2" />
						  	  	 		<input v-model="user_pin[2]" v-mask="'#'" type="password" class="form-control mx-2" />
						  	  	 		<input v-model="user_pin[3]" v-mask="'#'" type="password" class="form-control mx-2" />
						  	  	 		<input v-model="user_pin[4]" v-mask="'#'" type="password" class="form-control mx-2" />
						  	  	 		<input v-model="user_pin[5]" v-mask="'#'" type="password" class="form-control mx-2" />
					  	  	 		</div>
					  	  		</b-form-group>
							</div>

							<div class="d-flex w-100">
								<b-button :disabled="user_pin.length > 5" variant="light" class="btn w-100 btn-light d-flex mx-1 mb-2" href="javascript:;" @click="user_pin.push('1')">
									<span class="font-size-20 my-auto mx-auto py-4">1</span>
								</b-button>
								<b-button :disabled="user_pin.length > 5" variant="light" class="btn w-100 btn-light d-flex mx-1 mb-2" href="javascript:;" @click="user_pin.push('2')">
									<span class="font-size-20 my-auto mx-auto py-4">2</span>
								</b-button>
								<b-button :disabled="user_pin.length > 5" variant="light" class="btn w-100 btn-light d-flex mx-1 mb-2" href="javascript:;" @click="user_pin.push('3')">
									<span class="font-size-20 my-auto mx-auto py-4">3</span>
								</b-button>
							</div>

							<div class="d-flex w-100">
								<b-button :disabled="user_pin.length > 5" variant="light" class="btn w-100 btn-light d-flex mx-1 mb-2" href="javascript:;" @click="user_pin.push('4')">
									<span class="font-size-20 my-auto mx-auto py-4">4</span>
								</b-button>
								<b-button :disabled="user_pin.length > 5" variant="light" class="btn w-100 btn-light d-flex mx-1 mb-2" href="javascript:;" @click="user_pin.push('5')">
									<span class="font-size-20 my-auto mx-auto py-4">5</span>
								</b-button>
								<b-button :disabled="user_pin.length > 5" variant="light" class="btn w-100 btn-light d-flex mx-1 mb-2" href="javascript:;" @click="user_pin.push('6')">
									<span class="font-size-20 my-auto mx-auto py-4">6</span>
								</b-button>
							</div>

							<div class="d-flex w-100">
								<b-button :disabled="user_pin.length > 5" variant="light" class="btn w-100 btn-light d-flex mx-1 mb-2" href="javascript:;" @click="user_pin.push('7')">
									<span class="font-size-20 my-auto mx-auto py-4">7</span>
								</b-button>
								<b-button :disabled="user_pin.length > 5" variant="light" class="btn w-100 btn-light d-flex mx-1 mb-2" href="javascript:;" @click="user_pin.push('8')">
									<span class="font-size-20 my-auto mx-auto py-4">8</span>
								</b-button>
								<b-button :disabled="user_pin.length > 5" variant="light" class="btn w-100 btn-light d-flex mx-1 mb-2" href="javascript:;" @click="user_pin.push('9')">
									<span class="font-size-20 my-auto mx-auto py-4">9</span>
								</b-button>
							</div>
							<div class="d-flex w-100">
								
								<b-button :disabled="user_pin.length > 5" variant="light" class="btn flex-grow-3 btn-light d-flex mx-1 mb-2" href="javascript:;" @click="user_pin.push('0')">
									<span class="font-size-20 my-auto mx-auto py-4">0</span>
								</b-button>
								<a class="btn flex-grow-1 btn-light d-flex mx-1 mb-2 w-25" href="javascript:;" @click="user_pin.splice(user_pin.length - 1, 1)">
									<span class="font-size-20 my-auto mx-auto py-4"><i class="bx bx-arrow-back font-size-20"></i></span>
								</a>
							</div>
							<div class="d-flex w-100">
								<b-button :disabled="user_pin.length !== 6" variant="primary" class="btn w-100 btn-primary d-flex mx-1 mb-2" @click="initiateSwitchUser()">
										<span class="font-size-20 my-auto mx-auto py-4">ENTER PIN</span>
								</b-button>
							</div>
						</b-col>
					</b-row>
				</SideSlidePanel>		
				<SideSlidePanel :editPanel="cartsWindow" :panelClass="'pos-sidepanel'" @close-panel="cartsWindow = !cartsWindow">
					<b-row>
						<b-col md="12">
							<h4>Saved Carts</h4>
							 <div  >
				              	<div class="mt-sm-0 form-inline w-100 d-flex mb-2" >
					              <div class="search-box ml-auto w-100">
					                <div class="position-relative mr-2">
					                  <input
					                    type="text"
					                    class="form-control w-100 border-0 bg-light"
					                    placeholder="Search saved carts"
					                    v-model="cartsearch_query" @keydown.enter="loadCarts()" 
					                  />
					                  <i class="bx bx-search-alt search-icon"></i>
					                </div>
					              </div>
					              <a class="btn btn-primary btn-rounded" @click="loadCarts()" >Search</a>
					            </div>
					        </div>
							<b-card v-if="saved_carts.length == 0" class="border-radius-1em border shadow-none" body-class="text-center py-4 d-flex flex-column justify-content-center" style="min-height: 300px;">
								<i class="mdi mdi-cart-variant font-size-42 text-primary d-block"></i>
								<p class="text-muted mb-0">No Saved Carts</p>
							</b-card>
							<b-card v-else class="border-radius-1em border shadow-none" body-class="text-center py-4 d-flex flex-column justify-content-center" style="min-height: 300px;">
								<simplebar style="min-height: 300px; max-height: 300px;">
									<b-list-group>
										<b-list-group-item v-for="cart in saved_carts">
											<div class="media">
												<div class="avatar-sm">
													<span class="avatar-title rounded-circle">
														<i class="bx bx-shopping-bag"></i>
													</span>
												</div>	
												<div class="media-body d-flex my-auto ml-3 flex-column">
													<p class="w-100 my-auto text-left">{{cart.uid}}</p>
													<a class="text-left" v-if="(cart.customer_id !== 0) && (cart.customer_id !== null)">
														{{cart.customer.name}}<br>
														<span class="text-primary">{{cart.customer.email}}</span>
													</a>
													<small class="text-left">Date Created: {{cart.created_at | luxon({input: 'server'})}}</small>
													<small class="text-left">Created By: <span v-if="cart.company_user !== null">{{cart.company_user.user.name}} {{cart.company_user.user.lastname}}</span></small>
												</div>
												<a class="btn btn-light my-auto text-left" v-b-tooltip.focus title="Use Cart">
													<i class="mdi mdi-plus"></i>
												</a>
											</div>
										</b-list-group-item>
									</b-list-group>
								</simplebar>

							</b-card>
						</b-col>
						<b-col md="12">
							<h4>Recent Carts</h4>
							<div  >
				              	<div class="mt-sm-0 form-inline w-100 d-flex mb-2" >
					              <div class="search-box ml-auto w-100">
					                <div class="position-relative mr-2">
					                  <input
					                    type="text"
					                    class="form-control w-100 border-0 bg-light"
					                    placeholder="Search recent carts"
					                    v-model="cartsearch_query" @keydown.enter="loadCarts()" 
					                  />
					                  <i class="bx bx-search-alt search-icon"></i>
					                </div>
					              </div>
					              <a class="btn btn-primary btn-rounded" @click="loadCarts()" >Search</a>
					            </div>
					        </div>
							<b-card v-if="recent_carts.length == 0"  class="border-radius-1em border shadow-none" body-class="text-center py-4 d-flex flex-column justify-content-center"  style="min-height: 300px;">
								<i class="mdi mdi-cart-variant font-size-42 text-primary d-block"></i>
								<p class="text-muted mb-0">No Recent Carts</p>
							</b-card>
							<b-card v-else class="border-radius-1em border shadow-none" body-class="text-center py-4 d-flex flex-column justify-content-center" style="min-height: 300px;">
								<simplebar style="min-height: 300px; max-height: 300px;">
									<b-list-group>
										<b-list-group-item v-for="cart in recent_carts">
											<div class="media">
												<div class="avatar-sm">
													<span class="avatar-title rounded-circle">C</span>
												</div>	
												<div class="media-body d-flex my-auto ml-3 flex-column">
													<p class="w-50 my-auto text-left">Test Cart</p>
													<a class="text-left">
														Customer Name<br>
														<span class="text-primary">customer@email.com</span>
													</a>
												</div>
												<a class="btn btn-light my-auto text-left" v-b-tooltip.focus title="Use Cart">
													<i class="mdi mdi-plus"></i>
												</a>
											</div>
										</b-list-group-item>
									</b-list-group>
								</simplebar>

							</b-card>
						</b-col>
					</b-row> 
				</SideSlidePanel>
				<SideSlidePanel :editPanel="ordersWindow" :panelClass="'pos-sidepanel'" @close-panel="ordersWindow = !ordersWindow">
					<b-row class="inner-window-list">
						
						<b-col md="12">
							<h4>Orders</h4>
							<div  >
				              	<div class="mt-sm-0 form-inline w-100 d-flex mb-2" >
					              <div class="search-box ml-auto flex-grow-4">
					                <div class="position-relative mr-2">
					                  <input
					                    type="text"
					                    class="form-control w-100 border-0 bg-light"
					                    placeholder="Search recent carts"
					                    v-model="cartsearch_query" @keydown.enter="loadCarts()" 
					                  />
					                  <i class="bx bx-search-alt search-icon"></i>
					                </div>
					              </div>
					              <a class="btn btn-primary btn-rounded flex-grow-1" @click="loadCarts()" >Search</a>
					              
					              <b-dropdown variant="variant" class="btn-rounded flex-grow-1 ml-1">
								<template #button-content>
									Scan Receipt <i class="bx bx-barcode"></i>
								</template>
								<b-dropdown-item  v-b-modal.product-code-reader @click="scanner_mode = 'camera'">
									Camera <i class="bx bx-camera"></i>
								</b-dropdown-item>
								<b-dropdown-item  v-b-modal.product-code-reader @click="scanner_mode = 'scanner', scanner_value = '', onScan()">
									Scanner <i class="bx bx-barcode"></i>
								</b-dropdown-item>
							</b-dropdown>
					            </div>
					        </div>
					        <b-card class="border-radius-1em border shadow-none" body-class="d-flex flex-column">
									
									<div class="d-flex w-100">
										<label class="mr-auto">Active Filters</label>
										<b-dropdown variant="outline-light" menu-class="dropdown-menu-right" class="ml-auto">
										<template #button-content>
											Dates
										</template>
										<b-dropdown-form style="width: 250px;">
											  <div class="m-3">
											  	<label>Start Date</label>
				                                <b-form-input type="date" v-model="ordersstartdate">
				                                </b-form-input>
				                				<label class="mt-2">End Date</label>
				                                <b-form-input type="date" v-model="ordersenddate">
				                                </b-form-input>
				                                <a class="btn btn-primary btn-rounded w-100 mt-2" @click="getOrders()">
				                                    Update
				                                </a>
				                            </div>
										</b-dropdown-form>
										<b-dropdown-item @click="ordersstartdate = '', ordersenddate = '', getOrders()" >
											Clear All
										</b-dropdown-item>
									</b-dropdown>
									<b-dropdown variant="outline-light" menu-class="dropdown-menu-right" class="ml-2">
										<template #button-content>
											Payment
										</template>
										<b-dropdown-header>
											Payment Status
										</b-dropdown-header>
										<b-dropdown-item @click="searchorderpaid = 1, orderscurrentPage = 1, getOrders()" >
											Paid
										</b-dropdown-item>
										<b-dropdown-item @click="searchorderpaid = 0, orderscurrentPage = 1, getOrders()" >
											Upaid
										</b-dropdown-item>
										<b-dropdown-item @click="searchorderpaid = '', orderscurrentPage = 1, getOrders()" >
											Any
										</b-dropdown-item>
										<b-dropdown-divider></b-dropdown-divider>
										<b-dropdown-header>
											Payment Method
										</b-dropdown-header>
										<b-dropdown-item @click="searchpaidby = 'cash', orderscurrentPage = 1, getOrders()" >
											Cash
										</b-dropdown-item>
										<b-dropdown-item @click="searchpaidby = 'cheque', orderscurrentPage = 1, getOrders()" >
											Cheque
										</b-dropdown-item>
										<b-dropdown-item @click="searchpaidby = 'card', orderscurrentPage = 1, getOrders()" >
											Card
										</b-dropdown-item>
										<b-dropdown-item @click="searchpaidby = 'card_present', orderscurrentPage = 1, getOrders()" >
											Card Terminal
										</b-dropdown-item>
										<b-dropdown-item disabled >
											Personal Finance
										</b-dropdown-item>
										<b-dropdown-item @click="searchpaidby = '', orderscurrentPage = 1, getOrders()" >
											Any
										</b-dropdown-item>
										<b-dropdown-divider></b-dropdown-divider>
										<b-dropdown-item @click="searchpaidby = '', searchorderpaid = '', orderscurrentPage = 1, getOrders()" >
											Clear All
										</b-dropdown-item>
									</b-dropdown>
									<b-dropdown variant="outline-light" menu-class="dropdown-menu-right" class="ml-2">
										<template #button-content>
											Source
										</template>
										<b-dropdown-item @click="inthisstore = true, posorders = '', customerorders = '', websiteorders = '', tradeorders = '', orderscurrentPage = 1, getOrders()" >
											In This Store
										</b-dropdown-item>
										<b-dropdown-item @click="inthisstore = '', posorders = true, customerorders = '', websiteorders = '', tradeorders = '', orderscurrentPage = 1, getOrders()" >
											In All Stores
										</b-dropdown-item>
										<b-dropdown-item @click="inthisstore = '', posorders = true, customerorders = '', websiteorders = true, tradeorders = '',orderscurrentPage = 1, getOrders()" >
											In Stores & Online
										</b-dropdown-item>
										<b-dropdown-item @click="inthisstore = '', posorders = true, customerorders = true, websiteorders = true, tradeorders = true, orderscurrentPage = 1, getOrders()" >
											All Orders
										</b-dropdown-item>
										<b-dropdown-divider></b-dropdown-divider>
										<b-dropdown-item @click="inthisstore = '', posorders = '', customerorders = '', websiteorders = '', tradeorders = '',  orderscurrentPage = 1, getOrders()" >
											Clear All
										</b-dropdown-item>
									</b-dropdown>
									</div>
									<div class="filter-badges d-flex w-100 border-top mt-2 pt-2">
										<span class="d-flex my-auto badge font-size-11 mx-1 badge-light bg-light text-dark" v-if="inthisstore == true">
											<i class="font-size-20 bx bx-store text-primary"></i>
											<span class="my-auto ml-1">This Store</span>
										</span>
										<span class="d-flex my-auto badge font-size-11 mx-1 badge-light bg-light text-dark" v-if="posorders == true">
											<i class="font-size-20 bx bx-store text-primary"></i>
											<span class="my-auto ml-1">All Stores</span>
										</span>
										<span class="d-flex my-auto badge font-size-11 mx-1 badge-light bg-light text-dark" v-if="websiteorders == true">
											<i class="font-size-20 bx bx-store text-primary"></i>
											<span class="my-auto ml-1">Online</span>
										</span>
										<span class="d-flex my-auto badge font-size-11 mx-1 badge-light bg-light text-dark" v-if="customerorders == true">
											<i class="font-size-20 bx bx-store text-primary"></i>
											<span class="my-auto ml-1">Marketplaces</span>
										</span>
										<span class="d-flex my-auto badge font-size-11 mx-1 badge-soft-success bg-soft-success text-success" v-if="searchorderpaid == true">
										 <i class="font-size-20 bx bx-check-circle"></i>	<span class="my-auto ml-1">Paid</span>
										</span>
										<span class="d-flex my-auto badge font-size-11 mx-1  badge-soft-danger bg-soft-danger text-danger" v-if="(searchorderpaid == false) && (searchorderpaid !== '')">
										<i class="font-size-20 bx bx-x-circle"></i> <span class="my-auto ml-1">Not Paid</span>
										</span>
										<span class="d-flex my-auto badge font-size-11 mx-1  badge-soft-success bg-soft-success text-success text-capitalize" v-if="searchpaidby !== ''">
											<i class="font-size-20 bx bx-cash" v-if="searchpaidby == 'cash'"></i>
											<i class="font-size-20 bx bx-check" v-if="searchpaidby == 'cheque'"></i>
											<i class="font-size-20 bx bx-credit-card-alt" v-if="searchpaidby == 'card' || 'card_present'"></i>
											<span class="my-auto ml-1">{{searchpaidby}}</span>
										</span>
									</div>
									
								</b-card>
							<b-card v-if="'data' in orders && orders.data.length == 0"  class="border-radius-1em border shadow-none" body-class="text-center py-4 d-flex flex-column justify-content-center"  style="min-height: 300px;">
								<i class="mdi mdi-cart-variant font-size-42 text-primary d-block"></i>
								<p class="text-muted mb-0">No Recent Orders</p>
							</b-card>
							<b-card v-else-if="'data' in orders && orders.data.length > 0" class="border-radius-1em border shadow-none" body-class="text-center py-4 d-flex flex-column justify-content-center" style="min-height: 300px;">

								

								<simplebar style="min-height: 300px; max-height: 52vh;">
									<b-list-group>
										<b-list-group-item v-for="order in orders.data" @click="seeOrder(order)">
											<div class="media">
												<div class="media-body d-flex my-auto ml-3 flex-column text-left">
													<p class="w-100 my-auto text-left">{{order.uid}}</p>
													<small>Date Created: {{order.created_at | luxon({input: 'server'})}}</small>
													<div v-if="order.trade_orders.length > 0">
                        <a :href="'/seller/profile/'+order.trade_orders[0].order.customer.nickname+''"><h6 class="mb-0">{{order.trade_orders[0].order.customer.name}}</h6>
                        </a>
                      </div>
                      <div v-else-if="order.customer_orders.length > 0">
                         <a :href="'/customer/details/'+order.customer_orders[0].order.customer.id+''"><h6 class="mb-0">{{order.customer_orders[0].order.customer.name}} {{order.customer_orders[0].order.customer.lastname}}</h6>
                        </a>
                      </div>
                      <div v-else-if="order.website_orders.length > 0">
                         <a :href="'/customer/details/'+order.website_orders[0].order.customer.id+''"><h6 class="mb-0">{{order.website_orders[0].order.customer.name}} {{order.website_orders[0].order.customer.lastname}}</h6>
                        </a>
                      </div>
                      <div v-else-if="order.pos_orders.length > 0">
                         <a v-if="order.pos_orders[0].order.customer !== null" :href="'/customer/details/'+order.website_orders[0].order.customer.id+''"><h6 class="mb-0">{{order.website_orders[0].order.customer.name}} {{order.website_orders[0].order.customer.lastname}}</h6>
                        </a>
                      </div>
													<a v-else class="text-left" v-else>
														No Customer Information Provided
													</a>
												</div>
												<div class="my-auto mr-3">
													   <div v-if="order.trade_orders.length > 0">
						                       <span class="font-size-12 badge badge-light bg-light text-dark"  v-b-tooltip.hover title="Order from Trade Marketplace"><i class="bx bx-store text-primary"></i> Trade Marketplace</span>
						                      </div>
						                      <div v-if="order.customer_orders.length > 0">
						                        <span class="font-size-12 badge bg-primary bg-soft text-dark"  v-b-tooltip.hover title="Order from Gemesys Public Marketplace"><i class="bx bxs-store text-dark"></i> Public Marketplace</span>
						                      </div>
						                      <div v-if="order.website_orders.length > 0">
						                        <span class="font-size-12 badge bg-peach-soft text-primary" v-b-tooltip.hover title="Order from Your Website"><i class="bx bx-globe text-primary"></i> Website</span>
						                      </div>
						                      <div v-if="order.pos_orders.length > 0">
						                        <span class="font-size-12 badge bg-pos text-white" v-b-tooltip.hover title="Order from Your Point of Sale"><i class="bx bx-shopping-bag text-white"></i> In Store</span>
						                      </div>
												</div>
												
                    							
						                      
						                      <div class="my-auto mx-3 text-right">
						                      		<div>
                      									<span class="currency">{{companyCurrency}}</span>{{parseFloat(order.total_order_inc).toFixed(2)}}
                    								</div>
                    								<div class="my-auto mr-auto" v-if="order.payments.length > 0">
						                        <span v-if="order.payments[0].payment_type == 'card'" class="font-size-12 badge badge-soft-success text-success">
						                          <i class="bx bx-credit-card"></i>
						                          Card
						                        </span>
						                        <span v-if="order.payments[0].payment_type == 'card_present'" class="font-size-12 badge badge-soft-success text-success">
						                          <i class="bx bx-credit-card"></i>
						                          Card Terminal
						                        </span>
						                      </div>
						                      <div class="my-auto mr-auto" v-else>
						                         <span  class="font-size-12 badge badge-soft-danger text-danger">
						                          <i class="bx bx-no-entry"></i> No Payment Made
						                         </span>
						                      </div>

                    							</div>
                 

												<a class="btn btn-light my-auto text-left" v-b-tooltip.focus title="See Order" >
													<i class="mdi mdi-eye-outline"></i>
												</a>
											</div>
										</b-list-group-item>
									</b-list-group>
								</simplebar>
								<b-pagination class="mt-4" v-model="orderscurrentPage" size="lg" align="center" :total-rows="orders.total" :per-page="20" @input="getOrders()">
									
								</b-pagination>
							</b-card>
						</b-col>
					</b-row> 
				</SideSlidePanel>
				<SideSlidePanel :editPanel="customerListWindow" :panelClass="'pos-sidepanel'" @close-panel="customerListWindow = !customerListWindow"> 
				</SideSlidePanel>
				<div class="position-fixed left-0 bottom-0 z-index-5 m-2 col-md-7">
					<div class="d-flex">
					<a class="btn btn-primary btn-lg btn-rounded" @click="settingsPanel = !settingsPanel">
						<i class="bx bx-cog font-size-20 py-2"></i>
					</a>
					<a class="btn btn-primary btn-lg btn-rounded ml-3" @click="getPrinterJobs(), printingPanel = !printingPanel">
						<i class="bx bx-printer font-size-20 py-2"></i>
					</a>
					<transition name="fade-left">
						<div v-if="settingsPanel == true">
							<a class="btn btn-primary btn-lg btn-rounded ml-2 mr-2" v-if="searchboxPanel == true" @click="loadProducts()">
								<i  class="bx bx-search font-size-20 py-2"></i>
							</a>
						</div>
					</transition>
					<!--<transition name="fade-left">
						<div v-if="searchboxPanel == true" class="w-100">
							<div class="my-auto form-inline w-100" >
					              <div class="search-box mr-3 ml-3">
					                <div class="position-relative">
					                  <input
					                  	style="height: 50px; border-radius: 50px;"
					                    type="text"
					                    class="form-control w-100 border"
					                    placeholder="Search name, reference or description..."
					                    v-model="search"
					                    @keyup.enter="loadProducts($event)"
					                  />
					                  <i class="bx bx-search-alt search-icon" style="line-height: 50px"></i>
					                </div>
					              </div>
					            </div>
						</div>
					</transition>
					<transition name="fade-left">
					<a class="btn btn-primary btn-lg btn-rounded ml-2 mr-2" v-if="searchboxPanel == true" @click="loadProducts()">
						<i  class="bx bx-search font-size-20 py-2"></i>
					</a>
					</transition>
					<a class="btn btn-primary btn-lg btn-rounded ml-auto mr-4" @click="searchboxPanel = !searchboxPanel">
						<i v-if="searchboxPanel == false" class="bx bx-search font-size-20 py-2"></i>
						<i v-else class="bx bx-x font-size-20 py-2"></i>
					</a>-->
					</div>
				</div>

				</b-col>
				<b-col md="5" class="position-relative h-100 overflow-auto d-flex flex-column p-0 pos-frame-inner-right">
					<SideSlidePanel :editPanel="paymentMethodWindow" :panelClass="'pos-sidepanel'" @close-panel="paymentMethodWindow = !paymentMethodWindow, paymentSuccess = false, payment_method = ''"> 
						<b-overlay :show="loadingPaymentMethodWindow" :class="{'position-absolute top-0 left-0 bottom-0 w-100 overflow-hidden' : loadingPaymentMethodWindow == true}" :style="{'width: 98%' : loadingPaymentMethodWindow == true }" variant="white" opacity="0.9">
							<template #overlay> 
								<div class="">
									<img src="/images/pos-terminal-graphic.svg" class="w-100">

									<div class="text-center">
									<h4>Present the Card Reader to the Customer to Collect Payment</h4>
									</div>

									<b-progress class="w-50 mx-auto" v-model="payment_progress" variant="primary"></b-progress>

									<a class="btn btn-danger btn-rounded w-100 mt-3 py-3 btn-lg" @click="cancelPayment()">
										Cancel Payment
									</a>
								</div>
							</template>
						<b-row v-if="paymentSuccess == false">
							<b-col v-if="(paymentMethodWindow == true) && (loadingPaymentMethodWindow == false)">
								<h4>Select Payment Method</h4>
								<b-card
								v-for="method in payment_methods"
								 class="shadow-sm border-radius-1em border py-3"
								 @click="payment_method = method"
								 :class="{'border-warning border-2' : payment_method == method}"
								>
								<div class="d-flex">
								<i v-if="method.icon !== null" :class="method.icon" class="font-size-20 text-primary ml-1 mr-2" ></i>
								<h6 class="my-auto ml-2">{{method.name}}</h6>
								</div>
								</b-card>
								<b-card class="shadow-sm border-radius-1em border" @click="payment_method = 'cash'"
								 :class="{'border-warning border-2' : payment_method == 'cash'}">
									<div class="d-flex">
										<i class="mdi mdi-cash text-primary font-size-36"></i>
										<h6 class="my-auto ml-2">Cash</h6>
									</div>
								</b-card>
								<b-card class="shadow-sm border-radius-1em border" @click="payment_method = 'cheque'"
								 :class="{'border-warning border-2' : payment_method == 'cheque'}">
									<div class="d-flex">
										<i class="mdi mdi-checkbook text-primary font-size-36"></i>
										<h6 class="my-auto ml-2">Cheque</h6>
									</div>
								</b-card>
								<div v-if="settings.terminal_id !== ''">
									<b-card class="shadow-sm border-radius-1em border"  @click="payment_method = 'card'"
								 :class="{'border-warning border-2' : payment_method == 'card'}">
										<div class="d-flex">
										<i class="mdi mdi-credit-card-outline font-size-36 text-primary"></i>
										<h6 class="my-auto ml-2">Debit / Credit / Amex Card</h6>
										</div>
									</b-card>
									<b-card class="shadow-sm border-radius-1em border"  @click="payment_method = 'contactless'"
								 :class="{'border-warning border-2' : payment_method == 'contactless'}">
										<div class="d-flex">
										<i class="mdi mdi-contactless-payment-circle-outline font-size-36 text-primary"></i>
										<h6 class="my-auto ml-2">ApplePay / GooglePay / Contactless</h6>
										</div>
									</b-card>
									
								</div>
								<div v-if="settings.terminal_id == ''">
									<div v-if="terminal.connectionStatus !== 'not_connected'">
									<b-card class="shadow-sm border-radius-1em border"   @click="payment_method = 'card'"
								 :class="{'border-warning border-2' : payment_method == 'card'}">
										<div class="d-flex">
										<i class="mdi mdi-credit-card-outline font-size-36 text-primary"></i>
										<h6 class="my-auto ml-2">Debit / Credit / Amex Card</h6>
										<div class="btn btn-outline-light btn-rounded ml-auto my-auto">
											<i class="mdi mdi-circle mr-1 text-success"></i>
											Connected
										</div>
										</div>
									</b-card>
									<b-card class="shadow-sm border-radius-1em border"   @click="payment_method = 'contactless'"
								 :class="{'border-warning border-2' : payment_method == 'contactless'}">
										<div class="d-flex">
										<i class="mdi mdi-contactless-payment-circle-outline font-size-36 text-primary"></i>
										<h6 class="my-auto ml-2">ApplePay / GooglePay / Contactless</h6>
										<div class="btn btn-outline-light btn-rounded ml-auto my-auto">
											<i class="mdi mdi-circle mr-1 text-success"></i>
											Connected
										</div>
										</div>
									</b-card>
									
									</div>
									<div v-else>
										<b-card class="shadow-sm border-radius-1em border" :class="{'border-warning border-2' : payment_method == 'card'}">
										<div class="d-flex">
										<i class="mdi mdi-credit-card-outline font-size-36 text-primary"></i>
										<h6 class="my-auto ml-2">Debit / Credit / Amex Card</h6>
										<a class="btn btn-outline-light btn-rounded ml-auto my-auto">
											<i class="mdi mdi-circle mr-1 text-danger"></i>
											Terminal Not Connected
										</a>
										</div>
									</b-card>
									<b-card class="shadow-sm border-radius-1em border" :class="{'border-warning border-2' : payment_method == 'contactless'}">
										<div class="d-flex">
										<i class="mdi mdi-contactless-payment-circle-outline font-size-36 text-primary"></i>
										<h6 class="my-auto ml-2">ApplePay / GooglePay / Contactless</h6>

										<a class="btn btn-outline-light btn-rounded ml-auto my-auto">
											<i class="mdi mdi-circle mr-1 text-danger"></i>
											Terminal Not Connected
										</a>
										</div>
									</b-card>
									</div>
								</div>
								<b-card class="shadow-sm border-radius-1em border" 
								 :class="{'border-warning border-2' : payment_method == 'finance'}">
									<div class="d-flex">
										<i class="bx bx-pound text-primary font-size-36"></i>
										<h6 class="my-auto ml-2">Personal Finance</h6>
										<div class="btn btn-outline-light btn-rounded ml-auto my-auto">
											<i class="mdi mdi-circle mr-1 text-info"></i>
											Coming Soon
										</div>
									</div>
								</b-card>
							</b-col>
							<b-col md="12" v-if="payment_method !== ''">
								<a class="btn btn-primary w-100 py-3 btn-rounded" @click="processOrder()">
									Process Payment
								</a>
							</b-col>
						</b-row>
						<b-row v-else-if="paymentSuccess == true">
							<b-col md="12">
								<h4 class="text-center">Payment Successful!</h4>
								<svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
								  <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none"/>
								  <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
								</svg>
							</b-col>
							<b-col md="12">
								<b-card>
									<h4>Order Details</h4>
									<hr>
									<p>Reference #{{create_order_response.company_orders[0].reference}}</p>
									<p>Date #{{create_order_response.company_orders[0].created_at}}</p>
									

							
				          		<hr>
				          		<div class="d-flex w-100">
				          			<h3 class="mt-auto mr-auto">Totals</h3>
				          			<div class="ml-auto">
				          			<h5><span class="currency">{{companyCurrency}}</span>{{(create_order_response.company_orders[0].total_order_inc).toFixed(2)}}</h5>
									<small><span class="currency">{{companyCurrency}}</span>{{(create_order_response.company_orders[0].total_order_exc).toFixed(2)}}</small>
									</div>

								</div>
								</b-card>
							</b-col>
							<b-col md="12" class="mb-3">
								<a class="btn btn-light w-100 py-3 btn-rounded btn-lg " v-b-modal.print-receipt-window @click="receipt_action = 'email'">
									Email Receipt <i class="bx bx-mail-send"></i>
								</a>
							</b-col>
							<b-col md="12" class="mb-3">
								<a class="btn btn-primary w-100 py-3 btn-rounded btn-lg " v-b-modal.print-receipt-window @click="receipt_action = 'qr'">
									QR Receipt <i class="bx bx-barcode"></i>
								</a>
							</b-col>
								<b-col md="12" class="mb-3">
								<a class="btn btn-info w-100 py-3 btn-rounded btn-lg " v-b-modal.print-receipt-window @click="receipt_action = 'print'" >
									Print Receipt <i class="bx bx-printer"></i>
								</a>
							</b-col>
						</b-row>
						</b-overlay>
						<b-modal id="print-receipt-window" hide-footer>

							<b-card class="shadow-none border text-uppercase font-size-10" v-if="paymentSuccess == true">
								<div class="text-center">
									<img :src="storageurl + company.logo" class="img-thumbnail avatar-lg">
								</div>
								<div class="border-bottom mt-2 mb-3 pb-2">
									<h6 class="text-center mb-0">{{company.name}}</h6>
									<p class="mb-0 text-center" v-if="settings.listing == ''">
										<span >{{company.address1}}</span>
										<span >{{company.address2}}</span>
										<span >{{company.address3}}</span>
										<span v-if="company.county">{{company.county.name}}</span>
										<span>{{company.postcode}}</span>
										<span v-if="company.country">{{company.country.name}}</span>

									</p>
									<p class="mb-0 text-center" v-else>
										<span >{{settings.listing.address_1}}</span>
										<span >{{settings.listing.address_2}}</span>
										<span >{{settings.listing.address_3}}</span>
										<span v-if="settings.listing.county">{{settings.listing.county.name}}</span>
										<span>{{settings.listing.postcode}}</span>
										<span v-if="settings.listing.country">{{settings.listing.country.name}}</span>

									</p>
								</div>
								
								<p class="mb-0 text-center"><strong>Order ID</strong> #{{create_order_response.company_orders[0].uid}}</p>
								<p class="mb-0 text-center"><strong>Transaction ID</strong> #{{create_order_response.company_orders[0].reference}}</p>
								<p class="mb-0 text-center"><strong>Date</strong> #{{create_order_response.company_orders[0].created_at | luxon({input: 'server'}) }}</p>
								<p class="mb-0 text-center"><strong>Served By</strong> {{(user.name).slice(0,1)}} {{user.lastname}}</p>


								
								<div v-if="cartLoading == false" class="px-0 border-top pt-2 mt-3">
								 <table class="table mb-0">
								                <thead>
								                  <tr>
								                    <th>ITEM</th>
								                    <th>QTY</th>
								                    <th>TAX</th>
								                    <th colspan="2">PRICE</th>
								                  </tr>
								                </thead>
								                <tbody>
								                  <tr v-for="(productitem, index) in create_order_response.line_items">
								                    <th scope="row"><div class="media-body my-auto">
									                	<span class="">{{productitem.product.reference}}</span>
									                  <p class="mt-0 mb-0" style="line-height: 1; font-weight: 400;">{{productitem.product.name}}</p>
									                </div></th>
								                    <td><div class="my-auto text-right d-flex flex-column">
									                   <span class="mx-auto my-auto">{{productitem.quantity}}</span>
									                </div></td>
												     <td><div  class="my-auto flex-grow-1 text-center d-flex" v-if="productitem.product.tax_rule !== null">
								                	{{getVatValue(productitem.product.sales_price, productitem.product.tax_rule)}} <span v-if="productitem.product.tax_rule.type == 1">%</span>
								                	<span v-if="productitem.product.tax_rule.type == 2"><span class="currency">{{companyCurrency}}</span></span> {{productitem.product.tax_rule.code}}
								                </div>
								                 <div  class="my-auto flex-grow-1 text-center d-flex" v-else-if="productitem.product.tax_rule == null">
								                 	0% VAT
								                 </div></td>
								                    <td colspan="1">
								                
						                	<div class="my-auto flex-grow-2 text-center d-flex">
								                <!--<div class="my-auto flex-grow-2 text-center d-flex flex-column">
								                  <p class="mb-0"><span class="currency">{{companyCurrency}}</span>{{productitem.price_exc}}</p>
								                  <small>Item Price Exc</small>
								                </div>-->
								                <div class="my-auto flex-grow-2 text-center d-flex flex-column">
								                  <p class="mb-0"><span class="currency">{{companyCurrency}}</span>{{(productitem.price_exc * productitem.quantity).toFixed(2)}}</p>
								                  <small>Tax Exc</small>
								             	 </div>
						             		</div>
						             	</td>
						             	<td>
						             		<div class="my-auto flex-grow-2 text-center d-flex">
								                <!--<div class="my-auto flex-grow-2 text-center d-flex flex-column">
								                  <p class="mb-0"><span class="currency">{{companyCurrency}}</span>{{productitem.price_inc}}</p>
								                  <small>Item Price Inc</small>
								                </div>-->
								                <div class="my-auto flex-grow-2 text-center d-flex flex-column">
								                  <p class="mb-0"><span class="currency">{{companyCurrency}}</span>{{(productitem.price_inc * productitem.quantity).toFixed(2)}}</p>
								                  <small>Tax Inc</small>
								             	 </div>
						             		</div>
				               				</td>
								                    </td>
								                  </tr>
								                 <tr>
								                 	<td colspan="5">
								                 		<div class="d-flex w-100">
									          			<h3 class="mt-auto mr-auto">Totals</h3>
									          			<div class="ml-auto">
									          			<h5><span class="currency">{{companyCurrency}}</span>{{(create_order_response.company_orders[0].total_order_inc).toFixed(2)}}</h5>
														<small><span class="currency">{{companyCurrency}}</span>{{(create_order_response.company_orders[0].total_order_exc).toFixed(2)}}</small>
														</div>

													</div>
								                 	</td>
								                 </tr>
								                </tbody>
								              </table>
				            
				          </div>
				          <div class="d-flex w-100 border-bottom py-2">
				          	  <div class="text-right ml-auto my-auto">
				          	  	<small class="text-uppercase">
				          	  		For Office Use: 
				          	  	</small>
				          	  </div>
				          	  <div class="my-auto ml-auto">
				          	  	 <VueBarcode v-if="create_order_response.company_orders[0].reference !== ''" :value="company.id+'-'+create_order_response.company_orders[0].uid.slice(0,7)+'-'+create_order_response.company_orders[0].id" :options="{ displayValue: true, format: 'CODE128', lineColor: '#000000', width: 1, height: 80, background: '#FFFFFF', fontSize: 10, marginTop: 0 }"></VueBarcode>
				          	  </div>
					          <div class="text-center ml-2 my-auto ">
					          <qrcode-vue v-if="create_order_response.company_orders[0].reference !== ''" :value="JSON.stringify({type: 'order-receipt', reference: create_order_response.company_orders[0].reference, uid: create_order_response.company_orders[0].uid, id: create_order_response.company_orders[0].id})" :size="80" level="L" foreground="#000000" background="#ffffff" margin="0" class="px-1" style="margin-bottom: -2px; margin-top: -2px;" />
					          <small>
					          {{company.id}}-{{create_order_response.company_orders[0].uid.slice(0,7)
					         }}-{{create_order_response.company_orders[0].id}}
					          </small>
					      	  </div>
				      		</div>
							</b-card>
							<b-form v-if="(receipt_action == 'email') && (customer == '')">
								<b-form-group label="Email Address">
									<b-form-input type="email" class="underline-input"></b-form-input>
								</b-form-group>
							</b-form>
							<div class="" v-if="receipt_step == 0">
								<a class="btn btn-primary btn-rounded py-3 w-100" v-if="receipt_action == 'print'" @click="printReceipt()">
									Print Receipt
								</a>
								<a class="btn btn-primary btn-rounded py-3 w-100"  v-if="receipt_action == 'qr'">
									Show Customer
								</a>
								<a class="btn btn-primary btn-rounded py-3 w-100"  v-if="receipt_action == 'email'">
									Email Receipt
								</a>
							</div>
						</b-modal>
					</SideSlidePanel>
					<SideSlidePanel :editPanel="showProductOptionsWindow" :panelClass="'pos-sidepanel'" @close-panel="showProductOptionsWindow = !showProductOptionsWindow, productOptionsData = ''"> 
						<b-overlay
						:show="previewWindowLoading">
						<div v-if="productOptionsData !== ''">
							<span class="badge badge-primary bg-primary position-absolute product-grid-reference-badge font-size-14">{{productOptionsData.reference}}</span>
					         	 <b-img-lazy v-if="(productOptionsData.cover_image) && ('gallery' in productOptionsData.cover_image)"class="border-radius-1em w-100 d-block mx-auto mb-3" :src="storageurl+productOptionsData.cover_image.gallery.replaceAll('//', '/')" ></b-img-lazy>
                              	<!--<b-img-lazy v-else-if="(productOptionsData.cover_image) && ('thumb' in productOptionsData.cover_image)"class="border-radius-1em w-100 d-block mx-auto mb-3" :src="storageurl+productOptionsData.cover_image.thumb.replaceAll('//', '/')" ></b-img-lazy>-->
                              	<b-img-lazy v-else-if="(productOptionsData.cover_image) && ('src' in productOptionsData.cover_image)"class="border-radius-1em w-100 d-block mx-auto mb-3" :src="storageurl+productOptionsData.cover_image.src.replaceAll('//', '/')" ></b-img-lazy>
		                        <b-img-lazy v-else :src="'/images/product-placeholder.png'" alt="Image" class="border-radius-1em w-100 d-block mx-auto mb-3"></b-img-lazy>
							<h4>{{productOptionsData.name}}</h4>
							<p v-html="productOptionsData.short_description"></p>
							<h2 class="text-primary d-inline"><span class="currency">{{companyCurrency}}</span>
								<strong v-if="productOptionsData.tax_rule !== null">{{(parseFloat(addTax(productOptionsData.sales_price, productOptionsData.tax_rule)) + parseFloat(selectedvariantstotal[1]) ).toFixed(2) }}</strong>

								<strong v-else>{{(parseFloat(productOptionsData.sales_price) + parseFloat(selectedvariantstotal[1]) ).toFixed(2) }}</strong>
							</h2>
							<small>Price Inc. VAT</small>
							<div class="border-top pt-3 mt-3" v-if="productOptionsData.combinations.length == 0" >
					
							

							<h5 v-if="(productOptionsData.variants.length > 0) || (productOptionsData.combinations.length > 0)" class="text-uppercase font-size-12 text-underline spaced-title mb-4 text-decoration-underline">Select Customisation Options</h5>
				<div class="w-100" v-for="(variants, key) in productOptionsData.grouped_variants">
					<h5 class="text-uppercase font-size-12 spaced-title">{{variants[0].attribute.name}}</h5>
					
					<b-row >
						<b-col md="4" v-for="value in variants">
							<a href="javascript:;" @click="selectVariant(key, value), calculateVariantsTotal(selectedvariants, productOptionsData.tax_rule, selectedproductqty)" >
							<b-card class="border-radius-1em border shadow-none text-dark mb-1" body-class="d-flex" :class="{'border-primary' : selectedvariants[key] == value}">
								<span class="my-auto" v-if="value.value !== null"><img v-if="value.value.image !== null" :src="value.value.image" class="avatar-xs rounded-circle mr-2 border"> 
								</span>
								<span class="my-auto mr-2" v-if="value.value !== null">{{value.value.name}}</span> <span class="my-auto">{{value.measurement_value}}</span><span class="my-auto mr-2" v-if="value.units !== null">{{value.units.suffix}}</span>
								<span class="badge badge-light bg-light my-auto ml-auto font-size-14">
								<span class="ml-auto my-auto" v-if="value.price_difference > 0">+</span><span class="ml-auto my-auto" v-else>-</span><span>{{companyCurrency}}</span>{{value.price_difference}}
								</span>
							</b-card>
							</a>
						</b-col>
					</b-row>
					
				</div>
			</div>
				<div v-else >
					<h5 class="text-uppercase font-size-12 text-underline spaced-title mt-2 pt-3 text-decoration-underline pb-2">Select Customisation Options</h5>
				<div class="w-100" v-for="(combination, index) in productOptionsData.combinations">
					<a href="javascript:;" @click="selectedcombination = combination" >
						<b-card class="border-radius-1em border shadow-none text-dark m-1" :class="{'border-primary' : selectedcombination == combination}">
					<b-row>
						<b-col md="3" v-for="value in combination.variants">
							
							
								<span v-if="value.variant.attribute !== null">{{value.variant.attribute.name}}</span> 
								<span v-if="value.variant.value !== null">{{value.variant.value.name}}</span> {{value.variant.measurement_value}}<span v-if="value.variant.units !== null">{{value.variant.units.suffix}}</span>
								
								
							
							
						</b-col>
						<b-col md="12" class="d-flex">
							<span class="badge badge-light bg-light ml-auto mt-2 font-size-14">
							<span v-if="combination.price_difference > 0">+</span><span v-else>-</span><span>{{companyCurrency}}</span>{{combination.price_difference}}
								</span>
						</b-col>
					</b-row>
					</b-card>
					</a>
				</div>
				</div>
				<h5 class="text-uppercase font-size-12 text-underline spaced-title mt-2 pt-3 text-decoration-underline pb-2">Available Stock</h5>
				<div class="border-top pt-2" >
		              		<label>Availability in this Store</label>
		              						<span v-if="productAtThisListing(productOptionsData.listings) !== 0 " class="badge badge-success bg-success">
		              							 In Stock Here
		              						</span>
		              						<span class="badge badge-danger bg-danger" v-else>Out of Stock Here</span>
							<p>{{productAtThisListing(productOptionsData.listings)}}</p>
						</div>
							<div class="border-top pt-2">
								<label>Available Quantity</label><span class="badge badge-success bg-success" v-if="productOptionsData.total_qty > 0">In Stock in The Company</span><span class="badge badge-danger bg-danger" v-else>Out of Stock</span>
								<p>{{productOptionsData.total_qty}}</p>
							</div>
							<div class="" v-if="(productAtThisListing(productOptionsData.listings) !== 0)">
							<div class="quantity d-flex flex-row my-2" style="height: 60px">
								<b-button class="btn btn-light d-flex w-25" @click="selectedproductqty--" :disabled="selectedproductqty < 2">
									<i class="bx bx-minus mx-auto my-auto text-dark"></i>
								</b-button>
								<b-form-input class="underline-input mx-auto" v-model="selectedproductqty" type="number"></b-form-input>
								<b-button class="btn btn-light d-flex w-25" @click="selectedproductqty++" >
									<i class="bx bx-plus mx-auto my-auto text-dark"></i>
								</b-button>
							</div>

							<a class="btn btn-primary btn-block btn-rounded" @click="addToCart(productOptionsData, selectedproductqty, selectedproductlisting, selectedvariants, selectedcombination), showProductOptionsWindow = !showProductOptionsWindow, productOptionsData = ''"> Add To Cart </a>
							</div>
							<div  v-else-if="(productAtThisListing(productOptionsData.listings) == 0)  && (availableStockListing.length > 0)">
							<h5 class="text-uppercase font-size-12 text-underline spaced-title mt-2 pt-3 text-decoration-underline pb-2">Transfer from Another Store</h5>
							<simplebar style="max-height: 260px;">
							<b-list-group>
								<b-list-group-item v-for="listing in availableStockListing" @click="selectedproductlisting = listing" :class="{'p-0' : listing.quantity == 0}">
									<div class="media" v-if="listing.quantity > 0">
										<img src="/images/product-placeholder.png" class="rounded-circle avatar-sm mr-3 my-auto">
										<div class="media-body my-auto">
											<strong>{{listing.listing_info.name}}</strong>
											<p class="mb-0">{{listing.listing_info.address_1}}, {{listing.listing_info.city}}, {{listing.listing_info.postcode}}</p>
											
											</a>
										</div>
										<a class="my-auto btn btn-primary btn-rounded my-auto" @click="selectedproductlisting = listing" v-b-modal.stocktransfer   :id="listing.company_listing_id">
												Book Transfer <i class="bx bx-transfer-alt"></i>
											</a>
									</div>
								</b-list-group-item>
							</b-list-group>
							</simplebar>
						</div>
							<div v-else-if="(productAtThisListing(productOptionsData.listings) == 0) && (productOptionsData.total_qty > 0)">
								<b-card class="shadow-none border border-radius-1em" v-if="company.split_stock == 1">
									<h5 class="text-uppercase font-size-12 text-underline spaced-title mt-2 pt-3 text-decoration-underline pb-2">Product Not Found in Another Store</h5>
									<p>No other shops have this product in stock, but it is available online. Transfer stock from your webstore to sell in store.</p>
									<a class="btn btn-primary" v-b-modal.webstocktransfer>
									Transfer from Webstock
									</a>
								</b-card>
								<div v-if="company.split_stock == 0">
									
									<div class="quantity d-flex flex-row my-2" style="height: 60px">
										<b-button class="btn btn-light d-flex w-25" @click="selectedproductqty--" :disabled="selectedproductqty < 2">
										<i class="bx bx-minus mx-auto my-auto text-dark"></i>
									</b-button>
								<b-form-input class="underline-input mx-auto" v-model="selectedproductqty" type="number"></b-form-input>
								<b-button class="btn btn-light d-flex w-25" @click="selectedproductqty++" >
									<i class="bx bx-plus mx-auto my-auto text-dark"></i>
								</b-button>
							</div>

							<a class="btn btn-primary btn-block btn-rounded" @click="addToCart(productOptionsData, selectedproductqty, selectedproductlisting, selectedvariants, selectedcombination), showProductOptionsWindow = !showProductOptionsWindow, productOptionsData = ''"> Add To Cart </a>
								</div>

								
							</div>
							<div v-else>
								<h5 class="text-uppercase font-size-12 text-underline spaced-title mt-2 pt-3 text-decoration-underline pb-2">Add To Waiting List</h5>
								<p>This product is out of stock in all stores and online - add customer to waiting list for this product.</p>
								<a class="btn btn-primary" v-b-modal.waiting-list @click="waiting_list.product = productOptionsData">
									Add to Waiting List
								</a>
							</div>
							
						</div>
					</b-overlay>
					</SideSlidePanel>
					<b-card class="shadow-none border-radius-1em mt-3 mx-2 mb-2">
						<div class="d-flex">
							<a class="btn btn-light my-auto mr-1 p-3 flex-grow-1 border-radius-1em" :class="{'border border-primary': searchResource == 'customer','border border-light': searchResource !== 'customer'}" @click="searchResource = 'customer'" v-b-tooltip.hover title="Search Customers">
	                			<i class="mdi mdi-account-search-outline font-size-20 text-primary"></i>
	                		</a>
			                <a class="btn btn-light my-auto mr-1 p-3 flex-grow-1 border-radius-1em" :class="{'border border-primary': searchResource == 'coupons','border border-light': searchResource !== 'coupons'}"  @click="searchResource = 'coupons'" v-b-tooltip.hover title="Discounts">
			                	<i class="mdi mdi-ticket-percent-outline font-size-20 text-primary"></i>
			                </a>

			                <a class="btn btn-light my-auto mr-1 p-3 flex-grow-1 border-radius-1em" :class="{'border border-primary': searchResource == 'carts','border border-light': searchResource !==  'carts'}"  @click="searchResource = 'carts', loadCarts()" v-b-tooltip.hover title="Carts">
			                	<i class="mdi mdi-cart-variant font-size-20 text-primary"></i>
			                </a>

			                <a class="btn btn-light my-auto mr-1 p-3 flex-grow-1 border-radius-1em" :class="{'border border-primary': searchResource == 'orders','border border-light': searchResource !== 'orders'}"  @click="getOrders(),searchResource = 'orders', ordersWindow = !ordersWindow" v-b-tooltip.hover title="Orders">
			                	<i class="mdi mdi-receipt font-size-20 text-primary"></i>
			                </a>
			                <a class="btn btn-light my-auto mr-1 p-3 flex-grow-1 border-radius-1em" :class="{'border border-primary': searchResource == 'calculator','border border-light': searchResource !== 'calculator'}"  @click="showCalculator = !showCalculator" v-b-tooltip.hover title="Calculator">
			                	<i v-if="showCalculator == false" class="mdi mdi-calculator font-size-20 text-primary"></i>
			                	<i v-else class="bx bx-x font-size-20"></i>
			                </a>
			                <a class="btn btn-light my-auto mr-1 p-3 flex-grow-1 border-radius-1em" :class="{'border border-primary': searchResource == 'booking','border border-light': searchResource !== 'booking'}"  @click="searchResource == 'booking'" v-b-tooltip.hover title="Booking Options">
			                	<i v-if="searchResource !== 'booking'" class="mdi mdi-calendar font-size-20 text-primary"></i>
			                	<i v-else class="bx bx-x font-size-20"></i>
			                </a>
						</div>
						<transition name="fade-top">
						<div class="search-box px-4 ml-auto my-3 d-flex py-4 position-absolute top-0 right-0 left-0 bg-white" v-if="searchResource == 'customer' || searchResource == 'coupons'">
			                <div class="position-relative flex-grow-1 mr-1" :class="{'overflow-unset' : searchResource == 'customer' , 'overflow-unset' : searchResource !== 'customer'}" style="height: 35px;">
			               <transition name="fade-bottom">
			                

			                 <CompanyCustomerSearch ref="selectcustomer" v-if="searchResource == 'customer'" :api_token="user.api_token" @search-resource-selected="existingCustomerWindow = true, selectCustomer" :input_classes="'bg-light'"></CompanyCustomerSearch>
							
			              </transition>
			              <transition name="fade-top">
				              <div  v-if="searchResource == 'coupons'">
				              	<div class="mt-sm-0 form-inline w-100 d-flex" >
					              <div class="search-box ml-auto flex-grow-4 mr-3">
					                <div class="position-relative">
					                  <input
					                    type="text"
					                    class="form-control w-100 border-0 bg-light"
					                    placeholder="Search coupon code"
					                    v-model="couponsearch" @keydown.enter="getDiscountCoupon(company.uid)" 
					                  />
					                  <i class="bx bx-search-alt search-icon"></i>
					                </div>
					              </div>
					              <a class="btn btn-primary btn-rounded flex-grow-1" @click="getDiscountCoupon(company.uid)" >Search</a>
					            
					              <b-dropdown variant="light" class="btn-rounded flex-grow-1 ml-1" menu-class="dropdown-menu-right">
								<template #button-content>
									Scan Code <i class="bx bx-barcode"></i>
								</template>
								<b-dropdown-item  v-b-modal.product-code-reader @click="scanner_mode = 'camera'">
									Camera <i class="bx bx-camera"></i>
								</b-dropdown-item>
								<b-dropdown-item  v-b-modal.product-code-reader @click="scanner_mode = 'scanner', scanner_value = '', onScan()">
									Scanner <i class="bx bx-barcode"></i>
								</b-dropdown-item>
							</b-dropdown>
					            </div>
					            
					              
					        </div>
			              </transition>
			                 
			                </div>
			                <a class="btn btn-outline-light btn-rounded" @click="searchResource = ''">
			                	<i class="mdi mdi-close"></i>
			                </a>
	                
	              </div>
	          </transition>
					</b-card>
					<!--<transition name="slide-fadepanel">
						<b-card class="shadow-none border-radius-1em mx-2 mb-2" body-class="p-0" v-if="coupon !== ''">
							 <div>
						            	<b-card class="border-radius-1em shadow-none mb-0" body-class="d-flex">
						            		<h6 class="my-auto mr-3 text-primary">{{coupon.code}}</h6>
						            		<p class="text-truncate my-auto mr-auto">{{coupon.description}}</p>
											<span class="my-auto" v-if="coupon.discount_type == 2">{{companyCurrency}}</span>
											<span class="my-auto">{{coupon.discount_amount}}</span>
											<span class="my-auto" v-if="coupon.discount_type == 1">%</span>

	                                		<a class="btn btn-light my-auto rounded-circle ml-2" v-b-tooltip.hover title="Remove Customer" @click="coupon = ''"><i class="mdi mdi-close font-size-18"></i></a>
						            	</b-card>
						            </div>
						</b-card>
					</transition>-->
					<b-card class="shadow-none border-radius-1em mx-2 mb-2">
						<div class="d-flex flex-column border p-3" v-if="(customerSelected == false)">
							<i class="bx bx-user font-size-20 text-secondary mb-2 mt-3 mx-auto text-center d-block"></i>
							<h6 class="text-center">No Customer Selected</h6>
							<div class="d-flex mt-3">
								<a href="javascript:;" class="btn btn-primary btn-rounded btn-block mr-1" @click="existingCustomerWindow = !existingCustomerWindow, newCustomerWindow = false, focusCustomerInput()">
									<span v-if="existingCustomerWindow == false">Select Existing Customer</span>
								<span v-if="existingCustomerWindow == true">Cancel <i class="bx bx-x"></i></span>
								</a>
								<a href="javascript:;" class="btn btn-light btn-rounded btn-block ml-1" @click="newCustomerWindow = !newCustomerWindow, existingCustomerWindow = false">
								<span v-if="newCustomerWindow == false">Add New Customer</span>
								<span v-if="newCustomerWindow == true">Cancel <i class="bx bx-x"></i></span>
							</a>
							</div>
							<div class="d-flex mt-2 mb-3">
								<a href="javascript:;" class="btn btn-info btn-rounded btn-block" @click="customerIsGuest()">
								Checkout As Guest
								
							</a>
							</div>
						</div>
						<div v-else class="d-flex border border-radius-1em px-3 py-2">
								<div v-if="customer.customer !== null"><img v-if="customer.customer.avatar" class="avatar-sm border mr-3 rounded-circle" :src="customer.customer.avatar">
                                <img v-else class="avatar-sm border mr-3 rounded-circle" src="/images/product-placeholder.png">
                              </div>
                              <img v-else class="avatar-sm border mr-3 rounded-circle" src="/images/product-placeholder.png">
                                <div class="mr-auto ml-2 my-auto flex-grow-3 mr-3">
                                	<h6 class="mr-auto my-auto text-primary">{{customer.firstname}} {{customer.lastname}}</h6>
                                	<p class="text-truncate mb-0" v-if="customer_address !== ''">{{customer_address.address_1}} {{customer_address.city}} {{customer_address.postcode}}</p>
                                </div>
                                <a class="btn btn-light my-auto rounded-circle" v-b-tooltip.hover title="Remove Customer" @click="customer = '', customer_address = '', customerSelected = false"><i class="mdi mdi-close font-size-18"></i></a>
						</div>
					</b-card>
				<b-modal id="line-item-details" hide-footer hide-header  centered size="lg">
					<b-card v-if="lineitemdetail !== ''" class="mb-0 shadow-none border-none">
						<b-row>
							<b-col>
					<b-img-lazy v-if="(lineitemdetail.product.cover_image) && ('thumb' in lineitemdetail.product.cover_image)"class="border-radius-1em w-100 d-block mx-auto mb-3 border" :src="storageurl+lineitemdetail.product.cover_image.thumb.replaceAll('//', '/')" ></b-img-lazy>
					<div>
						<div class="d-flex overflow-hidden">
							<img v-for="image in lineitemdetail.product.images" v-if="('thumb' in image)" :src="storageurl+image.thumb.replaceAll('//', '/')" width="100px" height="auto" class="img-thumbnail border">
						</div>
					</div>
				</b-col>
				<b-col>
					<h4>{{lineitemdetail.product.name}}</h4>
					<p v-html="lineitemdetail.product.short_description"></p>
		
					<div>
					<div class="d-flex">
						<div class="d-flex flex-column">
						<h5>Item Price</h5>
						<h4 class="text-warning">
							<span class="currency">{{companyCurrency}}</span>
							{{lineitemdetail.price_exc}}
						</h4>
						</div>
						

						<!--<a class="btn btn-primary my-auto ml-auto" @click="editlineitem = !editlineitem">
							Edit Item Price
						</a>-->

						
					</div>
					<!--<div class="d-flex flex-column">
						<b-form-group v-if="editlineitem == true" >
							<b-form-input v-model="lineitemnewprice" type="number">
							</b-form-input>
						</b-form-group>

						<h5>Difference in Price</h5>
						<h4 class="text-warning">
							<span class="currency">{{companyCurrency}}</span>
							{{lineitemdetail.price_exc - lineitemnewprice}}
						</h4>
						<a v-if="editlineitem == true" class="btn btn-primary" @click="couponvalueinc += ((parseFloat(lineitemdetail.price_exc) - parseFloat(lineitemnewprice)) * lineitemdetail.quantity)">
							Save New Price
						</a>
					</div>-->
					<hr>
					<h5>Quantity</h5>
					<h4 class="text-warning">
						{{lineitemdetail.quantity}}
					</h4>
					<hr>
					</div>
					<div v-if="lineitemdetail.variants.length > 0">
						<h5>Variation</h5>
						<hr>
						<b-list-group flush class="mb-4 mt-3">
							<b-list-group-item class="px-0" v-for="variant in lineitemdetail.variants" v-if="variant !== undefined" >
								<div class="d-flex">
									<span class="mr-2 my-auto">{{variant.attribute.name}} -</span>
									<span class="mr-auto my-auto">{{variant.value.name}}</span>
									<span class="ml-auto my-auto">{{variant.measurement_value}}</span>
									<span class="mr-auto my-auto">{{variant.units.suffix}}</span>
									<span class="ml-auto my-auto">{{variant.price_difference}}</span>
								</div>
								
							</b-list-group-item>
						</b-list-group>
					</div>
					<div v-if="lineitemdetail.combinations !== ''">
						<h5>Combination Variation</h5>
						<hr>
						<b-list-group flush>
							<b-list-group-item v-for="combination in lineitemdetail.combinations">
							</b-list-group-item>
						</b-list-group>
					</div>

					<h5>Total Items Price</h5>
					<hr>
					<h4 class="text-warning">
						<span class="currency">{{companyCurrency}}</span>
						{{(lineitemdetail.price_inc * lineitemdetail.quantity).toFixed(2)}}
					</h4>
				</b-col>
				</b-row>
					</b-card>
				</b-modal>
				<transition name="fade-top">
					<b-card key=1 class="shadow-none border-radius-1em mx-2 mb-2 bg-white" v-if="(newCustomerWindow == false) && (existingCustomerWindow == false)">
						<b-overlay
						:show="cartLoading"
						class="bg-white border-radius-1em">
						<simplebar style="max-height: 530px;" >
				          <b-list-group v-if="cartLoading == false" flash>
				            <b-list-group-item  v-for="(productitem, index) in cart.products" href="javascript: void(0);" class="text-reset notification-item px-0 py-3 m-0" >
				              <div class="media">
				                <div class="avatar-xs my-auto me-3">
				                  
                              	<b-img-lazy v-b-modal.line-item-details @click="lineitemdetail = productitem" v-if="(productitem.product.cover_image) && ('thumb' in productitem.product.cover_image)"class="border-radius-1em w-100 d-block mx-auto mb-3" :src="storageurl+productitem.product.cover_image.thumb.replaceAll('//', '/')"  ></b-img-lazy>
               
				                  <span v-else class="avatar-title bg-primary rounded-circle font-size-16" >
				                    <i class="bx bx-cart"></i>
				                  </span>
				                </div>
				                <div class="media-body my-auto flex-grow-10" v-b-modal.line-item-details @click="lineitemdetail = productitem">
				                	<span class="badge badge-primary bg-primary text-white">{{productitem.product.reference}}</span>
				                  <h6 class="mt-0 mb-0 font-size-12">{{productitem.product.name}}</h6>
				                </div>
				                <div class="my-auto flex-grow-2 text-right d-flex flex-column">
				                  <a href="javascript:;" class="text-center" @click="productitem.quantity++, reCalculateTotals()"><i class="bx bx-chevron-up mb-auto mx-auto"></i></a>
				                  <b-form-input type="number"  v-model="productitem.quantity" class="visible mx-auto my-auto d-none" style="width: 25%;" >
				                  </b-form-input>
				                  <span class="mx-auto my-auto">{{productitem.quantity}}</span>
				                  <a v-if="productitem.quantity > 1" href="javascript:;" class="text-center" @click="productitem.quantity--, reCalculateTotals()"><i class="bx bx-chevron-down mt-auto mx-auto"></i></a>
				                  <a v-else href="javascript:;" class="text-center" @click="removeProduct(productitem.id, index)"><i class="bx bx-chevron-down mt-auto mx-auto"></i></a>
				                </div>
				                <div class="my-auto flex-grow-2 text-center d-flex flex-column">
				                	<div class="my-auto flex-grow-2 text-center d-flex">
						                <div class="my-auto flex-grow-2 text-center d-flex flex-column">
						                  <p class="mb-0"><strong class="text-primary mr-2 currency">{{companyCurrency}}</strong>{{productitem.price_exc}}</p>
						                  <small>Item Price Exc</small>
						                </div>
						                <div class="my-auto flex-grow-2 text-center d-flex flex-column">
						                  <p class="mb-0"><strong class="text-primary mr-2 currency">{{companyCurrency}}</strong>{{(productitem.price_exc * productitem.quantity).toFixed(2)}}</p>
						                  <small>Total Price Exc</small>
						             	 </div>
				             		</div>
				             		<div class="my-auto flex-grow-2 text-center d-flex">
						                <div class="my-auto flex-grow-2 text-center d-flex flex-column">
						                  <p class="mb-0"><strong class="text-primary mr-2 currency">{{companyCurrency}}</strong>{{productitem.price_inc}}</p>
						                  <small>Item Price Inc</small>
						                </div>
						                <div class="my-auto flex-grow-2 text-center d-flex flex-column">
						                  <p class="mb-0"><strong class="text-primary mr-2 currency">{{companyCurrency}}</strong>{{(productitem.price_inc * productitem.quantity).toFixed(2)}}</p>
						                  <small>Total Price Inc</small>
						             	 </div>
				             		</div>
				                </div>
				                <div class="my-auto flex-grow-1 text-center">
				                 <a class="btn btn-outline-light btn-rounded" @click="removeProduct(productitem.id, index)"><i class="bx bx-trash-alt"></i></a>
				                </div>
				              </div>
				            </b-list-group-item>
				            <b-list-group-item v-if="cart.products.length == 0">
				              <div class="p-5 text-center">
				                <i class="bx bx-shopping-bag font-size-42 text-secondary mb-2"></i>
				                <h6>No Products in Cart</h6>
				              </div>
				            </b-list-group-item>
				          </b-list-group>
				            

				          </simplebar>
				      </b-overlay>
					</b-card>
					<b-card key=2 class="shadow-none border-radius-1em mx-2 mb-2 bg-white"  v-else-if="(newCustomerWindow == true) && (existingCustomerWindow == false)">
						
						<b-overlay
						:show="cartLoading"
						class="bg-white border-radius-1em">
						<simplebar style="max-height: 400px;">
							           
				                <b-col md="12">
				                  <b-form-group class="my-2" label="Name">
				                    <b-form-input class="underline-input" type="text" name="name" v-model="newUser.name"></b-form-input>
				                  </b-form-group>
				                  <b-form-group class="my-2" label="Last Name">
				                    <b-form-input class="underline-input" type="text" name="name" v-model="newUser.lastname"></b-form-input>
				                  </b-form-group>
				                  <div
				                        class="form-check form-switch form-switch-sm my-3 ml-auto"
				                        dir="ltr"
				                      >
				                      <input
				                          class="form-check-input"
				                          type="checkbox"
				                          :id="`SwitchCheckSize-iscompanynew`"
				                          v-model="newUser.iscompany"
				                          value="1"
				                          unchecked-value="0"
				                          name="iscompany"
				                        />
				                        <label class="form-check-label" :for="`SwitchCheckSize-iscompanynew`" 
				                          > Customer is a Company</label
				                        >
				                    </div>
				                  <b-form-group class="my-2" label="Company Name" v-if="newUser.iscompany == 1">
				                    <b-form-input class="underline-input" type="text" name="name" v-model="newUser.companyname"></b-form-input>
				                  </b-form-group>
				                  <b-form-group class="my-2" label="Email">
				                    <b-form-input class="underline-input" type="text" name="name" v-model="newUser.email"></b-form-input>
				                  </b-form-group>
				                  <b-form-group class="my-2" label="Password">
				                    <b-input-group >
				                      <b-form-input class="underline-input" type="password" name="name" v-model="newUser.password"></b-form-input>
				                       <b-input-group-append>
				                      <b-button variant="primary" @click="generatePassword('new')">Generate Password</b-button>
				                      </b-input-group-append>
				                    </b-input-group>
				                  </b-form-group>
				                 
				                  <a href="javascript:;" class="btn btn-success mt-3 btn-block btn-rounded" @click="addNewUser">Save</a>
				                </b-col>
				              
						</simplebar>
						</b-overlay>
					</b-card>
					<b-card key=3 class="shadow-none border-radius-1em mx-2 mb-2 bg-white" v-else-if="(existingCustomerWindow == true)">

			                 <CompanyCustomerSearch ref="selectcustomer" :api_token="user.api_token" @search-resource-selected="selectCustomer" :input_classes="'bg-light mb-2'"></CompanyCustomerSearch>
			                 <div v-if="customer !== ''" class="d-flex border border-radius-1em px-3 py-2 my-2">
								<div v-if="customer.customer !== null"><img v-if="customer.customer.avatar" class="avatar-sm border mr-3 rounded-circle" :src="customer.customer.avatar">
                                <img v-else class="avatar-sm border mr-3 rounded-circle" src="/images/product-placeholder.png">
                              </div>
                              <img v-else class="avatar-sm border mr-3 rounded-circle" src="/images/product-placeholder.png">
                                <div class="mr-auto ml-2 my-auto flex-grow-3 mr-3">
                                	<h6 class="mr-auto my-auto">{{customer.firstname}} {{customer.lastname}}</h6>

                                </div>
                                <a class="btn btn-light my-auto rounded-circle" v-b-tooltip.hover title="Remove Customer" @click="customer = ''"><i class="mdi mdi-close font-size-18"></i></a>

							</div>
							<SearchCompanyCustomerAddress v-if="customer !== ''" :id="customer_address" :api_token="user.api_token" :options="customer.addresses" @listing-selected="selectAddress" :data_provided="true" @add-address="$bvModal.show('add-address')" ></SearchCompanyCustomerAddress>
							<a class="btn btn-success btn-rounded btn-block mt-2" v-if="customer !== ''" @click="customerSelected = true, existingCustomerWindow = false">Add Customer to Order</a>
					</b-card>
				</transition>

					<b-card class="position-sticky bottom-0 right-0 left-0 mt-auto mb-0" body-class="p-0">
						 <transition name="slide-fadepanel">
								<b-card class="shadow-none border-radius-1em border mb-2" body-class="p-0" v-if="coupon !== ''">
									 <div>
								            	<b-card class="border-radius-1em shadow-none mb-0" body-class="d-flex py-2">
								            		<h6 class="my-auto mr-3 text-primary">{{coupon.code}}</h6>
								            		<p class="text-truncate my-auto mr-auto">{{coupon.description}}</p>
													<span class="my-auto">-</span>
													<span class="my-auto" v-if="coupon.discount_type == 2">{{companyCurrency}}</span>
													<span class="my-auto">{{coupon.discount_amount}}</span>
													<span class="my-auto" v-if="coupon.discount_type == 1">%</span>

			                                		<a class="btn btn-light my-auto rounded-circle ml-3" v-b-tooltip.hover.left title="Remove Coupon" @click="coupon = ''"><i class="mdi mdi-close font-size-18"></i></a>
								            	</b-card>
								            </div>
								</b-card>
							</transition>
						<div class="d-flex flex-column px-4">
						<div class="pt-4 w-100">
							 <strong class="mr-auto"> Totals: </strong>
						</div>
						<div class="pt-4 d-flex flex-column w-100" >
						
			              <hr class="mt-0 mb-2">
			               <div class="pb-4 d-flex">
			                <span class="mx-auto flex-grow-1 text-right my-auto"><span class="mr-0">{{companyCurrency}}</span>{{cartTotal(cart)}}<small class="d-block">Exc. Tax</small></span>
			                <span class="mx-auto flex-grow-1 text-right my-auto">+<span class="mr-0">{{companyCurrency}}</span>{{(parseFloat(cartvariantstotal[0] + cartcombinationstotal[0])).toFixed(2)}}<small class="d-block">Customisations Exc.</small></span>
			                <span class="mx-auto flex-grow-1 text-right my-auto">-<span class="mr-0">{{companyCurrency}}</span>{{(couponvalueinc)}}<small class="d-block">Discounts</small></span>
			                <span class="mx-auto flex-grow-1 text-right my-auto">+<span class="mr-0">{{companyCurrency}}</span>{{ ( (parseFloat(cart_totalinc) - parseFloat(cart_total)) + ( parseFloat(cartvariantstotal[1]) - parseFloat(cartvariantstotal[0]) ) + ( parseFloat(cartcombinationstotal[1]) - parseFloat(cartcombinationstotal[0]) ) ).toFixed(2)  }}<small class="d-block">Taxes</small></span>
			                
			                <div class="pb-2 d-flex flex-column flex-grow-1" >
			              	<span class="ml-auto text-right font-size-20 text-primary"><span class="mr-0">{{companyCurrency}}</span><strong>{{(parseFloat(cart_totalinc) + parseFloat(cartvariantstotal[1]) + parseFloat(cartcombinationstotal[1]) - parseFloat(couponvalueinc)).toFixed(2) }}</strong></span>
			              	<small class="d-block text-right">Total Inc.</small>
			                 </div>
			                </div>
			                  
			                  <!--<span class="mx-auto flex-grow-3 text-center font-size-20" v-if="(customer_address == '') && (cart.products.length > 0)"><strong class="text-primary mr-0">{{companyCurrency}}</strong>{{(parseFloat(cart_totalinc) - parseFloat(couponvalueinc)).toFixed(2)}}<small class="d-block">Total.</small></span>-->
			              </div>

			          </div>
			           <div class="d-flex">
			              <div class="w-50">
			              	<a class="btn btn-primary btn-block rounded-0 py-4" @click="saveCart()">Save Cart</a>
			              </div>
			              <div class="w-50">
			              	<a class="btn btn-success btn-block rounded-0 py-4" @click="createOrder()">Create Order</a>
			              </div>
			          	</div>   
					</b-card>
				</b-col>
			</b-row>
		</b-container>
		<b-modal id="waiting-list" centered content-class="border-radius-1em" header-class="border-none" hide-footer>
		<b-card class="shadow-none border-none">
			<b-card-body class="card-body pt-0" v-if="productOptionsData !== ''">
            <div class="row">
            	<div class="col-sm-12 pb-4 text-center mb-4 d-flex">
            		<h3 class="section-info-header position-relative mx-auto">Adding customer to waiting list for</h3>
            	</div>
              <div class="col-sm-12 pb-4 text-center border border-radius-1em">
                <div class="avatar-md profile-user-wid mb-2 mx-auto" v-if="productOptionsData.cover_image !== null" >
                  <img
                  v-if="'thumb' in productOptionsData.cover_image" 
                  	 :src="storageurl + productOptionsData.cover_image.thumb+'?s='+company.nickname"
                    alt
                    class="img-thumbnail rounded-circle"
                  />
                  <img
                  v-else-if="'gallery' in productOptionsData.cover_image" 
                  	 :src="storageurl + productOptionsData.cover_image.gallery+'?s='+company.nickname"
                    alt
                    class="img-thumbnail rounded-circle"
                  />
                  <img
                  v-else-if="'src' in productOptionsData.cover_image" 
                  	 :src="storageurl + productOptionsData.cover_image.src+'?s='+company.nickname"
                    alt
                    class="img-thumbnail rounded-circle"
                  />
                   <span v-else class="avatar-title rounded-circle font-size-16 bg-light text-primary">{{productOptionsData.name.slice(0,1)}}</span>
                </div>
                <div v-else class="avatar-md profile-user-wid mb-2 mx-auto">
                	<span class="avatar-title rounded-circle font-size-16 bg-light text-primary">{{productOptionsData.name.slice(0,1)}}</span>
                </div>
                <h5 class="font-size-15 text-truncate">{{productOptionsData.name}}</h5>
                <p class="paragraph-truncate-line2 font-size-12 mb-0 px-3" v-html="productOptionsData.short_description"></p>
              </div>
           

            </div>
          </b-card-body>
			<b-row>
				<b-col>
					 <CompanyCustomerSearch ref="selectwaitinglistcustomer" v-if="searchResource == 'customer'" :api_token="user.api_token" @search-resource-selected="selectWaitingListCustomer" :input_classes="'bg-light'"></CompanyCustomerSearch>

					<b-form-group label="Name">
						<b-form-input type="text" v-model="waiting_list.name"></b-form-input>
					</b-form-group>

					<b-form-group label="Email">
						<b-form-input type="text" v-model="waiting_list.email"></b-form-input>
					</b-form-group>

					<b-form-group label="Phone">
						<b-form-input type="text" v-model="waiting_list.phone"></b-form-input>
					</b-form-group>



				</b-col>
			</b-row>
			<a class="btn btn-success" @click="addToWaitingList">Add to Waiting List</a>
			</b-card>
		</b-modal>
		<b-modal id="webstocktransfer" centered content-class="border-radius-1em" header-class="border-none" hide-footer>
			<b-row>
					<b-col>
						<div class="text-center">
						<h3>Stock Transfer</h3>
						<p>You are requesting a transfer of stock from:</p>
						<p class="badge badge-light bg-light font-size-12 text-dark"><i class="bx bx-store font-size-20 text-peach"></i> Your Webstock</p>
						<p>To the following location:</p>
						
						<b-card class="border-radius-1em border shadow-none">
							<p class="font-size-12 text-dark"><i class="bx bx-store font-size-24 text-primary"></i><br> {{settings.listing.name}}<br> {{settings.listing.address_1}}<br> {{settings.listing.postcode}}</p>
						</b-card>
						<h4 class="py-3">Quantity to Transfer</h4>
						</div>
						<div class="quantity d-flex flex-row my-2" style="height: 60px">
									<b-button class="btn btn-light d-flex w-25" @click="selectedproductqty--" :disabled="selectedproductqty < 2">
										<i class="bx bx-minus mx-auto my-auto text-dark"></i>
									</b-button>
									<b-form-input class="underline-input mx-auto" v-model="selectedproductqty" type="number"></b-form-input>
									<b-button class="btn btn-light d-flex w-25" @click="selectedproductqty++" >
										<i class="bx bx-plus mx-auto my-auto text-dark"></i>
									</b-button>
								</div>
						<a class="btn btn-success btn-rounded btn-block mt-3 py-3" @click="compileWebstockTransfer(selecetedproductlisting, settings.listing)">Book Transfer</a>
				</b-col>
			</b-row>
		</b-modal>
		<b-modal id="stocktransfer" centered content-class="border-radius-1em" header-class="border-none" hide-footer>
			<b-overlay :show="loadingTransferForm">
				<b-row>
					<b-col v-if="(selectedproductlisting !== 0) && (transfer_booking_success == false)">
						<div class="text-center">
						<h3>Stock Transfer</h3>
						<p>You are requesting a transfer of stock from:</p>
						

						<b-card class="border-radius-1em border shadow-none">
						<p class="font-size-12 text-dark"><i class="bx bx-store font-size-20 text-primary"></i> <br>{{selectedproductlisting.listing_info.name}}<br> {{selectedproductlisting.listing_info.address_1}}<br> {{selectedproductlisting.listing_info.city}}<br> {{selectedproductlisting.listing_info.postcode}}</p>
						</b-card>
						<p>To the following location:</p>
					

						<b-card class="border-radius-1em border shadow-none">
							<p class="font-size-12 text-dark"><i class="bx bx-store font-size-24 text-primary"></i><br> {{settings.listing.name}}<br> {{settings.listing.address_1}}<br> {{settings.listing.postcode}}</p>
						</b-card>
						</div>
						<b-card class="border-radius-1em border shadow-none">
							<h4 class="py-3">Quantity to Transfer</h4>
						<div class="quantity d-flex flex-row my-2" style="height: 60px">
									<b-button class="btn btn-light d-flex w-25" @click="selectedproductqty--" :disabled="selectedproductqty < 2">
										<i class="bx bx-minus mx-auto my-auto text-dark"></i>
									</b-button>
									<b-form-input class="underline-input mx-auto" v-model="selectedproductqty" type="number"></b-form-input>
									<b-button class="btn btn-light d-flex w-25" @click="selectedproductqty++" >
										<i class="bx bx-plus mx-auto my-auto text-dark"></i>
									</b-button>
								</div>
							</b-card>
						<a class="btn btn-success btn-rounded btn-block mt-3 py-3" @click="compileTransfer(selectedproductlisting, settings.listing)">Book Transfer</a>
					</b-col>
					<b-col v-if="(selectedproductlisting !== 0) && (transfer_booking_success == true)">
						<div class="text-center">
							<svg class="checkmark checkmark-error" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
								  <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="red"/>
								  <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
								</svg>
						<h3>Stock Transfer Successful</h3>
						<p>You have booked a stock transfer from:</p>
						<p class="badge badge-light bg-light font-size-12 text-dark"><i class="bx bx-store font-size-20 text-primary"></i> {{selectedproductlisting.listing_info.name}} {{selectedproductlisting.listing_info.address_1}} {{selectedproductlisting.listing_info.city}} {{selectedproductlisting.listing_info.postcode}}</p>
						<p>To the following location:</p>
						<p class="badge badge-light bg-light font-size-12 text-dark"><i class="bx bx-store font-size-20 text-primary"></i> {{settings.listing.name}} {{settings.listing.address_1}} {{settings.listing.postcode}}</p>
					
						<h4 class="py-3">Product to Transfer</h4>
						<h5>{{productOptionsData.reference}}: {{productOptionsData.name}}</h5>
						<h4 class="py-3">Quantity to Transfer</h4>
						<h5>{{selectedproductqty}}</h5>
						</div>
						

						<a class="btn btn-success btn-rounded btn-block mt-3 py-3" @click="compileTransfer(selecetedproductlisting, settings.listing)">Book Transfer</a>
					</b-col>
				</b-row>
			</b-overlay>
		</b-modal>
		<b-modal id="terminal-connection" hide-footer hide-header centered v-model="connectionStatusModal" no-close-on-esc no-close-on-backdrop >
			<b-card class="shadow-none border border-radius-1em">
				<div v-if="(terminal.connectionStatus !== 'not_connected') && (terminal.connectionStatus !== 'connected') && (terminal_status == 'pending-connection')" class="text-center">
					<img class="card-reader d-block w-100" src="https://stripe-images.s3.amazonaws.com/terminal/hardware_skus/WisePOS-E.png">
					<svg class="checkmark checkmark-error" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
								  <circle class="checkmark__circle__error" cx="26" cy="26" r="25" fill="#feb346"/>
								     <rect data-v-0b26b296="" x="45" y="10" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -26.5097 64)" width="8" height="6" class="st0 checkmark__pending" style="
									    transform: translate(-16px, 25px) rotateY(30deg);
									    fill: white;
									"></rect> <rect data-v-0b26b296="" x="98" y="63" transform="translate(-62px, -52px) rotateY(30deg)" width="8" height="20" class="st0 checkmark__cross" style="
									    transform: translate(-62px, -54px) rotateY(30deg);
									    fill: white;
									"></rect>
								</svg>
					<h4>Terminal Connection Pending...</h4>
					<p>Your terminal connection is pending, please wait...</p>

					
				</div>
				<div v-if="terminal.connectionStatus == 'not_connected'" class="text-center">
					<img class="card-reader d-block w-100" src="https://stripe-images.s3.amazonaws.com/terminal/hardware_skus/WisePOS-E.png">
					<svg class="checkmark checkmark-error" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
								  <circle class="checkmark__circle__error" cx="26" cy="26" r="25" fill="#F40101"/>
								  <rect data-v-0b26b296="" x="45" y="10" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -26.5097 64)" width="38.7" height="1.9" class="st0 checkmark__cross"></rect> <rect data-v-0b26b296="" x="98" y="63" transform="matrix(-0.7071 -0.7071 0.7071 -0.7071 64 154.5097)" width="38.7" height="1.9" class="st0 checkmark__cross"></rect>
								</svg>
					<h4>Terminal Disconnected</h4>
					<p>Your terminal could not communicate with your point of sale.</p>

					<b-form-group class="my-2" label="Selected Terminal" v-if="settings.listing !== ''">
					  	  	<SearchResource :id="settings.terminal" :options="settings.listing.stripe_terminals" :resource="'terminals'" @resource-selected="terminalSelected"></SearchResource>
					  	  </b-form-group>

					<a class="btn btn-primary w-100 py-3 mb-2" @click="initialiseTerminalScript()">
						Refresh Connection
					</a>
					<a class="btn btn-white text-danger w-100 py-2" @click="connectionStatusModal = false">
						Continue without Terminal
					</a>
				</div>
				<div v-if="terminal.connectionStatus ==  'connected'" class="text-center">
					<img class="card-reader d-block w-100" src="https://stripe-images.s3.amazonaws.com/terminal/hardware_skus/WisePOS-E.png">
					<svg class="checkmark checkmark-error" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
								  <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="red"/>
								  <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
								</svg>
					<h4>Terminal Successfully Connected</h4>
					<p>Your card terminal is connected. You can now process card and contactless payments via your point of sale.</p>

					<a class="btn btn-success w-100 py-3" @click="connectionStatusModal = false">
						Dismiss
					</a>

				</div>
			</b-card>
		</b-modal>
		<b-modal id="add-address"  hide-footer hide-header centered >
			<CompanyCustomerAddressForm  :action="'create'" :user="user" :customer="customer" @address-data="loadAddressData"></CompanyCustomerAddressForm>
		</b-modal>
	</Layout>

</template>

<style scoped>
	.pos-product-frame{
		top: 75px;
		bottom: 0px;
		left: 10px;
		right: 10px;
	}
	.receipt-font-size{
		font-size: 75%;
	}
	.inner-window-list{
		height: 80vh;
	}
	.btn-group input[type=date]{
		visibility: visible;
		display: block;
	}
	.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #7ac142;
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}
	.checkmark__circle__error {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #F40101;
  fill: #F40101;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}
#divOuter{
  transform: scale(1.5);
}
.checkmark {
  width: 128px;
  height: 128px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #fff;
  stroke-miterlimit: 10;
  margin: 5% auto;
  background: #7ac142;
  box-shadow: inset 0px 0px 0px #7ac142;
  animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
}
img.card-reader{
	max-width: 300px !important;
	margin: auto;
}
.checkmark.checkmark-error{
	margin-top: -30%;
}
.checkmark__cross {


  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}
.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

video{
	width: 100% !important;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes scale {
  0%, 100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}
@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px #7ac142;
  }
}

</style>