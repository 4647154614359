<script>
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
//import 'swiper/css/swiper.css'
/**
 * Dashboard Component
 */
export default {
  components: {
    Swiper,
    SwiperSlide
  },
  directives: {
    swiper: directive
  },
  data() {
    return {
    	hoverIndex: -1,
    	selected_index: 0,
      storageurl: '.s3.eu-west-2.amazonaws.com',
      storageend: '',
      enabled: false,
    };
  },
  props: {
  	isAdmin: {
      type: Array,
      default: () => false
    },
    isTrade: {
      type: Array,
      default: () => false
    },
    isPublic: {
      type: Array,
      default: () => false
    },
    isWebsite: {
      type: Array,
      default: () => false
    },
    items: {
  		type: Array,
  		default: () => []
  	},
  	swiperOptions: {
				type: Object,
				default: () => {
          // Some Swiper option/callback...
        },
		},
  	startSlide: {
  		type: Number,
  		default: () => 2
  	},
    imgClass: {
      type: String
    },
  	roundedBorders: {
  		type: Boolean,
  		default: () => true
  	},
  },
  computed: {
      swiper() {
        return this.$refs.mySwiper.$swiper
      }
    },
   mounted() {
    if(this.isAdmin == true){
      this.storageurl = '/image';
    }
     if(this.isTrade == true){
      //this.storageurl = '/trade-image';
      //this.storageend = '?s='+this.$attrs.s;
    }
    if(this.isPublic == true) {
      //this.storageurl = '/public-image';
     // this.storageend = '?s='+this.$attrs.s;
    }
    console.log('Current Swiper instance object', this.swiper);
      this.swiper.slideTo(this.startSlide, 1000, false);
  },
  methods: {
    imageSelected(index){
      
      console.log(index);
      this.$emit('image-selected', this.items[index]);
    }
  }
};
</script>

<template>
	<swiper ref="imgGallerySwiper" :options="swiperOptions" @click-slide="imageSelected">
		
    
        <swiper-slide v-for="(item, index) in items" class="h-100" >
        

            	<b-img @click="selected_index = index" v-if="'thumb' in item" :src="'https://' + $attrs.company.nickname + storageurl+item.thumb.replace('//','/')+storageend" class="border-radius-1em border img-fluid" :class="{'border border-primary' : index == selected_index}"></b-img>
              <b-img @click="selected_index = index" v-else-if="'gallery' in item" :src="'https://' + $attrs.company.nickname + storageurl+item.gallery.replace('//','/')+storageend" class="border-radius-1em border img-fluid" :class="{'border border-primary' : index == selected_index}"></b-img>
              <b-img @click="selected_index = index" v-else-if="'src' in item" :src="'https://' + $attrs.company.nickname + storageurl+item.src.replace('//','/')+storageend" class="border-radius-1em border img-fluid" :class="{'border border-primary' : index == selected_index}"></b-img>
            	
			
        </swiper-slide>

  	</swiper>
</template>