<script type="text/javascript">
	import ImageBlocks from "../components/widgets/blocks/ImageBlocks";
	import MiniCardCarousel from "../components/widgets/carousel/MiniCardCarousel";
	import Swiper from "../components/widgets/carousel/Swiper";
	export default{
		components: { Swiper, ImageBlocks, MiniCardCarousel},
		data(){
			return{
				title: '',
				breadcrumbs: [],
				data: '',
			}
		},
		created() {
			console.log(this.$route.path);
			axios.get('/customer-api/product'+this.$route.path).then(response => {
				this.data = response.data;
			}).catch(error => {
				console.log(error);
			});
			
			this.breadcrumbs = this.$route.path.split('/');
			if(this.breadcrumbs.length > 0){
			this.title = this.breadcrumbs[this.breadcrumbs.length - 1]
			}
		}
	}

</script>

<template>

		<section class="header-section pb-4">
		  <div class="container-fullwidth">
		    <!--<PageHeader :title="title" :items="items" />-->
		    <b-row style=" min-height: 350px; background: linear-gradient(45deg, rgba(94, 59, 174,0.15), rgba(239, 242, 247, 0.25))" class="bg-light bg-soft">
		      <b-col md="2" class="d-flex flex-column justify-content-end align-items-start ml-auto" >
		         
		      </b-col>
		      <b-col md="5" class="d-flex flex-column justify-content-center align-items-center">

		        <h1 class="section-info-header position-relative">Select from Jewellery Types</h1>
		        <p class="text-dark text-center">
		            Choose one of the categories below to filter by the type
		            of jewellery you are searching for.
		          </p>
		      </b-col>
		      <b-col md="2" class="d-flex flex-column justify-content-end align-items-start mr-auto" >
		         
		      </b-col>
		    </b-row>
		     <b-row class="categories-top-row mb-4 pb-4">
				<b-col md="8" class="mx-auto">
					<ImageBlocks :items="data" :grid_limit="24" :end="24" :autoplay="false"></ImageBlocks>
				</b-col>
			</b-row>
		    <!-- end row -->
		  </div>
		</section>

</template>