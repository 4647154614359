<script type="text/javascript">
	import Layout from "../layouts/public";
	import { termsConditions } from "../terms"
	export default{
		components: { Layout },
		data(){
			return{
				terms: termsConditions
			}
		}
	}

</script>

<template>
	<div :v-html="terms.public.general">
		
	</div>

</template>