<script type="text/javascript">
	export default{
		name: 'CardListWidgetCard',
		data(){
			return {
				storageurl: 'https://'+this.$attrs.company.nickname+'.s3.eu-west-2.amazonaws.com',
				hoverIndex: -1,
				hoverLink: -1,
				categories: []
			}
		},
		props: {
			product: {
				type: Object
			},
			index: {
				type: Number
			}
		}, 
		computed: {
			headerComponent: function() {
			      return {
			        template: `<${this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'title')].title.subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'title')].title.subfields, 'heading_level')].heading_level.value}>${this.$attrs.data[this.filterStylesKey(this.$attrs.data, 'title')].title.value}</${this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'title')].title.subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'title')].title.subfields, 'heading_level')].heading_level.value}>`,
			        methods: {
			          someAction() {
			            console.log("Action!");
			          }
			        }
			      }
			    }
		},
		methods: {
			filterStylesKey(styles, key){
	            for(var i = 0; i < styles.length; i++){
	               var keys = Object.keys(styles[i])
	               if(keys.includes(key)){
	                 return i;
	               }
	            }
	        },
			loadDefaultClasses(classes){
				return classes;
			},
			compileElementStyles(fieldname, types){
				var style = '';
				if(types.includes('background') == true){
					if(fieldname !== 'column'){
				  		style += 'background-color:'+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_colour')][fieldname+'_colour'].value+' !important;'
				  	}else{
				  		style += 'background-color:'+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'background_colour')].background_colour.value+' !important;'
				  	}
				}
				if(types.includes('color') == true){
					if(fieldname !== 'column'){
						style += 'color:'+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_text_colour')][fieldname+'_text_colour'].value+' !important;'
					}else{
						style += 'color:'+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'text_colour')].text_colour.value+' !important;'
					}
				}
				if(types.includes('border') == true){
					if(fieldname !== 'column'){
						style +=  'border-color: '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_border_colour')][fieldname+'_border_colour'].value+' !important; border-style:'+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_border_style')][fieldname+'_border_style'].value+' !important; border-radius:'+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_border_radius')][fieldname+'_border_radius'].top_left+' '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_border_radius')][fieldname+'_border_radius'].top_right+' '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_border_radius')][fieldname+'_border_radius'].bottom_right+' '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_border_radius')][fieldname+'_border_radius'].bottom_left+' !important; border-left:'+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_border')][fieldname+'_border'].left+'px !important; border-right: '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_border')][fieldname+'_border'].right+'px !important; border-top: '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_border')][fieldname+'_border'].top+'px !important; border-bottom: '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_border')][fieldname+'_border'].bottom+'px !important;'
					}else{
						style +=  'border-color: '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'border_colour')].border_colour.value+' !important;'

						style += 'border-style:'+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'border_style')].border_style.value+' !important;'

						style += 'border-radius:'+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'border_radius')].border_radius.top_left+' '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'border_radius')].border_radius.top_right+' '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'border_radius')].border_radius.bottom_right+' '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'border_radius')].border_radius.bottom_left+' !important;'
						style += 'border-left:'+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'border')].border.left+'px !important; border-right: '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'border')].border.right+'px !important; border-top: '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'border')].border.top+'px !important; border-bottom: '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'border')].border.bottom+'px !important;'
					}

				}
				if(types.includes('margin') == true){
					if(fieldname !== 'column'){
					style +=  'margin-left:'+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_margin')][fieldname+'_margin'].left+'px !important;';
					style +=  'margin-right: '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_margin')][fieldname+'_margin'].right+'px !important;';
					style +=  'margin-top: '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_margin')][fieldname+'_margin'].top+'px !important;';
					style +=  'margin-bottom: '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_margin')][fieldname+'_margin'].bottom+'px !important;';
					}else{
					style +=  'margin-left:'+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'margin')]['margin'].left+'px !important;'
					style +=  'margin-right: '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'margin')]['margin'].right+'px !important;'
					style +=  'margin-top: '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'margin')]['margin'].top+'px !important;'
					style +=  'margin-bottom: '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'margin')]['margin'].bottom+'px !important;'
					}
				}
				if(types.includes('padding') == true){
					if(fieldname !== 'column'){
					style +=  'padding-left:'+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_padding')][fieldname+'_padding'].left+'px !important;'
					style +=  'padding-right: '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_padding')][fieldname+'_padding'].right+'px !important;'
					style +=  'padding-top: '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_padding')][fieldname+'_padding'].top+'px !important;'
					style +=  'padding-bottom: '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_padding')][fieldname+'_padding'].bottom+'px !important;'
					}else{
						style +=  'padding-left:'+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'padding')]['padding'].left+'px !important;'
					style +=  'padding-right: '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'padding')]['padding'].right+'px !important;'
					style +=  'padding-top: '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'padding')]['padding'].top+'px !important;'
					style +=  'padding-bottom: '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'padding')]['padding'].bottom+'px !important;'
					}
				}
				if(types.includes('size') == true){
					if(fieldname !== 'column'){
					style +=  'height:'+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_height')][fieldname+'_height'].value+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_height')][fieldname+'_height'].units+' !important;';
					style +=  'max-height:'+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_max_height')][fieldname+'_max_height'].value+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_max_height')][fieldname+'_max_height'].units+' !important;';
					style +=  'width:'+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_width')][fieldname+'_width'].value+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_width')][fieldname+'_width'].units+' !important;'
					style +=  'max-width:'+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_max_width')][fieldname+'_max_width'].value+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_max_width')][fieldname+'_max_width'].units+' !important;'
					}else{
					style +=  'height:'+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'height')]['height'].value+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'height')]['height'].units+' !important;';
					style +=  'max-height:'+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'max_height')]['max_height'].value+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'max_height')]['max_height'].units+' !important;';
					style +=  'width:'+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'width')]['width'].value+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'width')]['width'].units+' !important;'
					style +=  'max-width:'+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'max_width')]['max_width'].value+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'max_width')]['max_width'].units+' !important;'
					} 
				}
				
				if(types.includes('text') == true){
				  style += 'font-family: \"'+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'font_family')].font_family.value.family+'\", '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'font_family')].font_family.value.category+' !important; font-size:'+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'font_size')].font_size.value+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'font_size')].font_size.units+' !important; text-transform: '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'text_transform')].text_transform.value+'!important;';
				}

				return style;
			}
		},
	}
</script>
<template>
	<a v-if="$attrs.liveMode == false" :href="'javascript:;'">
					<b-card v-if="$attrs.card_layout == 'vertical'" no-body class="border shadow-none border-radius-1em" @mouseenter="hoverIndex = index" @mouseleave="hoverIndex = -1" >
						<!--<transition name="fade">
						<b-card-img v-if="product.cover_image" :src="storageurl+product.cover_image.src.replaceAll('//', '/')" />
						
						</transition>-->
						<transition name="fade">

						<b-card-img-lazy v-if="$attrs.data[filterStylesKey($attrs.data, 'image')].image.value !== ''"class="rounded-0" :src="$attrs.data[filterStylesKey($attrs.data, 'image')].image.value" :style="compileElementStyles('image', ['image', 'border', 'margin', 'padding', 'sizing'])" ></b-card-img-lazy>
						</transition>
						<b-card-text class="p-4 peach-border-top-5 d-flex flex-column">
							
							<component :is="headerComponent" :style="compileElementStyles('title', ['text'])" />
							<p class="text-dark text-truncate mb-0" v-html="$attrs.data[filterStylesKey($attrs.data, 'description')].description.value"></p>
							<div class="d-flex">
							<span class="badge badge-light text-dark bg-light font-size-12 my-2">
								
							</span>
						
							<a :class="loadDefaultClasses('btn btn-peach')" :style="compileElementStyles('button', ['background', 'color', 'border'])" :href="$attrs.data[filterStylesKey($attrs.data, 'button_url')].button_url.value">
								{{$attrs.data[filterStylesKey($attrs.data, 'button_text')].button_text.value}} <i class="bx bx-chevron-right"></i>
							</a>
					
							</div>
						</b-card-text>
					</b-card>
					<b-card v-else-if="$attrs.card_layout == 'horizontal'" no-body class="overflow-hidden" @mouseenter="hoverIndex = index" @mouseleave="hoverIndex = -1">
					    <b-row no-gutters>
					      <b-col md="3"  v-if="$attrs.data[filterStylesKey($attrs.data, 'image')].image.value !== ''" >
					        <b-card-img-lazy v-if="$attrs.data[filterStylesKey($attrs.data, 'image')].image.value !== ''" class="rounded-0" :src="$attrs.data[filterStylesKey($attrs.data, 'image')].image.value" ></b-card-img-lazy>
					      </b-col>
					      <b-col :md="{9 : $attrs.data[filterStylesKey($attrs.data, 'image')].image.value !== '', 12 : $attrs.data[filterStylesKey($attrs.data, 'image')].image.value == ''}" class="d-flex flex-column justify-content-center">
					        <b-card-body class="flex-inherit" :title="$attrs.data[filterStylesKey($attrs.data, 'name')].name">
					          <b-card-text class="text-dark" v-html="$attrs.data[filterStylesKey($attrs.data, 'description')].description.value">
					          </b-card-text>
					          <div class="d-flex">
								
								
									<a  :class="loadDefaultClasses('btn btn-peach')" :style="compileElementStyles('button', ['background', 'color', 'border'])" :href="$attrs.data[filterStylesKey($attrs.data, 'button_url')].button_url.value">
										{{$attrs.data[filterStylesKey($attrs.data, 'button_text')].button_text.value}} <i class="bx bx-chevron-right"></i>
									</a>
							
							</div>
					        </b-card-body>
					      </b-col>
					    </b-row>
					  </b-card>
				</a>
				<router-link v-else :to="$attrs.data[filterStylesKey($attrs.data, 'button_url')].button_url.value">
					<b-card v-if="$attrs.card_layout == 'vertical'" no-body class="border shadow-none border-radius-1em" @mouseenter="hoverIndex = index" @mouseleave="hoverIndex = -1" >
						<!--<transition name="fade">
						<b-card-img v-if="product.cover_image" :src="storageurl+product.cover_image.src.replaceAll('//', '/')" />
						
						</transition>-->
						<transition name="fade">

						<b-card-img-lazy v-if="$attrs.data[filterStylesKey($attrs.data, 'image')].image.value !== ''"class="rounded-0" :src="$attrs.data[filterStylesKey($attrs.data, 'image')].image.value" ></b-card-img-lazy>
						</transition>
						<b-card-text class="p-4 peach-border-top-5 d-flex flex-column">
							
							<component :is="headerComponent" :style="compileElementStyles('title', ['text'])" />
							<p class="text-dark text-truncate mb-0" v-html="$attrs.data[filterStylesKey($attrs.data, 'description')].description.value"></p>
							<div class="d-flex">
							<span class="badge badge-light text-dark bg-light font-size-12 my-2">
								
							</span>
						<transition name="fade-right">
							<a v-if="hoverIndex == index" class="border-bottom border-primary text-uppercase font-size-12 ml-auto text-dark my-auto py-2" :href="$attrs.data[filterStylesKey($attrs.data, 'button_url')].button_url.value">
								{{$attrs.data[filterStylesKey($attrs.data, 'button_text')].button_text.value}} <i class="bx bx-chevron-right"></i>
							</a>
						</transition>
							</div>
						</b-card-text>
					</b-card>
					<b-card v-if="$attrs.card_layout == 'horizontal'" no-body class="overflow-hidden" @mouseenter="hoverIndex = index" @mouseleave="hoverIndex = -1">
					    <b-row no-gutters>
					      <b-col md="3"  v-if="$attrs.data[filterStylesKey($attrs.data, 'image')].image.value !== ''" >
					        <b-card-img-lazy v-if="$attrs.data[filterStylesKey($attrs.data, 'image')].image.value !== ''" class="rounded-0" :src="$attrs.data[filterStylesKey($attrs.data, 'image')].image.value" ></b-card-img-lazy>
					      </b-col>
					      <b-col :md="{9 : $attrs.data[filterStylesKey($attrs.data, 'image')].image.value !== '', 12 : $attrs.data[filterStylesKey($attrs.data, 'image')].image.value == ''}" class="d-flex flex-column justify-content-center">
					        <b-card-body class="flex-inherit" :title="$attrs.data[filterStylesKey($attrs.data, 'name')].name">
					          <b-card-text class="text-dark" v-html="$attrs.data[filterStylesKey($attrs.data, 'description')].description.value">
					          </b-card-text>
					          <div class="d-flex">
								
								<transition name="fade-right">
									<a v-if="hoverIndex == index" class="border-bottom border-primary text-uppercase font-size-12 ml-auto text-dark my-auto py-2" :href="$attrs.data[filterStylesKey($attrs.data, 'button_url')].button_url.value">
										{{$attrs.data[filterStylesKey($attrs.data, 'button_text')].button_text.value}} <i class="bx bx-chevron-right"></i>
									</a>
								</transition>
							</div>
					        </b-card-body>
					      </b-col>
					    </b-row>
					  </b-card>
				</router-link>
</template>