<script>
//import Vertical from "./vertical";
import WebsiteWrapper from "./websitewrapper";



export default {
  components: { WebsiteWrapper},
  data() {
    return {

    };
  },
  props: {
    user: {
      type: Object
    },
    domain: {
      type: Object
    }
  },
  computed: {
    layoutType() {
        return this.$root.layout?.type;
    }
  },
  methods: {},
};
</script>

<template>
  <div>

    <WebsiteWrapper :domain="domain" :user="user">
      <router-view :domain="domain" :user="user" ></router-view>
    </WebsiteWrapper>
        
  </div>
</template>
