<script>
import draggable from "vuedraggable";
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
import Swal from "sweetalert2";
import SideSlidePanel from "../../components/widgets/SideSlidePanel";
import SearchCompanyUser from "../../components/widgets/SearchCompanyUser";
import UserList from "../../components/widgets/UserList";
import SearchCompanyUsers from "../../components/widgets/SearchCompanyUsers";
import SearchCompanyDepartment from "../../components/widgets/SearchCompanyDepartment";
import Subscription from '../../components/widgets/subscription';
import AccessRestricted from "../../views/utility/access-restricted";

/**
 * Contacts-list component
 */
export default {
  components: { draggable, Layout, PageHeader, SideSlidePanel, SearchCompanyUser, UserList, SearchCompanyUsers, SearchCompanyDepartment, Subscription, AccessRestricted },
  data() {
    return {
      title: "Statuses",
      view: 'list',
      dragging: false,
      selectedids: [],
      userAddToDepartment: false,
      teams: [],
      load_error: [],
      delete_confirm: false,
      delete_action: false, 
      deleted_message: false,
      delete_form_load: false,
      delete_assprojects: false,
      delete_assactions: false,
      error_message: false,
      success_message: false,
      error_reponse: [],
      currentPage: 1,
      limit: 25,
      selectedids: [],
      loadingDetails: 1,
      orderDesc: true,
      order: 'desc',
      sortby: 'id',
      search: '',
      timeout: null,
      formResponse: '',
      errorResponse: [],
      addFormSubmitted: false,
      updateManagerBtn: false,
      isCheckAll: false,
       status_settings: {
      email: {
              department_manager: false,
              team_manager: false,
              customer: false,
            },
            action:{
              order_confirmation: false,
              order_invoice: false,
              attach_invoice: false,
              delivery_note: false,
            },
            mark: {
              mark_paid: false,
              mark_shipped: false,
              mark_refunded: false,
              mark_complete: false,
              hide_status: false,
            },
      },
      newTeam:{
        id: '',
        name: '',
        description: '',
        colour: '',
        text_color: '',
        order: 0,
        active: 0,
        default: 0,
        company_id: this.$attrs.company.id,
        settings: {
          statusupdate: {
            email: {
              department_manager: false,
              team_manager: false,
              assignedto: false,
              assignedby: false,
              collaborators: false,
            },
            action: {
              mark_complete: false,
              mark_archived: false,
              mark_approved: false,
              mark_seeking_approval: false,
              mark_overdue: false,
            }
          },
        },
      },
      updateTeam: {
       id: '',
        name: '',
        description: '',
        color: '',
        text_color: '',
        order: 0,
        default: 0,
        active: 1,
        company_id: this.$attrs.company.id,
        department_manager: false,
        team_manager: false,
        customer: false,
        order_confirmation: false,
        order_invoice: false,
        attach_invoice: false,
        delivery_note: false,
        mark_paid: false,
        mark_shipped: false,
        mark_refunded: false,
        mark_complete: false,
        hide_status: false,
        email_settings: [],
        list_order: 0,
      },
      updatePanel: false,
      addPanel: false,
      staffPanel: false,
      limits: [
        {value: 25, text: "25"},
        {value: 50, text: "50"},
        {value: 100, text: "100"},
      ],
      items: [
        {
          text: "Project",
          href: "/"
        },
        {
          text: "Statuses",
          active: true
        }
      ]
    };
  },
  created(){
    if(this.$attrs.validsubscription == 0){
      this.title = 'Subscription';
      this.items[0].text = 'Company';
      this.items[1].text = 'Subscription';
    }
    this.searchResource();
  },
  methods: {
    checkAll: function(){ 
                 this.isCheckAll = !this.isCheckAll; 
                 this.selectedids = []; 
                 if(this.isCheckAll){ 
                     // Check all 
                     for (var key in this.teams.data) {          
                        this.selectedids.push(this.teams.data[key].id); 
                     } 
                 } 
              }, 
    updateCheckall: function(){ 
                 if(this.selectedids.length == this.teams.data.length){
                    this.isCheckAll = true; 
                 }else{ 
                    this.isCheckAll = false; 
                 } 
    }, 
    deleteStatusEvent(action){
      this.delete_action = action;
      this.delete_confirm = true;
    },
    deleteStatus(action){
      this.delete_form_load = true;
      axios.post('/api/company/service/status/'+action.id+'/destroy', {deleteprojects: this.delete_assprojects, deleteactions: this.delete_assactions}, {headers: {
                  'Authorization': 'Bearer ' + this.$attrs.user.api_token
              }}).then(response => {
         if(response.data == true){
                var self = this;
                setTimeout(function(){
                 //Vue.swal("Avatar Updated");
                 self.delete_confirm = false;
                 self.deleted_message = true;
                 self.delete_form_load =false;
                 self.searchResource();
                }, 1500);
            }
          }).catch(error => {
        this.error_message = true;
      });
    },
    searchResource(){
      this.loadingDetails = 1;
      axios.get('/api/company/service/statuses?page='+this.currentPage+'&limit='+this.limit+'&sortby='+this.sortby+'&order='+this.order+'&search='+this.search+'', {headers: {
                  'Authorization': 'Bearer ' + this.$attrs.user.api_token
              }}).then(response => {
            this.teams = response.data;
            this.setShortTimeout(() => {
            this.loadingDetails = 0;
            });
      }).catch(error => {
            this.load_error = response.error;
            this.setShortTimeout(() => {
            this.loadingDetails = 0;
            });
      });
    },
    searchFilter(e) {
      const searchStr = e.target.value;
      this.searchResource();
    },
    setTimeout(callback) {
        this.clearTimeout()
        this.timeout = setTimeout(() => {
          this.clearTimeout()
          callback()
        }, 1500)
    },
    setShortTimeout(callback) {
        this.clearTimeout()
        this.timeout = setTimeout(() => {
          this.clearTimeout()
          callback()
        }, 500)
    },
    clearTimeout() {
        if (this.timeout) {
          clearTimeout(this.timeout)
          this.timeout = null;
        }
    },
    addNewTeam(){
      this.addFormSubmitted = true;
      this.error_message = false;
      axios.post('/api/company/service/status/store', this.newTeam, {headers: {
                  'Authorization': 'Bearer ' + this.$attrs.user.api_token
              }}).then(response => {
            this.formResponse = response.data;
             this.setTimeout(() => {
          this.success_message = true;
          this.newTeam = {
            id: '',
            name: '',
            description: '',
            manager_id: '',
            company_department_id: '',
            company_id: this.$attrs.company.id,
          };
        });  
            this.setTimeout(() => {
                     this.addFormSubmitted = false;
                     this.addPanel = false;
                     this.searchResource();
            });
      }).catch(error => {
            this.error_reponse = error.response.data.errors;
            this.error_message = true;
            this.setTimeout(() => {
            this.addFormSubmitted = false;
          });
      });
    },
    updateExistingTeam(){
        axios.post('/api/company/service/status/'+this.updateTeam.id+'/update', this.updateTeam, {headers: {
                  'Authorization': 'Bearer ' + this.$attrs.user.api_token
              }}).then(response => {
          this.success_message = true;
          this.setTimeout(() => {
                     this.addFormSubmitted = false;
                     this.addPanel = false;
                     this.searchResource();
            });
        }).catch(error => {
          this.error_reponse = error.response.data.errors;
          this.error_message = true;
            this.setTimeout(() => {
            this.addFormSubmitted = false;
          });
        });
      },
      setActive(active, area){
            axios.post('/api/company/service/status/set-active', {ids: this.selectedids, active: active, market: area}, {headers: {
              'Authorization' : 'Bearer '+this.$attrs.user.api_token
            }}).then(response => {
              
              this.success_text = 'Congratulations, your action has been saved';
              this.success_message = true;
              if(this.selectedids.length > 1){
                return this.productQuery();
              }else{
                return true;
              }
                
            }).catch(error => {

            });
      },
      getManager(selectedOption){
        this.newTeam.manager_id = selectedOption.companies[0].id;
        this.updateTeam.manager_id = selectedOption.companies[0].id;
      },
      getDepartment(selectedOption){
        this.newTeam.company_department_id = selectedOption.id;
        this.updateTeam.company_department_id = selectedOption.id;
      },
      sortOrder(evt){
        console.log(evt);
      },
      filterSelected(id){
        if(this.selectedids.length > 0){
          var exists = this.selectedids.filter(item => item == id);
          if(exists.length > 0){
            return true;
          }else{
            return false
          }
        }else{
          return false;
        }
      }
  },
  computed:{
     
  }
};
</script>

<template>
  <Layout :nickname="$attrs.nickname" :user="$attrs.user" >
    <PageHeader :title="title" :items="items" />
   <!-- <AccessRestricted  />-->
    <div >
    <div class="row mb-3">
       <b-col md="12">
                    <transition name="slide-success" mode="out-in">
                  <b-alert id="success-message" variant="success" :show="success_message"  dismissible @dismissed="success_message = !success_message">
                      <p class="font-size-14">Congratulations, your action has been saved.</p>
                    </b-alert>
                  </transition>
                  </b-col>
          <div class="col-xl-1 col-sm-6">
            <div class="mt-2">
              <b-form-select class="form-control" v-model="limit" :options="limits" @input="productQuery()"></b-form-select>
            </div>
          </div>
         
          <div class="col-lg-2 col-sm-6 d-flex">
          <form class="my-auto">
            <small class="mx-auto my-2 text-center d-block">Results {{teams.from}} - {{teams.to}} of {{teams.total}}</small>
          </form>
        </div>
        <div class="col-lg-5 col-sm-6 d-flex">
            <div class="mt-sm-0 form-inline w-100" >
              <div class="search-box mr-2 ml-auto">
                <div class="position-relative">
                  <input
                    type="text"
                    class="form-control w-100 border-0"
                    placeholder="Search name, reference or description..."
                    v-model="search"
                    @keyup.enter="searchFilter($event)"
                  />
                  <i class="bx bx-search-alt search-icon"></i>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-sm-6 d-flex">
            <div class="d-flex ml-auto">
              <ul class="nav nav-pills product-view-nav">
                <li class="nav-item">
                  <a class="nav-link" :class="{'active': showFilters == true }" href="javascript: void(0);" @click="showFilters = !showFilters">
                    <i class="bx bx-filter-alt"></i>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" :class="{'active': view == 'grid' }" href="javascript: void(0);" @click="view = 'grid'">
                    <i class="bx bx-grid-alt"></i>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" :class="{'active': view == 'list' }" href="javascript: void(0);" @click="view = 'list'">
                    <i class="bx bx-list-ul"></i>
                  </a>
                </li>
                <li class="nav-item">
                 <b-dropdown text="Bulk Actions" variant="primary" class="rounded">
                  <b-dropdown-item href="javascript: void(0);">Action</b-dropdown-item>
                  <b-dropdown-item href="javascript: void(0);">Another action</b-dropdown-item>
                  <b-dropdown-item href="javascript: void(0);">Something else here</b-dropdown-item>
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-item href="javascript: void(0);">Separated link</b-dropdown-item>
                </b-dropdown>
                </li>
                <li class="nav-item">
                 <b-button
                    class="btn btn-success btn-rounded mb-2 mr-2 text-white"
                    href="javascript:;"
                    @click="addPanel = !addPanel"
                    :disabled="!$attrs.user.companies[0].permissions.add.includes($attrs.active_menu.id)"
                  >
                    <i class="mdi mdi-plus mr-1"></i> New
                  </b-button>
                </li>
              </ul>
              <div class="text-sm-right">
                  
                </div>
          </div>
        </div>

      </div>
        <div class="row">
       <div class="col-md-12">
            <SideSlidePanel id="filter-project-panel" :editPanel="delete_confirm || deleted_message" :panelClass="'search-sidepanel mb-4'" @close-panel="delete_confirm = false">
               <b-overlay
                        id="report-generating-overlay"
                        :show="delete_form_load"
                        variant="white"
                        opacity="0.85"
                        blur="1px"
                        rounded="md"
                        class="w-100"
                      >
          <div class="col-md-12 confirm-delete d-flex h-100" v-if="delete_confirm == true">
            <div class="card my-auto mx-auto pb-3 text-center">
              <div class="card-body">
              <h4>Are You Sure?</h4>
              <p>You Are About to Delete Project Status {{delete_action.name}}</p>
               <b-row>
              <b-col md="12">
              <b-form-group title="Delete Projects" description="Delete all projects with this status">
                <b-form-checkbox v-model="delete_assprojects" switch></b-form-checkbox>
              </b-form-group>
            </b-col>
             <b-col md="12">
              <b-form-group v-if="delete_assprojects == true" title="Delete All Actions" description="Delete all actions">
                <b-form-checkbox v-model="delete_assactions" switch></b-form-checkbox>
              </b-form-group>
            </b-col>
            </b-row>
              <a class="btn btn-success float-left" @click="deleteStatus(delete_action)">Confirm</a>
              <a class="btn btn-danger float-right" @click="delete_confirm = false, delete_action = []">Cancel!</a>
            </div>
            </div>
          </div>
          <div class="col-md-12 confirm-delete d-flex h-100" v-if="deleted_message == true">
            <div class="card my-auto mx-auto pb-3 text-center">
              <div class="card-body">
              <h4>Status Deleted</h4>
              <p>Your status has been deleted</p>
              <a class="btn btn-success mx-auto text-center" @click="delete_confirm = false, deleted_message = false">Close</a>
            </div>
            </div>
          </div>
        </b-overlay>
        </SideSlidePanel>
      </div>
    </div>
    <div class="d-flex flex-column justify-content-center h-100 restricted-col" v-if="($attrs.user.companies[0].userrole.name !== 'Owner') && !$attrs.user.companies[0].permissions.read.includes($attrs.active_menu.id)" >
          <AccessRestricted :page="false"></AccessRestricted>
        </div>
        <div v-else>
    <div class="row" v-if="view == 'list'">
      
      <div class="col-lg-12">
        <div class="card">
          <div class="">
            <b-overlay
                        id="overlay-background"
                        :show="loadingDetails == 1"
                        variant="white"
                        class="bg-white"
                        opacity="0.5"
                        blur="1px"
                        rounded="md"
                        
                      >
            <div class="table-responsive">
              <table class="table table-centered table-nowrap table-hover">
                <thead>
                  <tr>
                    <th scope="col" style="width: 100px; text-align: center;"><input type="checkbox" class="align-middle" @click='checkAll()' v-model="isCheckAll" /></th>
                    <th scope="col">Type</th>
                    <th scope="col">Description</th>
         
                    <th scope="col">Active</th>
                    <th scope="col">Order</th>
                    <th scope="col" style="width: 150px;">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr valign="middle" v-for="team in teams.data" :key="team.id"  handle=".handle" :move="sortOrder" @start="dragging = true"
        @end="dragging = false">
                    <td>
                      <div class="text-center">
                        <b-form-checkbox class="select-row-btn" v-model="selectedids" :value="team.id" button-variant="primary" button>

                          <i v-if="filterSelected(team.id)" class="bx bx-check"></i><span :style="{'background-color': team.colour}" class="avatar-title rounded-circle" v-else>{{(team.name).slice(0,1)}}</span></b-form-checkbox>
                      </div>
                      
                    </td>
                    <td>
                      <p class="font-size-14 m-0">
                        <a href="javascript:;" class="text-primary" @click="updateTeam = team, updatePanel = true"><h6 class="mb-0">{{team.name}}</h6></a>
                      </p>
                    </td>
                    <td>
                      <p class="text-muted m-0 text-truncate">{{team.description}}</p>                   
                    </td>
                   
                   <td>
                      <div
                        class="form-check form-switch form-switch-sm mb-0 mt-auto"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          :id="'SwitchCheckSizeactive'+team.id"
                          v-model="team.active"
                          :value="team.active"
                          name="active"
                          :disabled="($attrs.user.companies[0].userrole.name !== ('Owner' || 'Manager')) && (!$attrs.user.companies[0].permissions.edit.includes($attrs.active_menu.id))"
                          @change="selectedids = [], selectedids.push(team.id), setActive(team.active, 'active')"
                        />
                        <label class="form-check-label" :for="'SwitchCheckSizeactive'+team.id" 
                          >Active</label
                        >
                    </div>
                   
                    </td>
                   <td>
                    <div class="avatar-xs">
                        <a href="javascript:;" v-b-tooltip.hover title="Status Board Order">
                         <span class="avatar-title rounded-circle">
                          {{team.list_order}}
                        </span>
                      </a>
                    </div>
                  </td>
                    <td>
                      <ul class="list-inline font-size-20 contact-links mb-0">
                        <li class="list-inline-item px-1" v-if="$attrs.user.companies[0].permissions.edit.includes($attrs.active_menu.id)">
                          <a v-b-tooltip.hover title="Edit" @click="updateTeam = team, updatePanel = true">
                            <i class="bx bx-edit"></i>
                          </a>
                        </li>
                        <!--<li class="list-inline-item px-1">
                          <a v-b-tooltip.hover title="Reports">
                            <i class="bx bx-line-chart"></i>
                          </a>
                        </li>-->
                        <li class="list-inline-item px-1" v-if="$attrs.user.companies[0].permissions.delete.includes($attrs.active_menu.id)">
                          <a v-b-tooltip.hover title="Delete" @click="deleteStatusEvent(team)">
                            <i class="bx bx-trash-alt"></i>
                          </a>
                        </li>
                        <li class="list-inline-item px-1" v-if="$attrs.user.companies[0].permissions.edit.includes($attrs.active_menu.id)">
                           <i class="bx bx-move handle"></i>
                        </li>
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            </b-overlay>
            <div class="row">
              <div class="col-lg-12">
                 <b-pagination
              class="justify-content-center"
              pills
              v-model="currentPage"
              :total-rows="teams.total"
              :per-page="teams.per_page"
              aria-controls="my-table"
              @input="searchResource"
            ></b-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    
    </div>
    <div class="row" v-if="view == 'grid'">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
            <div v-for="team in teams.data" :key="team.id" class="col-xl-3 col-sm-6">
        <div class="card text-center">
          <div class="card-body">
            <div class="avatar-sm mx-auto mb-4">
              <span
                class="avatar-title rounded-circle bg-soft-primary text-primary font-size-16"
              >{{(team.name).slice(0,1)}}</span>
            </div>
            <h5 class="font-size-15">
              <a href="javascript: void(0);" class="text-dark">{{team.name}}</a>
            </h5>
            <p class="text-muted">{{team.description}}</p>
            <div v-if="team.manager.user.avatar" class="btn btn-primary rounded-pill">
                        <img class="rounded-circle avatar-xs" :src="'/'+team.manager.user.avatar" alt />
                        <span>{{team.manager.user.name}} {{team.manager.user.lastname}} - {{team.manager.user.jobtitle}}</span>
                     </div>
            <div class="mt-3">
              <strong>Projects</strong>
             <h5 class="text-primary">{{team.projects_count}}</h5>
            </div>
          </div>
          <div class="card-footer bg-transparent border-top">
            <div class="contact-links d-flex font-size-20">
              <div class="flex-fill">
                <a v-b-tooltip.hover title="Edit">
                            <i class="bx bx-edit"></i>
                </a>
              </div>
              <div class="flex-fill">
                  <a v-b-tooltip.hover title="View">
                            <i class="mdi mdi-eye-outline"></i>
                  </a>
              </div>
              <div class="flex-fill">
                 <a v-b-tooltip.hover title="Staff">
                            <i class="bx bx-group"></i>
                  </a>
              </div>
              <div class="flex-fill">
                          <a v-b-tooltip.hover title="Reports">
                            <i class="bx bx-line-chart"></i>
                          </a>
              </div>
              <div class="flex-fill">
                          <a v-b-tooltip.hover title="Delete">
                            <i class="bx bx-trash-alt"></i>
                          </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
            <div class="row">
              <div class="col-lg-12">
                 <b-pagination
                    class="justify-content-center"
                    pills
                    v-model="currentPage"
                    :total-rows="teams.total"
                    :per-page="teams.per_page"
                    aria-controls="my-table"
                    @input="searchResource"
                  ></b-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
    <SideSlidePanel :editPanel="addPanel" :panelClass="'edit-sidepanel'" @close-panel="addPanel = false" >
      <b-row>
        <b-col md="12">
                    <transition name="slide-error" mode="out-in">
                    <b-alert id="errors" variant="danger" :show="error_message"  dismissible @dismissed="error_message = !error_message">
                      <p class="font-size-14">Oops! Please correct the following errors in your submission</p>
                      <ul>
                        <li v-for="(error, key) in error_reponse">
                          {{error[0]}}
                        </li>
                      </ul>
                    </b-alert>
                    
                  </transition>
                  </b-col>
              <b-overlay
                        id="overlay-background"
                        :show="addFormSubmitted"
                        variant="white"
                        opacity="0.85"
                        blur="1px"
                        rounded="md"
                      >
              <b-col md="12">
                <div class="m-4 px-2">
                <h4>Add New Status</h4>
                
              <b-row>
                <b-col md="12">
                  <b-form-group label="Name">
                    <b-form-input type="text" name="name" v-model="newTeam.name" :state="newTeam.name.length > 0"></b-form-input>
                  </b-form-group>
                  <b-form-group label="Description" :description="newTeam.description.length+' of 200'">
                     <b-form-textarea name="name" v-model="newTeam.description" rows="5" placeholder="Enter short company description..." :state="(newTeam.description.length <= 200) && (newTeam.description.length > 0)"></b-form-textarea>
                  </b-form-group>
                    <b-form-group
                    id="color"
                    label-cols-sm="2"
                    label-cols-lg="2"
                    label="Colour"
                    label-for="color"

                  >
                    <b-form-input id="color" type="color" name="color" v-model="newTeam.colour"></b-form-input>
                  </b-form-group>
                  <b-form-group
                    id="text-color"
                    label-cols-sm="2"
                    label-cols-lg="2"
                    label="Text Colour"
                    label-for="color"

                  >
                    <b-button variant="dark" class="btn-label" v-model="newTeam.text_color = 'text-dark'">
                      <i class="bx bx-font-color label-icon"></i> Dark
                    </b-button>
                    <b-button variant="light" class="btn-label" v-model="newTeam.text_color = 'text-light'">
                      <i class="bx bx-font-color label-icon"></i> Light
                    </b-button>
                  </b-form-group>
                 <div class="jumbotron pt-4 mt-2">
                    <h4>Status Actions</h4>
                    <p>Status Actions are triggered when you update the status of the order. This can include actions like notifying other users or marking the order as shipped or completed.</p>
                  <b-form-group
                    id="color"
                    label-cols-sm="2"
                    label-cols-lg="2"
                    label-class="p-0"
                    content-cols-lg="10"
                    label="Email"
                    description=""
                    label-for="color"
                    class="p-2"
                  >
                             <b-form-checkbox v-for="(item, key) in status_settings.email" v-model="newTeam.email_settings" name="check-button" switch :name="key" multiple :value="key">
                               <span class="ml-2 text-capitalize"> {{key.replaceAll('_', ' ')}}</span>
                        </b-form-checkbox>
                  </b-form-group>
                  <b-form-group
                    id="color"
                    label-cols-sm="2"
                    label-cols-lg="2"
                    label-class="p-0"
                    content-cols-lg="10"
                    label="Action"
                    description=""
                    label-for="color"
                    class=" p-2 mb-0"

                  >
                             <b-form-checkbox v-for="(item, key) in status_settings.action" v-model="newTeam[key]" name="check-button" switch :name="key">
                               <span class="ml-2 text-capitalize">{{key.replaceAll('_', ' ').replaceAll('order invoice', 'create invoice')}}</span>
                        </b-form-checkbox>
                  </b-form-group>
                  <b-form-group
                    id="color"
                    label-cols-sm="2"
                    label-cols-lg="2"
                    label-class="p-0"
                    content-cols-lg="10"
                    label="Mark Item"
                    description=""
                    label-for="color"
                    class=" p-2 mb-0"

                  >
                             <b-form-checkbox v-for="(item, key) in status_settings.mark" v-model="newTeam[key]" name="check-button" switch :name="key">
                              <span class="ml-2 text-capitalize">{{key.replaceAll('_', ' ')}}</span>
                        </b-form-checkbox>
                  </b-form-group>
                  </div>
                  <a href="javascript:;" class="btn btn-success" @click="addNewTeam">Save</a>
                </b-col>
              </b-row>
                </div>
              </b-col>
              </b-overlay>
      </b-row>            
    </SideSlidePanel>
    <SideSlidePanel :editPanel="updatePanel" :panelClass="'edit-sidepanel'" @close-panel="updatePanel = false">
      <b-row>
        <b-col md="12">
                    <transition name="slide-error" mode="out-in">
                    <b-alert id="errors" variant="danger" :show="error_message"  dismissible @dismissed="error_message = !error_message">
                      <p class="font-size-14">Oops! Please correct the following errors in your submission</p>
                      <ul>
                        <li v-for="(error, key) in error_reponse">
                          {{error[0]}}
                        </li>
                      </ul>
                    </b-alert>
                    
                  </transition>
                  </b-col>
              <b-col md="12">
                <div class="m-4 px-2" v-if="updatePanel == true">
                 <h4>Edit Status</h4>
                  <b-alert :show="errorResponse.length" dismissible variant="danger" @dismissed="errorResponse=[]">
                     <ul>
                       <li v-for="error in errorResponse">{{error.error}}</li>
                     </ul>
                  </b-alert>
                  <b-alert :show="formResponse.length > 0" dismissible variant="success" @dismissed="formResponse=[]">
                     <ul>
                       <li>{{formResponse.name}} has been updated</li>
                     </ul>
                  </b-alert>
                  <b-form-group label="Name">
                    <b-form-input type="text" name="name" v-model="updateTeam.name" :state="updateTeam.name.length > 0"></b-form-input>
                  </b-form-group>
                  <b-form-group label="Description" :description="updateTeam.description.length+' of 200'">
                     <b-form-textarea name="name" v-model="updateTeam.description" rows="5" placeholder="Enter short company description..." :state="(updateTeam.description.length <= 200) && (updateTeam.description.length > 0)"></b-form-textarea>
                  </b-form-group>
                  <b-form-group
                    id="color"
                    label-cols-sm="2"
                    label-cols-lg="2"
                    label="Colour"
                    label-for="color"
                    class="p-2"

                  >
                    <b-form-input id="color" type="color" name="color" v-model="updateTeam.colour"></b-form-input>
                  </b-form-group>
                  <b-form-group
                    id="text-color"
                    label-cols-sm="2"
                    label-cols-lg="2"
                    label="Text Colour"
                    label-for="color"
                    class="p-2 mb-0"

                  >
                    <b-button variant="dark" class="btn-label" v-model="updateTeam.text_color = 'text-dark'">
                      <i class="bx bx-font-color label-icon"></i> Dark
                    </b-button>
                    <b-button variant="light" class="btn-label" v-model="updateTeam.text_color = 'text-light'">
                      <i class="bx bx-font-color label-icon"></i> Light
                    </b-button>
                  </b-form-group>
                 <b-card class="border-radius-1em border shadow-none mt-3">
                    <h4 class="mb-3">Status Actions</h4>
                   <b-form-group
                    id="color"
                    label-cols-sm="2"
                    label-cols-lg="2"
                    label-class="p-0"
                    content-cols-lg="10"
                    label="Email"
                    description=""
                    label-for="color"
                    class="p-2"
                  >
                             <b-form-checkbox v-for="(item, key) in status_settings.email" v-model="updateTeam.email_settings" name="check-button" switch :name="key" multiple :value="key">
                               <span class="ml-2 text-capitalize"> {{key.replaceAll('_', ' ')}}</span>
                        </b-form-checkbox>
                  </b-form-group>
                  <b-form-group
                    id="color"
                    label-cols-sm="2"
                    label-cols-lg="2"
                    label-class="p-0"
                    content-cols-lg="10"
                    label="Action"
                    description=""
                    label-for="color"
                    class=" p-2 mb-0"

                  >
                             <b-form-checkbox v-for="(item, key) in status_settings.action" v-model="updateTeam[key]" name="check-button" switch :name="key">
                               <span class="ml-2 text-capitalize">{{key.replaceAll('_', ' ')}}</span>
                        </b-form-checkbox>
                  </b-form-group>
                  <b-form-group
                    id="color"
                    label-cols-sm="2"
                    label-cols-lg="2"
                    label-class="p-0"
                    content-cols-lg="10"
                    label="Mark Item"
                    description=""
                    label-for="color"
                    class=" p-2 mb-0"

                  >
                             <b-form-checkbox v-for="(item, key) in status_settings.mark" v-model="updateTeam[key]" name="check-button" switch :name="key" unchecked-value="0" value="1">
                              <span class="ml-2 text-capitalize">{{key.replaceAll('_', ' ')}}</span>
                        </b-form-checkbox>
                  </b-form-group>
                  </b-card>
                  <a href="javascript:;" class="btn btn-success" @click="updateExistingTeam">Save</a>
                </div>
              </b-col>
      </b-row>            
    </SideSlidePanel>

    <SideSlidePanel :editPanel="staffPanel" :panelClass="'edit-sidepanel'" @close-panel="staffPanel = false, updateManagerBtn = false">
      <b-row>
              <b-col md="12">
                <div class="m-4 px-2 row" v-if="staffPanel == true">

                  <b-col md="12">
                <h4>Department Staff</h4>
                </b-col>
                 <b-col md="12">
                  <b-form-group label="Department Manager">
                    <SearchCompanyUser :api_token="this.$attrs.user.api_token" :id="updateTeam.manager.user" @user-selected="updateManagerBtn = true" @input="getManager" />
                     <a href="javascript:;" v-if="updateManagerBtn == true" class="btn btn-light btn-block my-auto float-right" @click="updateExistingDepartment">
                  <i class="mdi mdi-reload d-block font-size-16"></i>
                Update Manager</a>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <div class="d-flex"><p class="mr-auto my-auto">Department Members</p>
                  <a class="btn btn-primary btn-rounded mb-2 mr-2 text-white ml-auto" @click="userAddToDepartment = !userAddToDepartment" v-b-tooltip.hover title="Add Staff Member">
                    <i class="mdi mdi-plus noti-icon font-size-20"></i>
                  </a>
                  </div>
                  <SearchCompanyUsers v-if="userAddToDepartment == true" :resource="'team'" :id="updateTeam.id" :api_token="this.$attrs.user.api_token" />
                  <UserList :resource="'team'" :id="updateTeam.id" :api_token="this.$attrs.user.api_token" />
                </b-col>
                </div>
              </b-col>
      </b-row>            
    </SideSlidePanel>
  </div>
  </Layout>
      <!--<Layout  :nickname="$attrs.nickname" :user="$attrs.user" >
    <PageHeader :title="title" :items="items" />
    <Subscription :company="this.$attrs.company" :user="this.$attrs.user" />
  </Layout>-->
</template>