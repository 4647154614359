<script>
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
import Swal from "sweetalert2";
import SideSlidePanel from "../../components/widgets/SideSlidePanel";
import SearchCompanyUser from "../../components/widgets/SearchCompanyUser";
import UserList from "../../components/widgets/UserList";
import SearchCompanyUsers from "../../components/widgets/SearchCompanyUsers";
import SearchCompanyDepartment from "../../components/widgets/SearchCompanyDepartment";
import Subscription from '../../components/widgets/subscription'
import UserPermissions from '../../components/widgets/admin/permissions/UserPermissions'
/**
 * Contacts-list component
 */
export default {
  components: { Layout, PageHeader, SideSlidePanel, SearchCompanyUser, UserList, SearchCompanyUsers, SearchCompanyDepartment, Subscription, UserPermissions},
  data() {
    return {
      deleteMode: 'single',
      setPermissions: false,
      title: "Users",
      view: 'list',
      userAddToDepartment: false,
      users: [],
      load_error: [],
      currentPage: 1,
      limit: 25,
      selectedids: [],
      permissions: [{
          view: [],
          add: [],
          edit: [],
          read: [],
          delete: [],
        }],
      orderDesc: true,
      order: 'desc',
      sortby: 'id',
      search: '',
       delete_action: [],
      delete_confirm: false,
      deleted_message: false, 
      success_message: false,
      delete_form_load: false, 
      error_message: false, 
      error_reponse: '',
      permissions_updating: false,
      timeout: null,
      formResponse: '',
      errorResponse: [],
      addFormSubmitted: false,
      updateFormSubmitted: false,
      loadingResource: true,
      updateManagerBtn: false,
      isCheckAll: false,
      newUser:{
        id: '',
        name: '',
        lastname: '',
        jobtitle: '',
        email: '',
        password: '',
        role_id: '',
        permissions: [],
        pos_user: false,
        company_id: this.$attrs.company.id,
      },
      updateUser: {
        id: '',
        name: '',
        lastname: '',
        jobtitle: '',
        email: '',
        password: '',
        role_id: '',
        permissions: [],
        pos_user: false,
        company_id: '',
      },
      counties: [],
      countries: [],
      updatePanel: false,
      addPanel: false,
      viewPanel: false,
      show_subtooltip: false,
      limits: [
        {value: 25, text: "25"},
        {value: 50, text: "50"},
        {value: 100, text: "100"},
      ],
      items: [
        {
          text: "Company",
          href: "/dashboard"
        },
        {
          text: "Users",
          active: true
        }
      ]
    };
  },
  created(){
    if(this.$attrs.validsubscription == 0){
      this.title = 'Subscription';
      this.items[1].text = 'Subscription';
    }
    this.searchResource();
    this.userRoles();
  },
  computed: {
    menuItems(){
        return this.$root.menu;
      },
  },
  methods: {
    deleteUserEvent(action){
      this.delete_action = action;
      this.delete_confirm = true;
    },
     destroyResource(product_id){
            axios.post('/api/company/user/'+this.selectedids[0]+'/destroy', {}, {headers: {
              'Authorization' : 'Bearer '+this.$attrs.user.api_token
            }}).then(response => {
                this.$bvModal.hide('delete-modal');
                return this.searchResource();
            }).catch(error => {

            });
    },
    bulkDestroyResource(){
            axios.post('/api/company/user/bulk-destroy', {ids: this.selectedids}, {headers: {
              'Authorization' : 'Bearer '+this.$attrs.user.api_token
            }}).then(response => {
                this.$bvModal.hide('delete-modal');
                this.selectedids = [];
                return this.searchResource();
            }).catch(error => {

            });
     },
    userRoles(){
      axios.get('/api/user/roles', {headers: {
                  'Authorization': 'Bearer ' + this.$attrs.user.api_token
              }}).then(response => {
        this.user_roles = response.data;
      }).catch(error => {
        this.load_error = error.data;
      });
    },
    searchResource(){
      axios.get('/api/company/users?page='+this.currentPage+'&limit='+this.limit+'&sortby='+this.sortby+'&order='+this.order+'&search='+this.search+'&list=full&showp=true', {headers: {
        'Authorization' : 'Bearer '+this.$attrs.user.api_token
      }}).then(response => {
            this.users = response.data;
            this.setTimeoutShort(() => {
              this.loadingResource = false;
            });
      }).catch(error => {
            this.load_error = error.data;
            this.setTimeoutShort(() => {
              this.loadingResource = false;
            });
      });
    },
    searchFilter(e) {
      const searchStr = e.target.value;
      this.searchResource();
    },
    setTimeout(callback) {
        this.clearTimeout()
        this.timeout = setTimeout(() => {
          this.clearTimeout()
          callback()
        }, 1500)
    },
    setTimeoutShort(callback) {
        this.clearTimeout()
        this.timeout = setTimeout(() => {
          this.clearTimeout()
          callback()
        }, 500)
    },
    clearTimeout() {
        if (this.timeout) {
          clearTimeout(this.timeout)
          this.timeout = null;
        }
    },
    generatePassword(type){
      if(type == 'update'){
        this.updateUser.password = Array(14).fill('0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz').map(function(x) { return x[Math.floor(Math.random() * x.length)] }).join('');
      }else if(type == 'new'){
        this.newUser.password = Array(14).fill('0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz').map(function(x) { return x[Math.floor(Math.random() * x.length)] }).join('');
      }
    },
    addNewUser(){
      this.addFormSubmitted = true;
      axios.post('/api/company/user/store', this.newUser, {
        headers: {
          'Authorization' : 'Bearer '+this.$attrs.user.api_token
        }
      }).then(response => {
            this.formResponse = response.data;
             this.setTimeout(() => {
          this.addFormSubmitted = false;
          this.success_message = true;
          /*this.newLocation = {
              id: '',
              name: '',
              address1: '',
              address2: '',
              address3: '',
              county_id: '',
              country_id: '',
              postcode: '',
              telephone: '',
              company_id: this.$attrs.company.id,
            };*/
        });  
            this.setTimeout(() => {
                     this.addPanel = false;
            });
      }).catch(error => {
            this.error_reponse = error.response.data.errors;
            this.error_message = true;
            this.setTimeout(() => {
            this.addFormSubmitted = false;
          });
      });
    },
    updateExistingUser(){
        axios.post('/api/company/user/'+this.updateUser.id+'/update', this.updateUser).then(response => {
          this.formResponse = response.data;
          this.updateLocation = {
          id: '',
          name: '',
          address1: '',
          address2: '',
          address3: '',
          county_id: '',
          country_id: '',
          postcode: '',
          telephone: '',
          company_id: this.$attrs.company.id,
        };
        setTimeout(function(){
                 self.updatePanel = false;
                }, 300);
        }).catch(error => {
          this.errorResponse = error.data; 
        });
      },
      editUser(user){
        this.updateUser.id = user.id;
        this.updateUser.name = user.name;
        this.updateUser.lastname = user.lastname;
        this.updateUser.jobtitle = user.jobtitle;
        this.updateUser.email = user.email;
        this.updateUser.permissions = user.companies[0].permissions;
        this.updateUser.role_id = user.companies[0].role_id;
        this.updateUser.company_id = user.companies[0].company_id;
        this.updatePanel = true;
      },
      getManager(selectedOption){
        this.newLocation.manager_id = selectedOption.companies[0].id;
        this.updateLocation.manager_id = selectedOption.companies[0].id;
      },
      getDepartment(selectedOption){
        this.newLocation.company_department_id = selectedOption.id;
        this.updateLocation.company_department_id = selectedOption.id;
      },
      updatePermissions(userrole){
        console.log(userrole);
        if(this.addPanel == true){
          var role = this.user_roles.filter(item => item.id == userrole);
          this.newUser.permissions = role[0].default_permissions;
        }else if(this.updatePanel == true){
          var role = this.user_roles.filter(item => item.id == userrole);
          this.updateUser.permissions = role[0].default_permissions;
        }
      },
      getUserRolePermissions(id){
        this.setPermissions = false;
        axios.get('/api/company/user/permissions/company-default/'+id+'', {headers: {
          'Authorization' : 'Bearer '+this.$attrs.user.api_token
        }}).then(response => {
          if(response){
            this.permissions[0].add = response.data.add;
            this.permissions[0].view = response.data.view
            this.permissions[0].edit = response.data.edit;
            this.permissions[0].read = response.data.read;
            this.permissions[0].delete = response.data.delete;

            this.newUser.permissions = this.permissions;
          }
          this.setPermissions = true;
        }).catch(error => {
          this.load_error = error.data;
        });
      },
      permissionsUpdated(userrole){
          var role = this.user_roles.filter(item => item.id == userrole);
          this.updateUser.permissions = role[0].default_permissions;
      },
      filterSelected(id){
        if(this.selectedids.length > 0){
          var exists = this.selectedids.filter(item => item == id);
          if(exists.length > 0){
            return true;
          }else{
            return false
          }
        }else{
          return false;
        }
      },
      checkAll: function(){ 
                 this.isCheckAll = !this.isCheckAll; 
                 this.selectedids = []; 
                 if(this.isCheckAll){ 
                     // Check all 
                     for (var key in this.users.data) {          
                        this.selectedids.push(this.users.data[key].id); 
                     } 
                 } 
              }, 
      updateCheckall: function(){ 
                   if(this.selectedids.length == this.users.data.length){
                      this.isCheckAll = true; 
                   }else{ 
                      this.isCheckAll = false; 
                   } 
      },

  }
};
</script>

<template>
  <Layout v-if="$attrs.validsubscription == 0" :nickname="$attrs.nickname" :user="$attrs.user" >
    <PageHeader :title="title" :items="items" />
    <!--<div class="row mb-3">
      <b-col md="12">
                    <transition name="slide-success" mode="out-in">
                  <b-alert id="success-message" variant="success" :show="success_message"  dismissible @dismissed="success_message = !success_message">
                      <p class="font-size-14">Congratulations, your location has been saved.</p>
                    </b-alert>
                  </transition>
                  </b-col>
          <div class="col-xl-2 col-sm-1">
            <div class="mt-2">
              <b-form-select v-model="limit" :options="limits" @input="productQuery()"></b-form-select>
            </div>
          </div>
          <div class="col-lg-2 d-flex">
            <form class="my-auto">
            <small class="mx-auto my-2 text-center d-block">Results {{users.from}} - {{users.to}} of {{users.total}}</small>
          </form>
          </div>
          <div class="col-lg-2 d-flex">
            <div class="my-auto">
                <div class="subscription-tooltip" v-if="show_subtooltip == true"><a href="javascript:;" @click="show_subtooltip = false"><i class="bx bx-x-circle subtip-close" ></i></a><small>Need more users? <a href="/company/subscriptions">Upgrade your package</a></small></div>
                <small>Allocated {{users.total}} of [sub_quantity] user licences.</small>
                <a href="javascript:;" @click="show_subtooltip = true"><i class="bx bx-info-circle" @click="show_subtooltip = true" ></i></a>
                
              </div>
          </div>
          <div class="col-lg-6 col-sm-12 d-flex">
            <div class="mt-4 mt-sm-0 form-inline ml-auto" >
              <div class="search-box mr-2 ml-auto">
                <div class="position-relative">
                  <input
                    type="text"
                    class="form-control w-100 border-0"
                    placeholder="Search name, reference or description..."
                    v-model="search"
                    @keyup.enter="searchFilter($event)"
                  />
                  <i class="bx bx-search-alt search-icon"></i>
                </div>
              </div>
              <ul class="nav nav-pills product-view-nav pt-2">
                <li class="nav-item">
                  <a class="nav-link" :class="{'active': showFilters == true }" href="javascript: void(0);" @click="showFilters = !showFilters">
                    <i class="bx bx-filter-alt"></i>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" :class="{'active': view == 'grid' }" href="javascript: void(0);" @click="view = 'grid'">
                    <i class="bx bx-grid-alt"></i>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" :class="{'active': view == 'list' }" href="javascript: void(0);" @click="view = 'list'">
                    <i class="bx bx-list-ul"></i>
                  </a>
                </li>
                <li class="nav-item">
                 <b-dropdown text="Bulk Actions" variant="primary" class="rounded-pill">
                  <b-dropdown-item href="javascript: void(0);">Action</b-dropdown-item>
                  <b-dropdown-item href="javascript: void(0);">Another action</b-dropdown-item>
                  <b-dropdown-item href="javascript: void(0);">Something else here</b-dropdown-item>
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-item href="javascript: void(0);">Separated link</b-dropdown-item>
                </b-dropdown>
                </li>
                <li class="nav-item">
                 <a v-if="users.total < $attrs.company.subscriptions[0].quantity"
                    class="btn btn-success btn-rounded mb-2 mr-2 text-white"
                    @click="addPanel = !addPanel"
                  >
                    <i class="mdi mdi-plus mr-1"></i> New
                  </a>
                  <a v-else href="/company/subscriptions"  class="btn btn-info btn-rounded mb-2 mr-2 text-white" >
                    Upgrade Subscription
                  </a>
                </li>
              </ul>
              <div class="text-sm-right">
                  
                </div>
            </div>
          </div>
        </div>-->
         <div class="row mb-3">
          <div class="col-xl-1 col-sm-6">
            <div class="mt-2">
              <b-form-select class="form-control" v-model="limit" :options="limits" @input="productQuery()"></b-form-select>
            </div>
          </div>
         
          <div class="col-lg-2 col-sm-6 d-flex">
          <form class="my-auto">
            <small class="mx-auto my-2 text-center d-block">Results {{users.from}} - {{users.to}} of {{users.total}}</small>
          </form>
          <div class="my-auto">
                <div class="subscription-tooltip" v-if="show_subtooltip == true"><a href="javascript:;" @click="show_subtooltip = false"><i class="bx bx-x-circle subtip-close" ></i></a><small>Need more users? <a href="/company/subscriptions">Upgrade your package</a></small></div>
                <small>Allocated {{users.total}} of [sub_quantity] user licences.</small>
                <a href="javascript:;" @click="show_subtooltip = true"><i class="bx bx-info-circle" @click="show_subtooltip = true" ></i></a>
                
              </div>
        </div>
        <div class="col-lg-5 col-sm-6 d-flex">
            <div class="mt-sm-0 form-inline w-100" >
              <div class="search-box mr-2 ml-auto">
                <div class="position-relative">
                  <input
                    type="text"
                    class="form-control w-100 border-0"
                    placeholder="Search name, reference or description..."
                    v-model="search"
                    @keyup.enter="searchFilter($event)"
                  />
                  <i class="bx bx-search-alt search-icon"></i>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-sm-6 d-flex">
            <div class="d-flex ml-auto">
              <ul class="nav nav-pills product-view-nav">
                <li class="nav-item">
                  <a class="nav-link" :class="{'active': showFilters == true }" href="javascript: void(0);" @click="showFilters = !showFilters">
                    <i class="bx bx-filter-alt"></i>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" :class="{'active': view == 'grid' }" href="javascript: void(0);" @click="view = 'grid'">
                    <i class="bx bx-grid-alt"></i>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" :class="{'active': view == 'list' }" href="javascript: void(0);" @click="view = 'list'">
                    <i class="bx bx-list-ul"></i>
                  </a>
                </li>
                <li class="nav-item">
                 <b-dropdown text="Bulk Actions" variant="primary" class="rounded">
                   <b-dropdown-text class="list-group list-group-flush" text-class="list-group-item mb-0" @mouseenter="showActiveOn = 'active'" @mouseleave="showActiveOn = -1">

                      <i class="bx bx-check-circle font-size-14 text-success">
                        </i> Set Active On:
                   <transition name="fade-right">
                        <b-list-group v-if="showActiveOn == 'active'" flush class="pages-submenu hide" style="position: absolute; left: -100%; top: 0; width: 100%" >
                           <b-list-group-item href="javascript: void(0);" @click="setActive(true, 'active')">Shops</b-list-group-item>
                            <b-list-group-item href="javascript: void(0);" @click="setActive(true, 'public')">Public Market</b-list-group-item>
                            <b-list-group-item href="javascript: void(0);" @click="setActive(true, 'trade')">Trade Market</b-list-group-item>
                        </b-list-group>
                      </transition>
                    </b-dropdown-text>
                    <b-dropdown-text class="list-group list-group-flush" text-class="list-group-item mb-0" @mouseenter="showActiveOn = 'inactive'" @mouseleave="showActiveOn = -1">

                      <i class="bx bx-x-circle font-size-14 text-danger">
                        </i> Set Inactive On:
                   <transition name="fade-right">
                        <b-list-group v-if="showActiveOn == 'inactive'" flush class="pages-submenu hide" style="position: absolute; left: -100%; top: 0; width: 100%" >
                           <b-list-group-item href="javascript: void(0);" @click="setActive(false, 'active')">Shops</b-list-group-item>
                            <b-list-group-item href="javascript: void(0);"  @click="setActive(false, 'public')">Public Market</b-list-group-item>
                            <b-list-group-item href="javascript: void(0);"  @click="setActive(false, 'trade')">Trade Market</b-list-group-item>
                        </b-list-group>
                      </transition>
                    </b-dropdown-text>
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-item href="javascript: void(0);" disabled><i class="bx bx-copy-alt font-size-14"></i> Duplicate</b-dropdown-item>
                  <b-dropdown-item href="javascript: void(0);" v-b-modal.move-modal @click="all_categories = '', categories_type = 'company', getAllCategories(), moveMode = 'bulk'"><i class="bx bx-move"></i> Move Category</b-dropdown-item>
                  <b-dropdown-item href="javascript: void(0);" v-b-modal.print-label @click="setPrintMode()"><i class="bx bx-printer font-size-14"></i> Print Labels</b-dropdown-item>
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-item href="javascript: void(0);" v-b-modal.delete-modal @click="deleteMode = 'bulk'"><i class="bx bx-trash-alt font-size-14"></i> Delete</b-dropdown-item>
                </b-dropdown>
                </li>
                <li class="nav-item">
                 <a
                    class="btn btn-success btn-rounded mb-2 mr-2 text-white"
                    href="javascript:;"
                    @click="addPanel = !addPanel"
                  >
                    <i class="mdi mdi-plus mr-1"></i> New
                  </a>
                  <!--<a v-if="users.total < $attrs.company.subscriptions[0].quantity"
                    class="btn btn-success btn-rounded mb-2 mr-2 text-white"
                    @click="addPanel = !addPanel"
                  >
                    <i class="mdi mdi-plus mr-1"></i> New
                  </a>
                  <a v-else href="/company/subscriptions"  class="btn btn-info btn-rounded mb-2 mr-2 text-white" >
                    Upgrade Subscription
                  </a>-->
                </li>
              </ul>
              <div class="text-sm-right">
                  
                </div>
          </div>
        </div>
      </div>
    <div class="row" v-if="view == 'list'">
      <div class="col-lg-12">
        <div class="card">
          <div class="">
            <b-overlay
                        id="overlay"
                        :show="loadingResource"
                        variant="white"
                        opacity="0.85"
                        blur="1px"
                        rounded="md"
                        class="w-100"
                      >
            <div class="table-responsive">
              <table class="table table-centered table-nowrap table-hover">
                <thead>
                  <tr>
                    <th scope="col" style="width: 100px; text-align: center;"><input type="checkbox" class="align-middle" @click='checkAll()' v-model="isCheckAll" /></th>
                    <th scope="col" colspan="1">Name</th>
                    <th scope="col" colspan="1">Email</th>
                    <th scope="col" colspan="1">Role</th>
                    <th scope="col" colspan="1">Teams</th>
                    <th scope="col" colspan="1">Departments</th>
                    <th scope="col" colspan="1" width="200px">Shops</th>
                    <th scope="col" colspan="2" width="25%">Sales</th>
                    <th scope="col" colspan="1">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="user in users.data" :key="user.id">
                    <td colspan="1" valign="middle">

                      <div class="text-center" v-if="user.avatar !== null">
                        <b-form-checkbox class="select-row-btn" v-model="selectedids" :value="user.companies[0].id" button-variant="primary" button>

                          <i v-if="filterSelected(user.companies[0].id)" class="bx bx-check"></i><img v-else :src="user.avatar" class="rounded-circle avatar-xs" style="width: 40px; height: 40px"></b-form-checkbox>
                      </div>

                       <div class="text-center" v-else >
                        <b-form-checkbox class="select-row-btn" v-model="selectedids" :value="user.companies[0].id" button-variant="light" @input="updateSelected" button>

                          <i v-if="filterSelected(user.companies[0].id)" class="bx bx-check"></i><span v-else class="avatar-title rounded-circle" v-else>{{(user.name).slice(0,1)}}</span></b-form-checkbox>
                      </div>
                      
                    </td>
                    <td colspan="1" valign="middle">
                      <h5 class="font-size-14 mb-1">
                        <a :href="'/company/user/'+user.companies[0].uid" class="text-dark">{{user.name}} {{user.lastname}}</a>
                        <small>{{user.jobtitle}}</small>
                      </h5>
                    </td>
                    <td colspan="1" valign="middle">
                     {{user.email}}
                     </td>
                     
                    <td colspan="1" valign="middle"><a v-if="user.companies[0].userrole !== ''" href="javascript;:" v-b-tooltip.hover :title="user.companies[0].userrole.name" >
                        <div class="avatar-xs d-inline-block mr-1"><span class="avatar-title rounded-circle bg-success">{{(user.companies[0].userrole.name).slice(0,1)}}</span></div>
                      </a>
                    <td colspan="1" valign="middle">
                      <a v-if="user.companies[0].manages_teams.length > 0" v-for="team in user.companies[0].manages_teams" :href="'/company/team/'+team.uid" v-b-tooltip.hover :title="team.name+' manager'" >
                        <div class="avatar-xs d-inline-block mr-1"><span class="avatar-title rounded-circle bg-success">{{(team.name).slice(0,1)}}</span></div>
                      </a>
                      <div v-if="user.companies[0].teams.length > 0">
                      <a  v-for="team in user.companies[0].teams" v-if="team.team !== null" :href="'/company/team/'+team.team.uid" v-b-tooltip.hover :title="team.team.name" ><div class="avatar-xs d-inline-block mr-1"><span class="avatar-title rounded-circle bg-warning">{{(team.team.name).slice(0,1)}}</span></div></a>
                    </div>
                    </td>
                    <td colspan="1" valign="middle">
                      <a v-for="department in user.companies[0].manages_departments" :href="'/company/department/'+department.uid" v-b-tooltip.hover :title="department.name+' manager'" >
                        <div class="avatar-xs d-inline-block mr-1"><span class="avatar-title rounded-circle bg-success">{{(department.name).slice(0,1)}}</span></div>
                      </a>
                      <a v-for="department in user.companies[0].departments" :href="'/company/department/'+department.department.uid" v-b-tooltip.hover :title="'Member of '+department.department.name" ><div class="avatar-xs d-inline-block mr-1"><span class="avatar-title rounded-circle">{{(department.department.name).slice(0,1)}}</span></div></a></td>
                    <td colspan="1" class="badge-group" valign="middle">
                      <a v-for="listing in user.companies[0].manages_listings" :href="'/company/shop/'+listing.uid"  >
                        <div v-b-tooltip.hover.right :title="listing.name+' manager'" class="avatar-xs d-inline-block mr-1"><span class="avatar-title border border-white rounded-circle bg-success">{{(listing.name).slice(0,1)}}</span></div>
                      </a>
                      <a v-for="listing in user.companies[0].listings" :href="'/company/listing/'+listing.listing.uid"  ><div v-b-tooltip.hover.right :title="'Member of '+listing.listing.name" class="avatar-xs d-inline-block mr-1"><span class="avatar-title border border-white rounded-circle">{{(listing.listing.name).slice(0,1)}}</span></div></a></td>
                    <td colspan="2" valign="middle">
                        <div class="d-flex">
                                      <div class="w-50 mr-4">
                                        <p class="text-muted mb-1">Sold</p>
                                        <b-progress :max="user.companies[0].sales_count" :value="user.companies[0].sales_count" >
                                        </b-progress>
                                        <h5 class="mb-0 mt-1">{{user.companies[0].sales_count}}</h5>
                                      </div>
                                      <div class="w-50 text-right">
                                        
                                        <p class="text-muted mb-1">Sales Value</p>
                                        <h5 class="mb-0" v-if="user.companies[0].sales_sum_total_order_inc !== null">£{{parseFloat(user.companies[0].sales_sum_total_order_inc).toFixed(2)}}</h5> 
                                        <h5 class="mb-0" v-else>£0.00</h5>
                                      </div>
                        </div>
                    </td>
                    <td colspan="1" valign="middle">
                      

                       <ul class="list-inline font-size-20 contact-links mb-0">
                        <li class="list-inline-item px-2"><a v-b-tooltip.hover title="Edit" placement="left"  @click="editUser(user)"><i class="bx bx-edit-alt"></i>
                          </a>
                        </li>
                          <li class="list-inline-item px-1">
                          <a v-b-tooltip.hover title="View" :href="'/company/user/'+user.companies[0].uid">
                            <i class="mdi mdi-eye-outline"></i>
                          </a>
                        </li>
                        <li class="list-inline-item px-2"><a v-b-tooltip.hover title="Delete" placement="left"  @click="selectedids = [], selectedids.push(user.companies[0].id),  deleteMode = 'single'" v-b-modal.delete-modal><i class="bx bx-trash-alt"></i>
                          </a>
                        </li>
                        <li class="list-inline-item px-2">
                           <b-dropdown v-b-tooltip.hover title="More Options" placement="left"dropleft class="more-options-dropdown"  menu-class="dropdown-menu-right">
                        <template v-slot:button-content>
                           <i class="bx bx-dots-horizontal-rounded"></i>
                        </template>
                        <b-dropdown-item href="javascript: void(0);"  @click="selectedids = [], selectedids.push(list.id), bulkDuplicateResource()">Duplicate</b-dropdown-item>
                        <b-dropdown-item href="javascript: void(0);" v-b-modal.move-modal @click="editcategory = JSON.parse(JSON.stringify(list)), moveMode = 'single'">Move Category</b-dropdown-item>
                      </b-dropdown>
                        </li>
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </b-overlay>
            <div class="row">
              <div class="col-lg-12">
                 <b-pagination
              class="justify-content-center"
              pills
              v-model="currentPage"
              :total-rows="users.total"
              :per-page="users.per_page"
              aria-controls="my-table"
              @input="searchResource"
            ></b-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="view == 'grid'">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
            <div v-for="location in users.data" :key="location.id" class="col-xl-3 col-sm-6">
        <div class="card text-center">
          <div class="card-body">
            <div class="avatar-sm mx-auto mb-4">
              <span
                class="avatar-title rounded-circle bg-soft-primary text-primary font-size-16"
              >{{(location.name).slice(0,1)}}</span>
            </div>
            <h5 class="font-size-15">
              <a href="javascript: void(0);" class="text-dark">{{location.name}}</a>
            </h5>
            <p class="text-muted">{{location.description}}</p>
            <div v-if="location.manager.user.avatar" class="btn btn-primary rounded-pill">
                        <img class="rounded-circle avatar-xs" :src="'/'+location.manager.user.avatar" alt />
                        <span>{{location.manager.user.name}} {{location.manager.user.lastname}} - {{location.manager.user.jobtitle}}</span>
                     </div>
            <div class="mt-3">
              <strong>Projects</strong>
             <h5 class="text-primary">{{location.projects_count}}</h5>
            </div>
          </div>
          <div class="card-footer bg-transparent border-top">
            <div class="contact-links d-flex font-size-20">
              <div class="flex-fill">
                <a v-b-tooltip.hover title="Edit">
                            <i class="bx bx-edit"></i>
                </a>
              </div>
              <div class="flex-fill">
                  <a v-b-tooltip.hover title="View">
                            <i class="mdi mdi-eye-outline"></i>
                  </a>
              </div>
              <div class="flex-fill">
                 <a v-b-tooltip.hover title="Staff">
                            <i class="bx bx-group"></i>
                  </a>
              </div>
              <div class="flex-fill">
                          <a v-b-tooltip.hover title="Reports">
                            <i class="bx bx-line-chart"></i>
                          </a>
              </div>
              <div class="flex-fill">
                          <a v-b-tooltip.hover title="Delete">
                            <i class="bx bx-trash-alt"></i>
                          </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
            <div class="row">
              <div class="col-lg-12">
                 <b-pagination
                    class="justify-content-center"
                    pills
                    v-model="currentPage"
                    :total-rows="users.total"
                    :per-page="users.per_page"
                    aria-controls="my-table"
                    @input="searchResource"
                  ></b-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
     <b-modal id="delete-modal" size="md" hide-footer hide-header centered>
        <b-card class="border-radius-1em border shadow-none">
          <h4>Are you sure?</h4>
          <p v-if="deleteMode == 'bulk'">You are about to delete {{selectedids.length}} products. Are you sure you want to do this? This action is not reversable</p>
           <p v-if="deleteMode == 'single'">You are about to delete this user. Are you sure you want to do this? This action is not reversable</p>

           <div class="d-flex">
            <a class="btn btn-danger" v-if="deleteMode == 'bulk'" @click="bulkDestroyResource">
          Resourcete
            </a>
             <a class="btn btn-danger"  v-if="deleteMode == 'single'" @click="destroyResource">
              Delete
            </a>
            <a class="btn btn-success" @click="$bvModal.hide('delete-modal')">
              Cancel
            </a>
          </div>
        </b-card>
      </b-modal>
    <SideSlidePanel :editPanel="addPanel" :panelClass="'edit-sidepanel'" @close-panel="addPanel = false" >
      <b-row>
              <b-col md="12">
                <div class="m-4 px-2">
                <h4>Add New User</h4>
                 <transition name="slide-error" mode="out-in">
                    <b-alert id="errors" variant="danger" :show="error_message"  dismissible @dismissed="error_message = !error_message">
                      <p class="font-size-14">Oops! Please correct the following errors in your submission</p>
                      <ul>
                        <li v-for="(error, key) in error_reponse">
                          {{error[0]}}
                        </li>
                      </ul>
                    </b-alert>
                    
                  </transition>
              <b-overlay
                :show="addFormSubmitted"
                variant="white"
                        opacity="0.85"
                        blur="1px"
                        rounded="md"
              >
              <b-row>
                 <b-col md="12" class="mt-5">
                  <i class="float-left bx bx-info-circle text-white font-size-24 bg-primary rounded-circle p-2 mr-2"></i> 
                <h4 class="mb-0">Basic Information</h4>
                <p>Provide the baisc information for your user</p>
                <hr>
              </b-col>
                <b-col md="12">
                  <b-form-group label="Name" class="mb-2">
                    <b-form-input type="text" name="name" v-model="newUser.name"></b-form-input>
                  </b-form-group>
                  <b-form-group label="Last Name" class="mb-2">
                    <b-form-input type="text" name="name" v-model="newUser.lastname"></b-form-input>
                  </b-form-group>
                  <b-form-group label="Job Tile" class="mb-2">
                    <b-form-input type="text" name="name" v-model="newUser.jobtitle"></b-form-input>
                  </b-form-group>
                  <b-form-group label="Email" class="mb-2">
                    <b-form-input type="text" name="name" v-model="newUser.email"></b-form-input>
                  </b-form-group>
                  <b-form-group label="Password" class="mb-2">
                    <b-input-group >
                      <b-form-input type="password" name="name" v-model="newUser.password"></b-form-input>
                       <b-input-group-append>
                      <b-button variant="primary" @click="generatePassword('new')">Generate Password</b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                  <b-form-group label="Active">
                  <div
                        class="form-check form-switch form-switch-sm mb-0 mx-auto p-0 mt-auto d-flex"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input m-0 my-auto" 
                          type="checkbox"
                          id="SwitchCheckSizesm"
                         
                          v-model="newUser.active"
                        
                          name="active"
                        />
                    </div>
                  </b-form-group>
                </b-col>
                <b-col md="12" class="mt-5">
                  <i class="float-left bx bx-lock-alt text-white font-size-24 bg-info rounded-circle p-2 mr-2"></i> 
                <h4 class="mb-0">User Permissions</h4>
                <p class="d-flex">Set the permissions for this user.</p>
                <hr>
              </b-col>
                <b-col md="12">
                  <b-form-group label="User Role" class="mb-2">
                    <b-form-select class="form-control" name="country" v-model="newUser.role_id" :options="user_roles"  value-field="id"  text-field="name" @input="getUserRolePermissions(newUser.role_id), permission_resources = menuItems"></b-form-select>
                  </b-form-group>
                  <b-form-group label="Additional Permissions">
                  <b-card class="border-radius-1em border shadow-none" v-if="setPermissions == true">
                  <UserPermissions :permission_resources="permission_resources" :permissions="newUser.permissions"></UserPermissions>
                  </b-card>
                  <div v-else>
                    <b-card class="border-radius-1em border shadow-none mb-0" body-class="d-flex flex-column justify-content-center">
                      <div class="my-auto text-center">
                      <i class="bx bx-filter text-muted font-size-42 mb-2"></i>
                      <h5>Advanced Permission Controls</h5>
                      <p>Once you select a role for your user you can update the individual permissions for your user</p>
                      </div>
                    </b-card>
                  </div>
                </b-form-group>
                
                </b-col>
                 <b-col md="12" class="mt-5">
                  <i class="float-left bx bx-laptop text-white font-size-24 bg-warning rounded-circle p-2 mr-2"></i> 
                <h4 class="mb-0">Point of Sale</h4>
                <p class="d-flex">Settings for user point of sale.</p>
                <hr>
              </b-col>
               <b-col md="12">
                 <b-card class="border-radius-1em border shadow-none" >
                 <b-form-group label="Make Point of Sale User">
                  <p>By making your user a point of sale user they will have the ability to sign in to your point of sale using either their ePOS User ID, QR Code, Barcode or NFC Cards. Once you save your user you will have the ability to produce login details for your users ePOS Access</p>
                   <div
                        class="form-check form-switch form-switch-sm mb-0 mx-auto p-0 mt-auto d-flex"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input m-0 my-auto" 
                          type="checkbox"
                          id="SwitchCheckSizesm"
                         
                          v-model="newUser.pos_user"
                        
                          name="active"
                        />
                    </div>
                    <div v-if="newUser.pos_user == true">
                      
                    </div>
                  </b-form-group>
                </b-card>
               </b-col>
              <b-col md="12">
                  <a href="javascript:;" class="btn btn-success" @click="addNewUser">Save</a>
              </b-col>
              </b-row>
            </b-overlay>
                </div>
              </b-col>
      </b-row>            
    </SideSlidePanel>
    <SideSlidePanel :editPanel="updatePanel" :panelClass="'edit-sidepanel'" @close-panel="updatePanel = false">
      <b-row>
              <b-col md="12">
                <div class="m-4 px-2" v-if="updatePanel == true">
                <h4>Update User</h4>
                <transition name="slide-error" mode="out-in">
                    <b-alert id="errors" variant="danger" :show="error_message"  dismissible @dismissed="error_message = !error_message">
                      <p class="font-size-14">Oops! Please correct the following errors in your submission</p>
                      <ul>
                        <li v-for="(error, key) in error_reponse">
                          {{error[0]}}
                        </li>
                      </ul>
                    </b-alert>
                    
                  </transition>
                <b-overlay
                :show="updateFormSubmitted"
                variant="white"
                        opacity="0.85"
                        blur="1px"
                        rounded="md"
              >
              <b-row>
                <b-col md="12">
                  <b-form-group label="Name">
                    <b-form-input type="text" name="name" v-model="updateUser.name"></b-form-input>
                  </b-form-group>
                  <b-form-group label="Last Name">
                    <b-form-input type="text" name="name" v-model="updateUser.lastname"></b-form-input>
                  </b-form-group>
                  <b-form-group label="Job Tile">
                    <b-form-input type="text" name="name" v-model="updateUser.jobtitle"></b-form-input>
                  </b-form-group>
                  <b-form-group label="Email">
                    <b-form-input type="text" name="name" v-model="updateUser.email"></b-form-input>
                  </b-form-group>
                  <b-form-group label="Password">
                    <b-input-group >
                      <b-form-input type="password" name="name" v-model="updateUser.password"></b-form-input>
                       <b-input-group-append>
                      <b-button variant="primary" @click="generatePassword('update')">Generate Password</b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                  <b-form-group label="User Role">
                    <b-form-select name="country" v-model="updateUser.role_id" :options="user_roles"  value-field="id"  text-field="name" @change="permissionsUpdated"></b-form-select>
                  </b-form-group>
                  <b-form-group label="Permissions" v-if="updateUser.permissions !== '[]'">
                  <div class="table-responsive" v-if="setPermissions == true">
                   <UserPermissions :permission_resources="''" :permissions="permissions"></UserPermissions>
                  </div>
                </b-form-group>
                  <a href="javascript:;" class="btn btn-success" @click="updateExistingUser">Save</a>
                </b-col>
              </b-row>
            </b-overlay>
                </div>
              </b-col>
      </b-row>            
    </SideSlidePanel>

    <SideSlidePanel :editPanel="viewPanel" :panelClass="'edit-sidepanel'" @close-panel="viewPanel = false, updateManagerBtn = false">
      <b-row>
              <b-col md="12">
                <div class="m-4 px-2 row" v-if="viewPanel == true">

                  <b-col md="12">
                <h4>{{updateLocation.name}}</h4>
                </b-col>
                 <b-col md="12">
                  <b-card>
                    {{updateLocation.address1}}<br>
                    {{updateLocation.address2}}<br>
                    {{updateLocation.address3}}<br>
                    {{updateLocation.county.name}}<br>
                    {{updateLocation.country.name}}<br>
                    {{updateLocation.postcode}}<br><br>

                    <a :href="'mailto:'+updateLocation.email">{{updateLocation.email}}</a><br>
                    <a :href="'tel:'+updateLocation.telephone">{{updateLocation.telephone}}</a><br>
                  </b-card>
                  </b-col>
                </div>
              </b-col>
      </b-row>            
    </SideSlidePanel>
  </Layout>
    <Layout v-else :nickname="$attrs.nickname" :user="$attrs.user" >
    <PageHeader :title="title" :items="items" />
    <Subscription :company="this.$attrs.company" :user="this.$attrs.user" />
  </Layout>
</template>

<style type="text/css" scoped>
  .badge-group a{
    margin-left: -15px;
  }
  .badge-group{
    flex-wrap: wrap;
    padding-left: 30px;
    display: flex;
    height: 100%;
    min-height: 85px;
  }

</style>