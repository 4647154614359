<script type="text/javascript">
	import DefaultContentCategoryCard from "./DefaultContent-CategoryCard";
	export default{
		data(){
			return {
				hoverIndex: -1,
				hoverLink: -1,
				categories: []
			}
		},
		props: {
			company: {
				type: Object
			},
			resource: {
				type: String,
				default: () => 'categories'
			}
		},
		components: {
			DefaultContentCategoryCard
		},
		created(){
			this.getResource(this.resource);
		},
		methods: {
			getResource(resource){
				axios.get('/website-api/widget/'+resource).then(response => {
					this.categories = response.data;
				}).catch(error => {
					this.categories = [];
					console.log(error);
				});
			}
		},
		  watch: {
        $route() {
        	this.$nextTick(() => {
        		 //this.loadingPage = true;
        		 this.getResource(this.resource);

        		});
        	}
        }
	}

</script>
<template>
	<b-container fluid class="py-4 mb-4 default-content categories-collections">
		<b-row>
			<b-col md="12">
				<h1>Product {{resource}}</h1>
					<p>Know what you're looking for? Select from {{company.name}}'s jewellery types below.</p>
			</b-col>
			<b-col md="3" v-for="(category, index) in categories.data">
				<DefaultContentCategoryCard :category="category" :company="company"></DefaultContentCategoryCard>
			</b-col>
			<b-col md="12">

				<b-pagination v-if="categories.length < 0" v-model="categories.current_page" :total-rows="categories.total" :per-page="categories.per_page" align="right"></b-pagination>
			</b-col>
		</b-row>
	</b-container>
</template>