<script type="text/javascript">
	export default{
		data(){
			return {
				storageurl: 'https://'+this.$attrs.company.nickname+'.s3.eu-west-2.amazonaws.com',
				hoverIndex: -1,
				hoverLink: -1,
				categories: []
			}
		},
		props: {
			product: {
				type: Object
			},
			index: {
				type: Number
			}
		}, 
		methods: {
			addTax(){
        		if(this.product.tax_rule !== null){
        			//console.log(product);
        			var percentage = (parseFloat(this.product.tax_rule.percentage) / 100) + 1;
        			return (parseFloat(this.product.sales_price) * parseFloat(percentage)).toFixed(2);
         		}else{
         			return parseFloat(this.product.sales_price).toFixed(2);
         		}
        	},
		}
	}
</script>
<template>
	<router-link :to="'/product/'+product.url_rewrite">
					<b-card id="product-card" no-body class="border shadow-none border-radius-1em" @mouseenter="hoverIndex = index" @mouseleave="hoverIndex = -1" >
						<!--<transition name="fade">
						<b-card-img v-if="product.cover_image" :src="storageurl+product.cover_image.src.replaceAll('//', '/')" />
						
						</transition>-->
						<transition name="fade">

						<b-card-img-lazy v-if="(product.cover_image) && ('gallery' in product.cover_image)"class="rounded-0" :src="storageurl+product.cover_image.gallery.replaceAll('//', '/')" ></b-card-img-lazy>
                         <b-card-img-lazy v-else-if="(product.cover_image) && ('thumb' in product.cover_image)"class="rounded-0" :src="storageurl+product.cover_image.thumb.replaceAll('//', '/')" ></b-card-img-lazy>
                         <b-card-img-lazy v-else-if="(product.cover_image) && ('src' in product.cover_image)"class="rounded-0" :src="storageurl+product.cover_image.src.replaceAll('//', '/')" ></b-card-img-lazy>
		                 <b-card-img-lazy v-else :src="'/images/product-placeholder.png'" alt="Image" class="rounded-0"></b-card-img-lazy>
						<b-card-img v-else :src="'/images/product-placeholder.png'" />
						</transition>
						<b-card-text class="p-4 peach-border-top-5 d-flex flex-column">
							<h3 class="font-size-18 my-auto py-2">{{product.name}}</h3>
							<p class="text-dark mb-0 paragraph-truncate-line2" v-html="product.short_description.replace(/<(.|\n)*?>/g, '')"></p>
							<div class="d-flex">
							<span class="badge badge-light text-dark bg-light font-size-14 my-2">
								{{addTax()}}
							</span>
						<transition name="fade-right">
							<router-link v-if="hoverIndex == index" class="border-bottom border-primary text-uppercase font-size-12 ml-auto text-dark my-auto py-2" :to="'/product/'+product.url_rewrite">
								See Product <i class="bx bx-chevron-right"></i>
							</router-link>
						</transition>
							</div>
						</b-card-text>
					</b-card>
				</router-link>
</template>