<script type="text/javascript">
	import VueSlider from 'vue-slider-component'
	import ImageBlocks from "../components/widgets/blocks/ImageBlocks";
	import PageHeader from "../components/widgets/ecommerce/public/PageHeader";
	import MiniCardCarousel from "../components/widgets/carousel/MiniCardCarousel";
	import Swiper from "../components/widgets/carousel/Swiper";
	import PublicMarketplaceProductList from "../components/widgets/PublicMarketplaceProductList";
	import FilterPublicProductBrands from "../components/widgets/filters/public/FilterPublicProductBrands";
	import FilterPublicProductCategories from "../components/widgets/filters/public/FilterPublicProductCategories";
	import { EventBus } from '../public-app'
	export default{
		components: { Swiper, ImageBlocks, MiniCardCarousel, PublicMarketplaceProductList, VueSlider, FilterPublicProductBrands, FilterPublicProductCategories, PageHeader },
		data(){
			return{
				title: '',
				loadingFilters: false,
				breadcrumbs: [],
				bread: {
					title: '',
					link: '',
					id: 1,
				},
				categorydata: '',
				categoriesdata: '',
				data: '',
				defaultcategory: '',
				productConditions: [
		        'New',
		        'Bespoke',
		        'Used',
		        'Antique',
		        'Refurbished',
		        'Custom',
      	],
      	productMetals: [
      			'Gold',
      			'Rose Gold',
      			'White Gold',
      			'Silver',
      			'Platinum',
      			'Palladium',
      	],
      	productCarats: [
      			'9',
      			'18',
      			'24',
      	],
      	productGemstones: [
      			'Aquamarine',
      			'Diamond',
      			'Emerald',
      			'Garnet',
      			'Opal',
      			'Pearl',
      			'Ruby',
      			'Sapphire',
      			'Tsavorite',
      			'Topaz',
      			'Tanzonite',
      	],
      	condition: '',
      	attributes: {
      		gemstones: [],
      		metals: [], 
      	},
      	keywordslist: [],
				productsLoading: true,
				showFilters: true,
				showFiltersSm: false,
		        sliderPrice: 800,
		        priceRange: [0,250000],
		        brands: [],
		        collections: [],
		        categories: [],
		        sortby: 'created_at',
		        order: 'desc',
		        search: '',
		        view: 'grid',
		        public_active: 1,
		        trade_active: '',
		        active: '',
		        expanded: 0,
		        currentPage: 1,
		        query: '',
		        limit: 25,
				limits: [
				        {value: 25, text: "25"},
				        {value: 50, text: "50"},
				        {value: 100, text: "100"},
				      ],
			}
		},
		mounted() {
			
			this.createQueryString();
			this.loadCategoryInfo();
			//this.loadCategory();
			//this.loadAllCategories();
			this.breadcrumbs = this.$route.path.split('/');
				if(this.breadcrumbs.length > 0){
				this.title = this.breadcrumbs[this.breadcrumbs.length - 1];
				this.breadcrumbs[0] = "Home";
				}
			
		},
		created(){
			window.scrollTo(0,0);
			
			this.loadAllCategories();
			let self = this;
			EventBus.$on('category-select', function(id){
				self.filterSelectedCategories(id);
		    });
			EventBus.$on('categories-cleared', function(id){
				self.categories = [];
				//self.loadCategory();
		    });
			
		},
		computed: {
			switchFilters(){
				if(window.innerWidth < 991){
						this.showFilters = false;
				}
				return window.innerWidth;
			}
		},
		methods: {
			loadCategory(){
				//this.createQueryString();
				//this.createQueryObject();
				this.productsLoading = true;
				if(this.condition == false){
					this.condition = '';
				}
				//console.log(this.$route.path);
				axios.get('/customer-api/product'+this.$route.path+'/products', {params: {
					page: this.currentPage,
					limit: this.limit,
					sortby: this.sortby,
					order: this.order,
					search: this.search,
					public_active: this.public_active,
					active: this.active,
					brands: this.brands,
					collections: this.collections,
					defaultcategory: this.defaultcategory,
					categories: this.categories,
					pricerange: this.priceRange,
					keywordslist: this.keywordslist,
					condition: this.condition,
				}}).then(response => {
					this.data = response.data;
				}).catch(error => {
					console.log(error);
					this.data = {products:{current_page:1,data: [],per_page:25,prev_page_url:null,to:0,total:0}};
					this.productsLoading = false;
				});
				var self = this;
                setTimeout(function(){
	                self.productsLoading = false;                
                }, 500);
			},
			loadProducts(){
				this.createQueryString();
				this.productsLoading = true;
				this.defaultcategory = this.categorydata.id;
				//console.log(this.$route.path);
				axios.get('/customer-api/products'+this.query).then(response => {
					this.data = response.data;
				}).catch(error => {
					console.log(error);
				});
				var self = this;
                setTimeout(function(){
	                self.productsLoading = false;                
                }, 500);
			},
			loadCategoryInfo(){
				axios.get('/customer-api/product'+this.$route.path).then(response => {
					console.log(response);
					var responsedata = response;
					this.categorydata = response.data;
					if(responsedata.status == 200){
						this.loadCategory();
					}
				}).catch(error => {
					console.log(error);
				});
			},
			loadAllCategories(){
				axios.get('/customer-api/product/categories?view=childs').then(response => {
					this.categoriesdata = response.data;
				}).catch(error => {
					console.log(error);
				});
			},
			filterBrands(value){
		      this.brands = value;
		      //this.createQueryString();
		      this.loadCategory();
		    },
		    reloadFilters: function(){
		    	this.loadingFilters = true; 
		    	var self = this;
                setTimeout(function(){
	                self.loadingFilters = false;                
                }, 500);
		    },
			createQueryString(){
				this.query = '?page='+this.currentPage+'&limit='+this.limit+'&sortby='+this.sortby+'&order='+this.order+'&search='+this.search+'&public_active='+this.public_active+'&active='+this.active+'&brands='+this.brands+'&collections='+this.collections+'&defaultcategory='+this.defaultcategory+'&categories='+this.categories+'&pricerange='+this.priceRange+'&keywordslist='+this.keywordslist+'&condition='+this.condition;
			},
			filterSelectedCategories(id){
		        
		          var exists = this.categories.findIndex(item => item == id);
		          console.log(exists);
		          if(exists == -1){
		            this.categories.push(id);
		          }else{
								this.categories.splice(exists, 1);
		          }
		          //this.createQueryString();
		      		this.loadCategory();
		      },
		},
		watch: {
        $route() {
        	this.$nextTick(() => {
        		this.productsLoading = true;
        		//this.reloadFilters();
        		//this.loadCategory();
        		window.scrollTo(0,0);
        		let self = this;
        		setTimeout(function(){
	                self.productsLoading = false;                
                }, 800);
        	 });
            //this.$nextTick();
          			
         },
         '$route.params.id'(){
         	this.$nextTick(() => {
         	//alert('test');

         		this.loadCategoryInfo();
	         	this.categories = [];
	         	this.$refs['categoryfilter'].clearCategories();
	         	this.$refs['categoryfilter'].$refs['childcategories'].clearCategories();
	         	this.$refs['categoryfiltersm'].clearCategories();
	         	this.$refs['categoryfiltersm'].$refs['childcategories'].clearCategories();
	         });
      	 }
    	},
	}

</script>

<template>
<div>
		<PageHeader :data="categorydata"/>


		<section >
			<b-container fluid>
				
				 <b-row class="pb-4">
				 	<!--<b-col md="12" class="d-flex flex-row align-items-start mx-auto">
				 	<div  v-for="(bread, index) in breadcrumbs" class="d-flex flex-row">
		    		<span class="badge badge-light text-dark font-size-12 text-capitalize my-auto">{{bread}}</span>
		    		 <i class="bx bx-chevron-right my-auto" v-if="index !== (breadcrumbs.length -1)"></i>
		    		</div>

		    	</b-col>-->
		    <transition name="fade-filtersleft">
		     	<b-col lg="3" v-if="showFilters" class="d-lg-block d-none">
          <div class="col-lg-12 pt-1 mt-4">
        <div>
          <div class="card p-4 rounded-4 shadow-sm border-radius-1em mt-4">
            <!--<h4 class="card-title mb-4">Filter</h4>-->
            <div>
               <div class="mb-3">
              <h5 class="mb-3">Price</h5>
              <vue-slider v-model="priceRange" :min="0" :max="100 + priceRange[1]"  @drag-end="loadCategory()" ></vue-slider>
              <b-form-group label="Min Price" class="w-50 d-inline float-left mt-2 mb-3 px-2">
              <b-form-input
                type="number"
                v-model="priceRange[0]"
                @input="loadCategory"></b-form-input>
              </b-form-group>
              <b-form-group label="Max Price" class="w-50 d-inline float-left mt-2 mb-3 px-2">
                 <b-form-input
                 label="Max Price"
                 type="number"
                 v-model="priceRange[1]"
                 @input="loadCategory"></b-form-input>
              </b-form-group>
            </div>
                <h5 class="my-3">Jewellery Type</h5>
                <!--<FilterListTree></FilterListTree>-->
                <b-overlay :show="loadingFilters">
                <FilterPublicProductCategories  v-if="loadingFilters == false" :category_data="categoriesdata" ref="categoryfilter"></FilterPublicProductCategories>
              </b-overlay>
                <h5 class="font-size-14 mb-3">Brands</h5>
               <FilterPublicProductBrands v-on:brands-filtered="filterBrands"></FilterPublicProductBrands>
                <h5 class="my-3">Gemstone</h5>
                <b-form-group>
                	<b-form-checkbox-group v-model="keywordslist" @change="loadCategory()" :options="productGemstones"></b-form-checkbox-group>
                </b-form-group>
                <h5 class="my-3">Metal</h5>
                <b-form-group>
                	<b-form-checkbox-group v-model="keywordslist"  @change="loadCategory()" :options="productMetals"></b-form-checkbox-group>
                </b-form-group>
                <h5 class="my-3">Condition</h5>
                <b-form-group>
                	<b-form-checkbox-group v-model="condition"  @change="loadCategory()" :options="productConditions"></b-form-checkbox-group>
                </b-form-group>
            </div>
           

          <!-- <div class="mt-4 pt-3">
              <h5 class="font-size-14 mb-3">Customer Rating</h5>
              <div>
                <b-form-checkbox
                  id="checkbox-1"
                  name="checkbox-1"
                  value="accepted"
                  unchecked-value="not_accepted"
                >
                  4
                  <i class="bx bx-star text-warning"></i> & Above
                </b-form-checkbox>

                <b-form-checkbox
                  id="checkbox-2"
                  class="mt-2"
                  name="checkbox-2"
                  value="accepted"
                  unchecked-value="not_accepted"
                >
                  3
                  <i class="bx bx-star text-warning"></i> & Above
                </b-form-checkbox>

                <b-form-checkbox
                  id="checkbox-3"
                  class="mt-2"
                  name="checkbox-3"
                  value="accepted"
                  unchecked-value="not_accepted"
                >
                  2
                  <i class="bx bx-star text-warning"></i> & Above
                </b-form-checkbox>
                <b-form-checkbox
                  id="checkbox-4"
                  class="mt-2"
                  name="checkbox-4"
                  value="accepted"
                  unchecked-value="not_accepted"
                >
                  1
                  <i class="bx bx-star text-warning"></i>
                </b-form-checkbox>
              </div>
            </div>-->
          </div>
          <h6>Advertisement</h6>
          <div class="card shadow-sm border-radius-1em">
              <img src="/images/banner-placeholder.png" class="w-100">
            </div>
        </div>
      </div>
          </b-col>
      </transition>
      <transition name="fade-right">
					<b-col class="" :class="{'col-lg-9 col-md-12 fade-right-in' : showFilters == true, 'col-lg-12 col-md-12 fade-right-exit' : showFilters == false, }">
						<div class="row mb-3">
          
        <div class="col-lg-7 col-sm-9 order-lg-1 order-md-1 order-sm-1 d-flex">
            <div class="mt-sm-0 form-inline w-100" >
              <div class="search-box mr-2 ml-auto d-flex">
                <div class="position-relative w-100">
                  <input
                    type="text"
                    class="form-control w-100 border"
                    placeholder="Search name, reference or description..."
                    v-model="search"
                    @keyup.enter="loadCategory()"
                  />
                  <i class="bx bx-search-alt search-icon"></i>
                </div>
                <transition name="fade-right">
                 <a v-if="search.length > 3" class="btn btn-primary btn-rounded" @click="loadCategory()">
              	Search
              </a>
            </transition>
              </div>
             
            </div>
          </div>

         
          <div class="col-lg-2 col-sm-12 order-lg-2 order-md-5 order-sm-5 d-flex">
          <form class="my-auto mx-auto">
            <small class="mx-auto my-2 text-center d-block">Results {{data.products.from}} - {{data.products.to}} of {{data.products.total}}</small>
          </form>
        </div>
       
          <div class="col-lg-2 col-sm-3 order-lg-3 order-md-2 order-sm-2 d-flex">
            <div class="d-flex ml-auto">
              <ul class="nav nav-pills product-view-nav">
                <li class="nav-item">
                  <a class="nav-link d-none d-lg-block" :class="{'active': showFilters == true }" href="javascript: void(0);" @click="loadAllCategories(), showFilters = !showFilters, expanded = !expanded">
                    <i class="bx bx-filter-alt"></i>
                  </a>
                  <a class="nav-link d-lg-none d-md-block" :class="{'active': showFiltersSm == true }" href="javascript: void(0);" @click="loadAllCategories(), showFiltersSm = !showFiltersSm">
                    <i class="bx bx-filter-alt"></i>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" :class="{'active': view == 'grid' }" href="javascript: void(0);" @click="view = 'grid'">
                    <i class="bx bx-grid-alt"></i>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" :class="{'active': view == 'list' }" href="javascript: void(0);" @click="view = 'list'">
                    <i class="bx bx-list-ul"></i>
                  </a>
                </li>
              </ul>
              <div class="text-sm-right">
                  
                </div>
          </div>
        </div>
         <div class="col-xl-1 col-lg-1 col-md-12 order-lg-4 order-md-4 order-sm-4">
            <div class="my-auto">
              <b-form-select class="form-control border btn-rounded" v-model="limit" :options="limits" @input="loadCategory()"></b-form-select>
            </div>
          </div>
          <b-col md="12" v-if="showFiltersSm" class="d-lg-none order-lg-5 order-md-3 order-sm-3">
          <div class="col-lg-12 pt-1 mt-4">
        <div class="widget-scrollbox border-radius-1em border" style="overflow-x: hidden; min-height: 300px;">
          <div class="card p-4 rounded-4 shadow-none  mt-4">
            <!--<h4 class="card-title mb-4">Filter</h4>-->
            <div>
               <div class="mb-3">
              <h5 class="mb-3">Price</h5>
              <vue-slider v-model="priceRange" :min="0" :max="100 + priceRange[1]"  @drag-end="loadCategory()" ></vue-slider>
              <b-form-group label="Min Price" class="w-50 d-inline float-left mt-2 mb-3 px-2">
              <b-form-input
                type="number"
                v-model="priceRange[0]"
                @input="loadCategory"></b-form-input>
              </b-form-group>
              <b-form-group label="Max Price" class="w-50 d-inline float-left mt-2 mb-3 px-2">
                 <b-form-input
                 label="Max Price"
                 type="number"
                 v-model="priceRange[1]"
                 @input="loadCategory"></b-form-input>
              </b-form-group>
            </div>
                <h5 class="my-3">Jewellery Type</h5>
                <!--<FilterListTree></FilterListTree>-->
                <b-overlay :show="loadingFilters">
                <FilterPublicProductCategories  v-if="loadingFilters == false" :category_data="categoriesdata" ref="categoryfiltersm"></FilterPublicProductCategories>
              </b-overlay>
                <h5 class="font-size-14 mb-3">Brands</h5>
               <FilterPublicProductBrands v-on:brands-filtered="filterBrands"></FilterPublicProductBrands>
                <h5 class="my-3">Gemstone</h5>
                <b-form-group>
                	<b-form-checkbox-group v-model="keywordslist" @change="loadCategory()" :options="productGemstones"></b-form-checkbox-group>
                </b-form-group>
                <h5 class="my-3">Metal</h5>
                <b-form-group>
                	<b-form-checkbox-group v-model="keywordslist"  @change="loadCategory()" :options="productMetals"></b-form-checkbox-group>
                </b-form-group>
                <h5 class="my-3">Condition</h5>
                <b-form-group>
                	<b-form-checkbox-group v-model="condition"  @change="loadCategory()" :options="productConditions"></b-form-checkbox-group>
                </b-form-group>
            </div>
          
          </div>
          
        </div>
      </div>
          </b-col>
      </div>
						<b-overlay
			        :show="productsLoading"
			        variant="white"
			        opacity="0.85"
			        blur="1px"
			        rounded="xl">
			          <PublicMarketplaceProductList v-if="!productsLoading" :product_list="data.products.data" :expanded="expanded" :layouttoggle="false" :view="'grid'" :marketplace="'public'"  :exchange_rates="$attrs.exchange_rates" :selected_currency="$attrs.selected_currency" :selected_currency_symbol="$attrs.selected_currency_symbol"></PublicMarketplaceProductList>
			      </b-overlay>
					</b-col>
				</transition>
				
			</b-row>
			</b-container>
		</section>
</div>
</template>