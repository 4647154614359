<script type="text/javascript">
	import CardListWidgetCard from "./CardListWidget-Card";
	import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper';
	import { EventBus } from "../../../../../app";
		
	export default{
		data(){
			return {
				current_page: 1,
				query: '',
                contentReloading: false,
				loadingCategories: false,
				hoverIndex: -1,
				hoverLink: -1,
				categories: [],
				swiperOptions: {
                  direction: 'horizontal',
                  loop: true,
                  slidesPerView: 3,
                  spaceBetween: 10,
                  pagination: {
                    el: '.swiper-pagination',
                    dynamicBullets: true,
                    clickable: true
                  },
                  navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                  }
            },
			}
		},
		props: {
			company: {
				type: Object
			},
			resource: {
				type: String,
				default: () => 'categories'
			}
		},
		components: {
			CardListWidgetCard,
			Swiper,
		  SwiperSlide
		},
		directives: {
		    swiper: directive
		 },
         
     computed: {
        swiper() {
            return this.$refs['cardListCarousel-'+this.$attrs.id].$swiper
        },  
     },
		 watch: {
            '$attrs.content'(){
                 if(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel.subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel.subfields, 'show')].show.value !== ''){
                    this.swiperOptions.slidesPerView = parseFloat(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel.subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel.subfields, 'show')].show.value).toFixed(0);
                }else{
                    this.swiperOptions.slidesPerView = 3;
                }
                if(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel.subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel.subfields, 'loop')].loop.value !== ''){
                    this.swiperOptions.loop = this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel.subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel.subfields, 'loop')].loop.value;
                }else{
                    this.swiperOptions.loop = false;
                }
                if(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel.subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel.subfields, 'margin')].margin.value !== ''){
                    this.swiperOptions.spaceBetween = this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel.subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel.subfields, 'margin')].margin.value;
                }else{
                    this.swiperOptions.spaceBetween = 10;
                }
                if(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel.subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel.subfields, 'direction')].direction.value !== ''){
                    this.swiperOptions.direction = this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel.subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel.subfields, 'direction')].direction.value;
                }else{
                    this.swiperOptions.direction = 'horizontal';
                }
            },
            '$attrs.content[*].carousel.show.value'(){
                if(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel[this.filterStylesKey(this.$attrs.content, 'show')].show.value !== ''){
                    this.swiperOptions.slidesPerView = parseFloat(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel[this.filterStylesKey(this.$attrs.content, 'show')].show.value).toFixed(0);
                    this.swiper.loopedSlide = parseFloat(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel[this.filterStylesKey(this.$attrs.content, 'show')].show.value).toFixed(0);
                }else{
                    this.swiperOptions.slidesPerView = 3;
                }
              
            },
            '$attrs.content[*].carousel.margin.value'(){
                if(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel[this.filterStylesKey(this.$attrs.content, 'margin')].margin.value !== ''){
                    this.swiperOptions.loop = this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel[this.filterStylesKey(this.$attrs.content, 'margin')].margin.value;
                }else{
                    this.swiperOptions.loop = 10;
                }
                
            },
            '$attrs.content[*].carousel.loop.value'(){
                if(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel[this.filterStylesKey(this.$attrs.content, 'loop')].loop.value !== ''){
                    this.swiperOptions.loop = this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel[this.filterStylesKey(this.$attrs.content, 'loop')].loop.value;
                }else{
                    this.swiperOptions.loop = false;
                }
               
            },
            '$attrs.content[*].carousel.direction.value'(){
                if(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel[this.filterStylesKey(this.$attrs.content, 'direction')].direction.value !== ''){
                    this.swiperOptions.direction = this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel[this.filterStylesKey(this.$attrs.content, 'direction')].direction.value;
                }else{
                    this.swiperOptions.direction = 'horizontal';
                }
                
            }
        },
        created(){
                if(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel.subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel.subfields, 'show')].show.value !== ''){
                    this.swiperOptions.slidesPerView = parseFloat(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel.subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel.subfields, 'show')].show.value).toFixed(0);
                }else{
                    this.swiperOptions.slidesPerView = 3;
                }
                if(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel.subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel.subfields, 'loop')].loop.value !== ''){
                    this.swiperOptions.loop = this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel.subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel.subfields, 'loop')].loop.value;
                }else{
                    this.swiperOptions.loop = false;
                }
                if(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel.subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel.subfields, 'margin')].margin.value !== ''){
                    this.swiperOptions.spaceBetween = this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel.subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel.subfields, 'margin')].margin.value;
                }else{
                    this.swiperOptions.spaceBetween = 10;
                }
                if(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel.subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel.subfields, 'direction')].direction.value !== ''){
                    this.swiperOptions.direction = this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel.subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel.subfields, 'direction')].direction.value;
                }else{
                    this.swiperOptions.direction = 'horizontal';
                }
        },
		methods: {
            filterStylesKey(styles, key){
                for(var i = 0; i < styles.length; i++){
                   var keys = Object.keys(styles[i])
                   if(keys.includes(key)){
                     return i;
                   }
                }
            },
            loadDefaultClasses(classes){
                return classes;
            },
            compileElementStyles(fieldname, types){
                var style = '';
                if(types.includes('background') == true){
                    if(fieldname !== 'column'){
                        style += 'background-color:'+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_colour')][fieldname+'_colour'].value+' !important;'
                    }else{
                        style += 'background-color:'+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'background_colour')].background_colour.value+' !important;'
                    }
                }
                if(types.includes('color') == true){
                    if(fieldname !== 'column'){
                        style += 'color:'+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_text_colour')][fieldname+'_text_colour'].value+' !important;'
                    }else{
                        style += 'color:'+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'text_colour')].text_colour.value+' !important;'
                    }
                }
                if(types.includes('border') == true){
                    if(fieldname !== 'column'){
                        style +=  'border-color: '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_border_colour')][fieldname+'_border_colour'].value+' !important; border-style:'+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_border_style')][fieldname+'_border_style'].value+' !important; border-radius:'+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_border_radius')][fieldname+'_border_radius'].top_left+' '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_border_radius')][fieldname+'_border_radius'].top_right+' '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_border_radius')][fieldname+'_border_radius'].bottom_right+' '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_border_radius')][fieldname+'_border_radius'].bottom_left+' !important; border-left:'+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_border')][fieldname+'_border'].left+'px !important; border-right: '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_border')][fieldname+'_border'].right+'px !important; border-top: '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_border')][fieldname+'_border'].top+'px !important; border-bottom: '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_border')][fieldname+'_border'].bottom+'px !important;'
                    }else{
                        style +=  'border-color: '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'border_colour')].border_colour.value+' !important;'

                        style += 'border-style:'+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'border_style')].border_style.value+' !important;'

                        style += 'border-radius:'+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'border_radius')].border_radius.top_left+' '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'border_radius')].border_radius.top_right+' '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'border_radius')].border_radius.bottom_right+' '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'border_radius')].border_radius.bottom_left+' !important;'
                        style += 'border-left:'+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'border')].border.left+'px !important; border-right: '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'border')].border.right+'px !important; border-top: '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'border')].border.top+'px !important; border-bottom: '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'border')].border.bottom+'px !important;'
                    }

                }
                if(types.includes('margin') == true){
                    if(fieldname !== 'column'){
                    style +=  'margin-left:'+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_margin')][fieldname+'_margin'].left+'px !important;';
                    style +=  'margin-right: '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_margin')][fieldname+'_margin'].right+'px !important;';
                    style +=  'margin-top: '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_margin')][fieldname+'_margin'].top+'px !important;';
                    style +=  'margin-bottom: '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_margin')][fieldname+'_margin'].bottom+'px !important;';
                    }else{
                    style +=  'margin-left:'+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'margin')]['margin'].left+'px !important;'
                    style +=  'margin-right: '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'margin')]['margin'].right+'px !important;'
                    style +=  'margin-top: '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'margin')]['margin'].top+'px !important;'
                    style +=  'margin-bottom: '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'margin')]['margin'].bottom+'px !important;'
                    }
                }
                if(types.includes('padding') == true){
                    if(fieldname !== 'column'){
                    style +=  'padding-left:'+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_padding')][fieldname+'_padding'].left+'px !important;'
                    style +=  'padding-right: '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_padding')][fieldname+'_padding'].right+'px !important;'
                    style +=  'padding-top: '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_padding')][fieldname+'_padding'].top+'px !important;'
                    style +=  'padding-bottom: '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_padding')][fieldname+'_padding'].bottom+'px !important;'
                    }else{
                        style +=  'padding-left:'+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'padding')]['padding'].left+'px !important;'
                    style +=  'padding-right: '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'padding')]['padding'].right+'px !important;'
                    style +=  'padding-top: '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'padding')]['padding'].top+'px !important;'
                    style +=  'padding-bottom: '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'padding')]['padding'].bottom+'px !important;'
                    }
                }
                if(types.includes('size') == true){
                    if(fieldname !== 'column'){
                    style +=  'height:'+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_height')][fieldname+'_height'].value+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_height')][fieldname+'_height'].units+' !important;';
                    style +=  'max-height:'+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_max_height')][fieldname+'_max_height'].value+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_max_height')][fieldname+'_max_height'].units+' !important;';
                    style +=  'width:'+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_width')][fieldname+'_width'].value+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_width')][fieldname+'_width'].units+' !important;'
                    style +=  'max-width:'+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_max_width')][fieldname+'_max_width'].value+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_max_width')][fieldname+'_max_width'].units+' !important;'
                    }else{
                    style +=  'height:'+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'height')]['height'].value+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'height')]['height'].units+' !important;';
                    style +=  'max-height:'+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'max_height')]['max_height'].value+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'max_height')]['max_height'].units+' !important;';
                    style +=  'width:'+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'width')]['width'].value+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'width')]['width'].units+' !important;'
                    style +=  'max-width:'+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'max_width')]['max_width'].value+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'max_width')]['max_width'].units+' !important;'
                    } 
                }
                
                if(types.includes('text') == true){
                  style += 'font-family: \"'+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'font_family')].font_family.value.family+'\", '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'font_family')].font_family.value.category+' !important; font-size:'+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'font_size')].font_size.value+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'font_size')].font_size.units+' !important; text-transform: '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'text_transform')].text_transform.value+'!important;';
                }

                return style;
            }
        },
		  watch: {
        $route() {
        	this.$nextTick(() => {
        		 //this.loadingPage = true;
        		 this.getResource(this.resource);

        		});
        	}
        },
        mounted(){
             if(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel.subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel.subfields, 'show')].show.value !== ''){
                    this.swiperOptions.slidesPerView = parseFloat(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel.subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel.subfields, 'show')].show.value).toFixed(0);
                }else{
                    this.swiperOptions.slidesPerView = 3;
                }
                if(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel.subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel.subfields, 'loop')].loop.value !== ''){
                    this.swiperOptions.loop = this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel.subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel.subfields, 'loop')].loop.value;
                }else{
                    this.swiperOptions.loop = false;
                }
                if(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel.subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel.subfields, 'margin')].margin.value !== ''){
                    this.swiperOptions.spaceBetween = this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel.subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel.subfields, 'margin')].margin.value;
                }else{
                    this.swiperOptions.spaceBetween = 10;
                }
                if(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel.subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel.subfields, 'direction')].direction.value !== ''){
                    this.swiperOptions.direction = this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel.subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel.subfields, 'direction')].direction.value;
                }else{
                    this.swiperOptions.direction = 'horizontal';
                }
                let self = this;
                EventBus.$on('update-widget', function(){
                    self.contentReloading = true;
                    setTimeout(function(){
                        self.contentReloading = false;
                    }, 1000);
                });
        }
	}

</script>

<template>
	<b-container fluid class="py-4 mb-4 default-content categories-collections">
		<b-overlay :show="loadingCategories" no-wrap></b-overlay>
		<b-row v-if="(($attrs.content[filterStylesKey($attrs.content, 'view')].view.value == 'grid') || ($attrs.content[filterStylesKey($attrs.content, 'view')].view.value == 'list'))  && (contentReloading == false)">
			<b-col  :class="'col-xl-'+$attrs.content[filterStylesKey($attrs.content, 'columns')].columns.value.xl+' col-lg-'+$attrs.content[filterStylesKey($attrs.content, 'columns')].columns.value.lg+' col-md-'+$attrs.content[filterStylesKey($attrs.content, 'columns')].columns.value.md+' col-sm-'+$attrs.content[filterStylesKey($attrs.content, 'columns')].columns.value.sm+' col-xs-'+$attrs.content[filterStylesKey($attrs.content, 'columns')].columns.value.xs" v-for="(item, index) in $attrs.content[filterStylesKey($attrs.content, 'list')].list">
					<CardListWidgetCard :company="company" :liveMode="$attrs.liveMode" :content="$attrs.content" :data="item" :index="index" :card_layout="$attrs.content[filterStylesKey($attrs.content, 'card_layout')].card_layout.value"></CardListWidgetCard>
			</b-col>
			<b-col md="12">

				<b-pagination v-if="$attrs.content[filterStylesKey($attrs.content, 'list')].list < 0" v-model="current_page" :total-rows="$attrs.content[filterStylesKey($attrs.content, 'list')].list.length" :per-page="$attrs.filters.limit.value" align="right"></b-pagination>
			</b-col>
		</b-row>
		<swiper v-else-if="($attrs.content[filterStylesKey($attrs.content, 'view')].view.value == 'carousel')  && (contentReloading == false)" :ref="'cardListCarousel-'+$attrs.id" :options="swiperOptions" class="overflow-hidden" >
        <swiper-slide  v-for="(item, index) in $attrs.content[filterStylesKey($attrs.content, 'list')].list" class="h-100" >
              <CardListWidgetCard :company="company" :content="$attrs.content" :liveMode="$attrs.liveMode" :data="item" :index="index" :card_layout="$attrs.content[filterStylesKey($attrs.content, 'card_layout')].card_layout.value"></CardListWidgetCard>
        </swiper-slide>
  	</swiper>
    <b-overlay v-else-if="contentReloading == true" :show="contentReloading">
    </b-overlay>
	</b-container>
</template>