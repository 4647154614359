<script type="text/javascript">
	import PublicTopbar from "../components/public-topbar";
	import PublicNav from "../components/public-nav";
	import DefaultNavBar from "../components/widgets/page-builder/templates/actinolite/DefaultNavBar";
	import RightBar from "../components/right-bar";
	//import DefaultNav from "../components/widgets/page-builder/templates/actinolite/DefaultNav";
	import DefaultBreadcrumbs from "../components/widgets/page-builder/templates/actinolite/DefaultBreadcrumbs";
	import DefaultFooter from "../components/widgets/page-builder/templates/actinolite/DefaultFooter";
	import DefaultContent404 from "../components/widgets/page-builder/templates/actinolite/DefaultContent-404";
	import DefaultContentProduct from "../components/widgets/page-builder/templates/actinolite/DefaultContent-Product";
	import DefaultContentProducts from "../components/widgets/page-builder/templates/actinolite/DefaultContent-Products";
	import DefaultContentComingSoon from "../components/widgets/page-builder/templates/actinolite/DefaultContent-ComingSoon";
	import DefaultContentHome from "../components/widgets/page-builder/templates/actinolite/DefaultContent-Home";
	import DefaultContentCustomerAccount from "../components/widgets/page-builder/templates/actinolite/DefaultContent-CustomerAccount";
	import DefaultContentOrder from "../components/widgets/page-builder/templates/actinolite/DefaultContent-Order";
	import DefaultContentContact from "../components/widgets/page-builder/templates/actinolite/DefaultContent-Contact";
	import DefaultContentCategories from "../components/widgets/page-builder/templates/actinolite/DefaultContent-Categories";
	import DefaultContentCategory from "../components/widgets/page-builder/templates/actinolite/DefaultContent-Category";
	import PageBuilderProcessor from "../components/widgets/page-builder/PageBuilderProcessor"

	export default {
		name: 'Page',
		props: {
			domain: {
				type: Object
			},
			user: {
				type: Object
			}
		},
		data(){
			return {
				loadDefaultHeader: false,
				loadDefaultContent: false,
				loadDefaultFooter: false,
				resource: '',
				loadingPage: true,
				page_loaded: false,
				page_raw_content: [],
				page: {
					header: null,
					content: null,
					footer: null,
				}
			}
		},
		components: {
		    PublicTopbar,
		    PublicNav,
		    DefaultNavBar,
		    DefaultBreadcrumbs,
		    DefaultFooter,
		    DefaultContent404,
		    DefaultContentComingSoon,
		    DefaultContentCustomerAccount,
		    DefaultContentOrder,
		    DefaultContentHome,
		    DefaultContentProduct,
		    DefaultContentProducts,
		    DefaultContentContact,
		    DefaultContentCategories,
		    DefaultContentCategory,
		    RightBar,
		    PageBuilderProcessor
		  },
		  mounted(){
		  	this.getPage();
		  },
		  methods: {
		  	async getPage(){
			  		console.log(this.$route.path);
			  		if(this.$route.path == '/' || this.$route.path == '/shop'){
			  					if(this.domain.company.default_webpage !== null){
						  			this.page = this.domain.company.default_webpage;
						  			this.page_loaded = true;
					  			}else{
					  				this.page.content = null;
					  			}
			  					if(this.domain.company.default_webtemplate !== null){
									this.page.header = this.domain.company.default_webtemplate.header;
									this.page.footer = this.domain.company.default_webtemplate.footer;

									if(this.page.header == null){
										this.loadDefaultHeader = true;
									}
									if(this.page.footer == null){
										this.loadDefaultFooter = true;
									}
								}else{
									console.log('No Templates');
									this.page.header = null;
									this.page.footer = null;
									this.loadDefaultHeader = true;
									this.loadDefaultFooter = true;
								}
								

								if(this.domain.company.default_webtheme !== null){
									var h = document.getElementsByTagName('head').item(0);
									var s = document.createElement("link");
									s.rel = "stylesheet"; 
									s.id = "themeStylesheet";
									s.href = 'https://'+this.company.nickname+'.s3.eu-west-2.amazonaws.com/public/web-themes/'+this.domain.company.default_webtheme.uid+'.css';
									//s.href = 'https://'+this.domain.company.nickname+'.eu-west-2.amazonaws.com/'+this.domain.company.uid+'/web-themes/'+this.domain.company.default_webtheme.uid+'.css';
									h.appendChild(s);

									var b = document.getElementsByTagName('body').item(0);
									b.id = "theme";

								}
			  			let self = this;
			  				setTimeout(function(){
					            self.loadingPage = false;
					          }, 300);
			  		}else{
			  		var routearray = this.$route.path.substring(1).split('/');
			  		console.log(routearray);

			  		if(routearray[0] !== ('product' || 'category')){
			  			await axios.get('/website-api/page/'+routearray[routearray.length - 1]).then(response => {
							//console.log(response.data);
								this.page = response.data;
								this.page_raw_content = response.data.content;
								if(response.data.template !== null){
									if((response.data.template.header !== null)){
									this.page.header = response.data.template.header;
									}
									if((response.data.template.footer !== null)){
									this.page.footer = response.data.template.footer;
									}
									if((response.data.template.content !== null)){
										this.page.content = response.data.template.content;
										
										var contentstring = JSON.stringify(response.data.template.content);

									}
								}else if((this.domain.company.default_webtemplate !== null) && (this.domain.company.default_webtemplate > 0)){
									this.page.header = this.domain.company.default_webtemplate.header;
									this.page.footer = this.domain.company.default_webtemplate.footer;


									
								}else{
									console.log('No Templates');
									this.page.header = null;
									this.page.footer = null;
									
								}

								if(reponse.data.theme !== null){
									var h = document.getElementsByTagName('head').item(0);
									var s = document.createElement("link");
									s.rel = "stylesheet"; 
									s.id = "themeStylesheet";
									s.href = 'https://'+this.company.nickname+'.s3.eu-west-2.amazonaws.com/public/web-themes/'+response.data.theme.uid+'.css';
									//s.href = 'https://'+this.domain.company.nickname+'.eu-west-2.amazonaws.com/'+this.domain.company.uid+'/web-themes/'+response.data.theme.uid+'.css';
									h.appendChild(s);

									var b = document.getElementsByTagName('body').item(0);
									b.id = "theme";
								}else if(this.domain.company.default_webtheme !== null){
									var h = document.getElementsByTagName('head').item(0);
									var s = document.createElement("link");
									s.rel = "stylesheet"; 
									s.id = "themeStylesheet";
									s.href = 'https://'+this.company.nickname+'.s3.eu-west-2.amazonaws.com/public/web-themes/'+this.domain.company.default_webtheme.uid+'.css';
									//s.href = 'https://'+this.domain.company.nickname+'.eu-west-2.amazonaws.com/'+this.domain.company.uid+'/web-themes/'+this.domain.company.default_webtheme.uid+'.css';
									h.appendChild(s);

									var b = document.getElementsByTagName('body').item(0);
									b.id = "theme";
								}
								this.page_loaded = true;
								let self = this;
					          setTimeout(function(){
					            self.loadingPage = false;
					            	if(self.page.header == null){
										self.loadDefaultHeader = true;
									}
									if(self.page.footer == null){
										self.loadDefaultFooter = true;
									}
					          }, 500);
			  			}).catch(error => {
			  					
			  				
			  					if(this.domain.company.default_webtemplate !== null){
									this.page.header = this.domain.company.default_webtemplate.header;
									this.page.footer = this.domain.company.default_webtemplate.footer;

									
								}
								

								if(this.domain.company.default_webtheme !== null){
									var h = document.getElementsByTagName('head').item(0);
									var s = document.createElement("link");
									s.rel = "stylesheet"; 
									s.id = "themeStylesheet";
									s.href = 'https://'+this.company.nickname+'.s3.eu-west-2.amazonaws.com/public/web-themes/'+this.domain.company.default_webtheme.uid+'.css';
									//s.href = 'https://'+this.domain.company.nickname+'.eu-west-2.amazonaws.com/'+this.domain.company.uid+'/web-themes/'+this.domain.company.default_webtheme.uid+'.css';
									h.appendChild(s);

									var b = document.getElementsByTagName('body').item(0);
									b.id = "theme";

								}
								if(!error.response){
									this.page_loaded = true;
								}else{
									this.page_loaded = false;
								}
			  			let self = this;
			  				setTimeout(function(){
					            self.loadingPage = false;
					            	if(self.page.header == null){
										self.loadDefaultHeader = true;
									}
									if(self.page.footer == null){
										self.loadDefaultFooter = true;
									}
					          }, 300);
			  	
			  			});
			  		}else{
			  			this.resource = routearray[0];

			  			if(this.domain.company.default_webtheme !== null){
									var h = document.getElementsByTagName('head').item(0);
									var s = document.createElement("link");
									s.rel = "stylesheet"; 
									s.id = "themeStylesheet";
									s.href = 'https://'+this.company.nickname+'.s3.eu-west-2.amazonaws.com/public/web-themes/'+this.domain.company.default_webtheme.uid+'.css';
									//s.href = 'https://'+this.domain.company.nickname+'.eu-west-2.amazonaws.com/'+this.domain.company.uid+'/web-themes/'+this.domain.company.default_webtheme.uid+'.css';
									h.appendChild(s);

									var b = document.getElementsByTagName('body').item(0);
									b.id = "theme";

								}
			  			//this.page.content = null;

						if(this.domain.company.default_webtemplate !== null){
									this.page.header = this.domain.company.default_webtemplate.header;
									this.page.footer = this.domain.company.default_webtemplate.footer;

									/*if(this.page.header == null){
										this.loadDefaultHeader = true;
									}
									if(this.page.footer == null){
										this.loadDefaultFooter = true;
									}*/
								}
										
						if(this.domain.company.resource_webtemplate !== null){
			  				if((this.domain.company.resource_webtemplate.header !== null)){
								this.page.header = this.domain.company.resource_webtemplate.header;
							}
							if((this.domain.company.resource_webtemplate.footer !== null)){
								this.page.footer = this.domain.company.resource_webtemplate.footer;
							}
							if((this.domain.company.resource_webtemplate.content !== null)){
								this.page.content = this.domain.company.resource_webtemplate.content;
										
										var contentstring = JSON.stringify(this.domain.company.resource_webtemplate.content);
								this.page_loaded = true;

							}
			  			}else{
			  				this.page_loaded = false;
			  			}

			  			let self = this;
			  				setTimeout(function(){
					            self.loadingPage = false;

					            if(self.page.header == null){
										self.loadDefaultHeader = true;
									}
									if(self.page.footer == null){
										self.loadDefaultFooter = true;
									}
					          }, 300);
			  		}
		  		}
		  	}
		  },
		  watch: {
        $route() {
        	this.$nextTick(() => {
        		 this.loadingPage = true;
        		 this.page_loaded = false;
        		 this.page = {
						header: null,
						content: null,
						footer: null,
					};
        		 this.getPage();

        		});
        	}
        }
	}
</script>
<template>
	<div>
		<PageBuilderProcessor v-if="(page.header !== null)"  :content="page.header" :company="domain.company" :user="user" :domain="domain"></PageBuilderProcessor>
		<div v-else-if="loadDefaultHeader == true">
	 <DefaultNavBar v-if="(page.header == null)" :company="domain.company" :user="user" :menu="''" :domain="domain" />
	 
	 <!--<PublicTopbar v-if="(page.header == null)" :domain="domain" />
      <PublicNav v-if="(page.header == null)" ></PublicNav>-->
		
		<DefaultBreadcrumbs  v-if="((page.header == null)) && ($route.path !== '/')"></DefaultBreadcrumbs>
		</div>
      <!-- ============================================================== -->
      <!-- Start right Content here -->
      <!-- ============================================================== -->
      	<div class="main-content">
        	<div class="page-content bg-white p-0" >  <!-- container-fluid -->
        		<b-overlay
        			:show="loadingPage">
						<PageBuilderProcessor :content="page.content" v-if="page_loaded == true && ($route.path.substring(1).split('/')[0] !== 'account')" :company="domain.company" :user="user" :domain="domain" :inner_content="page_raw_content" :resource="resource"></PageBuilderProcessor>
						<DefaultContentContact v-else-if="(page.content == null) && ($route.path == ('/contact' || '/contact/'))" :company="domain.company"></DefaultContentContact>
						<DefaultContentCategory v-else-if="(page.content == null) && ($route.path.substring(1).split('/')[0] == 'category')" :company="domain.company" :resource="'category'" ></DefaultContentCategory>
						<DefaultContentCategory v-else-if="(page.content == null) && ($route.path.substring(1).split('/')[0] == 'collection')" :company="domain.company" :resource="'collection'" ></DefaultContentCategory>
						<DefaultContentProduct v-else-if="(page.content == null) && ($route.path.substring(1).split('/')[0] == 'product')" :company="domain.company" :user="user" :resource="'product'" ></DefaultContentProduct>
						<DefaultContentProducts v-else-if="(page.content == null) && ($route.path.substring(1).split('/')[0] == 'shop')" :company="domain.company" :resource="'products'" ></DefaultContentProducts>
						
						
						<DefaultContentHome v-else-if="(page.content == null) && ($route.path == '/')" :company="domain.company" :resource="'categories'" ></DefaultContentHome>
						<DefaultContentCategories v-else-if="(page.content == null) && ($route.path == ('/categories' || '/categories/'))" :company="domain.company" :resource="'categories'" ></DefaultContentCategories>
						<DefaultContentCategories v-else-if="(page.content == null) && ($route.path == ('/collections' || '/collections/'))" :company="domain.company" :resource="'collections'" ></DefaultContentCategories>
						
						<DefaultContentComingSoon v-else-if="(page.content == null) && ($route.path == ('/services' || '/services/'))" :company="domain.company"></DefaultContentComingSoon>
						<DefaultContentCustomerAccount v-else-if="($route.path.substring(1).split('/')[0] == 'account')" :company="domain.company" :user="user"></DefaultContentCustomerAccount>
						<DefaultContentOrder v-else-if="($route.path.substring(1).split('/')[0] == 'order')" :company="domain.company" :domain="domain" :user="user"></DefaultContentOrder>

						<DefaultContent404 v-else></DefaultContent404>
					</b-overlay>
        	</div>
        <!-- End Page-content -->
        
        <DefaultFooter v-if="page.footer == null" :company="domain.company" :user="user" />
				<PageBuilderProcessor :domain="domain" :content="page.footer" :company="domain.company" :user="user"/>
				
      </div>
	</div>
	
	
</template>

<style type="text/css">
	
	body{
		overflow-x: hidden;
	}
	body[data-layout=horizontal] .navbar-header{
		max-width: 100%;
	}
</style>
