<script type="text/javascript">
import OrderReceipt from './OrderReceipt'
import GemesysOrderReceipt from './GemesysOrderReceipt'
//import VueHtml2pdf from 'vue-html2pdf';
	export default {
		components: {OrderReceipt, GemesysOrderReceipt},
		data(){
			return {
				storageurl: '.s3.eu-west-2.amazonaws.com',
				total_shipping_inc: 0.00,
				total_shipping_exc: 0.00,
				order_receipt_item: '',
				invoiceDownload: false,
				form: {
					action: '',
					name: '',
					lastname: '',
					email: '',
					customer_uid: '',
					seller: '',
					share_details: '',
					review: '',
					message: '',
					review_score: 0,
				},
			}
		},
		props: {
	  	   	 user: {
	  	   	 	type: Object,
	  	   	 },
	  	   	 company: {
	  	   	 	type: Object,
	  	   	 	default: () => '',
	  	   	 },
	  	   	 order: {
	  	   	 	type: Object,
	  	   	 },
	  	   	 paid: {
	  	   	 	type: Boolean,
	  	   	 	default: () => false,
	  	   	 },
	  	   	 uid: {
	  	   	 	type: String,
	  	   	 	default: () => '',
	  	   	 },

	  	   },
	  mounted(){
	  	this.loadOrder();
	  },
	  methods: {
	  	loadOrder(){
		  		if(this.uid !== ''){
			  		axios.get('/api/company/trade/order/'+this.uid, {headers: { 'Authorization': 'Bearer ' + this.user.api_token}}).then(response => {
			  			this.order = response.data;
			  			this.totalShipping(response.data.company_order);
			  		}).catch(error => {

			  		});
		  		}
	  	},
	  	generateReport () {
            this.$refs.html2Pdf.generatePdf()
        },
        printInvoice(){
        	this.$bvModal.hide('invoice-modal');
        	this.showInvoiceLayout = true;
        	
        	//var printContents = document.getElementById('invoiceArea').innerHTML;
			     //var originalContents = document.getElementById('print-window').innerHTML;

			     //document.getElementById('print-window').innerHTML = printContents;

			     window.print();

			     //document.getElementById('print-window').innerHTML = originalContents;
			     //this.showInvoiceLayout = false;
			     //this.$bvModal.hide('invoice-modal');
        },
        gemesysGenerateReport () {
            this.$refs.gemesyshtml2Pdf.generatePdf()
        },
        gemesysPrintInvoice(){
        	this.$bvModal.hide('gemesys-invoice-modal');
        	this.showInvoiceLayout = true;
        	
        	//var printContents = document.getElementById('invoiceArea').innerHTML;
			     //var originalContents = document.getElementById('print-window').innerHTML;

			     //document.getElementById('print-window').innerHTML = printContents;

			     window.print();

			     //document.getElementById('print-window').innerHTML = originalContents;
			     //this.showInvoiceLayout = false;
			     //this.$bvModal.hide('invoice-modal');
        },
	  	totalShipping(orders){
	  		for(var i = 0; i < orders.length; i++){
	  			this.total_shipping_inc += parseFloat(orders[i].company_order.total_shipping_inc);
	  			this.total_shipping_exc += parseFloat(orders[i].company_order.total_shipping_exc);
	  		}
	  	}
	  }
	}

</script>

<template>
<b-container fluid>
	<b-row class="pb-5">
		<b-col md="8">

			<b-card class="border-radius-1em">
		
				<b-row>
					<b-col class="">
						<b-card class="border-radius-1em border shadow-none">
						<h4 class="card-title mb-3">Delivery Address</h4>
						{{order.customer_delivery_address.alias}}
						{{order.customer_delivery_address.address1}}
						{{order.customer_delivery_address.address2}}
						{{order.customer_delivery_address.address3}}
						{{order.customer_delivery_address.city}}
						<span v-if="order.customer_delivery_address.county !== null">{{order.customer_delivery_address.county.name}}</span>
						{{order.customer_delivery_address.postcode}}
						<span v-if="order.customer_delivery_address.country !== null">{{order.customer_delivery_address.country.name}}</span>
					</b-card>
					</b-col>
					<b-col>
						<b-card class="border-radius-1em border shadow-none">
						<h4 class="card-title mb-3">Invoice Address</h4>
						{{order.customer_invoice_address.alias}}
						{{order.customer_invoice_address.address1}}
						{{order.customer_invoice_address.address2}}
						{{order.customer_invoice_address.address3}}
						{{order.customer_invoice_address.city}}
						<span v-if="order.customer_invoice_address.county !== null">{{order.customer_invoice_address.county.name}}</span>
						{{order.customer_invoice_address.postcode}}
						<span v-if="order.customer_invoice_address.country !== null">{{order.customer_invoice_address.country.name}}</span>
					</b-card>
					</b-col>
					<b-col md="12">
						<b-card class="border-radius-1em border shadow-none">
							<h4 class="card-title mb-3">Order Summary</h4>
							<b-card class="shadow-none border-radius-1em border" v-for="(company, index) in order.company_order">
								<div class="d-flex">
								<h5 class="card-title my-auto mr-auto">Seller Information</h5>
								<a class="btn btn-outline-light btn-rounded ml-auto my-auto" :href="'/trade/profile/'+company.company_order.company.nickname">View Profile</a>
								<b-dropdown
					          variant="white" right toggle-class="noti-icon d-flex" menu-class="dropdown-menu-end"
					        >
					          <template v-slot:button-content>
									<i class="bx bx-dots-vertical my-auto"></i>
								</template>

           				<b-dropdown-item class="notify-item" v-b-modal.modal-1 @click="form.seller = company.company_order.company, form.action = 'message'">Message Contact <i class="bx bx-mail-send text-primary"></i></b-dropdown-item>
		        			<b-dropdown-item class="notify-item" @click="form.action = 'favourites'">Add To Favourites <i class="mdi mdi-heart-outline text-danger"></i></b-dropdown-item>
		        			<b-dropdown-item class="notify-item" v-b-modal.modal-1 @click="form.action = 'review'">Submit Review <i class="bx bx-star text-warning"></i></b-dropdown-item>
								</b-dropdown>
							</div>
								<hr>
								<div class="d-flex justify-content-start mb-3">
								
								<h5 class="text-muted my-auto">Order from {{company.company_order.company.name}}</h5>
								<img v-if="company.company_order.company.logo" :src="'https://'+ company.company_order.company.nickname + storageurl + company.company_order.company.logo" class="avatar-sm rounded-circle border my-auto ml-3">
										<img v-else src="/images/product-placeholder.png" class="avatar-sm rounded-circle border my-auto ml-3">
								<div class="ml-auto my-auto d-flex">
									<strong class="ml-auto my-auto">Seller Ref <span>#</span></strong>
									<p class="my-auto">{{company.company_order.reference}}</p>
								</div>
							</div>

							<h5 class="card-title">Order Item(s)</h5>
								<hr>
								<b-list-group flush>
									<b-list-group-item v-for="product in company.company_order.products" class="d-flex flex-column">
										<div class="d-flex">
										<div v-if="product.product.cover_image !== null" >
										<img v-if="'thumb' in product.product.cover_image" :src="'https://'+ product.product.company.nickname + storageurl + product.product.cover_image.thumb.replaceAll('//', '/')" class="avatar-sm rounded-circle mr-3">
										<img v-else-if="'gallery' in product.product.cover_image" :src="'https://'+ product.product.company.nickname + storageurl + product.product.cover_image.gallery.replaceAll('//', '/')" class="avatar-sm rounded-circle mr-3">
										<img v-else-if="'src' in product.product.cover_image" :src="'https://'+ product.product.company.nickname + storageurl + product.product.cover_image.src.replaceAll('//', '/')" class="avatar-sm rounded-circle mr-3">
										</div>
										<img v-else src="/images/product-placeholder.png" class="avatar-sm rounded-circle mr-3">
										<span class="mr-auto my-auto">{{product.product.name}}</span>
										<div class="my-auto mr-4">OTY {{product.quantity}}</div>
										<div class="my-auto text-bold ml-3 d-flex flex-column">
											<div class="my-auto"><strong class="text-primary">£</strong>{{(product.product.sales_price  * product.quantity).toFixed(2)}}</div></div>
										</div>
												<b-row  v-if="(product.combinations.length == 0) && (product.variants.length > 0)" class="mt-4">
											<b-col md="12">
											<b-card class="border-radius-1em border-none shadow-none" body-class="p-0">
												<h3 class="font-size-18 text-center mb-4">Customisation Options</h3>
											<div class="w-100 mb-3" v-for="(variants, key) in product.grouped_variants">
											
												<h5 class="text-uppercase font-size-12 spaced-title my-2">{{variants[0].variant.attribute.name}}</h5>
											
											
												<b-row >
													<b-col md="12" v-for="value in variants">
													<b-overlay :show="loadingVariants">
														<a href="javascript:;">
														<b-card class="border-top border-bottom bg-transparent shadow-none text-dark mb-0" body-class="d-flex">
															<span class="my-auto" v-if="value.variant.value !== null"><img v-if="value.variant.value.image !== null" :src="value.variant.value.image" class="avatar-xs rounded-circle mr-2 border"> 
															</span>
															<span class="my-auto mr-2" v-if="value.variant.value !== null">{{value.variant.value.name}}</span> <span class="my-auto">{{value.variant.measurement_value}}</span><span class="my-auto mr-2" v-if="value.variant.units !== null">{{value.variant.units.suffix}}</span>
															<span class="badge badge-light bg-light my-auto ml-auto font-size-14">
															<span class="ml-auto my-auto" v-if="value.variant.price_difference > 0">+</span><span class="ml-auto my-auto" v-else>-</span><span>£</span>{{value.variant.price_difference}}
															</span>
														</b-card>
														</a>
													</b-overlay>
													</b-col>
												</b-row>
												</div>
											</b-card>
											</b-col>
											
									</b-row>
									<b-row v-else-if="product.combinations.length > 0">
										<b-col>
											<b-card class="border-radius-1em border-none shadow-none mt-3" body-class="p-0">
													<h3 class="font-size-18 text-center mb-4">Customisation Options</h3>
											<div class="w-100" v-for="(combination, index) in product.combinations">
												<a href="javascript:;" >
													<b-card  class="border-top border-bottom bg-transparent shadow-none text-dark mb-0">
												<b-row>
													<b-col md="3" v-for="value in combination.combination_variants">
														
														
															<span v-if="value.variant.attribute !== null">{{value.variant.attribute.name}}</span> 
															<span v-if="value.variant.value !== null">{{value.variant.value.name}}</span> {{value.variant.measurement_value}}<span v-if="value.variant.units !== null">{{value.variant.units.suffix}}</span>
															
															
														
														
													</b-col>
												</b-row>
												<b-row>
													<b-col md="2" class="d-flex offset-md-8">
														<span class="ml-auto my-auto">Quantity: {{combination.quantity}}</span>
													</b-col>
													<b-col md="2" class="d-flex">
														<span class="badge badge-light bg-light ml-auto mt-2 font-size-14">
														<span v-if="combination.price_difference > 0">+</span><span v-else>-</span><span>£</span>{{combination.price_difference}}
															</span>
													</b-col>
												</b-row>
												</b-card>
												</a>
											</div>
											</b-card>
										</b-col>
								       </b-row>

									</b-list-group-item>
									<b-list-group-item class="border-top d-flex">
										<div class="font-italic">Item(s) Total</div>
										<div class="my-auto text-bold ml-auto"><strong>£</strong>{{(company.company_order.total_order_exc - company.company_order.total_shipping_exc).toFixed(2)}}</div>
									</b-list-group-item>
									<b-list-group-item class="border-top d-flex">
										<div class="font-italic">Customisations Total</div>
										<div class="my-auto text-bold ml-auto"><strong>£</strong>{{(parseFloat(company.company_order.total_combinations_exc) + parseFloat(company.company_order.total_variants_exc)).toFixed(2)}}</div>
									</b-list-group-item>
									<b-list-group-item class="border-top d-flex">
										<div class="font-italic">Shipping</div>
										<div class="my-auto text-bold ml-auto"><strong class="text-primary">+£</strong>{{parseFloat(company.company_order.total_shipping_inc).toFixed(2)}}</div>
									</b-list-group-item>
									<b-list-group-item class="border-top d-flex">
										<div class="font-italic">Taxes</div>
										<div class="my-auto text-bold ml-auto"><strong class="text-primary">+£</strong>{{(company.company_order.total_order_inc - company.company_order.total_order_exc).toFixed(2)}}</div>
									</b-list-group-item>
									<b-list-group-item class="border-top d-flex">
										<div class="my-auto text-bold ml-auto"><p class="font-size-18"><strong class="text-primary">£</strong>{{parseFloat(company.company_order.total_order_inc).toFixed(2)}}
											</p>
										</div>
									</b-list-group-item>
								</b-list-group>

								<a class="btn btn-primary btn-rounded mr-1" v-if="company.company_order.order_paid" @click="order_receipt_item = company" v-b-modal.invoice-modal>Download & Print Seller Receipt</a>
								<a class="btn btn-primary btn-rounded mr-1" v-else>Pay for Order</a>
								<a class="btn btn-light btn-rounded mr-1" v-if="company.company_order.invoice_created">Print Invoice</a>
								<a class="btn btn-primary btn-rounded mr-1" v-if="company.company_order.invoice_created">Download Invoice</a>
								<a class="btn btn-primary btn-rounded mr-1" v-if="(company.company_order.invoice_created == 1) && (order_paid == 1)">Request Invoice</a>
							</b-card>
						</b-card>
					</b-col>
				</b-row>
			</b-card>
		</b-col>
		<b-col md="4">
			<b-card class="border-radius-1em">
				<b-list-group flush>
				<b-list-group-item class="d-flex flex-column w-100">
				<h4 class="card-title mb-3">Order Reference</h4>
				<span>{{order.reference}}</span>
			</b-list-group-item>

				<b-list-group-item class="d-flex w-100 flex-column"><h4 class="card-title mb-3">Transaction Reference</h4>
				<span>{{order.reference}}</span></b-list-group-item>

				<b-list-group-item class="d-flex w-100 flex-column"><h4 class="card-title mb-3">Order Status</h4>
				<span class="badge badge-light bg-light font-size-14 px-3 py-2 btn-rounded mr-auto">
					<div v-if="order.paid == 1">
						Order Paid
						<i class="bx bx-check-circle text-success"></i>
					</div>
					<div v-else>
						Order Not Paid
						<i class="bx bx-x-circle text-danger"></i>
					</div>
				</span></b-list-group-item>
			</b-list-group>
		</b-card>
		<b-card class="border-radius-1em">
			<b-list-group flush>
				<b-list-group-item class="d-flex w-100"><h4 class="card-title mb-3">Payment Summary</h4>
				</b-list-group-item>
         	</b-list-group>
				<b-list-group flush>
					<b-list-group-item class="d-flex w-100">
						<h6 class="mt-auto">Order Total <small>Exc. Taxes</small></h6>
						<h4 class="my-auto ml-auto text-muted"><small>£{{parseFloat(order.total_order_exc).toFixed(2)}}</small></h4>
					</b-list-group-item>
					<b-list-group-item class="d-flex w-100">
						<h6 class="mt-auto">Shipping Total</h6>
						<h4 class="my-auto ml-auto text-muted"><small>£{{parseFloat(total_shipping_inc).toFixed(2)}}</small></h4>
					</b-list-group-item>
					<b-list-group-item class="d-flex w-100">
						<h6 class="mt-auto">Taxes</h6>
						<h4 class="my-auto ml-auto text-muted"><small>£{{parseFloat(order.total_order_inc - order.total_order_exc).toFixed(2)}}</small></h4>
					</b-list-group-item>
					<b-list-group-item class="d-flex w-100">
						<h6 class="mt-auto">Total <small>inc. Taxes</small></h6>
						<h4 class="my-auto ml-auto text-muted"><small>£{{(parseFloat(order.total_order_inc) + parseFloat(total_shipping_inc)).toFixed(2)}}</small></h4>
					</b-list-group-item>
				</b-list-group>
			</b-card>
		
			<a class="btn btn-primary btn-block btn-rounded mb-3" v-if="order.paid"  v-b-modal.gemesys-invoice-modal>Download & Print Order Receipt</a>
			<a class="btn btn-primary btn-block btn-rounded mb-3" v-else>Pay for Order Now</a>
		</b-col>
	</b-row>
		<b-modal id="modal-1" centered content-class="border-radius-1em" header-class="border-none" hide-footer>
			<b-card-body class="card-body pt-0">
            <div class="row">
            	<div class="col-sm-12 pb-4 text-center mb-4">
            		<h3>You are about to {{form.action}}</h3>
            	</div>
              <div class="col-sm-12 pb-4 text-center border border-radius-1em">
                <div class="avatar-md profile-user-wid mb-2 mx-auto">
                  <img
                  	v-if="form.seller.logo" :src="storageurl + form.seller.logo"
                    alt
                    class="img-thumbnail rounded-circle"
                  />
                   <img
                  	v-else 
                    src="/images/product-placeholder.png"
                    alt
                    class="img-thumbnail rounded-circle"
                  />
                </div>
                <h5 class="font-size-15 text-truncate">{{form.seller.name}}</h5>
              </div>
           

            </div>
          </b-card-body>
    <b-form v-if="form.action == 'message'">
    	<b-form-group>
    		<b-form-input class="underline-input my-2" placeholder="First Name" type="text"></b-form-input>
    	</b-form-group>
    	<b-form-group>
    		<b-form-input class="underline-input my-2" placeholder="Last Name" type="text"></b-form-input>
    	</b-form-group>
    	<b-form-group>
    		<b-form-input class="underline-input my-2" placeholder="Email" type="text"></b-form-input>
    	</b-form-group>
    	<b-form-group>
    		<b-form-input class="underline-input my-2" placeholder="Telephone" type="text"></b-form-input>
    	</b-form-group>
    	<b-form-group>
    		<b-form-textarea
    		class="underline-input my-2"
		      id="textarea"
		      placeholder="Enter something..."
		      rows="4"
		    ></b-form-textarea>
    	</b-form-group>
    	<a class="btn btn-outline-light btn-rounded btn-block mt-3" href="javascript:;">Send Message</a>
    </b-form>

    <b-form v-if="(form.action == 'review')  && (user)" class="px-2">
    	<b-form-group class="text-center">
    	<label class="mt-3 text-center">Your Details</label>
    </b-form-group>
    	<b-form-group>
    		<b-form-input class="underline-input my-2" placeholder="First Name" type="text"></b-form-input>
    	</b-form-group>
    	<b-form-group>
    		<b-form-input class="underline-input my-2" placeholder="Last Name" type="text"></b-form-input>
    	</b-form-group>
    	<b-form-group>
    		<b-form-input class="underline-input my-2" placeholder="Email" type="text"></b-form-input>
    	</b-form-group>
    	<b-form-group class="text-center">
    		 <label for="rating-lg-no-border" class="mt-3">Your Rating</label>
    			<b-form-rating id="rating-lg-no-border" variant="warning" v-model="form.review_score" class="text-center" no-border size="lg"></b-form-rating>
    	</b-form-group>
    	<b-form-group>
    		<b-form-textarea
    		class="underline-input my-2"
		      id="textarea"
		      placeholder="Enter review comments"
		      rows="4"
		    ></b-form-textarea>
    	</b-form-group>
    	<a class="btn btn-outline-light btn-rounded btn-block mt-3" href="javascript:;">Submit Review</a>
    </b-form>
    <b-form v-if="(form.action == 'favourites')  && (user !== null)" class="px-2">
    </b-form>
  </b-modal>
  		<b-modal id="invoice-modal" size="xl" centered content-class="border-radius-1em" header-class="border-none" hide-footer>
			<OrderReceipt v-if="order_receipt_item !== ''" :order="order" :company_order="order_receipt_item" :company="order_receipt_item.company_order.company" @process-pdf="generateReport(), $bvModal.hide('invoice-modal')" @print-invoice="printInvoice(), $bvModal.hide('invoice-modal')" @enable-download="invoiceDownload = $event"></OrderReceipt>
		</b-modal>
		<vue-html2pdf
		 		id="invoiceArea1"
        :show-layout="showInvoiceLayout"
        :float-layout="true"
        :enable-download="invoiceDownload"
        :preview-modal="true"
        :paginate-elements-by-height="1400"
        :filename="order.uid"
        :pdf-quality="2"
        :manual-pagination="false"
        pdf-format="a4"
        pdf-orientation="portrait"
        :margin="2"
        ref="html2Pdf"
    >
        <section slot="pdf-content">
           <OrderReceipt v-if="order_receipt_item !== ''" :order="order" :company_order="order_receipt_item" :company="order_receipt_item.company_order.company" :printable="true" ></OrderReceipt>
        </section>
    </vue-html2pdf>
    	<b-modal id="gemesys-invoice-modal" size="xl" centered content-class="border-radius-1em" header-class="border-none" hide-footer>
			<GemesysOrderReceipt :company="$attrs.company" :order="order"  @process-pdf="gemesysGenerateReport(), $bvModal.hide('gemesys-invoice-modal')" @print-invoice="gemesysPrintInvoice(), $bvModal.hide('gemesys-invoice-modal')" @enable-download="invoiceDownload = $event" ></GemesysOrderReceipt>
		</b-modal>
		<vue-html2pdf
		 		id="invoiceArea"
        :show-layout="showInvoiceLayout"
        :float-layout="true"
        :enable-download="invoiceDownload"
        :preview-modal="true"
        :paginate-elements-by-height="1400"
        :filename="order.uid"
        :pdf-quality="2"
        :manual-pagination="false"
        pdf-format="a4"
        pdf-orientation="portrait"
        :margin="2"
        ref="gemesyshtml2Pdf"
    >
        <section slot="pdf-content">
           <GemesysOrderReceipt :company="$attrs.company" :order="order" :printable="true" ></GemesysOrderReceipt>
        </section>
    </vue-html2pdf>
</b-container>
</template>