<script type="text/javascript">
	import { EventBus } from '../../../../public-app'
	export default{
		props: {
			user: {
				type: Object,
				default: null,
			},
			use: {
				type: String,
				default: 'public',
			},
			index: {
				type: Number,
				default: -1,
			},
		},
		data(){
			return {
				storageurl: '',
				hoverIndex: -1,
				hoverLink: -1,
				categories: [],
				linkprefix: '',
				linksuffix: '',
			}
		},
		mounted(){
			if(this.use !== ''){
		   		if(this.use == 'public'){
		   			this.storageurl = '.s3.eu-west-2.amazonaws.com';
		   			this.linkprefix = '/seller/';
		   			this.linksuffix = '/product';
		   		}else if(this.use == 'website'){
		   			this.storageurl = '.s3.eu-west-2.amazonaws.com';
		   			this.linkprefix = '';
		   			this.linksuffix = '/product';
		   		}
		   	}
		},
		methods: {
			
			addTax(product, price){
        		var tax_rule = product.tax_rule;
        		console.log(tax_rule);
		    	if((this.user !== null && this.user !== '') && (this.user.default_delivery_address !== '' && this.user.default_delivery_address !== null)){
		    		var country_id = this.user.default_delivery_address.country_id;
		    	}else{
		    		var country_id = product.company.country_id;
		    	}
		    	if(tax_rule !== null && tax_rule.country_rules.length > 0){
		    		var selected_rule = null;
		    		var selected_ruleIndex = -1;
			    	for(var ti = 0; ti < tax_rule.country_rules.length; ti++){
			    		selected_ruleIndex = tax_rule.country_rules[ti].countries.findIndex(item => item == country_id);
			    		if(selected_ruleIndex !== -1){
			    			selected_rule = tax_rule.country_rules[ti];
			    			ti = tax_rule.country_rules.length;
			    		}
			    	}
			    	console.log(selected_rule);
			    	if(tax_rule.type == 1){
		        		if(selected_rule !== null){
		        			var percentage = (parseFloat(selected_rule.value) / 100) + 1;
		        			return price * percentage;
		         		}else{
		         			return price * 1.2;
		         		}
	         		}else{
	         			if(selected_rule !== null){
		        			return price + selected_rule.value;
		         		}else{
		         			return price * 1.2;
		         		}
	         		}
         		}else if(tax_rule !== null && tax_rule.country_rules.length > 0){
         			var percentage = (parseFloat(tax_rule.percentage) / 100) + 1;
		        	return price * percentage;
         		}else {
		        	return price * 1.2;
         		}
        	},
		}
	}
</script>
<template>
	<router-link :to="'/seller/'+$attrs.product.company.nickname+'/product/'+$attrs.product.url_rewrite">
					<b-card no-body class="border shadow-none border-radius-1em" @mouseenter="hoverIndex = index" @mouseleave="hoverIndex = -1" >
						
						<b-row no-gutters>
							<transition name="fade-left">
		                    	<div class="col-md-6 position-absolute z-index-2" v-if="hoverIndex == index"> 
                              	<router-link id="tooltip-add"  class="mt-3 ml-3 btn btn-primary font-size-16 btn-rounded ml-auto float-left" :to="'/seller/'+$attrs.product.company.nickname+'/product/'+$attrs.product.url_rewrite"><i class="mdi mdi-eye-outline"></i></router-link>
                              </div>
                          </transition>
                          <transition name="fade-right">
		                    	<div class="col-md-6 position-absolute z-index-2 right-0" v-if="hoverIndex == index"> 
                              	<router-link id="tooltip-add"  class="mt-3 mr-2 float-right" :to="'/seller/'+$attrs.product.company.nickname+'/profile'">
                              		<img
                                            		
                                                :src="'https://'+$attrs.company.nickname+storageurl + $attrs.product.company.logo.replaceAll('//', '/').replaceAll('https:/'+$attrs.product.company.nickname+'.s3.eu-west-2.amazonaws.com', '')+'?s='+$attrs.product.company.nickname+''"
                                                alt=""
                                                class="avatar-sm rounded-circle"
                                            />
                              	</router-link>
                              </div>
                          </transition>
						 <b-col lg="12" v-if="use == 'public'">
		                        <router-link v-if="typeof $attrs.product.cover_image !== 'string' && $attrs.product.cover_image !== null" :to="linkprefix+($attrs.product.company !== null && use == 'public' ? $attrs.product.company.nickname : '' )+linksuffix+'/'+$attrs.product.url_rewrite">
				            	<b-card-img-lazy v-if="'gallery' in $attrs.product.cover_image" :src="'https://'+$attrs.company.nickname+storageurl+$attrs.product.cover_image.gallery.replaceAll('//', '/')+'?s='+$attrs.product.company.nickname" :class="{'border-radius-1em' : roundedBorders}"></b-card-img-lazy>
				            	<b-card-img-lazy v-else-if="'thumb' in $attrs.product.cover_image" :src="'https://'+$attrs.company.nickname+storageurl+$attrs.product.cover_image.thumb.replaceAll('//', '/')+'?s='+$attrs.product.company.nickname" :class="{'border-radius-1em' : roundedBorders}"></b-card-img-lazy>
				            	<b-card-img-lazy v-else-if="'src' in $attrs.product.cover_image" :src="'https://'+$attrs.company.nickname+storageurl+$attrs.product.cover_image.src.replaceAll('//', '/')+'?s='+$attrs.product.company.nickname" :class="{'border-radius-1em' : roundedBorders}"></b-card-img-lazy>
				            	<b-card-img-lazy v-else :src="'https://'+$attrs.company.nickname+storageurl+$attrs.product.cover_image.replaceAll('//', '/')+'?s='+$attrs.product.company.nickname" :class="{'border-radius-1em' : roundedBorders}"></b-card-img-lazy>
				            	</router-link>
				            	<router-link v-else :to="linkprefix+($attrs.product.company !== null && use == 'public' ? $attrs.product.company.nickname : '' )+linksuffix+'/'+$attrs.product.url_rewrite">
				            	<b-card-img-lazy src="/images/product-placeholder.png" :class="{'border-radius-1em' : roundedBorders}"></b-card-img-lazy>
				            	</router-link>
		                   <transition name="fade-right">

		                         <p  v-if="hoverIndex == index" class="text-muted position-absolute bottom-0 right-0 mr-4 pr-2 mb-3">
		                         	<b-form-rating style="width: 40%;" id="rating-inline" readonly variant="warning" class="bg-transparent pl-4 ml-auto" inline no-border size="sm" :value="$attrs.product.reviews_avg_rating"></b-form-rating> 
			                
			                  </p>
			              			</transition>
		                      </b-col>
		                      <b-col lg="12" v-else >
		                        <a v-if="typeof $attrs.product.cover_image !== 'string' && $attrs.product.cover_image !== null" :href="linkprefix+($attrs.product.company !== null && use == 'public' ? $attrs.product.company.nickname : '' )+linksuffix+'/'+$attrs.product.url_rewrite">
				            	<b-card-img-lazy v-if="'gallery' in $attrs.product.cover_image" :src="'https://'+$attrs.company.nickname+storageurl+$attrs.product.cover_image.gallery" :class="{'border-radius-1em' : roundedBorders}"></b-card-img-lazy>
				            	<b-card-img-lazy v-else-if="'thumb' in $attrs.product.cover_image" :src="'https://'+$attrs.company.nickname+storageurl+$attrs.product.cover_image.thumb" :class="{'border-radius-1em' : roundedBorders}"></b-card-img-lazy>
				            	<b-card-img-lazy v-else-if="'src' in $attrs.product.cover_image" :src="'https://'+$attrs.company.nickname+storageurl+$attrs.product.cover_image.src" :class="{'border-radius-1em' : roundedBorders}"></b-card-img-lazy>
				            	<b-card-img-lazy v-else :src="'https://'+$attrs.company.nickname+storageurl+$attrs.product.cover_image" :class="{'border-radius-1em' : roundedBorders}"></b-card-img-lazy>
				            	</a>
				            	<a v-else :href="linkprefix+($attrs.product.company !== null && use == 'public' ? $attrs.product.company.nickname : '' )+linksuffix+'/'+$attrs.product.url_rewrite">
				            	<b-card-img-lazy src="/images/product-placeholder.png" :class="{'border-radius-1em' : roundedBorders}"></b-card-img-lazy>
				            	</a>
		                     <transition name="fade-right">

		                         <p  v-if="hoverIndex == index" class="text-muted float-right ml-auto my-auto">
		                         	<b-form-rating style="width: 40%;" id="rating-inline" readonly variant="warning" class="bg-transparent pl-4 ml-auto" inline no-border size="sm" :value="$attrs.product.reviews_avg_rating"></b-form-rating> 
			                
			                  </p>
			              			</transition>
		                      </b-col>
		                  </b-row>
						
						
              				
						<b-card-text class="p-4 peach-border-top-5 d-flex flex-column">
							<h3 class="font-size-18 my-auto py-2">{{$attrs.product.name}}</h3>
							<p class="text-dark paragraph-truncate-line2 mb-0" v-html="$attrs.product.short_description"></p>
							<div class="d-flex">
							<span class="badge badge-light text-dark bg-light font-size-14 my-2" v-if="'sales_price' in $attrs.product">
								<span class="currency">{{$attrs.selected_currency_symbol}}</span>{{(addTax($attrs.product, $attrs.product.sales_price) * (1 / $attrs.exchange_rates[$attrs.product.company.trading_currency_code])).toFixed(2) }}
							</span>
							
						<transition name="fade-right">
							<router-link v-if="hoverIndex == index" class="border-bottom border-primary text-uppercase font-size-12 ml-auto text-dark my-auto py-2" :to="'/seller/'+$attrs.product.company.nickname+'/product/'+$attrs.product.url_rewrite">
								See Product <i class="bx bx-chevron-right"></i>
							</router-link>
						</transition>
							</div>
						</b-card-text>
					</b-card>
				</router-link>
</template>