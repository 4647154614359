<script>
import Layout from '../../layouts/main'
import PageHeader from '../../components/page-header'
import DateTime from 'luxon/src/datetime.js';
import SideSlidePanel from "../../components/widgets/SideSlidePanel"
import SearchBar from "../../components/widgets/searchBar"
import EditModal from "../../components/widgets/editModal"
import AccessRestricted from "../../views/utility/access-restricted";
import Multiselect from "vue-multiselect";
/**
 * Shops component
 */
export default {
  components: { Layout, PageHeader, SideSlidePanel, SearchBar, EditModal, DateTime, AccessRestricted, Multiselect },
  data() {
    return {
      success_message: false,
      error_reponse: '',
      error_message: false,
      loadingForm: false,
      deleteMode: 'single',
      shopsData: '',
      productsData: [],
      showListingProducts: 0,
      addProductToListing: 0,
      selectedListing:[],
      searchproduct: '',
      shops_error: [],
      company_info: [],
      categoryProducts: [],
      productsLimit: '100',
      productsCurrentPage: 1,
      productListingSelected: [],
      newquantity: 0,
      listingquantity: 0,
      transferquantity: 0,
      currentPage: 1,
      orderDesc: false,
      priceRange: [0,999999],
      trade_productsactive: '',
      trade_active: '',
      public_active: '',
      public_productsactive: '',
      totalListingsQuantity: 0,
      editModal: 0,
      productsactive: '',
      brands: '',
      collections: '',
      active: '',
      view: 'list',
      search: '',
      limit: '25',
      sortby: 'id',
      zones: [],
      order: 'desc',
      rule: {
        uid: '',
        id: '',
        name: '',
        description: '',
        code: '',
        type: '',
        company_id: '',
        country_rules: []
      },
      ruleRaw: {
        company_id: '',
        active: 0,
        uid: '',
        id: '',
        name: '',
        description: '',
        code: '',
        type: '',
        company_id: '',
        country_rules: []
      },
      countryrule: {
          zones: [],
          countries: [],
          value: '',
          editable: 1,
      },
      selectedids: [],
      addPanel: false,
      isCustom: 0,
      dropzoneOptions: {
            url: 'https://httpbin.org/post',
            thumbnailHeight: 300,
            thumbnailWidth: 300,
          },
          limits: [
            {value: 25, text: "25"},
            {value: 50, text: "50"},
            {value: 100, text: "100"},
          ],
          transferOptions: [
            {value: 0, text: "Company Stock"},
            {value: 1, text: "Another Shop or Listing"},
          ],
          transferOption: 0,
      title: 'Tax Rules',
      items: [
        {
          text: 'Orders',
          active: true,
        },
        {
          text: 'Tax Rules',
          active: true,
        },
      ],
    }
  },
  methods: {
    editTaxRule(list){
      this.rule = list;
      for(var i = 0; i < this.rule.country_rules.length; i++){
        if( 'id' in this.rule.country_rules[i] ){
          //console.log('Assembling tax rules');
          var zone = this.zones.filter(item => item.id == this.rule.country_rules[i].country_zone_id);
          var countries = [];
          if(this.rule.country_rules[i].countries.length > 0 && Object.keys(this.rule.country_rules[i].countries[0]).includes('id') == false){
          for(var ci = 0; ci < this.rule.country_rules[i].countries.length; ci++){
             console.log('Assembling tax rules');
            var country = '';
            country = zone[0]["countries"].filter(item => item.id == this.rule.country_rules[i].countries[ci]);
            countries.push(country[0]);
            //this.rule.country_rules[i].countries.push(country[0]);
          }
          console.log(countries);
          this.rule.country_rules[i].countries = countries;
          }
        }
      }
      this.addPanel = true;
    },
    createTaxRule(){
      this.loadingForm = true;
      axios.post('/api/company/order/tax-rule/create', this.rule, {headers: {
        'Authorization' : 'Bearer '+this.$attrs.user.api_token
      }}).then(response => {
        this.success_message = true;
        setTimeout(function() {
                self.shopsData.data.push(response.data);
                self.loadingForm = false;
        }, 400);
      }).catch(error => {
           this.error_reponse = error.response.data.errors;
        this.error_message = true;
        //this.resetProjectData();
        let self = this;
          setTimeout(function(){
            self.loadingForm = false;
          }, 1500);
      });
    },
    updateTaxRule(){
      this.loadingForm = true;
      axios.post('/api/company/order/tax-rule/'+this.rule.id+'/update', this.rule, {headers: {
        'Authorization' : 'Bearer '+this.$attrs.user.api_token
      }}).then(response => {
        this.success_message = true;
        setTimeout(function() {
                self.shopsData.data.push(response.data);
                self.loadingForm = false;
        }, 400);
      }).catch(error => {
           this.error_reponse = error.response.data.errors;
        this.error_message = true;
        //this.resetProjectData();
        let self = this;
          setTimeout(function(){
            self.loadingForm = false;
          }, 1500);
      });
    },
    setActive(active){
              axios.post('/api/company/order/tax-rule/set-active', {ids: this.selectedids, active: active}, {headers: {
                'Authorization' : 'Bearer '+this.$attrs.user.api_token
              }}).then(response => {
                
                this.success_text = 'Congratulations, your action has been saved';
                this.success_message = true;
                if(this.selectedids.length > 1){
                  return this.productQuery();
                }else{
                  return true;
                }
                  
              }).catch(error => {

              });
      },
      bulkDestroyResource(){
            axios.post('/api/company/order/tax-rule/bulk-destroy', {ids: this.selectedids}, {headers: {
              'Authorization' : 'Bearer '+this.$attrs.user.api_token
            }}).then(response => {
                this.$bvModal.hide('delete-modal');     
                this.selectedids = [];
                return this.productQuery();
            }).catch(error => {

            });
     },
     bulkDuplicateResource(){
      axios.post('/api/company/order/tax-rule/duplicate', {ids: this.selectedids}, {headers : {
        'Authorization' : 'Bearer '+this.$attrs.user.api_token
      }}).then(response => {
        console.log(response);
        this.success_text = 'Congratulations, your collection(s) have been updated.';
        this.success_message = true;
        //this.resetProjectData();
        this.addPanel = false;
        let self = this;
          setTimeout(function(){
            self.loadingForm = false;
            self.productQuery();
            //self.$bvModal.hide('move-modal');
          }, 1500);
      }).catch( error => {
        
        this.error_reponse = error.response.data.errors;
        this.error_message = true;
        //this.resetProjectData();
        let self = this;
          setTimeout(function(){
            self.loadingForm = false;
          }, 1500);
      });
    },
    brandQuery(){
      axios.get('/api/company/listings?page='+this.currentPage+'&limit='+this.limit+'&sortby='+this.sortby+'&order='+this.order+'&search='+this.search, {headers: {
                  'Authorization': 'Bearer ' + this.$attrs.user.api_token
              }}).then(response => {
        this.shopsData = response.data;
      }).catch(error => {
        this.shops_error = error.data;
      });
    },
    getProducts(id){
      axios.get('/api/listing/'+id+'/products').then(response => {
        this.categoryProducts = response.data;
        //this.showListingProducts = 1;
      }).catch(error => {
        this.products_error = error.data;
      });
    },
    getSellerProducts(search_query){
      if(this.orderDesc == true){
        this.order = 'desc';
      }else{
        this.order = 'asc';
      }
      axios.get('/api/company/order/tax-rules?page='+this.productsCurrentPage+'&limit='+this.productsLimit+'&sortby='+this.sortby+'&order='+this.order+'&search='+search_query+'&trade_active='+this.trade_productsactive+'&public_active='+this.public_productsactive+'&active='+this.productsactive+'&brands='+this.brands+'&collections='+this.collections+'&pricerange='+this.priceRange, {headers: {
              'Authorization' : 'Bearer '+this.$attrs.user.api_token
            }}).then(response => {
        console.log(response);
        this.productsData = response.data.data;
      });
    },
    closeEditModal(){
      this.$refs['edit-modal'].hide()
    },
    selectCountries(index){
      this.rule.country_rules[index].countries = this.rule.country_rules[index].zones.countries
    },
    addToListing(product){
      this.productListingSelected = product;
      this.selectedProductListingInfo = product.listings;
      if(this.productListingSelected.listings.length > 0){
        var listing_qty = this.productListingSelected.listings.filter(item => (item.listing_id == this.selectedListing.id) && (item.product_id == this.productListingSelected.id));
        if(listing_qty.length > 0){
          this.listingquantity = listing_qty[0].quantity;
        }else{
          this.listingquantity = 0;
        }
      }else{
         this.listingquantity = 0;
      }
      this.$refs['edit-modal'].show();
    },
    getListingData(listing_id){
        return this.shopsData.data.filter(item => item.id == listing_id);
    },
    priceIncVat(price, tax_percentage){
      var tax = 1 + (tax_percentage / 100);
      return price * tax;
    },
     productQuery(){
      if(this.productsData != ''){
        if(this.limit > this.productsData.total){
          this.currentPage = 1;
        }
      }
      if(this.orderDesc == true){
        this.order = 'desc';
      }else{
        this.order = 'asc';
      }
      axios.get('/api/company/order/tax-rules?page='+this.currentPage+'&limit='+this.limit+'&sortby='+this.sortby+'&order='+this.order+'&search='+this.search+'&trade_active='+this.trade_active+'&public_active='+this.public_active+'&active='+this.active+'&brands='+this.brands+'&collections='+this.collections+'&pricerange='+this.priceRange, {headers: {
              'Authorization' : 'Bearer '+this.$attrs.user.api_token
            }}).then(response => {
        console.log(response);
        this.shopsData = response.data;
      });
    },
    getDate(date){
        var createdOn = DateTime.fromISO(date, { zone: 'utc'});
        console.log(createdOn);
        return createdOn.day+' '+createdOn.monthLong+' '+createdOn.weekYear+'';
      },
      filterSelected(id){
        if(this.selectedids.length > 0){
          var exists = this.selectedids.filter(item => item == id);
          if(exists.length > 0){
            return true;
          }else{
            return false
          }
        }else{
          return false;
        }
      },
      checkAll: function(){ 
                 this.isCheckAll = !this.isCheckAll; 
                 this.selectedids = []; 
                 if(this.isCheckAll){ 
                     // Check all 
                     for (var key in this.shopsData.data) {          
                        this.selectedids.push(this.shopsData.data[key].id); 
                     } 
                 } 
              }, 
      updateCheckall: function(){ 
                 if(this.selectedids.length == this.shopsData.data.length){
                    this.isCheckAll = true; 
                 }else{ 
                    this.isCheckAll = false; 
                 } 
      },
  },
  computed:{
    allProductListingQuantities: function(){
      if(this.productListingSelected.length !== 0){
          if(this.productListingSelected.listings.length > 0){
            this.totalListingsQuantity = 0;
            for(var i = 0; i < this.productListingSelected.listings.length; i++){
              //console.log(this.productListingSelected.listings[i].quantity);
              this.totalListingsQuantity = Number.parseFloat(this.totalListingsQuantity) + Number.parseFloat(this.productListingSelected.listings[i].quantity);
            }
            return this.totalListingsQuantity;
          }else{
            this.totalListingsQuantity = 0;
            return 0;
          }
      }else{
        return 0;
      }
    }
  },
  created(){
    //console.log(localStorage.getItem('default_company'));
    this.company_info = JSON.parse(localStorage.getItem('default_company'));
    this.productQuery();
  },
  mounted(){
    axios.get('/api/country/zones', {headers: {
                  'Authorization': 'Bearer ' + this.$attrs.user.api_token
              }}).then(response =>{
            this.zones = response.data;
        });
  },
}
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-md-12">

        <div class="row mb-3">
          <b-col md="12">
             <transition name="slide-error" mode="out-in">
                    <b-alert id="errors" variant="danger" :show="error_message == true"  dismissible @dismissed="error_message = !error_message">
                      <p class="font-size-14">Oops! Please correct the following errors in your submission</p>
                      <ul>
                        <li v-for="(error, key) in error_reponse">
                          {{error[0]}}
                        </li>
                      </ul>
                      <small>Please Try Again. If this error persists, please contact support.</small>
                    </b-alert>
                    
                  </transition>
          </b-col>
           <b-col md="12">
                    <transition name="slide-success" mode="out-in">
                  <b-alert id="success-message" variant="success" :show="success_message"  dismissible @dismissed="success_message = !success_message">
                      <p class="font-size-14">Congratulations, your action has been saved.</p>
                    </b-alert>
                  </transition>
                  </b-col>
          <div class="col-xl-1 col-sm-6">
            <div class="mt-2">
              <b-form-select class="form-control" v-model="limit" :options="limits" @input="productQuery()"></b-form-select>
            </div>
          </div>
         
          <div class="col-lg-2 col-sm-6 d-flex">
          <form class="my-auto">
            <small class="mx-auto my-2 text-center d-block">Results {{productsData.from}} - {{productsData.to}} of {{productsData.total}}</small>
          </form>
        </div>
        <div class="col-lg-5 col-sm-6 d-flex">
            <div class="mt-sm-0 form-inline w-100" >
              <div class="search-box mr-2 ml-auto">
                <div class="position-relative">
                  <input
                    type="text"
                    class="form-control w-100 border-0"
                    placeholder="Search name, reference or description..."
                    v-model="search"
                    @keyup.enter="searchFilter($event)"
                  />
                  <i class="bx bx-search-alt search-icon"></i>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-sm-6 d-flex">
            <div class="d-flex ml-auto">
              <ul class="nav nav-pills product-view-nav">
                <li class="nav-item">
                  <a class="nav-link" :class="{'active': showFilters == true }" href="javascript: void(0);" @click="showFilters = !showFilters">
                    <i class="bx bx-filter-alt"></i>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" :class="{'active': view == 'grid' }" href="javascript: void(0);" @click="view = 'grid'">
                    <i class="bx bx-grid-alt"></i>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" :class="{'active': view == 'list' }" href="javascript: void(0);" @click="view = 'list'">
                    <i class="bx bx-list-ul"></i>
                  </a>
                </li>
                <li class="nav-item">
                 <b-dropdown text="Bulk Actions" variant="primary" class="rounded">
                    <b-dropdown-item href="javascript: void(0);" @click="setActive(true)"><i class="bx bx-check-circle font-size-14 text-success"></i> Set Active</b-dropdown-item>
                      <b-dropdown-item href="javascript: void(0);" @click="setActive(false)"><i class="bx bx-x-circle font-size-14 text-danger"></i> Set Inactive</b-dropdown-item>
                      <b-dropdown-item href="javascript: void(0);" @click="bulkDuplicateResource()"><i class="bx bx-copy-alt font-size-14"></i> Duplicate</b-dropdown-item>
                      <b-dropdown-divider></b-dropdown-divider>
                      <b-dropdown-item href="javascript: void(0);" v-b-modal.delete-modal @click="deleteMode = 'bulk'"><i class="bx bx-trash-alt font-size-14"></i> Delete</b-dropdown-item>
                </b-dropdown>
                </li>
                <li class="nav-item">
                 <a
                    class="btn btn-success btn-rounded mb-2 mr-2 text-white"
                    href="javascript:;"
                    @click="rule = JSON.parse(JSON.stringify(ruleRaw)), addPanel = !addPanel"
                  >
                    <i class="mdi mdi-plus mr-1"></i> New
                  </a>
                </li>
              </ul>
              <div class="text-sm-right">
                  
                </div>
          </div>
        </div>
      </div>
        <div class="card" v-if="view == 'list'" >
          <div class="card-body p-0">
            <div class="table">
              <table class="table table-centered table-hover">

                <thead class="thead-light">
                  <tr>
                    <th scope="col" style="max-width: 100px;">
                      <div
                        class="form-check form-switch form-switch-sm mb-0 mx-auto p-0 mt-auto d-flex"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input mx-auto my-auto" 
                          type="checkbox"
                          id="SwitchCheckSizesm"
                         
                          v-model="isCheckAll"
                          @click='checkAll()'
                          name="check-all"
                        />
                    </div>
                    </th>
                    <th scope="col"><a :class="{'text-dark': sortby != 'order_no'}" href="javascript: void(0);" @click="orderDesc = !orderDesc,  sortby = 'order_no', brandQuery()">Name <i v-if="orderDesc == true" class="bx bx-caret-up"></i><i v-if="orderDesc == false" class="bx bx-caret-down"></i></a></th>
                    <th scope="col">Description</th>
                    <th scope="col">Country Restriction</th>
                    <th scope="col">Type <i class="mdi mdi-percent-outline"></i></th>
                    <th scope="col">Code</th>
                    <th scope="col" class="text-center">Active</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
               
                <tbody>
                  
                  <tr v-for="list in shopsData.data" :key="list.id" valign="middle">
                    <td>
                      <div class="text-center" >
                        <b-form-checkbox class="select-row-btn" v-model="selectedids" :value="list.id" button-variant="light" @input="updateSelected" button>

                          <i v-if="filterSelected(list.id)" class="bx bx-check"></i><span class="avatar-title rounded-circle bg-light text-primary avatar-sm" v-else>{{(list.name).slice(0,1)}}</span></b-form-checkbox>
                    </div>
                    </td>
                    <td>
                       <a :href="'/order/cart/view/'+list.id+''"><strong>{{list.name}}</strong></a>
                    </td>
                    <td>
                        <p class="m-0 p-0" v-html="list.description"></p>
                    </td>
                    <td>
                      <a class="btn btn-light rounded-circle">
                        <i class="bx bx-world font-size-18 text-primary"> </i>
                      </a>
                    </td>
                    <td>
                      {{list.type == 1 ? 'Percentage (%)' : 'Fixed Price (£)'}} 
                    </td>
                    <td>
                      {{list.code}}
                    </td>
                     <td class="text-center">
                      <div
                        class="form-check form-switch form-switch-sm mb-0 mt-auto d-flex p-0"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input mx-auto"
                          type="checkbox"
                          id="SwitchCheckSizesm"
                          v-model="list.active"
                          value="1"
                          unchecked-value="0"
                          name="active"
                        />
                        <label class="form-check-label" for="SwitchCheckSizesm" 
                          ></label
                        >
                    </div>
                    </td>
                    <td>
                      <ul class="list-inline font-size-20 contact-links mb-0">
                        <li class="list-inline-item"><a v-b-tooltip.hover title="Edit" placement="left" class="btn btn-outline-light btn-rounded" @click="editTaxRule(list)"><i class="bx bx-edit-alt"></i>
                          </a>
                        </li>
                      <li class="list-inline-item">
                        <a href="javascript:;" v-b-tooltip.hover title="View" placement="left" @click="selectedListing = list, getProducts(list.id), getProductsPanel = 1" class="btn btn-outline-light btn-rounded"><i class="mdi mdi-eye-outline"></i></a>
                      </li>
                        <li class="list-inline-item">
                           <b-dropdown v-b-tooltip.hover title="More Options" placement="left" dropleft class="more-options-dropdown btn btn-outline-light btn-rounded">
                        <template v-slot:button-content>
                           <i class="bx bx-dots-horizontal-rounded"></i>
                        </template>
                         <b-dropdown-item href="javascript: void(0);" @click="setActive(true)"><i class="bx bx-check-circle font-size-14 text-success"></i> Set Active</b-dropdown-item>
                            <b-dropdown-item href="javascript: void(0);" @click="setActive(false)"><i class="bx bx-x-circle font-size-14 text-danger"></i> Set Inactive</b-dropdown-item>
                            <b-dropdown-item href="javascript: void(0);" @click="selectedids = [], selectedids.push(list.id), bulkDuplicateResource()"><i class="bx bx-copy-alt font-size-14"></i> Duplicate</b-dropdown-item>
                            <!--  <b-dropdown-divider></b-dropdown-divider>
                            <b-dropdown-item href="javascript: void(0);" v-b-modal.delete-modal @click="deleteMode = 'bulk'"><i class="bx bx-trash-alt font-size-14"></i> Delete</b-dropdown-item>-->
                             <b-dropdown-item href="javascript: void(0);" v-b-modal.delete-modal @click="selectedids = [], selectedids.push(list.id), deleteMode = 'single'"><i class="bx bx-trash-alt font-size-14"></i> Delete</b-dropdown-item>
                            </b-dropdown>
                      </b-dropdown>
                        </li>
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="row">
              <div class="col-lg-12">
                <b-pagination
              v-if="shopsData.last_page > 1"
              class="justify-content-center"
              pills
              v-model="currentPage"
              :total-rows="shopsData.total"
              :per-page="shopsData.per_page"
              aria-controls="my-table"
              @input="brandQuery"
            ></b-pagination>
             <small class="mx-auto my-2 text-center d-block">Records {{shopsData.from}} - {{shopsData.to}} of {{shopsData.total}} // Page {{shopsData.current_page}} of {{shopsData.last_page}}</small>
              </div>
            </div>
          </div>
      </div>
    <div class="row" v-if="view == 'grid'" >
     <div class="col-xl-4"  v-for="list in shopsData.data" :key="list.id">
        <div class="card border-radius-1em">
          <div class="bg-primary bg-soft border-radius-top-right-1em border-radius-top-left-1em" :style="{'min-height' : '150px'}">
            <div class="row">
              
              <div class="col-12 align-self-end">
                <img v-if="list.thumbnail" :src="`${list.thumbnail}`" alt class="img-fluid border-radius-top-right-1em border-radius-top-left-1em" />
                
              </div>
            </div>
          </div>
          <div class="card-body pt-0">

            <div class="row">
              <div class="col-sm-12 text-center px-4 mb-2">
               <!-- <div class="avatar-xl profile-user-wid mb-4">
                  <img class="img-thumbnail rounded-circle h-100" :src="`/public/storage/${list.image}`" alt />
                </div>-->
                <div class="avatar-sm profile-user-wid mx-auto">
                   <div class="text-center" v-if="list.image">
                      <b-form-checkbox class="select-row-btn" v-model="selectedids" :value="list.id" button-variant="primary" @input="updateSelected" button>
                        <i v-if="filterSelected(list.id)" class="bx bx-check"></i>
                        <img v-else :src="`${list.image}`" alt class="avatar-sm img-thumbnail rounded-circle h-100" />
                      </b-form-checkbox>
                    </div>
                    <div class="text-center" v-else >
                        <b-form-checkbox class="select-row-btn" v-model="selectedids" :value="list.id" button-variant="primary" @input="updateSelected" button>

                          <i v-if="filterSelected(list.id)" class="bx bx-check"></i><span class="avatar-title rounded-circle" v-else>{{(list.name).slice(0,1)}}</span></b-form-checkbox>
                    </div> 
                </div>
                <h4 class="font-size-18 text-truncate mt-3">{{list.name}}</h4>
                <p class="text-muted mb-0 text-truncate" v-html="list.description"></p>
              </div>

              <div class="col-sm-12">
              
              </div>
            </div>
          </div>
          <div class="card-footer  bg-transparent border-top">
                  <div class="d-flex">
                    
                    <a  @click="editTaxRule(list)" class="btn btn-outline-light font-size-16 btn-rounded my-auto"><i class="bx bx-pencil"></i></a>
                    <a class="btn btn-outline-light font-size-16 btn-rounded ml-2 my-auto" v-b-tooltip.hover title="Delete" placement="left"  @click="selectedids = [], selectedids.push(list.id), deleteMode = 'single'" v-b-modal.delete-modal><i class="bx bx-trash-alt"></i>
                          </a>
                         <b-dropdown v-b-tooltip.hover title="More Options" placement="left"  variant="outline-light"  dropup class="more-options-dropdown btn-rounded dropup ml-2" menu-class="dropdown-menu-right">
                   <template #button-content>
                        <i class="bx bx-dots-horizontal-rounded font-size-18"></i>
                      </template>
                      <!--<b-dropdown-item :disabled="list.default == false" href="javascript: void(0);" @click="setActive(true)"><i class="bx bx-star font-size-14 text-warning"></i> Make Default</b-dropdown-item>-->
                            <b-dropdown-item href="javascript: void(0);" @click="setActive(true)"><i class="bx bx-check-circle font-size-14 text-success"></i> Set Active</b-dropdown-item>
                            <b-dropdown-item href="javascript: void(0);" @click="setActive(false)"><i class="bx bx-x-circle font-size-14 text-danger"></i> Set Inactive</b-dropdown-item>
                            <b-dropdown-divider></b-dropdown-divider>
                      <b-dropdown-item href="javascript: void(0);"  @click="selectedids = [], selectedids.push(list.id), bulkDuplicateResource()">Duplicate</b-dropdown-item>
                     
                      <b-dropdown-item href="javascript:;"  v-b-modal.delete-modal @click="selectedids = [], selectedids.push(list.id), deleteMode = 'single'">
                        Delete
                      </b-dropdown-item>
                    </b-dropdown>
                   
                      <div
                        class="form-check form-switch form-switch-sm my-auto ml-auto"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          :id="`SwitchCheckSize-${list.id}`"
                          v-model="list.active"
                          :value="list.active"
                          name="active"
                          
                          @change="selectedids = [], selectedids.push(list.id), setActive(list.active)"
                        />
                        <label class="form-check-label" :for="`SwitchCheckSize-${list.id}`" 
                          >Active</label
                        >
                    </div>
                  </div>
          </div>
        </div>
    </div>

              <div class="col-lg-12">
                <b-pagination
              v-if="shopsData.data.length > 0"
              class="justify-content-center"
              pills
              v-model="currentPage"
              :total-rows="shopsData.total"
              :per-page="shopsData.per_page"
              aria-controls="my-table"
              @input="brandQuery"
            ></b-pagination>
             <small class="mx-auto my-2 text-center d-block">Records {{shopsData.from}} - {{shopsData.to}} of {{shopsData.total}} // Page {{shopsData.current_page}} of {{shopsData.last_page}}</small>
              </div>
      </div>
    </div>
    </div>
    <!--  end row -->

    <div class="row">
      <!-- end col-->
    </div>

        <b-modal id="delete-modal" size="md" hide-footer hide-header centered>
        <b-card class="border-radius-1em border shadow-none">
          <h4>Are you sure?</h4>
          <p v-if="deleteMode == 'bulk'">You are about to delete {{selectedids.length}} products. Are you sure you want to do this? This action is not reversable</p>
           <p v-if="deleteMode == 'single'">You are about to delete this category. Are you sure you want to do this? This action is not reversable</p>

           <div class="d-flex">
            <a class="btn btn-danger" v-if="deleteMode == 'bulk'" @click="bulkDestroyResource">
              Delete
            </a>
             <a class="btn btn-danger"  v-if="deleteMode == 'single'"  @click="bulkDestroyResource">
              Delete
            </a>
            <a class="btn btn-success" @click="$bvModal.hide('delete-modal')">
              Cancel
            </a>
          </div>
        </b-card>
      </b-modal>


    <SideSlidePanel :editPanel="showListingProducts" :panelClass="'edit-sidepanel-large'" @close-panel="showListingProducts = 0">
      <div class="row">
        
      </div>
    </SideSlidePanel>

    <SideSlidePanel :editPanel="addPanel" :panelClass="'edit-sidepanel-large'" @close-panel="addPanel =!addPanel">
      <b-row class="px-3">
        <b-col md="12" class="mb-2">
          <h3 class="d-flex mt-4">Add New Tax Rule</h3>
          <hr>
        </b-col>
        <b-col md="2">
          <h5 class="d-flex">Description <i class="bx bx-right-arrow-circle text-primary font-size-20 ml-2"></i></h5>
        </b-col>

        <b-col md="10">
          <b-card class="shadow-none border border-radius-1em">
            <b-form-group label="Name" class="mb-2">
              <b-form-input type="text" v-model="rule.name"></b-form-input>
            </b-form-group>
            <b-form-group label="Reference Code" class="mb-2">
              <b-form-input type="text" v-model="rule.code"></b-form-input>
            </b-form-group>
            <b-form-group label="Description" class="mb-2">
              <b-textarea v-model="rule.description" rows="3"></b-textarea>
            </b-form-group>
            <b-form-group label="Tax Type">
              <b-form-radio-group
                :options="[{text: 'Percentage (%)', value: 1}, {text: 'Fixed Value', value: 2}]"
                v-model="rule.type"
              >
              </b-form-radio-group>
            </b-form-group>
             
          </b-card>
        </b-col>

        <b-col md="2">
          <h5 class="d-flex">Zone & Country Rules <i class="bx bx-right-arrow-circle text-primary font-size-20 ml-2"></i></h5>
        </b-col>

        <b-col md="10">
          <b-card class="shadow-none border border-radius-1em" v-for="(country_rule, index) in rule.country_rules">
            <b-row >
              <b-col md="3">
                <b-form-group label="Country Zone" class="my-auto">
                <multiselect v-model="rule.country_rules[index].zones" track-by="name" label="name" :options="zones" :option-height="104" @input="selectCountries(index)" @remove="rule.country_rules[index].zones = {}">
                     <!--<template slot="singleLabel" slot-scope="{ option }">{{ option.name }} - {{ option.jobtitle }}</template>-->
                     <template slot="singleLabel" slot-scope="{ option }"><div class="d-flex w-100 h-100"><div class="avatar-sm mr-4 my-auto">
                        <span class="avatar-title rounded-circle">{{(option.name).slice(0,1)}}</span>
                      </div>
      <div class="option__desc d-flex flex-row w-75"><span class="option__title ml-2 my-auto w-100">{{ option.name }}<br><p class="m-0 lh-1 w-100 text-truncate"><small></small></p></span>
      </div>
      </div></template>
                <template slot="option" slot-scope="{ option }">
                  <div class="d-flex"><div class="avatar-sm mr-4">
                                    <span class="avatar-title rounded-circle">{{(option.name).slice(0,1)}}</span>
                                  </div>
                  <div class="option__desc d-flex flex-row w-50"><span class="option__title ml-2 my-auto w-100">{{ option.name }}<br><p class="m-0 lh-1 w-100 text-truncate"><small></small></p></span>
                  </div>
                  </div>
                </template>
                  </multiselect>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label="Countries" class="my-auto" v-if="'zones' in country_rule">
                <multiselect v-model="country_rule.countries" track-by="name" label="name" :options="country_rule.zones.countries" :option-height="104" multiple >
                     <!--<template slot="singleLabel" slot-scope="{ option }">{{ option.name }} - {{ option.jobtitle }}</template>-->
                     <template slot="singleLabel" slot-scope="{ option }"><div class="d-flex w-100 h-100"><div class="avatar-sm mr-4 my-auto">
                        <span class="avatar-title rounded-circle">{{option.iso_code}}</span>
                      </div>
                  <div class="option__desc d-flex flex-row w-75"><span class="option__title ml-2 my-auto w-100">{{ option.name }}<br><p class="m-0 lh-1 w-100 text-truncate"><small></small></p></span>
                  </div>
                  </div></template>
                <template slot="option" slot-scope="{ option }">
                  <div class="d-flex"><div class="avatar-sm mr-4">
                                    <span class="avatar-title rounded-circle">{{option.iso_code}}</span>
                                  </div>
                  <div class="option__desc d-flex flex-row w-50"><span class="option__title ml-2 my-auto w-100">{{ option.name }}<br><p class="m-0 lh-1 w-100 text-truncate"><small></small></p></span>
                  </div>
                  </div>
                </template>
                  </multiselect>
                  <b-form-select v-if="country_rule.zones.length == 0" class="form-control" value="" disabled>
                    <b-form-select-option value="">-- Select A Zone First --</b-form-select-option>
                  </b-form-select>
                </b-form-group>
              </b-col>
              <b-col md="2">
                 <b-form-group label="Amount" class="my-auto">
                  <b-input-group prepend="%" v-if="rule.type == 1">
                  <b-form-input type="number" v-model="country_rule.value"></b-form-input>
                  </b-input-group>
                  <b-input-group prepend="£" v-else>
                    <b-form-input type="number" v-model="country_rule.value"></b-form-input>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col md="1">
                <b-form-group label="Delete" class="my-auto text-center">
                 <a class="btn btn-danger" href="javascript:;" @click="rule.country_rules.splice(index, 1)">
                   <i class="bx bx-trash-alt"></i>
                 </a>
               </b-form-group>
              </b-col>
            </b-row>
 
          </b-card>
          <b-card class="shadow-none border border-radius-1em">
            <a class="btn btn-rounded btn-primary" @click="rule.country_rules.push(JSON.parse(JSON.stringify(countryrule)))"><i class="mdi mdi-plus"></i></a>
          </b-card>
        </b-col>
          <b-col md="2">
          <h5 class="d-flex">Status <i class="bx bx-right-arrow-circle text-primary font-size-20 ml-2"></i></h5>
        </b-col>

        <b-col md="10">
           <b-card class="shadow-none border border-radius-1em" body-class="d-flex">
          <b-form-group class="my-3">
                     <div
                        class="form-check form-switch form-switch-sm mb-0 mt-auto"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          id="newbrand-owner"
                          v-model="rule.active" value="true"
                          unchecked-value="false"
                        />
                        <label class="form-check-label" for="newbrand-owner" 
                          > Active</label
                        >
                    </div>
          </b-form-group>
          <input type="hidden" name="active" :value="rule.active">
          <a v-if="rule.id == ''" class="btn btn-success btn-rounded btn-block my-auto ml-4" @click="createTaxRule()">
            Save
          </a>
          <a v-else class="btn btn-success btn-rounded btn-block my-auto ml-4" @click="updateTaxRule()">
            Update
          </a>
        </b-card>
        </b-col>
      </b-row>
    </SideSlidePanel>
    <!-- end row -->
  </Layout>
</template>
