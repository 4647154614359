<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="row mb-3">
          <div class="col-xl-1 col-sm-6">
            <div class="mt-2">
              <b-form-select v-if="$attrs.showFilters == true" class="form-control" v-model="limit" :options="limits" @input="productQuery()"></b-form-select>
            </div>
          </div>
         
          <div class="col-lg-2 col-sm-6 d-flex">
          <form class="my-auto" v-if="$attrs.showFilters == true">
            <small class="mx-auto my-2 text-center d-block">Results {{$attrs.shopsData.from}} - {{$attrs.shopsData.to}} of {{$attrs.shopsData.total}}</small>
          </form>
        </div>
        <div class="col-lg-5 col-sm-6 d-flex">
            <div class="mt-sm-0 form-inline w-100" v-if="$attrs.showFilters == true" >
              <div class="search-box mr-2 ml-auto">
                <div class="position-relative">
                  <input
                    type="text"
                    class="form-control w-100 border-0"
                    placeholder="Search name, reference or description..."
                    v-model="search"
                    @keyup.enter="searchFilter($event)"
                  />
                  <i class="bx bx-search-alt search-icon"></i>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-sm-6 d-flex">
            <div class="d-flex ml-auto">
              <ul class="nav nav-pills product-view-nav">
                <li class="nav-item">
                  <a class="nav-link" :class="{'active': $attrs.showFilters == true }" href="javascript: void(0);" @click="$attrs.showFilters = !$attrs.showFilters">
                    <i class="bx bx-filter-alt"></i>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" :class="{'active': view == 'grid' }" href="javascript: void(0);" @click="view = 'grid'">
                    <i class="bx bx-grid-alt"></i>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" :class="{'active': view == 'list' }" href="javascript: void(0);" @click="view = 'list'">
                    <i class="bx bx-list-ul"></i>
                  </a>
                </li>
                <!--<li class="nav-item">
                 <b-dropdown text="Bulk Actions" variant="primary" class="rounded">
                  <b-dropdown-item href="javascript: void(0);">Action</b-dropdown-item>
                  <b-dropdown-item href="javascript: void(0);">Another action</b-dropdown-item>
                  <b-dropdown-item href="javascript: void(0);">Something else here</b-dropdown-item>
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-item href="javascript: void(0);">Separated link</b-dropdown-item>
                </b-dropdown>
                </li>-->
                
              </ul>
              <div class="text-sm-right">
                  
                </div>
          </div>
        </div>
      </div>
        <div class="card shadow-none border-radius-1em border-none" v-if="view == 'list'" >
          <div class="card-body p-0">
            <div class="table-responsive">
             

              <!--  <thead class="thead-light">
                  <tr>
                    <th scope="col">
                      <div
                        class="form-check form-switch form-switch-sm mb-0 mx-auto p-0 mt-auto d-flex"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input mx-auto my-auto" 
                          type="checkbox"
                          id="SwitchCheckSizesm"
                         
                          v-model="isCheckAll"
                          @click='checkAll()'
                          name="check-all"
                        />
                    </div>
                    </th>
                    <th scope="col"><a :class="{'text-dark': sortby != 'order_no'}" href="javascript: void(0);" @click="orderDesc = !orderDesc,  sortby = 'order_no', productQuery()">Order No. <i v-if="orderDesc == true" class="bx bx-caret-up"></i><i v-if="orderDesc == false" class="bx bx-caret-down"></i></a></th>
                    <th scope="col">Sellers</th>
                    <th scope="col">Source</th>-
                    <th scope="col">Products</th>
                    <th scope="col">Date</th>
                    <th scope="col">Total Exc.</th>
                    <th scope="col">Total Inc.</th>
                    <th scope="col">Paid</th>
                    <th scope="col">Payment Method</th>
                    <th scope="col">Status</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>-->
               
                <b-list-group>
                  
                  <b-list-group-item v-for="list in $attrs.shopsData.data" :key="list.id" valign="middle" class="d-flex px-4 py-3 border product-list-item mb-2 w-100 border-radius-1em list-group-item-action">
                    <div>
                    <div class="text-center" >
                       <b-form-checkbox class="select-row-btn" v-model="selectedids" :value="list.id" button-variant="light" @input="updateSelected" button>

                          <i v-if="filterSelected(list.id)" class="bx bx-check"></i><span class="avatar-title rounded-circle bg-light text-primary avatar-sm" v-else>{{(list.reference).slice(0,1)}}</span></b-form-checkbox>
                    </div> 
                    </div>
                    <div class="my-auto w-50 d-flex">
                      <a class="my-auto ml-2" href="javascript:;" @click="orderuid = list.uid, showOrder = 1"><span>#{{list.reference}}</span></a>
                      <div class=" mx-auto">
                     <a class="d-flex badge badge-light bg-outline-light text-dark border border-radius-1em my-1" v-for="item in list.company_order" :href="'/trade/profile/'+item.company.nickname"> 
                      <img v-if="item.company.logo" :src="'https://' + item.company.nickname + storageurl + item.company.logo" class="avatar-xs border rounded-circle"> 
                      <img v-else src="/images/product-placeholder.png" class="avatar-xs border rounded-circle">
                      <p class="my-auto ml-2">
                      {{item.company.name}}
                      </p>
                      </a>
                    </div>
                    </div>
                   
                    
                    
                     
                    <div class="my-auto mr-4">
                      <a href="javascript:;" v-b-tooltip.hover title="Stock" placement="left" @click="selectedListing = list, getOrderProducts(list), showListingProducts = 1" class="btn btn-outline-light btn-rounded"><i class="bx bx bx-purchase-tag-alt"></i></a>
                    </div>
                     <div class="my-auto mr-2">
                     <i class="bx bx-calendar"></i> {{getDate(list.created_at)}}
                    </div>
                     <div class="ml-auto my-auto mr-2">
                      <span class="currency">£</span>
                      {{list.total_order_exc}}
                    </div>
                    <div class="my-auto">
                      <span class="currency">£</span>
                      {{list.total_order_inc}}
                    </div>
                     
                    <div>
                       
                    </div>
                    <div>
                    </div>
                    <div class="my-auto">
                      <ul class="list-inline font-size-20 contact-links mb-0">
                      <li class="list-inline-item">
                        <a href="javascript:;" v-b-tooltip.hover title="View" placement="left" @click="orderuid = list.trade_orders[0].order.uid, showOrder = 1" class="btn btn-outline-light btn-rounded"><i class="mdi mdi-eye-outline"></i></a>
                      </li>
                        <!--<li class="list-inline-item">
                           <b-dropdown v-b-tooltip.hover title="More Options" placement="left" dropleft class="more-options-dropdown btn btn-outline-light btn-rounded">
                        <template v-slot:button-content>
                           <i class="bx bx-dots-horizontal-rounded"></i>
                        </template>
                        <b-dropdown-item>Action</b-dropdown-item>
                        <b-dropdown-item>Another Action</b-dropdown-item>
                        <b-dropdown-item>Something else here</b-dropdown-item>
                      </b-dropdown>
                        </li>-->
                      </ul>
                    </div>
                  </b-list-group-item>
                </b-list-group>
            </div>
            <div class="row">
              <div class="col-lg-12">
                <b-pagination
              v-if="$attrs.shopsData.last_page > 1"
              class="justify-content-center"
              pills
              v-model="$attrs.currentPage"
              :total-rows="$attrs.shopsData.total"
              :per-page="$attrs.shopsData.per_page"
              aria-controls="my-table"
              @input="$emit('change-page', $attrs.currentPage)"
            ></b-pagination>
             <small class="mx-auto my-2 text-center d-block">Records {{$attrs.shopsData.from}} - {{$attrs.shopsData.to}} of {{$attrs.shopsData.total}} // Page {{$attrs.shopsData.current_page}} of {{$attrs.shopsData.last_page}}</small>
              </div>
            </div>
          </div>
      </div>
    <div class="row" v-if="view == 'grid'" >
     <div class="col-xl-4"  v-for="list in $attrs.shopsData.data" :key="list.id">
        <div class="card overflow-hidden">
          <div class="bg-soft-primary min-h-100">
            <div class="row">
              
              <div class="col-12 align-self-end">
                <img :src="'/public/storage/'+list.banner" alt class="img-fluid" />
              </div>
            </div>
          </div>
          <div class="card-body pt-0">
            <div class="row">
              <div class="col-sm-12 text-center">
               <div class="avatar-md profile-user-wid mb-4 mx-auto text-center">
                  <img class="img-thumbnail rounded-circle h-100 mx-auto" :src="`/public/storage/${list.logo}`" alt />
                </div>
                <h4 class="font-size-18 text-truncate mt-3">#{{list.reference}}</h4>
                <p class="text-muted mb-0 text-truncate">{{list.customer.name}}</p>
              </div>

              <div class="col-sm-12">
                <div class="pt-4">
                  <div class="mt-4 d-flex">
                    <a href="javascript:;" @click="selectedListing = list, getProducts(list.id)" class="btn btn-outline-light btn-rounded"><i class="bx bx bx-purchase-tag-alt my-1"></i></a>
                    <b-tooltip target="tooltip-add">Add Product</b-tooltip>
                    <a id="tooltip-add" class="btn btn-outline-light font-size-16 btn-rounded ml-2 my-auto"><i class="bx bx-pencil"></i></a><b-tooltip target="tooltip-add">Add Product</b-tooltip>
                    <a href="javascript:;" v-b-tooltip.hover title="View" placement="left" @click="selectedListing = list, getProducts(list.id), getProductsPanel = 1" class="btn btn-outline-light btn-rounded ml-2"><i class="mdi mdi-eye-outline"></i></a>
                     <b-dropdown v-b-tooltip.hover title="More Options" placement="left" dropleft class="more-options-dropdown btn btn-outline-light btn-rounded mr-auto ml-2">
                        <template v-slot:button-content>
                           <i class="bx bx-dots-horizontal-rounded"></i>
                        </template>
                        <b-dropdown-item>Action</b-dropdown-item>
                        <b-dropdown-item>Another Action</b-dropdown-item>
                        <b-dropdown-item>Something else here</b-dropdown-item>
                      </b-dropdown>
                    <b-form-checkbox switch size="lg" class="my-auto" v-model="list.active" value="1"
                      unchecked-value="0"><small>Active</small></b-form-checkbox>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>

              <div class="col-lg-12">
                <b-pagination
              v-if="$attrs.shopsData.data.length > 0"
              class="justify-content-center"
              pills
              v-model="$attrs.currentPage"
              :total-rows="$attrs.shopsData.total"
              :per-page="$attrs.shopsData.per_page"
              aria-controls="my-table"
              @input="productQuery"
            ></b-pagination>
             <small class="mx-auto my-2 text-center d-block">Records {{$attrs.shopsData.from}} - {{$attrs.shopsData.to}} of {{$attrs.shopsData.total}} // Page {{$attrs.shopsData.current_page}} of {{$attrs.shopsData.last_page}}</small>
              </div>
      </div>
    </div>
    </div>
    <!--  end row -->

    <div class="row">
      <!-- end col-->
    </div>
    <SideSlidePanel :editPanel="showListingProducts" :panelClass="'edit-sidepanel'" @close-panel="showListingProducts = 0">
      <div class="row">
        <CompanyProductOrderList :resource="'order'" :product_list="categoryProducts" :resource_id="''" :default_resource_key="default_resource_key" :expanded="isExpanded" @remove-product="removeFromResource" ></CompanyProductOrderList>
      </div>
    </SideSlidePanel>

    <SideSlidePanel :editPanel="showOrder" :panelClass="'edit-sidepanel-fullwidth'" @close-panel="showOrder = 0">
      <b-row>
        <TradeOrderSummary v-if="orderuid !== ''" :uid="orderuid" :user="$attrs.user" ></TradeOrderSummary>
      </b-row>

    </SideSlidePanel>
    <!-- end row -->
  </div>
</template>

<script type="text/javascript">
  
import SideSlidePanel from "../../../../components/widgets/SideSlidePanel";
import TradeOrderSummary from "../../../../components/widgets/ecommerce/TradeOrderSummary";
import CompanyProductOrderList from "../../../../components/widgets/CompanyProductOrderList";
import DateTime from 'luxon/src/datetime.js';

    export default {
      components: {SideSlidePanel, TradeOrderSummary, CompanyProductOrderList, DateTime},
      data(){
        return {
            storageurl: '.s3.eu-west-2.amazonaws.com',
            shopsData: '',
            productsData: [],
            showOrder: 0,
            orderuid: '',
            showListingProducts: 0,
            addProductToListing: 0,
            selectedListing:[],
        searchproduct: '',
        shops_error: [],
        company_info: [],
        categoryProducts: [],
        productsLimit: '100',
        productsCurrentPage: 1,
        productListingSelected: [],
        newquantity: 0,
        listingquantity: 0,
        transferquantity: 0,
        currentPage: 1,
        orderDesc: true,
        priceRange: [0,999999],
        trade_productsactive: '',
        trade_active: '',
        public_active: '',
        public_productsactive: '',
        totalListingsQuantity: 0,
        editModal: 0,
        productsactive: '',
        brands: '',
        collections: '',
        active: '',
        view: 'list',
        search: '',
        limit: '25',
        sortby: 'created_at',
        order: 'desc',
        isCheckAll: false,
        selectedids: [],
        addNewPanel: 0,
        isCustom: 0,
        }
      },
      methods: {
        filterSelected(id){
        if(this.selectedids.length > 0){
          var exists = this.selectedids.filter(item => item == id);
          if(exists.length > 0){
            return true;
          }else{
            return false
          }
        }else{
          return false;
        }
      },
      priceIncVat(price, tax_percentage){
          var tax = 1 + (tax_percentage / 100);
          return price * tax;
        },
        getOrderProducts(order){
            var products_list = [];
            for(var i = 0; i < order.company_order.length; i++){
              console.log(order.company_order[i].company_order.products);
              for(var pi = 0; pi < order.company_order[i].company_order.products.length; pi++){
                console.log(order.company_order[i].company_order.products[pi]);
                  products_list.push(order.company_order[i].company_order.products[pi]);
              }
            }
            this.categoryProducts = products_list;
            return products_list;
        },
      getDate(date){
        var createdOn = DateTime.fromISO(date, { zone: 'utc'});
        console.log(createdOn);
        return createdOn.day+' '+createdOn.monthLong+' '+createdOn.weekYear+'';
      },
      checkAll: function(){ 
                 this.isCheckAll = !this.isCheckAll; 
                 this.selectedids = []; 
                 if(this.isCheckAll){ 
                     // Check all 
                     for (var key in this.shopsData.data) {          
                        this.selectedids.push(this.shopsData.data[key].id); 
                     } 
                 } 
              }, 
      updateCheckall: function(){ 
                 if(this.selectedids.length == this.shopsData.data.length){
                    this.isCheckAll = true; 
                 }else{ 
                    this.isCheckAll = false; 
                 } 
      },
      }
    }

</script>