<script>
import Layout from '../../layouts/main'
import PageHeader from '../../components/page-header'
import DateTime from 'luxon/src/datetime.js';
import SideSlidePanel from "../../components/widgets/SideSlidePanel"
import SearchBar from "../../components/widgets/searchBar"
import EditModal from "../../components/widgets/editModal"
import SelectCompanyOrderStatus from "../../components/widgets/SelectCompanyOrderStatus";
import AddressLabel from "../../components/widgets/admin/print/AddressLabel";
import Emailer from "../../components/widgets/admin/email/Emailer"
import SearchCompanyUser from "../../components/widgets/SearchCompanyUser";
import SearchCompanyLocation from "../../components/widgets/SearchCompanyLocation";
import CompanyCustomerSearch from "../../components/widgets/CompanyCustomerSearch"
import CompanyCustomerSelect from "../../components/widgets/CompanyCustomerSelect"
/**
 * Shops component
 */
export default {
  components: { Layout, PageHeader, SideSlidePanel, SearchBar, EditModal, DateTime, SelectCompanyOrderStatus, SearchCompanyUser, AddressLabel, Emailer, CompanyCustomerSearch, SearchCompanyLocation, CompanyCustomerSelect },
  data() {
    return {
      search_user: '',
      search_customer: '',
      search_location: '',
      search_sources: [],
      ordersources: [
        {key: 'public', name: 'Public Market', bg: 'bg-primary', color: 'text-white'},
        {key: 'trade', name: 'Trade Market', bg: 'bg-blue', color: 'text-white'},
        {key: 'website', name: 'Website', bg: 'bg-peach', color: 'text-dark'},
        {key: 'external', name: 'External Website', bg: 'bg-info', color: 'text-white'},
        {key: 'ext', name: 'External Marketplace', bg: 'bg-info' , color: 'text-white'},
        {key: 'pos', name: 'Point of Sale', bg: 'bg-warning', color: 'text-white'},
      ],
      showFilters: false,
      stateActive: false,
      stateMode: 'single',
      stateItem: '',
      loadingNewStatus: false,
      delete_action: [],
      delete_confirm: false,
      deleted_message: false, 
      success_message: false,
      success_text: 'Your action has been successful.',
      printWindow: false,
      addressLabel: '',
      addressLabelsList: [],
      addressBulk: true,
      addressBulkOptions: true,
      actionMode: 'single',
      order_statuses: [],
      current_status: '',
      new_order_status: '',
      shopsData: '',
      productsData: [],
      showListingProducts: 0,
      addProductToListing: 0,
      selectedListing:[],
      searchproduct: '',
      shops_error: [],
      company_info: [],
      categoryProducts: [],
      productsLimit: '100',
      productsCurrentPage: 1,
      productListingSelected: [],
      newquantity: 0,
      listingquantity: 0,
      transferquantity: 0,
      currentPage: 1,
      orderDesc: true,
      dateRange: [null, null],
      trade_productsactive: '',
      trade_active: '',
      public_active: '',
      public_productsactive: '',
      totalListingsQuantity: 0,
      editModal: 0,
      productsactive: '',
      brands: '',
      collections: '',
      active: '',
      view: 'list',
      search: '',
      limit: '25',
      sortby: 'created_at',
      order: 'desc',
      isCheckAll: false,
      selectedids: [],
      addNewPanel: 0,
      isCustom: 0,
      send_email: {
        id: '',
        notification_thread_id: '',
        email_to: [],
        email_cc: [],
        email_bcc: [],
        subject: '',
        message: '',
        replying_to: [],
      },
      dropzoneOptions: {
            url: 'https://httpbin.org/post',
            thumbnailHeight: 300,
            thumbnailWidth: 300,
          },
          limits: [
            {value: 25, text: "25"},
            {value: 50, text: "50"},
            {value: 100, text: "100"},
          ],
          transferOptions: [
            {value: 0, text: "Company Stock"},
            {value: 1, text: "Another Shop or Listing"},
          ],
          transferOption: 0,
      title: 'Orders',
      items: [
        {
          text: 'Orders',
          active: true,
        },
      ],
    }
  },
  methods: {
    addToSourcesFilter(source){
      var exists = this.search_sources.findIndex(item => item == source.key);
      if(exists > -1){
        this.search_sources.splice(exists, 1);
      }else{
        this.search_sources.push(source.key);
      }
      this.productQuery();
    },
    updateOrderState(updatestate, stateactive){
        this.loadingNewStatus = true;
        axios.post('/api/company/order/'+this.selectedids[0]+'/update-state', {state: updatestate, active: stateactive}, {headers : { 'Authorization' : 'Bearer '+this.$attrs.user.api_token }}).then(response => {
          this.success_text = 'Congratulations, your order status has been updated.';
          this.success_message = true;
          //this.resetProjectData();
          this.addPanel = false;
          let self = this;
            setTimeout(function(){
              self.loadingNewStatus = false;
               
            }, 500);
        }).catch(error => {
          console.log(error);
        });
      },
      bulkUpdateOrderState(updatestate, stateactive){
        this.loadingNewStatus = true;
        axios.post('/api/company/order/bulk-update-state', {ids: this.selectedids, state: updatestate, active: stateactive }, {headers : { 'Authorization' : 'Bearer '+this.$attrs.user.api_token }}).then(response => {
          this.success_text = 'Congratulations, your order status has been updated.';
          this.success_message = true;
          //this.resetProjectData();
          this.addPanel = false;
          let self = this;
            setTimeout(function(){
              self.loadingNewStatus = false;
              //self.searchResource();
              self.productQuery();
              return self.$bvModal.hide('update-status');
            }, 500);
        }).catch(error => {
          console.log(error);
        });
      },
    updateOrderStatus(){
        this.loadingNewStatus = true;
        axios.post('/api/company/order/'+this.selectedids[0]+'/update-status', this.new_order_status, {headers : { 'Authorization' : 'Bearer '+this.$attrs.user.api_token }}).then(response => {
          this.success_text = 'Congratulations, your order status has been updated.';
          this.success_message = true;
          //this.resetProjectData();
          this.addPanel = false;
          let self = this;
            setTimeout(function(){
              self.loadingNewStatus = false;
              //self.searchResource();
              //self.$bvModal.hide('move-modal');
              self.productQuery();
              return self.$bvModal.hide('update-status');
            }, 500);
        }).catch(error => {
          console.log(error);
        });
      },
      bulkUpdateOrderStatus(){
        this.loadingNewStatus = true;
        axios.post('/api/company/order/bulk-update-status', {ids: this.selectedids, status: this.new_order_status}, {headers : { 'Authorization' : 'Bearer '+this.$attrs.user.api_token }}).then(response => {
          this.success_text = 'Congratulations, your order status has been updated.';
          this.success_message = true;
          //this.resetProjectData();
          this.addPanel = false;
          let self = this;
            setTimeout(function(){
              self.loadingNewStatus = false;
              //self.searchResource();
              self.productQuery();
              return self.$bvModal.hide('update-status');
            }, 500);
        }).catch(error => {
          console.log(error);
        });
      },
    getOrderStatuses(){
      if(this.order_statuses.length == 0){
          axios.get('/api/company/order/statuses?limit=0&sortby=id&order=desc&search=', {headers: {'Authorization' : 'Bearer '+this.$attrs.user.api_token}}).then(response => {
              this.order_statuses = response.data;

            }).catch(error => {
              this.response_error = error.data;
            });
        }
    },
    brandQuery(){
      axios.get('/api/company/listings?page='+this.currentPage+'&limit='+this.limit+'&sortby='+this.sortby+'&order='+this.order+'&search='+this.search, {headers: {
                  'Authorization': 'Bearer ' + this.$attrs.user.api_token
              }}).then(response => {
        this.shopsData = response.data;
      }).catch(error => {
        this.shops_error = error.data;
      });
    },
    getProducts(id){
      axios.get('/api/company/listing/'+id+'/products').then(response => {
        this.categoryProducts = response.data;
        //this.showListingProducts = 1;
      }).catch(error => {
        this.products_error = error.data;
      });
    },
    getSellerProducts(search_query){
      if(this.orderDesc == true){
        this.order = 'desc';
      }else{
        this.order = 'asc';
      }
      axios.get('/api/company/orders?page='+this.productsCurrentPage+'&limit='+this.productsLimit+'&sortby='+this.sortby+'&order='+this.order+'&search='+search_query+'&trade_active='+this.trade_productsactive+'&public_active='+this.public_productsactive+'&active='+this.productsactive+'&brands='+this.brands+'&collections='+this.collections, {headers: {'Authorization' : 'Bearer '+this.$attrs.user.api_token}}).then(response => {
        console.log(response);
        this.productsData = response.data.data;
      });
    },
    closeEditModal(){
      this.$refs['edit-modal'].hide()
    },
    addToListing(product){
      this.productListingSelected = product;
      this.selectedProductListingInfo = product.listings;
      if(this.productListingSelected.listings.length > 0){
        var listing_qty = this.productListingSelected.listings.filter(item => (item.listing_id == this.selectedListing.id) && (item.product_id == this.productListingSelected.id));
        if(listing_qty.length > 0){
          this.listingquantity = listing_qty[0].quantity;
        }else{
          this.listingquantity = 0;
        }
      }else{
         this.listingquantity = 0;
      }
      this.$refs['edit-modal'].show();
    },
    getListingData(listing_id){
        return this.shopsData.data.filter(item => item.id == listing_id);
    },
    priceIncVat(price, tax_percentage){
      var tax = 1 + (tax_percentage / 100);
      return price * tax;
    },
     productQuery(){
      if(this.productsData != ''){
        if(this.limit > this.productsData.total){
          this.currentPage = 1;
        }
      }
      if(this.orderDesc == true){
        this.order = 'desc';
      }else{
        this.order = 'asc';
      }
      axios.get('/api/company/orders?page='+this.currentPage+'&limit='+this.limit+'&sortby='+this.sortby+'&order='+this.order+'&search='+this.search+'&trade_active='+this.trade_active+'&public_active='+this.public_active+'&active='+this.active+'&brands='+this.brands+'&collections='+this.collections+'&daterange='+this.dateRange+'&source='+this.search_sources+'&company_listing_id='+this.search_location+'&company_user_id='+this.search_user, {headers: {'Authorization' : 'Bearer '+this.$attrs.user.api_token}}).then(response => {
        console.log(response);
        this.shopsData = response.data;
      });
    },
    userSelected(user){
      this.search_user = user.companies[0].id;
      this.productQuery();
    },
    userRemoved(user){
      this.search_user = '';
      this.productQuery();
    },
    customerSelected(user){
      this.search_customer = user.id;
      this.productQuery();
    },
    customerRemoved(user){
      this.search_customer = '';
      this.productQuery();
    },
    locationSelected(location){
      this.search_location = location.id;
      this.productQuery();
    },
    locationRemoved(user){
      this.search_location = '';
      this.productQuery();
    },
    getDate(date){
        var createdOn = DateTime.fromISO(date, { zone: 'utc'});
        console.log(createdOn);
        return createdOn.day+' '+createdOn.monthLong+' '+createdOn.weekYear+'';
      },
      filterSelected(id){
        if(this.selectedids.length > 0){
          var exists = this.selectedids.filter(item => item == id);
          if(exists.length > 0){
            return true;
          }else{
            return false
          }
        }else{
          return false;
        }
      },
      checkAll: function(){ 
                 this.isCheckAll = !this.isCheckAll; 
                 this.selectedids = []; 
                 if(this.isCheckAll){ 
                     // Check all 
                     for (var key in this.shopsData.data) {          
                        this.selectedids.push(this.shopsData.data[key].id); 
                     } 
                 } 
              }, 
      updateCheckall: function(){ 
                 if(this.selectedids.length == this.shopsData.data.length){
                    this.isCheckAll = true; 
                 }else{ 
                    this.isCheckAll = false; 
                 } 
      },
  },
  computed:{
    allProductListingQuantities: function(){
      if(this.productListingSelected.length !== 0){
          if(this.productListingSelected.listings.length > 0){
            this.totalListingsQuantity = 0;
            for(var i = 0; i < this.productListingSelected.listings.length; i++){
              //console.log(this.productListingSelected.listings[i].quantity);
              this.totalListingsQuantity = Number.parseFloat(this.totalListingsQuantity) + Number.parseFloat(this.productListingSelected.listings[i].quantity);
            }
            return this.totalListingsQuantity;
          }else{
            this.totalListingsQuantity = 0;
            return 0;
          }
      }else{
        return 0;
      }
    },
  },
  created(){
    //console.log(localStorage.getItem('default_company'));
    this.company_info = JSON.parse(localStorage.getItem('default_company'));
    this.productQuery();
  },
  mounted(){

  },
}
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-md-12">
        <div class="row mb-3">
          <div class="col-xl-1 col-sm-6">
            <div class="mt-2">
              <b-form-select class="form-control" v-model="limit" :options="limits" @input="productQuery()"></b-form-select>
            </div>
          </div>
         
          <div class="col-lg-2 col-sm-6 d-flex">
          <form class="my-auto mr-auto">
            <small class="mx-auto my-2 text-center d-block">Results {{productsData.from}} - {{productsData.to}} of {{productsData.total}}</small>
          </form>
          <div v-if="view == 'grid'" class="text-center my-auto ml-auto"><div
                        class="form-check form-switch form-switch-sm mb-0 mr-auto p-0 mt-auto d-flex"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input mx-auto my-auto" 
                          type="checkbox"
                          id="SwitchCheckSizeCheckall"
                         
                          v-model="isCheckAll"
                          @click='checkAll()'
                          name="active"
                        />
                        <label class="d-inline-block my-auto ml-2" for="SwitchCheckSizeCheckall">Select All</label>
                    </div></div>
        </div>
        <div class="col-lg-5 col-sm-6 d-flex">
            <div class="mt-sm-0 form-inline w-100" >
              <div class="search-box mr-2 ml-auto">
                <div class="position-relative">
                  <input
                    type="text"
                    class="form-control w-100 border-0"
                    placeholder="Search name, reference or description..."
                    v-model="search"
                    @keyup.enter="productQuery()"
                  />
                  <i class="bx bx-search-alt search-icon"></i>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-sm-6 d-flex">
            <div class="d-flex ml-auto">
              <ul class="nav nav-pills product-view-nav">
                <li class="nav-item">
                  <a class="nav-link" :class="{'active': showFilters == true }" href="javascript: void(0);" @click="showFilters = !showFilters">
                    <i class="bx bx-filter-alt"></i>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" :class="{'active': view == 'grid' }" href="javascript: void(0);" @click="view = 'grid'">
                    <i class="bx bx-grid-alt"></i>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" :class="{'active': view == 'list' }" href="javascript: void(0);" @click="view = 'list'">
                    <i class="bx bx-list-ul"></i>
                  </a>
                </li>
                <li class="nav-item">
                 <b-dropdown text="Bulk Actions" variant="primary" class="rounded" menu-class="dropdown-menu-right">
                  <b-dropdown-item href="javascript: void(0);" v-b-modal.update-status @click="actionMode = 'bulk', getOrderStatuses()"><i class="bx bx-history font-size-14 text-primary"></i> Update Status</b-dropdown-item>
                  <b-dropdown-item href="javascript: void(0);" @click="bulkUpdateOrderState('paid', true)"><i class="bx bx-check-circle font-size-14 text-success"></i> Mark Paid</b-dropdown-item>
                  <b-dropdown-item href="javascript: void(0);" @click="bulkUpdateOrderState('paid', true)"><i class="bx bx-check-circle font-size-14 text-danger"></i> Mark Unpaid</b-dropdown-item>
                   <b-dropdown-item href="javascript: void(0);" @click="setActive(true)"><i class="bx bxs-truck font-size-14 text-info"></i> Mark Delivered</b-dropdown-item>
                  
                  <!--<b-dropdown-item href="javascript: void(0);" @click="bulkDuplicateResource()"><i class="bx bx-copy-alt font-size-14"></i> Duplicate</b-dropdown-item>-->
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-item href="javascript: void(0);" @click="addressLabelsList = [], addressBulk = true, addressBulkOptions = true, printWindow = !printWindow"><i class="bx bx-printer font-size-14"></i> Print Address Labels</b-dropdown-item>
                  <b-dropdown-item href="javascript: void(0);"  v-b-modal.send-email @click="send_email.email_to = [], send_email.email_to.push(location.email)" ><i class="bx bx-mail-send font-size-14"></i> Send Email</b-dropdown-item>
                  <b-dropdown-divider></b-dropdown-divider>
                   <b-dropdown-item href="javascript: void(0);" @click="setActive(true)"><i class="bx bx-no-entry font-size-14 text-danger"></i> Mark Cancelled</b-dropdown-item>
                  <b-dropdown-item disabled href="javascript: void(0);" v-b-modal.delete-modal @click="deleteMode = 'bulk'"><i class="bx bx-trash-alt font-size-14"></i> Delete</b-dropdown-item>
                </b-dropdown>
                </li>
                <!--<li class="nav-item">
                 <a
                    class="btn btn-success btn-rounded mb-2 mr-2 text-white"
                    href="product/add"
                  >
                    <i class="mdi mdi-plus mr-1"></i> New
                  </a>
                </li>-->
              </ul>
              <div class="text-sm-right">
                  
                </div>
          </div>
        </div>
      </div>
        <div class="card" v-if="view == 'list'" >
          <div class="card-body p-0">
            <div class="table-responsive">
              <table class="table table-centered table-hover">

                <thead class="thead-light">
                  <tr>
                    <th scope="col">
                      <div
                        class="form-check form-switch form-switch-sm mb-0 mx-auto p-0 mt-auto d-flex"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input mx-auto my-auto" 
                          type="checkbox"
                          id="SwitchCheckSizesm"
                         
                          v-model="isCheckAll"
                          @click='checkAll()'
                          name="check-all"
                        />
                    </div>
                    </th>
                    <th scope="col"><a :class="{'text-dark': sortby != 'order_no'}" href="javascript: void(0);" @click="orderDesc = !orderDesc,  sortby = 'order_no', productQuery()">Order No. <i v-if="orderDesc == true" class="bx bx-caret-up"></i><i v-if="orderDesc == false" class="bx bx-caret-down"></i></a></th>
                    <th scope="col">Customer</th>
                    <th scope="col">Source</th>
                    <!--<th scope="col">Products</th>-->
                    <th scope="col">Date</th>
                    <th scope="col">Total Exc.</th>
                    <th scope="col">Total Inc.</th>
                    <th scope="col">Paid</th>
                    <th scope="col">Payment Method</th>
                    <th scope="col">Status</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
               
                <tbody>
                  
                  <tr v-for="list in shopsData.data" :key="list.id" valign="middle">
                    <td>
                    <div class="text-center" >
                       <b-form-checkbox class="select-row-btn" v-model="selectedids" :value="list.id" button-variant="light" @input="updateSelected" button>

                          <i v-if="filterSelected(list.id)" class="bx bx-check"></i><span class="avatar-title rounded-circle bg-light text-primary avatar-sm" v-else>{{(list.reference).slice(0,1)}}</span></b-form-checkbox>
                    </div> 
                    </td>
                    <td>
                      <a :href="'/order/details/'+list.uid+''"><span>#{{list.reference}}</span></a>
                    </td>
                   
                    
                    <td>
                      <div v-if="list.trade_orders.length > 0">
                        <a :href="'/seller/profile/'+list.trade_orders[0].order.customer.nickname+''"><h6 class="mb-0">{{list.trade_orders[0].order.customer.name}}</h6>
                        {{list.trade_orders[0].order.customer.email}}</a>
                      </div>
                      <div v-if="list.customer_orders.length > 0">
                         <a :href="'/customer/details/'+list.customer_orders[0].order.customer.id+''"><h6 class="mb-0">{{list.customer_orders[0].order.customer.name}} {{list.customer_orders[0].order.customer.lastname}}</h6>
                        {{list.customer_orders[0].order.customer.email}}</a>
                      </div>
                      <div v-if="list.website_orders.length > 0">
                         <a :href="'/customer/details/'+list.website_orders[0].order.customer.id+''"><h6 class="mb-0">{{list.website_orders[0].order.customer.name}} {{list.website_orders[0].order.customer.lastname}}</h6>
                        {{list.website_orders[0].order.customer.email}}</a>
                      </div>
                      <div v-if="list.pos_orders.length > 0">
                         <a v-if="list.pos_orders[0].order.customer !== null" :href="'/customer/details/'+list.website_orders[0].order.customer.id+''"><h6 class="mb-0">{{list.website_orders[0].order.customer.name}} {{list.website_orders[0].order.customer.lastname}}</h6>
                        {{list.website_orders[0].order.customer.email}}</a>
                      </div>
                      
                    </td>
                     <td>
                       <div v-if="list.trade_orders.length > 0">
                       <span class="font-size-12 badge badge-light bg-light text-dark"  v-b-tooltip.hover title="Order from Trade Marketplace"><i class="bx bx-store text-primary"></i> Trade Marketplace</span>
                      </div>
                      <div v-if="list.customer_orders.length > 0">
                        <span class="font-size-12 badge bg-primary bg-soft text-dark"  v-b-tooltip.hover title="Order from Gemesys Public Marketplace"><i class="bx bxs-store text-dark"></i> Public Marketplace</span>
                      </div>
                      <div v-if="list.website_orders.length > 0">
                        <span class="font-size-12 badge bg-peach-soft text-primary" v-b-tooltip.hover title="Order from Your Website"><i class="bx bx-globe text-primary"></i> Website</span>
                      </div>
                      <div v-if="list.pos_orders.length > 0">
                        <span class="font-size-12 badge bg-pos text-primary" v-b-tooltip.hover title="Order from Your Point of Sale"><i class="bx bx-shopping-bag text-primary"></i> In Store</span>
                      </div>
                    </td>
                    <!--<td>
                      <a href="javascript:;" v-b-tooltip.hover title="Stock" placement="left" @click="selectedListing = list, getProducts(list.id), showListingProducts = 1" class="btn btn-outline-light btn-rounded"><i class="bx bx bx-purchase-tag-alt"></i></a>
                    </td>-->
                     <td>
                     <i class="bx bx-calendar"></i> {{getDate(list.created_at)}}
                    </td>
                     <td>
                      {{parseFloat(list.total_order_exc).toFixed(2)}}
                    </td>
                    <td>
                      {{parseFloat(list.total_order_inc).toFixed(2)}}
                    </td>
                     <td class="text-center">
                      <i v-if="list.order_paid == 1" class="bx bx-check-circle text-success font-size-18 mx-auto"></i>
                      <i v-else class="bx bx-x-circle text-danger font-size-18 mx-auto"></i>
                    </td>
                    <td>
                      <div v-if="list.payments.length > 0">
                        <span v-if="list.payments[0].payment_type == 'card'" class="font-size-12 badge badge-soft-primary text-primary">
                          <i class="bx bx-credit-card"></i>
                          Card
                        </span>
                        <span v-if="list.payments[0].payment_type == 'card_present'" class="font-size-12 badge badge-soft-primary text-primary">
                          <i class="bx bx-credit-card"></i>
                          Card Terminal
                        </span>
                      </div>
                      <div v-else>
                         <span  class="font-size-12 badge badge-soft-danger text-danger">
                          <i class="bx bx-no-entry"></i> No Payment Made
                         </span>
                      </div>
                    </td>
                    <td>
                       <div v-if="list.order_cancelled == 1">
                        <span class="badge bg-soft font-size-12 bg-danger" > Order Cancelled</span>
                       </div>
                       <div v-else-if="list.status !== null">
                        <span class="badge bg-soft font-size-12" :style="{'background-color': list.status.color}" > {{list.status.name}}</span>
                       </div>
                    </td>
                    <td style="width: 125px;">
                      <ul class="list-inline font-size-20 contact-links mb-0">
                      <li class="list-inline-item">
                        <a href="javascript:;" v-b-tooltip.hover title="View" placement="left" :href="'/order/details/'+list.uid+''" class="btn btn-outline-light btn-rounded"><i class="mdi mdi-eye-outline"></i></a>
                      </li>
                        <li class="list-inline-item">
                           <b-dropdown v-b-tooltip.hover title="More Options" placement="left" dropleft class="more-options-dropdown btn btn-outline-light btn-rounded" menu-class="dropdown-menu-right">
                        <template v-slot:button-content>
                           <i class="bx bx-dots-horizontal-rounded"></i>
                        </template>
                        <b-dropdown-item href="javascript: void(0);" v-b-modal.update-status @click="selectedids = [], selectedids.push(list.uid), current_status = list.status , actionMode = 'single', getOrderStatuses()"><i class="bx bx-history font-size-14 text-primary"></i> Update Status</b-dropdown-item>
                  <b-dropdown-item href="javascript: void(0);" v-if="list.order_paid == false" v-b-modal.payment-modal @click="stateItem = list, stateMode = 'single', stateActive = true"><i class="bx bx-check-circle font-size-14 text-success"></i> Mark Paid</b-dropdown-item>
                  <b-dropdown-item href="javascript: void(0);"  v-else @click="selectedids = [], selectedids.push(list.uid), updateOrderState('paid', false)"><i class="bx bx-x-circle font-size-14 text-danger"></i> Mark Unpaid</b-dropdown-item>
                   <b-dropdown-item href="javascript: void(0);" v-b-modal.shippings-modal  @click="stateItem = list, stateMode = 'single', stateActive = true"><i class="bx bxs-truck font-size-14 text-primary"></i> Mark Shipped</b-dropdown-item>
                    <b-dropdown-item href="javascript: void(0);" v-if="order_refunded !== true" v-b-modal.process-refund @click="stateItem = list"><i class="bx bx-revision font-size-14 text-info"></i> Process Refund</b-dropdown-item>
                   <b-dropdown-item href="javascript: void(0);" @click="setCancelled(true)"><i class="bx bx-no-entry font-size-14 text-danger"></i> Mark Cancelled</b-dropdown-item>
                  <!--<b-dropdown-item href="javascript: void(0);" @click="bulkDuplicateResource()"><i class="bx bx-copy-alt font-size-14"></i> Duplicate</b-dropdown-item>-->
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-item v-if="list.trade_orders.length > 0" href="javascript: void(0);" @click="addressLabel = list.trade_orders[0].order.customer_delivery_address, addressBulk = false, addressBulkOptions = false, printWindow = !printWindow"><i class="bx bx-printer font-size-14"></i> Print Address Labels
                       
                      </b-dropdown-item>
                      <b-dropdown-item v-if="list.customer_orders.length > 0" href="javascript: void(0);" @click="addressLabel = list.customer_orders[0].order.customer_delivery_address, addressBulk = false, addressBulkOptions = false, printWindow = !printWindow"><i class="bx bx-printer font-size-14"></i> Print Address Labels
                        
                      </b-dropdown-item>
                      <b-dropdown-item v-if="list.website_orders.length > 0" href="javascript: void(0);" @click="addressLabel = list.website_orders[0].order.customer_delivery_address, addressBulk = false, addressBulkOptions = false, printWindow = !printWindow"><i class="bx bx-printer font-size-14"></i> Print Address Labels
                
                      </b-dropdown-item>
            

                  <!-- send email --> 

                  <b-dropdown-item v-if="list.trade_orders.length > 0" v-b-modal.send-email @click="send_email.email_to = [], send_email.email_to.push(list.trade_orders[0].order.customer.email)"><i class="bx bx-mail-send font-size-14"></i> Send Email
                       
                      </b-dropdown-item>
                      <b-dropdown-item v-if="list.customer_orders.length > 0" v-b-modal.send-email @click="send_email.email_to = [], send_email.email_to.push(list.customer_orders[0].order.customer.email)"><i class="bx bx-mail-send font-size-14"></i> Send Email
                        
                      </b-dropdown-item>
                      <b-dropdown-item v-if="list.website_orders.length > 0" v-b-modal.send-email @click="send_email.email_to = [], send_email.email_to.push(list.website_orders[0].order.customer.email)"><i class="bx bx-mail-send font-size-14"></i> Send Email
                
                      </b-dropdown-item>
                 
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-item disabled href="javascript: void(0);" v-b-modal.delete-modal @click="deleteMode = 'bulk'"><i class="bx bx-trash-alt font-size-14"></i> Delete</b-dropdown-item>
                      </b-dropdown>
                        </li>
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="row">
              <div class="col-lg-12">
                <b-pagination
              v-if="shopsData.last_page > 1"
              class="justify-content-center"
              pills
              v-model="currentPage"
              :total-rows="shopsData.total"
              :per-page="shopsData.per_page"
              aria-controls="my-table"
              @input="productQuery"
            ></b-pagination>
             <small class="mx-auto my-2 text-center d-block">Records {{shopsData.from}} - {{shopsData.to}} of {{shopsData.total}} // Page {{shopsData.current_page}} of {{shopsData.last_page}}</small>
              </div>
            </div>
          </div>
      </div>
    <div class="row" v-if="view == 'grid'" >
     <div class="col-xl-4"  v-for="list in shopsData.data" :key="list.id">
        <div class="card overflow-hidden">
           <div class="bg-soft-primary bg-light border-radius-top-right-1em border-radius-top-left-1em" style="min-height: 150px;">
            <div class="row">
              
              <div class="col-12 align-self-end">
               
                
              </div>
            </div>
          </div>
          <div class="card-body pt-0">

            <div class="row">
              <div class="col-sm-12 text-center px-4 mb-2">
               <!-- <div class="avatar-xl profile-user-wid mb-4">
                  <img class="img-thumbnail rounded-circle h-100" :src="`/public/storage/${list.image}`" alt />
                </div>-->
                <div class="avatar-sm profile-user-wid mx-auto">
                  
                    <div class="text-center" >
                        <b-form-checkbox class="select-row-btn" v-model="selectedids" :value="list.id" button-variant="primary" @input="updateSelected" button>

                          <i v-if="filterSelected(list.id)" class="bx bx-check"></i><span class="avatar-title rounded-circle text-uppercase" v-else>{{(list.uid).slice(0,1)}}</span></b-form-checkbox>
                    </div> 
                </div>
               <a class="text-muted mb-0 text-truncate" :href="'/order/details/'+list.uid+''"><h5 class="mt-2">#{{list.reference}}</h5></a>
                 <div class="col-md-12 text-center my-2 text-muted">
                     <i class="bx bx-calendar"></i> {{getDate(list.created_at)}}
                    </div>
                      <div class="my-3" v-if="list.trade_orders.length > 0">
                        <a :href="'/seller/profile/'+list.trade_orders[0].order.customer.nickname+''"><p class="mb-0 text-muted text-truncate">{{list.trade_orders[0].order.customer.name}}</p>
                        {{list.trade_orders[0].order.customer.email}}</a>
                      </div>
                      <div class="my-3" v-if="list.customer_orders.length > 0">
                         <a :href="'/customer/details/'+list.customer_orders[0].order.customer.id+''"><p class="mb-0 text-muted text-truncate">{{list.customer_orders[0].order.customer.name}} {{list.customer_orders[0].order.customer.lastname}}</p>
                        {{list.customer_orders[0].order.customer.email}}</a>
                      </div>
                      <div class="my-3" v-if="list.website_orders.length > 0">
                         <a :href="'/customer/details/'+list.website_orders[0].order.customer.id+''"><p class="mb-0 text-muted text-truncate">{{list.website_orders[0].order.customer.name}} {{list.website_orders[0].order.customer.lastname}}</p>
                        {{list.website_orders[0].order.customer.email}}</a>
                      </div>
                      
              </div>

              <div class="col-sm-12 d-flex mb-4">
                <div class="mx-auto" v-if="list.trade_orders.length > 0">
                       <span class="font-size-12 badge badge-light bg-light text-dark"  v-b-tooltip.hover title="Order from Trade Marketplace"><i class="bx bx-transfer-alt text-primary"></i> Trade Marketplace</span>
                      </div>
                      <div class="mx-auto" v-if="list.customer_orders.length > 0">
                        <span class="font-size-12 badge bg-primary bg-soft text-dark"  v-b-tooltip.hover title="Order from Gemesys Public Marketplace"><i class="bx bxs-user text-dark"></i> Public Marketplace</span>
                      </div>
                      <div class="mx-auto" v-if="list.website_orders.length > 0">
                        <span class="font-size-12 badge bg-peach-soft text-primary" v-b-tooltip.hover title="Order from Your Website"><i class="bx bx-globe text-primary"></i> Website</span>
                      </div>
              </div>
            
               <div class="col-sm-6 text-center border-right">
                <h6>
                <span class="currency">£</span> {{parseFloat(list.total_order_exc).toFixed(2)}} exc. VAT
                </h6>
              </div>
              <div class="col-sm-6 text-center">
                <h6>
                <span class="currency">£</span> {{parseFloat(list.total_order_inc).toFixed(2)}} inc. VAT
                </h6>
              </div>
            </div>
          </div>

          <div class="card-footer  bg-transparent border-top">
                  <div class="d-flex">
                    <a  :href="'/order/details/'+list.uid+''" class="btn btn-outline-light btn-rounded"><i class="mdi mdi-eye-outline my-1"></i></a>
                    <b-dropdown  placement="left" variant="outline-light" dropup class="dropup more-options-dropdown btn-rounded ml-2 mr-auto" menu-class="dropdown-menu-left">
                        <template v-slot:button-content>
                           <i class="bx bx-dots-horizontal-rounded"></i>
                        </template>
                           <b-dropdown-item href="javascript: void(0);" v-b-modal.update-status @click="selectedids = [], selectedids.push(list.uid), current_status = list.status , actionMode = 'single', getOrderStatuses()"><i class="bx bx-history font-size-14 text-primary"></i> Update Status</b-dropdown-item>
                  <b-dropdown-item href="javascript: void(0);" v-if="list.order_paid == false" v-b-modal.payment-modal @click="stateItem = list, stateMode = 'single', stateActive = true"><i class="bx bx-check-circle font-size-14 text-success"></i> Mark Paid</b-dropdown-item>
                  <b-dropdown-item href="javascript: void(0);"  v-else @click="selectedids = [], selectedids.push(list.uid), updateOrderState('paid', false)"><i class="bx bx-x-circle font-size-14 text-danger"></i> Mark Unpaid</b-dropdown-item>
                   <b-dropdown-item href="javascript: void(0);" v-b-modal.shippings-modal  @click="stateItem = list, stateMode = 'single', stateActive = true"><i class="bx bxs-truck font-size-14 text-primary"></i> Mark Shipped</b-dropdown-item>
                    <b-dropdown-item href="javascript: void(0);" v-if="order_refunded !== true" v-b-modal.process-refund @click="stateItem = list"><i class="bx bx-revision font-size-14 text-info"></i> Process Refund</b-dropdown-item>
                   
                  <!--<b-dropdown-item href="javascript: void(0);" @click="bulkDuplicateResource()"><i class="bx bx-copy-alt font-size-14"></i> Duplicate</b-dropdown-item>-->
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-item v-if="list.trade_orders.length > 0" href="javascript: void(0);" @click="addressLabel = list.trade_orders[0].order.customer_delivery_address, addressBulk = false, addressBulkOptions = false, printWindow = !printWindow"><i class="bx bx-printer font-size-14"></i> Print Address Labels
                       
                      </b-dropdown-item>
                      <b-dropdown-item v-if="list.customer_orders.length > 0" href="javascript: void(0);" @click="addressLabel = list.customer_orders[0].order.customer_delivery_address, addressBulk = false, addressBulkOptions = false, printWindow = !printWindow"><i class="bx bx-printer font-size-14"></i> Print Address Labels
                        
                      </b-dropdown-item>
                      <b-dropdown-item v-if="list.website_orders.length > 0" href="javascript: void(0);" @click="addressLabel = list.website_orders[0].order.customer_delivery_address, addressBulk = false, addressBulkOptions = false, printWindow = !printWindow"><i class="bx bx-printer font-size-14"></i> Print Address Labels
                
                      </b-dropdown-item>
            

                  <!-- send email --> 

                  <b-dropdown-item v-if="list.trade_orders.length > 0" v-b-modal.send-email @click="send_email.email_to = [], send_email.email_to.push(list.trade_orders[0].order.customer.email)"><i class="bx bx-mail-send font-size-14"></i> Send Email
                       
                      </b-dropdown-item>
                      <b-dropdown-item v-if="list.customer_orders.length > 0" v-b-modal.send-email @click="send_email.email_to = [], send_email.email_to.push(list.customer_orders[0].order.customer.email)"><i class="bx bx-mail-send font-size-14"></i> Send Email
                        
                      </b-dropdown-item>
                      <b-dropdown-item v-if="list.website_orders.length > 0" v-b-modal.send-email @click="send_email.email_to = [], send_email.email_to.push(list.website_orders[0].order.customer.email)"><i class="bx bx-mail-send font-size-14"></i> Send Email
                
                      </b-dropdown-item>
                 
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-item href="javascript: void(0);" @click="setCancelled(true)"><i class="bx bx-no-entry font-size-14 text-danger"></i> Mark Cancelled</b-dropdown-item>
                  <b-dropdown-item disabled href="javascript: void(0);" v-b-modal.delete-modal @click="deleteMode = 'bulk'"><i class="bx bx-trash-alt font-size-14"></i> Delete</b-dropdown-item>
                      </b-dropdown>
                     
                      <span v-if="list.order_paid == false"  class="font-size-12 badge badge-soft-danger text-danger my-auto mr-2">
                          <i class="bx bx-x-circle"></i> Unpaid
                         </span>
                      <span v-if="list.order_paid == true"  class="font-size-12 badge badge-soft-success text-success my-auto mr-2">
                          <i class="bx bx-check-circle text-success"></i> Paid
                       </span>
                      <div v-if="list.payments.length > 0" class="my-auto mr-2">
                        <span v-if="list.payments[0].payment_type == 'card'" class="font-size-12 badge badge-soft-primary text-primary">
                          <i class="bx bx-credit-card"></i>
                          Card
                        </span>
                      </div>
                      <div v-else class="my-auto mr-2">
                         <span  class="font-size-12 badge badge-soft-danger text-danger">
                          <i class="bx bx-no-entry"></i> No Payment Made
                         </span>
                      </div>
                      <div class="my-auto" v-if="list.order_cancelled == true">
                        <span class="badge bg-soft font-size-12 bg-danger" > Order Cancelled</span>
                       </div>
                       <div class="my-auto" v-else-if="list.status !== null">
                        <span class="badge bg-soft font-size-12" :style="{'background-color': list.status.color}" > {{list.status.name}}</span>
                       </div>
                  </div>
          </div>
        </div>
    </div>

              <div class="col-lg-12">
                <b-pagination
              v-if="shopsData.data.length > 0"
              class="justify-content-center"
              pills
              v-model="currentPage"
              :total-rows="shopsData.total"
              :per-page="shopsData.per_page"
              aria-controls="my-table"
              @input="productQuery"
            ></b-pagination>
             <small class="mx-auto my-2 text-center d-block">Records {{shopsData.from}} - {{shopsData.to}} of {{shopsData.total}} // Page {{shopsData.current_page}} of {{shopsData.last_page}}</small>
              </div>
      </div>
    </div>
    </div>
    <!--  end row -->

    <div class="row">
      <!-- end col-->
    </div>
     <b-modal  hide-footer size="xl" v-model="printWindow" centered>
      <b-card no-body class="shadow-none border-none mb-0" v-if="(addressBulk == true) && (addressBulkOptions == true)" >
        <b-row>
          <b-col>
            <b-card class="border-radius-1em border shadow-none" body-class="d-flex flex-column align-items-center justify-content-center"  @click="getCustomerAddresses('delivery')">
              <i class="bx bxs-truck font-size-42 text-primary"></i>
              <p class="text-muted">Delivery Addresses</p>
            </b-card>
          </b-col>
          <b-col>
            <b-card class="border-radius-1em border shadow-none" body-class="d-flex flex-column align-items-center justify-content-center" @click="getCustomerAddresses('invoice')">
              <i class="bx bx-receipt font-size-42 text-primary"></i>
              <p class="text-muted">Invoice Addresses</p>
            </b-card>
          </b-col>
        </b-row>
        <b-row>
          <b-col>

          </b-col>
        </b-row>
      </b-card>
      <b-card no-body class="shadow-none border-none mb-0" v-if="addressBulk == false">
        <
        <AddressLabel :address="addressLabel" :addresses="[]"></AddressLabel>
      </b-card>
      <b-card no-body class="shadow-none border-none mb-0" v-if="(addressBulk == true) && (addressLabelsList.length > 0)">
        <AddressLabel v-if="addressLabelsList.length > 0" :address="addressLabelsList[0]" :addresses="addressLabelsList"></AddressLabel>
      </b-card>
    </b-modal>
    <b-modal id="send-email" size="lg" centered hide-footer content-class="border-radius-1em">
          <Emailer :email="send_email" :user="$attrs.user"></Emailer>
        </b-modal>
        <b-modal id="update-status" size="lg" centered hide-footer content-class="border-radius-1em">
          <b-card >
        <h4>Update Status</h4>
        <b-overlay :show="loadingNewStatus">
        <b-form-group label="Current Status:" v-if="actionMode == 'single'">
          <SelectCompanyOrderStatus v-if="order_statuses.length > 0" :id="current_status" :selectdata="order_statuses" :isDisabled="true" :allowAdd="false"></SelectCompanyOrderStatus>
        </b-form-group>
        <b-form-group label="Update To:">
          <SelectCompanyOrderStatus v-if="order_statuses.length > 0" :selectdata="order_statuses" @order-status-selected="new_order_status = $event" :allowAdd="false" ></SelectCompanyOrderStatus>
        </b-form-group>
        <a class="btn btn-primary btn-rounded btn-block mt-3" @click="updateOrderStatus()" v-if="actionMode == 'single'">
          Update Order Status
        </a>
        <a class="btn btn-primary btn-rounded btn-block mt-3" @click="bulkUpdateOrderStatus()" v-else>
          Update Order Statuses
        </a>
      </b-overlay>
      </b-card>
        </b-modal>

        <b-modal id="payment-modal" size="lg" centered hide-footer content-class="border-radius-1em">
          <b-card class="shadow-none" v-if="stateMode == 'single', stateActive == true" >
            <div class="d-flex">
              <div class="d-flex flex-column mt-auto">
            <h4>Mark Order as Paid</h4>
            <p class="mb-0">#{{stateItem.reference}}</p>
              </div>
            <a class="btn btn-primary btn-rounded my-auto ml-auto w-25" :href="'details/'+stateItem.uid" target="_blank">
              <i class="bx bx-receipt"></i> View Order
            </a>
            </div>
            <hr>
            <div class="d-flex">
              <div class="d-flex flex-column mt-auto">
                <h5>Total Order Value: <span class="ml-3 text-primary">{{parseFloat(stateItem.total_order_inc).toFixed(2)}}</span></h5>
                <h5 v-if="(stateItem.payments_sum_amount !== null) && (stateItem.payments_sum_fee !== null)">Total Payments Received: <span class="ml-3 text-primary">{{parseFloat(stateItem.payments_sum_fee) + parseFloat(stateItem.payments_sum_amount)}}</span></h5>
                <h5 v-else-if="(stateItem.payments_sum_amount !== null) && (stateItem.payments_sum_fee == null)">Total Payments Received: <span class="ml-3 text-primary">{{parseFloat(stateItem.payments_sum_amount)}}</span></h5>
                <h5 v-else-if="(stateItem.payments_sum_amount == null) && (stateItem.payments_sum_fee !== null)">Total Payments Received: <span class="ml-3 text-primary">{{parseFloat(stateItem.payments_sum_fee)}}</span></h5>
                <h5 v-else-if="(stateItem.payments_sum_amount == null) && (stateItem.payments_sum_fee == null)">Total Payments Received: <span class="ml-3 text-primary">0.00</span></h5>
              </div>
          <div class="d-flex flex-column my-auto ml-auto w-25">
            <a v-if="(parseFloat(stateItem.payments_sum_fee) + parseFloat(stateItem.payments_sum_amount)) !== parseFloat(stateItem.total_order_inc)" class="btn btn-primary btn-rounded btn-block mt-auto ml-auto mb-1" :href="'details/'+stateItem.uid+'#payemnts-makepayment'" target="_blank">
               <i class="bx bx-credit-card"></i> Make Payment
            </a>
            <a class="btn btn-light btn-rounded btn-block mb-auto mt-1 ml-auto" @click="selectedids = [], selectedids.push(stateItem.uid), updateOrderState('paid', true)" target="_blank">
               <i class="bx bx-check-circle text-success"></i> Mark Paid Manually
            </a>
          </div>
        </div>
          </b-card>
        </b-modal>

        <b-modal id="shippings-modal" size="xl" centered hide-footer content-class="border-radius-1em">
           <b-card class="shadow-none mb-0" >
             
             <div class="d-flex">
              <div class="d-flex flex-column mt-auto">
                <h4>Mark Order as Shipped</h4>
                <p class="mb-0">#{{stateItem.reference}}</p>
              </div>
                <a class="btn btn-primary btn-rounded my-auto ml-auto w-25" :href="'details/'+stateItem.uid" target="_blank">
                  <i class="bx bx-receipt"></i> View Order
                </a>
            </div>
            <hr>
            <b-card class="shadow-none border border-radius-1em mt-3 mb-0" v-if="stateItem !== ''">
              <b-row>
                <b-col md="12">
                  <b-list-group  v-if="'shippings' in stateItem && stateItem.shippings.length > 0">
                    <b-list-group-item v-for="shipping in stateItem.shippings" class="d-flex">
                      <div class="my-auto">
                        <span>{{shipping.dc_request_id}}</span>
                      </div>
                      <div class="my-auto ml-auto mr-3">
                        <span class="spaced-title">Tracking Code:</span>
                        <span class="badge badge-light bg-light font-size-12"># {{shipping.delivery_reference}}</span>
                      </div>
                      <div class="mr-2 ml-auto">
                        <a class="btn btn-outline-light btn-rounded" >Download Label <i class="mdi mdi-download"></i></a>
                      </div>
                      <div class="mr-2 ml-2">
                        <a class="btn btn-outline-light btn-rounded" >Open & Print Label <i class="bx bx-printer"></i></a>
                      </div>
                      <div class="mr-2 ml-2">
                        <a class="btn btn-primary btn-rounded" v-b-modal.action-modal  @click=" action = 'delivery-tracking', getDeliveryStatus(shipping)" >Tracking Progress <i class="bx bx-print"></i></a>
                      </div>
                    </b-list-group-item>
                  </b-list-group>
                  <b-card class="shadow-none border-none mt-3 mb-0" body-class="d-flex flex-column justify-content-center align-items-center text-center p-0" v-else>
              <div class="d-flex flex-column my-auto mx-auto" >
                <i class="bx bxs-truck font-size-42 text-muted"></i>
                <p>
                  No Deliveries Booked
                </p>
                <a class="btn btn-primary btn-rounded" v-b-modal.action-modal @click="action = 'arrange-delivery'">
                  Book New Delivery
                </a>
              </div>
              </b-card>
                </b-col>
              </b-row>
            </b-card>
              <a class="btn btn-primary btn-rounded mt-3 mx-auto" @click="updateOrderState('shipped'. stateActive)" target="_blank">
               <i class="bx bx-check-circle text-success"></i> Mark Shipped Manually
            </a>
            </b-card>
        </b-modal>
        <b-modal id="process-refund" size="lg" centered hide-footer content-class="border-radius-1em">
           <b-card class="shadow-none mb-0" >
            <div class="d-flex">
              <div class="d-flex flex-column mt-auto">
                <h4>Mark Order as Shipped</h4>
                <p class="mb-0">#{{stateItem.reference}}</p>
              </div>
                <a class="btn btn-primary btn-rounded my-auto ml-auto w-25" :href="'details/'+stateItem.uid" target="_blank">
                  <i class="bx bx-receipt"></i> View Order
                </a>
            </div>
            <hr>
            <div class="d-flex">
              <div class="d-flex flex-column mt-auto">
                <h5>Total Order Value: <span class="ml-3 text-primary">{{parseFloat(stateItem.total_order_inc).toFixed(2)}}</span></h5>
                <h5 v-if="(stateItem.payments_sum_amount !== null) && (stateItem.payments_sum_fee !== null)">Total Payments Received: <span class="ml-3 text-primary">{{parseFloat(stateItem.payments_sum_fee) + parseFloat(stateItem.payments_sum_amount)}}</span></h5>
                <h5 v-else-if="(stateItem.payments_sum_amount !== null) && (stateItem.payments_sum_fee == null)">Total Payments Received: <span class="ml-3 text-primary">{{parseFloat(stateItem.payments_sum_amount)}}</span></h5>
                <h5 v-else-if="(stateItem.payments_sum_amount == null) && (stateItem.payments_sum_fee !== null)">Total Payments Received: <span class="ml-3 text-primary">{{parseFloat(stateItem.payments_sum_fee)}}</span></h5>
                <h5 v-else-if="(stateItem.payments_sum_amount == null) && (stateItem.payments_sum_fee == null)">Total Payments Received: <span class="ml-3 text-primary">0.00</span></h5>
              </div>
          <div class="d-flex flex-column my-auto ml-auto w-25">
            <a v-if="(parseFloat(stateItem.payments_sum_fee) + parseFloat(stateItem.payments_sum_amount)) == parseFloat(stateItem.total_order_inc)" class="btn btn-primary btn-rounded btn-block mt-auto ml-auto mb-1" :href="'details/'+stateItem.uid+'#payemnts-makepayment'" target="_blank">
               <i class="bx bx-credit-card"></i> Process Full Refund
            </a>
            <a class="btn btn-light btn-rounded btn-block mb-auto mt-1 ml-auto" @click="selectedids = [], selectedids.push(stateItem.uid), updateOrderState('paid', true)" target="_blank">
               <i class="bx bx-check-circle text-success"></i> Process Partial Refund
            </a>
          </div>
        </div>
           </b-card>
        </b-modal>
    <SideSlidePanel :editPanel="showListingProducts" :panelClass="'edit-sidepanel-large'" @close-panel="showListingProducts = 0">
      <div class="row">
        
      </div>
    </SideSlidePanel>

      <SideSlidePanel :editPanel="showFilters" :panelClass="'edit-sidepanel-slim'" @close-panel="showFilters = !showFilters">     
         <div class="col-lg-12" v-if="showFilters == true">
        <div class="">
          <div class="p-3">
            <h4 class="card-title mb-2">Source</h4>


              <div class="row">
                <div class="col-md-12">

                  <a href="javascript:;" v-for="source in ordersources" class="badge badge-light bg-light m-2 font-size-14" @click="addToSourcesFilter(source)" >
                    <i class="bx bx-check-circle text-success" v-if="search_sources.includes(source.key)"></i>
                    {{source.name}}
                  </a>
                </div>
              </div>


            <div>
               <div class="my-3 pt-3">
              <h5 class="font-size-14">Date Range</h5>
              <b-form-group label="Start Date" class="w-50 d-inline float-left mt-2 mb-3 px-2">
              <b-form-input
                type="date"
                v-model="dateRange[0]"
                @input="productQuery"></b-form-input>
              </b-form-group>
              <b-form-group label="End Date" class="w-50 d-inline float-left mt-2 mb-3 px-2">
                 <b-form-input
                 label="Max Price"
                 type="date"
                 v-model="dateRange[1]"
                 @input="productQuery"></b-form-input>
              </b-form-group>
            </div>
                <h5 class="font-size-14 my-3">User</h5>
               <SearchCompanyUser :company="$attrs.company" :api_token="$attrs.user.api_token" :id="search_user" @user-selected="userSelected" @user-removed="userRemoved"></SearchCompanyUser>


               <h5 class="font-size-14 my-3">Customer</h5>
              
                <CompanyCustomerSelect ref="selectcustomer" :api_token="$attrs.user.api_token" @search-resource-selected="customerSelected" :input_classes="'bg-light'"  @user-removed="custoemrRemoved"></CompanyCustomerSelect>

               <h5 class="font-size-14 my-3">Shop</h5>
               <SearchCompanyLocation :company="$attrs.company" :api_token="$attrs.user.api_token" :id="search_location" @listing-selected="locationSelected"  @listing-removed="locationRemoved"></SearchCompanyLocation>
            </div>
           

          <!-- <div class="mt-4 pt-3">
              <h5 class="font-size-14 mb-3">Customer Rating</h5>
              <div>
                <b-form-checkbox
                  id="checkbox-1"
                  name="checkbox-1"
                  value="accepted"
                  unchecked-value="not_accepted"
                >
                  4
                  <i class="bx bx-star text-warning"></i> & Above
                </b-form-checkbox>

                <b-form-checkbox
                  id="checkbox-2"
                  class="mt-2"
                  name="checkbox-2"
                  value="accepted"
                  unchecked-value="not_accepted"
                >
                  3
                  <i class="bx bx-star text-warning"></i> & Above
                </b-form-checkbox>

                <b-form-checkbox
                  id="checkbox-3"
                  class="mt-2"
                  name="checkbox-3"
                  value="accepted"
                  unchecked-value="not_accepted"
                >
                  2
                  <i class="bx bx-star text-warning"></i> & Above
                </b-form-checkbox>
                <b-form-checkbox
                  id="checkbox-4"
                  class="mt-2"
                  name="checkbox-4"
                  value="accepted"
                  unchecked-value="not_accepted"
                >
                  1
                  <i class="bx bx-star text-warning"></i>
                </b-form-checkbox>
              </div>
            </div>-->
          </div>
        </div>
      </div>
    </SideSlidePanel>
    <!-- end row -->
  </Layout>
</template>

<style >
  .bg-pos{
    background-color: #FEA100;
    border-color: #FEA100;
    color: #fff;
  }
</style>