<script>
import { menuItems } from "../../../../../website-views/default-menu";
import simplebar from "simplebar-vue";
import i18n from "../../../../../websitei18n";
import Login from "../../../../../website-views/account/onpageLogin";
import { EventBus } from '../../../../../website-app'

export default {
  components: {
    simplebar,
    Login
  },
  data() {
    return {
      menuItemsRaw: menuItems,
      menuItems: [],
      menuItemsSlice1: 0,
      menuItemsSlice2: 0,
      csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
      storageurl: 'https://'+this.company.nickname+'.s3.eu-west-2.amazonaws.com',
      languages: [
        {
          flag: "/images/flags/us.jpg",
          language: "en",
          title: "English",
        },
        {
          flag: "/images/flags/french.jpg",
          language: "fr",
          title: "French",
        },
        {
          flag: "/images/flags/spain.jpg",
          language: "es",
          title: "Spanish",
        },
        {
          flag: "/images/flags/chaina.png",
          language: "zh",
          title: "Chinese",
        },
        {
          flag: "/images/flags/arabic.png",
          language: "ar",
          title: "Arabic",
        },
      ],
      currencies: [
        {
          icon: "bx bx-pound",
          code: "GBP",
          title: "GBP (British Pound)",
        },
        {
          icon: "bx bx-dollar",
          code: "USD",
          title: "USD (US Dollar)",
        },
        {
          icon: "bx bx-euro",
          code: "EUR",
          title: "EUR (Euro)",
        }
      ],
      cart: {
        products: [],
        uid: '',
        id: '',
      },
      currency: '',
      cartname: '',
      currencyicon: 'bx bx-pound',
      currencytext: 'GBP',
      cartLoading: false,
      initiateCheckout: false,
      cartPanelData: [],
      cartPanel: false,
      showCart: false, 
      showSearch: false,
      lan: i18n.locale,
      text: null,
      flag: null,
      value: null,
    };
  },
  props: {
    company: {
      type: Object
    },
    user: {
      type: Object
    },
    domain: {
      type: Object
    },
    content: {
      type: Object
    },
    elements: {
      type: Object
    },
    filters: {
      type: Object
    },
    styles: {
      type: Object
    },
    liveMode: {
      type: Boolean,
      default: false
    }
  },
  created() {
    //this.cartname = this.company.nickname + this.company.uid.slice(0,4);
    this.getMenu(this.filters.menus.value);
    var session_cart = window.sessionStorage.getItem("websitecart");
    session_cart = JSON.parse(session_cart);
    if(session_cart == null){
      this.checkAndGetCart();
    }else{
      this.cart = session_cart;
    }
    if(this.content[this.filterStylesKey(this.content, 'layout')].layout == 'Layout 2'){
      this.menuItemsSlice1 = ((this.menuItems.length - 1) / 2).toFixed(0);
    }else{
      this.menuItemsSlice1 = this.menuItems.length - 1;
    }
  },
  watch:{
    '$attrs.content[*].layout'() {
      if(this.content[this.filterStylesKey(this.content, 'layout')].layout == 'Layout 2'){
        this.menuItemsSlice1 = ((this.menuItems.length - 1) / 2).toFixed(0);
        this.menuItemsSlice2 = this.menuItems.length - 1;
      }else{
        this.menuItemsSlice1 = this.menuItems.length - 1;
      }
    }
  },
  mounted() {

    this.value = this.languages.find((x) => x.language === i18n.locale);
   // this.text = this.value.title;
    //this.flag = this.value.flag;
    let self = this;
    EventBus.$on('add-to-cart', function(product, qty, variants, combination){
      console.log('Cart Event Triggered');
       self.addToCart(product, qty, variants, combination);
    });
    var links = document.getElementsByClassName("side-nav-link-ref");
    var matchingMenuItem = null;
    for (var i = 0; i < links.length; i++) {
      if (window.location.pathname === links[i].pathname) {
        matchingMenuItem = links[i];
        break;
      }
    }

    if (matchingMenuItem) {
      matchingMenuItem.classList.add("active");
      var parent = matchingMenuItem.parentElement;

      /**
       * TODO: This is hard coded way of expading/activating parent menu dropdown and working till level 3.
       * We should come up with non hard coded approach
       */
      if (parent) {
        parent.classList.add("active");
        const parent2 = parent.parentElement;
        if (parent2) {
          parent2.classList.add("active");
        }
        const parent3 = parent2.parentElement;
        if (parent3) {
          parent3.classList.add("active");
          var childAnchor = parent3.querySelector(".has-dropdown");
          if (childAnchor) childAnchor.classList.add("active");
        }

        const parent4 = parent3.parentElement;
        if (parent4) parent4.classList.add("active");
        const parent5 = parent4.parentElement;
        if (parent5) parent5.classList.add("active");
      }
    }
    if(this.content[this.filterStylesKey(this.content, 'layout')].layout == 'Layout 2'){
        this.menuItemsSlice1 = ((this.menuItems.length - 1) / 2).toFixed(0);
        this.menuItemsSlice2 = this.menuItems.length - 1;
      }else{
        this.menuItemsSlice1 = this.menuItems.length - 1;
      }
  },
  methods: {
    getMenu(uid){
      axios.get('/website-api/widget/menu/'+uid).then(response => {
        this.menuItems = response.data.menuitems;
      }).catch(error => {
        this.menuItems = this.menuItemsRaw;
      });
    },
    onMenuClick(event) {
      event.preventDefault();
      const nextEl = event.target.nextElementSibling;
      if (nextEl && !nextEl.classList.contains("show")) {
        const parentEl = event.target.parentNode;
        if (parentEl) {
          parentEl.classList.remove("show");
        }
        nextEl.classList.add("show");
      } else if (nextEl) {
        nextEl.classList.remove("show");
      }
      return false;
    },
    filterStylesKey(styles, key){
            for(var i = 0; i < styles.length; i++){
               var keys = Object.keys(styles[i])
               if(keys.includes(key)){
                 return i;
               }
            }
          },
    /**
     * Returns true or false if given menu item has child or not
     * @param item menuItem
     */
    hasItems(item) {
      return item.menuitems !== undefined ? item.menuitems.length > 0 : false;
    },

    topbarLight() {
      document.body.setAttribute("data-topbar", "light");
      document.body.removeAttribute("data-layout-size", "boxed");
    },
    boxedWidth() {
      document.body.setAttribute("data-layout-size", "boxed");
      document.body.removeAttribute("data-topbar", "light");
      document.body.setAttribute("data-topbar", "dark");
    },
    coloredHeader() {
      document.body.setAttribute("data-topbar", "colored");
      document.body.removeAttribute("data-layout-size", "boxed");
    },
    checkAndGetCart(){
      axios.get('/website-api/cart/read?cart_uid='+this.cart.uid, {headers: {
                  'Authorization': 'Bearer ' + this.user.api_token
              }}).then(response => {
        console.log(response);
          if(response.data !== 0){
            this.cart = response.data;
            window.sessionStorage.setItem("websitecart", JSON.stringify(response.data));
          }else{
            window.sessionStorage.setItem("websitecart", JSON.stringify(this.cart))
          }
        
      }).catch(error => {
        this.error_reponse = error.response.data.errors;
      });
    },
    cartTotal(cart){
          var score = 0;
          if(cart.products.length > 1){
            for(var i = 0; i < cart.products.length; i++){
              var itemscore = (parseFloat(cart.products[i].price_exc) * parseFloat(cart.products[i].quantity));
              score = score + itemscore;
            }
          }else{
            score = cart.products[0].price_exc * cart.products[0].quantity;
          }
          return (score).toFixed(2);
        },
    addToCart(productdata, qty, variantsdata, combinationdata){
      this.cartLoading = true;
      if(this.cart.uid == ''){
        this.cart.products.push({product: productdata});
        this.createCart(productdata);
      }else{
        axios.post('/website-api/cart/'+this.cart.uid+'/add-product', {product: productdata, quantity: qty, variants: variantsdata, combination: combinationdata}, {headers: {
                  'Authorization': 'Bearer ' + this.user.api_token
              }}).then(response => {
        var carindex = this.cart.products.findIndex(item => item.product_id == productdata.id);
            if(carindex !== -1){
              this.cart.products[carindex] = response.data;
              this.cart.products[carindex]["product"] = productdata;
              window.sessionStorage.setItem("websitecart", JSON.stringify(this.cart));
              this.productAdded(productdata);
            }else if(carindex == -1){
              this.cart["products"].push(response.data);
              this.cart["products"][(this.cart.products.length - 1)]["product"] = productdata;
              window.sessionStorage.setItem("websitecart", JSON.stringify(this.cart));
              this.productAdded(productdata);
            }
      }).catch(error => {
        this.error_reponse = error.response.data.errors;
      });
      } 
    },
    removeProduct(id, index){
      axios.post('/website-api/cart/product/'+id+'/delete', {headers: {
                  'Authorization': 'Bearer ' + this.user.api_token
              }}).then(response => {
          this.cart.products.splice(index, 1);
          if(this.cart.length == 0){
            window.sessionStorage.deleteItem("websitecart");
            //this.checkAndGetCart();
          }else{
            window.sessionStorage.setItem("websitecart", JSON.stringify(this.cart));
          }
        }).catch(error => {
          this.error_reponse = error.response.data.errors;
        });
    },
    productAdded(product){
      //this.cartPanelData = product;
      //this.cartPanel = true;
      this.cartLoading = false;
      this.$refs.cart.show(true);
    },
    createCart(product){
      var token = '';
      if(this.user !== ''){
        token = this.user.api_token;
      }
      axios.post('/website-api/cart/create', product, {headers: {
                  'Authorization': 'Bearer ' + token
              }}).then(response => {
        this.cart = response.data[0];
        this.cart["products"] = [];
        this.cart.products.push(response.data[1]);
        this.cart.products[0]["product"] = product;
        window.sessionStorage.setItem("websitecart", JSON.stringify(this.cart));
        this.productAdded(product);
      }).catch(error => {

      });
    },
    toggleRightSidebar() {
      this.$parent.toggleRightSidebar();
    },
    toggleMenu() {
      let element = document.getElementById("topnav-menu-content");
      element.classList.toggle("show");
    },
    initFullScreen() {
      document.body.classList.toggle("fullscreen-enable");
      if (
        !document.fullscreenElement &&
        /* alternative standard method */ !document.mozFullScreenElement &&
        !document.webkitFullscreenElement
      ) {
        // current working methods
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen(
            Element.ALLOW_KEYBOARD_INPUT
          );
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        }
      }
    },
    setCurrency(code, title, currencyicon) {
      this.currencycode = code;
      this.currencytext = code;
      this.currencyicon = currencyicon;
      i18n.locale = locale;
      localStorage.setItem("currency", locale);
    },
    setLanguage(locale, country, flag) {
      this.lan = locale;
      this.text = country;
      this.flag = flag;
      i18n.locale = locale;
      localStorage.setItem("locale", locale);
    },
    goToCheckout(){
      if(this.$route.path == ('order' || '/order')){
        this.$router.go(0);
      }
    }
  },
};
</script>

<template>
  <header v-if="content[filterStylesKey(content, 'layout')].layout.value == 'Layout 1'" id="pb-menu-layout-1" class="shadow-none">
    <div class="navbar-header" :class="{'bg-dark' : content[filterStylesKey(content, 'layout_colour')].layout_colour.value == 'Dark'}">
      <div class="d-flex w-25" v-if="content[filterStylesKey(content, 'logo_position')].logo_position.value == 'logo center'" >

        <b-dropdown
          v-if="(elements[filterStylesKey(elements, 'show_search')].show_search.value == true)"
          class="d-inline-block d-lg-none ml-2"
          variant="black"
          menu-class="dropdown-menu-lg p-0 dropdown-menu-end"
          toggle-class="header-item noti-icon"
          right
        >
          <template v-slot:button-content>
            <i class="mdi mdi-magnify"></i>
          </template>

          <form class="p-3">
            <div class="form-group m-0">
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Search ..."
                  aria-label="Recipient's username"
                />
                <div class="input-group-append">
                  <button class="btn btn-primary" type="submit">
                    <i class="mdi mdi-magnify"></i>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </b-dropdown>

        <!--<b-dropdown variant="white" right toggle-class="header-item">
          <template v-slot:button-content>
            <img class :src="flag" alt="Header Language" height="16" />
            {{ text }}
          </template>
          <b-dropdown-item
            class="notify-item"
            v-for="(entry, i) in languages"
            :key="`Lang${i}`"
            :value="entry"
            @click="setLanguage(entry.language, entry.title, entry.flag)"
            :class="{ active: lan === entry.language }"
          >
            <img
              :src="`${entry.flag}`"
              alt="user-image"
              class="me-1"
              height="12"
            />
            <span class="align-middle">{{ entry.title }}</span>
          </b-dropdown-item>
        </b-dropdown>-->



        <!--<div class="dropdown d-none d-lg-inline-block ml-1">
          <button
            type="button"
            class="btn header-item noti-icon"
            @click="initFullScreen"
          >
            <i class="bx bx-fullscreen"></i>
          </button>
        </div>-->
        <div class="dropdown d-none d-lg-inline-block ml-1">
          <button  
            v-if="(elements[filterStylesKey(elements, 'show_search')].show_search.value == true)"
            type="button"
            class="btn header-item noti-icon"
            @click="showSearch = !showSearch"
          >
            <i class="bx bx-search" :class="{'text-white' : content[filterStylesKey(content, 'layout_colour')].layout_colour.value == 'Dark'}"></i>
          </button>
        </div>
        <b-dropdown
          v-if="elements[filterStylesKey(elements, 'show_currency')].show_currency.value == true"
          variant="white" right toggle-class="header-item noti-icon"
        >
          <template v-slot:button-content>
            <i :class="[`${currencyicon}`, {'text-white' : content[filterStylesKey(content, 'layout_colour')].layout_colour.value == 'Dark'}]" :alt="'Pay in ' + currency" ></i>
          </template>

          <b-dropdown-item
            class="notify-item"
            v-for="(entry, i) in currencies"
            :key="`Lang${i}`"
            :value="entry"
            @click="setCurrency(entry.code, entry.title, entry.icon)"
            :class=" {'active' : lan === entry.language}"
          >
            <i :class="`${entry.icon}`" :alt="'Pay in ' + entry.title" class="me-1" ></i>
            <span class="align-middle">{{ entry.title }}</span>
          </b-dropdown-item>
        </b-dropdown>

      </div>
      <div class="d-flex" :class="{'w-100' : content[filterStylesKey(content, 'logo_position')].logo_position.value == 'logo left', 'w-50' : content[filterStylesKey(content, 'logo_position')].logo_position.value == 'logo center'}">
        <!-- LOGO -->
        <div class="navbar-brand-box px-0 bg-transparent" :class="{'text-center mx-auto' : content[filterStylesKey(content, 'logo_position')].logo_position.value == 'logo center'}" v-if="content[filterStylesKey(content, 'logo_position')].logo_position.value !== 'logo right'">
          <a href="/" class="logo" >
            <span class="logo-sm">
              <img v-if="content[filterStylesKey(content, 'logo')].logo.value !== ''" :src="content[filterStylesKey(content, 'logo')].logo.value" :width="content[filterStylesKey(content, 'logo')].logo.width.value + content[filterStylesKey(content, 'logo')].logo.width.units" :height="content[filterStylesKey(content, 'logo')].logo.height.value + content[filterStylesKey(content, 'logo')].logo.height.units">
              <div v-else class="d-flex logo-text-holder"><span class="logo-text">{{company.name.slice(0, 1)}}</span></div>
            </span>
            <span class="logo-lg">
              <img v-if="content[filterStylesKey(content, 'logo')].logo.value !== ''" :src="content[filterStylesKey(content, 'logo')].logo.value" :width="content[filterStylesKey(content, 'logo')].logo.width.value + content[filterStylesKey(content, 'logo')].logo.width.units" :height="content[filterStylesKey(content, 'logo')].logo.height.value + content[filterStylesKey(content, 'logo')].logo.height.units">
              <div v-else class="d-flex logo-text-holder"><span class="logo-text" :class="{'text-dark' : content[filterStylesKey(content, 'layout_colour')].layout_colour.value == 'Light', 'text-white' : content[filterStylesKey(content, 'layout_colour')].layout_colour.value == 'Dark'}">{{company.name}}</span></div>
            </span>
          </a>

          
        </div>

        <button
          id="toggle"
          type="button"
          class="btn btn-sm me-2 font-size-16 d-lg-none header-item"
          @click="toggleMenu"
        >
          <i class="fa fa-fw fa-bars"></i>
        </button>
        
      </div>
      <transition name="fade-right">
         <form class="app-search d-none d-lg-block ml-auto w-50 my-auto" v-if="(showSearch == true) && (content[filterStylesKey(content, 'logo_position')].logo_position.value == 'logo left')" >
          <div class="position-relative">
            <input
              type="text"
              class="form-control bg-light bg-soft"
              :placeholder="$t('navbar.search.text')"
               :class="{'bg-dark' : content[filterStylesKey(content, 'layout_colour')].layout_colour.value == 'Dark', 'bg-light' : content[filterStylesKey(content, 'layout_colour')].layout_colour.value == 'Light'}"
            />
            <span class="bx bx-search-alt" :class="{'text-light' : content[filterStylesKey(content, 'layout_colour')].layout_colour.value == 'Dark'}" ></span>
          </div>
        </form>
      </transition>
      <transition name="fade-top">
         <form class="app-search d-none d-lg-block mx-auto w-50 my-auto position-absolute top-0 right-0 left-0" v-if="(showSearch == true) && ((content[filterStylesKey(content, 'logo_position')].logo_position.value == 'logo center') || (content[filterStylesKey(content, 'logo_position')].logo_position.value == 'logo right'))"  :class="{'bg-dark' : content[filterStylesKey(content, 'layout_colour')].layout_colour.value == 'Dark', 'bg-white' : content[filterStylesKey(content, 'layout_colour')].layout_colour.value == 'Light'}" >
          <div class="position-relative">
            <input
              type="text"
              class="form-control bg-light bg-soft"
              :placeholder="$t('navbar.search.text')"
               :class="{'bg-dark' : content[filterStylesKey(content, 'layout_colour')].layout_colour.value == 'Dark', 'bg-light' : content[filterStylesKey(content, 'layout_colour')].layout_colour.value == 'Light'}"
            />
            <span class="bx bx-search-alt" :class="{'text-light' : content[filterStylesKey(content, 'layout_colour')].layout_colour.value == 'Dark'}" ></span>
          </div>
        </form>
      </transition>
      <div class="d-flex"  :class="{'ml-auto' : content[filterStylesKey(content, 'logo_position')].logo_position.value == 'logo left', 'mr-auto' : content[filterStylesKey(content, 'logo_position')].logo_position.value == 'logo right', 'ml-auto w-25' : content[filterStylesKey(content, 'logo_position')].logo_position.value == 'logo center'}">
        <b-dropdown
          v-if="(elements[filterStylesKey(elements, 'show_search')].show_search.value == true) && (content[filterStylesKey(content, 'logo_position')].logo_position.value !== 'logo center')"
          class="d-inline-block d-lg-none ml-2"
          variant="black"
          menu-class="dropdown-menu-lg p-0 dropdown-menu-end"
          toggle-class="header-item noti-icon"
          right
        >
          <template v-slot:button-content>
            <i class="mdi mdi-magnify"></i>
          </template>

          <form class="p-3">
            <div class="form-group m-0">
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Search ..."
                  aria-label="Recipient's username"
                />
                <div class="input-group-append">
                  <button class="btn btn-primary" type="submit">
                    <i class="mdi mdi-magnify"></i>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </b-dropdown>

        <!--<b-dropdown variant="white" right toggle-class="header-item">
          <template v-slot:button-content>
            <img class :src="flag" alt="Header Language" height="16" />
            {{ text }}
          </template>
          <b-dropdown-item
            class="notify-item"
            v-for="(entry, i) in languages"
            :key="`Lang${i}`"
            :value="entry"
            @click="setLanguage(entry.language, entry.title, entry.flag)"
            :class="{ active: lan === entry.language }"
          >
            <img
              :src="`${entry.flag}`"
              alt="user-image"
              class="me-1"
              height="12"
            />
            <span class="align-middle">{{ entry.title }}</span>
          </b-dropdown-item>
        </b-dropdown>-->



        <!--<div class="dropdown d-none d-lg-inline-block ml-1">
          <button
            type="button"
            class="btn header-item noti-icon"
            @click="initFullScreen"
          >
            <i class="bx bx-fullscreen"></i>
          </button>
        </div>-->
        <div class="dropdown d-none d-lg-inline-block ml-1">
          <button  
            v-if="(elements[filterStylesKey(elements, 'show_search')].show_search.value == true) && (content[filterStylesKey(content, 'logo_position')].logo_position.value !== 'logo center')"
            type="button"
            class="btn header-item noti-icon"
            @click="showSearch = !showSearch"
          >
            <i class="bx bx-search" :class="{'text-white' : content[filterStylesKey(content, 'layout_colour')].layout_colour.value == 'Dark'}"></i>
          </button>
        </div>
        <b-dropdown
          v-if="(content[filterStylesKey(content, 'logo_position')].logo_position.value !== 'logo center') && (elements[filterStylesKey(elements, 'show_currency')].show_currency.value == true)"
          variant="white" right toggle-class="header-item noti-icon"
        >
          <template v-slot:button-content>
            <i :class="[`${currencyicon}`, {'text-white' : content[filterStylesKey(content, 'layout_colour')].layout_colour.value == 'Dark'}]" :alt="'Pay in ' + currency" ></i>
          </template>

          <b-dropdown-item
            class="notify-item"
            v-for="(entry, i) in currencies"
            :key="`Lang${i}`"
            :value="entry"
            @click="setCurrency(entry.code, entry.title, entry.icon)"
            :class=" {'active' : lan === entry.language}"
          >
            <i :class="`${entry.icon}`" :alt="'Pay in ' + entry.title" class="me-1" ></i>
            <span class="align-middle">{{ entry.title }}</span>
          </b-dropdown-item>
        </b-dropdown>
        <b-dropdown
        v-if="elements[filterStylesKey(elements, 'show_cart')].show_cart.value == true"
          right
          menu-class="dropdown-menu-lg p-0 dropdown-menu-end cart-dropdown border-radius-1em"
          toggle-class="header-item noti-icon"
          variant="black"
          ref="cart"
          :class="{'ml-auto' : content[filterStylesKey(content, 'logo_position')].logo_position.value == 'logo center'}"
        >
          <template v-slot:button-content>
            <i class="bx bx-shopping-bag" :class="{'text-white' : content[filterStylesKey(content, 'layout_colour')].layout_colour.value == 'Dark'}"></i>
            <span
              class="badge bg-danger rounded-pill"
            >{{cart.products.length}}</span>
          </template>

          <div class="p-3">
            <div class="row align-items-center">
              <div class="col">
                <h6 class="m-0">Shopping Cart</h6>
              </div>
              <div class="col-auto">
                <a href="#" class="small">View Cart</a>
              </div>
            </div>
          </div>
          <simplebar style="max-height: 430px;">
          <b-list-group v-if="cartLoading == false">
            <b-list-group-item  v-for="(productitem, index) in cart.products" href="javascript: void(0);" class="text-reset notification-item px-0 py-3 m-0">
              <div class="media">
                <div class="avatar-sm me-3">
                  <img v-if="productitem.product.cover_image" :src="storageurl+productitem.product.cover_image.src.replaceAll('//', '/')" class="avatar-title rounded-circle">
                  <span v-else class="avatar-title bg-primary rounded-circle font-size-16" >
                    <i class="bx bx-cart"></i>
                  </span>
                </div>
                <div class="cart-name-cell my-auto w-50">
                  <h6 class="mt-0 mb-1 text-truncate">{{productitem.product.name}}</h6>
                </div>
                <div class="my-auto text-right d-flex flex-column mx-auto">
                  <a href="javascript:;" class="text-center" @click="productitem.quantity++"><i class="bx bx-chevron-up mb-auto mx-auto"></i></a>
                  <b-form-input type="number"  v-model="productitem.quantity" class="visible mx-auto my-auto d-none" style="width: 25%;" >
                  </b-form-input>
                  <span class="mx-auto my-auto">{{productitem.quantity}}</span>
                  <a v-if="productitem.quantity > 1" href="javascript:;" class="text-center" @click="productitem.quantity--"><i class="bx bx-chevron-down mt-auto mx-auto"></i></a>
                  <a v-else href="javascript:;" class="text-center" @click="removeProduct(productitem.id, index)"><i class="bx bx-chevron-down mt-auto mx-auto"></i></a>
                </div>
                <div class="my-auto text-center d-flex flex-column mx-auto">
                  <p class="mb-0"><span class="text-muted">£</span>{{productitem.price_exc}}</p>
                  <small>Item Price Exc</small>
                </div>
                <div class="my-auto text-center d-flex flex-column mx-auto">
                  <p class="mb-0"><span class="text-muted">£</span>{{(productitem.price_exc * productitem.quantity).toFixed(2)}}</p>
                  <small>Total Price Exc</small>
                </div>
                <div class="my-auto mx-auto text-center">
                 <a class="btn btn-outline-light btn-rounded" @click="removeProduct(productitem.id, index)"><i class="bx bx-trash-alt"></i></a>
                </div>
              </div>
            </b-list-group-item>
            <b-list-group-item v-if="cart.products.length == 0">
              <div class="p-5 text-center border">
                <i class="bx bx-shopping-bag font-size-42 text-secondary mb-2"></i>
                <h6>No Products in Cart</h6>
              </div>
            </b-list-group-item>
          </b-list-group>
            

          </simplebar>
          <b-overlay
            :show="cartLoading">
              <div class="p-4 border-top d-flex bg-light" v-if="(cart.products.length !== 0) && (cartLoading == false)">
                <strong class="mr-auto flex-grow-2"> Total: </strong>
                  <span class="mx-auto flex-grow-1 text-center"><strong class="text-primary mr-2">£</strong> {{cartTotal(cart)}}</span>
              </div>
          </b-overlay>
         <div class="p-2 border-top d-grid" v-if="cart.products.length !== 0">
            <a class="btn btn-sm btn-link font-size-14 text-center btn-primary text-white btn-rounded" :href="{ name: 'Order', path: '/order', params: { cart } }" @click="goToCheckout()" >
              <i class=" me-1"></i>
              <span key="t-view-more"> Checkout Now </span>
            </a>
          </div>
        </b-dropdown>
        <b-dropdown
        v-if="elements[filterStylesKey(elements, 'show_account')].show_account.value == true"
          right
          variant="black"
          toggle-class="header-item"
          menu-class="dropdown-menu-end"
          :menu-class="{'border-radius-1em p-0 login-dropdown dropdown-menu-end' : !user}"
        >
          <template v-slot:button-content>
            <div v-if="user">
            <img
              v-if="user.avatar"
              class="rounded-circle header-profile-user"
              :src="user.avatar"
              alt="Header Avatar"
            />
            <img v-else
              class="rounded-circle header-profile-user"
              src="/images/product-placeholder.png"
            > 
            <span class="d-none d-xl-inline-block ml-1" :class="{'text-white' : content[filterStylesKey(content, 'layout_colour')].layout_colour.value == 'Dark'}">{{user.name}} {{user.lastname}}</span>
            <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
            </div>
            <div v-else>
              <img
              class="rounded-circle header-profile-user"
              src="/images/product-placeholder.png"
              alt="Sign in to Gemesys"
            />
            <span class="d-none d-xl-inline-block ml-1" :class="{'text-white' : content[filterStylesKey(content, 'layout_colour')].layout_colour.value == 'Dark'}">
              My Account
            </span>
            <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
            </div>

            
          </template>
          <!-- item-->
           <div v-if="user">
          <b-dropdown-item href="/account">
            <i class="bx bx-user font-size-16 align-middle me-1"></i>
            My Account
          </b-dropdown-item>
          <b-dropdown-item href="/account#addresses">
            <i class="bx bx-home-alt font-size-16 align-middle me-1"></i>
            Addresses
          </b-dropdown-item>
          <b-dropdown-item href="/account#orders">
            <i class="bx bx-tag font-size-16 align-middle me-1"></i>
            Orders
          </b-dropdown-item>
          <b-dropdown-item class="d-block"  href="/account#messages">
            <span class="badge bg-success float-end">11</span>
            <i class="bx bx-chat font-size-16 align-middle me-1"></i>
            Messages
          </b-dropdown-item>
          <b-dropdown-item  href="/account#wishlist">
            <i class="mdi mdi-heart-outline font-size-16 align-middle me-1"></i>
            Wishlist
          </b-dropdown-item>

          <b-dropdown-divider></b-dropdown-divider>
          <a href="/account/logout" class="dropdown-item text-danger">
            <i
              class="bx bx-power-off font-size-16 align-middle me-1 text-danger"
            ></i>
            {{ $t("navbar.dropdown.henry.list.logout") }}
          </a>
          </div>
          <div v-else class="p-0">
            <Login :submit-url="'http://website.'+domain.domain_key+'.gemesys.co.uk/account/login'" auth-error="">
                 <input type="hidden" name="_token" :value="csrf">
            </Login>
          </div>
        </b-dropdown>
      
        <!--<div class="dropdown d-inline-block">
          <button
            type="button"
            class="btn header-item noti-icon right-bar-toggle toggle-right"
            @click="toggleRightSidebar"
          >
            <i class="bx bx-cog bx-spin toggle-right"></i>
          </button>
        </div>-->
      </div>
       <div class="navbar-brand-box px-0 bg-transparent" :class="{'text-right' : content[filterStylesKey(content, 'logo_position')].logo_position.value == 'logo right'}" v-if="(content[filterStylesKey(content, 'logo_position')].logo_position.value == 'logo right')">
          <a href="/" class="logo" >
            <span class="logo-sm">
              <img v-if="content[filterStylesKey(content, 'logo')].logo.value !== ''" :src="content[filterStylesKey(content, 'logo')].logo.value" :width="content[filterStylesKey(content, 'logo')].logo.width.value + content[filterStylesKey(content, 'logo')].logo.width.units" :height="content[filterStylesKey(content, 'logo')].logo.height.value + content[filterStylesKey(content, 'logo')].logo.height.units" >
              <div v-else class="d-flex logo-text-holder"><span class="logo-text">{{company.name.slice(0, 1)}}</span></div>
            </span>
            <span class="logo-lg">
              <img v-if="content[filterStylesKey(content, 'logo')].logo.value !== ''" :src="content[filterStylesKey(content, 'logo')].logo.value" :width="content[filterStylesKey(content, 'logo')].logo.width.value + content[filterStylesKey(content, 'logo')].logo.width.units" :height="content[filterStylesKey(content, 'logo')].logo.height.value + content[filterStylesKey(content, 'logo')].logo.height.units" >
              <div v-else class="d-flex logo-text-holder"><span class="logo-text" :class="{'text-dark' : content[filterStylesKey(content, 'layout_colour')].layout_colour.value == 'Light', 'text-white' : content[filterStylesKey(content, 'layout_colour')].layout_colour.value == 'Dark'}">{{company.name}}</span></div>
            </span>
          </a>

          
        </div>
    </div>
    <div class="border-top" :class="{'bg-dark' : content[filterStylesKey(content, 'layout_colour')].layout_colour.value == 'Dark', 'bg-white' : content[filterStylesKey(content, 'layout_colour')].layout_colour.value !== 'Dark'}">
    <div class="container-fluid">
      <nav class="navbar navbar-light navbar-expand-lg topnav-menu">
        <div id="topnav-menu-content" class="justify-content-center collapse navbar-collapse">
          <ul class="navbar-nav">
            <!-- Menu data -->

            <template v-for="(item, index) of menuItems">
              <li class="nav-item dropdown" :key="index">
                <router-link
                  class="nav-link dropdown-toggle arrow-none"
                  v-if="!hasItems(item)"
                  id="topnav-components"
                  :to="'/'+item.link"
                  role="button"
                  :class="{'text-white' : content[filterStylesKey(content, 'layout_colour')].layout_colour.value == 'Dark'}"
                >
                  <i :class="`bx ${item.icon} mr-2`"></i>{{ $t(item.label) }}
                  <div class="arrow-down" v-if="hasItems(item)"></div>
                </router-link>

                <a
                  v-else
                  class="nav-link dropdown-toggle arrow-none"
                  @click="onMenuClick($event)"
                  href="javascript: void(0);"
                  id="topnav-components"
                  role="button"
                  :class="{'text-white' : content[filterStylesKey(content, 'layout_colour')].layout_colour.value == 'Dark'}"
                >
                  <i :class="`bx ${item.icon} mr-1`"></i>
                  {{ $t(item.label) }}
                  <div class="arrow-down"></div>
                </a>

                <div
                  class="dropdown-menu"
                  aria-labelledby="topnav-dashboard"
                  v-if="hasItems(item)"
                >
                  <template v-for="(subitem, index) of item.menuitems">
                    <router-link
                      class="col dropdown-item side-nav-link-ref"
                      :class="{'text-white' : content[filterStylesKey(content, 'layout_colour')].layout_colour.value == 'Dark'}"
                      :key="index"
                      v-if="!hasItems(subitem)"
                      :to="((subitem.resource == 'collection' || 'category' ) ? '/'+subitem.resource+'/' : '') +subitem.link"
                      >{{ $t(subitem.label) }}</router-link
                    >

                    <div class="dropdown" v-if="hasItems(subitem)" :key="index">
                      <a
                        class="dropdown-item dropdown-toggle"
                        href="javascript: void(0);"
                        :class="{'text-white' : content[filterStylesKey(content, 'layout_colour')].layout_colour.value == 'Dark'}"
                        @click="onMenuClick($event)"
                        >{{ $t(subitem.label) }}
                        <div class="arrow-down"></div>
                      </a>
                      <div class="dropdown-menu">
                        <template
                          v-for="(subSubitem, index) of subitem.menuitems"
                        >
                          <router-link
                            class="dropdown-item side-nav-link-ref"
                            :key="index"
                            v-if="!hasItems(subSubitem)"
                            :to="subSubitem.link"
                            :class="{'text-white' : content[filterStylesKey(content, 'layout_colour')].layout_colour.value == 'Dark'}"
                            >{{ $t(subSubitem.label) }}</router-link
                          >
                          <div
                            class="dropdown"
                            v-if="hasItems(subSubitem)"
                            :key="index"
                          >
                            <a
                              class="dropdown-item dropdown-toggle"
                              href="javascript: void(0);"
                              @click="onMenuClick($event)"
                              :class="{'text-white' : content[filterStylesKey(content, 'layout_colour')].layout_colour.value == 'Dark'}"
                              >{{ $t(subSubitem.label) }}
                              <div class="arrow-down"></div>
                            </a>
                            <div class="dropdown-menu">
                              <template
                                v-for="(
                                  subSubSubitem, index
                                ) of subSubitem.menuitems"
                              >
                                <router-link
                                  class="dropdown-item side-nav-link-ref"
                                  :key="index"
                                  :to="subSubSubitem.link"
                                  routerLinkActive="active"
                                  >{{ $t(subSubSubitem.label) }}</router-link
                                >
                              </template>
                            </div>
                          </div>
                        </template>
                      </div>
                    </div>
                  </template>
                </div>
              </li>
            </template>
          </ul>
          <ul class="navbar-nav">
            <!-- Menu data -->

            <!--<template v-for="(item, index) of menuItems">
              <li class="nav-item dropdown" :key="index">
                <a
                  class="nav-link dropdown-toggle arrow-none"
                  v-if="!item.subItems && liveMode == false"
                  id="topnav-components"
                  :href="item.link"
                  role="button"
                  :class="{'text-white' : content[filterStylesKey(content, 'layout_colour')].layout_colour.value == 'Dark'}"
                >
                  <i :class="`bx ${item.icon} mr-2`"></i>{{ $t(item.label) }}
                  <div class="arrow-down" v-if="hasItems(item)"></div>
                </a>
                <router-link
                  class="nav-link dropdown-toggle arrow-none"
                  v-if="!item.subItems && liveMode == true"
                  id="topnav-components"
                  :to="item.link"
                  role="button"
                  :class="{'text-white' : content[filterStylesKey(content, 'layout_colour')].layout_colour.value == 'Dark'}"
                >
                  <i :class="`bx ${item.icon} mr-2`"></i>{{ $t(item.label) }}
                  <div class="arrow-down" v-if="hasItems(item)"></div>
                </router-link>

                <a
                  v-if="item.subItems"
                  class="nav-link dropdown-toggle arrow-none"
                  @click="onMenuClick($event)"
                  href="javascript: void(0);"
                  id="topnav-components"
                  role="button"
                  :class="{'text-white' : content[filterStylesKey(content, 'layout_colour')].layout_colour.value == 'Dark'}"
                >
                  <i :class="`bx ${item.icon} mr-1`"></i>
                  {{ $t(item.label) }}
                  <div class="arrow-down"></div>
                </a>

                <div

                  :class="{'bg-dark' : content[filterStylesKey(content, 'layout_colour')].layout_colour.value == 'Dark'}"
                  class="dropdown-menu"
                  aria-labelledby="topnav-dashboard"
                  v-if="hasItems(item)"
                >
                  <template v-for="(subitem, index) of item.subItems">
                    <a

                      :class="{'text-white' : content[filterStylesKey(content, 'layout_colour')].layout_colour.value == 'Dark'}"
                      class="col dropdown-item side-nav-link-ref"
                      :key="index"
                      v-if="!hasItems(subitem) && liveMode == false"
                      :href="subitem.link"
                      >{{ $t(subitem.label) }}</a
                    >
                     <router-link

                      :class="{'text-white' : content[filterStylesKey(content, 'layout_colour')].layout_colour.value == 'Dark'}"
                      class="col dropdown-item side-nav-link-ref"
                      :key="index"
                      v-if="!hasItems(subitem) && liveMode == true"
                      :to="subitem.link"
                      >{{ $t(subitem.label) }}</router-link
                    >
                    <div class="dropdown" v-if="hasItems(subitem)" :key="index">
                      <a
                        :class="{'text-white' : content[filterStylesKey(content, 'layout_colour')].layout_colour.value == 'Dark'}" 
                        class="dropdown-item dropdown-toggle"
                        href="javascript: void(0);"
                        @click="onMenuClick($event)"
                        >{{ $t(subitem.label) }}
                        <div class="arrow-down"></div>
                      </a>
                      <div class="dropdown-menu">
                        <template
                          v-for="(subSubitem, index) of subitem.subItems"
                        >
                          <a

                            :class="{'text-white' : content[filterStylesKey(content, 'layout_colour')].layout_colour.value == 'Dark'}"
                            class="dropdown-item side-nav-link-ref"
                            :key="index"
                            v-if="!hasItems(subSubitem) && liveMode == false"
                            :href="subSubitem.link"
                            >{{ $t(subSubitem.label) }}</a
                          >
                          <router-link

                            :class="{'text-white' : content[filterStylesKey(content, 'layout_colour')].layout_colour.value == 'Dark'}"
                            class="dropdown-item side-nav-link-ref"
                            :key="index"
                            v-if="!hasItems(subSubitem) && liveMode == true"
                            :to="subSubitem.link"
                            >{{ $t(subSubitem.label) }}</router-link
                          >
                          <div
                            class="dropdown"
                            v-if="hasItems(subSubitem)"
                            :key="index"
                          >
                            <a

                              :class="{'text-white' : content[filterStylesKey(content, 'layout_colour')].layout_colour.value == 'Dark'}"
                              class="dropdown-item dropdown-toggle"
                              href="javascript: void(0);"
                              @click="onMenuClick($event)"
                              >{{ $t(subSubitem.label) }}
                              <div class="arrow-down"></div>
                            </a>
                            <div class="dropdown-menu">
                              <template
                                v-for="(
                                  subSubSubitem, index
                                ) of subSubitem.subItems"
                              >
                                <a

                                  :class="{'text-white' : content[filterStylesKey(content, 'layout_colour')].layout_colour.value == 'Dark'}"
                                  class="dropdown-item side-nav-link-ref"
                                  :key="index"
                                  :href="subSubSubitem.link"
                                  routerLinkActive="active"
                                  >{{ $t(subSubSubitem.label) }}</a
                                >
                              </template>
                            </div>
                          </div>
                        </template>
                      </div>
                    </div>
                  </template>
                </div>
              </li>
            </template>-->
          </ul>
        </div>
      </nav>
    </div>
  </div>
  </header>
  <header v-else>
    <div class="navbar-header" :class="{'bg-dark' : content[filterStylesKey(content, 'layout_colour')].layout_colour.value == 'Dark'}">
        <div class="d-flex" :class="{'w-auto' : content[filterStylesKey(content, 'logo_position')].logo_position.value == 'logo left'}">
        <!-- LOGO -->
        <div class="navbar-brand-box px-0 bg-transparent" :class="{'text-center mx-auto' : content[filterStylesKey(content, 'logo_position')].logo_position.value == 'logo center'}" v-if="content[filterStylesKey(content, 'logo_position')].logo_position.value !== 'logo right'">
          <a href="/" class="logo" >
            <span class="logo-sm">
              <img v-if="content[filterStylesKey(content, 'logo')].logo.value !== ''" :src="content[filterStylesKey(content, 'logo')].logo.value" :width="content[filterStylesKey(content, 'logo')].logo.width.value + content[filterStylesKey(content, 'logo')].logo.width.units" :height="content[filterStylesKey(content, 'logo')].logo.height.value + content[filterStylesKey(content, 'logo')].logo.height.units">
              <div v-else class="d-flex logo-text-holder"><span class="logo-text">{{company.name.slice(0, 1)}}</span></div>
            </span>
            <span class="logo-lg">
              <img v-if="content[filterStylesKey(content, 'logo')].logo.value !== ''" :src="content[filterStylesKey(content, 'logo')].logo.value" :width="content[filterStylesKey(content, 'logo')].logo.width.value + content[filterStylesKey(content, 'logo')].logo.width.units" :height="content[filterStylesKey(content, 'logo')].logo.height.value + content[filterStylesKey(content, 'logo')].logo.height.units">
              <div v-else class="d-flex logo-text-holder"><span class="logo-text" :class="{'text-dark' : content[filterStylesKey(content, 'layout_colour')].layout_colour.value == 'Light', 'text-white' : content[filterStylesKey(content, 'layout_colour')].layout_colour.value == 'Dark'}">{{company.name}}</span></div>
            </span>
          </a>

          
        </div>

        <button
          id="toggle"
          type="button"
          class="btn btn-sm me-2 font-size-16 d-lg-none header-item"
          @click="toggleMenu"
        >
          <i class="fa fa-fw fa-bars"></i>
        </button>
        
      </div>
      <div class="d-flex w-75">
        <nav class="navbar navbar-light navbar-expand-lg topnav-menu">
        <div id="topnav-menu-content" class="justify-content-center collapse navbar-collapse" v-if="liveMode == true">
          <ul class="navbar-nav">
            <!-- Menu data -->
            <template v-for="(item, index) of menuItems">
              <li class="nav-item dropdown" :key="index">
                <router-link
                  class="nav-link dropdown-toggle arrow-none"
                  v-if="!hasItems(item)"
                  id="topnav-components"
                  :to="'/'+item.link"
                  role="button"
                  :class="{'text-white' : content[filterStylesKey(content, 'layout_colour')].layout_colour.value == 'Dark'}"
                >
                  <i :class="`bx ${item.icon} mr-2`"></i>{{ $t(item.label) }}
                  <div class="arrow-down" v-if="hasItems(item)"></div>
                </router-link>

                <a
                  v-else
                  class="nav-link dropdown-toggle arrow-none"
                  @click="onMenuClick($event)"
                  href="javascript: void(0);"
                  id="topnav-components"
                  role="button"
                  :class="{'text-white' : content[filterStylesKey(content, 'layout_colour')].layout_colour.value == 'Dark'}"
                >
                  <i :class="`bx ${item.icon} mr-1`"></i>
                  {{ $t(item.label) }}
                  <div class="arrow-down"></div>
                </a>

                <div
                  class="dropdown-menu"
                  aria-labelledby="topnav-dashboard"
                  v-if="hasItems(item)"
                >
                  <template v-for="(subitem, index) of item.menuitems">
                    <router-link
                      class="col dropdown-item side-nav-link-ref"
                      :class="{'text-white' : content[filterStylesKey(content, 'layout_colour')].layout_colour.value == 'Dark'}"
                      :key="index"
                      v-if="!hasItems(subitem)"
                      :to="((subitem.resource == 'collection' || 'category' ) ? '/'+subitem.resource+'/' : '') +subitem.link"
                      >{{ $t(subitem.label) }}</router-link
                    >

                    <div class="dropdown" v-if="hasItems(subitem)" :key="index">
                      <a
                        class="dropdown-item dropdown-toggle"
                        href="javascript: void(0);"
                        :class="{'text-white' : content[filterStylesKey(content, 'layout_colour')].layout_colour.value == 'Dark'}"
                        @click="onMenuClick($event)"
                        >{{ $t(subitem.label) }}
                        <div class="arrow-down"></div>
                      </a>
                      <div class="dropdown-menu">
                        <template
                          v-for="(subSubitem, index) of subitem.menuitems"
                        >
                          <a
                            class="dropdown-item side-nav-link-ref"
                            :key="index"
                            v-if="!hasItems(subSubitem)"
                            :href="subSubitem.link"
                            :class="{'text-white' : content[filterStylesKey(content, 'layout_colour')].layout_colour.value == 'Dark'}"
                            >{{ $t(subSubitem.label) }}</a
                          >
                          <div
                            class="dropdown"
                            v-if="hasItems(subSubitem)"
                            :key="index"
                          >
                            <a
                              class="dropdown-item dropdown-toggle"
                              href="javascript: void(0);"
                              @click="onMenuClick($event)"
                              :class="{'text-white' : content[filterStylesKey(content, 'layout_colour')].layout_colour.value == 'Dark'}"
                              >{{ $t(subSubitem.label) }}
                              <div class="arrow-down"></div>
                            </a>
                            <div class="dropdown-menu">
                              <template
                                v-for="(
                                  subSubSubitem, index
                                ) of subSubitem.menuitems"
                              >
                                <a
                                  class="dropdown-item side-nav-link-ref"
                                  :key="index"
                                  :href="subSubSubitem.link"
                                  routerLinkActive="active"
                                  >{{ $t(subSubSubitem.label) }}</a
                                >
                              </template>
                            </div>
                          </div>
                        </template>
                      </div>
                    </div>
                  </template>
                </div>
              </li>
            </template>
          </ul>
        </div>
        <div id="topnav-menu-content" class="justify-content-center collapse navbar-collapse" v-else-if="liveMode == false">
          <ul class="navbar-nav">
            <!-- Menu data -->
            <template v-for="(item, index) of menuItems">
              <li class="nav-item dropdown" :key="index">
                <a
                  class="nav-link dropdown-toggle arrow-none"
                  v-if="!hasItems(item)"
                  id="topnav-components"
                  :to="'/'+item.link"
                  role="button"
                  :class="{'text-white' : content[filterStylesKey(content, 'layout_colour')].layout_colour.value == 'Dark'}"
                >
                  <i :class="`bx ${item.icon} mr-2`"></i>{{ $t(item.label) }}
                  <div class="arrow-down" v-if="hasItems(item)"></div>
                </a>

                <a
                  v-else
                  class="nav-link dropdown-toggle arrow-none"
                  @click="onMenuClick($event)"
                  href="javascript: void(0);"
                  id="topnav-components"
                  role="button"
                  :class="{'text-white' : content[filterStylesKey(content, 'layout_colour')].layout_colour.value == 'Dark'}"
                >
                  <i :class="`bx ${item.icon} mr-1`"></i>
                  {{ $t(item.label) }}
                  <div class="arrow-down"></div>
                </a>

                <div
                  class="dropdown-menu"
                  aria-labelledby="topnav-dashboard"
                  v-if="hasItems(item)"
                >
                  <template v-for="(subitem, index) of item.menuitems">
                    <a
                      class="col dropdown-item side-nav-link-ref"
                      :class="{'text-white' : content[filterStylesKey(content, 'layout_colour')].layout_colour.value == 'Dark'}"
                      :key="index"
                      v-if="!hasItems(subitem)"
                      :to="((subitem.resource == 'collection' || 'category' ) ? '/'+subitem.resource+'/' : '') +subitem.link"
                      >{{ $t(subitem.label) }}</a
                    >

                    <div class="dropdown" v-if="hasItems(subitem)" :key="index">
                      <a
                        class="dropdown-item dropdown-toggle"
                        href="javascript: void(0);"
                        :class="{'text-white' : content[filterStylesKey(content, 'layout_colour')].layout_colour.value == 'Dark'}"
                        @click="onMenuClick($event)"
                        >{{ $t(subitem.label) }}
                        <div class="arrow-down"></div>
                      </a>
                      <div class="dropdown-menu">
                        <template
                          v-for="(subSubitem, index) of subitem.menuitems"
                        >
                          <a
                            class="dropdown-item side-nav-link-ref"
                            :key="index"
                            v-if="!hasItems(subSubitem)"
                            :href="subSubitem.link"
                            :class="{'text-white' : content[filterStylesKey(content, 'layout_colour')].layout_colour.value == 'Dark'}"
                            >{{ $t(subSubitem.label) }}</a
                          >
                          <div
                            class="dropdown"
                            v-if="hasItems(subSubitem)"
                            :key="index"
                          >
                            <a
                              class="dropdown-item dropdown-toggle"
                              href="javascript: void(0);"
                              @click="onMenuClick($event)"
                              :class="{'text-white' : content[filterStylesKey(content, 'layout_colour')].layout_colour.value == 'Dark'}"
                              >{{ $t(subSubitem.label) }}
                              <div class="arrow-down"></div>
                            </a>
                            <div class="dropdown-menu">
                              <template
                                v-for="(
                                  subSubSubitem, index
                                ) of subSubitem.menuitems"
                              >
                                <a
                                  class="dropdown-item side-nav-link-ref"
                                  :key="index"
                                  :href="subSubSubitem.link"
                                  routerLinkActive="active"
                                  >{{ $t(subSubSubitem.label) }}</a
                                >
                              </template>
                            </div>
                          </div>
                        </template>
                      </div>
                    </div>
                  </template>
                </div>
              </li>
            </template>
          </ul>
        </div>
      </nav>
      </div>
      <transition name="fade-right">
         <form class="app-search d-none d-lg-block ml-auto w-50 my-auto" v-if="(showSearch == true) && (content[filterStylesKey(content, 'logo_position')].logo_position.value == 'logo left')" >
          <div class="position-relative">
            <input
              type="text"
              class="form-control bg-light bg-soft"
              :placeholder="$t('navbar.search.text')"
               :class="{'bg-dark' : content[filterStylesKey(content, 'layout_colour')].layout_colour.value == 'Dark', 'bg-light' : content[filterStylesKey(content, 'layout_colour')].layout_colour.value == 'Light'}"
            />
            <span class="bx bx-search-alt" :class="{'text-light' : content[filterStylesKey(content, 'layout_colour')].layout_colour.value == 'Dark'}" ></span>
          </div>
        </form>
      </transition>
      <transition name="fade-top">
         <form class="app-search d-none d-lg-block mx-auto w-50 my-auto position-absolute top-0 right-0 left-0" v-if="(showSearch == true) && ((content[filterStylesKey(content, 'logo_position')].logo_position.value == 'logo center') || (content[filterStylesKey(content, 'logo_position')].logo_position.value == 'logo right'))"  :class="{'bg-dark' : content[filterStylesKey(content, 'layout_colour')].layout_colour.value == 'Dark', 'bg-white' : content[filterStylesKey(content, 'layout_colour')].layout_colour.value == 'Light'}" >
          <div class="position-relative">
            <input
              type="text"
              class="form-control bg-light bg-soft"
              :placeholder="$t('navbar.search.text')"
               :class="{'bg-dark' : content[filterStylesKey(content, 'layout_colour')].layout_colour.value == 'Dark', 'bg-light' : content[filterStylesKey(content, 'layout_colour')].layout_colour.value == 'Light'}"
            />
            <span class="bx bx-search-alt" :class="{'text-light' : content[filterStylesKey(content, 'layout_colour')].layout_colour.value == 'Dark'}" ></span>
          </div>
        </form>
      </transition>
      <div class="d-flex"  :class="{'ml-auto' : content[filterStylesKey(content, 'logo_position')].logo_position.value == 'logo left', 'mr-auto' : content[filterStylesKey(content, 'logo_position')].logo_position.value == 'logo right', 'ml-auto w-25' : content[filterStylesKey(content, 'logo_position')].logo_position.value == 'logo center'}">
        <b-dropdown
          v-if="(elements[filterStylesKey(elements, 'show_search')].show_search.value == true) && (content[filterStylesKey(content, 'logo_position')].logo_position.value !== 'logo center')"
          class="d-inline-block d-lg-none ml-2"
          variant="black"
          menu-class="dropdown-menu-lg p-0 dropdown-menu-end"
          toggle-class="header-item noti-icon"
          right
        >
          <template v-slot:button-content>
            <i class="mdi mdi-magnify"></i>
          </template>

          <form class="p-3">
            <div class="form-group m-0">
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Search ..."
                  aria-label="Recipient's username"
                />
                <div class="input-group-append">
                  <button class="btn btn-primary" type="submit">
                    <i class="mdi mdi-magnify"></i>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </b-dropdown>

        <!--<b-dropdown variant="white" right toggle-class="header-item">
          <template v-slot:button-content>
            <img class :src="flag" alt="Header Language" height="16" />
            {{ text }}
          </template>
          <b-dropdown-item
            class="notify-item"
            v-for="(entry, i) in languages"
            :key="`Lang${i}`"
            :value="entry"
            @click="setLanguage(entry.language, entry.title, entry.flag)"
            :class="{ active: lan === entry.language }"
          >
            <img
              :src="`${entry.flag}`"
              alt="user-image"
              class="me-1"
              height="12"
            />
            <span class="align-middle">{{ entry.title }}</span>
          </b-dropdown-item>
        </b-dropdown>-->



        <!--<div class="dropdown d-none d-lg-inline-block ml-1">
          <button
            type="button"
            class="btn header-item noti-icon"
            @click="initFullScreen"
          >
            <i class="bx bx-fullscreen"></i>
          </button>
        </div>-->
        <div class="dropdown d-none d-lg-inline-block ml-1">
          <button  
            v-if="(elements[filterStylesKey(elements, 'show_search')].show_search.value == true) && (content[filterStylesKey(content, 'logo_position')].logo_position.value !== 'logo center')"
            type="button"
            class="btn header-item noti-icon"
            @click="showSearch = !showSearch"
          >
            <i class="bx bx-search" :class="{'text-white' : content[filterStylesKey(content, 'layout_colour')].layout_colour.value == 'Dark'}"></i>
          </button>
        </div>
        <b-dropdown
          v-if="(elements[filterStylesKey(elements, 'show_currency')].show_currency.value == true) && (content[filterStylesKey(content, 'logo_position')].logo_position.value !== 'logo center')"
          variant="white" right toggle-class="header-item noti-icon"
        >
          <template v-slot:button-content>
            <i :class="[`${currencyicon}`, {'text-white' : content[filterStylesKey(content, 'layout_colour')].layout_colour.value == 'Dark'}]" :alt="'Pay in ' + currency" ></i>
          </template>

          <b-dropdown-item
            class="notify-item"
            v-for="(entry, i) in currencies"
            :key="`Lang${i}`"
            :value="entry"
            @click="setCurrency(entry.code, entry.title, entry.icon)"
            :class=" {'active' : lan === entry.language}"
          >
            <i :class="`${entry.icon}`" :alt="'Pay in ' + entry.title" class="me-1" ></i>
            <span class="align-middle">{{ entry.title }}</span>
          </b-dropdown-item>
        </b-dropdown>
        <b-dropdown
        v-if="elements[filterStylesKey(elements, 'show_cart')].show_cart.value == true"
          right
          menu-class="dropdown-menu-lg p-0 dropdown-menu-end cart-dropdown border-radius-1em"
          toggle-class="header-item noti-icon"
          variant="black"
          ref="cart"
          :class="{'ml-auto' : content[filterStylesKey(content, 'logo_position')].logo_position.value == 'logo center'}"
        >
          <template v-slot:button-content>
            <i class="bx bx-shopping-bag" :class="{'text-white' : content[filterStylesKey(content, 'layout_colour')].layout_colour.value == 'Dark'}"></i>
            <span
              class="badge bg-danger rounded-pill"
            >{{cart.products.length}}</span>
          </template>

          <div class="p-3">
            <div class="row align-items-center">
              <div class="col">
                <h6 class="m-0">Shopping Cart</h6>
              </div>
              <div class="col-auto">
                <a href="#" class="small">View Cart</a>
              </div>
            </div>
          </div>
          <simplebar style="max-height: 430px;">
          <b-list-group v-if="cartLoading == false">
            <b-list-group-item  v-for="(productitem, index) in cart.products" href="javascript: void(0);" class="text-reset notification-item px-0 py-3 m-0">
              <div class="media">
                <div class="avatar-sm me-3">
                  <img v-if="productitem.product.cover_image" :src="storageurl+productitem.product.cover_image.src.replaceAll('//', '/')" class="avatar-title rounded-circle">
                  <span v-else class="avatar-title bg-primary rounded-circle font-size-16" >
                    <i class="bx bx-cart"></i>
                  </span>
                </div>
                <div class="cart-name-cell my-auto w-50">
                  <h6 class="mt-0 mb-1 text-truncate">{{productitem.product.name}}</h6>
                </div>
                <div class="my-auto text-right d-flex flex-column mx-auto">
                  <a href="javascript:;" class="text-center" @click="productitem.quantity++"><i class="bx bx-chevron-up mb-auto mx-auto"></i></a>
                  <b-form-input type="number"  v-model="productitem.quantity" class="visible mx-auto my-auto d-none" style="width: 25%;" >
                  </b-form-input>
                  <span class="mx-auto my-auto">{{productitem.quantity}}</span>
                  <a v-if="productitem.quantity > 1" href="javascript:;" class="text-center" @click="productitem.quantity--"><i class="bx bx-chevron-down mt-auto mx-auto"></i></a>
                  <a v-else href="javascript:;" class="text-center" @click="removeProduct(productitem.id, index)"><i class="bx bx-chevron-down mt-auto mx-auto"></i></a>
                </div>
                <div class="my-auto text-center d-flex flex-column mx-auto">
                  <p class="mb-0"><span class="text-muted">£</span>{{productitem.price_exc}}</p>
                  <small>Item Price Exc</small>
                </div>
                <div class="my-auto text-center d-flex flex-column mx-auto">
                  <p class="mb-0"><span class="text-muted">£</span>{{(productitem.price_exc * productitem.quantity).toFixed(2)}}</p>
                  <small>Total Price Exc</small>
                </div>
                <div class="my-auto mx-auto text-center">
                 <a class="btn btn-outline-light btn-rounded" @click="removeProduct(productitem.id, index)"><i class="bx bx-trash-alt"></i></a>
                </div>
              </div>
            </b-list-group-item>
            <b-list-group-item v-if="cart.products.length == 0">
              <div class="p-5 text-center border">
                <i class="bx bx-shopping-bag font-size-42 text-secondary mb-2"></i>
                <h6>No Products in Cart</h6>
              </div>
            </b-list-group-item>
          </b-list-group>
            

          </simplebar>
          <b-overlay
            :show="cartLoading">
              <div class="p-4 border-top d-flex bg-light" v-if="(cart.products.length !== 0) && (cartLoading == false)">
                <strong class="mr-auto flex-grow-2"> Total: </strong>
                  <span class="mx-auto flex-grow-1 text-center"><strong class="text-primary mr-2">£</strong> {{cartTotal(cart)}}</span>
              </div>
          </b-overlay>
         <div class="p-2 border-top d-grid" v-if="cart.products.length !== 0">
            <a class="btn btn-sm btn-link font-size-14 text-center btn-primary text-white btn-rounded" :href="{ name: 'Order', path: '/order', params: { cart } }" @click="goToCheckout()" >
              <i class=" me-1"></i>
              <span key="t-view-more"> Checkout Now </span>
            </a>
          </div>
        </b-dropdown>
        <b-dropdown
        v-if="elements[filterStylesKey(elements, 'show_account')].show_account.value == true"
          right
          variant="black"
          toggle-class="header-item"
          menu-class="dropdown-menu-end"
          :menu-class="{'border-radius-1em p-0 login-dropdown dropdown-menu-end' : !user}"
        >
          <template v-slot:button-content>
            <div v-if="user">
            <img
              v-if="user.avatar"
              class="rounded-circle header-profile-user"
              :src="user.avatar"
              alt="Header Avatar"
            />
            <img v-else
              class="rounded-circle header-profile-user"
              src="/images/product-placeholder.png"
            > 
            <span class="d-none d-xl-inline-block ml-1" :class="{'text-white' : content[filterStylesKey(content, 'layout_colour')].layout_colour.value == 'Dark'}">{{user.name}} {{user.lastname}}</span>
            <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
            </div>
            <div v-else>
              <img
              class="rounded-circle header-profile-user"
              src="/images/product-placeholder.png"
              alt="Sign in to Gemesys"
            />
            <span class="d-none d-xl-inline-block ml-1" :class="{'text-white' : content[filterStylesKey(content, 'layout_colour')].layout_colour.value == 'Dark'}">
              My Account
            </span>
            <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
            </div>

            
          </template>
          <!-- item-->
           <div v-if="user">
          <b-dropdown-item href="/account">
            <i class="bx bx-user font-size-16 align-middle me-1"></i>
            My Account
          </b-dropdown-item>
          <b-dropdown-item href="/account#addresses">
            <i class="bx bx-home-alt font-size-16 align-middle me-1"></i>
            Addresses
          </b-dropdown-item>
          <b-dropdown-item href="/account#orders">
            <i class="bx bx-tag font-size-16 align-middle me-1"></i>
            Orders
          </b-dropdown-item>
          <b-dropdown-item class="d-block"  href="/account#messages">
            <span class="badge bg-success float-end">11</span>
            <i class="bx bx-chat font-size-16 align-middle me-1"></i>
            Messages
          </b-dropdown-item>
          <b-dropdown-item  href="/account#wishlist">
            <i class="mdi mdi-heart-outline font-size-16 align-middle me-1"></i>
            Wishlist
          </b-dropdown-item>

          <b-dropdown-divider></b-dropdown-divider>
          <a href="/account/logout" class="dropdown-item text-danger">
            <i
              class="bx bx-power-off font-size-16 align-middle me-1 text-danger"
            ></i>
            {{ $t("navbar.dropdown.henry.list.logout") }}
          </a>
          </div>
          <div v-else class="p-0">
            <Login :submit-url="'http://website.'+domain.domain_key+'.gemesys.co.uk/account/login'" auth-error="">
                 <input type="hidden" name="_token" :value="csrf">
            </Login>
          </div>
        </b-dropdown>
      
        <!--<div class="dropdown d-inline-block">
          <button
            type="button"
            class="btn header-item noti-icon right-bar-toggle toggle-right"
            @click="toggleRightSidebar"
          >
            <i class="bx bx-cog bx-spin toggle-right"></i>
          </button>
        </div>-->
      </div>
    </div>
  </header>
</template>

<style scoped>
  .cart-name-cell{
    max-width: 250px;
  }
  #pb-menu-layout-1 .navbar-brand-box {
    overflow: hidden;
    max-height: 70px;
  }
</style>