<script>
import Layout from "../layouts/public";
import ImageCarousel from "../components/widgets/carousel/ImageCarousel";
import ImageCarouselSwiper from "../components/widgets/carousel/ImageCarouselSwiper";
import MiniCardCarousel from "../components/widgets/carousel/MiniCardCarousel";
import ImgSwiper from "../components/widgets/carousel/Swiper";
import ProductCard from '../components/widgets/ecommerce/public/ProductCard';
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper';
import SwiperClass, { Pagination } from 'swiper'
  import 'swiper/swiper.min.css'
/**
 * Dashboard Component
 */
export default {
  name: "Homepage",
  components: { Layout, ImgSwiper, ImageCarousel, ImageCarouselSwiper, MiniCardCarousel, ProductCard, Swiper,
        SwiperSlide},
  directives: {
        swiper: directive
   },
  data() {
    return {
      title: "Home",
      showModal: false,
      products: [],
      sellers: [],
      categories: [],
      sellerSwiper: {
          slidesPerView: 4,
          spaceBetween: 20,
          loop: false,
          loopFillGroupWithBlank: false,
          pagination: {
            el: '.swiper-pagination',
            clickable: true,
          },
          breakpoints: {
          0: {
            slidesPerView: 1,
            spaceBetween: 20
          },
          // when window width is >= 320px
          420: {
            slidesPerView: 2,
            spaceBetween: 20
          },
          // when window width is >= 480px
          567: {
            slidesPerView: 3,
            spaceBetween: 30
          },
          // when window width is >= 640px
          767: {
            slidesPerView: 4,
            spaceBetween: 40
          },
          990: {
            slidesPerView: 4,
            spaceBetween: 40
          }
        },
      },
      categorySwiper: {
          slidesPerView: 5,
          spaceBetween: 20,
          loop: false,
          loopFillGroupWithBlank: false,
          pagination: {
            el: '.swiper-pagination',
            clickable: true,
          },
          breakpoints: {
          0: {
            slidesPerView: 1,
            spaceBetween: 20
          },
          // when window width is >= 320px
          420: {
            slidesPerView: 2,
            spaceBetween: 20
          },
          // when window width is >= 480px
          567: {
            slidesPerView: 3,
            spaceBetween: 30
          },
          // when window width is >= 640px
          767: {
            slidesPerView: 4,
            spaceBetween: 40
          },
          990: {
            slidesPerView: 5,
            spaceBetween: 40
          }
        }
          // Some Swiper option/callback...
        },
        swiperOptions: {
          slidesPerView: 4,
          spaceBetween: 20,
          loop: false,
          loopFillGroupWithBlank: false,
          pagination: {
            el: '.swiper-pagination',
            clickable: true,
          },
          breakpoints: {
          0: {
            slidesPerView: 1,
            spaceBetween: 20
          },
          // when window width is >= 320px
          320: {
            slidesPerView: 1,
            spaceBetween: 20
          },
          // when window width is >= 480px
          567: {
            slidesPerView: 2,
            spaceBetween: 30
          },
          // when window width is >= 640px
          767: {
            slidesPerView: 3,
            spaceBetween: 40
          },
          990: {
            slidesPerView: 4,
            spaceBetween: 40
          }
        }
          // Some Swiper option/callback...
        },
        recentlyAddedOptions: {
          slidesPerView: 3,
          spaceBetween: 5,
          loop: false,
          loopFillGroupWithBlank: false,
          pagination: {
            el: '.swiper-pagination',
            clickable: true,
          }, breakpoints: {
          // when window width is >= 320px
          320: {
            slidesPerView: 1,
            spaceBetween: 20
          },
          // when window width is >= 480px
          567: {
            slidesPerView: 2,
            spaceBetween: 30
          },
          // when window width is >= 640px
          767: {
            slidesPerView: 3,
            spaceBetween: 40
          },
          990: {
            slidesPerView: 4,
            spaceBetween: 40
          }
          }
          // Some Swiper option/callback...
        },
      items: [
        {
          cover_image: '/images/product-placeholder.png',
          name: 'Necklaces',
          link: '/category/jewellery',
        },
        {
          cover_image: '/images/product-placeholder.png',
          name: 'Rings',
          link: '/category/jewellery',
        },
        {
          cover_image: '/images/product-placeholder.png',
          name: 'Earrings',
          link: '/category/jewellery',
        },
        {
          cover_image: '/images/product-placeholder.png',
          name: 'Earrings',
          link: '/category/jewellery',
        },
        {
          cover_image: '/images/product-placeholder.png',
          name: 'Earrings',
          link: '/category/jewellery',
        },
      ],
      items2: [
        {
          cover_image: '/images/product-placeholder.png',
          name: 'Modern Aquamarine Diamond And White Gold Chandelier Drop Earrings',
          link: '/category/jewellery',
          price: '2000.00',
        },
        {
          cover_image: '/images/product-placeholder.png',
          name: 'Vintage 18ct Gold Necklace And Bracelet Suite, Circa 1980',
          link: '/category/jewellery',
          price: '2000.00',
        },
        {
          cover_image: '/images/product-placeholder.png',
          name: 'Edwardian Sapphire And Diamond Bracelet, Circa 1910',
          link: '/category/jewellery',
          price: '2000.00',
        },
        {
          cover_image: '/images/product-placeholder.png',
          name: 'David Webb Rock Crystal Diamond Gold Earrings',
          link: '/category/jewellery',
          price: '2000.00',
        },
        {
          cover_image: '/images/product-placeholder.png',
          name: 'Earrings',
          link: '/category/jewellery',
          price: '2000.00',
        },
        {
          cover_image: '/images/product-placeholder.png',
          name: 'Earrings',
          link: '/category/jewellery',
          price: '2000.00',
        },
        {
          cover_image: '/images/product-placeholder.png',
          name: 'Earrings',
          link: '/category/jewellery',
          price: '2000.00',
        },
        {
          cover_image: '/images/product-placeholder.png',
          name: 'Earrings',
          link: '/category/jewellery',
          price: '2000.00',
        },
        {
          cover_image: '/images/product-placeholder.png',
          name: 'Earrings',
          link: '/category/jewellery',
          price: '2000.00',
        },
        {
          cover_image: '/images/product-placeholder.png',
          name: 'Earrings',
          link: '/category/jewellery',
          price: '2000.00',
        },
        {
          cover_image: '/images/product-placeholder.png',
          name: 'Earrings',
          link: '/category/jewellery',
          price: '2000.00',
        },
        {
          cover_image: '/images/product-placeholder.png',
          name: 'Earrings',
          link: '/category/jewellery',
          price: '2000.00',
        },
      ]
    };
  },
  created(){
    this.getProducts();
    this.getSellers();
    this.getCategories();
  },
  methods: {
    getProducts(){
      axios.post('/customer-api/product/query-list', {type: 'home'}).then(response => {
        this.products = response.data;
      }).catch(error => {

      });
    },
    getSellers(){
      axios.post('/customer-api/seller/query-list', {type: 'home'}).then(response => {
        this.sellers = response.data;
      }).catch(error => {

      });
    },
    getCategories(){
      axios.post('/customer-api/product/category/query-list', {type: 'home'}).then(response => {
        this.categories = response.data;
      }).catch(error => {

      });
    },
    filterRecentProducts(products, start, end){
      return products.slice(start, end);
    }
  },
   mounted() {
    setTimeout(() => {
      this.showModal = true;
    }, 1500);
  },
};
</script>

<template>
<div id="home" class="homepage">
  <section class="header-section">
  <div class="container-fullwidth">
    <!--<PageHeader :title="title" :items="items" />-->
    <b-row class="header-row bg-light bg-soft px-4" style="min-height: 350px;">
      <b-col md="12" class="d-flex flex-column justify-content-end align-items-center mx-auto">

        <h1 class="section-info-header section-info-header position-relative text-sm-center">Find Your Perfect Piece of Luxury</h1>
        <p class="text-dark font-size-16 text-center">
            Select from a range of bespoke, antique and every day jewellery from sellers across the UK
          </p>
      </b-col>
      <b-col md="6" class="d-flex flex-column justify-content-start align-items-center mx-auto">
        <div class="mt-sm-0 form-inline w-100"><div class="search-box mr-2 ml-auto d-flex"><div class="position-relative w-100"><input type="text" placeholder="Search name, reference or description..." class="form-control w-100 border"> <i class="bx bx-search-alt search-icon"></i></div> <!----></div></div>
      </b-col>
    </b-row>
     <b-row class="categories-top-row px-4">
      <b-col md="12" lg="9" class="mx-auto overflow-hidden">
        <!--<ImageCarousel :items="categories[0]" :grid_limit="5" :end="5" :autoplay="false" :resource="'category'" :liveMode="true"></ImageCarousel>-->
        <ImageCarouselSwiper :items="categories[0]" :grid_limit="5" :end="5" :autoplay="false" :resource="'category'" :liveMode="true" :swiperOptions="categorySwiper"></ImageCarouselSwiper>
        
      </b-col>

    </b-row>
    <!-- end row -->
  </div>
</section>
 <section class="pt-0">
  <b-container class="py-4" fluid>
   <b-row class="py-4 my-4">
      <b-col md="12">
        <b-card class="shadow-none h-100" body-class="d-flex flex-column justify-content-center align-items-start align-items-sm-center">
        <p class="mb-1 text-muted mx-md-0">Editors Picks</p>
        <h2 class="section-info-header section-info-header position-relative mx-md-0">Our Featured Pieces</h2>
      </b-card>
      </b-col>
     <b-col md="12" class="mx-auto px-4">
        <!--<MiniCardCarousel :use="'public'" :items="products['recent_products']" :grid_limit="4" :end="4" :layout_type="'vertical'" :autoplay="false" :customer="$attrs.user"></MiniCardCarousel>-->
        <swiper ref="productGalleryCarousel" :options="swiperOptions" class="overflow-hidden w-100" >
                  <swiper-slide v-for="(product, index) in filterRecentProducts(products['recent_products'], 0, 7)" >

                <ProductCard ref="latestProductsCarousel" :product="product" :company="product.company" :index="index" :exchange_rates="$attrs.exchange_rates" :selected_currency="$attrs.selected_currency" :selected_currency_symbol="$attrs.selected_currency_symbol"></ProductCard>
                </swiper-slide>
              </swiper>
      </b-col>
      <b-col md="12" class="text-center">
        <router-link class="border-bottom border-primary text-dark my-auto py-2 d-inline-flex" to="/category/jewellery">
          <h4>All Our Jewellery</h4> <i class="bx bx-chevron-right text-primary font-size-20 ml-2"></i>
        </router-link>
      </b-col> 
    </b-row>
  </b-container>
</section>
 <section>
  <b-container fluid>
    <b-row>
       <b-col md="12" class="pb-3">
        <b-card class="shadow-none border-radius-1em h-100" body-class="d-flex flex-column justify-content-center align-items-start align-items-sm-center">
        <p class="mb-1 text-muted">New In Stock</p>
        <h2 class="section-info-header section-info-header position-relative">Recently Added</h2>
        
      </b-card>
      </b-col>
       <b-col md="4" class="pb-3 px-4">
        <b-card class="shadow-none border border-radius-1em h-100" body-class="d-flex flex-column justify-content-center align-items-center px-4">

        <p class="px-3 lh-2">
          <i class="bx bxs-quote-left text-primary font-size-20"></i>
          Excepteur sint occaecat cupidatat non proident sunt in 
            culpa qui officia deserunt mollit lorem ipsum anim id est 
            laborum perspiciatis unde.
            <i class="bx bxs-quote-right text-primary font-size-20"></i>
        </p>
        
      </b-card>
      </b-col>
      <b-col md="8" class="mx-auto overflow-hidden px-4">
        <ImgSwiper :use="'public'" :nickname="true" :items="filterRecentProducts(products['recent_products'], 0, 7)" :swiperOptions="recentlyAddedOptions" :startSlide="0" :customer="$attrs.user" :isPublic="true" :exchange_rates="$attrs.exchange_rates" :selected_currency="$attrs.selected_currency" :selected_currency_symbol="$attrs.selected_currency_symbol"></ImgSwiper>
        <!--<MiniCardCarousel :items="items2" :grid_limit="3" :end="3" :layout_type="'vertical'" :autoplay="false" :truncate_titles="true"></MiniCardCarousel>-->
      </b-col>
      <b-col md="8" class="mx-auto overflow-hidden px-4">
        <ImgSwiper :use="'public'" :nickname="true" :items="filterRecentProducts(products['recent_products'], 7, 16)" :swiperOptions="recentlyAddedOptions" :startSlide="1" :isPublic="true" :exchange_rates="$attrs.exchange_rates" :selected_currency="$attrs.selected_currency" :selected_currency_symbol="$attrs.selected_currency_symbol"></ImgSwiper>
        <!--<MiniCardCarousel :items="items2" :grid_limit="3" :end="3" :layout_type="'vertical'" :autoplay="false" :truncate_titles="true"></MiniCardCarousel>-->
      </b-col>
      <b-col md="4" class="pb-3 px-4">
        <b-card class="shadow-none border border-radius-1em h-100" body-class="d-flex flex-column justify-content-center align-items-center px-4">

        <p class="px-3 lh-2">
          <i class="bx bxs-quote-left text-primary font-size-20"></i>
          Excepteur sint occaecat cupidatat non proident sunt in 
            culpa qui officia deserunt mollit lorem ipsum anim id est 
            laborum perspiciatis unde.
            <i class="bx bxs-quote-right text-primary font-size-20"></i>
        </p>
        
      </b-card>
      </b-col>
    </b-row>
  </b-container>
</section>
 <section>
  <b-container class="container-fullwidth padded-section bg-light bg-soft">
    <b-row>
       <b-col md="10" class="pb-3 mx-auto">
        <b-card class="shadow-none h-100 bg-transparent" body-class="d-flex flex-column justify-content-center align-items-start bg-transparent align-items-sm-center">
        <p class="mb-1 text-muted">Editors Picks</p>
        <h2  class="section-info-header section-info-header position-relative text-center mx-auto">Our Featured Collections</h2>
        
      </b-card>
      </b-col>
      <b-col md="10" class="mx-auto px-4 overflow-hidden">
        <!--<ImageCarousel :items="categories[1]" :grid_limit="5" :end="5" :autoplay="false" :resource="'collection'" :liveMode="true"></ImageCarousel>-->
            <ImageCarouselSwiper :swiperOptions="categorySwiper" :items="categories[1]" :grid_limit="5" :end="5" :autoplay="false" :resource="'collection'" :liveMode="true"></ImageCarouselSwiper>
      </b-col>
    </b-row>
  </b-container>
</section>
 <section>
  <b-container fluid>
    <b-row>
       <b-col md="12" class="pb-3">
  
        <b-card class="shadow-none h-100" body-class="d-flex flex-column justify-content-center align-items-start align-items-sm-center">
        <p class="mb-1 text-muted">Editors Picks</p>
        <h2  class="section-info-header section-info-header position-relative">Our Antique Gallery</h2>
    
      </b-card>

      </b-col>
      <b-col md="12" class="mx-auto px-4">
        <ImgSwiper ref="gallerySwiper" :use="'public'" :nickname="true"  :customer="$attrs.user" :items="filterRecentProducts(products['recent_products'], 0, 7)" :swiperOptions="recentlyAddedOptions" :startSlide="2"  :exchange_rates="$attrs.exchange_rates" :selected_currency="$attrs.selected_currency" :selected_currency_symbol="$attrs.selected_currency_symbol"></ImgSwiper>
      </b-col>
       
       <b-col md="12" class="text-center">
        <router-link class="border-bottom border-primary text-dark my-auto py-2 d-inline-flex" to="/category/antiques">
          <h4>View Antiques</h4> <i class="bx bx-chevron-right text-primary font-size-20 ml-2"></i>
        </router-link>
      </b-col> 
    </b-row>
  </b-container>
</section>
 <section class="pb-4">
  <b-container fluid class="pb-4">
    <b-row class="pb-4">
       <b-col md="12" class="pb-3">
  
        <b-card class="shadow-none mb-0" body-class="d-flex flex-column justify-content-center align-items-center align-items-sm-center">
        <p class="mb-1 text-muted">Most Popular</p>
        <h2 class="section-info-header section-info-header position-relative text-center mx-auto">Featured Brands & Sellers</h2>
        
      </b-card>

      </b-col>
      <b-col md="10">
          <!-- <ImageCarousel :items="sellers" :resource="'seller'" :liveMode="true"  :grid_limit="4" :end="4" :autoplay="false" :use="'public'"></ImageCarousel>-->
           <ImageCarouselSwiper :swiperOptions="sellerSwiper" :items="sellers" :grid_limit="5" :end="5" :autoplay="false" :resource="'seller'" :liveMode="true"></ImageCarouselSwiper>
      </b-col>
       <b-col md="12" class="text-center mt-4">
        <router-link class="border-bottom border-primary ml-auto text-dark my-auto py-2 d-inline-flex" to="/sellers">
          <h4>View All Brands & Sellers</h4> <i class="bx bx-chevron-right text-primary font-size-20 ml-2"></i>
        </router-link>
      </b-col>  
    </b-row>
  </b-container>
</section>

 <!--<section class="pt-0">
  <b-container class="py-4" fluid>
   <b-row class="py-4 mt-4">
      <b-col md="12">
        <b-card class="shadow-none h-100" body-class="d-flex flex-column justify-content-center align-items-start">
        <p>From the Blog</p>
        <h2>Fresh Off The Press</h2>
        <i class="bx bx-right-arrow-circle text-primary font-size-20"></i>
      </b-card>
      </b-col>
     <b-col md="12" class="mx-auto">
        <MiniCardCarousel :use="'public'" :customer="$attrs.user" :items="products['recent_products']" :grid_limit="3" :end="3" :layout_type="'vertical'" :autoplay="false"></MiniCardCarousel>
      </b-col>
    </b-row>
  </b-container>
</section>-->

</div>
</template>

<style scoped>

  section{
    padding: 50px 0;
  }
  section.header-section{
    padding: 0 !important;
  }

</style>