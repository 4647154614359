<template>
	<div>
		<ProductPriceBlock :product="product" :selectedvariants="selectedvariants" :selectedcombination="selectedcombination" :company="company" :selectedvariantstotal="selectedvariantstotal"></ProductPriceBlock>
		<div class="border-top pt-3" v-if="product.combinations.length == 0" >
					<h5 class="text-uppercase font-size-12 text-underline spaced-title mb-4">Select Customisation Options</h5>
				<div class="w-100" v-for="(variants, key) in product.grouped_variants">
					<h5 class="text-uppercase font-size-12 spaced-title">{{variants[0].attribute.name}}</h5>
					<b-row >
						<b-col md="4" v-for="value in variants">
						<b-overlay :show="loadingVariants">
							<a href="javascript:;" @click="selectedvariants[key] = value, calculateVariantsTotal(), loadingVariants = true, loadingVariants = false" >
							<b-card class="border-radius-1em border shadow-none text-dark mb-3" body-class="d-flex" :class="{'border-primary' : selectedvariants[key] == value}">
								<span class="my-auto" v-if="value.value !== null"><img v-if="value.value.image !== null" :src="value.value.image" class="avatar-xs rounded-circle mr-2 border"> 
								</span>
								<span class="my-auto mr-2" v-if="value.value !== null">{{value.value.name}}</span> <span class="my-auto">{{value.measurement_value}}</span><span class="my-auto mr-2" v-if="value.units !== null">{{value.units.suffix}}</span>
								<span class="badge badge-light bg-light my-auto ml-auto font-size-14">
								<span class="ml-auto my-auto" v-if="value.price_difference > 0">+</span><span class="ml-auto my-auto" v-else>-</span><span>£</span>{{value.price_difference}}
								</span>
							</b-card>
							</a>
						</b-overlay>
						</b-col>
					</b-row>
				</div>
			</div>
				<div v-else >
					<h5 class="text-uppercase font-size-12 text-underline spaced-title mt-2 pt-3">Select Customisation Options</h5>
				<div class="w-100" v-for="(combination, index) in product.combinations">
					<a href="javascript:;" @click="selectedcombination = combination" >
						<b-card class="border-radius-1em border shadow-none text-dark" :class="{'border-primary' : selectedcombination == combination}">
					<b-row>
						<b-col md="3" v-for="value in combination.variants">
							
							
								<span v-if="value.variant.attribute !== null">{{value.variant.attribute.name}}</span> 
								<span v-if="value.variant.value !== null">{{value.variant.value.name}}</span> {{value.variant.measurement_value}}<span v-if="value.variant.units !== null">{{value.variant.units.suffix}}</span>
								
								
							
							
						</b-col>
						<b-col md="12" class="d-flex">
							<span class="badge badge-light bg-light ml-auto mt-2 font-size-14">
							<span v-if="combination.price_difference > 0">+</span><span v-else>-</span><span>£</span>{{combination.price_difference}}
								</span>
						</b-col>
					</b-row>
					</b-card>
					</a>
				</div>
				</div>
				<div class="d-flex mb-2">
					 <b-button variant="-light" :disabled="quantity < 2" id="tooltip-add" @click="quantity--" class="btn btn-light font-size-16 btn-rounded w-25 mr-1 my-auto"><i class="mdi mdi-minus"></i></b-button>
					  <b-form-group label="" class="w-50 my-auto mx-1">
						<b-form-input type="number" v-model="quantity" size="lg" /></b-form-group>
					  <b-button variant="-light" :disabled="quantity >= product.total_qty " @click="quantity++" class="btn btn-light font-size-16 btn-rounded w-25 my-auto m1-1"><i class="mdi mdi-plus"></i></b-button>
				</div>
				<div class="d-flex">
					<b-button variant="peach" class="btn-rounded btn-block w-100 text-white btn-lg btn-peach" :disabled="((product.combinations.length > 0) && (selectedcombination == null)) || (((product.variants.length > 0) && (product.combinations.length == 0)) && (Object.keys(selectedvariants).length === 0))"  @click="addToCart(product, quantity, selectedvariants, selectedcombination)">
					Add to Cart
					</b-button>	
					<!--<a class="btn btn-primary my-auto btn-rounded w-75"  >Add to Cart</a>-->
				</div>
	</div>
</template>

<script type="text/javascript">

	import { EventBus } from "../../../../../website-app";
	import ProductPriceBlock from './product-price-block';
	export default{
		components: {ProductPriceBlock},
		data(){
			return {
				selectedvariantstotal: 0.00,
				quantity: 1,
				loadingVariants: false,
			}
		},
		props: {
			company: {
				type: Object,
			},
			product: {
				type: Object,
			},
			selectedvariants: {
				type: Object,
			},
			selectedcombination: {
				type: Object,
			}
		},
		methods: {
			addToCart(product, quantity, variant, combination){
		    	EventBus.$emit('add-to-cart', product, quantity, variant, combination);
		    },
		    calculateVariantsTotal(){
        		var total = 0.00;
        		for(let key in this.selectedvariants){
        			console.log(key);
        			total += parseFloat(this.selectedvariants[key].price_difference);
        		}
        		this.selectedvariantstotal = this.addTax(this.product, total);
        	},
        	addTax(product, price){
        		var tax_rule = this.product.tax_rule;
        		console.log(tax_rule);
		    		var country_id = this.company.country_id;
		    	if(tax_rule !== null && tax_rule.country_rules.length > 0){
		    		var selected_rule = null;
		    		var selected_ruleIndex = -1;
			    	for(var ti = 0; ti < tax_rule.country_rules.length; ti++){
			    		selected_ruleIndex = tax_rule.country_rules[ti].countries.findIndex(item => item == country_id);
			    		if(selected_ruleIndex !== -1){
			    			selected_rule = tax_rule.country_rules[ti];
			    			ti = tax_rule.country_rules.length;
			    		}
			    	}
			    	console.log(selected_rule);
			    	if(tax_rule.type == 1){
		        		if(selected_rule !== null){
		        			var percentage = (parseFloat(selected_rule.value) / 100) + 1;
		        			return price * percentage;
		         		}else{
		         			return price;
		         		}
	         		}else{
	         			if(selected_rule !== null){
		        			return price + selected_rule.value;
		         		}else{
		         			return price;
		         		}
	         		}
         		}else if(tax_rule !== null && tax_rule.country_rules.length > 0){
         			var percentage = (parseFloat(tax_rule.percentage) / 100) + 1;
		        	return price * percentage;
         		}else {
		        	return price;
         		}
        	},
		}
	}
</script>