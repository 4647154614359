<script>
import PublicTopbar from "../components/public-topbar";
import PublicNav from "../components/public-nav";
import RightBar from "../components/right-bar";
import Footer from "../components/public-footer";

/**
 * Public-layout
 */
export default {
  components: {
    PublicTopbar,
    PublicNav,
    Footer,
    RightBar
  },
  props: {
    user: {
      type: Object
    },
    domain: {
      type: Object
    }
  },
  data() {
    return {};
  },
  created: () => {
    document.body.setAttribute("data-layout", "horizontal");
    document.body.setAttribute("data-topbar", "light");
    document.body.removeAttribute("data-sidebar", "light");
    document.body.removeAttribute("data-layout-size", "fluid");
  },
  methods: {
    toggleRightSidebar() {
      document.body.classList.toggle("right-bar-enabled");
    },
    hideRightSidebar() {
      document.body.classList.remove("right-bar-enabled");
    }
  }
};
</script>

<template>
  <div>
    <!-- Begin page -->
    <div id="layout-wrapper">
     
          
            <slot :domain="domain" :user="user"/>
     
        
      <!-- end main content-->
    </div>
    <!-- END layout-wrapper -->
    <RightBar />
  </div>
</template>
